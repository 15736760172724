import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getAllTrainings = (body) => {
  return instance2({
    method: 'get',
    url: 'trainings/get.php',
    params: body
  })
}

export const getSettingsTrainings = () => {
  return instance2({
    method: 'get',
    url: 'settings/trainings/get.php',
  })
}

export const getTrainingsCategories = () => {
  return instance2({
    method: 'get',
    url: 'settings/trainings/get_category.php'
  })
}


export const editTraining = body => {
  return instance({
    method: 'post',
    url: 'trainings/edit.php',
    data: body
  })
}

export const editTrainingContent = body => {
  return instance({
    method: 'post',
    url: 'trainings/content.php',
    data: body
  })
}

export const editTrainingParticipants = body => {
  return instance({
    method: 'post',
    url: 'trainings/add_participants.php',
    data: body
  })
}

export const signTrainingParticipant = body => {
  return instance({
    method: 'post',
    url: 'trainings/sign.php',
    data: body
  })
}

export const signResponsibleTraining = body => {
  return instance({
    method: 'post',
    url: 'trainings/user_sign.php',
    data: body
  })
}

export const endTraining = body => {
  return instance({
    method: 'post',
    url: 'trainings/new_end.php',
    data: body
  })
}

export const uploadTrainingFile = body => {
  return instance2({
    method: 'post',
    url: 'trainings/upload_file',
    data: body
  })
}

export const deleteTraining = body => {
  return instance({
    method: 'post',
    url: 'trainings/delete.php',
    data: body
  })
}

export const createTraining = body => {
  return instance({
    method: 'post',
    url: 'trainings/create.php',
    data: body
  })
}

export const createMultipleTrainings = body => {
  return instance({
    method: 'post',
    url: 'trainings/create_multiple.php',
    data: body
  })
}