export const branchInfo = [
    { name: "address", label: "Dirección" },
    { name: "name", label: "Nombre" },
    { name: "ext_id", label: "ID" },
    { name: "rut", label: "RUT" }
]

export const subBranchInfo = [
    { name: "name", label: "Nombre" },
    { name: "rut", label: "Rut" },
]

export const enterpriseDataTypes = [
    { name: "name", label: "Nombre" },
    { name: "address", label: "Dirección" },
    { name: "rut", label: "RUT" },
    { name: "legalName", label: "Razon Social" },
]

export const enterpriseFormInfo = [
    { name: "name", label: "Nombre", type: 'text' },
    { name: "address", label: "Dirección", type: 'text' },
    { name: "rut", label: "RUT", type: 'text' },
    { name: "legalName", label: "Razon Social", type: 'text' },
]


