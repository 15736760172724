import instance2 from "../Utils/instance2"

export const deleteBaseItem = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/delete_item.php',
		data: body
	})
}

export const editBaseField = body => {
	return instance2({
		method: 'put',
		url: 'settings/checklists/field',
		data: body
	})
}

export const createBasefield = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/field',
		data: body
	})
}

export const deleteBasefield = body => {
	return instance2({
		method: 'delete',
		url: 'settings/checklists/field',
		data: body
	})
}

export const editFieldCategory = body => {
	return instance2({
		method: 'put',
		url: 'settings/checklists/field_category',
		data: body
	})
}

export const createFieldOption = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/field_option',
		data: body
	})
}

export const editFieldOption = body => {
	return instance2({
		method: 'put',
		url: 'settings/checklists/field_option',
		data: body
	})
}

export const deleteFieldOption = body => {
	return instance2({
		method: 'delete',
		url: 'settings/checklists/field_option',
		data: body
	})
}

export const moveFieldCategory = body => {
	return instance2({
		method: 'put',
		url: 'settings/checklists/field/position',
		data: body
	})
}

export const getBaseConfig = body => {
	return instance2({
		method: 'get',
		url: 'settings/checklists/config',
		params: body
	})
}

export const editBaseConfig = body => {
	return instance2({
		method: 'put',
		url: 'settings/checklists/config',
		data: body
	})
}

export const setQuickAccessUsers = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/config/users',
		data: body
	})
}

export const createBaseSignGroup = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/group',
		data: body
	})
}

export const editBaseSignGroup = body => {
	return instance2({
		method: 'put',
		url: 'settings/checklists/group',
		data: body
	})
}

export const deleteBaseSignGroup = body => {
	return instance2({
		method: 'delete',
		url: 'settings/checklists/group',
		data: body
	})
}