import { Divider } from '@material-ui/core'
import { AccessTimeOutlined, Block, CheckCircleOutline } from '@material-ui/icons'
import { Box, Button, Dialog, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import AppContext from '../../AppContext'
import useToggle from '../../Hooks/ToogleHook'
import newTheme from '../../newTheme'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		margin: '24px 0'
	},
	authorization: {
		margin: '18px 0'
	},
	sings: {
		background: 'whitesmoke',
		borderRadius: 1,
	},
	user: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 2,
		borderRadius: 1,
	},
	userSigned: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 2,
		borderRadius: 1,
		background: newTheme.palette.blue.light
	},

	dialog: {
		padding: 2
	}
}

function Authorization({ authorization, index, onSign, disabled, last }) {
	const [openSign, toggleSign] = useToggle(false)
	const [selectedSign, setSign] = useState({})
	const { account } = useContext(AppContext)
	const { users, signs, amount } = authorization

	function handleSign(user) {
		const sign = user.signs[0]
		const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
		const should_notify = amount === signs.length + 1
		fetch(url)
			.then(res => res.blob())
			.then(blob => {
				const file = new File([blob], `sign-${user?.name}.png`, { type: "image/png" })
				const data = new FormData()
				data.append("user_id", user.id)
				data.append("file", file)
				data.append("file_name", file.name)
				data.append("name", user.name)
				data.append("authorization_id", authorization.id)
				const body = { data, notify: should_notify, index, last }
				onSign(body)
			})
	}

	function gotoSign(sign) {
		setSign(sign)
		toggleSign()
	}

	return (
		<Box sx={css.authorization}>
			<Dialog open={openSign} onClose={toggleSign} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography variant='h4'>Firma de {selectedSign.name}</Typography>
					<img src={selectedSign.url} width="100%" alt="firma" />
					<Typography variant='subtitle1'>Firmado el: {moment(selectedSign.date).format("YYYY-MM-DD HH:mm")}</Typography>
				</Box>
			</Dialog>
			<Typography variant='subtitle1'>{authorization.name}</Typography>
			<Typography variant='caption'>{`Se require${amount > 1 ? "n" : ""} ${amount} firma${amount > 1 ? "s" : ""}`}</Typography>
			<Box sx={css.sings}>
				{users.map((user, index) => {
					const isSigned = signs.map(s => s.user_id).includes(user.id)
					return (
						<>
							{index > 0 && <Divider />}
							{disabled ?
								<Box sx={css.user} style={{ opacity: 0.7 }}>
									<Box style={{ display: 'flex', alignItems: 'center' }}>
										<Block style={{ margin: '0 8px', color: newTheme.palette.grey.dark }} />
										<Box>
											<Typography variant='subtitle1'>{user.name}</Typography>
											<Typography variant='caption'>Esperando autorización anterior</Typography>
										</Box>
									</Box>
									<Button color="primary" variant="contained" disabled >
										Firmar
									</Button>
								</Box>
								:
								<Box sx={isSigned ? css.userSigned : css.user}>
									<Box style={{ display: 'flex', alignItems: 'center' }}>
										{isSigned ?
											<CheckCircleOutline style={{ margin: '0 8px', color: newTheme.palette.green.dark }} />
											:
											<AccessTimeOutlined style={{ margin: '0 8px', color: newTheme.palette.info.main }} />
										}
										<Typography variant='subtitle1'>{user.name}</Typography>
									</Box>
									{isSigned ?
										<Button color="secondary" variant="contained" onClick={() => gotoSign(signs.find(s => s.user_id === user.id))}>
											Ver firma
										</Button>
										:
										<Button color="primary" variant="contained" disabled={account.user.id !== String(user.id)} onClick={() => handleSign(user)}>
											Firmar
										</Button>
									}
								</Box>
							}
						</>
					)
				})}
			</Box>
		</Box>
	)
}

function Authorizations({ onSign }) {

	const { checklist } = useContext(ChecklistContext)

	const authorizations = checklist.authorizations || []
	if (!authorizations.length) return null

	return (
		<Box sx={css.container}>
			<Typography variant='h1'>Autorizaciones</Typography>
			{authorizations.map((authorization, index) => {
				const isPreviousSigned = index > 0 ? authorizations[index - 1].signs.length >= authorizations[index - 1].amount : true
				return <Authorization
					disabled={!isPreviousSigned}
					onSign={onSign}
					authorization={authorization}
					index={index}
					key={authorization.id}
					last={index === authorizations.length - 1}
				/>
			})}
		</Box>
	)
}

export default Authorizations