import { Box, Button, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { getSettingsTalks, getTalksCategories } from '../../API/talks'
import useChangeParams from '../../Hooks/UseStateChangeParams'
import DateTimeInput from '../../Shared/Inputs/DateTimeInput'
import NewMultiSelectInput from '../../Shared/Inputs/NewMultiSelectInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import UsersInput from '../../Shared/Inputs/UsersInput'
import { end_buttons, paper_style, title_style } from '../../Utils/defaultStyles'
import { filterActiveWorkers } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2
	},
	form: {
		display: 'flex',
		'& > *': {
			flexBasis: 230
		},
		gap: 3,
		flexWrap: 'wrap'
	}
}

function TalkForm({ onCreate }) {

	const [params, updateParams, setParams] = useChangeParams({ date_scheduled: moment().format("YYYY-MM-DD HH:mm") })
	const [categories, setCategories] = useState([])
	const [base_talks, setBaseTalks] = useState([])

	useEffect(() => {
		async function fetchData() {
			const responses = await Promise.all([getTalksCategories(), getSettingsTalks()])
			const datas = responses.map(r => r.data.info)
			setCategories(datas[0])
			setBaseTalks(datas[1])
		}

		fetchData()
	}, [])

	useEffect(() => {
		const filteredWorkers = filterActiveWorkers()
		const supervised = filteredWorkers.filter(worker => String(worker.user.id) === params.user_id)
		const supervisedIds = supervised.map(user => user.id)
		setParams(p => ({ ...p, participant_ids: supervisedIds }))
	}, [params.user_id, setParams])

	function onSubmit() {
		const body = { ...params }
		onCreate(body)
		setParams({ date_scheduled: moment().format("YYYY-MM-DD HH:mm") })
	}

	function isValid() {
		return params.user_id && params.category_id && params.documents_ids && params.date_scheduled
	}

	const filteredBaseTalks = useMemo(() => base_talks.filter(bt => String(bt.talk_type_id) === params.category_id), [base_talks, params.category_id])


	return (
		<Box sx={paper_style}>
			<Typography variant='h1' sx={title_style} >Nueva Charla</Typography>
			<Box sx={css.form}>
				<UsersInput onChange={updateParams} value={params.user_id} name="user_id" label="Responsable *" />
				<UsersInput options="multiple" onChange={updateParams} value={params.participant_ids} name="participant_ids" label="Participantes" />
				<SelectInput options={transformToOptions(categories)} onChange={updateParams} value={params.category_id} name="category_id" label="Categoría *" />
				<NewMultiSelectInput options={transformToOptions(filteredBaseTalks)} onChange={updateParams} value={params.documents_ids} name="documents_ids" label="Tipo de Charla *" />
				<DateTimeInput onChange={updateParams} value={params.date_scheduled} name="date_scheduled" label="Fecha y Hora a realizar *" />
			</Box>
			<Box sx={end_buttons}>
				<Button color="primary" variant="contained" onClick={onSubmit} disabled={!isValid()}>
					Crear Charla
				</Button>
			</Box>
		</Box>
	)
}

export default TalkForm