import React, { Component } from 'react'
import { Typography, withStyles, Collapse, Paper, Button, Checkbox } from '@material-ui/core'
import { connect } from 'react-redux'
import { editStepAction, getSettingsWorkflowsAction, getWorkflowsAction } from '../../Actions/WorkFlows'
import { getWorkersAction } from '../../Actions/EnterpriseAction'
import LoadingDialog from '../../Shared/LoadingDialog'
import { Block, InfoOutlined, KeyboardArrowDown, Save } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import TextPhase from './Phases/TextPhase'
import SelectPhase from './Phases/SelectPhase'
import MultiSelectPhase from './Phases/MultiSelectPhase'
import CheckPhase from './Phases/CheckPhase'
import FilePhase from './Phases/FilePhase'
import { checkIfStepIsCompleted, checkIfWorkflowIsReadyToEnd } from './Utils'
import moment from 'moment'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'
import { createInjured, deleteInjured, editInjured, editPhase, editStep, editWorkflow, sendMail } from '../../API/workflows'
import MailStep from './MailStep'
import InjuredManager from './InjuredManager'
import EditAuthsAndResponsible from './EditAuthsAndResponsible'
import RTEPhase from './Phases/RTEPhase'
import ExtraInfoToggler from './ExtraInfoToggler'

const style = (theme) => ({
  stageContainer: {
    margin: 24
  },
  buttonsContainer: {
    margin: 24,
    display: 'flex',
    justifyContent: 'space-between'
  },
  stage: {
    padding: 24,
    '& > h5': {
      marginBottom: 6
    }
  },
  step: {
    background: 'white',
    padding: 12,
    borderRadius: 15,
    margin: '12px 0'
  },
  stepTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    background: '#f2f3f8',
    padding: 8,
    borderRadius: 8,
    marginBottom: 8
  },
  stepSubtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 300,
    cursor: 'pointer',
    background: '#f2f3f8',
    padding: '4px 8px',
    borderRadius: 8,
    marginBottom: 8
  },
  phases: {
    margin: '12px 0 36px 0',
    background: theme.palette.blue.main,
    padding: 12,
    borderRadius: 15,
  },
  saveButton: {
    position: 'fixed',
    top: '40%',
    right: -128,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      right: 0
    }
  },
  stepStatus: {
    background: 'white',
    padding: 12,
    borderRadius: 15
  },
  save: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 182,
    padding: 12
  },
  finalButton: {
    padding: '0 24px',
    textAlign: 'end',
    marginBottom: 24
  },
  closed: {
    opacity: 0
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 12,
  },
  phase: {
    margin: '12px 0'
  }
})

function getPhaseType(phase) {
  switch (phase.type) {
    case "text": return TextPhase
    case "select": return SelectPhase
    case "multiselect": return MultiSelectPhase
    case "number": return TextPhase
    case "check": return CheckPhase
    case "file": return FilePhase
    case "rte": return RTEPhase
    default: return TextPhase
  }
}

class NewWorkflow extends Component {
  constructor() {
    super()
    this.state = {
      currentStep: 0,
      workflow: {},
      currentPhase: [],
      completed: false,
      loading: true,
      loadingStep: false,
      openStep: -1,
      somethingHasChanged: false,
      selectedItems: []
    }
    autobind(NewWorkflow, this)
  }

  componentDidMount() {
    const { getSettingsWorkflows, getWorkers, getWorkflows, match } = this.props
    getSettingsWorkflows()
    getWorkflows().then((response) => {
      if (response.payload.data.status !== "success") return false
      const { currentStep } = this.state
      const selected = match.params.id
      const allWorkflows = response.payload.data.info || []
      const selectedWorkflow = allWorkflows.find(wf => wf.id === selected)

      // Setup current step based on progress
      const steps = selectedWorkflow.workflow_steps || []
      for (const [index, step] of steps.entries()) {
        if (!step.data.finished) {
          this.setState({ currentStep: index, openStep: index, step })
          break
        }
      }

      // Check if process is completed
      const completed = steps.map(step => !!step.data.finished).every(finished => finished)

      const phases = steps[currentStep].phases || []
      this.setState({ workflow: selectedWorkflow, currentPhase: [...phases], completed, loading: false })
    })
    getWorkers()
  }

  generateMailContent(step) {
    const selectedItems = step.phases || []
    const details = selectedItems.reduce((x, item) => {
      const data = item.data
      const type = data.type
      let value = data.value

      if (type === "file" && value) {
        const files = data.value.split("#")
        value = files.map((file, index) => {
          const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}${file}`
          return `<a href="${url}">Documento ${index + 1}</a>`
        }).join(", ")
      }

      if (type === "check" && value) {
        value = data.value === true ? "Si" : "No"
      }

      if (type === "select" && value) {
        if (!!item?.data?.options) {
          value = item?.data?.options[value - 1]
        }
        if (item?.data?.entity === "workers") {
          value = this.getUsersNamesRaw([value])
        }
      }

      if (type === "multiselect" && value) {
        if (!!item?.data?.options) {
          value = value?.map(v => item?.data?.options[v])
        }
        if (item?.data?.entity === "workers") {
          value = this.getUsersNamesRaw(value)
        }
      }

      const itemText = `<strong>${data.label}</strong>
      <p>Respuesta: ${value || "Sin respuesta..."}</p>
      <p></p>`
      return x + itemText
    }, "")

    const sep = `
    <p>#------------------------------------------------------------------#</p>
    <p></p>
    `

    return `<p>Hola! Se ha terminado la etapa ${step?.data?.name}, y su contenido es:</p>
            ${sep}
            ${details}`
    //
  }

  getUsersNamesRaw(ids) {
    const { workers } = this.props
    const allWorkers = workers.all || []
    return ids.map(id => (allWorkers.find(w => w.id === id)?.name)).join(", ")
  }

  getUsersNames(ids) {
    if (ids.length === 0) return (
      <div>
        <Typography variant="subtitle1">Sin asignar</Typography>
      </div>
    )
    const { workers } = this.props
    const allWorkers = workers.all || []
    return ids.map(id => {
      return (
        <div>
          <Typography variant="subtitle1">* {allWorkers.find(w => w.id === id)?.name || null}</Typography>
        </div>
      )
    })
  }

  handleOpenStep(value) {
    return () => {
      const { openStep, somethingHasChanged, workflow } = this.state
      if (somethingHasChanged) this.handleSave()
      if (openStep === value) return this.setState({ openStep: -1 })
      const step = workflow.workflow_steps[value]
      return this.setState({ openStep: value, currentStep: value, step })
    }
  }

  handleSelectItem(item) {
    return () => {
      const { selectedItems } = this.state
      const newItems = [...selectedItems]
      const selectedIds = newItems.map(i => i.id)
      if (selectedIds.includes(item.id)) {
        const index = selectedIds.findIndex(id => id === item.id)
        newItems.splice(index, 1)
      } else {
        newItems.push(item)
      }
      this.setState({ selectedItems: newItems })
    }
  }


  handleFinishPhase(phase) {
    return async (value, show = true) => {
      const { workflow, step } = this.state
      value.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      const phaseBody = {
        id: phase.id,
        workflow_id: workflow.id,
        step_id: step.id,
        phase_data: value
      }
      const response = await editPhase(phaseBody)
      if (response?.data?.status !== "success") return false
      const newWorkflow = response.data.info
      this.setState({ workflow: newWorkflow, somethingHasChanged: show })
    }
  }

  async handleSave() {
    const { workflow, currentStep } = this.state
    const steps = workflow.workflow_steps
    const step = steps[currentStep]
    const { data } = step
    const body = {
      id: step.id,
      step_data: data,
      workflow_id: workflow.id
    }
    this.setState({ loadingStep: true })
    const response = await editStep(body)
    if (response?.data?.status !== "success") return this.setState({ somethingHasChanged: false, loadingStep: false })
    const newWorkflow = response.data.info
    this.setState({ workflow: newWorkflow, somethingHasChanged: false, loadingStep: false })
  }

  handleEditResponsibles(index) {
    return async (params) => {
      const { workflow } = this.state
      const steps = workflow.workflow_steps
      const step = steps[index]
      const { data } = step
      data.auth_ids = params.auth_ids
      data.responsible_ids = params.responsible_ids
      const body = {
        id: step.id,
        step_data: data,
        workflow_id: workflow.id
      }
      this.setState({ loadingStep: true })
      const response = await editStep(body)
      if (response?.data?.status !== "success") return this.setState({ somethingHasChanged: false, loadingStep: false })
      const newWorkflow = response.data.info
      this.setState({ workflow: newWorkflow, somethingHasChanged: false, loadingStep: false })
    }
  }

  async handleFinishStep() {
    const { workflow, currentStep } = this.state
    const steps = workflow.workflow_steps
    const step = steps[currentStep]
    const isCompleted = checkIfStepIsCompleted(step)
    const { data } = step
    if (isCompleted) {
      data.finished = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    }
    const body = {
      id: step.id,
      step_data: data,
      workflow_id: workflow.id
    }
    this.setState({ loadingStep: true })
    this.handleSendMail()
    const response = await editStep(body)
    if (response?.data?.status !== "success") return this.setState({ somethingHasChanged: false, loadingStep: false })
    const newWorkflow = response.data.info
    this.setState({ workflow: newWorkflow, somethingHasChanged: false, loadingStep: false })
  }

  handleSendMail() {
    const { workflow, currentStep } = this.state
    const { workers } = this.props
    const steps = workflow.workflow_steps || []

    // To make sure there is a next step and has responsibles
    const isNextStep = currentStep <= steps.length - 1

    let nextStep = false
    if (isNextStep) { nextStep = steps[currentStep + 1] }

    const isOwnerResponsibleOnNextStep = nextStep?.data?.owner_as_responsible
    const isOwnerNotifiedForThisStep = steps[currentStep]?.data?.owner_as_auth

    const responsibleIds = [...nextStep?.data?.responsible_ids || []]
    const notifiesIds = [...steps[currentStep]?.data?.auth_ids || []]

    if (isOwnerNotifiedForThisStep) {
      notifiesIds.push(workflow.user_id)
    }
    if (isOwnerResponsibleOnNextStep) {
      responsibleIds.push(workflow.user_id)
    }

    const allWorkers = workers.all || []
    const body = {
      subject: `Avance de proceso: ${workflow?.name}`,
      content: this.generateMailContent(steps[currentStep]),
      emails: responsibleIds.concat(notifiesIds).map(participant => allWorkers
        .find(worker => worker.id.toString() === participant)?.email),
    }
    console.log(body)
    if (responsibleIds.length > 0 || notifiesIds.length > 0) return sendMail(body)
    return false
  }

  async handleFinishWorkflow() {
    const { workflow } = this.state
    const { id, workflow_type_id: workflow_base_id, name } = workflow
    const data = { id, workflow_base_id, name, date_finished: moment().format("YYYY-MM-DD HH:mm:ss") }
    console.log(data)
    this.setState({ loadingStep: true })
    const response = await editWorkflow(data)
    if (response?.data?.status !== "success") return this.setState({ somethingHasChanged: false, loadingStep: false })
    const newWorkflow = response.data.info
    this.setState({ workflow: newWorkflow, somethingHasChanged: false, loadingStep: false })
  }

  async handleCreateInjured(injured) {
    const { workflow } = this.state
    const body = {
      workflow_id: workflow.id,
      injured_users: injured
    }
    this.setState({ loadingStep: true })
    const response = await createInjured(body)
    if (response?.data?.status !== "success") return this.setState({ loadingStep: false })
    const newWorkflow = response.data.info
    this.setState({ workflow: newWorkflow, loadingStep: false })
  }

  async handleEditInjured(injured) {
    const { workflow } = this.state
    const body = { ...injured, workflow_id: workflow.id }
    this.setState({ loadingStep: true })
    const response = await editInjured(body)
    if (response?.data?.status !== "success") return this.setState({ loadingStep: false })
    const newWorkflow = response.data.info
    this.setState({ workflow: newWorkflow, loadingStep: false })
  }

  async handleDeleteInjured(injured) {
    const { workflow } = this.state
    const body = { ...injured, workflow_id: workflow.id }
    this.setState({ loadingStep: true })
    const response = await deleteInjured(body)
    if (response?.data?.status !== "success") return this.setState({ loadingStep: false })
    const newWorkflow = response.data.info
    this.setState({ workflow: newWorkflow, loadingStep: false })
  }

  renderSteps() {
    const { workflow, openStep } = this.state
    const { classes, user } = this.props
    const steps = workflow.workflow_steps || []
    const admin = user?.account?.user?.userType === 1
    return steps.map((step, index) => {
      const { data, phases = [] } = step
      let ready = true
      let latestPhaseDate = phases?.[0]?.data?.date
      phases.forEach(phase => {
        if (!phase?.data?.value) ready = false
        if (moment(phase?.data?.date).isAfter(latestPhaseDate)) {
          latestPhaseDate = moment(phase.data.date)
        }
      })

      if (data?.finished) { latestPhaseDate = moment(data.finished) }
      const prevStep = index > 0 ? steps[index - 1] : false

      let totalTimeHours = moment(workflow.date_created).from(latestPhaseDate, true)

      if (prevStep) {
        totalTimeHours = !!prevStep?.data?.finished ? moment(prevStep?.data?.finished).from(latestPhaseDate, true) : "Aún no inicia esta etapa"
      }


      const isFinished = !!step?.data?.finished
      const canOpen = index === 0 || (checkIfStepIsCompleted(steps[index - 1]) && !!steps[index - 1]?.data?.finished)
      const isCompleted = checkIfStepIsCompleted(step)
      const isOwnerResponsible = !!step?.data?.owner_as_responsible
      const isOwnerAuth = !!step?.data?.owner_as_auth
      let responsibles = step?.data?.responsible_ids || []
      let auths = step?.data?.auth_ids || []

      return (
        <>
          <div className={classes.step} >
            <div className={classes.stepTitle} onClick={canOpen && this.handleOpenStep(index)}>
              <Typography variant="h5">{data?.name}</Typography>
              {canOpen ? <KeyboardArrowDown /> : <Block />}
            </div>
            <ExtraInfoToggler>
              <div style={{ display: 'flex', alignItems: 'baseline', marginTop: 12 }} onClick={e => e.stopPropagation()}>
                <Typography style={{ fontWeight: 600 }} variant="subtitle1">Responsables</Typography>
                {admin && <EditAuthsAndResponsible step={step} disabled={isFinished} onSave={this.handleEditResponsibles(index)} />}
              </div>
              {isOwnerResponsible && <Typography style={{ fontWeight: 600 }}>* Creador: {workflow?.user}</Typography>}
              {this.getUsersNames(responsibles)}
              <div style={{ display: 'flex', alignItems: 'baseline', marginTop: 12 }} onClick={e => e.stopPropagation()}>
                <Typography style={{ fontWeight: 600 }} variant="subtitle1">Serán notificados</Typography>
                {admin && <EditAuthsAndResponsible step={step} disabled={isFinished} onSave={this.handleEditResponsibles(index)} />}
              </div>
              <div style={{ marginBottom: 6 }}>
                {isOwnerAuth && <Typography style={{ fontWeight: 600 }}>* Creador: {workflow?.user}</Typography>}
                {this.getUsersNames(auths)}
              </div>
            </ExtraInfoToggler>
            {isFinished &&
              <div>
                <Typography variant="caption">Finalizado el: {step?.data?.finished}</Typography>
              </div>
            }
            <div>
              <Typography variant="caption">Tiempo transcurrido: {`${totalTimeHours}`}</Typography>
            </div>
          </div>
          <Collapse in={openStep === index}>
            <div className={classes.phases}>
              {ready && isFinished &&
                <div className={classes.stepStatus}>
                  <Typography variant="h5">Etapa completada</Typography>
                </div>
              }
              {this.renderPhases(step)}
              {isCompleted && !isFinished &&
                <div style={{ textAlign: 'end' }}>
                  <Button color="primary" variant="contained" onClick={this.handleFinishStep}>Terminar etapa</Button>
                </div>
              }
            </div>
          </Collapse>
        </>
      )
    })
  }

  renderPhases(step) {
    if (!step) return null
    const { classes, user } = this.props
    const { currentStep, selectedItems, workflow } = this.state
    const phases = step.phases || []
    const responsibles = step?.data?.responsible_ids || []
    const steps = workflow.workflow_steps || []
    const completed = steps.map(step => !!step.data.finished).every(finished => finished) && (!!workflow.date_finished || steps?.[0]?.data?.finished_workflow)
    const disabled = (responsibles.length > 0 && !responsibles.includes(user.account.user.id)) || completed

    return phases.map((phase, index) => {
      const Phase = getPhaseType(phase.data)
      const selectedIds = selectedItems.map(i => i.id)
      const selectedItem = selectedIds.includes(phase.id)
      return (
        <Paper className={classes.phase} key={`${currentStep}-${index}`}>
          {phase?.data?.value &&
            <div className={classes.inline}>
              <Checkbox checked={selectedItem} onClick={this.handleSelectItem(phase)} />
              <Typography variant="subtitle1">Seleccionar para enviar por correo</Typography>
            </div>
          }
          {phase?.data?.required &&
            < div className={classes.inline}>
              <InfoOutlined style={{ height: 16, width: 16, padding: 12 }} color="secondary" />
              <Typography variant="subtitle1">Campo obligatorio</Typography>
            </div>
          }
          <Phase disabled={disabled} index={index} phase={phase?.data} onSubmit={this.handleFinishPhase(phase)} />
          {phase?.data?.date &&
            <div style={{ padding: 12 }}>
              <Typography variant="caption">Última actualización: {phase?.data?.date}</Typography>
            </div>
          }
        </Paper>
      )
    })
  }

  render() {
    const { classes } = this.props
    const { workflow, loading, somethingHasChanged, loadingStep, selectedItems } = this.state
    const steps = workflow.workflow_steps || []
    const date_finished = steps?.[0]?.data?.finished_workflow || workflow.date_finished
    const completed = steps.map(step => !!step.data.finished).every(finished => finished) && (!!workflow.date_finished || !!steps?.[0]?.data?.finished_workflow)
    const allStepsAreNotCompleted = !checkIfWorkflowIsReadyToEnd(workflow)
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <div className={classes.title}>
          <Typography variant="h6">{workflow?.name || workflow?.workflow_type}</Typography>
          <Typography style={{ marginLeft: 24 }} variant="h4">{workflow?.workflow_type}</Typography>
          <Typography style={{ marginLeft: 24 }} variant="subtitle1">Creado por: {workflow?.user}</Typography>
          <Typography style={{ marginLeft: 24 }} variant="subtitle1">Creado el: {workflow?.date_created}</Typography>
          {date_finished && <Typography variant="h4" style={{ marginLeft: 24 }}>{`Proceso terminado el: ${date_finished}`}</Typography>}
        </div>

        {workflow.workflow_type_id <= 2 && <InjuredManager
          injured={workflow?.injured_users || []}
          onCreate={this.handleCreateInjured}
          onEdit={this.handleEditInjured}
          onDelete={this.handleDeleteInjured}
        />}

        <div className={classes.stageContainer}>
          {this.renderSteps()}
        </div>
        <MailStep selectedItems={selectedItems} />
        {!completed && !loading && <div className={classes.finalButton}>
          <Button variant="contained" color="primary" size="large" disabled={allStepsAreNotCompleted} onClick={this.handleFinishWorkflow}>
            Terminar Proceso
          </Button>
        </div>}
        {<div className={`${classes.saveButton} ${!somethingHasChanged ? classes.closed : ""}`}>
          <Button
            size="large"
            className={classes.save}
            onClick={this.handleSave}
            color="secondary"
            variant="contained"
          >
            {loadingStep ?
              <div>
                <MiniLoaderAnimator loading />
              </div>
              :
              <div style={{ height: 32, width: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <Save style={{ height: 24, width: 24 }} />
              </div>
            }
            Guardar cambios
          </Button>
        </div>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workflows: state.workflows,
  settings: state.settings,
  workers: state.workers,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getSettingsWorkflows: () => dispatch(getSettingsWorkflowsAction()),
  getWorkflows: () => dispatch(getWorkflowsAction()),
  getWorkers: () => dispatch(getWorkersAction()),
  editStep: body => dispatch(editStepAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(NewWorkflow))