import React from 'react'
import { Typography } from '@material-ui/core'
import { Box } from '@mui/material'

const css = {
  container: {
    padding: '6px 12px',
    borderRadius: 24,
    textAlign: 'center'
  }
}

function Chip({ value, to_string }) {
  if (to_string) return value

  function getColor() {
    if (value === "Terminado") return "#91deb1"
    return value === "Vencido" ? "#ff5b5b" : "#5578eb"
  }

  return (
    <Box sx={css.container} style={{ background: getColor() }}>
      <Typography variant="body1" style={{ color: "white" }}>{value}</Typography>
    </Box>
  )
}

export default Chip