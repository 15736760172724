import { ErrorOutline } from '@material-ui/icons'
import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import useChange from '../../../../Hooks/UseStateChange'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import { FindingStructureContext } from './DefaultFindingStructure'

const css = {
	container: {
		padding: 2,
		borderRadius: 1,
		background: 'white',
		margin: '12px 0',
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: 2
	},
	timing: {
		border: '1px solid grey',
		padding: 1,
		borderRadius: 1,
		margin: '6px 0',
		flexGrow: 1,
		flexBasis: 400,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	inline: {
		display: 'flex',
		alignItems: 'center',
		gap: 1
	},
	hours: {
		maxWidth: 60
	}
}

function DefaultTiming({ timing = {}, crit }) {
	const [hours, setHours] = useChange(timing.offset_hours || 0)
	const { editTiming } = useContext(FindingStructureContext)

	function onBlur() {
		const body = { ...timing, hours, default_criticality: crit.value }
		editTiming(body)

	}

	return (
		<Box sx={css.timing} style={{ borderColor: crit.color }}>
			<Box sx={css.inline}>
				<ErrorOutline style={{ color: crit.color }} />
				<Typography variant='subtitle2' style={{ color: crit.color }} >Tiempo limite máximo para {crit.name}:</Typography>
			</Box>
			<Box sx={css.hours}>
				<Box sx={css.inline}>
					<SeamlessInput onBlur={onBlur} padding={"2px 8px"} value={hours} onChange={setHours} />
					<Typography variant='subtitle1'>Hrs.</Typography>
				</Box>
			</Box>
		</Box>
	)
}

function DefaultTimings({ timings }) {

	const { crits } = useContext(FindingStructureContext)

	return (
		<Box sx={css.container}>
			{crits.map(crit => {
				const timing = timings.find(t => t.default_criticality === crit.value)
				return <DefaultTiming crit={crit} key={timing?.id} timing={timing} />
			})
			}
		</Box>
	)
}

export default DefaultTimings