/* eslint-disable react-hooks/exhaustive-deps */
import { AddCircleOutline, Bookmark, BookmarkBorder, BookmarkOutlined, Edit, ErrorOutline, Filter9PlusOutlined, InfoOutlined, KeyboardArrowDown, KeyboardArrowUp, PlusOne, PlusOneOutlined, Score, Settings } from '@material-ui/icons'
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { editBaseChecklistItem, editItemCategory, editSubtitle } from '../../../../API/settings_checklist'
import useToggle from '../../../../Hooks/ToogleHook'
import newTheme from '../../../../newTheme'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import { SetupChecklistContext } from './ChecklistSetup'
import CritsMenu from './CritsMenu'
import Item from './Item'
import PointsMenu from './PointsMenu'
import Subtitle from './Subtitle'

const css = {
	header: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		marginTop: 2,
		flexWrap: 'wrap',
	}
}

function ChecklistItemsSetup({ value }) {
	const { checklist } = useContext(SetupChecklistContext)
	const items = checklist.checklist_items || []
	const subtitles = checklist.subtitles || []

	function renderSubtitles() {
		return subtitles.map(subtitle => {
			const subtitle_items = items.filter(item => item.subtitle_id === subtitle.id)
			return <Subtitle key={subtitle.id} subtitle={subtitle} items={subtitle_items} />
		})
	}

	function renderNoCategory() {
		const no_subtitle_items = items.filter(item => item.subtitle_id === 0)
		return (
			<Box>
				<Box sx={css.header}>
					<Typography variant='h4' color="secondary" >Sin Categoría</Typography>
					<IconButton color="info">
						<AddCircleOutline />
					</IconButton>
				</Box>
				{no_subtitle_items.map(item => <Item item={item} key={item.id} />)}
			</Box>
		)
	}

	return (
		<Box>
			{renderSubtitles()}
			{renderNoCategory()}
		</Box>
	)
}

export default ChecklistItemsSetup