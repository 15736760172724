import newTheme from "../newTheme"

export const inline_space = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: 2
}

export const inline = {
	display: 'flex',
	alignItems: 'center',
	gap: 2
}

export const text_space = {
	margin: '12px 0'
}

export const end_buttons = {
	textAlign: 'end'
}

export const end_flex_buttons = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	gap: 2
}

export const labeless = {
	marginBottom: -2
}

export const dialog_style = {
	padding: 3
}

export const link_style = {
	color: newTheme.palette.blue.main,
	textDecoration: 'underline',
	cursor: 'pointer'
}

export const paper_style = {
	padding: 2,
	borderRadius: 2,
	background: 'white',
	position: 'relative'
}

export const title_style = {
	marginBottom: 2
}

export const section_separation = {
	margin: '24px 0'
}