import { withStyles } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import autobind from '../../Utils/autobind'
import DateInput from './DateInput'

const style = () => ({
  container: {
    maxWidth: 500
  }
})

class DateIntervalSelector extends React.Component {
  constructor() {
    super()
    this.state = {
      date_start: moment(new Date()).startOf("week").format("YYYY-MM-DD"),
      date_end: moment(new Date()).endOf("week").format("YYYY-MM-DD"),
      updated: true
    }
    autobind(DateIntervalSelector, this)
  }
  componentDidMount() {
    const { onDateChange, start_at, end_at } = this.props
    if (!start_at && !end_at) {
      const { date_start, date_end } = this.state
      const dates = { date_start, date_end }
      return onDateChange(dates)
    }
    const dates = { date_start: start_at, date_end: end_at }
    onDateChange(dates)
    this.setState({ ...this.state, ...dates })

  }

  componentDidUpdate(prevProps) {
    const { onDateChange, updated = true } = this.props
    const { updated: myUpdated = true, date_start, date_end } = this.state
    if (updated !== myUpdated) {
      const dates = { date_start, date_end }
      onDateChange(dates)
    }
  }

  handleChange(event) {
    const { target } = event
    const { onDateChange } = this.props
    const callback = () => {
      const { date_start, date_end } = this.state
      const dates = { date_start, date_end }
      return onDateChange(dates)
    }
    this.setState({ [target.name]: target.value }, callback)
  }

  render() {
    const { classes, disabled } = this.props
    const { date_start, date_end } = this.state
    return (
      <div className={classes.container}>
        <DateInput disabled={disabled} label="Desde" name="date_start" value={date_start} onChange={this.handleChange} />
        <DateInput disabled={disabled} label="Hasta" name="date_end" value={date_end} onChange={this.handleChange} />
      </div>
    )
  }
}

export default withStyles(style)(DateIntervalSelector)