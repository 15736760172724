import { Box } from '@mui/material'

function Loader({ value }) {
	return (
		<div className="lds-ring">
			<div />
			<div />
			<div />
			<div />
		</div>
	)
}

export default Loader