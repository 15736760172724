import { FilterList, HighlightOffOutlined } from '@material-ui/icons'
import { Box, Drawer, IconButton, Tooltip, Typography } from '@mui/material'
import useToggle from '../../Hooks/ToogleHook'
import newTheme from '../../newTheme'
import { transformToOptions } from '../../Utils/functions'
import SelectInput from '../Inputs/SelectInput'

const css = {
	filters: {
		padding: 3,
		width: 250
	},
	filterBox: {
		display: 'flex',
		alignItems: 'center',
		margin: '6px 0'
	},
	filterTag: {
		background: newTheme.palette.blue.light,
		padding: '3px 6px 3px 12px',
		textAlign: 'center',
		borderRadius: 24,
		margin: '0 12px',
		display: 'flex',
		alignItems: 'center',
	}
}

function TableFilters({ filters = [], data, selected, setFilters, setPage }) {

	const [openFilters, toggleFilters] = useToggle(false)

	return (
		<Box>
			<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Filtros</Typography>}>
				<IconButton onClick={toggleFilters}>
					<FilterList />
				</IconButton>
			</Tooltip>
			{filters.map(filter => {
				const entities = data[filter.entity]
				if (!entities) return null
				const selectedEntityId = selected[filter.label]
				const selectedEntity = entities.find(e => String(e.id) === selectedEntityId)
				if (!selectedEntity) return null
				return (
					<Box sx={css.filterBox}>
						<Typography variant='subtitle1'>{filter.name}:</Typography>
						<Box sx={css.filterTag}>
							<Typography variant='subtitle1'>{selectedEntity?.name}</Typography>
							<IconButton
								size="small"
								style={{ marginLeft: 6 }}
								onClick={e => {
									setFilters(f => ({ ...f, [filter.label]: null }))
									setPage(0)
								}}
							>
								<HighlightOffOutlined />
							</IconButton>
						</Box>
					</Box>
				)
			})}
			<Drawer anchor="right" open={openFilters} onClose={toggleFilters}>
				<Box sx={css.filters}>
					<Box style={{ textAlign: 'end' }}>
						<IconButton onClick={toggleFilters}>
							<HighlightOffOutlined />
						</IconButton>
					</Box>
					{filters.map(filter => {
						const options = data?.[filter.entity] || []
						return (
							<Box>
								<Typography variant='subtitle1'><strong>{filter.name}</strong></Typography>
								<SelectInput
									value={selected[filter.label]}
									onChange={e => {
										setFilters(f => ({ ...f, [filter.label]: e.target.value }))
										// toggleFilters()
										setPage(0)
									}}
									name={filter.label}
									label=""
									options={transformToOptions(options)}
								/>
							</Box>
						)
					})}
				</Box>
			</Drawer>
		</Box>
	)
}

export default TableFilters