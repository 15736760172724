import React, { Component } from 'react'
import { Button, Collapse, Dialog, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { Delete, KeyboardArrowDown } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import ImageContainer from '../../Shared/ImageContainer'
import MySignaturePad from '../Talks/MySignaturePad'
import { addUserSignAction } from '../../Actions/WorkerActions'
import moment from 'moment'
import { openGlobalDialog } from '../../Actions/GlobalActions'
import { deleteUserSign } from '../../API/users'

const style = theme => ({
  container: {
    background: 'white'
  },
  title: {
    padding: 12,
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      color: 'white'
    }
  },
  open: {
    transition: 'all 0.2s ease-in-out',
    transform: 'rotate(180deg)'
  },
  closed: {
    transition: 'all 0.2s ease-in-out',
    transform: 'rotate(0deg)'
  },
  sign: {
    width: 250,
    height: 150,
    padding: 12,
    margin: 12,
    "@media (max-width:500px)": {
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  signs: {
    padding: 12,
    display: 'flex',
    flexWrap: 'wrap'
  },
  addSign: {
    padding: 12,
    textAlign: 'center'
  },
  delete: {
    position: 'absolute',
    top: 6,
    right: 6
  }
})
class WorkerSigns extends Component {
  constructor() {
    super()
    this.state = {
      openCollapse: false,
      openSign: false
    }
    autobind(WorkerSigns, this)
  }

  handleOpenCollpase() {
    this.setState({ openCollapse: !this.state.openCollapse })
  }

  handleOpenSign() {
    this.setState({ openSign: !this.state.openSign })
  }

  handleDelete(id) {
    return () => {
      const { openGlobalDialog, reload } = this.props
      openGlobalDialog(deleteUserSign, { id }, {
        callback: reload,
        message: "Seguro que quiered eliminar esta firma",
        title: "Eliminar firma registrada",
        submit: "Eliminar"
      })
    }
  }

  handleSave(value) {
    fetch(value)
      .then(res => res.blob())
      .then(blob => {
        const { workers, addUserSign } = this.props
        const selected = workers.selected
        const file = new File([blob], "File name", { type: "image/png" })
        const data = new FormData()
        data.append("file", file)
        data.append("user_id", selected.id)
        data.append("date", moment(new Date()).format("YYYY-MM-DD"))
        data.append("name", selected.name)
        addUserSign(data)
        this.setState({ openSign: false })
      })
  }

  renderSigns() {
    const { classes, workers, user } = this.props
    const signs = workers.selected?.signs || []
    const selected = workers.selected
    const isSameUSer = selected?.id?.toString() === user.account.user.id.toString()
    return signs.map(sign => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
      return (
        <Paper className={classes.sign} key={sign.id} square>
          <ImageContainer src={url || '/noimage.png'} width="140px" />
          <IconButton disabled={!isSameUSer || signs.length <= 1} onClick={this.handleDelete(sign.id)} className={classes.delete} size="small">
            <Delete />
          </IconButton>
        </Paper>
      )
    })
  }

  render() {
    const { classes, workers, user } = this.props
    const { openCollapse, openSign } = this.state
    const signs = workers.selected?.signs || []
    const selected = workers.selected
    const isSameUSer = selected?.id?.toString() === user.account.user.id.toString()
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h2" >Firmas</Typography>
          <IconButton size="small" onClick={this.handleOpenCollpase}>
            <KeyboardArrowDown className={openCollapse ? classes.open : classes.closed} />
          </IconButton>
        </div>
        <Collapse in={openCollapse}>
          {isSameUSer &&
            <div className={classes.addSign}>
              <Button color="primary" variant="contained" onClick={this.handleOpenSign}>
                Añadir Firma
              </Button>
            </div>
          }
          <div className={classes.signs}>
            {signs.length > 0 ? this.renderSigns() : <Typography variant="subtitle1">No tienes firmas registradas aún</Typography>}
          </div>
        </Collapse>
        <Dialog open={openSign} onClose={this.handleOpenSign} maxWidth="md">
          <div >
            <MySignaturePad
              clearButton="true"
              save={this.handleSave}
            />
          </div>
        </Dialog>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  addUserSign: body => dispatch(addUserSignAction(body)),
  openGlobalDialog: (action, body, options) => dispatch(openGlobalDialog(action, body, options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(WorkerSigns))