import { Add, GetApp } from '@material-ui/icons'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import JSZip from 'jszip'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { getBranches } from '../../API/branches'
import { getSettingsChecklist } from '../../API/checklists'
import { getOrderRule, getSubEnterprises } from '../../API/enterprise'
import { getBaseEvaluations } from '../../API/evaluations'
import { getSettingsTalks } from '../../API/talks'
import { getSettingsTrainings } from '../../API/trainings'
import { createBaseActivity, getBaseActivitiesReport, getPositions } from '../../API/users'
import useToggle from '../../Hooks/ToogleHook'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import { addQrToFile, mergeMultiplePdfFilesAndGet, signDocument } from '../../Utils/functions'
import CreateConstrain from '../Settings/Users/CreateConstrain'
import WorkerBaseActivity from './WorkerBaseActivity'
import { saveAs } from 'file-saver'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'
import { filterActiveWorkers } from '../../Utils/filters'

const css = {
	container: {
		padding: 2,
		position: 'relative',
		minHeight: 200
	},
	button: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	activities: {
		position: 'relative',
		minHeight: 100
	},
	header: {
		display: 'flex',
		alignItems: 'center'
	}
}

function WorkerBaseActivities({ user_id, disableNew = false }) {
	const [baseActivities, setBaseActivities] = useState([])
	const [data, setData] = useState([])
	const [openCreate, toggleCreate] = useToggle(false)
	const [order, setOrder] = useState(false)
	const [loading, setLoading] = useState(false)
	const [downloading, setDownloading] = useState(false)

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			const response = await getBaseActivitiesReport({ user_id })
			const data_response = await Promise.all([getSettingsChecklist(), getSettingsTalks(), getSettingsTrainings(), getBaseEvaluations()])
			const order_response = await getOrderRule()
			const newData = data_response.map(r => r.data.info)
			setData(newData)
			setBaseActivities(response.data.info)
			setOrder(order_response.data.info)
			setLoading(false)
		}
		fetchData()
	}, [])

	async function onCreateActivity(body) {
		body.users_id = [user_id]
		await createBaseActivity(body)
		const response = await getBaseActivitiesReport({ user_id })
		setBaseActivities(response.data.info)
	}

	function checkIfItsComply(activity) {
		if (!activity.participations.length) return false
		switch (activity.frequency) {
			case "0": { return !!activity?.participations.length }
			case "1": { return moment(activity?.participations[0]?.date_scheduled).isSame(moment(), "day") }
			case "7": { return moment(activity?.participations[0]?.date_scheduled).isSame(moment(), "week") }
			case "15": {
				const start = moment().endOf("week").subtract("2", "weeks")
				const end = moment().endOf("week")
				return moment(activity?.participations[0]?.date_scheduled).isBetween(start, end)
			}
			case "30": { return moment(activity?.participations[0]?.date_scheduled).isSame(moment(), "month") }
			case "60": {
				const start = moment().endOf("month").subtract("2", "months")
				const end = moment().endOf("month")
				return moment(activity?.participations[0]?.date_scheduled).isBetween(start, end)
			}
			case "90": {
				const start = moment().endOf("month").subtract("3", "months")
				const end = moment().endOf("month")
				return moment(activity?.participations[0]?.date_scheduled).isBetween(start, end)
			}
			case "180": {
				const start = moment().startOf("year")
				const end = moment().startOf("year").add("6", "months")
				return moment(activity?.participations[0]?.date_scheduled).isBetween(start, end)
			}
			case "365": { return moment(activity?.participations[0]?.date_scheduled).isSame(moment(), "year") }
			default: { return false }
		}
	}

	async function onDownload() {
		if (!baseActivities.length) return null
		setDownloading(true)
		const zip = new JSZip()
		await Promise.all(baseActivities.map(async activity => {
			const folder = zip.folder(activity?.name)
			if (activity.record_type === "0") {
				const participations = activity.participations || []
				await Promise.all(participations.map(async part => {
					try {
						const file = await downloadChecklist(part, activity)
						folder.file(moment(part.date_done).format("DD-MM-YYYY") + ".pdf", file)
					} catch {
						console.log("NOFILE")
					}
				}))
			}

			if (activity.record_type === "1") {
				const participations = activity.participations || []
				await Promise.all(participations.map(async part => {
					try {
						const file = await downloadTNT(part, activity, "talk")
						folder.file(moment(part.date_end).format("DD-MM-YYYY") + ".pdf", file)
					} catch {
						console.log("NOFILE")
					}
				}))
			}

			if (activity.record_type === "2") {
				const participations = activity.participations || []
				await Promise.all(participations.map(async part => {
					try {
						const file = await downloadTNT(part, activity, "train")
						folder.file(moment(part.date_end).format("DD-MM-YYYY") + ".pdf", file)
					} catch {
						console.log("NOFILE")
					}
				}))
			}

			if (activity.record_type === "3") {
				const participations = activity.participations || []
				await Promise.all(participations.map(async part => {
					try {
						const file = await downloadEvaluation(part, activity)
						console.log(file)
						folder.file(moment(part.date_done).format("DD-MM-YYYY") + ".pdf", file)
					} catch {
						console.log("NOFILE")
					}
				}))
			}

		}))
		setDownloading(false)
		zip.generateAsync({ type: "blob" })
			.then(function (content) {
				saveAs(content, `Documentación_Obligatoria.zip`)
			})
	}

	function handleGetTypeName(elementType, singular = true,) {
		if (singular) return elementType === "talk" ? "Charla" : "Capacitación"
		return elementType === "talk" ? "Charlas" : "Capacitaciones"
	}

	async function downloadChecklist(checklist, activity) {
		const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_CHECKLIST_PDF}?id=${checklist.id}&u=${user_id}`
		const parsed_url = new URL(url)
		const final_url = parsed_url.toString()
		const signedDocument = await mergeMultiplePdfFilesAndGet([final_url], "Reporte de registro o formulario")
		const code = `${checklist.id}--l`
		const validateUrl = `${window.location.origin}/validate/${code}`
		const pdf = addQrToFile(signedDocument, `${moment(checklist.date_done).format("DD-MM-YYYY")}-${activity.name.replaceAll(".", "-")}-${checklist.branch_name}`, validateUrl, true)
		return pdf
	}

	async function downloadEvaluation(evaluation, activity) {
		const url = `https://app.safeasy.cl/model/pdf/evaluations.php?id=${evaluation.id}&u=${evaluation.user_id}`
		const parsed_url = new URL(url)
		const final_url = parsed_url.toString()
		const file_blob = await mergeMultiplePdfFilesAndGet([final_url], `Reporte de evaluación: ${evaluation.name} de ${evaluation.user}`)
		const file = await file_blob.save()
		return file
	}

	async function downloadTNT(talk, activity, elementType = "talk") {

		const urls = {
			document: { talk: process.env.REACT_APP_TALKS_DOCUMENT_FOLDER, train: process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER },
			signs: { talk: process.env.REACT_APP_TALKS_SIGNS_FOLDER, train: process.env.REACT_APP_TRAININGS_SIGNS_FOLDER },
			pdf: { talk: process.env.REACT_APP_TALKS_PDF, train: process.env.REACT_APP_TRAININGS_PDF },
		}

		const finalUrls = {}
		Object.keys(urls).forEach(key => {
			finalUrls[key] = urls[key][elementType]
		})
		const workers = filterActiveWorkers(false)
		const selected = talk
		const file = selected.file || ""
		const url1 = `${process.env.REACT_APP_IMG_URL}${finalUrls.pdf}?id=${talk.id}&u=${user_id}`
		const parsed_url = new URL(url1)
		const final_url = parsed_url.toString()
		const url2 = process.env.REACT_APP_IMG_URL + finalUrls.document + "/" + file
		const relatorSign = selected?.user_sign[0]
		const sign = selected.participants_signs.find(ps => String(ps.user_id) === String(user_id))
		const relator = workers.find(worker => worker.id?.toString() === selected.user_id?.toString())

		if (file.includes(".pdf")) {
			const signedFile = await mergeMultiplePdfFilesAndGet([final_url, url2], `Reporte ${handleGetTypeName(elementType)}`)
			let finalDoc = signedFile
			if (!!sign) {
				const imgUrl = process.env.REACT_APP_IMG_URL + finalUrls.signs + '/' + sign.sign
				const relatorSignUrl = process.env.REACT_APP_IMG_URL + finalUrls.signs + '/' + relatorSign?.sign
				const selectedWorker = workers.find(worker => worker.id?.toString() === sign.user_id?.toString())
				finalDoc = await signDocument(signedFile, sign, imgUrl, relatorSign, relatorSignUrl, `${handleGetTypeName(elementType)} ${sign.name}`, selectedWorker, relator, true)
			}
			const code = `${selected.id}--${elementType === "talk" ? "k" : "t"}`
			const validateUrl = `${window.location.origin}/validate/${code}`
			const final_file = await addQrToFile(finalDoc, `${activity.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}}`, validateUrl, true)
			return final_file

		}
		const signedFile = await mergeMultiplePdfFilesAndGet([final_url], `${activity.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}}`)
		let finalDoc = signedFile
		if (!!sign) {
			const imgUrl = process.env.REACT_APP_IMG_URL + finalUrls.signs + '/' + sign.sign
			const relatorSignUrl = process.env.REACT_APP_IMG_URL + finalUrls.signs + '/' + relatorSign?.sign
			const selectedWorker = workers.find(worker => worker.id?.toString() === sign.user_id?.toString())
			finalDoc = await signDocument(signedFile, sign, imgUrl, relatorSign, relatorSignUrl, `${handleGetTypeName(elementType)} ${sign.name}`, selectedWorker, relator, true)
		}
		const code = `${selected.id}--${elementType === "talk" ? "k" : "t"}`
		const validateUrl = `${window.location.origin}/validate/${code}`
		const final_file = await addQrToFile(finalDoc, `${activity.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD HHmmss")}`, validateUrl, true)
		return final_file
	}

	function activities() {
		return (
			<>
				<Box sx={css.header}>
					<IconButton onClick={onDownload} disabled={downloading}>
						{downloading ? <MiniLoaderAnimator /> : <GetApp />}
					</IconButton>
					<Typography variant='h4' style={{ padding: 6 }} >Actividades y participación</Typography>
				</Box>
				{!disableNew &&
					<IconButton sx={css.button} onClick={toggleCreate}>
						<Add />
					</IconButton>
				}
				<CreateConstrain disableUsers open={openCreate} data={data} onCreate={onCreateActivity} />
				<Box sx={css.activities}>
					{loading ?
						<LoaderAnimator />
						:
						baseActivities.map((activity, index, all) => {
							const disabled = order && index > 0 && !checkIfItsComply(all[index - 1])
							return <WorkerBaseActivity user_id={user_id} activity={activity} disabled={disabled} />
						})
					}
				</Box>
			</>
		)
	}

	if (!baseActivities.length) return null

	return (
		<Box>
			<Paper sx={css.container}>
				{!!baseActivities.length && !loading ? activities() : <LoaderAnimator />}
			</Paper>
		</Box>
	)
}

export default WorkerBaseActivities