import React, { Component } from 'react'
import { Button, Collapse, Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import ChecklistElement from './ChecklistElement'
import { Fragment } from 'react'
import TextInput from '../../../../Shared/Inputs/TextInput'
import autobind from '../../../../Utils/autobind'
import { withRouter } from 'react-router-dom'
import Category from './Category'
import { transformToOptions } from '../../../../Utils/functions'
import MultiSelectInput from '../../../../Shared/Inputs/MultiSelectInput'

const style = () => ({
  container: {
    '& > h4': {
      padding: 12
    }
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  form: {
    padding: '12px 24px'
  },
  input: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogButtons: {
    padding: 12,
    textAlign: 'end',
    '& > *': {
      marginLeft: 6
    }
  },
  mainTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 6
  },
  category: {
    padding: 12,
    background: '#f2f3f8',
    borderRadius: 8,
    margin: '12px 0'
  },

  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

// const crits = [
//   { label: "No asignar valor por defecto", value: 0 },
//   { label: "No Crítico", valule: 1 },
//   { label: "Poco Crítico", value: 2 },
//   { label: "Medianamente Crítico", value: 3 },
//   { label: "Altamente Crítico", value: 4 }
// ]

class ChecklistsElements extends Component {
  constructor() {
    super()
    this.state = {
      params: {
        users: []
      },
      open: false,
      openWarning: false,
      openCreateCategory: false,
      element: {}
    }
    autobind(ChecklistsElements, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeCounter() {
    const { params } = this.state
    const actualCounter = params.counter
    if (actualCounter === 1) {
      params.counter = 0
    } else {
      params.counter = 1
    }
    this.setState({ params })
  }

  handleOpenEdit(element) {
    return () => {
      const { params } = this.state
      params.name = element.name
      params.default_criticality = element.default_criticality
      params.counter = element.counter
      this.setState({ params, open: true, element })
    }
  }

  handleOpenCreateCategory() {
    const { openCreateCategory } = this.state
    this.setState({ openCreateCategory: !openCreateCategory })
  }

  handleOpenWarning(element) {
    return () => {
      this.setState({ element, openWarning: true })
    }
  }

  renderElements() {
    const { checklist = {}, classes, onChangeCategory, onEditCategory, onDeleteCategory, workers, onEdit, onCreateEmpty } = this.props
    const elements = checklist?.checklist_items || []
    const categories = checklist?.subtitles || []
    const allCategories = categories.sort((a, b) => {
      if (a.subtitle_order > b.subtitle_order) return 1
      if (a.subtitle_order < b.subtitle_order) return -1
      return 0
    }).concat([{ name: "Sin categoría", id: 0, subtitle_order: -1, users: [] }])
    const allCategoryIds = allCategories.map(cat => cat.id)
    return allCategories.map(category => {
      const categoryElement = elements.filter(element => {
        const isRealCategory = category.id !== 0
        if (isRealCategory) {
          const categoryIncludesElement = element?.subtitle_id === category.id
          return categoryIncludesElement
        }

        const categoryIncludesElement = element?.subtitle_id === category.id
        const elementIsFromNoExistingCategory = !allCategoryIds.includes(element.subtitle_id)
        return categoryIncludesElement || elementIsFromNoExistingCategory
      })
      let content
      if (categoryElement.length === 0) { content = <Typography variant="subtitle1" style={{ padding: '12px 0 0 12px' }}>Aún no hay elementos en la lista...</Typography> }
      else {
        content = categoryElement.map((element, index) => {
          return (
            <Fragment key={element.id}>
              {index > 0 && <Divider />}
              <ChecklistElement
                element={element}
                key={element.id}
                onEdit={onEdit}
                checklist={checklist}
                onDelete={this.handleOpenWarning(element)}
                onChangeCategory={onChangeCategory}
                categories={allCategories}
              />
            </Fragment>
          )
        })
      }
      return (
        <div className={classes.category} key={category.id}>
          <Category
            checklist={checklist}
            category={category}
            onEdit={onEditCategory}
            editElement={onEdit}
            onDelete={onDeleteCategory}
            workers={workers}
            onCreate={onCreateEmpty}
          />
          <Divider />
          {content}
        </div>
      )

    })
  }

  async handleCreateCategory() {
    const { params: { category_name: name, users }, params } = this.state
    const { match: { params: { id: checklist_id } }, onCreateCategory } = this.props
    const body = { name, checklist_id, users }
    onCreateCategory(body)
    const newParams = { ...params, category_name: "", users: [] }
    this.setState({ openCreateCategory: false, params: newParams })
  }

  async handleEditCategory() {

  }

  async handleDeleteCategory() {

  }

  // handleEdit() {
  //   const { onEdit, match } = this.props
  //   const body = { ...newBody, checklist_id: match.params.id }
  //   return onEdit
  // }

  handleClose() {
    this.setState({ params: {}, open: false, openWarning: false, element: {} })
  }

  handleDelete() {
    const { onDelete, match } = this.props
    const { element } = this.state
    const body = { checklist_id: match.params.id, id: element.id }
    onDelete(body)
    this.handleClose()
  }

  render() {
    const { classes, workers } = this.props
    const { params, openWarning, element, openCreateCategory } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.mainTitle}>
          <Typography variant="h4">Elementos del registro</Typography>
          <Button color="primary" variant="outlined" onClick={this.handleOpenCreateCategory}>
            Agregar nueva categoría
          </Button>
        </div>
        <Collapse in={openCreateCategory}>
          <div className={classes.form}>
            <TextInput label="Nombre categoría" value={params.category_name} name="category_name" onChange={this.handleChange} />
            <MultiSelectInput options={transformToOptions(workers)} label="Usuarios responsables" value={params.users} name="users" onChange={this.handleChange} />
            <Button color="secondary" variant="contained" onClick={this.handleCreateCategory}>Crear</Button>
          </div>
        </Collapse>
        {this.renderElements()}
        <Dialog fullWidth maxWidth="sm" open={openWarning} onClose={this.handleClose}>
          <div className={classes.dialog}>
            <Typography variant="h1">¿Seguro que deseas eliminar este elemento?</Typography>
            <Typography variant="subtitle1">{element?.name}</Typography>
            <div className={classes.dialogButtons}>
              <Button color="primary" variant="contained" onClick={this.handleClose}>
                Cancelar
              </Button>
              <Button color="primary" variant="outlined" onClick={this.handleDelete}>
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withRouter(withStyles(style)(ChecklistsElements))