import {
    CalendarToday,
    Dashboard,
    Work,
    AssignmentTurnedIn,
    Settings,
    BarChart,
    AccountTreeOutlined,
    Assignment,
    RateReview,
} from "@material-ui/icons"

// const sideRoutes = [
//     { name: "Panel de Control", icon: Dashboard, link: "/panel" },
//     {
//         name: "Empresa",
//         icon: Work,
//         link: "/enterprise",
//         subroutes: [
//             { name: "Datos Empresa", link: "/enterprise" },
//             { name: "Documentos", link: "/enterprise/documents" },
//             { name: "Colaboradores", link: "/workers" },
//         ],
//     },
//     {
//         name: "Módulos",
//         icon: AssignmentTurnedIn,
//         link: "/risks",
//         subroutes: [
//             { name: "Planificación Actividades", link: "/activities" },
//             { name: "Registros y formularios", link: "/checklists" },
//             { name: "Hallazgos", link: "/findings" },
//             { name: "No Conformidades", link: "/unconformities" },
//             { name: "Charlas", link: "/talks" },
//             { name: "Capacitaciones", link: "/trainings" },
//             { name: "IPER", link: "/iper" },
//         ],
//     },
//     { name: "Calendario", icon: CalendarToday, link: "/calendar" },
//     {
//         name: "Configuración", icon: Settings, subroutes: [
//             { name: "Registros y formularios", link: "/settings/checklists" },
//             { name: "Actividades", link: "/settings/activities" },
//             { name: "Charlas", link: "/settings/talks" },
//             { name: "Capacitaciones", link: "/settings/trainings" },
//             { name: "Documentos Colaboradores", link: "/settings/users/documents" },
//             { name: "Documentos Empresa", link: "/settings/enterprise/documents" },
//         ]
//     },
//     { name: "Reportes", icon: BarChart, link: "/reports" },
// ]

const sideRoutes = {
    1: [
        { name: "Panel de Control", icon: Dashboard, link: "/panel" },
        {
            name: "Empresa",
            icon: Work,
            link: "/enterprise",
            subroutes: [
                { name: "Datos Empresa", link: "/enterprise" },
                { name: "Centros de trabajo", link: "/branches" },
                { name: "Documentos", link: "/enterprise/documents" },
                { name: "Colaboradores", link: "/workers" },
            ],
        },
        {
            name: "Módulos",
            icon: AssignmentTurnedIn,
            link: "/risks",
            subroutes: [
                { name: "Planificación Actividades", link: "/activities" },
                { name: "Registros y formularios", link: "/checklists" },
                { name: "Evaluaciones", link: '/evaluations' },
                { name: "Insumos y Maquinaria", link: "/supplies" },
                { name: "Hallazgos", link: "/findings" },
                { name: "No Conformidades", link: "/unconformities" },
                { name: "Charlas", link: "/talks" },
                { name: "Capacitaciones", link: "/trainings" },
                { name: "Procesos", link: "/workflows" },
                // { name: "IPER", link: "/iper" },
            ],
        },
        { name: "Programas de Gestión", icon: AccountTreeOutlined, link: "/programs" },
        {
            name: "Tareas", icon: Assignment, link: "", subroutes: [
                { name: "Solicitudes", icon: RateReview, link: '/users/signs' }
            ]
        },
        { name: "Calendario", icon: CalendarToday, link: "/calendar" },
        {
            name: "Configuración", icon: Settings, subroutes: [
                { name: "Registros y formularios", link: "/settings/checklists" },
                { name: "Evaluaciones", link: "/settings/evaluations" },
                { name: "Actividades", link: "/settings/activities" },
                { name: "Charlas", link: "/settings/talks" },
                { name: "Capacitaciones", link: "/settings/trainings" },
                { name: "Colaboradores", link: "/settings/users" },
                { name: "Insumos y Maquinaria", link: "/settings/supplies" },
                { name: "Documentos Colaboradores", link: "/settings/users/documents" },
                { name: "Documentos Empresa", link: "/settings/enterprise/documents" },
                { name: "Documentos Centros de Trabajo", link: "/settings/branch/documents" },
                { name: "Contratistas", link: "/settings/sub_branches" },
                { name: "Procesos", link: "/settings/processes" },
                { name: "Hallazgos", link: "/settings/findings" },
                { name: "No Conformidades", link: "/settings/nonconformities" },
            ]
        },
        {
            name: "Reportes", icon: BarChart, subroutes: [
                { name: "Hallazgos", link: "/reports/findings" },
                { name: "No Conformidades", link: "/reports/unconformities" },
                { name: "Actividades", link: "/reports/activities" },
                { name: "Procesos", link: "/reports/workflows" }

            ]
        },
    ],
    2: [
        { name: "Panel de Control", icon: Dashboard, link: "/panel" },
        {
            name: "Empresa",
            icon: Work,
            link: "/enterprise",
            subroutes: [
                { name: "Datos Empresa", link: "/enterprise" },
                { name: "Documentos", link: "/enterprise/documents" },
                { name: "Colaboradores", link: "/workers" },
            ],
        },
        {
            name: "Módulos",
            icon: AssignmentTurnedIn,
            link: "/risks",
            subroutes: [
                { name: "Registros y formularios", link: "/checklists" },
                { name: "Evaluaciones", link: '/evaluations' },
                { name: "Hallazgos", link: "/findings" },
                { name: "No Conformidades", link: "/unconformities" },
                { name: "Charlas", link: "/talks" },
                { name: "Capacitaciones", link: "/trainings" },
                { name: "Procesos", link: "/workflows" },
            ],
        },
        {
            name: "Tareas", icon: Assignment, link: "", subroutes: [
                { name: "Solicitudes", icon: RateReview, link: '/users/signs' }
            ]
        },
        { name: "Calendario", icon: CalendarToday, link: "/calendar" },
    ],
    3: [
        { name: "Panel de Control", icon: Dashboard, link: "/panel" },
        {
            name: "Empresa",
            icon: Work,
            link: "/enterprise",
            subroutes: [
                { name: "Documentos", link: "/enterprise/documents" },
            ],
        },
        {
            name: "Módulos",
            icon: AssignmentTurnedIn,
            link: "/risks",
            subroutes: [
                { name: "Registros y formularios", link: "/checklists" },
                { name: "Evaluaciones", link: '/evaluations' },
                { name: "Hallazgos", link: "/findings" },
                { name: "No Conformidades", link: "/unconformities" },
                { name: "Charlas", link: "/talks" },
                { name: "Capacitaciones", link: "/trainings" },
            ],
        },
        {
            name: "Tareas", icon: Assignment, link: "", subroutes: [
                { name: "Solicitudes", icon: RateReview, link: '/users/signs' }
            ]
        },
        { name: "Calendario", icon: CalendarToday, link: "/calendar" },
    ],
    4: [
        { name: "Panel de Control", icon: Dashboard, link: "/panel" },
        {
            name: "Módulos",
            icon: AssignmentTurnedIn,
            link: "/risks",
            subroutes: [
                { name: "Registros y formularios", link: "/checklists" },
                { name: "Evaluaciones", link: '/evaluations' },
                { name: "Hallazgos", link: "/findings" },
                { name: "No Conformidades", link: "/unconformities" },
                { name: "Charlas", link: "/talks" },
                { name: "Capacitaciones", link: "/trainings" },
            ],
        },
        {
            name: "Tareas", icon: Assignment, link: "", subroutes: [
                { name: "Solicitudes", icon: RateReview, link: '/users/signs' }
            ]
        },
        { name: "Calendario", icon: CalendarToday, link: "/calendar" },
    ]
}

export default sideRoutes
