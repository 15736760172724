const SettingsReducer = (state = {
  checklists: { all: [] },
  activities: { all: [] },
  talks: { all: [] },
  trainings: { all: [] },
  user_documents: { all: [] },
  base_documents: { all: [] },
  sub_branch_documents: { all: [] },
  branch_documents: { all: [] },
  epps_categories: { all: [] },
  requirements_categories: { all: [] },
  types: { all: [] },
  areas: { all: [] },
  lists: { all: [] },
  workflows: { all: [] }
}, action) => {
  switch (action.type) {
    // ------ Checklists ------- //
    case "GET_SETTINGS_CHECKLISTS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.checklists.all = action.payload.data.info
      }
      return newState
    }
    case "CREATE_SETTINGS_CHECKLIST": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newChecklist = action.payload.data.info
        newState.checklists.all.push(newChecklist)
      }
      return newState
    }
    case "EDIT_SETTINGS_CHECKLIST": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const editedChecklist = action.payload.data.info
        const oldIndex = newState.checklists.all.findIndex(cl => cl.id === editedChecklist.id)
        newState.checklists.all[oldIndex] = editedChecklist
      }
      return newState
    }
    case "SELECT_SETTINGS_CHECKLIST": {
      const newState = { ...state }
      const selectedChecklistId = action.payload
      const selectedChecklist = newState.checklists.all.find(cl => cl.id === selectedChecklistId)
      newState.checklists.selected = selectedChecklist
      return newState
    }
    case "CREATE_CHECKLIST_ITEM": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newSelectedItem = action.payload.data.info
        const index = newState.checklists.all.findIndex(cl => cl.id === newSelectedItem.id)
        newState.checklists.selected = newSelectedItem
        newState.checklists.all[index] = newSelectedItem
      }
      return newState
    }
    case "UPDATE_SETTING_EXTRA_FIELDS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newSelectedItem = action.payload.data.info
        newState.checklists.selected = newSelectedItem
      }
      return newState
    }
    // ------ Activities ------- //
    case "GET_SETTINGS_ACTIVITIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.activities = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_SETTINGS_ACTIVITY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newActivity = action.payload.data.info[0]
        newState.activities.all.push(newActivity)
      }
      return newState
    }
    case "EDIT_SETTINGS_ACTIVITY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const editedActivities = action.payload.data.info[0]
        const oldIndex = newState.activities.all.findIndex(cl => cl.id === editedActivities.id)
        newState.activities.all[oldIndex] = editedActivities
      }
      return newState
    }
    // ------ Talks ------- //
    case "GET_SETTINGS_TALKS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.talks.all = action.payload.data.info
      }
      return newState
    }
    case "CREATE_SETTINGS_TALK": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.talks.all.push(newTalk)
      }
      return newState
    }
    case "EDIT_SETTINGS_TALK": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const editedTalk = action.payload.data.info
        const oldIndex = newState.talks.all.findIndex(talk => talk.id === editedTalk.id)
        newState.talks.all[oldIndex] = editedTalk
      }
      return newState
    }
    case "GET_TALKS_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.talks.categories = action.payload.data.info
      }
      return newState

    }
    case "CREATE_TALKS_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.talks.categories = action.payload.data.info
      }
      return newState

    }
    case "DELETE_TALKS_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.talks.categories = action.payload.data.info
      }
      return newState

    }
    case "EDIT_TALKS_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.talks.categories = action.payload.data.info
      }
      return newState
    }

    // ------ Trainings ------- //
    case "GET_SETTINGS_TRAININGS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.trainings.all = action.payload.data.info
      }
      return newState
    }
    case "CREATE_SETTINGS_TRAINING": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.trainings.all.push(newTalk)
      }
      return newState
    }
    case "EDIT_SETTINGS_TRAINING": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const editedTalk = action.payload.data.info
        const oldIndex = newState.trainings.all.findIndex(talk => talk.id === editedTalk.id)
        newState.trainings.all[oldIndex] = editedTalk
      }
      return newState
    }
    case "GET_TRAININGS_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.trainings.categories = action.payload.data.info
      }
      return newState

    }
    case "CREATE_TRAININGS_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.trainings.categories = action.payload.data.info
      }
      return newState

    }
    case "DELETE_TRAININGS_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.trainings.categories = action.payload.data.info
      }
      return newState

    }
    case "EDIT_TRAININGS_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.trainings.categories = action.payload.data.info
      }
      return newState
    }

    // ------ User Documents ------- //
    case "GET_SETTINGS_USER_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.user_documents = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_SETTINGS_USER_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.user_documents.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_SETTINGS_USER_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.user_documents.all = action.payload.data.info
      }
      return newState
    }
    case "DELETE_SETTINGS_USER_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.user_documents.all = action.payload.data.info
      }
      return newState
    }
    case "CREATE_SETTINGS_USER_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newCategory = action.payload.data.info[0]
        const indexOfCat = newState.user_documents.all.findIndex(category => category.id === newCategory.id)
        newState.user_documents.all[indexOfCat] = newCategory
      }
      return newState
    }
    case "EDIT_SETTINGS_USER_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newCategory = action.payload.data.info[0]
        const indexOfCat = newState.user_documents.all.findIndex(category => category.id === newCategory.id)
        newState.user_documents.all[indexOfCat] = newCategory
      }
      return newState
    }
    case "DELETE_SETTINGS_USER_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.user_documents.all = action.payload.data.info
      }
      return newState
    }
    case "CREATE_SETTINGS_USER_SUB_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newSubCategory = action.payload.data.info[0]
        const category = newState.user_documents.all.find(cat => {
          return cat.subcategories.find(subcategory => subcategory.id === newSubCategory.id) !== undefined
        })
        const indexOfCat = newState.user_documents.all.findIndex(cat => cat.id === category.id)
        const indexOfSubCat = category.subcategories.findIndex(sub => sub.id === newSubCategory.id)
        newState.user_documents.all[indexOfCat].subcategories[indexOfSubCat] = newSubCategory
      }
      return newState
    }
    case "EDIT_SETTINGS_USER_SUB_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newSubCategory = action.payload.data.info[0]
        const category = newState.user_documents.all.find(cat => {
          return cat.subcategories.find(subcategory => subcategory.id === newSubCategory.id) !== undefined
        })
        const indexOfCat = newState.user_documents.all.findIndex(cat => cat.id === category.id)
        const indexOfSubCat = category.subcategories.findIndex(sub => sub.id === newSubCategory.id)
        newState.user_documents.all[indexOfCat].subcategories[indexOfSubCat] = newSubCategory
      }
      return newState
    }
    case "DELETE_SETTINGS_USER_SUB_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const catIndex = newState.user_documents.all.findIndex(cat => cat.id === action.meta.id)
        newState.user_documents.all[catIndex].subcategories = action.payload.data.info
      }
      return newState
    }

    // ------ Enterprise Documents ------- //
    case "GET_SETTINGS_ENTERPRISE_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.base_documents = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_SETTINGS_ENTERPRISE_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.base_documents.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_SETTINGS_ENTERPRISE_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.base_documents.all = action.payload.data.info
      }
      return newState
    }
    case "DELETE_SETTINGS_ENTERPRISE_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.base_documents.all = action.payload.data.info
      }
      return newState
    }

    // ------ Sub Branch Documents ------- //
    case "GET_SETTINGS_SUB_BRANCH_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.sub_branch_documents = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_SETTINGS_SUB_BRANCH_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.sub_branch_documents.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_SETTINGS_SUB_BRANCH_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.sub_branch_documents.all = action.payload.data.info
      }
      return newState
    }
    case "DELETE_SETTINGS_SUB_BRANCH_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.sub_branch_documents.all = action.payload.data.info
      }
      return newState
    }
    case "CREATE_SETTINGS_SUB_BRANCH_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newCategory = action.payload.data.info[0]
        const indexOfCat = newState.sub_branch_documents.all.findIndex(category => category.id === newCategory.id)
        newState.sub_branch_documents.all[indexOfCat] = newCategory
      }
      return newState
    }
    case "EDIT_SETTINGS_SUB_BRANCH_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newCategory = action.payload.data.info[0]
        const indexOfCat = newState.sub_branch_documents.all.findIndex(category => category.id === newCategory.id)
        newState.sub_branch_documents.all[indexOfCat] = newCategory
      }
      return newState
    }
    case "DELETE_SETTINGS_SUB_BRANCH_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.sub_branch_documents.all = action.payload.data.info
      }
      return newState
    }


    // ------ Branch Documents ------- //
    case "GET_SETTINGS_BRANCH_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.branch_documents = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_SETTINGS_BRANCH_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.branch_documents.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_SETTINGS_BRANCH_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.branch_documents.all = action.payload.data.info
      }
      return newState
    }
    case "DELETE_SETTINGS_BRANCH_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.branch_documents.all = action.payload.data.info
      }
      return newState
    }
    case "CREATE_SETTINGS_BRANCH_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newCategory = action.payload.data.info[0]
        const indexOfCat = newState.branch_documents.all.findIndex(category => category.id === newCategory.id)
        newState.branch_documents.all[indexOfCat] = newCategory
      }
      return newState
    }
    case "EDIT_SETTINGS_BRANCH_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newCategory = action.payload.data.info[0]
        const indexOfCat = newState.branch_documents.all.findIndex(category => category.id === newCategory.id)
        newState.branch_documents.all[indexOfCat] = newCategory
      }
      return newState
    }
    case "DELETE_SETTINGS_BRANCH_SUBCATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.branch_documents.all = action.payload.data.info
      }
      return newState
    }


    // ------ Permissions Epps ------- //
    case "GET_PERMISSION_EPP_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.epps_categories = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_PERMISSION_EPP_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.epps_categories.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_PERMISSION_EPP_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.epps_categories.all = action.payload.data.info
      }
      return newState
    }
    case "DELETE_PERMISSION_EPP_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.epps_categories.all = action.payload.data.info
      }
      return newState
    }

    // ------ Permissions Requirements ------- //
    case "GET_PERMISSION_REQUIREMENT_CATEGORIES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.requirements_categories = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_PERMISSION_REQUIREMENT_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.requirements_categories.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_PERMISSION_REQUIREMENT_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.requirements_categories.all = action.payload.data.info
      }
      return newState
    }
    case "DELETE_PERMISSION_REQUIREMENT_CATEGORY": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.requirements_categories.all = action.payload.data.info
      }
      return newState
    }

    // ------ Permissions Areas ------- //
    case "GET_PERMISSION_AREAS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.areas = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_PERMISSION_AREA": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.areas.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_PERMISSION_AREA": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.areas.all = action.payload.data.info
      }
      return newState
    }


    // ------ Permissions Types ------- //
    case "GET_PERMISSION_TYPES": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.types = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_PERMISSION_TYPE": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.types.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_PERMISSION_TYPE": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.types.all = action.payload.data.info
      }
      return newState
    }

    // ------ Lists ------- //
    case "GET_SETTINGS_LISTS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.lists = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_SETTINGS_LIST": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.lists.all = action.payload.data.info
      }
      return newState
    }
    case "EDIT_SETTINGS_LIST": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.lists.all = action.payload.data.info
      }
      return newState
    }

    // ------ WorkFlows ------- //

    case "GET_SETTINGS_WORKFLOWS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.workflows = { all: action.payload.data.info }
      }
      return newState
    }
    case "CREATE_SETTINGS_WORKFLOW": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.workflows.all.push(action.payload.data.info)
      }
      return newState
    }
    case "EDIT_SETTINGS_WORKFLOW": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const index = newState.workflows.all.findIndex(e => e.id === action.payload.data.info.id)
        newState.workflows.all[index] = action.payload.data.info
      }
      return newState
    }
    case "EDIT_WORKFLOW_STATE": {
      const newState = { ...state }
      if (action.payload.data.status !== "success") return newState
      const index = newState.workflows.all.findIndex(element => element.id === action.payload.data.info.id)
      newState.workflows.all[index] = action.payload.data.info
      return newState
    }
    case "CREATE_WORKFLOW_STATE": {
      const newState = { ...state }
      if (action.payload.data.status !== "success") return newState
      const index = newState.workflows.all.findIndex(element => element.id === action.payload.data.info.id)
      newState.workflows.all[index] = action.payload.data.info
      return newState
    }

    default: {
      return state
    }
  }
}

export default SettingsReducer