import React, { Component } from 'react'
import { Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import autobind from '../../../Utils/autobind'
import { Send, SendOutlined } from '@material-ui/icons'
import { connect } from 'react-redux'
import moment from 'moment'
import TextInput from '../../../Shared/Inputs/TextInput'
import { sendMail } from '../../../API/workflows'

const style = () => ({
	container: {
		padding: 12,
		margin: '12px 0',
		background: 'white',
		borderRadius: 8
	},
	newComment: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: 12
	}
})
class Comments extends Component {
	constructor() {
		super()
		this.state = {
			comment: "",
			motive: "",
		}
		autobind(Comments, this)
	}

	handleChange(event) {
		const { target } = event
		this.setState({ [target.name]: target.value })
	}

	handleCreateComment() {
		const { user: { account: { user } }, onSubmit, responsible, checklist } = this.props
		const { comment, motive } = this.state
		const { metadata } = this.props

		const meta = metadata ? metadata : {}

		const comments = Array.isArray(meta?.comments) ? [...meta?.comments] : []

		const commentBody = {
			user: user.name,
			user_id: user.id,
			motive: motive,
			body: comment,
			date: moment().format("YYYY-MM-DD HH:mm")
		}

		comments.push(commentBody)
		meta.comments = comments
		onSubmit(meta)
		const body = {
			subject: `Nuevo comentario en ${checklist?.activity} - ${checklist?.preventive_measure}`,
			content: `
			<strong>${responsible.name}</strong>
			<p>Se ha agregado un comentario a uno de tus registros</p>
			<p>Asunto: <strong>${commentBody.motive || "Sin asunto"}</strong></p>
			<p>Comentario: <strong>${commentBody.body}</strong></p>
			<p>Puedes revisar el registro en el siguiente enlace: ${window.location.href}</p>
			`,
			emails: [responsible.email],
			// emails: ["mati.eynaudi@gmail.com"],
			extra_mails: []
		}
		sendMail(body)
		this.setState({ comment: "", motive: "" })
	}

	renderComments() {
		const { metadata, classes } = this.props
		const comments = metadata?.comments || []
		return comments.map((comment, index) => {
			return (
				<div className={classes.comment}>
					{index > 0 && <Divider style={{ margin: '12px 0' }} />}
					<Typography variant='subtitle1'>{comment.motive}</Typography>
					<Typography variant='subtitle1' color="primary">{comment.body}</Typography>
					<Typography variant='caption'>{comment.date} - {comment.user}</Typography>
				</div>
			)
		})

	}

	render() {
		const { comment, motive } = this.state
		const { classes } = this.props
		return (
			<div className={classes.container}>
				<Typography variant='h4'>Agregar comentarios</Typography>
				<div className={classes.newComment}>
					<div style={{ width: 400 }}>
						<TextInput name="motive" label="Asunto" onChange={this.handleChange} value={motive} />
						<SeamlessInput name="comment" width="400px" value={comment} onChange={this.handleChange} />
					</div>
					<IconButton size="small" onClick={this.handleCreateComment} disabled={!comment}>
						<Send />
					</IconButton>
				</div>
				<div className={classes.comments}>
					{this.renderComments()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(Comments))