import stateMaker from "../Utils/stateMaker"
const NonConformityReducer = (state = { selected: {}, all: [] }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case "GET_NON_CONFORMITIES":
      return stateMaker(newState, "all", "fetch", action)
    case "CREATE_NON_CONFORMITY": {
      const newNonConformity = action.payload.data.info[0]
      newState.all.push(newNonConformity)
      return newState
    }
    case "ASSIGN_NON_CONFORMITY": {
      const newAll = newState.all
      const selectedNonConformity = newAll.find(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      const selectedNonConformityIndex = newAll.findIndex(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      selectedNonConformity.user = action.payload.user
      selectedNonConformity.date_limit = action.payload.date_limit
      selectedNonConformity.date_assigned = action.payload.date_assigned
      selectedNonConformity.inmediate_action = action.payload.inmediate_action
      selectedNonConformity.status = "Procesando"
      newAll[selectedNonConformityIndex] = selectedNonConformity
      newState.all = newAll
      newState.selected = selectedNonConformity
      return newState
    }

    case "SOLVE_NON_CONFORMITY": {
      const newAll = newState.all
      const selectedNonConformity = newAll.find(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      const selectedNonConformityIndex = newAll.findIndex(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      selectedNonConformity.status = "Terminado"
      selectedNonConformity.solution_comment = action.payload.solution_comment
      selectedNonConformity.solution_evidence = action.payload.base64
      selectedNonConformity.date_solved = action.payload.date_solved
      newAll[selectedNonConformityIndex] = selectedNonConformity
      newState.all = newAll
      newState.selected = selectedNonConformity
      return newState
    }
    case "APPROVE_NON_CONFORMITY": {
      const newAll = newState.all
      const selectedNonConformity = newAll.find(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      const selectedNonConformityIndex = newAll.findIndex(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      selectedNonConformity.status = "Aprobado"
      selectedNonConformity.date_approved = action.payload.date_approved
      newAll[selectedNonConformityIndex] = selectedNonConformity
      newState.all = newAll
      newState.selected = selectedNonConformity
      return newState
    }
    case "DISMISS_NON_CONFORMITY": {
      const newAll = newState.all
      const selectedNonConformity = newAll.find(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      const selectedNonConformityIndex = newAll.findIndex(nonConformity => nonConformity.id.toString() === action.payload.id.toString())
      selectedNonConformity.status = "Desestimado"
      selectedNonConformity.date_approved = action.payload.date_approved
      newAll[selectedNonConformityIndex] = selectedNonConformity
      newState.all = newAll
      newState.selected = selectedNonConformity
      return newState
    }
    case "SELECT_NON_CONFORMITY": {
      const id = action.payload
      const newSelected = newState.all.find(nonConformity => nonConformity.id.toString() === id)
      newState.selected = newSelected
      return newState
    }
    default: {
      return state
    }
  }
}

export default NonConformityReducer
