import instance from "../../../Utils/instance"

export function getProgress(checklist) {
  const { metadata } = checklist
  const disable_categories = metadata?.disable_categories || []
  const checklistElements = checklist?.checklist_items || []
  if (checklistElements.length === 0) return 100
  const filterDisabled = checklistElements.filter(element => !disable_categories.includes(element?.subtitle_id))
  if (filterDisabled.length === 0) return 100
  const total = filterDisabled.length
  let added = 0
  filterDisabled.forEach(element => {
    if (element.answer.value_id !== null) {
      added++
    }
  })
  return Math.round(added / total * 100, 10)
}

export function getCompliance(checklist, partial = false) {
  const checklistElements = checklist?.checklist_items || []
  const complianceExtraFields = checklist?.extra_fields?.filter(field => field.type === "compliance") || []
  if (checklistElements.length === 0) return 100
  let total = 0
  let added = 0
  checklistElements.forEach(element => {
    if (element.answer.value_id !== null && element.answer.value_id !== 3) {
      total += element.points
      if (element.answer.value_id === 1) {
        added += element.points
      }
    }
  })

  const itemsCompliance = total > 0 ? (added / total * 100) : 0
  const itemsAmount = total

  let fields = 0
  let fieldsAmount = 0
  complianceExtraFields.forEach(field => {
    if (!field.value) return null
    fieldsAmount += 1
    const values = JSON.parse(field.value)
    const des = values.desfav || 0
    let fieldCompliance = 100
    if (values.solved && !partial) {
      fieldCompliance = 100
    } else {
      fieldCompliance = values.total > 0 ? (100 - ((des * 100) / values.total)) : 100
    }
    fields += fieldCompliance
  })

  const fieldsCompliance = fieldsAmount > 0 ? (fields / fieldsAmount) : 0
  const totalAmount = fieldsAmount + itemsAmount
  const result = totalAmount > 0 ? (itemsAmount / totalAmount) * itemsCompliance + (fieldsAmount / totalAmount) * fieldsCompliance : 0
  return (result).toFixed(1)
}

export function getPoints(checklist) {
  const checklistElements = checklist?.checklist_items || []
  if (checklistElements.length === 0) return { total: 0, added: 0 }
  let total = 0
  let added = 0
  checklistElements.forEach(element => {
    if (element.answer.value_id !== null && element.answer.value_id !== 3) {
      total += element.points
      if (element.answer.value_id === 1) {
        added += element.points
      }
    }
  })
  return { total, added }
}

export function checkIfExtraIsFinished(selected, extra, extended) {
  const fields = selected?.extra_fields || []

  const boolList = fields.concat(extended)
    .map((field, index) => {
      if (!field.required) return true
      const value = extra[index]
      return !!value
    })
  return boolList.every(Boolean)
}

export function newCheckForExtraFinished(extrafields = []) {
  if (!extrafields) return true
  const boolList = extrafields
    .map((field, index) => {
      const options = field?.options || []
      const subquestions = options.map(option => option.label === field?.value ? option.subquestions || [] : []).flat()
      if (!field.required) return true && newCheckForExtraFinished(subquestions)
      const value = extrafields[index].value
      if (field.type === "compliance") {
        const values = value ? JSON.parse(value) : {}
        const des = values.desfav || 0
        const compliance = values.total > 0 ? 100 - ((des * 100) / values.total) : 100
        if (compliance < 100) {
          return !!values.total && !!values.desfav && !!values.comment
        } else {
          return !!values.total && !!values.desfav
        }
      }
      return !!value
    })
  return boolList.every(Boolean)
}

export function simulateClick(element, index) {
  return () => {
    const input = document.getElementById(`fileInput${index}-${element.id}`)
    input.click()
  }
}

export function gotoTop() {
  (function smoothscroll() {
    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    if (currentScroll > 0) {
      window.requestAnimationFrame(smoothscroll)
      window.scrollTo(0, currentScroll - (currentScroll / 5))
    }
  })()
}

export function handleScroll(e) {
  // Get the new Value
  const newValue = window.pageYOffset
  const element = document.getElementById("fab-container")
  if (!element) return null
  //Subtract the two and conclude
  if (this.oldValue - newValue < 0) {
    element.style.right = "12px"
  } else if (this.oldValue - newValue > 0) {
    element.style.right = "-64px"
  }

  // Update the old value
  this.oldValue = newValue
  clearTimeout(this.timer)
  this.timer = setTimeout(() => element.style.right = "-64px", 2000)
}

export const createSignGroup = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'checklists/create_group_sign.php',
    data: body
  })
  return REQUEST
}

export const editSignGroup = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'checklists/edit_group_sign.php',
    data: body
  })
  return REQUEST
}

export const addSignToGroup = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'checklists/user_sign.php',
    data: body
  })
  return REQUEST
}