import { Send } from '@material-ui/icons'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import SeamlessInput from '../../Shared/Inputs/SeamlessInput'
import TextInput from '../../Shared/Inputs/TextInput'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		background: 'white',
		padding: 2,
		borderRadius: 2,
		maxWidth: 800
	},
	new: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 2
	}
}

function Comments({ value }) {

	const [params, setParams] = useState({})
	const { checklist, responsible, current_user, setMetadata } = useContext(ChecklistContext)

	function onChange(event) {
		const { target } = event
		setParams(p => ({ ...p, [target.name]: target.value }))
	}

	function onCreate() {
		const { comment, motive } = params

		const meta = checklist.metadata ? checklist.metadata : {}

		const comments = Array.isArray(meta?.comments) ? [...meta?.comments] : []

		const commentBody = {
			user: current_user.name,
			user_id: current_user.id,
			motive: motive,
			body: comment,
			date: moment().format("YYYY-MM-DD HH:mm")
		}

		comments.push(commentBody)
		meta.comments = comments
		setMetadata(meta)
		// const body = {
		// 	subject: `Nuevo comentario en ${checklist?.activity} - ${checklist?.preventive_measure}`,
		// 	content: `
		// 	<strong>${responsible.name}</strong>
		// 	<p>Se ha agregado un comentario a uno de tus registros</p>
		// 	<p>Asunto: <strong>${commentBody.motive || "Sin asunto"}</strong></p>
		// 	<p>Comentario: <strong>${commentBody.body}</strong></p>
		// 	<p>Puedes revisar el registro en el siguiente enlace: ${window.location.href}</p>
		// 	`,
		// 	emails: [responsible.email],
		// 	// emails: ["mati.eynaudi@gmail.com"],
		// 	extra_mails: []
		// }
		// sendMail(body)
		setParams({ comment: "", motive: "" })
	}

	const { comment, motive } = params

	function renderComments() {
		const metadata = checklist.metadata || {}
		const comments = metadata?.comments || []
		return comments.map((comment, index) => {
			return (
				<Box className={css.comment}>
					{index > 0 && <Divider style={{ margin: '12px 0' }} />}
					<Typography variant='subtitle1'>{comment.motive}</Typography>
					<Typography variant='subtitle1' color="primary">{comment.body}</Typography>
					<Typography variant='caption'>{comment.date} - {comment.user}</Typography>
				</Box>
			)
		})
	}

	return (
		<Box sx={css.container}>
			<Typography variant='h4' style={{ marginBottom: 12 }}>Agregar comentarios</Typography>

			<TextInput name="motive" label="Asunto" onChange={onChange} value={motive} />
			<Box sx={css.new}>
				<SeamlessInput name="comment" width="400px" value={comment} onChange={onChange} />
				<IconButton size="small" onClick={onCreate} disabled={!comment}>
					<Send />
				</IconButton>
			</Box>
			{renderComments()}
		</Box>
	)
}

export default Comments