export function transformChecklistToPendings(elements) {
	return elements.map(element => {
		return ({
			name: "Registro o Formulario",
			link: `/checklists2/${element.id}`,
			content: <>{element.activity}<br /><p style={{ fontSize: '0.8rem', color: 'grey', marginTop: -3 }}>{element.preventive_measure}</p></>
			,
			date: element.date_scheduled,
			info: element
		})
	})
}

export function transformFindingsToPendings(elements, user_id) {
	return elements.map(element => {
		const isSameCreator = element.user_creator_id?.toString() === user_id.toString()
		const isSameUser = element.user_id?.toString() === user_id.toString()
		const isMine = isSameCreator && !isSameUser
		const isReadyForApprobal = element.status === "Terminado"
		return (
			{
				name: `Hallazgo ${isMine ? "detectado por tí" : ""} ${isReadyForApprobal ? "listo para ser aprobado" : ""}`,
				link: `/findings`,
				content: `${element.checklist_item_name || element.free_description}`,
				date: element.date_limit
			}
		)
	})
}

export function transformTalksToPendings(elements) {
	return elements.map(element => ({
		name: "Charla",
		link: `/talks/${element.id}`,
		content: `${element.name}`,
		date: element.date_scheduled
	}))
}

export function transformTrainingsToPendings(elements) {
	return elements.map(element => ({
		name: "Capacitación",
		link: `/trainings/${element.id}`,
		content: `${element.name}`,
		date: element.date_scheduled
	}))
}

export function transformDocumentsToPendings(elements) {
	return elements.map(element => ({
		name: "Solicitud de firma",
		link: `/users/signs?id=${document.id}`,
		content: `${document.name}`,
		date: document.date
	}))
}

export function transformEvaluationsToPendings(elements) {
	return elements.map(element => ({
		name: "Evaluación",
		link: `/evaluations/${element.id}`,
		content: `${element.name}`,
		date: element.created_at
	}))
}

export function transformNonConformitiesToPendings(elements) {
	return elements.map(element => ({
		name: "No Conformidad",
		link: `/unconformities`,
		content: `${element.name}`,
		date: element.date_limit
	}))
}