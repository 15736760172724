import React, { Component } from 'react'
import { Button, Collapse, Dialog, Divider, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../Shared/Inputs/TextInput'
import GeneralTable from '../../../Shared/GeneralTable'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import autobind from '../../../Utils/autobind'
import { createSettingsTrainingAction, createTrainingsCategoryAction, deleteTrainingsCategoryAction, editSettingsTrainingAction, editTrainingsCategoryAction, getSettingsTrainingsAction, getTrainingsCategoriesAction } from '../../../Actions/SettingsActions'
import { connect } from 'react-redux'
import { Add, Backup, Delete, Edit } from '@material-ui/icons'
import { formatDownloadableDocument, transformToOptions } from '../../../Utils/functions'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import MyUploadAdapter from '../../Talks/MyUploader'

const style = theme => ({
  container: {
    padding: 12,
  },
  card: {

  },
  cardTitle: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > h1": {
      color: 'white'
    }
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    flexWrap: 'wrap'
  },
  input: {
    flexBasis: 350,
    flexGrow: 1,
    marginRight: 24,
    width: "100%",
    "@media (max-width:500px)": {
      marginRight: 0
    },
  },
  title: {
    marginBottom: 24
  },
  table: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > h1": {
      color: 'white'
    },
    marginTop: 36,
    marginBottom: 24,
  },
  dialogInput: {
    padding: 24
  },
  dialogAction: {
    textAlign: 'end',
    padding: 12
  },
  openButton: {
    marginTop: 12,
    textAlign: 'end',
    padding: 12
  },
  inline: {
    display: 'flex',
    alignItems: 'center'
  },
  hidden: {
    display: 'none'
  },
  inlineSep: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sideButton: {
    padding: 15,
    marginLeft: 6
  },
  category: {
    padding: "6px 12px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttons: {
    display: 'flex',
    '& > *': {
      marginLeft: 6
    }
  },
  editCategory: {
    padding: "6px 12px",
  },
  submitButton: {
    textAlign: 'end'
  },
  submitEditButton: {
    textAlign: 'end',
    padding: 12
  },
  loaderContainer: {
    position: 'relative',
    height: 300
  },
  rte: {
    padding: 24
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '6px 0'
    }
  }
})

const baseUrl = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER}`

const tableInfo = [
  { name: "Nombre", label: "name" },
  { name: "Categoría", label: "category_name" },
  { name: "Documento", label: "file", format: formatDownloadableDocument(baseUrl) }
]

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

function simulateClick(id) {
  return () => {
    document.getElementById(id).click()
  }
}

class TrainingSettings extends Component {
  constructor() {
    super()
    this.state = {
      openMenu: false,
      params: {},
      editCategory: -1,
      selectedTraining: {},
      openEdit: false,
      loading: true,
      content: "",
      content_length: 0,
      edit_content_length: 0
    }

    autobind(TrainingSettings, this)
  }

  componentDidMount() {
    const { getSettingsTrainings, getTrainingsCategories } = this.props
    getSettingsTrainings().then(() => this.setState({ loading: false }))
    getTrainingsCategories()
  }

  handleOpenMenu() {
    this.setState({ openMenu: !this.state.openMenu })
  }

  handleCreateCategory() {
    const { params } = this.state
    const { category } = params
    const { createTrainingsCategory } = this.props
    const body = {
      name: category
    }
    createTrainingsCategory(body)
    this.setState({ openMenu: false, category: "" })

  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeContent(event, editor) {
    const data = editor.getData()
    var div = document.createElement('div')
    div.innerHTML = data
    var text = div.textContent
    if (text.length > 600) {
      this.setState({ tooLong: true, content_length: text.length })
    }
    this.setState({ content: data, content_length: text.length })
  }

  handleChangeEditContent(event, editor) {
    const data = editor.getData()
    var div = document.createElement('div')
    div.innerHTML = data
    var text = div.textContent
    if (text.length > 600) {
      this.setState({ tooLong: true, edit_content_length: text.length })
    }
    const { params } = this.state
    this.setState({ params: { ...params, new_content: data }, edit_content_length: text.length })
  }

  handleSelectFile(event) {
    const { target } = event
    const { params } = this.state
    params.file = target.files[0]
    params.fileName = target.files[0].name
    this.setState({ params })
  }

  handleSelectNewFile(event) {
    const { target } = event
    const { params } = this.state
    params.new_file = target.files[0]
    params.new_fileName = target.files[0].name
    this.setState({ params })
  }

  handleCreateTraining() {
    const { params, content } = this.state
    const { createSettingsTraining } = this.props
    const body = new FormData()
    body.append("file", params.file)
    body.append("category_id", params.category_id)
    body.append("name", params.name)
    body.append("content", content)
    body.append("video", params.video)
    this.setState({ params: {}, shownWarning: false, tooLong: false })
    createSettingsTraining(body)
  }

  handleSendEditTraining() {
    const { params, selectedTraining } = this.state
    const { editSettingsTraining } = this.props
    const body = new FormData()
    body.append("file", params.new_file)
    body.append("category_id", params.new_category_id)
    body.append("name", params.new_name)
    body.append("id", selectedTraining.id)
    body.append("content", params.new_content)
    body.append("video", params.new_video)
    editSettingsTraining(body)
    this.setState({ openEdit: false, shownWarning: false, tooLong: false })
  }

  handleDeleteCategory(category) {
    return () => {
      const body = { id: category.id }
      const { deleteTrainingsCategory } = this.props
      deleteTrainingsCategory(body)
    }
  }

  handleEditCategory(index, category) {
    return () => {
      const { params, editCategory } = this.state
      let newIndex = index
      if (editCategory === index) {
        newIndex = -1
      }
      this.setState({ editCategory: newIndex, params: { ...params, newCategoryName: category.name, editCategoryId: category.id } })
    }
  }

  handleEditTraining(row) {
    return () => {
      const { params } = this.state
      var div = document.createElement('div')
      div.innerHTML = row.content
      var text = div.textContent
      this.setState({ edit_content_length: text.length, tooLong: false, shownWarning: false, openEdit: true, selectedTraining: row, params: { ...params, new_name: row.name, new_category_id: row.category_id, new_fileName: row.file, new_content: row.content, new_video: row.video } })
    }
  }

  handleCloseDialog() {
    this.setState({ edit_content_length: 0, openEdit: false, shownWarning: false })
  }

  handleCloseWarning() {
    this.setState({ tooLong: false, shownWarning: true })
  }

  handleSendEditCategory() {
    const { params } = this.state
    const { editTrainingsCategory } = this.props
    const body = {
      id: params.editCategoryId,
      name: params.newCategoryName
    }
    editTrainingsCategory(body)
    this.setState({ editCategory: -1 })
  }

  renderCategoriesDialog() {
    const { settings, classes } = this.props
    const { editCategory, params } = this.state
    const categories = settings.trainings.categories || []
    return categories.map((category, index) => {
      return (
        <>
          <div className={classes.category}>
            <Typography variant="subtitle2">{category.name}</Typography>
            <div className={classes.buttons}>
              <IconButton onClick={this.handleEditCategory(index, category)}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleDeleteCategory(category)}>
                <Delete />
              </IconButton>
            </div>
          </div>
          <Collapse in={editCategory === index}>
            <div className={classes.editCategory}>
              <TextInput label="Nuevo Nombre" name="newCategoryName" value={params.newCategoryName} onChange={this.handleChange} />
              <div className={classes.submitButton} onClick={this.handleSendEditCategory}>
                <Button size="small" color="primary" variant="outlined">Guardar</Button>
              </div>
            </div>
          </Collapse>
          <Divider />
        </>
      )
    })
  }

  render() {
    const { classes, settings } = this.props
    const { openMenu, params, openEdit, loading, content, tooLong, shownWarning, content_length, edit_content_length } = this.state
    const trainings = settings.trainings.all || []
    const categories = settings.trainings.categories || []
    const actions = [
      { name: "Editar Capacitación", icon: Edit, action: this.handleEditTraining }
    ]
    return (
      <div className={classes.container}>
        <Typography variant="h1" className={classes.title}>Configuración Capacitaciones</Typography>
        <Paper className={classes.card} square>
          <div className={classes.cardTitle}>
            <Typography variant="h1">Crear Capacitación</Typography>
          </div>
          <div className={classes.cardContent}>
            <div className={classes.input} >
              <TextInput label="Nombre" name="name" value={params.name} onChange={this.handleChange} />
            </div>
            <div className={classes.input} >
              <div className={classes.inline}>
                <SelectInput
                  label="Categoría"
                  placeholder="Ingresar Categoría"
                  name="category_id"
                  value={params.category_id} onChange={this.handleChange} options={transformToOptions(categories)} />
                <Button color="primary" onClick={this.handleOpenMenu} variant="outlined" className={classes.sideButton}>
                  <Add />
                </Button>
              </div>
            </div>
            <div className={classes.input} >
              <div className={classes.inlineSep}>
                <TextInput label="Documento" name="fileName" value={params.fileName} disabled />
                <Button onClick={simulateClick("document-selector")} variant="outlined" className={classes.sideButton}>
                  <Backup />
                </Button>
              </div>
              <input type="file" onChange={this.handleSelectFile} id="document-selector" className={classes.hidden}></input>
            </div>
            <div className={classes.input} >
              <div className={classes.inlineSep}>
                <TextInput label="Video (Enlace de Youtube)" name="video" value={params.video} onChange={this.handleChange} />
              </div>
            </div>
          </div>
          <div className={classes.rte}>
            <Typography variant='subtitle1'>Caracteres: {content_length}/600</Typography>
            <CKEditor
              editor={Editor}
              data={content}
              onChange={this.handleChangeContent}
              config={{
                // fontSize: {
                //   supportAllValues: true
                // },
                toolbar: ['bold', 'italic', '|',
                  "Undo",
                  "Redo",
                  "imageUpload",
                  "insertTable",
                  "link",
                  '|',
                  "alignment:left",
                  "alignment:right",
                  "alignment:center",
                  "alignment:justify",
                  '|',
                  'fontColor',
                  'fontBackgroundColor',
                  'fontSize'
                ],
                language: 'es',
                extraPlugins: [MyCustomUploadAdapterPlugin]
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log("Editor is ready to use!", editor);
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "min-height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  )
                })
              }}
            />
          </div>
          <div className={classes.openButton}>
            <Button color="primary" variant="outlined" onClick={this.handleCreateTraining} >Crear Capacitación</Button>
          </div>
          <Dialog open={openMenu} onClose={this.handleOpenMenu} maxWidth="sm" fullWidth >
            <div className={classes.dialogContent}>
              <div className={classes.cardTitle}>
                <Typography variant="h1">Crear nueva categoría</Typography>
              </div>
              <div className={classes.dialogInput}>
                <TextInput label="Nombre Categoría" name="category" value={params.category} onChange={this.handleChange} />
              </div>
              <div className={classes.dialogAction}>
                <Button color="primary" variant="contained" onClick={this.handleCreateCategory}>
                  Agregar
                </Button>
              </div>
            </div>
            <Divider />
            {this.renderCategoriesDialog()}
          </Dialog>
        </Paper>
        <Paper className={classes.table} square>
          <Typography variant="h1">Capacitaciones</Typography>
        </Paper>
        {loading ?
          <div className={classes.loaderContainer}>
            <LoaderAnimator />
          </div>
          :
          <GeneralTable data={trainings} info={tableInfo} actions={actions} />
        }
        <Dialog open={openEdit} onClose={this.handleCloseDialog}>
          <div className={classes.cardTitle}>
            <Typography variant="h1">Editar Capacitación</Typography>
          </div>
          <div className={classes.cardContent}>
            <div className={classes.input} >
              <TextInput label="Nombre" name="new_name" value={params.new_name} onChange={this.handleChange} />
            </div>
            <div className={classes.input} >
              <div className={classes.inline}>
                <SelectInput
                  label="Categoría"
                  placeholder="Ingresar Categoría"
                  name="new_category_id"
                  value={params.new_category_id} onChange={this.handleChange} options={transformToOptions(categories)} />
              </div>
            </div>
            <div className={classes.input} >
              <div className={classes.inlineSep}>
                <TextInput label="Documento" name="new_fileName" value={params.new_fileName} disabled />
                <Button onClick={simulateClick("new-document-selector")} variant="outlined" className={classes.sideButton}>
                  <Backup />
                </Button>
              </div>
              <input type="file" onChange={this.handleSelectNewFile} id="new-document-selector" className={classes.hidden}></input>
            </div>
            <div className={classes.input} >
              <div className={classes.inlineSep}>
                <TextInput label="Video (Enlace de Youtube)" name="new_video" value={params.new_video} onChange={this.handleChange} />
              </div>
            </div>
            <div className={classes.dialogEditInput} >
              <Typography variant='subtitle1'>Caracteres: {edit_content_length}/600</Typography>
              <CKEditor
                editor={Editor}
                data={params.new_content}
                onChange={this.handleChangeEditContent}
                config={{
                  // fontSize: {
                  //   supportAllValues: true
                  // },
                  toolbar: ['bold', 'italic', '|',
                    "Undo",
                    "Redo",
                    "imageUpload",
                    "insertTable",
                    "link",
                    '|',
                    "alignment:left",
                    "alignment:right",
                    "alignment:center",
                    "alignment:justify",
                    '|',
                    'fontColor',
                    'fontBackgroundColor',
                    'fontSize'
                  ],
                  language: 'es',
                  extraPlugins: [MyCustomUploadAdapterPlugin]
                }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "min-height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    )
                  })
                }}
              />
            </div>
          </div>
          <div className={classes.submitEditButton}>
            <Button color="primary" variant="contained" onClick={this.handleSendEditTraining}>
              Guardar
            </Button>
          </div>
        </Dialog>
        <Dialog open={tooLong && !shownWarning} fullWidth maxWidth="sm" onClose={this.handleCloseWarning}>
          <div className={classes.dialog}>
            <Typography variant="h4">Extensión sugerida sobrepasada</Typography>
            <Typography variant="subtitle1">Se ha sobrepasado la extensión
              sugerido para contenido de charlas y capacitaciones.
              Podrá seguir agregando información, pero es posible que se vea afectado
              el formato de las descargas de documentos
            </Typography>
          </div>
          <div className={classes.dialogAction}>
            <Button color="primary" variant="contained" onClick={this.handleCloseWarning}>
              Cerrar
            </Button>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getSettingsTrainings: () => dispatch(getSettingsTrainingsAction()),
  getTrainingsCategories: () => dispatch(getTrainingsCategoriesAction()),
  createTrainingsCategory: body => dispatch(createTrainingsCategoryAction(body)),
  editTrainingsCategory: body => dispatch(editTrainingsCategoryAction(body)),
  deleteTrainingsCategory: body => dispatch(deleteTrainingsCategoryAction(body)),
  createSettingsTraining: body => dispatch(createSettingsTrainingAction(body)),
  editSettingsTraining: body => dispatch(editSettingsTrainingAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(TrainingSettings))