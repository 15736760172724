import React, { Component } from 'react'
import { Button, Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../Utils/autobind'
import { createProgramRecord, deleteProgramRecord, editProgramRecord, getSettingPrograms } from '../../API/programs'
import SubPrograms from './SubPrograms'
import LoadingDialog from '../../Shared/LoadingDialog'
import NewRecordItem from './NewRecordItem'
import GeneralTable from '../../Shared/GeneralTable'
import { Delete, Edit } from '@material-ui/icons'
import ProgramContext from './ProgramContext'
import { withRouter } from 'react-router-dom'

const style = () => ({
  container: {
    margin: 12
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 0'
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '6px 0'
    }
  }
})

const frequencies = [
  { label: "Sin frecuencia", value: 0 },
  { label: "Semanal", value: 7 },
  { label: "Mensual", value: 30 },
  { label: "Trimestral", value: 90 },
  { label: "Semestral", value: 180 },
  { label: "Anual", value: 365 }
]

class ProgramContainer extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      selectedRow: null
    }
    addToggle(ProgramContainer, this, "create")
    addToggle(ProgramContainer, this, "edit")
    addToggle(ProgramContainer, this, "delete")
    autobind(ProgramContainer, this)
  }

  async componentDidMount() {
    await this.handleReload()
  }

  async componentDidUpdate(prevProps) {
    const { match: { params: { id } } } = this.props
    const { match: { params: { id: prevId } } } = prevProps
    if (id !== prevId) return this.handleReload()
    return true
  }

  async handleReload() {
    const { match: { params: { id } } } = this.props
    this.setState({ loading: true })
    const response = await getSettingPrograms({ id })
    console.log(response)
    const { info: program } = response.data
    this.setState({ program: program[0], loading: false })
  }

  async handleAddRecord(body) {
    const { match: { params: { id } } } = this.props
    body.program_id = id
    await createProgramRecord(body)
    this.handleReload()
  }

  async handleEditRecord(body) {
    const { match: { params: { id } } } = this.props
    body.program_id = id
    await editProgramRecord(body)
    this.handleReload()
  }

  async handleDeleteRecord() {
    const { selectedRow } = this.state
    const { match: { params: { id } } } = this.props
    const body = {
      program_id: id,
      id: selectedRow.id
    }
    await deleteProgramRecord(body)
    this.handleOpenDelete()
    this.handleReload()
  }

  handleEdit(row) {
    return () => {
      this.setState({ selectedRow: row })
      this.handleOpenEdit()
    }
  }

  handleDelete(row) {
    return () => {
      this.setState({ selectedRow: row })
      this.handleOpenDelete()
    }
  }

  render() {
    const { classes } = this.props
    const { program, loading, openCreate, openEdit, selectedRow, openDelete } = this.state
    const { getRecordName, getRecordTypeName } = this.context
    const actions = [
      { name: "Editar registro o formulario", action: this.handleEdit, icon: Edit },
      { name: "Eliminar registro o formulario", action: this.handleDelete, icon: Delete }
    ]
    const info = [
      { name: "Tipo", label: "record_type_id", format: (value) => getRecordTypeName(value) },
      { name: "Frecuencia", label: "frequency", format: (value) => frequencies.find(f => String(f.value) === value)?.label },
      { name: "Registro o formulario", label: "record_id", format: (value, element) => getRecordName(element.record_type_id, value) }
    ]

    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <Typography variant="h1">Programa: {program?.name}</Typography>
        <div className={classes.inline}>
          <Typography variant="h4">Registros y formularios asociados</Typography>
          <Button onClick={this.handleOpenCreate} color="secondary" variant="contained">
            Agregar Registro o formulario
          </Button>
        </div>
        <NewRecordItem open={openCreate} onClose={this.handleOpenCreate} onSubmit={this.handleAddRecord} />
        <NewRecordItem edit={selectedRow} open={openEdit} onClose={this.handleOpenEdit} onSubmit={this.handleEditRecord} />
        <GeneralTable scrollable data={program?.program_items} info={info} actions={actions} />

        <Divider style={{ margin: '24px 0' }} />
        <SubPrograms reload={this.handleReload} program={program} subprograms={program?.child_programs || []} />

        <Dialog open={openDelete} onClose={this.handleOpenDelete} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h4">Eliminar registro o formulario</Typography>
            <Typography variant="subtitle1">¿Seguro que deseas eliminar este registro o formulario?</Typography>
            <div className={classes.buttons}>
              <Button onClick={this.handleOpenDelete} color="primray" variant="contained">
                Cancelar
              </Button>
              <Button onClick={this.handleDeleteRecord} color="primary" variant="outlined">
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

ProgramContainer.contextType = ProgramContext

export default withRouter(withStyles(style)(ProgramContainer))