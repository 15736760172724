import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { getSettingsWorkflows, getWorkflows } from '../../../API/workflows'
import WorkflowTypeSelector from './WorkflowTypeSelector'
import autobind from '../../../Utils/autobind'
import BasesContainer from './BasesContainer'

const style = () => ({
  container: {
    margin: 24
  }
})

class WorkflowsReportContainer extends Component {
  constructor() {
    super()
    this.state = {
      bases: [],
      workflows: [],
      selectedType: 1
    }
    autobind(WorkflowsReportContainer, this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const baseWorkflowsResponse = await getSettingsWorkflows()
    if (baseWorkflowsResponse.status !== 200) return this.setState({ loading: false })
    const bases = baseWorkflowsResponse.data.info
    this.setState({ bases })

    const workflowsResponse = await getWorkflows()
    if (workflowsResponse.status !== 200) return this.setState({ loading: false })
    const workflows = workflowsResponse.data.info
    this.setState({ workflows, loading: false })
  }

  handleSelectType(name) {
    return () => {
      const { selectedType } = this.state
      if (name === selectedType) return this.setState({ selectedType: null })
      return this.setState({ selectedType: name })
    }
  }

  filterByType(data) {
    const { selectedType } = this.state
    return data.filter(wf => wf.workflow_type_id === selectedType)
  }

  render() {
    const { classes } = this.props
    const { selectedType, bases, workflows } = this.state
    const filteredWorkflows = this.filterByType(workflows)
    const filteredBases = this.filterByType(bases)
    return (
      <div className={classes.container}>
        <Typography variant="h1">Reporte de procesos</Typography>
        <WorkflowTypeSelector onSelect={this.handleSelectType} selected={selectedType} />
        <BasesContainer bases={filteredBases} workflows={filteredWorkflows} />
      </div>
    )
  }
}

export default withStyles(style)(WorkflowsReportContainer)