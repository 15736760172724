const TrainingReducer = (state = { all: [], selected: {} }, action) => {
  switch (action.type) {
    case "GET_TRAININGS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        newState.all = action.payload.data.info
      }
      return newState
    }
    case "SELECT_TRAINING": {
      const newState = { ...state }
      const selectedTalk = newState.all.find(training => training.id === action.payload)
      newState.selected = selectedTalk
      return newState
    }
    case "SIGN_TRAINING": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        const selectedIndex = newState.all.findIndex(training => training.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
        newState.selected = newTalk
      }
      return newState
    }
    case "USER_SIGN_TRAINING": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        const selectedIndex = newState.all.findIndex(training => training.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
        newState.selected = newTalk
      }
      return newState
    }
    case "EDIT_TRAINING": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        const selectedIndex = newState.all.findIndex(training => training.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
        newState.selected = newTalk
      }
      return newState
    }
    case "END_TRAINING": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        const selectedIndex = newState.all.findIndex(training => training.id === newTalk.id)
        newState.all[selectedIndex] = newTalk
        newState.selected = newTalk
      }
      return newState
    }
    case "CREATE_TRAINING": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalk = action.payload.data.info
        newState.all.push(newTalk)
      }
      return newState
    }
    default: {
      return state
    }
    case "CREATE_MULTIPLE_TRAININGS": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newTalks = action.payload.data.info
        newState.all = newState.all.concat(newTalks)
      }
      return newState
    }
  }
}

export default TrainingReducer