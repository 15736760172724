import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { ErrorOutline, Error } from '@material-ui/icons'
import autobind from '../../../../Utils/autobind'

const style = () => ({
	container: {
		width: 'fit-content'
	},
	crit: {
		background: 'white',
		padding: 6,
		borderRadius: 6,
		textAlign: 'center',
		margin: 6,
		marginLeft: 0,
		// width: 162,
		'& > svg': {
			width: 24,
			height: 24,
			margin: 12,
			transition: 'all 0.2s linear',
		},
		cursor: 'pointer',
		'&:hover': {
			'& > svg': {
				transform: 'scale(1.5)'
			}
		}
	},
	crits: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap'
	}
})

const colors = [
	"#a6b9f9",
	"#f6ec79",
	"#ffab6e",
	"#ff6c87",
]

class CritSelector extends Component {
	constructor() {
		super()
		autobind(CritSelector, this)
	}

	handleClick(crit) {
		return () => {
			const { onEdit, element } = this.props
			const selectedCrit = element.default_criticality
			const isSelected = selectedCrit === crit.value
			const finalValue = isSelected ? 0 : crit.value
			const event = {
				target: {
					name: "default_criticality",
					value: finalValue
				}
			}
			onEdit(event, true)
		}
	}

	render() {
		const { classes, crits, element } = this.props
		const selectedCrit = element.default_criticality
		return (
			<div className={classes.container}>
				<div className={classes.crits}>
					{crits.map((crit, index) => {
						const isSelected = selectedCrit === crit.value
						return (
							<div className={classes.crit} onClick={this.handleClick(crit)}>
								{/* <Typography variant='subtitle1' style={{ textAlign: 'center' }}>{crit.label}</Typography> */}
								{isSelected ? <Error style={{ color: colors[index], transform: 'scale(1.5)' }} /> : <ErrorOutline style={{ color: colors[index] }} />}
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

export default withStyles(style)(CritSelector)