import React, { useContext, useState } from 'react'
import { IconButton, ListItem, ListItemIcon, Menu, Typography } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import AppContext from '../AppContext'


function DownloadButton({ classes, onClick, params, disabled, enableOnly = [] }) {

	const { sub_enterprises } = useContext(AppContext)
	const [anchorEl, setAnchor] = useState(null)

	function handleOpenMenu(event) {
		let target = null
		if (!!event) {
			target = event.target
		}
		setAnchor(anchorEl === null ? target : null)
	}

	function handleClick(body) {
		return () => {
			onClick(params, body)()
			handleOpenMenu()
		}
	}

	const action = sub_enterprises.filter(se => enableOnly.length === 0 || enableOnly.includes(se.id)).length === 0 ? onClick(params) : handleOpenMenu

	return (
		<div>
			<IconButton disabled={disabled} onClick={action} id="menu-container">
				<GetApp />
			</IconButton>
			<Menu open={anchorEl !== null} anchorEl={anchorEl} onClose={handleOpenMenu}>
				<ListItem button style={{ display: 'flex', alignItems: 'center', padding: 12 }} onClick={handleClick()}>
					<ListItemIcon >
						<GetApp />
					</ListItemIcon>
					<Typography variant='subtitle1'>Descargar para Todas</Typography>
				</ListItem>
				{sub_enterprises.filter(se => enableOnly.length === 0 || enableOnly.includes(se.id)).map(se => {
					return (
						<ListItem button style={{ display: 'flex', alignItems: 'center', padding: 12 }} key={se.id} onClick={handleClick({ se: se.id })}>
							<ListItemIcon >
								<GetApp />
							</ListItemIcon>
							<Typography variant='subtitle1'>Descargar para {se.name}</Typography>
						</ListItem>
					)
				})}
			</Menu>
		</div>
	)
}

export default DownloadButton