import { Tab } from '@material-ui/core'
import { Box, Paper, Tabs, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { userComplianceReport } from '../../../API/users'
import newTheme from '../../../newTheme'
import DateIntervalSelector from '../../../Shared/Inputs/DateIntervalSelector'
import LoaderAnimator from '../../../Shared/LoaderAnimator'

const css = {
	container: {
		padding: 2,
		margin: '12px 0'
	},
	row: {
		display: 'flex',
		borderBottom: '1px solid lightgrey',
		background: 'whitesmoke',
		position: 'relative',
		flexDirection: 'column'
	},
	cell: {
		maxWidth: 60,
		minWidth: 60,
		padding: 2,
		// whiteSpace: "nowrap",
		// textOverflow: "ellipsis",
		// '& > *': {
		// 	overflow: 'hidden',
		// 	whiteSpace: "nowrap",
		// 	textOverflow: "ellipsis",
		// },
		maxHeight: 60,
		minHeight: 60,
		overflow: 'hidden',
		borderBottom: '1px solid grey'
	},
	table: {
		overflow: 'auto',
		background: 'whitesmoke',
		borderRadius: 2,
		position: 'relative',
		maxWidth: '100%',
		maxHeight: 500,
		display: 'flex',
	},
	tableContainer: {
		position: 'relative',
		minHeight: 250,
		height: 'auto'
	}
}

function FixedTable({ values }) {
	const headers = values.headers
	const total_headers = ["Actividad"].concat(headers)
	const data = values.rows

	const colors = [newTheme.palette.green.dark, newTheme.palette.error.dark, "goldenrod"]
	if (data.length === 0) return <Typography variant='subtitle1' style={{ margin: '12px 0' }} >No hay información en ese período</Typography>
	return (
		<Box sx={css.table}>
			<Box sx={css.row} style={{ position: 'sticky', left: 0, zIndex: 2, height: (60 + 24) * (headers.length + 1) }} >
				{total_headers.map(header => (
					<Tooltip key={header} title={<Typography variant='subtitle1' style={{ color: 'white' }}><strong>{header}</strong></Typography>}>
						<Box sx={css.cell} style={{ width: 140, maxWidth: 140, minWidth: 140 }} >
							<Typography variant='subtitle1' style={{ fontSize: 11 }} >{header}</Typography>
						</Box>
					</Tooltip>
				))}
			</Box>
			{data.map(row => (
				<Box key={row[0]} sx={css.row} style={{ position: 'relative', height: (60 + 24) * (headers.length + 1) }}>
					{row.map((value, index) => {
						const isFirst = index === 0
						const [amount, total] = value.split("/")
						const percentage = amount / total
						let color = colors[0]
						if (percentage < 1 && percentage >= 0.5) { color = colors[2] }
						if (percentage < 0.5) { color = colors[1] }
						return (
							<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>{value}</Typography>}>
								<Box sx={css.cell} key={"" + index + value} style={{
									background: isFirst ? "whitesmoke" : color,
									position: isFirst ? "sticky" : "relative",
									top: 0, zIndex: isFirst ? 1 : 0,
								}}>
									<Typography variant='subtitle1' style={{ color: isFirst ? "" : "white", fontSize: 11 }}>{value}</Typography>
								</Box>
							</Tooltip>
						)
					})}
				</Box>
			)
			)}
		</Box>
	)
}

function UserComplianceResumeChecklist({ value }) {

	const [dates, setDates] = useState({})
	const [tab, setTab] = useState(0)
	const [data, setData] = useState({ headers: [], rows: [] })
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		async function fetchData() {
			const body = {
				option: tab === 0 ? "amount" : "signs",
				...dates
			}
			setLoading(true)
			const response = await userComplianceReport(body)
			console.log(response.data.info)
			setData(response.data.info)
			setLoading(false)
		}

		fetchData()
	}, [tab, dates])

	function onDateChange(values) {
		setDates(values)
	}

	return (
		<Paper sx={css.container}>
			<Box>
				<Typography variant='h1'>Resumen de cumplimientos</Typography>
				<DateIntervalSelector onDateChange={onDateChange} />
			</Box>
			<Tabs value={tab}>
				<Tab onClick={() => setTab(0)} label="Cumplimiento" />
				<Tab onClick={() => setTab(1)} label="Firmas" />
			</Tabs>
			<Box sx={css.tableContainer}>
				{loading ? <LoaderAnimator /> : <FixedTable values={data} />}
			</Box>
		</Paper>
	)
}

export default UserComplianceResumeChecklist