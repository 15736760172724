import React, { Component } from 'react'
import { Checkbox, Dialog, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../Shared/Inputs/SelectInput'
import autobind from '../../Utils/autobind'
import TextInput from '../../Shared/Inputs/TextInput'
import SubmitButton from '../../Shared/SubmitButton'

const style = theme => ({
  container: {
    padding: 24
  },
  optionsCreated: {
    marginLeft: 24
  },
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 46,
    position: 'relative',
    '&::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "100%",
      width: 2,
      bottom: 0
    },
    '&:first-child::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "50%",
      width: 2,
      bottom: 0
    },
    '&:last-child::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "50%",
      width: 2,
      top: 0
    },
    '&::after': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 18,
      height: 12,
      width: 12,
      borderRadius: '50%'
    },
    '&:only-child::before': {
      content: "''",
      background: theme.palette.grey.dark,
      position: 'absolute',
      left: 23,
      height: "0%",
      width: 2,
      bottom: 0
    },
  },
  addExtra: {
    display: 'flex',
    alignItems: 'center'
  }
})

const typeOptions = [
  { label: "Texto", value: "text" },
  { label: "Texto enriquecido (Word)", value: "rte" },
  { label: "Lista con opciones", value: "select" },
  { label: "Selección Múltiple", value: "multiselect" },
  { label: "Imagen", value: "img" },
]

function parseOption(option) {
  const [value, message, color] = option.split('&')
  return (
    <div style={{ display: 'flex' }}>
      <Typography variant="subtitle1">{`${value} ${message ? "-" : ""} `}</Typography>
      {' '}
      {message && <Typography style={{ color }} variant="subtitle1">{`${message}`}</Typography>}
    </div>
  )
}

class NewExtraField extends Component {
  constructor() {
    super()
    this.state = {
      params: {

      }
    }
    autobind(NewExtraField, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeRequired() {
    const { params } = this.state
    params.required = !params.required
    this.setState({ params })
  }

  handleAddOption() {
    const { params } = this.state
    const options = params.options ? [...params.options] : []
    const compiledOption = `${params.option}${params.option_message ? `&${params.option_message}` : ""}${params.option_color ? `&${params.option_color}` : ""}`
    options.push(compiledOption)
    const newparams = { ...params }
    newparams.options = options
    newparams.option = ""
    this.setState({ params: newparams })
  }

  handleCreate() {
    const { params } = this.state
    const { onSubmit, onClose } = this.props
    const body = { ...params }
    onSubmit(body)
    onClose()
    this.setState({ params: {} })

  }

  renderCreatedOptions() {
    const { params } = this.state
    const { classes } = this.props
    const options = params.options || []
    return options.map((option, index) => {
      return (
        <div className={classes.element} style={{ "&::before": { background: 'red' } }} key={index}>
          {parseOption(option)}
        </div>
      )
    })
  }

  render() {
    const { classes, open, onClose } = this.props
    const { params } = this.state
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h2">Nuevo Campo</Typography>
          <TextInput label="Nombre Campo" onChange={this.handleChange} name="label" value={params.label} />
          <SelectInput onChange={this.handleChange} label="Tipo de campo" name="type" value={params.type} options={typeOptions} />
          {(params.type === "select" || params.type === "multiselect") &&
            <>
              <TextInput label="Agregar Opción" onChange={this.handleChange} name="option" value={params.option} />
              <SubmitButton color="primary" onClick={this.handleAddOption} disabled={!params.option}>
                Agregar Opción
              </SubmitButton>
            </>
          }
          <div className={classes.optionsCreated}>
            {this.renderCreatedOptions()}
          </div>
          <div className={classes.addExtra}>
            <Checkbox checked={!!params.required} onChange={this.handleChangeRequired} name="required" />
            <Typography variant="subtitle1"> Campo Obligatorio</Typography>
          </div>
          <div style={{ marginTop: 24 }}>
            <SubmitButton color="secondary" variant="contained" onClick={this.handleCreate} disabled={!params.label || !params.type}>
              Crear Campo
            </SubmitButton>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(NewExtraField)