import { AddAPhotoOutlined, ErrorOutline, PersonAdd } from '@material-ui/icons'
import { Box, Button, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useRef } from 'react'
import AppContext from '../../../../AppContext'
import useToggle from '../../../../Hooks/ToogleHook'
import useChangeParams from '../../../../Hooks/UseStateChangeParams'
import newTheme from '../../../../newTheme'
import ImageContainer from '../../../../Shared/ImageContainer'
import DateTimeInput from '../../../../Shared/Inputs/DateTimeInput'
import TextInput from '../../../../Shared/Inputs/TextInput'
import UsersInput from '../../../../Shared/Inputs/UsersInput'
import MultipleImageContainer from '../../../../Shared/MultipleImageContainer'
import { end_buttons, inline, inline_space, labeless, text_space } from '../../../../Utils/defaultStyles'
import { getBase64Image } from '../../../../Utils/functions'
import instance2 from '../../../../Utils/instance2'

const css = {
	images_container: {
		margin: '12px 0'
	},
	finding: {
		background: newTheme.palette.background.main,
		padding: 1,
		borderRadius: 1,
		margin: '6px 0',
		position: 'relative'
	},
}

const colors = [
	"#6a6a6a",
	"#a3b8ff",
	"#f6ec79",
	"#ffab6e",
	"#ff6c87",
]

function ItemFindingForm({ finding, updateFinding }) {

	const [openSettings, toggleSettings] = useToggle(false)
	const [params, setParams, updateParams] = useChangeParams({ images: [], counter: 1 })
	const { online: { status: online } } = useContext(AppContext)
	const input = useRef(null)

	useEffect(() => {
		const initialParams = { ...finding }
		initialParams.counter = initialParams.counter || 1
		initialParams.date_limit = moment(initialParams.date_limit).isValid() ? initialParams.date_limit : moment().format("YYYY-MM-DD HH:mm")
		updateParams(initialParams)
	}, [finding])

	function assignFinding() {
		const body = { ...params }
		onUpdate(body)
	}

	function onUpdate(body) {
		updateFinding(body, finding.checklist_index)
	}

	async function onSelectFile(event) {
		const { target } = event
		const file = target.files[0]
		const image = await getBase64Image(file)
		const new_finding = { ...finding, ...params }
		const new_images_string = new_finding.free_file
		const new_images = new_images_string?.split("&#&") || []
		new_images.push(image)
		new_finding.free_file = new_images.join("&#&")
		new_finding.images = new_images
		onUpdate(new_finding)
	}

	function simulateClick() { input.current.click() }

	return (
		<Box>
			<Box sx={css.finding}>
				<input ref={input} type="file" accept="image/png, image/jpeg" hidden onChange={onSelectFile} />
				<Box sx={inline_space}>
					<Box sx={inline}>
						<IconButton sx={{ color: colors[finding.criticality || finding.free_criticality] }} variant="">
							<ErrorOutline />
						</IconButton>
						<Button variant="outlined" endIcon={<PersonAdd />} onClick={toggleSettings}>
							Configurar Hallazgo
						</Button>
					</Box>
					<Button endIcon={<AddAPhotoOutlined />} onClick={simulateClick} variant="outlined" color="info">
						Agregar Foto
					</Button>
				</Box>
				<Box sx={css.images_container}>
					<MultipleImageContainer images={finding?.free_file?.split("&#&") || []} width={72} height={72} border={6} />
				</Box>
				{openSettings &&
					<Box sx={css.settings} >
						<Typography variant='subtitle2' sx={text_space} >Información del hallazgo</Typography>
						<Typography sx={labeless} variant='subtitle1'>Acción inmediata: </Typography>
						<TextInput value={params.description} onChange={setParams} name="description" label="" />
						<Typography sx={labeless} variant='subtitle1'>Fecha límite: </Typography>
						<DateTimeInput value={params.date_limit} onChange={setParams} name="date_limit" label="" />
						<Typography sx={labeless} variant='subtitle1'>Responsable: </Typography>
						<UsersInput value={params.user_id} onChange={setParams} name="user_id" label="" />
						<Typography sx={labeless} variant='subtitle1'>Cantidad de ocurrencias (opcional): </Typography>
						<TextInput type="number" value={params.counter} onChange={setParams} name="counter" label="" />
						<Box sx={end_buttons}>
							<Button color="success" variant="contained" onClick={assignFinding}>
								Asignar
							</Button>
						</Box>
					</Box>
				}
			</Box>
		</Box>
	)
}

export default ItemFindingForm