import React, { Component } from 'react'
import { Collapse, Divider, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import autobind from '../../../Utils/autobind'
import { CheckCircleOutline, InfoOutlined } from '@material-ui/icons'
import moment from 'moment'
import ProgressBar from './ProgressBar'

const style = theme => ({
  container: {
    padding: 12
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      color: theme.palette.red.main,
      height: 18,
      width: 18,
      marginRight: 6
    },
    marginBottom: 12
  },
  complianceElement: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    padding: 12,
    margin: '12px 0 0 0',
    '& > *': {
      marginRight: 12,
      color: 'white',
      minWidth: 25
    },
    '& > svg': {
      height: 24,
      width: 24
    },
    maxWidth: 200
  },
  green: { background: theme.palette.green.main },
  red: { background: theme.palette.red.main },
  blue: { background: theme.palette.blue.main },
  bar: {
    marginTop: 12,
    maxWidth: 600
  }
})

const frequencyOptions = [
  { label: "2 al día", value: 0.5 },
  { label: "Diario", value: 1 },
  { label: "Semanal", value: 7 },
  { label: "Mensual", value: moment(new Date()).daysInMonth() },
]

class FrequencySimulator extends Component {
  constructor() {
    super()
    this.state = {
      selectedRegister: null,
      frequency: null
    }
    autobind(FrequencySimulator, this)
  }

  handleSelectRegister(event) {
    const { target } = event
    this.setState({ selectedRegister: target.value })
  }

  handleChangeFrequency(event) {
    const { target } = event
    this.setState({ frequency: target.value })
  }

  getRegisterNames() {
    const { data } = this.props
    return data.reduce((array, element) => {
      if (!array.includes(element.nombre_registro)) { array.push(element.nombre_registro) }
      return array
    }, [])
  }

  render() {
    const { classes, data, dates } = this.props
    const { selectedRegister, frequency } = this.state
    console.log(data)
    const { date_start, date_end } = dates
    const names = this.getRegisterNames()
    const daysInMonth = moment(date_end).diff(moment(date_start), 'days') + 1
    console.log(daysInMonth)
    const totalRegistersSimulated = !!frequency ? Math.round(daysInMonth / frequency) : 0
    const totalDoneRegister = data.filter(element => {
      const isSameName = selectedRegister === element.nombre_registro
      const isDone = element.estado === "Terminado"
      return isSameName && isDone
    }).length
    const percentage = totalRegistersSimulated > 0 ? Math.round(totalDoneRegister * 100 / totalRegistersSimulated) : 0
    return (
      <div className={classes.container}>
        <Typography variant="h4">Simulador de cumplimiento</Typography>
        <Divider style={{ margin: '12px 0' }} />
        <div style={{ maxWidth: 500 }}>
          <SelectInput
            label="Seleccionar registro o formulario"
            options={names.map(name => ({ label: name, value: name }))}
            onChange={this.handleSelectRegister}
            value={selectedRegister}
          />
        </div>
        <Collapse in={selectedRegister !== null}>
          <div className={classes.inline}>
            <InfoOutlined />
            <Typography variant="subtitle1">Esta sección es para simular la cantidad de registros y formularios que debiesen haber sido ingresados en el periodo seleccionado, segun la frecuencia de ingreso de los mismos.</Typography>
          </div>
          <Typography variant="h4" style={{ margin: '12px 0' }}>Seleccione frecuencia estimada del registro o formulario</Typography>
          <SelectInput label="Frecuencia de registro o formulario" options={frequencyOptions} onChange={this.handleChangeFrequency} value={frequency} />
        </Collapse>
        <Collapse in={frequency !== null}>
          <Typography variant="h4" style={{ margin: '12px 0' }}>Simulación</Typography>
          <div className={`${classes.complianceElement} ${classes.blue}`}>
            <CheckCircleOutline />
            <Typography variant="h4">{totalRegistersSimulated ?? 0}</Typography>
            <Typography variant="subtitle1">Estimadas</Typography>
          </div>
          <div className={`${classes.complianceElement} ${classes.green}`}>
            <CheckCircleOutline />
            <Typography variant="h4">{totalDoneRegister ?? 0}</Typography>
            <Typography variant="subtitle1">Realizadas</Typography>
          </div>
          <div className={classes.bar}>
            <Typography variant="subtitle1" style={{ fontWeight: 600, marginBottom: 6 }}>Cumplimiento simulado</Typography>
            <ProgressBar value={percentage <= 100 ? percentage : 100} textValue={percentage} />
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(FrequencySimulator)