import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import GeneralTable from '../../../Shared/GeneralTable'
import AddElementButton from '../../../Shared/AddElementButton'
import autobind from '../../../Utils/autobind'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { dateTimeFormat } from '../../../Utils/formats'
import moment from 'moment'
import { Edit, GetApp, HourglassEmpty } from '@material-ui/icons'
import { createListAction, editListAction, getListsAction, getSettingsListsAction } from '../../../Actions/ListsAction'
import RegisterDialog from './RegisterDialog'
import { callSnackbar } from '../../../Utils/snackbar'

const style = () => ({
  title: {
    margin: "26px 0 12px 0 ",
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const tableInfo = [
  { name: "Supervisor", label: "supervisor" },
  { name: "Fecha de inicio", label: 'date_start', format: dateTimeFormat },
  { name: "Fecha de término", label: 'date_end', format: dateTimeFormat },
]

class Registers extends Component {
  constructor() {
    super()
    this.state = {
      openAdd: false,
      openEdit: false,
      selected: null,
      loadingFile: false
    }
    autobind(Registers, this)
  }

  componentDidMount() {
    const { match, getLists, getSettingsList } = this.props
    const { sub_branch_id } = match.params
    const body = { sub_branch_id }
    getLists(body)
    getSettingsList()
  }

  handleOpenAdd() {
    this.setState({ openAdd: !this.state.openAdd })
  }

  handleOpenEdit(row) {
    return () => {
      if (row) {
        const baseSelected = {
          ...row,
          date_start: moment(row.date_start).format("YYYY-MM-DDTHH:mm:ss"),
          date_end: moment(row.date_end).format("YYYY-MM-DDTHH:mm:ss")
        }
        return this.setState({ openEdit: !this.state.openEdit, selected: baseSelected })
      }
      this.setState({ openEdit: !this.state.openEdit, selected: row })
    }
  }

  handleCreate(params) {
    const { createList, match } = this.props
    const { sub_branch_id } = match.params
    const body = {
      ...params,
      sub_branch_id,
      date_created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      date_modified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      date_start: moment(params.date_start).format("YYYY-MM-DD HH:mm:ss"),
      date_end: moment(params.date_end).format("YYYY-MM-DD HH:mm:ss")
    }
    createList(body)
  }

  handleEdit(params) {
    const { selected } = this.state
    const { editList, match } = this.props
    const { sub_branch_id } = match.params
    const body = {
      ...params,
      sub_branch_id,
      date_created: selected.date_created,
      date_modified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      date_start: moment(params.date_start).format("YYYY-MM-DD HH:mm:ss"),
      date_end: moment(params.date_end).format("YYYY-MM-DD HH:mm:ss"),
      id: selected.id
    }
    editList(body)
  }

  handleGotoFile(row) {
    return () => {
      const { user } = this.props
      const url = `https://app.safeasy.cl/model/pdf/list.php?id=${row.id}&u=${user?.account?.user?.id}`
      this.setState({ loadingFile: true })
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement("a")
          a.href = url
          a.download = `Ficha de lista`
          a.click()
          this.setState({ loadingFile: false })
        })
      }).catch(() => {
        callSnackbar("Error al descargar documento", "error")
        this.setState({ loadingFile: false })
      }
      )
    }
  }

  render() {
    const { classes, lists } = this.props
    const { openAdd, openEdit, selected, loadingFile } = this.state
    const allLists = lists.all || []

    const actions = [
      { name: "Editar", icon: Edit, action: this.handleOpenEdit },
      { name: "Descargar", icon: loadingFile ? HourglassEmpty : GetApp, action: this.handleGotoFile, disabled: () => loadingFile }
    ]
    return (
      <div className={classes.container}>
        <div className={classes.inline}>
          <Typography variant="h1" className={classes.title}>
            Tabla de listas
          </Typography>
          <AddElementButton open={openAdd} onClick={this.handleOpenAdd}>Crear lista</AddElementButton>
        </div>
        <RegisterDialog open={openAdd} onClose={this.handleOpenAdd} onCreate={this.handleCreate} />
        <RegisterDialog open={openEdit} onClose={this.handleOpenEdit()} onCreate={this.handleEdit} baseParams={selected} edit />
        <Divider style={{ marginBottom: 24 }} />
        <GeneralTable info={tableInfo} data={allLists} actions={actions} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lists: state.lists,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  getSettingsList: body => dispatch(getSettingsListsAction(body)),
  getLists: body => dispatch(getListsAction(body)),
  editList: body => dispatch(editListAction(body)),
  createList: body => dispatch(createListAction(body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Registers)))