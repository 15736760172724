import { frequencyFormat, getCriticity, getResidualCriticity } from "../../Utils/formats"
import TableChip from "../../Shared/TableRenders/TableChip"
import MultipleData from "../../Shared/TableRenders/MultipleData"

export const activityInfo = [
    {
        required: true,
        name: "activity_id",
        label: "Actividad",
        type: "select",
        options: [],
        placeholder: "Seleccionar actividad",
    },
    {
        required: true,
        name: "branch_id",
        label: "Sucursal/Obra",
        type: "select",
        options: [],
        placeholder: "Seleccionar sucursal",
    },
    {
        required: true,
        name: "user_id",
        label: "Responsable",
        type: "select",
        options: [],
        placeholder: "Seleccionar responsable",
    },
    { required: true, name: "start_date", label: "Fecha de inicio", type: "date" },
    { required: true, name: "end_date", label: "Fecha de término", type: "date" },
    {
        required: true,
        name: "frequency",
        label: "Frecuencia de registros y formularios",
        type: "select",
        placeholder: "Seleccionar frecuencia",
        options: [
            { value: 1000, label: "Sin Frecuencia" },
            { value: 1, label: "Diario" },
            { value: 2, label: "Cada dos días" },
            { value: 3, label: "Cada tres días" },
            { value: 7, label: "Semanal" },
            { value: 15, label: "Quincenal" },
            { value: 30, label: "Mensual" },
            { value: 60, label: "Bimensual" },
            { value: 90, label: "Trimestral" },
            { value: 180, label: "Semestral" },
        ],
    },
]

export const multipleActivityInfo = [
    {
        required: true,
        name: "activity_id",
        label: "Actividad",
        type: "select",
        options: [],
        placeholder: "Seleccionar actividad",
    },
    {
        required: true,
        name: "user_id",
        label: "Responsable",
        type: "select",
        options: [],
        placeholder: "Seleccionar responsable",
    },
    { required: true, name: "start_date", label: "Fecha de inicio", type: "date" },
    { required: true, name: "end_date", label: "Fecha de término", type: "date" },
    {
        required: true,
        name: "frequency",
        label: "Frecuencia de registros y formularios",
        type: "select",
        placeholder: "Seleccionar frecuencia",
        options: [
            { value: 1000, label: "Sin Frecuencia" },
            { value: 1, label: "Diario" },
            { value: 2, label: "Cada dos días" },
            { value: 3, label: "Cada tres días" },
            { value: 7, label: "Semanal" },
            { value: 15, label: "Quincenal" },
            { value: 30, label: "Mensual" },
            { value: 60, label: "Bimensual" },
            { value: 90, label: "Trimestral" },
            { value: 180, label: "Semestral" },
        ],
    },
    {
        required: true,
        name: "branch_ids",
        label: "Centros de trabajo",
        type: "multiselect",
        options: [],
        placeholder: "Seleccionar centros",
    },
]

export const multipleActivityUsersInfo = [
    {
        required: true,
        name: "activity_id",
        label: "Actividad",
        type: "select",
        options: [],
        placeholder: "Seleccionar actividad",
    },
    {
        required: true,
        name: "branch_ids",
        label: "Sucursal(es)/Obra(s)",
        type: "multiselect",
        options: [],
        placeholder: "Seleccionar sucursal(es)",
    },
    { required: true, name: "start_date", label: "Fecha de inicio", type: "date" },
    { required: true, name: "end_date", label: "Fecha de término", type: "date" },
    {
        required: true,
        name: "frequency",
        label: "Frecuencia de listas de chequeo",
        type: "select",
        placeholder: "Seleccionar frecuencia",
        options: [
            { value: 1000, label: "Sin Frecuencia" },
            { value: 1, label: "Diario" },
            { value: 2, label: "Cada dos días" },
            { value: 3, label: "Cada tres días" },
            { value: 7, label: "Semanal" },
            { value: 15, label: "Quincenal" },
            { value: 30, label: "Mensual" },
            { value: 60, label: "Bimensual" },
            { value: 90, label: "Trimestral" },
            { value: 180, label: "Semestral" },
        ],
    },
]

export const activityEditInfo = [
    { name: "name", label: "Nombre" },
    { name: "branch_id", label: "Sucursal", type: "select", options: [] },
    {
        name: "risks_id",
        realName: "risks",
        label: "Riesgos",
        type: "multiselect",
        options: [],
    },
    {
        name: "epps_id",
        realName: "epps",
        label: "Elementos de protección",
        type: "multiselect",
        options: [],
    },
    {
        name: "measures_id",
        realName: "preventive_measures",
        label: "Registros y formularios",
        type: "multiselect",
        options: [],
    },
    {
        name: "probability_id",
        label: "Probabilidad",
        type: "select",
        options: [],
    },
    {
        name: "consequence_id",
        label: "Consecuencia",
        type: "select",
        options: [],
    },
    {
        name: "residual_probability_id",
        label: "Probabilidad residual",
        type: "select",
        options: [],
    },
    {
        name: "residual_consequence_id",
        label: "Consecuencia residual",
        type: "select",
        options: [],
    },
    { name: "user_id", label: "Responsable", type: "select", options: [] },
    { name: "start_date", label: "Fecha Inicio", type: "date" },
    { name: "end_date", label: "Fecha Término", type: "date" },
    {
        name: "frequency",
        label: "Frecuencia Registros y formularios",
        type: "select",
        options: [
            { value: 1000, label: "Sin Frecuencia" },
            { value: 1, label: "Diario" },
            { value: 2, label: "Cada dos días" },
            { value: 3, label: "Cada tres días" },
            { value: 7, label: "Semanal" },
            { value: 15, label: "Quincenal" },
            { value: 30, label: "Mensual" },
            { value: 60, label: "Bimensual" },
            { value: 90, label: "Trimestral" },
            { value: 180, label: "Semestral" },
        ],
    },
]

export const activityTableInfo = [
    { label: "name", name: "Nombre" },
    { label: "start_date", name: "Fecha Inicio" },
    { label: "end_date", name: "Fecha Término" },
    { label: "frequency", name: "Frecuencia", format: frequencyFormat },
    { label: "branch", name: "Centro de Trabajo", format: (value) => value?.name },
    { label: "user", name: "Responsable", format: (value) => value.name }
]

export const activityIPERInfo = [
    { label: "name", name: "Nombre" },
    { label: "risks", name: "Riesgos", render: MultipleData },
    { label: "epps", name: "EPP", render: MultipleData },
    { label: "consequence&name", name: "Consecuencia", render: TableChip }, // TODO: accept deep keys
    { label: "probability&name", name: "Probabilidad", render: TableChip }, // TODO: accept deep keys
    {
        label: "all",
        name: "Criticidad",
        format: getCriticity,
        render: TableChip,
    },
    { label: "preventive_measures", name: "Medidas Preventivas", render: MultipleData },
    { label: "residual_consequence&name", name: "Consecuencia Residual", render: TableChip }, // TODO: accept deep keys
    { label: "residual_probability&name", name: "Probabilidad Residual", render: TableChip }, // TODO: accept deep keys
    {
        label: "all",
        name: "Criticidad Residual",
        format: getResidualCriticity,
        render: TableChip,
        variant: "residual"
    },
]