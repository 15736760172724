import axios from "axios"
import createSnackbar from "../Utils/snackbar"
import instance from "../Utils/instance"

export const loginAction = (body, snackbar) => {
    const REQUEST = axios.post(
        "https://app.safeasy.cl/api/auth/login.php",
        body
    )
    createSnackbar(REQUEST, snackbar)
    return {
        type: "LOGIN",
        payload: REQUEST,
    }
}

export const logoutAction = () => {
    localStorage.setItem("filters", JSON.stringify([]))
    return {
        type: "LOGOUT",
    }
}

export const TokenTest = () => {
    const REQUEST = instance({
        method: "post",
        url: "/auth/me.php",
    })
    return {
        type: "TEST_TOKEN",
        payload: REQUEST,
    }
}

export const changePasswordAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "/users/change.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "CHANGE_PWD",
        payload: REQUEST,
    }
}
