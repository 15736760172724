import { Box, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import DonutChart from '../../../../Shared/Charts/DonutChart'
import NewMultiSelectInput from '../../../../Shared/Inputs/NewMultiSelectInput'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		flexBasis: 200,
		flexGrow: 1,
		minHeight: 200,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		whiteSpace: "nowrap",
		overflowX: "hidden",
		textOverflow: "ellipsis",
	},
	title: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		marginBottom: 2
	},
	users: {
		maxHeight: 150,
		overflowY: 'auto',
		overflowX: 'hidden',
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		background: 'whitesmoke',
		borderRadius: 2
	},
	user: {
		padding: 1,
		'& > *': {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		display: 'flex',
		alignItems: 'center'
	},
	circle: {
		height: 12,
		width: 12,
		borderRadius: 24,
		marginRight: 1
	}
}

function DocumentationCard({ options, doc }) {

	const [option, setOption] = useState(null)

	function onClick(item) {
		if (!item[0]) return null
		const color = item[0]._model.backgroundColor
		if (color === "rgb(255, 71, 105)") return selectOption("defaulters")
		if (color === "rgb(93, 238, 154)") return selectOption("pursuant")
	}

	function selectOption(op) {
		setOption(option => op === option ? null : op)
	}

	const [sub_enterprises, branches, positions] = options
	const selectedSubEnterprise = sub_enterprises?.find(se => String(se.id) === String(doc?.doc?.sub_enterprise_id))
	const selectedBranch = branches?.find(b => String(b.id) === String(doc?.doc?.branch_id))
	const selectedPosition = positions?.find(p => String(p.id) === String(doc?.doc?.position_id))
	const users = option === "pursuant" ? doc.pursuant_users : doc.defaulter_users
	return (
		<Box sx={css.container}>
			<Box>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >{doc?.name}</Typography>}>
					<Typography sx={css.title} variant='subtitle1'><strong>{doc?.name}</strong></Typography>
				</Tooltip>
				<Box style={{ margin: '12px 0' }}>
					{selectedPosition && <Typography variant='subtitle1'>Para: <strong>{`${selectedPosition?.name}`} </strong></Typography>}
					{selectedBranch && <Typography variant='subtitle1'>En: <strong>{`${selectedBranch?.name}`} </strong></Typography>}
					{selectedSubEnterprise && <Typography variant='subtitle1'>De: <strong>{`${selectedSubEnterprise?.name}`} </strong></Typography>}
					<Typography variant='subtitle1'>{`Cumplen ${doc?.pursuant} de ${doc?.total}`}</Typography>
				</Box>
			</Box>
			<Box style={{ width: 200, position: 'relative', margin: '12px auto' }}>
				<DonutChart onClick={onClick} name={doc?.doc?.id} data={[doc?.pursuant, doc?.total - doc?.pursuant]} />
			</Box>
			{!!option &&
				<Box sx={css.users}>
					{users.map(u =>
						<Box sx={css.user}>
							<Box sx={css.circle} style={{ background: option === "pursuant" ? "rgb(93, 238, 154)" : "rgb(255, 71, 105)" }} />
							<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >{u?.name}</Typography>}>
								<Typography variant='subtitle1'>{u?.name}</Typography>
							</Tooltip>
						</Box>
					)}
				</Box>
			}
		</Box>
	)
}

export default DocumentationCard