import React, { Component } from 'react'
import { Collapse, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../../Utils/autobind'
import { createDocument, deleteBranchDocument, editBranchDocument, getBranchDocuments, getSettingsBranchDocuments, signDocument, editDocumentParticipants } from '../../../API/branches'
import { withRouter } from 'react-router-dom'
import Categories from './Categories'
import DocumentContext from './DocumentContext'
import Documents from './Documents'
import { List } from '@material-ui/icons'
import { callSnackbar } from '../../../Utils/snackbar'
import LoadingDialog from '../../../Shared/LoadingDialog'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

const style = () => ({
  container: {
    display: 'flex',
    background: 'white',
    borderRadius: 8,
    padding: 12,
    margin: '12px 0',
    position: 'relative'
  },
  verticalDivider: {
    width: 1,
    background: 'lightgrey',
    margin: '0 12px'
  },
  mobileCategories: {
    position: 'absolute',
    zIndex: 2,
    background: 'white',
    borderRadius: 8,
    transition: 'all 0.3s linear',
    top: 0,
    left: 0,
    padding: 12
  },
  listButton: {
    top: -12,
    left: -12,
  }
})

class DocumentsContainer extends Component {
  constructor() {
    super()
    this.state = {
      documents: [],
      categories: [],
      selectedCategory: null,
      selectedSubcategory: null,
      title: "",
      openList: true,
      loading: false,
      actual: 0,
      loadingDownload: false,
    }
    addToggle(DocumentsContainer, this, "list")
    autobind(DocumentsContainer, this)
  }

  async componentDidMount() {
    const { match: { params: { id: branch_id } } } = this.props
    const body = { branch_id }
    this.setState({ loading: true })
    const [response, settings] = await Promise.all([getBranchDocuments(body), getSettingsBranchDocuments()])
    const { data: { info: documents } } = response
    const { data: { info: categories } } = settings
    this.setState({ documents, categories, openList: true, loading: false })
  }

  handleSelectCategory(cat, isSub) {
    const { selectedCategory, selectedSubcategory } = this.state
    if (isSub) {
      if (cat.id === selectedSubcategory) return this.setState({ title: "", selectedSubcategory: null })
      return this.setState({ title: cat.name, selectedSubcategory: cat.id, selectedCategory: null })
    }
    if (cat.id === selectedCategory) return this.setState({ title: "", selectedCategory: null })
    return this.setState({ title: cat.name, selectedCategory: cat.id, selectedSubcategory: null })
  }

  async handleSendEdit(params) {
    const body = new FormData()
    body.append("name", params.name)
    if (typeof params.file !== "string") { body.append("file", params.file) }
    body.append("category_id", params.category_id)
    body.append("subcategory_id", params.subcategory_id)
    body.append("id", params.id)
    body.append("branch_id", params.branch_id)
    body.append("date", params.date)
    await editBranchDocument(body)
    this.componentDidMount()
  }

  async handleDeleteDocument(document) {
    const body = { branch_id: document.branch_id, id: document.id }
    await deleteBranchDocument(body)
    this.componentDidMount()
  }

  async handleSignDocument(body) {
    await signDocument(body)
    this.componentDidMount()
  }

  async handleCreateDocument(params) {
    const { match } = this.props
    const body = new FormData()
    body.append("name", params.name || "")
    body.append("file", params.file)
    body.append("date", params.date)
    body.append("branch_id", match.params.id)
    body.append("category_id", params.category_id)
    body.append("subcategory_id", params.subcategory_id)
    await createDocument(body)
    this.componentDidMount()
  }

  async handleAskSign(body) {
    this.setState({ loading: true })
    await editDocumentParticipants(body)
    this.setState({ loading: false })
    callSnackbar("Solicitud enviada", "success")
    this.componentDidMount()
  }

  async handleDownloadAll() {
    const { documents, categories } = this.state
    const allDocuments = [...documents] || []
    const documentAmount = allDocuments.length
    this.setState({ loadingDownload: true, actual: 0 })
    let actual = 0
    const self = this
    const zip = new JSZip()

    const noCatDocuments = allDocuments.filter(doc => doc.category_id === null && doc.subcategory_id === 0)
    const nocat = zip.folder("Sin Categoría")
    await Promise.all(noCatDocuments.map(async function (doc) {
      actual += 1
      self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_BRANCH_DOCS}${doc.file}`
      const response = await fetch(url).catch(error => console.log("No se pudo descargar un documento"))
      if (!!response) {
        const blob = await response.blob()
        nocat.file(doc.file, blob)
      }
    }))

    await Promise.all(categories.map(async category => {

      const catDocuments = allDocuments.filter(doc => doc.category_id === category.id && doc.subcategory_id === 0)
      const cat = zip.folder(category.name)
      await Promise.all(catDocuments.map(async function (doc) {
        actual += 1
        self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
        const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_BRANCH_DOCS}${doc.file}`
        const response = await fetch(url).catch(error => console.log("No se pudo descargar un documento"))
        if (!!response) {
          const blob = await response.blob()
          cat.file(doc.file, blob)
        }
      }))

      const { subcategories } = category
      await Promise.all(subcategories.map(async subcategory => {
        const subcatDocuments = allDocuments.filter(doc => doc.category_id === category.id && doc.subcategory_id === subcategory.id)
        const subcat = cat.folder(subcategory.name)
        await Promise.all(subcatDocuments.map(async function (doc) {
          actual += 1
          self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
          const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_BRANCH_DOCS}${doc.file}`
          const response = await fetch(url).catch(error => console.log("No se pudo descargar un documento"))
          if (!!response) {
            const blob = await response.blob()
            subcat.file(doc.file, blob)
          }
        }))

        const { sub_sub_categories = [] } = subcategory
        await Promise.all(sub_sub_categories.map(async subsubcategory => {
          const subsubcatDocuments = allDocuments.filter(doc => doc.category_id === category.id && doc.subcategory_id === subcategory.id && doc.sub_subcategory_id === subsubcategory.id)
          const subsubcat = subcat.folder(subsubcategory.name)
          console.log(subsubcatDocuments.length)
          await Promise.all(subsubcatDocuments.map(async function (doc) {
            actual += 1
            self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
            const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_BRANCH_DOCS}${doc.file}`
            const response = await fetch(url).catch(error => console.log("No se pudo descargar un documento"))
            if (!!response) {
              const blob = await response.blob()
              subsubcat.file(doc.file, blob)
            }
          }))
        }))
      }))
    }))

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        // see FileSaver.js
        saveAs(content, `DocumentosCentroDeTrabajo.zip`)
        self.setState({ loadingDownload: false })
      })
  }

  render() {
    const { classes } = this.props
    const {
      categories,
      selectedCategory,
      selectedSubcategory,
      documents,
      title,
      openList,
      loading,
      loadingDownload,
      actual
    } = this.state
    const isMobile = window.innerWidth <= 900
    return (
      <DocumentContext.Provider value={{
        categories,
        documents,
        title,
        selectedCategory,
        selectedSubcategory,
        onSelect: this.handleSelectCategory,
        onEdit: this.handleSendEdit,
        onDelete: this.handleDeleteDocument,
        onSign: this.handleSignDocument,
        onCreate: this.handleCreateDocument,
        onAskSign: this.handleAskSign,
        base_url: `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_BRANCH_DOCS}`,
        downloadAll: this.handleDownloadAll,
      }}>
        <div className={classes.container}>
          <Dialog open={loadingDownload} fullWidth maxWidth="sm">
            <div style={{ padding: 24 }}>
              <Typography variant='h4'>Descargando documentos:</Typography>
              <Typography variant='h1' style={{ textAlign: 'center', margin: 24 }}>{`${actual}%`}</Typography>
            </div>
          </Dialog>
          <LoadingDialog open={loading} />
          {!isMobile ?
            <>
              <Categories categories={categories} />
              <div className={classes.verticalDivider} />
            </>
            :
            <div className={classes.mobileCategories} style={{ background: openList ? "white" : "transparent", boxShadow: openList ? "0 0 20px rgb(8 21 66 / 5%)" : "none" }}>
              <IconButton className={classes.listButton} onClick={this.handleOpenList}>
                <List />
              </IconButton>
              <Collapse in={openList}>
                <div style={{ padding: "0 6px" }}>
                  <Categories categories={categories} />
                </div>
              </Collapse>
            </div>
          }
          <div style={{ marginTop: isMobile ? 24 : 0, width: '100%' }}>
            <Documents documents={documents} />
          </div>
        </div>
      </DocumentContext.Provider>
    )
  }
}

export default withRouter(withStyles(style)(DocumentsContainer))