import React, { Component } from "react"
import moment from "moment"
import { KeyboardDatePicker } from '@material-ui/pickers/'
import autobind from "../../Utils/autobind"

export default class DateInput extends Component {
    constructor(props) {
        super(props)
        autobind(DateInput, this)
    }
    handleChange(value) {
        const { onChange, name } = this.props
        const result = value?.format('YYYY-MM-DD') || 'YYYY-MM-DD'
        const event = { target: { name, value: result } }
        onChange(event)
    }
    render() {
        const { value, label, name, disabled } = this.props
        return (
            <KeyboardDatePicker
                // disableToolbar
                autoOk
                // disablePast
                label={label}
                name={name}
                fullWidth
                disabled={disabled}
                format="YYYY-MM-DD"
                inputVariant="outlined"
                value={value || moment().format("YYYY-MM-DD")}
                onChange={this.handleChange}
                style={{ margin: "12px 0" }}
            />
        )
    }
}