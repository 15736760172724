import { Box } from '@mui/material'
import newTheme from '../../newTheme'

const css = {
	container: {
		width: '100%',
		overflow: 'auto',
		maxHeight: 450
	},
	header: {
		display: 'flex',
		width: '100%',
		position: 'sticky',
		top: 0,
		zIndex: 2
	},
	header_cell: {
		background: 'whitesmoke',
		padding: 2,
		flexBasis: 80,
		minWidth: 80,
		flexGrow: 1,
		borderBottom: '1px solid grey'
	},
	row: {
		display: 'flex',
		width: '100%'
	},
	body_cell: {
		padding: 2,
		flexBasis: 80,
		minWidth: 80,
		flexGrow: 1,
		borderBottom: '1px solid grey'
	},
	first_column: {
		background: 'whitesmoke',
		padding: 2,
		flexBasis: 80,
		minWidth: 120,
		flexGrow: 1,
		borderBottom: '1px solid grey',
		position: 'sticky',
		left: 0
	}
}

function getBackground(value) {
	const values = value.split("/")
	const [comp, total] = values.map(x => parseInt(x, 10))
	if (total === 0) return newTheme.palette.blue.white
	if (comp >= total) return newTheme.palette.blue.main
	if (comp / total < 0.5) return newTheme.palette.blue.transparent
	return newTheme.palette.blue.light
}

function FixedTable({ columns, rows }) {
	return (
		<Box sx={css.container}>
			<Box sx={css.header}>
				{columns.map((col, index) => <Box sx={css.header_cell} style={{ minWidth: index === 0 ? 120 : 80 }}>{col}</Box>)}
			</Box>
			<Box sx={css.body}>
				{rows.map(row =>
					<Box sx={css.row}>
						{row.map((value, index) => <Box
							sx={index === 0 ? css.first_column : css.body_cell}
							style={{ background: index === 0 ? "whitesmoke" : getBackground(value) }}
						>
							{value}
						</Box>
						)}
					</Box>
				)}
			</Box>
		</Box>
	)
}

export default FixedTable