import { Box, Paper, Switch, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getSettingsFindings } from '../../../API/settings'
import CreateConfiguration from './CreateConfiguration'
import FindingInfo from './FindingInfo'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import DefaultFindingStructure from './Structure/DefaultFindingStructure'
import { inline, text_space } from '../../../Utils/defaultStyles'
import { getFastFindingStatus, setFastFindingStatus } from '../../../API/enterprise'

const css = {
  container: {
    margin: 2,
    maxWidth: 950
  },
  tabs: {
    margin: '12px 0',
    borderRadius: 1
  }
}

function Tab1({ finding, reload }) {
  if (!finding) return < LoaderAnimator loading />
  return (
    <>
      {finding?.length === 0 ?
        <CreateConfiguration reload={reload} />
        :
        <FindingInfo finding={finding?.[0]} reload={reload} />
      }
    </>
  )
}

function FindingSettings({ value }) {

  const [finding, setFinding] = useState({})
  const [fastFinding, setFastFinding] = useState(false)
  const [tab, setTab] = useState(0)

  useEffect(() => {
    async function fetchData() {
      const response = await getSettingsFindings()
      setFinding(response.data.info)
    }

    async function fetchStatus() {
      const response = await getFastFindingStatus()
      setFastFinding(response.data.info)
    }

    fetchData()
    fetchStatus()
  }, [])

  async function reload() {
    const response = await getSettingsFindings()
    setFinding(response.data.info)
  }

  async function toggleFastFinding() {
    const response = await setFastFindingStatus()
    setFastFinding(response.data.info)
  }

  return (
    <Box sx={css.container}>
      <Typography variant="h1">Configuración Hallazgos</Typography>
      <Box sx={{ ...inline, ...text_space }}>
        <Switch color="info" checked={fastFinding} onClick={toggleFastFinding} />
        <Typography variant='subtitle2'>Activar levantamiento rápido de hallazgos</Typography>
      </Box>
      <Paper sx={css.tabs}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab label="Estructura" />
          <Tab label="Formulario" />
        </Tabs>
      </Paper>
      {tab === 0 ? <Tab1 reload={reload} finding={finding} /> : null}
      {tab === 1 ? <DefaultFindingStructure /> : null}
    </Box>
  )
}

export default FindingSettings