import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import autobind from '../Utils/autobind'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import MyUploadAdapter from '../Components/Talks/MyUploader'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

const style = () => ({

})

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

class RTE extends Component {

  constructor() {
    super()
    this.state = {
      openEdit: false,
      content: "",
      loading: true
    }
    autobind(RTE, this)
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  handleChange(e, editor) {
    const { onChange, name } = this.props
    const data = editor.getData()
    const event = { target: { value: data, name: name } }
    onChange(event)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ loading: false })
    }
  }

  renderEditor() {
    const { value, onBlur } = this.props
    return (
      <CKEditor
        editor={Editor}
        data={value || "<p></p>"}
        onBlur={onBlur}
        onChange={this.handleChange}
        config={{
          // fontSize: {
          //   supportAllValues: true
          // },
          toolbar: ['bold', 'italic', '|',
            "Undo",
            "Redo",
            "imageUpload",
            "insertTable",
            "link",
            '|',
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
            '|',
            'fontColor',
            'fontBackgroundColor',
            'fontSize'
          ],
          language: 'es',
          extraPlugins: [MyCustomUploadAdapterPlugin]
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log("Editor is ready to use!", editor);
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "min-height",
              "200px",
              editor.editing.view.document.getRoot()
            )
          })
        }}
      />
    )
  }

  render() {
    const { classes, disabled, value } = this.props
    const { loading } = this.state
    return (
      <div className={classes.editor}>
        {!loading && !disabled && this.renderEditor()}
        {disabled && <div dangerouslySetInnerHTML={{ __html: value }} />}
      </div>
    )
  }
}

export default withStyles(style)(RTE)