import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import PublicChecklist from "../Components/Checklists/PublicChecklist/PublicChecklist"
import ChangePassword from "../Components/Login/ChangePassword"
import Login from "../Components/Login/Login"
import RecoverPassword from "../Components/Login/RecoverPassword"
import ValidateDocument from "../Shared/ValidateDocument"

export default class PublicRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/public/:token" exact component={PublicChecklist} />
        <Route path="/validate/:code" exact component={ValidateDocument} />
        <Route path="/recoverpswd/:token" exact component={ChangePassword} />
        <Route path="/recoverpswd" exact component={RecoverPassword} />
        <Route path="*" exact component={Login} />
      </Switch>
    )
  }
}
