import { Box, Typography } from '@mui/material'
import { title_style } from '../../Utils/defaultStyles'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		margin: '12px 0'
	}
}

function VideoWatcher({ video }) {
	if (!video) return null

	function transformToEmbed(url) {
		if (url.includes("youtu.be")) {
			const split_url = url.split("/")
			const video_id = split_url[split_url.length - 1].split("&t")[0]
			return "https://www.youtube.com/embed/" + video_id
		}
		const video_id = url.split("v=")[1].split("&t")[0]
		return "https://www.youtube.com/embed/" + video_id
	}
	const video_url = video.includes("/embed/") ? video : transformToEmbed(video)
	return (
		<Box sx={css.container}>
			<Typography variant='h4' sx={title_style} >Video:</Typography>
			<iframe style={{ border: 'none' }} title="unitque" width="100%" height="500"
				src={video_url}>
			</iframe>
		</Box>
	)
}

export default VideoWatcher