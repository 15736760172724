import React, { Component } from 'react'
import { Button, IconButton, InputLabel, withStyles } from '@material-ui/core'
import ImageContainer from '../ImageContainer'
import { AddAPhoto, Delete } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { resizeImageAndGetFile } from '../../Utils/functions'

const style = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 74,
    width: '100%'
  },
  button: {
    padding: "8px 16px"
  },
  hidden: {
    display: 'none'
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 12,
    background: 'white',
    padding: 6
  },
  input: {
    border: "1px solid lightgrey",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    width: '100%',
    borderRadius: 5
  },
  images: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 48
    },
    background: 'whitesmoke',
    padding: 8,
    borderRadius: 10
  },
  image: {
    position: 'relative',
    background: 'white',
    borderRadius: 6,
    padding: 8
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    left: '95%',
    background: 'white',
    borderRadius: '0 50% 50% 0',
  }
})

function simulateClick(id) {
  return () => {
    document.getElementById(id).click()
  }
}

function completePath(file, url) {
  if (file.includes("amazonaws.com")) return file
  if (file.includes("data:")) return file
  return `${url}/${file}`
}

class ImgInput extends Component {
  constructor() {
    super()
    this.state = {
      selected: ""
    }
    autobind(ImgInput, this)
  }

  handleSelectFile(event) {
    const { onChange, name } = this.props
    const { target } = event
    const file = target.files[0]

    const callback = (image, file) => {
      const newEvent = {
        target: { name: name, value: file }
      }
      onChange(newEvent)
      this.setState({ selected: image })
    }

    resizeImageAndGetFile(file, callback)
  }

  handleDelete(index) {
    const { onDelete, name } = this.props
    onDelete(name, index)
  }

  renderImages() {
    const { value, url, classes, onDelete } = this.props
    if (url === null) return null
    if (!value) return null
    const images = value.split("&#&")
    return images.map((image, index) => {
      const final_url = completePath(image, url)
      return (
        <div className={classes.image} key={image}>
          <ImageContainer src={value ? final_url : "/noimage.png"} width="56px" height="56px" border={0} />
          {!!onDelete &&
            <IconButton size="small" color="secondary" className={classes.deleteButton} onClick={e => this.handleDelete(index)}>
              <Delete />
            </IconButton>
          }
        </div>
      )
    })
  }

  render() {
    const { label, name, disabled, classes, required } = this.props
    const { selected } = this.state
    return (
      <>
        <div className={classes.container}>
          <input type="file" id={name} className={classes.hidden} onChange={this.handleSelectFile} />
          <InputLabel shrink className={classes.label}>
            {`${label}${required ? "*" : ""}`}
          </InputLabel>
          <div className={classes.input}>
            <ImageContainer src={selected ? selected : "/noimage.png"} width="32px" height="32px" />
            <Button variant="outlined" color="primary" className={classes.button} onClick={simulateClick(name)} disabled={disabled}>
              <AddAPhoto />
            </Button>
          </div>
        </div>
        <div className={classes.images}>
          {this.renderImages()}
        </div>
      </>
    )
  }
}

export default withStyles(style)(ImgInput)