import { Box } from '@mui/material'
import Loader from './Loader'

const css = {
	container: {
		height: 150,
		position: 'relative'
	}
}

function LoadingBox({ value }) {
	return (
		<Box sx={css.container}>
			<Loader />
		</Box>
	)
}

export default LoadingBox