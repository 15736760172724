import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import ImageContainer from '../../../Shared/ImageContainer'
import { ErrorOutline } from '@material-ui/icons'
import MultipleImageContainer from '../../../Shared/MultipleImageContainer'

const style = () => ({
	finding: {
		background: 'whitesmoke',
		borderRadius: 6,
		padding: 12,
		margin: '12px 0',
		position: 'relative'
	},
	crit: {
		position: 'absolute',
		bottom: 12,
		right: 12,
		height: 32,
		width: 32
	}
})
class CreatedFinding extends Component {
	constructor() {
		super()
		this.state = {
			image: "/noimage.png"
		}
	}

	render() {
		const { classes, finding } = this.props
		const colors = [
			"#6a6a6a",
			"#a3b8ff",
			"#f6ec79",
			"#ffab6e",
			"#ff6c87",
		]
		const images = finding.images ? finding.images : (finding?.evidence?.split("&#&") || [])
		return (
			<div className={classes.finding}>
				<Typography variant='h4'>Hallazgo: {finding.checklist_item_name || finding.free_description}</Typography>
				<Typography variant='subtitle1'>{finding?.user || "En proceso"}</Typography>
				<Typography variant='subtitle1'>Acción inmediata: {finding?.inmediate_action}</Typography>
				<Typography variant='caption' style={{ marginBottom: 8 }}>Fecha límite: {finding?.date_limit}</Typography>
				<MultipleImageContainer images={images} height={64} width={64} border={12} />
				<ErrorOutline className={classes.crit} style={{ color: colors[finding.criticality || finding.free_criticality] }} />
			</div>
		)
	}
}

export default withStyles(style)(CreatedFinding)