import React, { Component } from "react"
import { withStyles, Typography, Divider } from "@material-ui/core"
import GeneralTable from "../../Shared/GeneralTable"
import AddElementButton from "../../Shared/AddElementButton"
import autobind, { addToggle } from "../../Utils/autobind"
import { createBranchAction } from "../../Actions/BranchActions"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { Category, CategoryOutlined, ExitToApp } from "@material-ui/icons"
import { withRouter } from "react-router-dom"
import GeneralForm from "../../Shared/GeneralForm"
import { branchInfo } from "./Info"
import FormContext from "../../Shared/Cards/Provider"
import { getBranches } from "../../Actions/EnterpriseAction"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import PermissionChecker from "../../Shared/Permissions/PermissionChecker"
import { filterActiveBranches } from "../../Utils/filters"
import BranchesTags from "./BranchesTags"
import { assignTag } from "../../API/branches"
import AssignBranchTagDialog from "./AssignBranchTagDialog"
import TagCell from "../../Shared/TableRenders/TagCell"

const style = () => ({
    container: {
        padding: 12,
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    formContainer: {
        overflowY: "hidden",
        transition: "all 0.2s linear",
    },
    info: {
        width: "100%",
        maxWidth: 200,
        display: "block",
        marginRight: 24,
        "@media (max-width:500px)": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "unset",
            marginRight: 0,
        },
    },
    input: {
        margin: "12px 0 ",
    },
    button: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 12,
        alignItems: "center",
    },
    flex: {
        display: "flex",
        flexWrap: "wrap",
    },
})

const tableInfo = [
    { name: "Nombre", label: "name" },
    { name: "Dirección", label: "address" },
    { name: "Grupos", label: "tags", render: TagCell }
]

class Branches extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            params: {},
            loading: true,
            selectedBranch: null
        }
        addToggle(Branches, this, "assign")
        autobind(Branches, this)
    }

    componentDidMount() {
        const { getBranches } = this.props
        this.setState({ loading: true })
        getBranches().then(() => this.setState({ loading: false }))
    }

    handleChange(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    handleGoto(row) {
        return () => {
            const { history } = this.props
            history.push(`/branches/${row.id}`)
        }
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    handleSend() {
        const { createBranch, enqueueSnackbar } = this.props
        const { params } = this.state
        const body = { ...params }
        body.companyId = 4
        createBranch(body, enqueueSnackbar)
        this.setState({ open: false, params: {} })
    }

    async handleAssign(body) {
        await assignTag(body)
        this.componentDidMount()
    }

    handleCloseAssign() {
        this.setState({ openAssign: false, selectedBranch: null })
    }

    handleOpenDialog(row) {
        return () => {
            this.setState({ selectedBranch: row, openAssign: true })
        }
    }

    render() {
        const { open, params, loading, openAssign, selectedBranch } = this.state
        const { classes, user, enterprise } = this.props
        const branches = enterprise.branches || []
        // const hasBranches = user?.account?.user?.branch_ids?.length > 0
        let filteredBranches = filterActiveBranches(branches)
        const level = user.account.user.userType
        const actions = level === 1 ? [
            { name: "Asignar grupo", icon: CategoryOutlined, action: this.handleOpenDialog, color: "primary" },
            { name: "Ver centro de trabajo", icon: ExitToApp, action: this.handleGoto, color: "primary" },
        ] : []
        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <Typography variant="h1">
                        Centros de trabajo
                    </Typography>
                    <PermissionChecker expectedPermissions={["cbr"]}>
                        {level === 1 && <AddElementButton name="Agregar centro" onClick={this.handleOpen} open={open} />}
                    </PermissionChecker>
                </div>
                <FormContext.Provider value={{ handleChange: this.handleChange, params }}>
                    <GeneralForm
                        open={open}
                        info={branchInfo}
                        onSubmit={this.handleSend}
                        submit="Crear Centro"
                        title="Nueva centro de trabajo"
                    />
                </FormContext.Provider>
                <Divider style={{ marginBottom: 24 }} />
                <BranchesTags />
                <AssignBranchTagDialog onSubmit={this.handleAssign} open={openAssign} onClose={this.handleCloseAssign} branch={selectedBranch} />
                {loading ?
                    <LoaderAnimator loading />
                    :
                    <GeneralTable
                        data={filteredBranches}
                        info={tableInfo}
                        actions={actions}
                    />
                }
            </div>
        )
    }
}

Branches.propTypes = {}

Branches.defaultProps = {
    branches: [],
}

const mapStateToProps = state => ({
    user: state.user,
    enterprise: state.enterprise
})

const mapDispatchToProps = (dispatch) => ({
    createBranch: (body, snackbar) => dispatch(createBranchAction(body, snackbar)),
    getBranches: (body) => dispatch(getBranches(body)),
})

export default withRouter(
    withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Branches)))
)
