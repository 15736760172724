import React, { Component } from "react"
import { withStyles, Typography, Divider, Tab, Paper, Switch, Button } from "@material-ui/core"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import { connect } from "react-redux"
import { createSubBranchAction, getBranchAction, getBranchDocumentsAction, getSubBranchesAction } from "../../Actions/BranchActions"
import GeneralTable from "../../Shared/GeneralTable"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import MyTabs from "../../Shared/MyTabs"
import { editBranchAction } from "../../Actions/EnterpriseAction"
import { branchInfo, subBranchInfo } from "../Enterprise/Info"
import { ExitToApp } from "@material-ui/icons"
import AddElementButton from "../../Shared/AddElementButton"
import FormContext from "../../Shared/Cards/Provider"
import GeneralForm from "../../Shared/GeneralForm"
import { getSettingsBranchCategoryAction } from "../../Actions/SettingsActions"
import { changeBranchLogo, toggleActiveBranch, toggleOverwriteBranch } from "../../API/branches"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import DocumentsContainer from "./Documents/DocumentsContainer"
import InternalStructure from "./InternalStructure"

const style = () => ({
	container: { margin: 12 },
	title: {
		margin: "26px 0 12px 0 ",
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	togglers: {
		background: 'white',
		padding: 12,
		borderRadius: 12,
		margin: '12px 0',
		maxWidth: 500,
		position: 'relative',
		minHeight: 80
	},
	toggle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '12px 0'
	}
})

const info = [{ name: "Nombre", label: "name" }]

function simulateClick() {
	document.getElementById("logo-selector").click()
}

class Branch extends Component {
	constructor(props) {
		super(props)
		this.state = {
			params: {},
			tab: 3,
			loading: true,
			open: false,
			create_params: {}
		}
		autobind(Branch, this)
	}

	componentDidMount() {
		const { match, getBranch, user, getSubBranches, getSettingsBranchCategories, getBranchDocuments } = this.props
		const { id } = match.params
		const companyId = user.account.user.idCompany
		const body = { id, companyId: companyId }
		const docBody = { branch_id: id }
		this.setState({ loading: true })
		getBranchDocuments(docBody)
		getSettingsBranchCategories()
		getBranch(body).then(() => {
			this.setState({ loading: false })
		})
		getSubBranches({ branch_id: id })
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	handleOpen() {
		this.setState({ open: !this.state.open })
	}

	handleSend(params) {
		const { editBranch, enqueueSnackbar, branch, enterprise } = this.props
		const body = { ...params }
		body.id = branch.selected.id
		body.companyId = enterprise.selected.id
		editBranch(body, enqueueSnackbar)
	}

	handleGoto(option) {
		return (row) => {
			return () => {
				const { history } = this.props
				history.push(`${option}/${row.id}`)
			}
		}
	}

	handleToggle(option) {
		return async () => {
			const { match, branch } = this.props
			if (option === "active") {
				const body = {
					id: match.params.id,
					active: branch.selected.active === 0 ? 1 : 0,
				}
				await toggleActiveBranch(body)
				this.componentDidMount()
			} else {
				const body = {
					id: match.params.id,
					overwrite: branch.selected.overwrite === 0 ? 1 : 0,
				}
				await toggleOverwriteBranch(body)
				this.componentDidMount()
			}
		}
	}

	handleChange(event) {
		const { target } = event
		const { create_params } = this.state
		create_params[target.name] = target.value
		this.setState({ create_params })
	}

	handleCreate() {
		const { match, createSubBranch } = this.props
		const { create_params } = this.state
		const body = {
			...create_params,
			branch_id: match.params.id
		}
		createSubBranch(body)
		this.setState({ create_params: {}, open: false })
	}

	async handleChangeLogo(event) {
		const { target } = event
		const { branch } = this.props
		const selected = branch.selected
		const file = target.files[0]
		console.log(file)
		const body = new FormData()
		body.append("id", selected.id)
		body.append("file", file)
		await changeBranchLogo(body)
		this.componentDidMount()
	}

	render() {
		const { classes, branch, match } = this.props
		const { loading, tab, open, create_params } = this.state
		const selected = branch.selected
		const users = selected ? selected.users : []
		const sub_branches = branch?.sub_branches || []
		const actions = (url) => [
			{
				name: "Ver Colaborador",
				icon: ExitToApp,
				action: this.handleGoto(url),
			},
		]

		return (
			<div className={classes.container}>
				<MutableInfoCard
					title="Datos centro de trabajo"
					submit="Guardar Cambios"
					subtitle="Editar centro de trabajo"
					submitAction={this.handleSend}
					formInfo={branchInfo}
					showInfo={branchInfo}
					response={selected}
					loading={loading}
				/>
				<div className={classes.togglers}>
					{loading ?
						<LoaderAnimator loading />
						:
						<>
							<div className={classes.toggle}>
								<Typography variant="subtitle1">Centro de Trabajo {selected.active === 0 ? "activo" : "inactivo"}</Typography>
								<Switch onClick={this.handleToggle("active")} checked={selected.active === 0} />
							</div>
							<div className={classes.toggle}>
								<Typography variant="subtitle1">Sobreescribir información de empresa para documentos</Typography>
								<Switch onClick={this.handleToggle("overwrite")} checked={selected.overwrite === 1} />
							</div>
						</>
					}
				</div>
				{selected.overwrite === 1 &&
					<div className={classes.togglers}>
						<Typography variant="h4" style={{ marginBottom: 12 }}>Logo de centro de trabajo</Typography>
						<input type="file" style={{ display: 'none' }} id="logo-selector" onChange={this.handleChangeLogo} />
						<div style={{ textAlign: 'center' }}>
							<img src={
								selected.logo ?
									`${process.env.REACT_APP_IMG_URL}/${process.env.REACT_APP_ENTERPRISE_LOGO}${selected.logo}` :
									"/noimage.png"
							} alt="logoc" width="50%" />
						</div>
						<div style={{ marginTop: 12, textAlign: 'end' }}>
							<Button onClick={simulateClick} color="primary" variant="contained">
								Seleccionar foto
							</Button>
						</div>
					</div>
				}
				<div />
				<Paper square className={classes.title}>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h4">
									Colaboradores
								</Typography>
							}
							value={0}
						/>
						<Tab
							label={
								<Typography variant="h4">
									Contratistas
								</Typography>
							}
							value={1}
						/>
						<Tab
							label={
								<Typography variant="h4">
									Documentos
								</Typography>
							}
							value={2}
						/>
						<Tab
							label={
								<Typography variant="h4">
									Estructura
								</Typography>
							}
							value={3}
						/>
					</MyTabs>
				</Paper>
				<TabPanel value={tab} index={0}>
					<Typography variant="h1" className={classes.title}>
						Tabla de Colaboradores
					</Typography>
					<Divider style={{ marginBottom: 24 }} />
					<GeneralTable
						data={users}
						info={info}
						actions={actions('/workers')}
					/>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<div className={classes.inline}>
						<Typography variant="h1">
							Tabla de Contratistas
						</Typography>
						<AddElementButton name="Agregar contratista" onClick={this.handleOpen} open={open}></AddElementButton>
					</div>
					<FormContext.Provider value={{ handleChange: this.handleChange, params: create_params }}>
						<GeneralForm
							open={open}
							info={subBranchInfo}
							onSubmit={this.handleCreate}
							submit="Crear Contratista"
							title="Nueva contratista"
						/>
					</FormContext.Provider>
					<Divider style={{ marginBottom: 24 }} />
					<GeneralTable
						data={sub_branches}
						info={info}
						actions={actions(`/branches/${match.params.id}/sub_branches`)}
					/>
				</TabPanel>
				<TabPanel value={tab} index={2}>
					<div className={classes.inline}>
						<Typography variant="h1">
							Documentos del Centro de Trabajo
						</Typography>
					</div>
					<DocumentsContainer />
				</TabPanel>
				<TabPanel value={tab} index={3}>
					<InternalStructure branch={selected} />
				</TabPanel>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	enterprise: state.enterprise,
	user: state.user,
	branch: state.branch,
	settings: state.settings
})

const mapDispatchToProps = (dispatch) => ({
	getBranch: (body) => dispatch(getBranchAction(body)),
	editBranch: (body, snackbar) => dispatch(editBranchAction(body, snackbar)),
	getSubBranches: body => dispatch(getSubBranchesAction(body)),
	createSubBranch: body => dispatch(createSubBranchAction(body)),
	getBranchDocuments: body => dispatch(getBranchDocumentsAction(body)),
	getSettingsBranchCategories: body => dispatch(getSettingsBranchCategoryAction(body)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Branch))
