import React, { Component } from "react"
import { TextField, Typography } from "@material-ui/core"
import autobind from "../../Utils/autobind"
import { getUserByRut } from '../../API/users'

export default class RutInput extends Component {
    constructor() {
        super()
        this.state = {
            valid: true,
            user: ""
        }
        autobind(RutInput, this)
    }

    handleChange(event) {
        const { target } = event
        const { onChange } = this.props
        const { name, value: newValue } = target
        // Format rut
        const replacedValue = newValue.replaceAll(".", "").replace("-", "")
        if (replacedValue.length < 7) {
            const newEvent = {
                target: {
                    value: replacedValue,
                    name
                }
            }
            return onChange(newEvent)
        }

        const cuerpo = replacedValue.slice(0, -1)
        const dv = replacedValue.slice(-1).toUpperCase()

        const finalValue = cuerpo + "-" + dv
        const newEvent = {
            target: {
                name,
                value: finalValue,
            }
        }
        onChange(newEvent)

        let suma = 0
        let multiplo = 2

        for (let i = 1; i <= cuerpo.length; i++) {

            // Obtener su Producto con el Múltiplo Correspondiente
            let index = multiplo * replacedValue.charAt(cuerpo.length - i)

            // Sumar al Contador General
            suma = suma + index

            // Consolidar Múltiplo dentro del rango [2,7]
            if (multiplo < 7) { multiplo = multiplo + 1 } else { multiplo = 2 }

        }

        const dvEsperado = 11 - (suma % 11)

        // Casos Especiales (0 y K)
        let dvalue = parseInt(dv, 10)
        if (dv === 'K') { dvalue = 10 }
        if (dv === '0') { dvalue = 11 }

        if (dvEsperado !== dvalue) { this.setState({ valid: false, user: "" }) }
        else { this.setState({ valid: true }, this.handleGetWorker) }
    }

    async handleGetWorker() {
        const { value } = this.props
        const response = await getUserByRut({ rut: value })
        console.log(response)
        const { data: { info: user } } = response
        console.log(user)
        this.setState({ user })
        if (user === "Usuario no existe.") {
            const event = new CustomEvent("userbyid", { detail: { rut: value } })
            return window.dispatchEvent(event)
        }
        const event = new CustomEvent("userbyid", { detail: user })
        return window.dispatchEvent(event)
    }

    render() {
        const { valid, user } = this.state
        const { value, label, name, type, disabled, options, required, onBlur, autoFocus } = this.props
        const isUserValid = typeof user !== "string"
        return (
            <>
                <TextField
                    value={value || ""}
                    disabled={disabled}
                    onChange={this.handleChange}
                    label={`${label}${required ? "*" : ""}`}
                    name={String(name)}
                    variant="outlined"
                    fullWidth
                    style={{ margin: "12px 0" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type={type}
                    rows={options.rows}
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                    multiline={options.multiline || false}
                    placeholder={options.placeholder}
                />
                {!valid && <Typography variant="caption" style={{ color: 'red' }}>Rut inválido</Typography>}
                {isUserValid && <div style={{ marginBottom: 12, marginTop: -12 }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 600, fontStyle: "italic" }}>
                        {user.name} - {user.position}
                    </Typography>
                </div>}
            </>
        )
    }
}

RutInput.propTypes = {

}


RutInput.defaultProps = {
    options: {}
}