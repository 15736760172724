import React, { Component } from 'react'
import { Button, Checkbox, Collapse, IconButton, Switch, Tooltip, Typography, withStyles } from '@material-ui/core'
import { CategoryOutlined, KeyboardArrowDown, NotificationsActiveOutlined } from '@material-ui/icons'
import SingleElement from './SingleElement'
import ChecklistContext from '../ChecklistContext'
import autobind, { addToggle } from '../../../Utils/autobind'

const style = (theme) => ({
	categoryTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '12px 0',
		'& > svg': {
			height: 24,
			width: 24,
			marginRight: 6
		}
	},
	innerTitle: {
		display: 'flex',
		alignItems: 'center',
		'& > svg': {
			height: 24,
			width: 24,
			marginRight: 6
		}
	},
	category: {
		margin: '24px 0'
	},
	inline: {
		display: 'flex',
		alignItems: 'center',
	},
	inlineContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'stretch',
		background: 'white',
		borderRadius: 8,
		padding: 12,
		margin: '12px 0'
	},
	defaultMarker: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	blue: {
		color: theme.palette.blue.main,
	},
	endOfCard: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'space-between'
	}
})
class ElementsCategory extends Component {
	constructor() {
		super()
		this.state = {
			openCollapse: true
		}
		addToggle(ElementsCategory, this, "collapse", true)
		autobind(ElementsCategory, this)
	}

	componentDidMount() {
		const { disabledCategory } = this.props
		this.setState({ openCollapse: !disabledCategory })
	}

	render() {
		const { classes,
			category,
			disabledCategory,
			disabledCategories,
			disabledUsers,
			categoryElements,
			responsibleUsers,
			users,
			onAnswer,
			itsOnly
		} = this.props
		const { checklist, current, disableUser, finished, notify, disableCategory, disabled } = this.context
		const { openCollapse } = this.state
		const responsable = String(checklist?.user_id)
		const currentUser = current?.id
		const notActualResponsable = responsable !== currentUser
		const actualResponsible = responsable === currentUser
		return (
			<div className={classes.container}>
				<div className={classes.category} key={category?.id}>
					< div className={classes.categoryTitle} style={{ opacity: !disabledCategory ? 1 : 0.5 }} >
						{!itsOnly && <div className={classes.innerTitle} >
							<CategoryOutlined color="secondary" />
							<Typography variant="h4">{category?.name}</Typography>
							<IconButton onClick={this.handleOpenCollapse}>
								<KeyboardArrowDown />
							</IconButton>
						</div>}
						{(current.userType === 1 || actualResponsible) && users.length > 0 &&
							<Switch onClick={disableCategory(category?.id)} checked={!disabledCategories.includes(category?.id)} />
						}
					</div >
					{(!notActualResponsable || current.userType === 1) && !!responsibleUsers.length &&
						(
							<div className={classes.inlineContainer}>
								<div>
									<Typography variant='subtitle1'>Responsables: </Typography>
									{responsibleUsers.map(user => {
										return (
											<div className={classes.inline} key={user.id}>
												<Checkbox
													disabled={finished}
													color="primary"
													checked={!disabledUsers.includes(user.id)}
													onClick={disableUser(user.id, category.id)}
												/>
												<Typography variant='subtitle1'>{`${user.name} - ${user.enterprise_name || ""} - ${user.position}`}</Typography>
											</div>
										)
									})}
								</div>
								<div className={classes.endOfCard}>
									<Tooltip title="Enviar notificación a los responsables">
										<IconButton onClick={() => notify(category)}>
											<NotificationsActiveOutlined />
										</IconButton>
									</Tooltip>
								</div>
							</div>
						)
					}
					<Collapse in={openCollapse}>
						{(current.userType === 1 || actualResponsible) &&
							<div className={classes.defaultMarker}>
								<Button
									variant="outlined"
									className={`${classes.button} ${classes.blue}`}
									onClick={onAnswer(categoryElements)}
									disabled={finished || disabledCategory}
								>
									Marcar Todos N/A
								</Button>
							</div>
						}
						{categoryElements.map(item => <SingleElement disabled={disabledCategory} item={item} key={item.id} />)}
					</Collapse>
				</div>
			</div>
		)
	}
}

ElementsCategory.contextType = ChecklistContext

export default withStyles(style)(ElementsCategory)