import React, { Component } from 'react'
import { Dialog, FormControl, IconButton, InputAdornment, InputLabel, TextField, Typography, withStyles } from '@material-ui/core'
import { HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
import autobind, { addToggle } from '../../Utils/autobind'
import TextInput from './TextInput'
import MultiGroups from './InputUtils/MultiGroups'
import SeamlessInput from './SeamlessInput'

const style = () => ({
	dialog: {
		padding: 12,
		'& > *': {
			margin: '12px 0'
		}
	},
	optionsContainer: {
		maxHeight: 400,
		overflow: 'auto'
	},
	selected: {
		background: 'whitesmoke',
		borderRadius: 8,
		'& > *': {
			padding: 8
		},
		maxHeight: 150,
		overflow: 'auto'
	},
	selection: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& > *:first-child': {
			flexGrow: 1,
			width: '100%',
			maxWidth: 300
		},
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
})

function sortOptions(a, b) {
	if (a.label > b.label) return 1
	if (a.label < b.label) return -1
	return 0
}

class NewMultiSelectInput extends Component {
	constructor() {
		super()
		this.state = {
			search: "",
			comments: {}
		}
		addToggle(NewMultiSelectInput, this, "select")
		autobind(NewMultiSelectInput, this)
	}

	componentDidMount() {
		const { value } = this.props
		const rValue = Array.isArray(value) ? value : []
		const newMessages = {}
		rValue.forEach(v => {
			const [option, message] = v?.toString().split(":")
			if (!!message) {
				newMessages[option] = message
			}
		})
		this.setState({ comments: newMessages })
	}

	handleSearch(event) {
		const { target } = event
		this.setState({ search: target.value })
	}

	handleFilterSearch(option) {
		const { search } = this.state
		return option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
	}

	handleChangeMessage(event) {
		const { onChange, name, value } = this.props
		const { comments } = this.state
		const { target } = event
		comments[target.name] = target.value
		console.log(target.name)
		console.log(target.value)
		console.log(comments)
		this.setState({ comments })

		const rValue = Array.isArray(value) ? value : []
		const cleanedValues = rValue.map(v => v.toString().split(":")[0])

		const finalValue = cleanedValues.map(v => `${v}:${comments[v]}`)
		console.log(finalValue)

		const newEvent = {
			target: {
				name,
				value: finalValue
			}
		}

		onChange(newEvent)
	}

	handleChange(option) {
		return () => {
			const { name, value, onChange } = this.props
			const rValue = Array.isArray(value) ? value : []
			const cleanedValues = rValue.map(v => v.toString().split(":")[0])
			if (cleanedValues.includes(option.value)) {
				rValue.splice(cleanedValues.findIndex(v => v === option.value), 1)
				const event = { target: { name, value: rValue } }
				return onChange(event)
			}
			rValue.push(option.value)
			const event = { target: { name, value: rValue } }
			return onChange(event)
		}
	}

	handleCloseMenu() {
		const { onBlur, value } = this.props
		onBlur && onBlur(value)
		this.handleOpenSelect()
	}

	renderGroupedOptions() {
		const { grouped, options = [], classes, value } = this.props
		const rValue = Array.isArray(value) ? value : []
		const cleanedValues = rValue.map(v => v.toString().split(":")[0])
		const filteredOptions = options.filter(this.handleFilterSearch).sort(sortOptions)
		const groups = Array.from(new Set(filteredOptions.map(grouped)))
		return (
			<div className={classes.optionsContainer}>
				{groups.map((group, gindex) => {
					const groupOptions = filteredOptions.filter(option => grouped(option) === group)
					return <MultiGroups key={gindex} value={cleanedValues} group={group} options={groupOptions} onChange={this.handleChange} />
				})}
			</div>
		)
	}

	renderSelected() {
		const { options = [], value, classes, hasComments } = this.props
		const { comments } = this.state
		const rValue = Array.isArray(value) ? value : []
		const cleanedValues = rValue.map(v => v.toString().split(":")[0])
		const selectedOptions = options.filter(option => cleanedValues.includes(option.value))
		return (
			<div className={classes.selected}>
				{selectedOptions.map((option, index) => {
					return (
						<div className={classes.selection}>
							<Typography variant='subtitle1'>{index + 1}. {option.label}</Typography>
							{hasComments &&
								<SeamlessInput value={comments[option.value]} placeholder="Comentario..." name={option.value} onChange={this.handleChangeMessage} />
							}
						</div>
					)
				}
				)}
			</div>
		)
	}

	renderMenu() {
		const { label, classes } = this.props
		const { search } = this.state
		return (
			<>
				<div className={classes.header}>
					<Typography variant='h1'>{`Seleccionar ${label}`}</Typography>
					<IconButton onClick={this.handleCloseMenu}>
						<HighlightOffOutlined />
					</IconButton>
				</div>
				{this.renderSelected()}
				<TextInput value={search} onChange={this.handleSearch} label={`Buscar ${label}`} />
				{this.renderGroupedOptions()}
			</>
		)
	}

	transformValues(value) {
		const rValue = Array.isArray(value) ? value : []
		const cleanedValues = rValue.map(v => v.toString().split(":")[0])
		const { options = [] } = this.props
		const selectedNames = options.filter(option => cleanedValues.includes(option.value)).map(o => o.label)
		const final = selectedNames.join(", ")
		return final
	}

	render() {
		const { openSelect } = this.state
		const { classes, value, onChange, disabled, label, required, name } = this.props
		const sValue = value || []
		return (
			<>
				<FormControl fullWidth style={{ margin: "12px 0" }}>
					<TextField
						value={String(this.transformValues(sValue)) || []}
						fullWidth
						name={String(name)}
						onChange={onChange}
						variant="outlined"
						multiple
						disabled
						onClick={!disabled && this.handleOpenSelect}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<KeyboardArrowDown />
								</InputAdornment>
							),
						}}
					/>
					<Dialog open={openSelect} onClose={this.handleCloseMenu} fullWidth maxWidth="sm">
						<div className={classes.dialog}>
							{this.renderMenu()}
						</div>
					</Dialog>
					<InputLabel shrink variant="outlined">
						{`${label}${required ? "*" : ""}`}
					</InputLabel>
				</FormControl>
			</>
		)
	}
}

NewMultiSelectInput.propTypes = {

}


NewMultiSelectInput.defaultProps = {
	grouped: (option) => ""
}

export default withStyles(style)(NewMultiSelectInput)