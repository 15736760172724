import { Box, Radio, Typography } from '@mui/material'

const css = {
	option: {
		display: 'flex',
		alignItems: 'center'
	},
	container: {
		textAlign: 'start'
	}
}

function Option({ option, value, onClick }) {
	return (
		<Box sx={css.option} onClick={() => onClick(option)}>
			<Radio checked={value === option} />
			<Typography variant='subtitle1'>{option}</Typography>
		</Box>
	)
}

function RadioInput({ options, value, onChange, name, label = "" }) {

	function onClick(value) {
		const event = {
			target: { name, value }
		}
		onChange(event)
	}

	return (
		<Box sx={css.container} >
			{label && <Typography variant='subtitle1' style={{ paddingLeft: 12 }} ><strong>{label}</strong></Typography>}
			{options.map(option => <Option value={value} onClick={onClick} option={option} />)}
		</Box>
	)
}

export default RadioInput