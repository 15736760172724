import store from "../../store"



function PermissionBoolean(expectedPermissions = []) {
  const user = store.getState().user
  const workers = store.getState().workers
  const actualUser = user?.account?.user
  const allWorkers = workers.all || []
  const actualWorker = allWorkers.find(w => w.id === actualUser.id)

  const passPermissions = expectedPermissions
    .map(expectedType => !actualWorker?.permissions?.includes(expectedType))
    .filter(Boolean)
    .length > 0
  if (!passPermissions) return false
  return true
}

export default PermissionBoolean