import { ArrowDownward, Filter, FilterList, HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
import { Box, Button, Checkbox, Dialog, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Radio, Select, TextField, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { getInputUsers } from '../../API/users'
import AppContext from '../../AppContext'
import useToggle from '../../Hooks/ToogleHook'
import { getUserTypeFilter, saveUserTypeFilter, transformToOptions } from '../../Utils/functions'
import RadioInput from './RadioInput'
import TextInput from './TextInput'

const css = {
	dialog: {
		padding: 2,
		position: 'relative'
	},
	options: {
		maxHeight: 250,
		overflow: 'auto',
		margin: '12px 0'
	},
	option: {
		display: 'flex',
		alignItems: 'center'
	},
	section: {
		margin: "12px 0"
	},
	close: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	sorts: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
		flexWrap: 'wrap'
	},
	sort: {
		padding: 1,
		background: 'whitesmoke',
		borderRadius: 1,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		gap: 2
	},
	radios: {
		margin: '6px 0',
		textAlign: 'end'
	}
}

const sorts = {
	created_at: "Fecha de ingreso",
	rut: "Rut",
	name: "Nombre",
}

function UsersInput({ value, label, name, disabled = false, required, inputStyle, onChange, options: input_type, onBlur }) {
	const isMultiple = input_type === "multiple"
	let sValue = isMultiple ? [] : ""
	sValue = !!value ? value : sValue
	const [data, setData] = useState([])
	const [search, setSearch] = useState("")
	const [openMenu, toggleMenu] = useToggle(false)
	const [sortBy, setSort] = useState("created_at")
	const [openFilters, toggleFilters] = useToggle(false)
	const [userTypeFilter, selectFilter] = useState(getUserTypeFilter())
	const { online } = useContext(AppContext)

	useEffect(() => {
		async function fetchData() {
			if (online.status) {
				const response = await getInputUsers()
				setData(response.data.info)
			} else {
				const users = JSON.parse(localStorage.getItem("user-input")) || []
				setData(users)
			}

		}
		fetchData()
	}, [online])

	function onClick(option) {
		return () => {
			const event = {
				target: {
					name,
					value: option.value
				}
			}
			onChange(event)
			toggleMenu()
		}
	}

	function onClickMultiple(option) {
		return () => {
			const sValue = value || []
			const newData = [...sValue]
			if (newData.includes(option.value)) {
				newData.splice(newData.findIndex(o => o === option.value), 1)
			} else {
				newData.push(option.value)
			}
			const event = {
				target: {
					name,
					value: newData
				}
			}
			onChange(event)
		}
	}

	function closeMultiple() {
		if (!!onBlur && isMultiple) { onBlur() }
		toggleMenu()
	}

	function renderInnerMenu() {
		const options = transformToOptions(data)
		return options.map((option) => {
			return (
				<MenuItem value={option.value} key={option.value}>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						{option.color &&
							<div style={{ height: 12, width: 12, background: option.color, borderRadius: '50%', marginRight: 12 }} />
						}
						<Typography variant='subtitle1'>{option.label}</Typography>
					</Box>
				</MenuItem>
			)
		})
	}

	function onChangeFilter(event) {
		const { target: { value } } = event
		selectFilter(value)
		saveUserTypeFilter(value)
	}

	function transformValues(value) {
		const rValue = Array.isArray(value) ? value : []
		const options = transformToOptions(data)
		const selectedNames = options.filter(option => rValue.includes(option.value)).map(o => o.label)
		const final = selectedNames.join(", ")
		return final
	}

	function transformFilterToInt(filter) {
		if (filter === "Administradores") return 1
		if (filter === "Supervisores") return 2
		if (filter === "Colaboradores") return 3
		return 0
	}

	function renderSelectMenu() {
		const raw_options = transformToOptions(data)
		const options = raw_options
			.filter(option => !transformFilterToInt(userTypeFilter) || option.user_type_id === transformFilterToInt(userTypeFilter))
			.filter(option => option.label.toLowerCase()
				.includes(search.toLocaleLowerCase())).sort((a, b) => {
					if (a[sortBy] > b[sortBy]) return 1
					if (a[sortBy] < b[sortBy]) return -1
					return 0
				})
		const supervised = options.filter(op => String(op.user_id) === "31")
		const other = options.filter(op => String(op.user_id) !== "31")
		return (
			<Dialog open={openMenu} onClose={closeMultiple} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<IconButton onClick={closeMultiple} sx={css.close}>
						<HighlightOffOutlined />
					</IconButton>
					<Box sx={css.section}>
						<Typography variant='h1'>Seleccionar {label}</Typography>
					</Box>
					<Box sx={css.sorts}>
						{Object.keys(sorts).map(key => {
							return (
								<Box sx={css.sort} onClick={() => setSort(key)}>
									{sorts[key]}
									{sortBy === key ? <ArrowDownward /> : null}
								</Box>
							)
						})}
					</Box>
					<Box sx={css.radios}>
						<Tooltip title="Filtros">
							<IconButton onClick={toggleFilters}>
								<FilterList />
							</IconButton>
						</Tooltip>
						{openFilters &&
							<RadioInput
								label='Filtro por tipo de usuario'
								value={userTypeFilter}
								onChange={onChangeFilter}
								options={["Todos", "Administradores", "Supervisores", "Colaboradores"]}
							/>
						}
					</Box>
					<Box sx={css.section}>
						<TextInput
							label="Buscar por nombre"
							placeholder="Buscar..."
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
					</Box>
					<Typography variant='h4'>Supervisados</Typography>
					<Box sx={css.options}>
						{supervised.map(option => {
							const isClicked = isMultiple ?
								value?.includes(String(option.value))
								: String(value) === String(option.value)
							return (
								<Box sx={css.option} key={option.value} onClick={isMultiple ? onClickMultiple(option) : onClick(option)}>
									{isMultiple ? <Checkbox checked={isClicked} /> : <Radio checked={isClicked} />}
									<Typography variant='subtitle1'>{option.label}</Typography>
								</Box>
							)
						})}
					</Box>
					<Typography variant='h4'>Otros</Typography>
					<Box sx={css.options}>
						{other.map(option => {
							const isClicked = isMultiple ?
								value?.includes(String(option.value))
								: String(value) === String(option.value)
							return (
								<Box sx={css.option} key={option.value} onClick={isMultiple ? onClickMultiple(option) : onClick(option)}>
									{isMultiple ? <Checkbox checked={isClicked} /> : <Radio checked={isClicked} />}
									<Typography variant='subtitle1'>{option.label}</Typography>
								</Box>
							)
						})}
					</Box>
					<Box style={{ textAlign: 'end' }}>
						<Button color="primary" variant="outlined" onClick={closeMultiple}>
							Terminar
						</Button>
					</Box>
				</Box>
			</Dialog>
		)
	}

	return (
		<Box>
			<Box style={{ position: 'relative', width: '100%' }}>
				<FormControl fullWidth style={{ margin: "12px 0" }}>
					{isMultiple ?
						<TextField
							value={transformValues(sValue)}
							fullWidth
							name={name}
							onChange={onChange}
							variant="outlined"
							displayEmpty
							disabled
							className={inputStyle}
							onClick={toggleMenu}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<KeyboardArrowDown />
									</InputAdornment>
								),
							}}
						>
							{renderInnerMenu()}
						</TextField>
						:
						<Select
							multiple={isMultiple}
							value={sValue}
							fullWidth
							name={name}
							onChange={onChange}
							variant="outlined"
							displayEmpty
							disabled
							className={inputStyle}
							onClick={toggleMenu}
						>
							{renderInnerMenu()}
						</Select>
					}
					<InputLabel shrink variant="outlined">
						{`${label}${required ? "*" : ""}`}
					</InputLabel>
				</FormControl>
				{renderSelectMenu()}
			</Box>
		</Box>
	)
}

export default UsersInput