import { AddPhotoAlternateOutlined, Brightness1Outlined, CheckCircle, Edit, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Box, Button, Collapse, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { useContext, useRef, useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import ImageContainer from '../../../Shared/ImageContainer'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import BaseOption from './BaseOption'
import EvaluationContext from './EvaluationContext'

const css = {
	container: {
		padding: 2,
		margin: '12px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	options: {
		background: 'whitesmoke',
		borderRadius: 2,
		margin: '12px 0'
	},
	disclaim: {
		display: 'flex',
		alignItems: 'center',
		padding: 1
	}
}

function BaseQuestion({ question }) {
	const options = question.options || []

	const [name, setName] = useState(question.name)
	const [open, toggleOpen] = useToggle(false)

	const { createOption, editQuestion, editQuestionPhoto } = useContext(EvaluationContext)

	function onCreate() {
		const body = { question_id: question.id }
		createOption(body)
	}

	function onBlur() {
		const body = { ...question, name }
		editQuestion(body)
	}

	function selectFile(event) {
		const { target } = event
		const file = target.files[0]
		const body = new FormData()
		body.append("file", file)
		body.append("id", question.id)
		editQuestionPhoto(body)
	}

	const inputFile = useRef(null)

	return (
		<Paper sx={css.container}>
			<input type="file" accept='image/*' hidden onChange={selectFile} ref={inputFile} />
			<Box sx={css.header}>
				<Box sx={css.inline}>
					<SeamlessInput placeholder="Ingrese texto pregunta..." onBlur={onBlur} onChange={e => setName(e.target.value)} color="transparent" variant="h4" value={name} />
					{question.photo &&
						<Box style={{ padding: 6 }}>
							<ImageContainer src={question.photo} alt="apoyo-item" height={64} width={64} border={6} />
						</Box>
					}
				</Box>
				<Box>
					<Tooltip title="Agregar imagen de apoyo para la pregunta">
						<IconButton onClick={() => inputFile.current.click()}>
							<AddPhotoAlternateOutlined />
						</IconButton>
					</Tooltip>
					<IconButton style={{ marginRight: 12 }} onClick={toggleOpen}>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
					<Button variant="outlined" color="primary" onClick={onCreate} size="small">
						Agregar opción
					</Button>
				</Box>
			</Box>
			<Collapse in={open} unmountOnExit>
				<Box sx={css.options}>
					{options.map((option, index) =>
						<>
							{index > 0 && <Divider />}
							<BaseOption option={option} key={option.id} />
						</>
					)}
					{!options.length && <Typography style={{ padding: 12 }} variant='subtitle1'>Agregue opciones para comenzar</Typography>}
				</Box>
				{!!options.length &&
					<Box sx={css.disclaim}>
						<CheckCircle color="success" style={{ marginRight: 6, color: '#7373e8' }} />
						<Typography variant='subtitle1'>= Respuesta correcta</Typography>
					</Box>
				}
			</Collapse>
		</Paper>
	)
}

export default BaseQuestion