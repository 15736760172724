import React, { Component, Fragment } from "react"
import {
	FormControl,
	InputLabel,
	Typography,
	Divider,
	TextField,
	ListItem,
	InputAdornment,
	Dialog,
	DialogContent,
	ListItemIcon,
	ListItemText,
	Checkbox,
	IconButton,
	withStyles,
} from "@material-ui/core"
import { KeyboardArrowDown, Close } from "@material-ui/icons"
import autobind from "../../Utils/autobind"
import TextInput from "./TextInput"
import propTypes from 'prop-types'

const style = ({
	check: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 16,
		'& > *:first-child': {
			marginRight: 16
		}
	}
})

class MultiSelectInput extends Component {
	constructor(props) {
		super(props)
		this.state = {
			openDialog: false,
			messages: {},
			search: "",
		}
		autobind(MultiSelectInput, this)
	}

	componentDidMount() {
		const { value } = this.props
		const sValue = value || []
		const newMessages = {}
		sValue.forEach(v => {
			const [option, message] = v?.toString().split(":")
			if (!!message) {
				newMessages[option] = message
			}
		})
		this.setState({ messages: newMessages })
	}

	handleOpenDialog() {
		this.setState({ openDialog: !this.state.openDialog })
	}

	handleSearch(event) {
		const { target } = event
		this.setState({ search: target.value.toLowerCase() })
	}

	handleChangeMessage(event) {
		const { onChange, name, value } = this.props
		const { messages } = this.state
		const { target } = event
		messages[target.name] = target.value
		this.setState({ messages })

		const cleanedValues = value.map(v => v.toString().split(":")[0])

		const finalValue = cleanedValues.map(v => `${v}:${messages[v]}`)

		const newEvent = {
			target: {
				name,
				value: finalValue
			}
		}

		onChange(newEvent)
	}

	handleSelectAll() {
		const { search } = this.state
		const { options, value, name, onChange } = this.props
		const filteredOptions = options.filter(option => option?.label?.toLowerCase()?.toString()?.includes(search))
		const allValues = filteredOptions.map(opt => opt.value)
		const sValue = !!value ? value : []
		const cleanedValues = sValue.map(v => v.toString().split(":")[0])
		if (!!cleanedValues.length) {
			const event = {
				target: {
					name,
					value: []
				}
			}
			return onChange(event)
		} else {
			const event = {
				target: {
					name,
					value: allValues
				}
			}
			return onChange(event)
		}
	}

	renderMenu() {
		const { messages, search } = this.state
		const { options, onChange, name, value, label, withText, grouped = false, classes, enableAll } = this.props
		const sValue = value ? value : []
		const cleanedValues = sValue.map(v => v.toString().split(":")[0])
		const filteredOptions = options.filter(option => option?.label?.toLowerCase()?.toString()?.includes(search))
		let menu = []
		if (!grouped) {
			menu = filteredOptions.map((option) => {
				const returnValue = [...sValue]
				if (cleanedValues.includes(option.value)) {
					const indexToRemove = cleanedValues.indexOf(option.value)
					returnValue.splice(indexToRemove, 1)
				} else {
					const oldMessage = messages[option.value]
					if (oldMessage) {
						returnValue.push(`${option.value}:${messages[option.value]}`)
					} else {
						returnValue.push(option.value)
					}

				}
				const event = {
					target: {
						name: name,
						value: returnValue,
					},
				}
				return (
					<ListItem button onClick={() => onChange(event)} key={option.value}>
						<ListItemIcon>
							<Checkbox
								checked={cleanedValues.includes(option.value)}
								color="primary"
							/>
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography variant="subtitle2">
									{option.label}
								</Typography>
							}
						/>
					</ListItem>
				)
			})
		} else {
			const groupedOptions = { supervised: [], unsupervised: [] }
			filteredOptions.forEach(option => {
				if (option.isSupervised) {
					groupedOptions.supervised.push(option)
				} else {
					groupedOptions.unsupervised.push(option)
				}
			})
			menu = Object.values(groupedOptions).map((group, index) => {
				const submenu = group.map((option) => {
					const returnValue = [...sValue]
					if (cleanedValues.includes(option.value)) {
						const indexToRemove = cleanedValues.indexOf(option.value)
						returnValue.splice(indexToRemove, 1)
					} else {
						const oldMessage = messages[option.value]
						if (oldMessage) {
							returnValue.push(`${option.value}:${messages[option.value]}`)
						} else {
							returnValue.push(option.value)
						}

					}
					const event = {
						target: {
							name: name,
							value: returnValue,
						},
					}
					return (
						<ListItem button onClick={() => onChange(event)} key={option.value}>
							<ListItemIcon>
								<Checkbox
									checked={cleanedValues.includes(option.value)}
									color="primary"
								/>
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography variant="subtitle2">
										{option.label}
									</Typography>
								}
							/>
						</ListItem>
					)
				})
				return (
					<Fragment key={index}>
						<Typography variant="h4" style={{ margin: '12px 0' }}>{index === 0 ? "Supervisados:" : "Otros:"}</Typography>
						{submenu}
					</Fragment>
				)
			})
		}
		const selectedOptions = options.filter((option) =>
			cleanedValues.includes(option.value)
		)

		const allSelected = selectedOptions.length === filteredOptions.length
		const isPartiallySelected = selectedOptions.length > 0 && !allSelected
		return (
			<>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: 12,
					}}
				>
					<Typography variant="h1">
						{label} seleccionados/as:
					</Typography>
					<IconButton
						size="small"
						color="primary"
						onClick={this.handleOpenDialog}
					>
						<Close />
					</IconButton>
				</div>
				<div style={{ maxHeight: 250, overflow: "auto" }}>
					{selectedOptions.map((option) => (
						<div key={option.label} style={{ margin: '6px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant="subtitle2" style={{ padding: 6 }} key={option.label}>
								{option.label}
							</Typography>
							{withText && <TextField placeholder="Comentario" onChange={this.handleChangeMessage} value={messages[option.label]} name={option.label} />}
						</div>
					))}
				</div>
				<Divider style={{ margin: "12px 0" }} />
				<Typography variant="subtitle1">Opciones:</Typography>
				<TextInput value={search} onChange={this.handleSearch} options={{ placeholder: "Buscar" }} label="" />
				{enableAll &&
					<div className={classes.check}>
						<Checkbox onClick={this.handleSelectAll} checked={allSelected} indeterminate={isPartiallySelected} />
						<Typography variant='subtitle1'>{(selectedOptions.length > 0 ? "Deshacer" : "Seleccionar Todos")}</Typography>
					</div>
				}
				<Divider style={{ margin: "12px 0" }} />
				<div style={{ maxHeight: 250, overflow: "auto" }}>{menu}</div>
			</>
		)
	}

	transformValues(values) {
		const { options } = this.props
		const { messages } = this.state
		const cleanedValues = values.map(v => v.toString().split(":")[0])
		const selectedOptions = options.filter((option) =>
			cleanedValues.includes(option.value)
		)
		const names = selectedOptions.map((option) => `${option.label}${messages[option.value] ? `: ${messages[option.value]}` : ""}`)
		return names
	}

	render() {
		const { value, onChange, label, name, required, disabled } = this.props
		const sValue = value ? value : []
		const { openDialog } = this.state
		return (
			<>
				<FormControl fullWidth style={{ margin: "12px 0" }}>
					<TextField
						value={String(this.transformValues(sValue)) || []}
						fullWidth
						name={String(name)}
						onChange={onChange}
						variant="outlined"
						multiple
						disabled
						onClick={!disabled && this.handleOpenDialog}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<KeyboardArrowDown />
								</InputAdornment>
							),
						}}
					/>
					<Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
						<DialogContent>{this.renderMenu()}</DialogContent>
					</Dialog>
					<InputLabel shrink variant="outlined">
						{`${label}${required ? "*" : ""}`}
					</InputLabel>
				</FormControl>
			</>
		)
	}
}

MultiSelectInput.propTypes = {
	enableAll: propTypes.bool
}

MultiSelectInput.defaultProps = {
	options: [],
	enableAll: true
}


export default withStyles(style)(MultiSelectInput)