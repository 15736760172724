import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import { InfoOutlined, KeyboardArrowRight } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import ChecklistContext from '../ChecklistContext'

const style = () => ({
  container: {
    borderRadius: 24,
    border: '1px solid #6a6a6a',
    maxWidth: 48,
    overflow: 'hidden',
    display: 'flex',
    transition: 'all 0.3s ease-in-out'
  },
  openContainer: {
    maxWidth: 500
  },
  main: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '6px 0'
  },
  critTag: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 24,
    border: '1px solid #6a6a6a',
    padding: '6px 12px',
    '& > svg': {
      marginRight: 6
    }
  }
})

class CritSelector extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(CritSelector, this)
  }

  componentDidMount() {
    const { item } = this.props
    const crit = item?.answer?.criticality
    const defaultCrit = item?.default_criticality
    if (defaultCrit) return true
    if (!crit) return this.setState({ open: true })
    return true
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleSelectCrit(value) {
    return () => {
      const { onCrit } = this.context
      const { item } = this.props
      const body = { criticality: value, checklist_item_id: item.id }
      this.setState({ open: false })
      onCrit(body)
    }
  }

  renderOptions() {
    const options = [
      { crit: 4, color: "#ff6c87" },
      { crit: 3, color: "#ffab6e" },
      { crit: 2, color: "#f6ec79" },
      { crit: 1, color: "#a3b8ff" },
    ]
    return options.reverse().map(option => {
      return (
        <IconButton key={option.crit} style={{ color: option.color }} onClick={this.handleSelectCrit(option.crit)}>
          <InfoOutlined />
        </IconButton>
      )
    })
  }

  renderFinishedOption() {
    const options = [
      { crit: 4, color: "#ff6c87", label: "Altamente Crítico" },
      { crit: 3, color: "#ffab6e", label: "Medianamente Crítico" },
      { crit: 2, color: "#f6ec79", label: "Poco Crítico" },
      { crit: 1, color: "#a3b8ff", label: "No Crítico" },
    ]
    const { item, classes } = this.props
    const crit = item?.answer?.criticality
    const answer = options.find(op => op.crit === crit)
    if (!answer) return (
      <div className={classes.critTag}>
        <InfoOutlined style={{ color: "#6a6a6a" }} />
        <Typography variant="subtitle1">Criticidad no seleccionada</Typography>
      </div>
    )
    return (
      <div className={classes.critTag}>
        <InfoOutlined style={{ color: answer?.color }} />
        <Typography variant="subtitle1">{answer?.label}</Typography>
      </div>
    )
  }

  render() {
    const { classes, item, disabled } = this.props
    const { finished } = this.context
    const { open } = this.state
    const crit = item?.answer?.criticality
    const colors = ["#ff6c87", "#ffab6e", "#f6ec79", "#a3b8ff"].reverse()
    return (
      <div className={classes.main}>
        {(finished || disabled) ? this.renderFinishedOption()
          :
          <div className={`${classes.container} ${open ? classes.openContainer : ""}`}>
            <IconButton onClick={this.handleOpen} style={{ color: colors[crit - 1] }}>
              {open ? <KeyboardArrowRight /> : <InfoOutlined />}
            </IconButton>
            {this.renderOptions()}
          </div>
        }
      </div>
    )
  }
}

CritSelector.contextType = ChecklistContext

export default withStyles(style)(CritSelector)