import { Divider } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material'
import moment from 'moment'
import { createContext, useEffect, useMemo, useState } from 'react'
import { getSupervised, getSupervisedData } from '../../../API/users'
import useToggle from '../../../Hooks/ToogleHook'
import useChange from '../../../Hooks/UseStateChange'
import useChangeParams from '../../../Hooks/UseStateChangeParams'
import useTabs from '../../../Hooks/UseTabs'
import DateIntervalSelector from '../../../Shared/Inputs/DateIntervalSelector'
import DateIntervalSelectorNew from '../../../Shared/Inputs/DateIntervalSelectorNew'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import LazyTable from '../../../Shared/LazyTable'
import { inline_space, text_space } from '../../../Utils/defaultStyles'
import { transformToOptions } from '../../../Utils/functions'
import SupervisedChecklists from './SupervisedChecklists'
import SupervisedEvaluations from './SupervisedEvaluations'
import SupervisedFindings from './SupervisedFindings'
import SupervisedTNTs from './SupervisedTNTs'

const css = {
	container: {
		padding: 2,
		margin: '12px 0',
		borderRadius: 2,
		background: 'white'
	}
}

const checklistsTableInfo = [
	{ label: "name", name: "Colaborador" },
	{ label: "pendings", name: "Programadas" },
	{ label: "overdue", name: "Vencidas" },
	{ label: "finished", name: "Terminadas" },
]

const findingsTableInfo = [
	{ label: "name", name: "Colaborador" },
	{ label: "owned", name: "Detector" },
	{ label: "responsible", name: "Responsable" },
	{ label: "pendings", name: "En Proceso" },
	{ label: "overdue", name: "Vencidas" },
	{ label: "finished", name: "Terminadas" },
	{ label: "approved", name: "Aprobadas" },
	{ label: "dismissed", name: "Desestimadas" },
]

const tntTableInfo = [
	{ label: "name", name: "Colaborador" },
	{ label: "owned", name: "Supervisor" },
	{ label: "participations", name: "Participante" },
	{ label: "signs", name: "Firmas" },
]

const evaluationsTableInfo = [
	{ label: "name", name: "Colaborador" },
	{ label: "evaluations", name: "Evaluaciones" },
	{ label: "pending", name: "Pendientes" },
	{ label: "finished", name: "Terminadas" },
]

export const SupervisionContext = createContext({})

const colors = {
	Terminado: "success",
	Programado: "info",
	Vencido: "error",
	Aprobado: "success",
	Proceso: "info",
	Aprobada: "success",
	Reprobada: "error"
}

const tableInfos = [checklistsTableInfo, findingsTableInfo, tntTableInfo, tntTableInfo, evaluationsTableInfo]
const types = ["checklists", "findings", "talks", "trainings", "evaluations"]

function SupervisionHome({ value }) {

	const [supervised, setSupervised] = useState([])
	const [selectedUser, setUser] = useState("")
	const [dates, setDates] = useChangeParams({ date_start: moment().startOf("week").format("YYYY-MM-DD"), date_end: moment().endOf("week").format("YYYY-MM-DD") })
	const [tab, setTab] = useTabs(0)
	const [open, toggleOpen] = useToggle(false)

	useEffect(() => {
		async function fetchData() {
			const response = await getSupervised()
			setSupervised(response.data.info)
		}
		fetchData()
	}, [])

	useEffect(() => {

	}, [dates])


	const tableInfo = useMemo(() => tableInfos[tab], [tab])

	function seeUser(row) {
		return () => {
			setUser(row.id)
		}
	}



	const supervisedUser = useMemo(() => supervised.find(s => String(s.id) === String(selectedUser)), [supervised, selectedUser])
	const isSupervisedSelected = !!supervised
	const params = useMemo(() => ({ ...dates, type: types[tab] }), [tab, dates])
	const actions = [{ name: "Ver detalles", icon: KeyboardArrowDown, action: seeUser }]

	if (!supervised.length) return null

	return (
		<Box sx={css.container}>
			<Box sx={inline_space}>
				<Typography variant='h1'>Supervisión de colaboradores</Typography>
				<IconButton onClick={toggleOpen}>
					<KeyboardArrowDown />
				</IconButton>
			</Box>
			{open &&
				<Box>
					<Typography sx={text_space} variant='h4'>Seleccionar intervalo de fechas para la supervisión</Typography>
					<DateIntervalSelectorNew dates={dates} onChange={setDates} />
					<Tabs value={tab} onChange={setTab}>
						<Tab label="Registros" />
						<Tab label="Hallazgos" />
						<Tab label="Charlas" />
						<Tab label="Capacitaciones" />
						<Tab label="Evaluaciones" />
					</Tabs>
					<LazyTable
						info={tableInfo}
						fetchEndpoint={getSupervisedData}
						extra={params}
						actions={actions}
						disableExport
						disableFilters
						disableSearch
					/>
					{selectedUser &&
						<Box>
							<SupervisionContext.Provider value={{ colors }}>
								<Divider />
								{tab === 0 && <SupervisedChecklists supervisedUser={supervisedUser} id={selectedUser} dates={dates} />}
								{tab === 1 && <SupervisedFindings supervisedUser={supervisedUser} id={selectedUser} dates={dates} />}
								{tab === 2 && <SupervisedTNTs supervisedUser={supervisedUser} type="talks" id={selectedUser} dates={dates} />}
								{tab === 3 && <SupervisedTNTs supervisedUser={supervisedUser} type="trainings" id={selectedUser} dates={dates} />}
								{tab === 4 && <SupervisedEvaluations supervisedUser={supervisedUser} id={selectedUser} dates={dates} />}
							</SupervisionContext.Provider>
						</Box>
					}
				</Box>
			}
		</Box>
	)
}

export default SupervisionHome