import React, { Component } from 'react'
import { Button, IconButton, InputLabel, withStyles } from '@material-ui/core'
import ImageContainer from '../ImageContainer'
import { AddAPhoto, Delete } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { resizeImageAndGetFile } from '../../Utils/functions'
import instance from '../../Utils/instance'
import LoadingDialog from '../LoadingDialog'

const style = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 74,
    width: '100%'
  },
  button: {
    padding: "8px 16px"
  },
  hidden: {
    display: 'none'
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 12,
    background: 'white',
    padding: 6
  },
  input: {
    border: "1px solid lightgrey",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    width: '100%',
    borderRadius: 5
  },
  images: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 6
    }
  },
  image: {
    position: 'relative'
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0
  }
})

function simulateClick(id) {
  return () => {
    document.getElementById(id).click()
  }
}

class MultipleImgInput extends Component {
  constructor() {
    super()
    this.state = {
      selected: "",
      loading: false
    }
    autobind(MultipleImgInput, this)
  }

  handleSelectFile(event) {
    const { onChange, name, options, value } = this.props
    const { target } = event
    const file = target.files[0]

    let field = "file"
    if (options.field) { field = options.field }
    const { uploadUrl } = options
    const oldImages = value

    const callback = async (image, file) => {
      // const newEvent = {
      //   target: { name: name, value: file }
      // }
      // onChange(newEvent)
      this.setState({ selected: image })
      console.log(uploadUrl)
      const body = new FormData()
      body.append(field, file)

      this.setState({ loading: true })
      const response = await instance({ method: 'post', url: uploadUrl, data: body })
      this.setState({ loading: false })
      if (oldImages && !options?.single) {
        const newEvent = {
          target: {
            name: name,
            value: `${oldImages}&#&${response.data?.info?.file_name}`
          }
        }
        return onChange(newEvent)
      } else {
        const newEvent = {
          target: {
            name: name,
            value: response.data?.info?.file_name
          }
        }
        return onChange(newEvent)
      }

    }

    resizeImageAndGetFile(file, callback)
  }



  handleDelete(index) {
    const { onDelete, name } = this.props
    onDelete && onDelete(name, index)
  }

  renderImages() {
    const { value, classes, options } = this.props
    const { url } = options
    if (!url) return null
    if (!value) return null
    const images = value.split("&#&")
    return images.map((image, index) => {
      return (
        <div className={classes.image} key={image}>
          <ImageContainer src={value ? `${url}/${image}` : "/noimage.png"} width="56px" height="56px" />
          <IconButton size="small" color="secondary" className={classes.deleteButton} onClick={e => this.handleDelete(index)}>
            <Delete />
          </IconButton>
        </div>
      )
    })
  }

  render() {
    const { label, name, disabled, classes, required, options, value } = this.props
    const { selected, loading } = this.state
    const images = value ? value.split("&#&") : []
    const lastValue = images.pop()
    const { url } = options
    return (
      <>
        <LoadingDialog open={loading} loadingMessage="Cargando imágen" />
        <div className={classes.container}>
          <input type="file" id={name} className={classes.hidden} onChange={this.handleSelectFile} />
          <InputLabel shrink className={classes.label}>
            {`${label}${required ? "*" : ""}`}
          </InputLabel>
          <div className={classes.input}>
            <ImageContainer src={value ? `${url}/${lastValue}` : "/noimage.png"} width="32px" height="32px" />
            <Button variant="outlined" color="primary" className={classes.button} onClick={simulateClick(name)} disabled={disabled}>
              <AddAPhoto />
            </Button>
          </div>
        </div>
        {!options?.single &&
          <div className={classes.images}>
            {this.renderImages()}
          </div>
        }
      </>
    )
  }
}

export default withStyles(style)(MultipleImgInput)