import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Paper, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import newTheme from '../../newTheme'
import GeneralTable from '../../Shared/GeneralTable'
import SelectInput from '../../Shared/Inputs/SelectInput'
import SubdivisionInfoDialog from './SubdivisionInfoDialog'

const css = {
	container: {
		padding: 2,
		borderRadius: 2
	},
	cell: {
		padding: 2,
		borderRadius: 2,
	}
}

function PercentageCellWithColor({ value }) {
	const numeric_value = parseInt(value, 10)
	return (
		<Box sx={css.cell} style={{ background: numeric_value === 100 ? newTheme.palette.blue.transparent : "white" }}>
			{value}
		</Box>
	)
}

function ChecklistProgressFollowing({ pendings, history }) {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null)
	const [data, setData] = useState([])
	const [subdivision_selected, setSubdivision] = useState(null)
	const [branch_name, setBranch] = useState("")

	useEffect(() => {
		const pm_names = []
		const pm_options = []
		pendings.forEach(pending => {
			if (!!pending.preventive_measure && !pm_names.includes(pending.preventive_measure)) {
				pm_names.push(pending.preventive_measure)
				pm_options.push({ value: pending.base_checklist_id, label: pending.preventive_measure })
			}
		})
		setOptions(pm_options)
	}, [pendings])

	useEffect(() => {
		if (selected) {
			const newData = pendings.filter(ch => String(ch.base_checklist_id) === String(selected))
			setData(newData)
		}
	}, [selected, pendings])

	function gotoChecklist(row) {
		return () => {
			history.push(`/checklists/${row.id}`)
		}
	}

	const tableData = useMemo(() => {
		const additional_headers = ["0"]
		const additional_info = [{ label: "0", name: "Sin categoría", render: PercentageCellWithColor }]
		data.forEach(checklist => {
			const subtitles = checklist.subtitles.sort((a, b) => {
				if (a.subtitle_order > b.subtitle_order) return 1
				if (a.subtitle_order < b.subtitle_order) return -1
				return 0
			}) || []
			console.log(subtitles)
			subtitles.forEach(subtitle => {
				if (!additional_headers.includes(subtitle.id)) {
					additional_headers.push(subtitle.id)
					additional_info.push({ label: String(subtitle.id), name: subtitle.name, render: PercentageCellWithColor })
				}
			})
		})
		const table_info = [
			{ label: "date_scheduled", name: "Fecha" },
			{ label: "name", name: "Registro" },
			{
				label: "location",
				name: "Ubicación",
				format: (value, element) => element?.subdivision?.name || value,
				clickable: true,
				action: (row) => {
					setBranch(row.location)
					setSubdivision(row.subdivision)
				}
			},
		].concat(additional_info.sort((a, b) => {
			if (a.subtitle_order > b.subtitle_order) return 1
			if (a.subtitle_order < b.subtitle_order) return -1
			return 0
		}))

		console.log(table_info)


		const body = data.map(checklist => {
			const element_data = {
				date_scheduled: checklist.date_scheduled,
				name: checklist.preventive_measure,
				subdivision: checklist.subdivision,
				location: checklist.branch_name,
				id: checklist.id
			}

			const { metadata } = checklist
			const disable_categories = metadata?.disable_categories || []

			const empty_headers = []
			additional_headers.forEach(header => {

				if (disable_categories.includes(header)) { return element_data[header] = '100%' }
				const items = checklist.checklist_items || []
				const filtered_items = items.filter(item => String(item.subtitle_id) === String(header))

				let total = 0
				let added = 0

				filtered_items.forEach(element => {
					total += element.points
					if (element.answer.value_id !== null) {
						added += element.points
					}
				})

				const itemsCompliance = total > 0 ? (added / total * 100) : 0

				if (!!filtered_items.length) {
					element_data[header] = itemsCompliance.toFixed(1) + "%"
				} else {
					empty_headers.push(header)
				}
			})

			empty_headers.forEach(header => {
				const empty_index = table_info.findIndex(el => el.label === String(header))
				if (empty_index !== -1) { table_info.splice(empty_index, 1) }
			})

			return element_data
		})

		const actions = [
			{ name: "Ver registro o formulario", link: (row) => `/checklists/${row.id}`, icon: KeyboardArrowRight, action: gotoChecklist, color: "primary" },
		]

		return (
			<GeneralTable
				info={table_info}
				data={body}
				actions={actions}
			/>
		)

	}, [data])

	return (
		<Box>
			<Paper sx={css.container}>
				<SubdivisionInfoDialog
					subdivision={subdivision_selected}
					open={!!subdivision_selected}
					onClose={() => setSubdivision(null)}
					branch_name={branch_name}
				/>
				<Typography variant='h4'>Resumen de avances y complimientos</Typography>
				<SelectInput value={selected} onChange={e => setSelected(e.target.value)} options={options} label="Seleccionar registro" />
				{tableData}
			</Paper>
		</Box>
	)
}

export default withRouter(ChecklistProgressFollowing)