import { Box, Button } from '@mui/material'
import { createContext, useContext, useEffect, useState } from 'react'
import ChecklistManager from './ChecklistsManager'
import RequestManager from './RequestsManager'

export const DatabaseManagerContext = createContext({})

let db

function DatabaseManager({ children }) {
	useEffect(() => {
		const request = indexedDB.open("TazkiDB", 2)

		request.onerror = function (event) {
			console.log("Error creating/accessing IndexedDB database")
		}

		request.onsuccess = function (event) {
			console.log("Success creating/accessing IndexedDB database")
			db = request.result

			db.transaction("checklists").objectStore("checklists")
			db.transaction("requests").objectStore("requests")
		}

		request.onupgradeneeded = function (event) {
			const db = event.target.result
			db.createObjectStore("checklists", { keyPath: "id" })
			db.createObjectStore("requests", { keyPath: "id", autoIncrement: true })
		}
	}, [])

	return (
		<Box>
			<RequestManager db={db} >
				<ChecklistManager db={db} >
					{children}
				</ChecklistManager>
			</RequestManager>
		</Box>
	)
}

export default DatabaseManager