import { Settings } from '@material-ui/icons'
import { Box, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import GeneralTable from '../../../Shared/GeneralTable'
import { title_style } from '../../../Utils/defaultStyles'
import { ActivityContext } from './ActivityNew'
import ManageActivityChecklist from './ManageActivityChecklist'

function ActivityChecklistSettings({ }) {

	const [checklist, setChecklist] = useState(null)
	const { activity } = useContext(ActivityContext)
	const checklists = useMemo(() => activity.preventive_measures || [], [activity])
	const actions = [{ name: "Gestionar", icon: Settings, action: selectChecklist }]

	function selectChecklist(row) {
		return () => {
			setChecklist(row)
		}
	}

	useEffect(() => {
		setChecklist(ch => checklists.find(c => c.id === ch?.id))
	}, [activity])

	return (
		<Box>
			<Typography sx={title_style} variant='h4' color="primary">Configuración de Registros y Formularios</Typography>
			<GeneralTable
				actions={actions}
				maxHeight={300}
				disableSearch
				scrollable
				disableExport
				data={checklists}
				info={[{ label: "name", name: "Nombre" }]}
			/>
			<ManageActivityChecklist checklist={checklist} onClose={selectChecklist(null)} />
		</Box>
	)
}

export default ActivityChecklistSettings