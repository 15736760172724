import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getParamsAction = () => {
  const REQUEST = instance2({
    method: 'get',
    url: "params/get.php"
  })
  return {
    type: "GET_PARAMS",
    payload: REQUEST
  }
}