import React, { Component } from 'react'
import { Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import SubmitButton from '../SubmitButton'
import autobind from '../../Utils/autobind'
import { connect } from 'react-redux'
import { closeGlobalDialog } from '../../Actions/GlobalActions'
import { Clear } from '@material-ui/icons'

const style = () => ({
  container: {
    padding: '12px 24px',
    '& > *': {
      margin: '12px 0'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12
  }
})

class GlobalDeletePopup extends Component {
  constructor() {
    super()
    autobind(GlobalDeletePopup, this)
  }

  handleClick() {
    const { global, onClose } = this.props
    const deleteState = global?.delete
    if (!deleteState) return false
    const { action, body, options } = deleteState
    const callback = options.callback || undefined
    Promise.resolve(action(body)).then(callback)
    onClose()
  }

  render() {
    const { classes, onClose, global } = this.props
    const deleteState = global?.delete || { open: false }
    const { open } = deleteState || { open: false }
    const dialogOptions = deleteState?.options || {}
    return (
      <Dialog open={open || false} onClose={onClose}>
        <div className={classes.container}>
          <div className={classes.title}>
            <Typography variant="h5">{dialogOptions?.title || "Eliminar elemento"}</Typography>
            <IconButton size="small" onClick={onClose}>
              <Clear />
            </IconButton>
          </div>
          <Typography variant="subtitle1">{dialogOptions?.message || "¿Seguro que deseas eliminar este elemento?"}</Typography>
          <SubmitButton onClick={this.handleClick}>
            {dialogOptions?.submit || "Sí, Eliminar"}
          </SubmitButton>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  global: state.global
})

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeGlobalDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(GlobalDeletePopup))