import React, { Component } from 'react'
import { Button, Collapse, Dialog, Typography, withStyles } from '@material-ui/core'
import FormContext from '../../Shared/Cards/Provider'
import GeneralForm from '../../Shared/GeneralForm'
import { multipleActivityUsersInfo } from './Info'
import { transformToOptions } from '../../Utils/functions'
import { connect } from 'react-redux'
import autobind from '../../Utils/autobind'
import moment from 'moment'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { callSnackbar } from '../../Utils/snackbar'
import { createMultipleActivityAction } from '../../Actions/ActivityActions'
import { filterActiveBranches } from '../../Utils/filters'

const style = theme => ({
  container: {
    background: theme.palette.blue.main,
    padding: 24,
    borderRadius: 15,
    marginBottom: 24
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 600
  }
})

class MultipleBranchActivities extends Component {
  constructor() {
    super()
    this.state = {
      params: {
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: moment(new Date())
          .add(1, "months")
          .format("YYYY-MM-DD"),
        frequency: 1000
      },
      openDialog: false,
      newActivities: [],
      actualActivityIndex: 0
    }
    autobind(MultipleBranchActivities, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleOpen() {
    const { openDialog } = this.state
    this.setState({ openDialog: !openDialog })
  }

  handleCreate() {
    const { params } = this.state
    if (!params.branch_ids) return callSnackbar("Seleccione sucursal(es)", "warning")
    if (!params.activity_id) return callSnackbar("Seleccione actividad", "warning")
    const branches = params.branch_ids
    const newActivities = branches.map(branch => {
      return {
        activity_id: params.activity_id,
        branch_id: branch,
        start_date: params.start_date,
        end_date: params.end_date,
        frequency: params.frequency,
      }
    })
    this.setState({ newActivities })
    this.handleOpen()
  }

  handleEnd() {
    const { onClose, createMultiple } = this.props
    const { newActivities } = this.state
    const body = [...newActivities]
    createMultiple(body)
    this.setState({
      openDialog: false, params: {
        start_date: moment(new Date()).format("YYYY-MM-DD"),
        end_date: moment(new Date())
          .add(1, "months")
          .format("YYYY-MM-DD"),
        frequency: 1000
      },
    })
    onClose()
  }

  setFormInfo(info) {
    const { enterprise, activities } = this.props

    info.map((input) => {
      switch (input.name) {
        case "branch_ids": {
          input.options = transformToOptions(filterActiveBranches(enterprise?.branches))
          return input
        }
        case "activity_id": {
          input.options = transformToOptions(activities?.global)
          return input
        }
        default:
          return input
      }
    })

    return info
  }

  renderNewActivity() {
    const { workers, classes, activities, enterprise } = this.props
    const { newActivities, actualActivityIndex } = this.state
    const actualActivity = newActivities[actualActivityIndex]
    const finalActivities = [...newActivities]


    const allWorkers = workers.all || []
    const filteredWorkers = allWorkers
      .filter(worker => {
        const isFromBranch = worker.branch_id === null || worker.branch_ids.includes(parseInt(actualActivity?.branch_id, 10))
        const isActive = worker?.active === 0
        return isActive && isFromBranch
      })
      .map(worker => ({ ...worker, name: `${worker.name} - ${worker.rut}` }))

    const self = this

    function setNext() {
      self.setState({ actualActivityIndex: self.state.actualActivityIndex + 1 })
    }

    function setPrev() {
      self.setState({ actualActivityIndex: self.state.actualActivityIndex - 1 })
    }

    function setUser(event) {
      const { target: { value } } = event
      finalActivities[actualActivityIndex].user_id = value
      self.setState({ newActivities: finalActivities })
    }

    const actualActivityName = activities?.global.find(ac => ac.id.toString() === actualActivity?.activity_id)?.name
    const actualBranchName = enterprise?.branches.find(br => br.id === actualActivity?.branch_id)?.name

    return (
      <>
        <Typography variant="h1">Seleccion de responsable</Typography>
        <Typography variant="subtitle1">Responsable de actividad {' '}
          <span className={classes.bold}>{actualActivityName}</span>
          {' '} en centro de trabajo {' '}
          <span className={classes.bold}>{actualBranchName}</span>
        </Typography>
        <SelectInput value={actualActivity?.user_id} onChange={setUser} label="Responsable" options={transformToOptions(filteredWorkers)} />
        <div className={classes.buttons}>
          {actualActivityIndex > 0 ?
            <Button onClick={setPrev} variant="contained" color="secondary">
              Anterior
            </Button> : <div />}
          {actualActivityIndex < (newActivities.length - 1) &&
            <Button onClick={setNext} variant="contained" color="secondary">
              Siguiente
            </Button>}
          {actualActivityIndex === (newActivities.length - 1) &&
            <Button onClick={this.handleEnd} variant="contained" color="primary">
              Terminar y Crear
            </Button>
          }
        </div>
      </>
    )
  }

  render() {
    const { classes, open } = this.props
    const { params, openDialog } = this.state

    return (
      <>
        <Collapse in={open}>
          <div className={classes.container}>
            <FormContext.Provider value={{ handleChange: this.handleChange, params }}>
              <GeneralForm
                open={open}
                title={`Planificación de actividades para multiples centros`}
                info={this.setFormInfo(multipleActivityUsersInfo)}
                onSubmit={this.handleCreate}
                submit="Seleccionar responsables"
              />
            </FormContext.Provider>
          </div>
        </Collapse>
        <Dialog open={openDialog} onClose={this.handleOpen} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            {this.renderNewActivity()}
          </div>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => ({
  enterprise: state.enterprise,
  workers: state.workers,
  activities: state.activities,
  branch: state.branch,
})

const mapDispatchToProps = dispatch => ({
  createMultiple: body => dispatch(createMultipleActivityAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(MultipleBranchActivities))