import React, { Component } from 'react'
import { Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import ImageContainer from '../../Shared/ImageContainer'

const style = theme => ({
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  link: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.blue.main,
    cursor: 'pointer',
  },
  dialog: {
    padding: 24,
  },
  signInfo: {
    '& > h2': {
      fontWeight: 600
    }
  },
  sign: {
    display: 'flex',
    alignItems: 'center'
  }
})

class SignCell extends Component {
  constructor() {
    super()
    this.state = {
      openDialog: false
    }
    autobind(SignCell, this)
  }

  handleOpenSigns() {
    const { openDialog } = this.state
    this.setState({ openDialog: !openDialog })
  }

  renderSings() {
    const { value, classes } = this.props
    return value.map((sign, index) => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}`
      return (
        <React.Fragment key={sign.id}>
          {index > 0 && <Divider />}
          <div className={classes.sign}>
            <ImageContainer height={100} width={175} src={`${url}${sign.sign}`} />
            <div className={classes.signInfo}>
              <Typography variant="h2">{sign.name}</Typography>
              <Typography variant="subtitle1">{sign.reason}</Typography>
              <Typography variant="caption">{sign.date}</Typography>
            </div>
          </div>
        </React.Fragment>
      )
    })
  }

  render() {
    const { classes, value } = this.props
    const { openDialog } = this.state
    const hasSigns = value.length > 0
    if (!hasSigns) return <Typography variant="subtitle1" className={classes.text}>Sin Firmar</Typography>
    return (
      <>
        <Dialog open={openDialog} onClose={this.handleOpenSigns} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            {this.renderSings()}
          </div>
        </Dialog>
        <Typography variant="subtitle1" className={classes.link} onClick={this.handleOpenSigns}>
          Ver Firmas
        </Typography>
      </>
    )
  }
}

export default withStyles(style)(SignCell)