import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"
import createSnackbar from "../Utils/snackbar"

export const getEnterpriseActivitiesAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/activities/get.php",
        params: body,
    })
    return {
        type: "GET_ACTIVITIES",
        payload: REQUEST,
    }
}

export const getBranchActivitiesAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/activities/get.php",
        params: body,
    })
    return {
        type: "GET_ACTIVITIES",
        payload: REQUEST,
    }
}

export const getActivityAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/activities/get.php",
        params: body,
    })
    return {
        type: "GET_ACTIVITY",
        payload: REQUEST,
    }
}

export const editActivityAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "/activities/edit.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "UPDATE_ACTIVITY",
        payload: REQUEST,
    }
}

export const createActivityAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "/activities/create.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_ACTIVITY",
        payload: REQUEST,
    }
}

export const createMultipleActivityAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "/activities/create_multiple.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_MULTIPLE_ACTIVITY",
        payload: REQUEST,
    }
}

export const getGlobalActivitiesAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/activities/global.php",
        params: body,
    })
    return {
        type: "GET_GLOBAL_ACTIVITIES",
        payload: REQUEST,
    }
}

export const getActivityParamsAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/activities/params.php",
        params: body,
    })
    return {
        type: "GET_ACTIVITY_PARAMS",
        payload: REQUEST,
    }
}

export const asignQuickaccessAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'activities/quick_access.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "ASSIGN_QUICK_ACCESS",
        payload: REQUEST
    }
}

export const assignRequiredSignAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'activities/require_signs.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "ASSIGN_REQUIRE_SIGNS",
        payload: REQUEST
    }
}

export const asignCalendarAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'activities/calendar.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "ASSIGN_CALENDAR_ACCESS",
        payload: REQUEST
    }
}

export const setAuthorizedUsersAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'activities/authorized_users.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "SET_AUTHORIZED_USERS",
        payload: REQUEST
    }
}

export const activityDispatcher = (dispatch) => ({
    getEnterpriseActivities: (body) =>
        dispatch(getEnterpriseActivitiesAction(body)),
    getBranchActivities: (body) => dispatch(getBranchActivitiesAction(body)),
    getActivity: (body) => dispatch(getActivityAction(body)),
    editActivity: (body) => dispatch(editActivityAction(body)),
    createActivity: (body) => dispatch(createActivityAction(body)),
    getGlobalActivities: (body) => dispatch(getGlobalActivitiesAction(body)),
    getActivityParams: (body) => dispatch(getActivityParamsAction(body)),
})
