import React, { Component } from 'react'
import { Button, Collapse, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import autobind from '../../../Utils/autobind'
import TextInput from '../../../Shared/Inputs/TextInput'
import { AddCircleOutline, Delete } from '@material-ui/icons'
import { editSettingsFinding, editSettingsNonConformity } from '../../../API/settings'
import { callSnackbar } from '../../../Utils/snackbar'

const style = theme => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: 6,
    background: theme.palette.purple.light,
    borderRadius: 12,
    margin: '6px 0',
    '& > *': {
      color: 'white'
    },
    '& > button': {
      marginRight: 12
    }
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    marginTop: 12,
    textAlign: 'end'
  }
})

const fieldTypes = [
  { label: "Texto", value: "text" },
  { label: "Selección", value: "select" },
  { label: "Selección Multiple", value: "multiselect" },
  { label: "Fecha", value: "date" },
]

class CreateField extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      option: "",
      options: []
    }
    autobind(CreateField, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeOption(event) {
    const { target } = event
    this.setState({ option: target.value })
  }

  async handleCreate() {
    const { finding, reload, onClose, nonconf } = this.props
    const { params, options } = this.state
    const field = { ...params, options: options || null }
    const fields = finding?.extra_fields || []
    fields.push(field)
    const body = {
      ...finding,
      extra_fields: fields
    }
    this.setState({ params: {}, options: [], option: "" })
    onClose()
    if (nonconf) {
      await editSettingsNonConformity(body)
    } else {
      await editSettingsFinding(body)
    }
    callSnackbar("Creado correctamente", "success")
    reload()
  }

  handleCreateOption() {
    const { option, options } = this.state
    const newOptions = [...options]
    newOptions.push(option)
    this.setState({ options: newOptions, option: "" })
  }

  handleDeleteOption(option) {
    return () => {
      const { options } = this.state
      const index = options.findIndex(op => op === option)
      options.splice(index, 1)
    }
  }

  renderOptions() {
    const { classes } = this.props
    const { options } = this.state
    return options.map(option => {
      return (
        <div className={classes.option}>
          <IconButton size="small" onClick={this.handleDeleteOption(option)}>
            <Delete />
          </IconButton>
          <Typography variant="subtitle1">{option}</Typography>
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props
    const { params, option } = this.state
    return (
      <div className={classes.container}>
        <TextInput
          onChange={this.handleChange}
          label="Titulo"
          name="text"
          value={params.text}
        />
        <SelectInput
          onChange={this.handleChange}
          label="Tipo de campo"
          options={fieldTypes}
          name="type"
          value={params.type}
        />
        <Collapse in={params.type === "select" || params.type === "multiselect"}>
          <Divider style={{ margin: '12px 0' }} />
          <Typography variant="h4" style={{ margin: '12px 0' }}>Opciones de selección</Typography>
          <div className={classes.inline}>
            <TextInput label="Nueva opción" onChange={this.handleChangeOption} value={option} />
            <IconButton onClick={this.handleCreateOption} disabled={!option}>
              <AddCircleOutline />
            </IconButton>
          </div>
          {this.renderOptions()}
        </Collapse>
        <div className={classes.button}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleCreate}
            disabled={!params.type || !params.text}
          >
            Crear campo
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(CreateField)