/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse, IconButton, Typography } from '@material-ui/core'
import { AddCircleOutline, KeyboardArrowDown, MailOutline, Send } from '@material-ui/icons'
import { Box, Paper, Button, Divider } from '@mui/material'
import { useEffect, useState } from 'react'
import TextInput from '../../Shared/Inputs/TextInput'
import RTE from '../../Shared/RTE'
import { transformToOptions } from '../../Utils/functions'
import { filterActiveWorkers } from '../../Utils/filters'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import useToggle from '../../Hooks/ToogleHook'
import { sendMail } from '../../API/workflows'

const css = {
	paper: {
		padding: 2
	},
	divider: {
		margin: '12px 0'
	},
	button: {
		textAlign: 'end',
		marginTop: 2
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

export function renderMailContent(items = [], content, getUsersNames) {
	// Render contents on RTE
	const details = items.reduce((x, item) => {
		const data = item.data
		const type = data.type
		let value = data.value

		if (type === "file") {
			const files = data?.value?.split("#") || []
			value = files.map((file, index) => {
				return `<a href="${file}">Documento ${index + 1}</a>`
			}).join(", ")
		}

		if (type === "check") {
			value = data.value === true ? "Si" : "No"
		}

		if (type === "select") {
			if (!!item?.data?.options) {
				value = item?.data?.options[value - 1]
			}
			if (item?.data?.entity === "workers") {
				value = getUsersNames([value])
			}
		}

		if (type === "multiselect") {
			if (!!item?.data?.options) {
				value = value.map(v => item?.data?.options[v])
			}
			if (item?.data?.entity === "workers") {
				value = getUsersNames(value)
			}
		}

		const itemText = `<p>Campo: ${data.label || "Sin nombre"}</p>
		<p>Respuesta: ${value || "Sin respuesta..."}</p>
		<p></p>`
		return x + itemText
	}, "")

	const sep = `
	<p>#------------------------------------------------------------------#</p>
	<p>Items: </p>
	<p></p>
	`

	const startIndex = content.indexOf("<p>#---")
	const newContent = content.slice(0, startIndex)
	if (items.length === 0) return newContent
	const finalContent = `${newContent}${sep}${details}`
	return finalContent
}

function WorkflowMail({ value, items = [] }) {

	const [openView, toggleOpen] = useToggle(false)
	const [emails, setEmails] = useState([])
	const [cc, setCC] = useState("")
	const [selectedWorkers, setSelected] = useState([])
	const workers = filterActiveWorkers(false)
	const [params, setParams] = useState({})

	function getUsersNames(ids) {
		if (!Array.isArray(ids) || ids.length === 0) return "Sin asignar"
		return ids.map(id => (workers.find(w => w.id === id)?.name)).join(", ")
	}

	function onChange(event) {
		const { target } = event
		setSelected(target.value)
	}

	function onChangeCC(event) {
		const { target } = event
		setCC(target.value)
	}

	function addMail() {
		const newMails = [...emails]
		newMails.push(cc)
		setCC("")
		setEmails(newMails)
	}

	function handleChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	function send() {
		const body = { ...params }
		body.emails = emails
		sendMail(body)
	}

	useEffect(() => {
		const selectedEmails = workers.filter(w => selectedWorkers.includes(w.id)).map(w => w.email)
		setEmails(selectedEmails)
	}, [selectedWorkers])

	useEffect(() => {
		function updateContent() {
			const newContent = renderMailContent(items, params.content || "", getUsersNames)
			console.log(newContent)
			setParams({ ...params, content: newContent })
		}

		updateContent()
	}, [items])

	return (
		<Box>
			<Paper sx={css.paper}>
				<Box onClick={toggleOpen} sx={css.header}>
					<Typography variant='h4'>Configuración envío por correo</Typography>
					<IconButton>
						<KeyboardArrowDown />
					</IconButton>
				</Box>
				<Collapse in={openView}>
					<MultiSelectInput onChange={onChange} value={selectedWorkers} grouped label="Seleccionar colaborador" options={transformToOptions(workers)} />
					<Box sx={css.title}>
						<TextInput label="Agregar correo" onChange={onChangeCC} value={cc} />
						<IconButton onClick={addMail} ><AddCircleOutline /></IconButton>
					</Box>
					<Divider sx={css.divider} />
					<Box sx={css.title}>
						<MailOutline style={{ marginRight: 6 }} />
						<Typography variant='h4'>Correo</Typography>
					</Box>
					{emails.map(email => {
						return <Typography variant='subtitle1'>{email}</Typography>
					})}
					<TextInput label="Asunto" name="subject" onChange={handleChange} value={params.subject} />
					<RTE name="content" onChange={handleChange} value={params.content} />
					<Box sx={css.button}>
						<Button color="primary" variant="contained" startIcon={<Send />} onClick={send}>
							Enviar Correos
						</Button>
					</Box>
				</Collapse>
			</Paper>
		</Box>
	)
}

export default WorkflowMail