import { Delete } from '@material-ui/icons'
import { Box, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { deleteBaseSignGroup, editBaseSignGroup } from '../../../../API/base_checklists'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'

const css = {
	container: {
		padding: 2,
		borderRadius: 2,
		background: 'whitesmoke',
		margin: '6px 0',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	}
}

function BaseConfigGroup({ group }) {
	const [params, setParams] = useState({ ...group })
	const [deleted, setDeleted] = useState(false)

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	function onBlur() {
		const body = { ...params }
		editBaseSignGroup(body)
	}

	function onDelete() {
		const body = { ...params }
		deleteBaseSignGroup(body)
		setDeleted(true)
	}

	if (deleted) return null

	return (
		<Box sx={css.container}>
			<Box>
				<SeamlessInput color="transparent" name="name" onChange={onChange} onBlur={onBlur} variant='subtitle1' value={params?.name} />
				<SeamlessInput color="transparent" name="description" onChange={onChange} onBlur={onBlur} variant='subtitle1' value={params?.description} />
			</Box>
			<IconButton onClick={onDelete}>
				<Delete />
			</IconButton>
		</Box>
	)
}

export default BaseConfigGroup