import React, { Component } from 'react'
import { Checkbox, Collapse, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../../../Shared/Inputs/TextInput'
import autobind from '../../../../../Utils/autobind'
import ImgInput from '../../../../../Shared/Inputs/ImgInput'
import instance from '../../../../../Utils/instance'

const style = () => ({
  inline: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 8
    }
  }
})

class TextStepCreate extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(TextStepCreate, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleChange(event) {
    const { onChange } = this.props
    const { target } = event
    const file = target.value

    const body = new FormData()
    body.append("file", file)

    instance({
      method: 'post',
      url: 'workflows/upload-file.php',
      data: body
    }).then(response => {
      const newEvent = {
        target: {
          name: target.name,
          value: response.data.info.file_name
        }
      }
      onChange(newEvent)
    })
  }

  render() {
    const { open } = this.state
    const { classes, params, onChange, noImage } = this.props
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}`
    return (
      <div className={classes.container}>
        <TextInput label="Titulo" name="label" value={params.label} onChange={onChange} />
        {!noImage &&
          <>
            <div className={classes.inline}>
              <Checkbox onClick={this.handleOpen} checked={open} />
              <Typography variant="subtitle1">Agregar imágen a campo de texto</Typography>
            </div>
            <Collapse in={open}>
              <ImgInput url={url} value={params.image || ""} name="image" onChange={this.handleChange} label="Imágen" />
            </Collapse>
          </>}
      </div>
    )
  }
}

export default withStyles(style)(TextStepCreate)