import React, { Component } from 'react'
import { Checkbox, Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'

const style = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 6
    }
  }
})

class CheckboxInput extends Component {
  constructor() {
    super()
    autobind(CheckboxInput, this)
  }

  handleChange() {
    const { value = false, name, onChange } = this.props
    const event = {
      target: {
        name,
        value: !value
      }
    }
    onChange(event)
  }

  render() {
    const { classes, label = "check", value = false } = this.props
    return (
      <div className={classes.container}>
        <Checkbox checked={value} onClick={this.handleChange} />
        <Typography variant="subtitle1">{label}</Typography>
      </div>
    )
  }
}

export default withStyles(style)(CheckboxInput)