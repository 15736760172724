import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = theme => ({
  container: {
    background: 'whitesmoke',
    borderRadius: 24,
    height: 24,
    position: 'relative'
  },
  bar: {
    position: 'absolute',
    left: 0,
    background: theme.palette.purple.light,
    borderRadius: 24,
    height: 24,
    transition: 'all 0.4s ease-in-out'
  },
  text: {
    position: 'absolute',
    color: 'white',
    left: 12
  }

})

class ProgressBar extends Component {

  render() {
    const { classes, value, textValue } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.bar} style={{ width: `${value}%` }} />
        <Typography variant="subtitle1" className={classes.text}>{`${textValue}%`}</Typography>
      </div>
    )
  }
}

export default withStyles(style)(ProgressBar)