import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import FilterTag from './FilterTag'

const style = (theme) => ({
  container: {
    background: '#f2f3f8',
    padding: 12,
    borderRadius: 8,
    flexGrow: 1,
    flexBasis: 500,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > *': {
      minWidth: 250,
      flexBasis: '50%',
      flexGrow: 1
    }
  },
  scrollable: {
    maxHeight: 300,
    overflow: 'auto'
  }
})

export function getActivityNames(checklists) {
  const names = {}
  checklists.forEach(checklist => {
    const activity = checklist.preventive_measure
    if (!Object.keys(names).includes(activity)) {
      return names[activity] = {
        name: activity,
        amount: 1,
        users: { [checklist.user]: { name: checklist.user, id: checklist.user_id, amount: 1 } },
        id: checklist.preventive_measure_id
      }
    }
    if (Object.keys(names[activity].users).includes(checklist.user)) {
      names[activity].users[checklist.user].amount += 1
    } else {
      names[activity].users[checklist.user] = { name: checklist.user, id: checklist.user_id, amount: 1 }
    }

    return names[activity].amount += 1
  })
  return names
}

export function getWorkerNames(checklists) {
  const names = {}
  checklists.forEach(checklist => {
    const worker = checklist.user
    if (!Object.keys(names).includes(worker)) {
      return names[worker] = {
        name: worker,
        amount: 1,
        id: checklist.user_id
      }
    }

    return names[worker].amount += 1
  })
  return names
}


// boxShadow: "0px 10px 3px -7px" TODO add animation on scroll
class InnerChecklists extends Component {

  render() {
    const { classes, selectedChecklists = [], onSelect, selectedActivities, onSelectWorker, selectedWorkers } = this.props
    const activities = getActivityNames(selectedChecklists)
    const workers = getWorkerNames(selectedChecklists)
    return (
      <div className={classes.container}>
        <div>
          <Typography variant='h4' style={{ padding: 6 }}>Actividades</Typography>
          <div className={classes.scrollable}>
            {Object.keys(activities).map(activity => <FilterTag
              key={activity}
              element={activities[activity]}
              onSelect={onSelect}
              selected={selectedActivities}
            />
            )}
          </div>
        </div>
        <div>
          <Typography variant='h4' style={{ padding: 6 }}>Colaboradores</Typography>
          <div className={classes.scrollable}>
            {Object.keys(workers).map(worker => <FilterTag
              key={worker}
              element={workers[worker]}
              onSelect={onSelectWorker}
              selected={selectedWorkers}
            />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(InnerChecklists)