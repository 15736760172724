import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import DocumentContext from './DocumentContext'
import Document from './Document'
import { AddCircle } from '@material-ui/icons'
import DocumentDialog from './DocumentDialog'
import autobind, { addToggle } from '../../../Utils/autobind'

const style = () => ({
  container: {
    flexGrow: 1,
    '& > h4': {
      paddingTop: 6
    },
    height: 500,
    width: '100%',
    maxWidth: 700
  },
  addButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    background: 'white',
    transition: 'all 0.2s linear',
    transform: 'scale(0)'
  },
  show: {
    transform: 'scale(1)'
  },
  documents: {
    maxHeight: 400,
    overflow: 'auto'
  }
})
class Documents extends Component {
  constructor() {
    super()
    this.state = {
      openCreate: false
    }
    addToggle(Documents, this, "create")
    autobind(Documents, this)
  }
  renderDocuments() {
    const { documents, disableAskSigns } = this.props
    const { selectedCategory, selectedSubcategory, selectedSubSubcategory } = this.context
    const filteredDocuments = documents.filter(document => {
      if (selectedCategory) return String(document.category_id) === String(selectedCategory) && !document.subcategory_id
      if (selectedSubcategory) return String(document.subcategory_id) === String(selectedSubcategory)
      if (selectedSubSubcategory) return String(document.sub_subcategory_id) === String(selectedSubSubcategory)
      return false
    })
    return filteredDocuments.map(document => {
      return <Document document={document} key={document.id} disableAskSigns={disableAskSigns} />
    })
  }

  render() {
    const { classes } = this.props
    const { openCreate } = this.state
    const { title } = this.context
    return (
      <div className={classes.container}>
        <Typography variant="h4" style={{ marginBottom: 12 }}>{title}</Typography>
        <div className={classes.documents}>
          {this.renderDocuments()}
        </div>
        <IconButton
          className={`${classes.addButton} ${title ? classes.show : ""}`}
          onClick={this.handleOpenCreate}
        >
          <AddCircle />
        </IconButton>
        <DocumentDialog type="create" open={openCreate} onClose={this.handleOpenCreate} />
      </div>
    )
  }
}

Documents.contextType = DocumentContext

export default withStyles(style)(Documents)