import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const answerExtraFields = body => {
  return instance({
    method: 'post',
    url: 'findings/extra_fields.php',
    data: body
  })
}

export const answerExtraFieldsConformity = body => {
  return instance({
    method: 'post',
    url: 'nonconformities/extra_fields.php',
    data: body
  })
}

export const getFindings = body => {
  return instance2({
    method: 'get',
    url: 'findings/get.php',
    params: body
  })
}

export const getUnconformities = body => {
  return instance2({
    method: 'get',
    url: "/nonconformities/get.php",
    params: body
  })
}

export const getFindingType = body => {
  return instance2({
    method: 'get',
    url: 'findings/types',
    data: body
  })
}

export const createFindingType = body => {
  return instance2({
    method: 'post',
    url: 'findings/types',
    data: body
  })
}

export const getBaseFindingActions = body => {
  return instance2({
    method: 'get',
    url: 'findings/actions',
    params: body
  })
}
export const createBaseFindingAction = body => {
  return instance2({
    method: 'post',
    url: 'findings/actions',
    data: body
  })
}

export const editBaseFindingAction = body => {
  return instance2({
    method: 'put',
    url: 'findings/actions',
    data: body
  })
}

export const deleteBaseFindingAction = body => {
  return instance2({
    method: 'delete',
    url: 'findings/actions',
    data: body
  })
}

export const getBaseFindingMeasures = body => {
  return instance2({
    method: 'get',
    url: 'findings/measures',
    params: body
  })
}

export const editBaseFindingMeasures = body => {
  return instance2({
    method: 'put',
    url: 'findings/measures',
    data: body
  })
}

export const createBaseFindingMeasures = body => {
  return instance2({
    method: 'post',
    url: 'findings/measures',
    data: body
  })
}

export const deleteBaseFindingMeasures = body => {
  return instance2({
    method: 'delete',
    url: 'findings/measures',
    data: body
  })
}

export const getBaseFindingTimings = body => {
  return instance2({
    method: 'get',
    url: 'findings/timings',
    params: body
  })
}

export const editBaseFindingTimings = body => {
  return instance2({
    method: 'put',
    url: 'findings/timings',
    data: body
  })
}

export const createBaseFindingTimings = body => {
  return instance2({
    method: 'post',
    url: 'findings/timings',
    data: body
  })
}

export const deleteBaseFindingTimings = body => {
  return instance2({
    method: 'delete',
    url: 'findings/timings',
    data: body
  })
}

export const createFreeFinding = body => {
  return instance2({
    method: 'post',
    url: 'findings',
    data: body
  })
}