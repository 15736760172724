import React, { Component } from "react"
import {
    Paper,
    Tab,
    Typography,
    withStyles,
    Divider,
    IconButton,
} from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind, { addToggle } from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
import { Add, Delete, ExitToApp, GetApp } from "@material-ui/icons"
import AddChecklist from "./AddChecklist"
import { deleteChecklistAction, getChecklistAction, getDoneChecklistsAction, getPendingChecklistsAction } from "../../Actions/CheckListActions"
import { connect } from "react-redux"
import LoaderComponent from "../../Shared/LoaderComponent"
import { getEnterpriseActivitiesAction } from "../../Actions/ActivityActions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import moment from 'moment'
import PermissionChecker from "../../Shared/Permissions/PermissionChecker"
import PermissionBoolean from "../../Shared/Permissions/PermissionBoolean"
import { openGlobalDialog } from "../../Actions/GlobalActions"
import { addQrToFile, mergeMultiplePdfFilesAndGet } from "../../Utils/functions"
import DateIntervalSelector from "../../Shared/Inputs/DateIntervalSelector"
import TagsFilterDialog from "./TagsFilterDialog"
import DownloadButton from "../../Shared/DownloadButton"
import ExtraFieldDialog from "../Finding&Conformities/ExtraFieldDialog"
import Chip from "./Chip"
import TagCell from "../../Shared/TableRenders/TagCell"
import SubdivisionInfoDialog from "./SubdivisionInfoDialog"
import NumberRender from "./NumberRender"
import { Box, Button } from "@mui/material"
import ChecklistProgressFollowing from "./ChecklistProgressFollowing"

const style = () => ({
    container: { margin: 12 },
    title: {
        margin: "36px 0 12px 0 ",
    },
    divider: {
        marginBottom: 24,
    },
    inline: {
        display: "flex",
        alignItems: "center",
        "& > *": {
            margin: "0 0 0 12px",
        },
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 24,
    },
    open: {
        transition: "all 0.3s linear",
        maxHeight: "500vh",
        overflow: "hidden",
    },
    closed: {
        transition: "all 0.3s linear",
        maxHeight: 0,
        overflow: "hidden",
    },
    tableTopper: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    }
})

const filters = [
    { name: "Nombre", label: "user" },
    { name: "Centro", label: "branch_name" }
]

const sortables = [
    { label: 'date_scheduled', name: "Fecha de Programación" },
    { label: "preventive_measure", name: "Medida preventiva" },
    { label: "user", name: "Responsable" },
    { label: "compliance", name: "Cumplimiento" },
    { label: "progress", name: "Avance" },
]

function checkIfDateOutOfRange(checklist) {
    const threeDaysAhead = moment(new Date()).add(3, 'days')
    return moment(checklist.date_scheduled) >= threeDaysAhead
}

function checkIfFinished(checklist) {
    return checklist.status !== "Terminado"
}

class Checklists extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
            open: false,
            loadingDone: true,
            loadingPending: true,
            updated: false,
            selected: [],
            subdivision_selected: null,
            branch_name: "",
            following: false
        }
        addToggle(Checklists, this, 'tags')
        autobind(Checklists, this)
    }

    componentDidMount() {
        this.getInformation()
    }

    componentDidUpdate(prevProps) {
        const { branch } = this.props
        if (branch.global.id !== prevProps.branch.global.id) {
            this.setState({ loadingDone: true, loadingPending: true }, this.componentDidMount)
        }
    }

    getInformation() {
        const { getActivities, enterprise, branch, getWorkers, online } = this.props
        const body = {
            companyId: enterprise.selected.id,
            branch_id: branch.global.id,
        }
        if (online.status) {
            getActivities(body)
            getWorkers(body)
            this.setState({ updated: false })
        } else {
            this.setState({ loadingDone: false, loadingPending: false })
        }
    }

    handleSelectDate(dates) {
        this.setState({ loadingPending: true, loadingDone: true })
        const { getPendingChecklists, getDoneChecklists, enterprise, branch, online } = this.props
        const body = {
            companyId: enterprise.selected.id,
            branch_id: branch.global.id,
            date_start: dates.date_start,
            date_end: dates.date_end
        }
        console.log(body)
        if (online.status) {
            getPendingChecklists(body).then(() => this.setState({ loadingPending: false }))
            getDoneChecklists(body).then(() => this.setState({ loadingDone: false }))
        } else {
            this.setState({ loadingDone: false, loadingPending: false })
        }
        this.setState({ updated: true })
    }

    handleGoto(row) {
        return () => {
            const { history } = this.props
            history.push(`/checklists2/${row.id}`)
        }
    }

    handleDownload(row, params) {
        const { user } = this.props
        return async () => {
            const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_CHECKLIST_PDF}?id=${row.id}&u=${user.account.user.id}`
            const parsed_url = new URL(url)
            if (params) {
                Object.keys(params).forEach(key => {
                    parsed_url.searchParams.append(key, params[key])
                })
            }
            const final_url = parsed_url.toString()
            const signedDocument = await mergeMultiplePdfFilesAndGet([final_url], "Reporte de registro o formulario")
            const code = `${row.id}--l`
            const validateUrl = `${window.location.origin}/validate/${code}`
            addQrToFile(signedDocument, `${moment(row.date_done).format("DD-MM-YYYY")}-${row.preventive_measure.replaceAll(".", "-")}-${row.branch_name}`, validateUrl)
        }
    }

    handleDelete(row) {
        return () => {
            const { deleteChecklist, openGlobal } = this.props
            const body = { id: row.id }
            openGlobal(deleteChecklist, body)
        }
    }

    handleChangeTab(event, value) {
        this.setState({ tab: value })
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    handleShowInfo(row) {
        if (row === null) return this.setState({ selected: [] })
        this.setState({ selected: row.extra_fields })
    }

    render() {
        const { classes, checklists, user } = this.props
        const { tab, open, loadingPending, loadingDone, updated, openTags, selected, subdivision_selected, branch_name, following } = this.state
        const pendings = checklists.pending || []
        const done = checklists.done || []
        const actions = [
            { name: "Ver registro o formulario", link: (row) => `/checklists2/${row?.id}`, icon: ExitToApp, action: this.handleGoto, color: "primary", disabled: checkIfDateOutOfRange },
            { name: "Descargar Resumen", component: DownloadButton, action: this.handleDownload, color: "primary", disabled: checkIfFinished },
        ]

        const level = user?.account?.user?.userType

        const hasPermissions = PermissionBoolean(["dch"]) && level === 1
        const pendingActions = [...actions]
        if (hasPermissions) {
            pendingActions.push(
                { name: "Eliminar", icon: Delete, action: this.handleDelete, color: "secondary" },
            )
        }

        const pendingTableInfo = [
            { label: 'date_scheduled', name: "Fecha de Programación" },
            { label: "preventive_measure", name: "Registro o formulario", clickable: true, action: this.handleShowInfo },
            {
                label: "branch_name",
                name: "Ubicación",
                format: (value, element) => element?.subdivision?.name || value,
                clickable: true,
                action: (row) => this.setState({ subdivision_selected: row?.subdivision, branch_name: row?.branch_name })
            },
            { label: "user", name: "Responsable" },
            { label: "compliance", name: "Cumplimiento", format: (value) => `${value}%` },
            { label: "progress", name: "Avance", format: (value) => `${value}%` },
            { label: "status", name: "Estado", render: Chip },
            { label: "tags", name: "Etiquetas", render: TagCell }
        ]

        const doneTableInfo = [
            { label: 'date_scheduled', name: "Fecha de Programación" },
            { label: 'date_done', name: "Fecha de Realización" },
            { label: "preventive_measure", name: "Registro o formulario", clickable: true, action: this.handleShowInfo },
            {
                label: "branch_name",
                name: "Ubicación",
                format: (value, element) => element?.subdivision?.name || value,
                clickable: true,
                action: (row) => this.setState({ subdivision_selected: row?.subdivision, branch_name: row?.branch_name })
            },
            { label: "user", name: "Responsable" },
            { label: "compliance", name: "Cumplimiento", render: NumberRender },
        ]


        const isMobile = window.innerWidth <= 500

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h1">Registros y formularios</Typography>
                    <PermissionChecker expectedPermissions={["cch"]}>
                        {level < 3 && <div className={classes.inline}>
                            <Typography variant="subtitle1">
                                Agregar registro o formulario
                            </Typography>
                            <IconButton onClick={this.handleOpen}>
                                <Add />
                            </IconButton>
                        </div>}
                    </PermissionChecker>
                </div>
                {open && <AddChecklist />}
                <Divider className={classes.divider} />
                <Paper square>
                    <MyTabs value={tab} onChange={this.handleChangeTab}>
                        <Tab
                            label={
                                <Typography variant="h4">
                                    Registros y formularios Abiertos
                                </Typography>
                            }
                            value={0}
                        />
                        <Tab
                            label={
                                <Typography variant="h4">
                                    Registros y formularios Realizados
                                </Typography>
                            }
                            value={1}
                        />
                    </MyTabs>
                </Paper>
                <ExtraFieldDialog
                    open={!!selected.length}
                    onClose={() => this.handleShowInfo(null)}
                    title={"Información extra"}
                    fields={selected}
                />
                <SubdivisionInfoDialog
                    subdivision={subdivision_selected}
                    open={!!subdivision_selected}
                    onClose={() => this.setState({ subdivision_selected: null })}
                    branch_name={branch_name}
                />
                <TabPanel value={tab} index={0}>
                    <Box className={classes.title} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h1" >
                            Registros y formularios Abiertos
                        </Typography>
                        <Button color="primary" variant="outlined" onClick={() => this.setState({ following: !following })}>
                            Revisar {following ? "Registros" : "Seguimiento"}
                        </Button>
                    </Box>
                    <Divider className={classes.divider} />
                    <div className={classes.tableTopper}>
                        <DateIntervalSelector onDateChange={this.handleSelectDate} updated={updated} />
                    </div>
                    {following ?
                        <ChecklistProgressFollowing pendings={pendings} />
                        :
                        <LoaderComponent loading={loadingPending}>
                            <GeneralTable
                                name="Registros"
                                info={pendingTableInfo}
                                data={pendings}
                                filters={filters}
                                actions={pendingActions}
                                sortings={isMobile ? sortables : []}
                            />
                        </LoaderComponent>
                    }
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Typography variant="h1" className={classes.title}>
                        Registros y formularios Realizados
                    </Typography>
                    <Divider className={classes.divider} />
                    <DateIntervalSelector onDateChange={this.handleSelectDate} updated={updated} />
                    <LoaderComponent loading={loadingDone}>
                        <GeneralTable
                            name="Registros Finalizados"
                            info={doneTableInfo}
                            data={done}
                            filters={filters}
                            actions={actions}
                            sortings={isMobile ? sortables : []}
                        />
                    </LoaderComponent>
                </TabPanel>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    checklists: state.checklists,
    branch: state.branch,
    enterprise: state.enterprise,
    online: state.online,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    getPendingChecklists: (body) => dispatch(getPendingChecklistsAction(body)),
    getDoneChecklists: (body) => dispatch(getDoneChecklistsAction(body)),
    getChecklist: (body) => dispatch(getChecklistAction(body)),
    getActivities: (body) => dispatch(getEnterpriseActivitiesAction(body)),
    deleteChecklist: body => dispatch(deleteChecklistAction(body)),
    getWorkers: (body) => dispatch(getWorkersAction(body)),
    openGlobal: (action, body) => dispatch(openGlobalDialog(action, body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Checklists))
