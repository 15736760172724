import React, { Component } from "react"
import { Typography, withStyles, Paper, Box } from "@material-ui/core"
import { connect } from "react-redux"
import { getEnterpriseAction, getWorkersAction } from "../../Actions/EnterpriseAction"
import autobind from "../../Utils/autobind"
import { TokenTest } from "../../Actions/LoginActions"
import { transformToSuperDispatch } from "../../Utils/functions"
import Shortcuts from "./Shortcuts"
import ChecklistsManagement from "./ChecklistsManagement"
// import FindingManagement from "./FindingManagement"
// import UserDocumentManagement from "./UserDocumentManagement"
// import NonConformitiesManagement from "./NonConformitiesManagement"
import Pendings from "./Pendings"
import moment from 'moment'
import SubBranchShortcuts from "./SubBranchShortcuts"
import { getEnterpriseActivitiesAction } from "../../Actions/ActivityActions"
import QuickAccessChecklists from "./QuickAccessChecklists"
import { FiClock } from "react-icons/fi"
import { getAskedSigns, getUserPendings, getWorkers, registerLogin } from "../../API/users"
import { getFindingsAction } from "../../Actions/FindingActions"
import { getNonConformitiesAction } from "../../Actions/NoConfActions"
import { getTalksAction } from "../../Actions/TalkActions"
import { getTrainingsAction } from "../../Actions/TrainingActions"
import { getChecklist } from "../../API/checklists"
import { getFindings, getUnconformities } from "../../API/findings&nc"
import { getAllTalks } from "../../API/talks"
import { getAllTrainings } from "../../API/trainings"
import { getActivities } from "../../API/activities"
import { getBranches } from "../../API/branches"
import DateIntervalSelector from "../../Shared/Inputs/DateIntervalSelector"
import { getDoneChecklistsAction, getPendingChecklistsAction } from "../../Actions/CheckListActions"
import PeriodicalChecklists from "./PeriodicalChecklists"
import OtherBranchPendings from "./Pendings/OtherBranchPendings"
import NewPendings from "./NewPendings"
import UserComplianceResumeChecklist from "./Dashboard/UserComplianceResumeChecklist"
import WorkerBaseActivities from "../Workers/WorkerBaseActivities"
import SupervisionHome from "./Supervision/SupervisionHome"
import FastFindingManager from "./FastFindings/FastFindingManager"
import { inline } from "../../Utils/defaultStyles"

// FiAlertCircle
// FiMenu
// FiWifi
// FiBell
// FiUser
// FiUsers
// FiCheckSquare
// FiSearch
// FiMapPin
// FiCalendar
// FiCornerDownRight
// FiLogOut
// FiBriefCase

const style = (theme) => ({
	container: {
		margin: 12,
	},
	grid: {
		display: "flex",
		gap: "24px",
		flexWrap: "wrap",
		justifyContent: "space-between",
		alignItems: "center",
	},
	divider: {
		margin: "24px 0",
	},
	cardsContainer: {
		padding: 12,
		borderRadius: 5,
		"& > h1": {
			color: theme.palette.grey.dark2,
			margin: "0 0 12px 0",
			fontSize: "1.25rem",
		},
	},
	fullContainer: {
		background: theme.palette.primary.light,
	},
	titleContainer: {
		background: theme.palette.primary.main,
		padding: 12,
		maxWidth: 500
	},
	title: {
		padding: 24
	},
	card: {
		marginBottom: 12
	},
	inlineCards: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'stretch',
		'& > *': {
			flexBasis: '49%',
			maxWidth: '49%'
		}
	},
	welcome: {
		display: 'flex',
		'& > *': {
			flexBasis: '50%'
		}
	},
	welcomeMessage: {
		padding: '12px 24px',
		'& > h1': {
			color: theme.palette.purple.light,
			fontSize: '3rem',
		},
		'& > h2': {
			color: theme.palette.primary.main,
			fontSize: '1.7rem',
		},
		'& > h3': {
			color: theme.palette.primary.main,
			fontSize: '1rem',
			marginTop: 24
		},
	},
	welcomeMessageMobile: {
		"@media (max-width:500px)": {
			background: theme.palette.blue.dark,
			position: 'relative',
			'&::before': {
				content: "''",
				position: 'absolute',
				bottom: 0,
				right: 0,
				height: 24,
				width: '100%',
				background: theme.palette.blue.dark,
				transform: 'translate(0, 100%)',
			},
			'&::after': {
				content: "''",
				position: 'absolute',
				bottom: 0,
				left: 0,
				height: 24,
				width: '100%',
				background: 'whitesmoke',
				transform: 'translate(0, 100%)',
				borderRadius: '30px 30px 0 0',
			}
		},
	},
	titleIcon: {
		color: theme.palette.purple.light,
		height: 24,
		width: 24
	},
	pendingTitle: {
		color: theme.palette.purple.light
	},
	pendingCard: {
		padding: 24,
		marginBottom: 24
	}
})

class Home extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			checklists: [],
			findings: [],
			unconformities: [],
			talks: [],
			trainings: [],
			documents: [],
			activities: [],
			branches: [],
			workers: [],
			pending: [],
			done: []
		}

		autobind(Home, this)
	}

	async componentDidMount() {
		const { user: { account: { user: { id } } } } = this.props
		const registerBody = { id, timestamp: moment().format("YYYY-MM-DD HH:mm:ss") }
		registerLogin(registerBody)
		this.handleLoadAll()
		window.addEventListener("branch_change", this.handleLoadAll)
	}

	componentWillUnmount() {
		window.removeEventListener("branch_change", this.handleLoadAll)
	}

	async handleSelectDate(dates) {
		const body = { ...dates }
		this.setState({ loading: true })
		const response = await Promise.all([
			getChecklist({ status: 'pending', ...body }),
			getChecklist({ status: 'done', ...body }),
		])
		this.setState({ loading: false })
		const datas = response.map(res => res?.data?.info)
		const [pending, done] = datas
		this.setState({ pending, done })
	}

	loadOffline() {
		const { checklists, findings, unconformities } = this.props
		const allFindings = findings.all || []
		const allNonConformities = unconformities.all || []
		const allchecklists = checklists.pending || []
		this.setState({ checklists: allchecklists, findings: allFindings, unconformities: allNonConformities })
	}

	async handleLoadAll() {
		const { getPendingChecklists, getDoneChecklists, online, getReduxFindings, getReduxNonConformities } = this.props
		const body = {
			date_start: moment().startOf("week").subtract(5, 'days').format("YYYY-MM-DD"),
			date_end: moment().endOf("week").format("YYYY-MM-DD")
		}

		if (!online.status) return this.loadOffline()

		getPendingChecklists(body)
		getDoneChecklists(body)
		getReduxFindings(body)
		getReduxNonConformities(body)
	}

	render() {
		const { classes, history, user, online } = this.props
		const { loading } = this.state
		const level = user?.account?.user?.userType
		const isMobile = window.innerWidth <= 900
		const isSubBranch = user?.account?.user?.sub_branch_id !== ""
		const values = { ...this.state }
		return (
			<div className={classes.container}>
				{level > 1 &&
					<Box style={{ margin: '12px 0' }}>
						<WorkerBaseActivities disableNew user_id={user?.account?.user?.id} />
					</Box>
				}
				<>
					<NewPendings history={history} online={online.status} />
					{isSubBranch && <SubBranchShortcuts />}
				</>
				{!isMobile &&
					<div className={classes.inlineCards}>
						<Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>BIENVENIDO</Typography>
							<div className={classes.welcome}>
								<div className={classes.welcomeMessage}>
									<Typography variant="h1">Hola</Typography>
									<Typography variant="h2">{user?.account?.user?.name}</Typography>
									<Typography variant="h3">{moment(new Date()).format("dddd DD [de] MMMM YYYY")}</Typography>
								</div>
							</div>
						</Paper>
						<Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>ACCESOS RÁPIDOS</Typography>
							<Shortcuts history={history} level={level} />
						</Paper>
					</div>
				}
				<Paper className={classes.card}>
					<Typography variant="h1" className={classes.title}>ACCIONES RÁPIDAS</Typography>
					<QuickAccessChecklists values={values} />
				</Paper>
				<SupervisionHome />
				<FastFindingManager />
				{level === 1 &&
					<>
						<Paper className={classes.card}>
							<Typography variant="h1" className={classes.title}>GESTIÓN REGISTROS Y FORMULARIOS</Typography>
							<div style={{ margin: '0 24px' }}>
								<DateIntervalSelector onDateChange={this.handleSelectDate}
									start_at={moment().format("YYYY-MM-DD")}
									end_at={moment().add(1, 'day').format("YYYY-MM-DD")}
								/>
							</div>
							<ChecklistsManagement loading={loading} values={values} />
						</Paper>
						<UserComplianceResumeChecklist />
						<PeriodicalChecklists />
					</>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	checklists: state.checklists,
	enterprise: state.enterprise,
	user: state.user,
	online: state.online,
	findings: state.findings,
	unconformities: state.unconformities
})

const mapDispatchToProps = (dispatch) => ({
	getPendingChecklists: (body) => dispatch(getPendingChecklistsAction(body)),
	getDoneChecklists: (body) => dispatch(getDoneChecklistsAction(body)),
	getEnterprise: (body) => dispatch(getEnterpriseAction(body)),
	tokenTest: () => dispatch(TokenTest()),
	getActivities: (body) => dispatch(getEnterpriseActivitiesAction(body)),
	getReduxFindings: () => dispatch(getFindingsAction()),
	getReduxNonConformities: () => dispatch(getNonConformitiesAction()),
	getTalks: () => dispatch(getTalksAction()),
	getTrainings: () => dispatch(getTrainingsAction()),
	getWorkers: () => dispatch(getWorkersAction())
})

export default withStyles(style)(
	connect(mapStateToProps, transformToSuperDispatch(mapDispatchToProps))(Home)
)
