import React, { Component } from "react"
import {
  withStyles,
  Paper,
  Tab,
  Typography,
  Divider,
} from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
// import Panel from "../Activities/Panel"
import AddElementButton from "../../Shared/AddElementButton"
import { createMultipleTrainingsAction, createTrainingAction, getTrainingAction, getTrainingsAction } from "../../Actions/TrainingActions"
import { connect } from "react-redux"
import { AssignmentTurnedInOutlined, Delete, ExitToApp } from "@material-ui/icons"
import moment from 'moment'
import { getSettingsTrainingsAction, getTrainingsCategoriesAction } from "../../Actions/SettingsActions"
import GeneralForm from "../../Shared/GeneralForm"
import FormContext from "../../Shared/Cards/Provider"
import { addQrToFile, mergeMultiplePdfFilesAndGet, transformToOptions } from "../../Utils/functions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import { filterActiveWorkers } from "../../Utils/filters"
import SignedCell from "../../Shared/TableRenders/SignedCell"
import { deleteTraining, editTrainingParticipants } from "../../API/trainings"
import { openGlobalDialog } from "../../Actions/GlobalActions"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import LoadingDialog from "../../Shared/LoadingDialog"
import DownloadButton from "../../Shared/DownloadButton"

const style = () => ({
  container: {
    margin: 12,
  },
  title: {
    margin: "36px 0 12px 0 ",
  },
  divider: {
    marginBottom: 24,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  inline: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
})

const tableInfo = [
  { name: "Fecha Programación", label: "date_scheduled", format: (value) => moment(value).format("YYYY-MM-DD") },
  { name: "Tipo", label: "category_name" },
  { name: "Tema", label: "name" },
  { name: "Responsable", label: "user_name" },
  { name: AssignmentTurnedInOutlined, label: "all", render: SignedCell },
]

const doneTableInfo = [
  { name: "Fecha Realizado", label: "date_saved", format: (value) => moment(value).format("YYYY-MM-DD") },
  { name: "Tipo", label: "category_name" },
  { name: "Tema", label: "name" },
  { name: "Responsable", label: "user_name" },
  { name: "Participantes", label: "participants_signs", format: (value) => value.length }
]

function checkIfFinished(element) {
  return moment(element.date_saved).format("YYYY-MM-DD").includes("Fecha")
}

function checkIfNotFinished(element) {
  return !moment(element.date_saved).format("YYYY-MM-DD").includes("Fecha")
}

class Trainings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
      params: {
        date_scheduled: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      },
      openForm: false,
      loading: false,
      loadingParticipants: false
    }

    autobind(Trainings, this)
  }

  componentDidMount() {
    window.addEventListener("branch_change", this.handleReload)
    this.handleReload()
  }

  componentWillUnmount() {
    window.removeEventListener("branch_change", this.handleReload)
  }

  async handleReload() {
    const { getTrainings, getSettingTraining, getWorkers, getTrainingCategories } = this.props
    this.setState({ loading: true })
    await getTrainings()
    await getSettingTraining()
    this.setState({ loading: false })
    getWorkers()
    getTrainingCategories()
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
  }

  handleDelete(row) {
    return () => {
      const { openGlobalDialog } = this.props
      openGlobalDialog(deleteTraining, { id: row.id }, {
        callback: this.handleReload,
        message: "Seguro que quieres eliminar esta capacitación",
        title: "Eliminar Capacitación",
        submit: "Eliminar"
      })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value

    if (target.name === "user_id") {
      const filteredWorkers = filterActiveWorkers()
      const supervised = filteredWorkers.filter(worker => String(worker.user.id) === target.value)
      const supervisedIds = supervised.map(user => user.id)
      params.participant_ids = supervisedIds
    }

    this.setState({ params })
  }

  handleGoto(row) {
    return () => {
      const { history, selectTraining } = this.props
      selectTraining(row.id)
      history.push(`/trainings/${row.id}`)
    }
  }

  handleOpen() {
    this.setState({ openForm: !this.state.openForm })
  }

  async handleCreate() {
    const { params } = this.state
    const { createTrainings } = this.props
    const body = { ...params }
    const participants = [...params.participant_ids]
    const response = await createTrainings(body)
    const createdTrainings = [...response.payload.data.info]

    this.setState({ loadingParticipants: true })
    await Promise.all(createdTrainings.map(async (training) => {
      const body = { id: training.id, participants }
      await editTrainingParticipants(body)
    }))
    this.setState({ loadingParticipants: false })

    this.setState({
      openForm: false, params: {
        date_scheduled: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      },
    })
  }

  handleDownload(row, params) {
    return async () => {
      const { user } = this.props
      const file = row.file
      const url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TRAININGS_PDF}?id=${row.id}&u=${user.account.user.id}`

      const parsed_url = new URL(url1)
      if (params) {
        Object.keys(params).forEach(key => {
          parsed_url.searchParams.append(key, params[key])
        })
      }
      console.log(parsed_url.toString())
      const final_url = parsed_url.toString()
      console.log(final_url)

      const url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER + "/" + file
      if (file.includes(".pdf")) {
        const signedFile = await mergeMultiplePdfFilesAndGet([final_url, url2], "Reporte Capacitación")
        const code = `${row.id}--t`
        const validateUrl = `${window.location.origin}/validate/${code}`
        return addQrToFile(signedFile, "Reporte Capacitación", validateUrl)
      } else {
        const signedFile = await mergeMultiplePdfFilesAndGet([final_url], "Reporte Capacitación")
        const code = `${row.id}--t`
        const validateUrl = `${window.location.origin}/validate/${code}`
        return addQrToFile(signedFile, `${row.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}`, validateUrl)
      }
    }
  }

  render() {
    const { classes, trainings, settings, user } = this.props
    const { tab, params, openForm, loading, loadingParticipants } = this.state
    const allTrainings = trainings.all || []
    const allSettingsTraining = settings.trainings?.all || []
    const allTrainingCategories = settings.trainings?.categories || []
    const filteredTrainings = allSettingsTraining.filter(training => training?.category_id?.toString() === params.category_id)
    const pendingTrainings = allTrainings.filter(training => moment(training.date_saved).format("YYYY-MM-DD").includes("Fecha"))
    const doneTrainings = allTrainings.filter(training => !moment(training.date_saved).format("YYYY-MM-DD").includes("Fecha"))
    let actions = [
      { name: "Ver Capacitación", icon: ExitToApp, action: this.handleGoto },
      { name: "Descargar Resumen", component: DownloadButton, action: this.handleDownload, color: "primary", disabled: checkIfFinished },
    ]

    const formInfo = [
      { name: "user_id", label: "Responsable", type: "users" },
      { name: "participant_ids", label: "Participantes", type: "users", options: "multiple" },
      { name: "category_id", label: "Categoría", type: "select", options: transformToOptions(allTrainingCategories) },
      { name: "documents_ids", label: "Capacitación", type: "multiselect", options: transformToOptions(filteredTrainings) },
      { name: "date_scheduled", label: "Fecha de realización", type: "datetime" }
    ]

    const level = user.account.user.userType

    if (level === 1) {
      actions = [
        { name: "Eliminar Capacitación", icon: Delete, action: this.handleDelete, disabled: checkIfNotFinished },
        { name: "Ver Capacitación", icon: ExitToApp, action: this.handleGoto },
        { name: "Descargar Resumen", component: DownloadButton, action: this.handleDownload, color: "primary", disabled: checkIfFinished },
      ]
    }

    if (loading) return <LoaderAnimator />

    return (
      <div className={classes.container}>
        <LoadingDialog open={loadingParticipants} loadingMessage="Invitando participantes..." />
        <div className={classes.header}>
          <Typography variant="h1">Capacitaciones</Typography>
          {level !== 3 && <AddElementButton name="Agregar Capacitación" onClick={this.handleOpen} open={openForm} />}
        </div>
        <FormContext.Provider value={{ handleChange: this.handleChange, params }}>
          <GeneralForm open={openForm} title="Crear Capacitación" submit="Crear" info={formInfo} onSubmit={this.handleCreate} />
        </FormContext.Provider>
        <Divider className={classes.divider} />
        <Paper square>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab
              label={
                <Typography variant="h1">
                  Programadas
                </Typography>
              }
              value={0}
            />
            <Tab
              label={
                <Typography variant="h1">Realizadas</Typography>
              }
              value={1}
            />
          </MyTabs>
        </Paper>
        <TabPanel value={tab} index={0}>
          <Typography variant="h1" className={classes.title}>
            Capacitaciones Programadas
          </Typography>
          <Divider className={classes.divider} />
          <GeneralTable info={tableInfo} data={pendingTrainings} actions={actions} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Typography variant="h1" className={classes.title}>
            Capacitaciones Realizadas
          </Typography>
          <Divider className={classes.divider} />
          <GeneralTable info={doneTableInfo} data={doneTrainings} actions={actions} />
        </TabPanel>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  trainings: state.trainings,
  settings: state.settings,
  workers: state.workers,
  user: state.user,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getTrainings: () => dispatch(getTrainingsAction()),
  createTraining: body => dispatch(createTrainingAction(body)),
  createTrainings: body => dispatch(createMultipleTrainingsAction(body)),
  getSettingTraining: () => dispatch(getSettingsTrainingsAction()),
  getTrainingCategories: () => dispatch(getTrainingsCategoriesAction()),
  selectTraining: id => dispatch(getTrainingAction(id)),
  getWorkers: () => dispatch(getWorkersAction()),
  openGlobalDialog: (action, body, options) => dispatch(openGlobalDialog(action, body, options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Trainings))