import { Tooltip, Typography } from '@mui/material'

function BigTooltip({ title, children }) {
	return (
		<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >{title}</Typography>}>
			{children}
		</Tooltip>
	)
}

export default BigTooltip