import React, { Component } from 'react'
import { Button, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline, CategoryOutlined, Clear, DragIndicatorOutlined, Edit } from '@material-ui/icons'
import autobind from '../../../../Utils/autobind'
import TextInput from '../../../../Shared/Inputs/TextInput'
import MultiSelectInput from '../../../../Shared/Inputs/MultiSelectInput'
import { transformToOptions } from '../../../../Utils/functions'

const style = () => ({
  dialog: {
    padding: 24
  },
  categoryTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > svg': {
      height: 24,
      width: 24,
      marginRight: 6
    }
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

class Category extends Component {
  constructor() {
    super()
    this.state = {
      openEdit: false,
      openDelete: false,
      name: "",
      users: []
    }
    this.counter = 0
    autobind(Category, this)
  }

  componentDidMount() {
    const { category, editElement, onEdit } = this.props
    if (category.id !== 0) {
      this.setState({ name: category.name, users: Array.isArray(category?.users) ? category?.users : JSON.parse(category?.users), subtitle_order: category.subtitle_order })
    } else {
      this.setState({ name: category.name, users: [], subtitle_order: category.subtitle_order })
    }

    const self = this

    const docCategory = document.getElementById(`category-${category?.id}`)

    docCategory.addEventListener("dragstart", (e) => {
      e.dataTransfer.effectAllowed = "move"
      e.dataTransfer.setData('text/plain', JSON.stringify({ ...self.props.category, type: "subtitle" }))
      e.dataTransfer.dropEffect = "none"
      docCategory.style.opacity = "0.5"
    })

    docCategory.addEventListener("dragend", () => {
      docCategory.style.opacity = "1"
    })

    docCategory.addEventListener("dragenter", (e) => {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      this.counter++
      if (category.id !== 0) {
        docCategory.style.transform = "scale(1.03)"
        docCategory.style.zIndex = 3
      }
    })
    docCategory.addEventListener("dragover", (e) => { e.preventDefault() })
    docCategory.addEventListener("dragleave", () => {
      this.counter--
      if (this.counter === 0) {
        docCategory.style.transform = "scale(1)"
        docCategory.style.zIndex = 0
      }
    })
    docCategory.addEventListener("drop", (event) => {
      event.preventDefault()
      docCategory.style.transform = "scale(1)"
      this.counter = 0
      const eventData = JSON.parse(event.dataTransfer.getData("text/plain"))
      console.log(eventData)

      if (eventData.type === "subtitle") {
        if (category.id !== 0) {
          const actualIndex = category.subtitle_order
          const newIndex = eventData.subtitle_order
          const body = { ...category, subtitle_order: newIndex, users: Array.isArray(category.users) ? category.users : JSON.parse(category.users) }
          const otherBody = { ...eventData, subtitle_order: actualIndex, users: Array.isArray(eventData.users) ? eventData.users : JSON.parse(eventData.users) }
          onEdit(otherBody)
          onEdit(body)
        }
      } else {
        const body = { ...eventData, subtitle_id: String(category.id), checklist_id: self.props.checklist?.id }
        editElement(body)
      }
    })


  }

  handleChange(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleOpenEdit() {
    const { openEdit } = this.state
    this.setState({ openEdit: !openEdit })
  }

  handleOpenDelete() {
    const { openDelete } = this.state
    this.setState({ openDelete: !openDelete })
  }

  handleEdit() {
    const { onEdit, category } = this.props
    const { name, users } = this.state
    const body = { name, id: category.id, users }
    onEdit(body)
    this.setState({ openEdit: false })
  }

  handleDelete() {
    const { onDelete, category } = this.props
    const body = { id: category.id }
    onDelete(body)
    this.setState({ openDelete: false })
  }

  handleCreateElement() {
    const { onCreate, category } = this.props
    onCreate(category?.id)
  }

  render() {
    const { classes, category, workers } = this.props
    const { name, openEdit, openDelete, users } = this.state
    return (
      <div id={`category-${category.id}`} className={classes.inline} draggable={category.id !== 0}>
        <div className={classes.categoryTitle}>
          {category.id !== 0 && <DragIndicatorOutlined />}
          <CategoryOutlined color="secondary" />
          <Typography color="secondary" variant="h4">{category?.name}</Typography>
        </div>
        <div>
          <IconButton onClick={this.handleCreateElement} >
            <AddCircleOutline color="secondary" />
          </IconButton>
          {category.id !== 0 &&
            <>
              <IconButton onClick={this.handleOpenEdit}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleOpenDelete}>
                <Clear />
              </IconButton>
            </>
          }
        </div>
        <Dialog onClose={this.handleOpenEdit} open={openEdit} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h1">Editar categoría "{category?.name}"</Typography>
            <TextInput name="name" label="Nombre" onChange={this.handleChange} value={name} />
            <MultiSelectInput options={transformToOptions(workers)} name="users" label="Usuarios responsable" onChange={this.handleChange} value={users} />
            <div style={{ textAlign: 'end' }}>
              <Button color="primary" variant="outlined" onClick={this.handleEdit}>
                Guardar cambios
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog onClose={this.handleOpenDelete} open={openDelete} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h1">Eliminar categoría "{category?.name}"?</Typography>
            <div style={{ textAlign: 'end' }}>
              <Button color="primary" variant="outlined" onClick={this.handleDelete}>
                Si, Eliminar categoría
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(style)(Category)