// import TagCell from "../../Shared/TableRenders/TagCell"
import TagCell from "../../Shared/TableRenders/TagCell"
import Chip from "./Chip"
import NumberRender from "./NumberRender"

export const pendingTableInfo = (handleShowInfo) => [
  { label: 'date_scheduled', name: "Fecha de Programación" },
  { label: "preventive_measure", name: "Registro o formulario", clickable: true, action: handleShowInfo },
  { label: "branch_name", name: "Ubicación" },
  { label: "user", name: "Responsable" },
  { label: "compliance", name: "Cumplimiento", format: (value) => `${value}%` },
  { label: "progress", name: "Avance", format: (value) => `${value}%` },
  { label: "status", name: "Estado", render: Chip },
  { label: "tags", name: "Etiquetas", render: TagCell }
]

export const doneTableInfo = [
  { label: 'date_scheduled', name: "Fecha de Programación" },
  { label: 'date_done', name: "Fecha de Realización" },
  { label: "preventive_measure", name: "Registro o formulario" },
  { label: "branch_name", name: "Ubicación" },
  { label: "user", name: "Responsable" },
  { label: "compliance", name: "Cumplimiento", render: NumberRender },
]