import React, { Component } from 'react'
import { Collapse, Paper, Tab, Typography, withStyles } from '@material-ui/core'
import { createWorkflowAction, getSettingsWorkflowsAction, getWorkflowsAction } from '../../Actions/WorkFlows'
import { connect } from 'react-redux'
import AddElementButton from '../../Shared/AddElementButton'
import autobind from '../../Utils/autobind'
import TextInput from '../../Shared/Inputs/TextInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../Utils/functions'
import SubmitButton from '../../Shared/SubmitButton'
import moment from 'moment'
import { KeyboardArrowRight } from '@material-ui/icons'
import MyTabs from '../../Shared/MyTabs'
import GeneralTable from '../../Shared/GeneralTable'
import LoaderAnimator from '../../Shared/LoaderAnimator'

const style = () => ({
  container: {
    marginTop: 36,
    margin: 12
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  workflow: {
    padding: 24,
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      margin: '6px 0'
    }
  },
  divider: {
    margin: '12px 0'
  },
  form: {
    padding: 24
  },
  tabs: {
    background: 'white',
    margin: '24px 0'
  }
})

function getCompleted(workflow) {
  const steps = workflow?.workflow_steps || []
  return steps.map(step => !!step.data.finished).every(finished => finished)
}

function formatWorkflowDate(date) {
  if (!date) return "No registrado"
  return moment(date).format("DD-MM-YYYY HH:mm")
}

const workflowInfo = [
  { name: "Nombre", label: "name" },
  { name: "Creado por", label: "user" },
  { name: "Fecha de creación", label: "date_created", format: formatWorkflowDate },
  { name: "Fecha de término", label: "date_finished", format: formatWorkflowDate },
]

class Workflows extends Component {
  constructor() {
    super()
    this.state = {
      openCreate: false,
      params: {},
      tab: 1,
      name: "Accidentes",
      option: 1,
      loading: false
    }

    autobind(Workflows, this)
  }

  componentDidMount() {
    window.addEventListener("branch_change", this.handleReload)
    this.handleReload()
  }

  componentWillUnmount() {
    window.removeEventListener("branch_change", this.handleReload)
  }

  async handleReload() {
    const { getSettingsWorkflows, getWorkflows } = this.props
    const params = new URLSearchParams(window.location.search)
    const tab = params.get("tab")
    if (tab) { this.handleChangeTab(null, parseInt(tab, 10)) }
    this.setState({ loading: true })
    await getSettingsWorkflows()
    await getWorkflows()
    this.setState({ loading: false })
  }

  handleChangeTab(event, value) {
    const names = ["Accidentes", "Incidentes", "Reclamos", "Procesos"]
    const name = names[value - 1] || "Procesos"
    this.setState({ tab: value, name })
  }

  handlechangeOption(event, value) {
    this.setState({ option: value })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { createWorkflow } = this.props
    const { params } = this.state
    const body = {
      ...params,
      date_created: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    }
    createWorkflow(body)
    this.setState({ params: {} })
  }

  handleOpenCreate() {
    this.setState({ openCreate: !this.state.openCreate })
  }

  handleOpenWorkflow(wf) {
    return () => {
      const { history } = this.props
      history.push(`/workflows/${wf.id}`)
    }
  }

  renderCoursingWorkflows() {
    const { tab, name } = this.state
    const { workflows, classes, settings } = this.props
    const allWorkflows = workflows.all || []
    const filtered = allWorkflows
      .filter(wf => wf.workflow_type_id === tab)
      .filter(wf => !getCompleted(wf))
    if (filtered.length === 0) return (<Typography variant="h4" style={{ marginTop: 12 }}>No hay {name} iniciados</Typography>)
    return filtered
      .map(wf => {
        const allWorkflowsSettings = settings.workflows.all || []
        const selectedWorkflow = allWorkflowsSettings.find(swf => swf.id === wf.workflow_base_id)
        return (
          <Paper className={classes.workflow} onClick={this.handleOpenWorkflow(wf)}>
            <div>
              <Typography variant="h4">{wf.name}</Typography>
              <Typography variant="subtitle1">{selectedWorkflow?.name}</Typography>
              <Typography variant="subtitle1">Creado por: {wf?.user || "No disponible"}</Typography>
              <Typography variant="caption">{`Fecha de creación: ${moment(wf.date_created).format("DD-MM-YYYY HH:mm")}`}</Typography>
            </div>
            <KeyboardArrowRight />
          </Paper>
        )
      })
  }

  renderFinishedWorkflows() {
    const { tab, name } = this.state
    const { workflows } = this.props
    const allWorkflows = workflows.all || []
    const filtered = allWorkflows
      .filter(wf => wf.workflow_type_id === tab)
      .filter(getCompleted)
    if (filtered.length === 0) return (<Typography variant="h4" style={{ marginTop: 12 }}>No hay {name} terminados</Typography>)
    console.log(filtered)
    return (
      <GeneralTable data={filtered} info={workflowInfo} actions={[
        { icon: KeyboardArrowRight, action: this.handleOpenWorkflow, name: "Ver proceso" }
      ]} />
    )
    // return filtered
    //   .map(wf => {
    //     const allWorkflowsSettings = settings.workflows.all || []
    //     const selectedWorkflow = allWorkflowsSettings.find(swf => swf.id === wf.workflow_base_id)
    //     return (
    //       <Paper className={classes.workflow} onClick={this.handleOpenWorkflow(wf)}>
    //         <div>
    //           <Typography variant="h4">{wf.name}</Typography>
    //           <Typography variant="subtitle1">{selectedWorkflow?.name}</Typography>
    //           <Typography variant="caption">{`Fecha de creación: ${wf.date_created}`}</Typography>
    //         </div>
    //         <KeyboardArrowRight />
    //       </Paper>
    //     )
    //   })
  }

  renderForm() {
    const { settings, classes } = this.props
    const { params, tab, name } = this.state
    const allWorkflowsSettings = settings?.workflows?.all || []
    const selectedTypeWorkflows = allWorkflowsSettings.filter(wf => wf.workflow_type_id === tab)
    return (
      <div className={classes.form}>
        <TextInput value={params.name} name="name" onChange={this.handleChange} label={`Nombre del ${name.replace(/.$/, "")}`} />
        <SelectInput options={transformToOptions(selectedTypeWorkflows)} value={params.workflow_base_id} name="workflow_base_id" onChange={this.handleChange} label={`Seleccione base del ${name.replace(/.$/, "")}`} />
        <SubmitButton onClick={this.handleCreate} disabled={!params.workflow_base_id}>
          Crear
        </SubmitButton>
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { openCreate, tab, name, option, loading } = this.state
    console.log(option)
    if (loading) return <LoaderAnimator />
    return (
      <div className={classes.container}>
        <div className={classes.tabs}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab value={1} label={<Typography variant="h2">Accidentes</Typography>} />
            <Tab value={2} label={<Typography variant="h2">Incidentes</Typography>} />
            <Tab value={3} label={<Typography variant="h2">Reclamos</Typography>} />
            <Tab value={5} label={<Typography variant="h2">Generales</Typography>} />
          </MyTabs>
        </div>
        <div className={classes.title}>
          <Typography variant="h1">{name}</Typography>
          <AddElementButton name={`Iniciar ${name.replace(/.$/, "")}`} open={openCreate} onClick={this.handleOpenCreate} />
        </div>
        <Collapse in={openCreate}>
          <Paper>
            {this.renderForm()}
          </Paper>
        </Collapse>
        <div style={{ marginBottom: 24 }}>
          <MyTabs value={option} onChange={this.handlechangeOption}>
            <Tab value={1} label={<Typography variant="h2">En curso</Typography>} />
            <Tab value={2} label={<Typography variant="h2">Cerrados</Typography>} />
          </MyTabs>
        </div>

        {option === 1 && this.renderCoursingWorkflows()}
        {option === 2 && this.renderFinishedWorkflows()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workflows: state.workflows,
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getSettingsWorkflows: () => dispatch(getSettingsWorkflowsAction()),
  getWorkflows: () => dispatch(getWorkflowsAction()),
  createWorkflow: body => dispatch(createWorkflowAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Workflows))