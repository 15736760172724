import React, { Component } from 'react'
import { Button, Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import { connect } from 'react-redux'
import { filterActiveWorkers } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'

const style = () => ({
  container: {
    padding: 24
  }
})

class EditAuthsAndResponsible extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      responsible_ids: [],
      auth_ids: []
    }
    autobind(EditAuthsAndResponsible, this)
  }

  getUsersNames(ids) {
    if (ids.length === 0) return (
      <div>
        <Typography variant="subtitle1">Sin asignar</Typography>
      </div>
    )
    const { workers } = this.props
    const allWorkers = workers.all || []
    return ids.map(id => {
      return (
        <div>
          <Typography variant="subtitle1">* {allWorkers.find(w => w.id === id)?.name || null}</Typography>
        </div>
      )
    })
  }

  handleOpen(e) {
    e.stopPropagation()
    const { step } = this.props
    this.setState({ open: !this.state.open, responsible_ids: step?.data?.responsible_ids, auth_ids: step?.data?.auth_ids })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleSave() {
    const { auth_ids, responsible_ids } = this.state
    const { onSave } = this.props
    onSave({ responsible_ids, auth_ids })
    this.setState({ open: false })
  }

  render() {
    const { classes, disabled } = this.props
    const { open, auth_ids, responsible_ids } = this.state
    return (
      <>
        <IconButton size="small" style={{ marginLeft: 6 }} disabled={disabled} onClick={this.handleOpen}>
          <Edit style={{ height: 18, width: 18 }} />
        </IconButton>
        <Dialog open={open} onClose={this.handleOpen} fullWidth maxWidth="sm">
          <div className={classes.container}>
            <Typography variant="h2">Editar responsable y notificados</Typography>
            <MultiSelectInput label="Responsables" onChange={this.handleChange} name="responsible_ids" value={responsible_ids} options={transformToOptions(filterActiveWorkers())} />
            {this.getUsersNames(responsible_ids || [])}
            <Divider style={{ margin: '12px 0' }} />
            <MultiSelectInput label="Notificados" onChange={this.handleChange} name="auth_ids" value={auth_ids} options={transformToOptions(filterActiveWorkers())} />
            {this.getUsersNames(auth_ids || [])}
            <div style={{ textAlign: 'end' }}>
              <Button color="primary" variant="contained" onClick={this.handleSave}>
                Guardar
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers
})

export default connect(mapStateToProps)(withStyles(style)(EditAuthsAndResponsible))