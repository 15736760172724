import { Box, Dialog, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getBranchTags } from '../../API/branches'
import NewMultiSelectInput from '../../Shared/Inputs/NewMultiSelectInput'
import { transformToOptions } from '../../Utils/functions'

const css = {
	dialog: {
		padding: 3
	}
}

function AssignBranchTagDialog({ value, branch, open, onClose, onSubmit }) {

	const [tags, setTags] = useState([])
	const [selectedTags, setSelected] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getBranchTags()
			setTags(response.data.info)
		}

		if (open) { fetchData() }
	}, [open])

	useEffect(() => {
		const newTags = branch?.tags?.map(t => t.id) || []
		setSelected(newTags)
	}, [branch])

	function onSave() {
		const body = { ...branch, tags: selectedTags }
		onSubmit(body)
		onClose()
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={css.dialog}>
				<Typography style={{ marginBottom: 12 }} variant='h4'>Asignar grupos para {branch?.name}</Typography>
				<NewMultiSelectInput onBlur={onSave} onChange={e => setSelected(e.target.value)} value={selectedTags} options={transformToOptions(tags)} label="Seleccione grupos a asociar" />
			</Box>
		</Dialog>
	)
}

export default AssignBranchTagDialog