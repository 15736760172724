import React, { Component } from "react"
import {
    Paper,
    withStyles,
    Typography,
    Divider,
    Tooltip,
    IconButton,
} from "@material-ui/core"
import { getTableCellValue } from "../Utils/functions"

const style = () => ({
    container: {
        padding: 12,
        marginBottom: 12,
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "2px 0",
    },
    actions: {
        textAlign: "end",
    },
})

class RowCard extends Component {
    renderActions() {
        const { actions, row } = this.props
        return actions.map((action, index) => {
            if (!!action.component) {
                return (
                    <Tooltip title={action.name} key={index.toString()} >
                        <action.component onClick={action.action} params={row} disabled={action.disabled ? action.disabled(row) : false} />
                    </Tooltip>
                )
            }
            return (
                <Tooltip title={action.name} key={index}>
                    <IconButton onClick={action.action(row)}>
                        <action.icon />
                    </IconButton>
                </Tooltip>
            )
        })
    }

    renderInfo() {
        const { row, info, classes } = this.props
        return info.map((header, index) => {

            let headerElement = null
            if (typeof header.name !== "string") {
                headerElement = (
                    <header.name />
                )
            } else {
                headerElement = <Typography variant="subtitle1">{header.name}:</Typography>
            }



            let value = getTableCellValue(row, header)
            if (header.format) {
                value = header.format(value, row)
            }
            const rendered = header.render ? (
                <header.render element={row} header={header} value={value} />
            ) : (
                false
            )
            return (
                <div key={index} className={classes.inline}>
                    {headerElement}
                    {rendered !== false ? (
                        rendered
                    ) : (
                        <Typography variant="subtitle1">{value}</Typography>
                    )}
                </div>
            )
        })
    }
    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.container}>
                {this.renderInfo()}
                <Divider style={{ margin: "6px 0" }} />
                <div className={classes.actions}>{this.renderActions()}</div>
            </Paper>
        )
    }
}

export default withStyles(style)(RowCard)
