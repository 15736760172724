import React, { Component } from "react"
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    withStyles,
} from "@material-ui/core"
import autobind from "../../Utils/autobind"
import { connect } from "react-redux"
import AddChecklistCard from "./AddChecklistCard"
import moment from "moment"

const style = () => ({
    container: {
        margin: "0 0 24px 0",
    },
    details: {
        display: "flex",
        gap: "12px",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 0,
            flexBasis: 350,
            "@media (max-width:500px)": {
                flexGrow: 1,
            },
        },
    },
})

class AddChecklist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: -1,
        }
        autobind(AddChecklist, this)
    }

    handleSelectAcc(value) {
        return () => {
            const { open } = this.state
            if (open === value) {
                this.setState({ open: -1 })
            } else {
                this.setState({ open: value })
            }
        }
    }

    renderActivities() {
        const { open } = this.state
        const { classes, activities } = this.props
        const allActivities = activities.all || []
        const activeActivities = allActivities.filter(activity => !moment(activity.end_date).isBefore(moment(new Date())))
        return activeActivities.map((activity, index) => {
            return (
                <Accordion
                    expanded={open === index}
                    onChange={this.handleSelectAcc(index)}
                    square
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <Typography variant="h1" color="primary">{activity.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        {activity.preventive_measures.map((measure, index) => <AddChecklistCard key={index} preventiveMeasure={measure} activity={activity} />)}
                    </AccordionDetails>
                </Accordion>
            )
        })
    }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>{this.renderActivities()}
                <input id="hiddenInput" style={{ position: 'absolute', opacity: '0' }} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    activities: state.activities
})

export default connect(mapStateToProps)(withStyles(style)(AddChecklist))
