import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = () => ({
  answer: {
    background: 'lightgrey',
    borderRadius: 6,
    padding: '6px 12px',
    margin: 6,
    width: 170
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

function getTotalCounters(checklist) {
  function getFactor(item) {
    const crit = item?.answer?.criticality
    if (crit === 4) return 2
    if (crit === 3) return 1.5
    return 1
  }
  const items = checklist.checklist_items
  const filtered = items
    .filter(item => item.counter === 1 && item.answer.counter !== null)
  if (filtered.length === 0) return 0
  const total = filtered
    .reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
  return total.answer.counter
}

class IDSField extends Component {
  renderAnswers() {
    const { field, data, classes } = this.props
    const answers = []
    data.forEach(checklist => {
      const answer = checklist?.informacion_extra?.find(extra => extra.label === field?.label)
      if (!answer || !answer.value) return null
      answers.push({ value: answer.value, counter: getTotalCounters(checklist) })
    })
    return answers.map(ids => {
      return (
        <div className={classes.answer}>
          <Typography variant="subtitle1">Eventos Ponderados: {ids.counter}</Typography>
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>Resultado: {ids?.value ? ((ids.counter * 100) / ids?.value).toFixed(2) : 0}</Typography>
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderAnswers()}
      </div>
    )
  }
}

export default withStyles(style)(IDSField)