import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import Selector from './Selector'
import moment from 'moment'
import DaysContainer from './DaysContainer'
import CalendarContext from './CalendarContext'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'
import MobileDays from './MobileDays'

const style = () => ({
  container: {
    padding: 12,
    background: 'white',
    borderRadius: 8,
    position: 'relative'
  },
  header: {
    textAlign: 'center',
    margin: '24px 0',
    '& > *': {
      fontSize: 16
    }
  }
})

class MyCalendar extends Component {
  constructor() {
    super()
    this.state = {
      date: moment(),
      today: moment(),
      events: [],
      loading: false,
    }
    autobind(MyCalendar, this)
  }
  async componentDidMount() {
    const { load, setDate } = this.props
    const { date } = this.state
    const dates = {
      date_start: date.clone().startOf("month").format("YYYY-MM-DD"),
      date_end: date.clone().endOf("month").format("YYYY-MM-DD")
    }
    this.setState({ loading: true })
    setDate(dates)
    await load(dates)
    this.setState({ loading: false })
  }

  handleChangeMonth(option) {
    return () => {
      const { date } = this.state
      if (option === "next") { date.add(1, 'month') }
      else if (option === "prev") { date.subtract(1, 'month') }
      else return this.setState({ date: moment() }, this.componentDidMount)
      this.setState({ date }, this.componentDidMount)
    }
  }

  render() {
    const { classes, event, tooltip, onDayClick, onDragDrop, onDeleteClick, onEventClick, events = [], loading: outterLoad } = this.props
    const { date, loading } = this.state
    const actualMonth = moment.months()[date.month()]
    const isMobile = window.innerWidth <= 700
    return (
      <div className={classes.container}>
        <CalendarContext.Provider value={{
          ...this.state,
          eventRender: event,
          events,
          tooltip,
          onDayClick,
          onDragDrop,
          onDeleteClick,
          onEventClick
        }}>
          {(loading || outterLoad) &&
            <div style={{ position: 'absolute', right: 6, top: 6 }}>
              <MiniLoaderAnimator />
            </div>
          }
          <Selector handleChange={this.handleChangeMonth} />
          <div className={classes.header}>
            <Typography variant='h4'>{`${actualMonth} de ${date.year()}`}</Typography>
          </div>
          {isMobile ?
            <MobileDays />
            :
            <DaysContainer />
          }
        </CalendarContext.Provider>
      </div>
    )
  }
}

export default withStyles(style)(MyCalendar)