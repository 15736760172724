import { Typography } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, IconButton } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import AppContext from '../../../AppContext'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'

const css = {
	container: {
		background: 'whitesmoke',
		borderRadius: 2,
		margin: '12px 0',
		padding: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function BaseEvaluation({ evaluation, onEdit }) {

	const [name, setName] = useState(evaluation.name)
	const [approval, setApproval] = useState(evaluation.approval)
	const { history } = useContext(AppContext)

	function onBlur() {
		const body = { ...evaluation, name, approval }
		onEdit(body)
	}

	return (
		<Box sx={css.container}>
			<Box>
				<SeamlessInput placeholder="Ingrese nombre evaluación..." variant="h4" color="transparent" value={name} onChange={e => setName(e.target.value)} onBlur={onBlur} />
				<Box style={{ display: 'flex', alignItems: 'center', paddingLeft: 6 }}>
					<Typography variant='subtitle1'>Porcentaje de aprobación (%):</Typography>
					<SeamlessInput color="transparent" value={approval} onChange={e => setApproval(e.target.value)} onBlur={onBlur} />
				</Box>
				<Typography variant="caption" style={{ paddingLeft: 6 }} >Creada: {moment(evaluation.created_at).format("YYYY-MM-DD HH:mm")}</Typography>
			</Box>
			<IconButton size="large" onClick={() => history.push(`/settings/evaluations/${evaluation.id}`)}>
				<KeyboardArrowRight />
			</IconButton>
		</Box >
	)
}

export default BaseEvaluation