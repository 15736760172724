import { KeyboardArrowRight } from '@material-ui/icons'
import { Box } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getParticipations } from '../../API/users'
import GeneralTable from '../../Shared/GeneralTable'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import Chip from '../Checklists/Chip'
import NumberRender from '../Checklists/NumberRender'
import DateIntervalPicker from './WorkerReports/DateIntervalPicker'

export const tableInfo = [
	{ label: "status", name: "Estado", render: Chip },
	{ label: 'date_scheduled', name: "Fecha de Programación" },
	{ label: 'date_done', name: "Fecha de Realización" },
	{ label: "preventive_measure", name: "Registro o formulario" },
	{ label: "compliance", name: "Cumplimiento", render: NumberRender },
]

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		position: 'relative'
	}
}


function WorkerRegisters({ id, history }) {

	const [checklists, setChecklists] = useState([])
	const [loading, setLoading] = useState(false)
	const [dates, setDates] = useState({ date_start: moment().startOf("week").format("YYYY-MM-DD"), date_end: moment().endOf("week").format("YYYY-MM-DD") })

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			const response = await getParticipations({ id, ...dates })
			setChecklists(response.data.info)
			setLoading(false)
		}

		fetchData()

	}, [dates])

	function onChange(event) {
		const { target } = event
		const newDates = { ...dates }
		newDates[target.name] = target.value
		setDates(newDates)
	}

	const actions = [{ name: "Ver registro", icon: KeyboardArrowRight, action: (value) => () => history.push(`/checklists/${value.id}`) }]

	return (
		<Box sx={css.container}>
			<DateIntervalPicker onChange={onChange} dates={dates} />
			{loading ?
				<LoaderAnimator loading={loading} />
				:
				<GeneralTable
					maxHeight={500}
					info={tableInfo}
					data={checklists}
					actions={actions}
					scrollable
				/>
			}
		</Box>
	)
}

export default withRouter(WorkerRegisters)

