import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'

const style = () => ({
  container: {
    padding: 24
  },
  tag: {
    padding: 12,
    borderRadius: 8,
    margin: '8px 0',
    '& > *': {
      color: 'white',
      fontWeight: 600
    }
  }
})

const tags = [
  { id: 1, name: "Primavera", color: "red" },
  { id: 2, name: "Solo admins", color: "blue" },
  { id: 3, name: "Semanales", color: "green" }
]

class TagsFilterDialog extends Component {

  render() {
    const { classes, open, onClose } = this.props
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant='h2'>Etiquetas de registro</Typography>
          {tags.map(tag => {
            return (
              <div className={classes.tag} style={{ background: tag.color }}>
                <Typography variant='subtitle1'>{tag.name}</Typography>
              </div>
            )
          })}
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(TagsFilterDialog)