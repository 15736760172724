import React, { Component } from 'react'
import { Dialog, Paper, Typography, withStyles } from '@material-ui/core'
import { AccountTreeOutlined, AlarmOn, AssignmentIndOutlined, AssignmentTurnedInOutlined, FeedbackOutlined, FindInPageOutlined, Settings, SupervisorAccountOutlined } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import "./animation.css"
import { connect } from 'react-redux'

const style = theme => ({
  container: {

  },
  shortcut: {
    minWidth: 130,
    height: 100,
    margin: 12,
    padding: 12,
    boxSizing: "border-box",
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    transition: "all 0.2s ease-in-out",
    background: theme.palette.blue.transparent,
    "&:hover": {
      transform: "scale(1.05)"
    },
    '&:last-child': {
      marginRight: 0
    },
    '& *': {
      color: theme.palette.blue.dark
    },

  },
  shortcuts: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: "100%",
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    padding: 12,
  },
  icon: {
    position: "absolute",
    top: "62%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 50,
    width: 50,
    color: theme.palette.blue.dark
  },
  shortcutContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 348
  },
  title: {
    background: theme.palette.primary.main,
    color: "white",
    padding: 12
  }
})

class Shortcuts extends Component {
  constructor() {
    super()
    this.state = {
      openDialog: false
    }
    autobind(Shortcuts, this)
  }

  componentDidMount() {
    const element = document.getElementById("slide-container")
    element.style.overflow = "hidden"
    setTimeout(() => {
      element.style.overflow = "auto"
    }, 1000)
  }

  handleOpenSettings() {
    this.setState({ openDialog: !this.state.openDialog })
  }

  handleClick(shortcut) {
    return () => {
      if (shortcut.link) {
        const { history } = this.props
        history.push(shortcut.link)
      } else {
        shortcut.action()
      }
    }
  }

  renderShortcuts() {
    const { classes, enterprise } = this.props
    const selectedId = enterprise?.selected?.id
    const shortcuts = [
      {
        name: "Registros y formularios",
        icon: AssignmentTurnedInOutlined,
        link: "/checklists"
      },
      {
        name: "Hallazgos",
        icon: FindInPageOutlined,
        link: "/findings"
      },
      {
        name: selectedId === 14 ? "IPAs" : "No Conformidades",
        icon: FeedbackOutlined,
        link: "/unconformities"
      },
      {
        name: "Charlas",
        icon: AssignmentIndOutlined,
        link: "/talks"
      },
      {
        name: "Capacitaciones",
        icon: SupervisorAccountOutlined,
        link: "/trainings"
      },
      {
        name: "Configuración",
        icon: Settings,
        action: this.handleOpenSettings,
        level: 1
      },
      {
        name: "Accidentes",
        icon: AccountTreeOutlined,
        link: "/workflows"
      },
      {
        name: "Incidentes",
        icon: AccountTreeOutlined,
        link: "/workflows?tab=2"
      },
      {
        name: "Reclamos",
        icon: AccountTreeOutlined,
        link: "/workflows?tab=3"
      },
    ]

    return shortcuts.map(shortcut => {
      const { level } = this.props
      if (!shortcut.level || shortcut.level === level) {
        return (
          <Paper className={`${classes.shortcut}`} key={shortcut.name} onClick={this.handleClick(shortcut)}>
            <Typography variant="caption">{shortcut.name}</Typography>
            <shortcut.icon className={classes.icon} />
          </Paper >
        )
      }
      return null
    })
  }

  renderSettingsShorctuts() {
    const { classes } = this.props
    const shortcuts = [
      {
        name: "Registros y formularios",
        icon: AssignmentTurnedInOutlined,
        link: "/settings/checklists"
      },
      {
        name: "Actividades",
        icon: AlarmOn,
        link: "/settings/findings"
      },
      {
        name: "Charlas",
        icon: AssignmentIndOutlined,
        link: "/settings/talks"
      },
      {
        name: "Capacitaciones",
        icon: SupervisorAccountOutlined,
        link: "/setting/trainings",
      },
    ]
    return shortcuts.map(shortcut => {
      return (
        <Paper className={`${classes.shortcut}`} key={shortcut.name} onClick={this.handleClick(shortcut)}>
          <Typography variant="subtitle1">{shortcut.name}</Typography>
          <shortcut.icon className={classes.icon} />
        </Paper >
      )
    })
  }

  render() {
    const { classes } = this.props
    const { openDialog } = this.state
    return (
      <div className={classes.container}>
        <div className={`${classes.shortcuts} slide-container`} id="slide-container">
          {this.renderShortcuts()}
        </div>
        <Dialog open={openDialog} onClose={this.handleOpenSettings}>
          <Typography variant="h2" className={classes.title}>Configuración</Typography>
          <div className={classes.shortcutContainer}>
            {this.renderSettingsShorctuts()}
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  enterprise: state.enterprise
})

export default connect(mapStateToProps)(withStyles(style)(Shortcuts))