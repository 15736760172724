import { transformOfflineRequestToOnline } from "../Utils/functions"
import instance2 from "../Utils/instance2"

export const cloneChecklistCategory = (body, offline) => {
	const request = {
		method: 'post',
		url: 'checklists/category/clone',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const deleteChecklistCategory = (body, offline) => {
	const request = {
		method: 'delete',
		url: 'checklists/category',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const answerChecklistField = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/field',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const answerChecklistItem = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/answer',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const changeMetadata = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/metadata',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const answerAllCategory = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/category/answer',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const addParticipants = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/add_participants',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const signChecklistGroup = (body, offline) => {
	const request = {
		method: 'post',
		url: 'checklists/sign_group',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const editChecklist = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const finishChecklist = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/finish',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const createItemFinding = (body, offline) => {
	const request = {
		method: 'post',
		url: 'checklists/item/finding',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const assignFindingToItem = (body, offline) => {
	const request = {
		method: 'put',
		url: 'checklists/item/finding',
		data: body
	}
	if (offline) return request
	return instance2(request)
}

export const createFindingFromChecklistNew = (body, offline) => {
	const request = {
		method: 'post',
		url: 'checklists/create_finding',
		data: body
	}
	if (offline) return request
	return instance2(request)
}