import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { createChecklist, deleteChecklist, editChecklist, getCalendar, getChecklist } from '../../API/checklists'
import MyCalendar from '../MyCalendar/MyCalendar'
import autobind, { addToggle } from '../../Utils/autobind'
import NewChecklist from './Checklist/NewChecklist'
import { withRouter } from 'react-router-dom'
import DeleteChecklist from './Checklist/DeleteChecklist'
import { getWorkers } from '../../API/users'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../Utils/functions'
import { filterActiveWorkersList } from '../../Utils/filters'

const style = () => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'white',
    fontWeight: 600,
  },
  input: {
    background: 'white',
    padding: '1px 10px',
    borderRadius: 8,
    marginBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 900 + 12 * 2,
    gap: 12,
    '& > *': {
      flexBasis: 300,
      flexGrow: 1
    }
  }
})

const statusOptions = [
  { label: "Todos", value: "" },
  { label: "Terminadas", color: 'green', value: "Terminado" },
  { label: "Vencidas", color: 'red', value: "Vencido" },
  { label: "Programadas", color: 'blue', value: "Programado" },
]

class ChecklistCalendar extends Component {
  constructor() {
    super()
    this.state = {
      selectedEvent: null,
      selectedDate: "",
      events: [],
      dates: {},
      loading: false,
      workers: [],
      user_id: "",
      preventive_measure_id: "",
      status_filter: ""
    }
    addToggle(ChecklistCalendar, this, "create")
    addToggle(ChecklistCalendar, this, "delete")
    autobind(ChecklistCalendar, this)
  }

  async componentDidMount() {
    const workersResponse = await getWorkers()
    const { data: { info: workers } } = workersResponse
    this.setState({ workers })
    window.addEventListener("branch_change", this.handleReload)
  }

  componentWillUnmount() {
    window.removeEventListener("branch_change", this.handleReload)
  }

  async handleGetChecklistCalendarInfo(dates) {
    const pendings = await getCalendar({ ...dates })

    const events = pendings.data.info.filter(x => x.calendar === 0).map(checklist => {
      const newChecklist = { ...checklist }
      const backgroundColor = checklist.status === "Vencido" ? "#ff5b5b" : checklist.status === "Terminado" ? "#0abb87" : "#5867dd"
      newChecklist.background = backgroundColor
      newChecklist.editable = checklist.status === "Programado" ? true : false
      return newChecklist
    })
    this.setState({ events })
  }

  async handleReload() {
    const { dates } = this.state
    this.setState({ loading: true })
    const pendings = await getCalendar({ ...dates })

    const events = pendings.data.info.filter(x => x.calendar === 0).map(checklist => {
      const newChecklist = { ...checklist }
      const backgroundColor = checklist.status === "Vencido" ? "#ff5b5b" : checklist.status === "Terminado" ? "#0abb87" : "#5867dd"
      newChecklist.background = backgroundColor
      newChecklist.editable = checklist.status === "Programado" ? true : false
      return newChecklist
    })
    this.setState({ events: events, loading: false })
  }

  filter(events) {
    const { user_id, preventive_measure_id, status_filter } = this.state
    return events
      .filter(event => user_id === "" || event.user_id === user_id)
      .filter(event => preventive_measure_id === "" || event.preventive_measure_id === preventive_measure_id)
      .filter(event => status_filter === "" || event.status === status_filter)
  }

  handleChange(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleSetDate(dates) {
    this.setState({ dates })
  }

  handleRenderChecklistEvent(event) {
    const { classes } = this.props
    return (
      <div className={classes.text}>
        <Typography variant='body1' className={classes.text}>{event.preventive_measure}</Typography>
      </div>
    )
  }

  handleRenderChecklistTooltip(event) {
    return (
      <div>
        <Typography variant="subtitle1" style={{ color: 'white', fontWeight: 600 }}>{event.preventive_measure}</Typography>
        <Typography variant="subtitle2" style={{ color: 'white' }}>Actividad: {event.activity}</Typography>
        <Typography variant="subtitle2" style={{ color: 'white' }}>Responsable: {event.user}</Typography>
      </div>
    )
  }

  handleClickDay(date) {
    this.setState({ selectedDate: date.format("YYYY-MM-DD") })
    this.handleOpenCreate()
  }

  handleClickDelete(event) {
    this.setState({ selectedEvent: event })
    this.handleOpenDelete()
  }

  handleClickEvent(event) {
    const { history } = this.props
    history.push(`/checklists/${event.id}`)
  }

  async handleDrop(info) {
    const { events } = this.state
    const { event, date } = info
    // Edit checklist date
    const body = {
      ...event,
      date_scheduled: date
    }
    const selectedEvent = events.find(ev => ev.id === event.id)
    selectedEvent.date_scheduled = date
    await editChecklist(body)
    this.handleReload()
  }

  handleDelete(event) {
    return async () => {
      const { events } = this.state
      const selectedEventIndex = events.findIndex(ev => ev.id === event.id)
      events.splice(selectedEventIndex, 1)
      const body = { id: event.id }
      await deleteChecklist(body)
      this.handleReload()
    }
  }

  async handleCreate(body) {
    const { events } = this.state
    const event = {
      ...body,
      activity: "Creando...",
      id: "newevent"
    }
    events.push(event)
    await createChecklist(event)
    this.handleReload()
  }




  render() {
    const { classes } = this.props
    const { openCreate, openDelete, selectedEvent, events, loading, selectedDate, user_id, workers, preventive_measure_id, status_filter } = this.state
    const usersInEvents = events.map(ev => ev.user_id)
    const activeWorkers = filterActiveWorkersList(workers).filter(worker => usersInEvents.includes(worker.id))
    const preventive_measures_ids = new Set(events.map(ev => ev.preventive_measure_id))
    const preventive_measures = []
    preventive_measures_ids.forEach(id => {
      const event = events.find(ev => ev.preventive_measure_id === id)
      preventive_measures.push({ id, name: event.preventive_measure })
    })
    activeWorkers.push({ id: "", name: "Todos" })
    preventive_measures.push({ id: "", name: "Todos" })
    return (
      <>
        <div className={classes.input}>
          <SelectInput
            value={user_id}
            options={transformToOptions(activeWorkers)}
            label="Responsable"
            name="user_id"
            onChange={this.handleChange}
          />
          <SelectInput
            value={preventive_measure_id}
            options={transformToOptions(preventive_measures)}
            label="Registro o Formulario"
            name="preventive_measure_id"
            onChange={this.handleChange}
          />
          <SelectInput
            value={status_filter}
            options={statusOptions}
            label="Estado"
            name="status_filter"
            onChange={this.handleChange}
          />
        </div>
        <MyCalendar
          key="checklists"
          load={this.handleGetChecklistCalendarInfo}
          events={this.filter(events)}
          loading={loading}
          setDate={this.handleSetDate}
          event={this.handleRenderChecklistEvent}
          tooltip={this.handleRenderChecklistTooltip}
          onDayClick={this.handleClickDay}
          onDragDrop={this.handleDrop}
          onDeleteClick={this.handleClickDelete}
          onEventClick={this.handleClickEvent}
        />
        <NewChecklist open={openCreate} onClose={this.handleOpenCreate} date={selectedDate} onSubmit={this.handleCreate} />
        <DeleteChecklist open={openDelete} onClose={this.handleOpenDelete} event={selectedEvent} onSubmit={this.handleDelete} />
      </>

    )
  }
}

export default withRouter(withStyles(style)(ChecklistCalendar))