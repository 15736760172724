import { Divider, MenuItem } from '@material-ui/core'
import { Add, AddCircleOutline, Delete, KeyboardArrowDown, KeyboardArrowUp, Report, ReportOutlined, Settings } from '@material-ui/icons'
import { Box, Button, Checkbox, Collapse, IconButton, Menu, Paper, Switch, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import useToggle from '../../../../Hooks/ToogleHook'
import newTheme from '../../../../newTheme'
import DeleteButton from '../../../../Shared/Buttons/DeleteButton'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import SwitchInput from '../../../../Shared/Inputs/SwitchInput'
import TextInput from '../../../../Shared/Inputs/TextInput'
import { SetupChecklistContext } from './ChecklistSetup'

const css = {
	field: {
		background: newTheme.palette.blue.transparent,
		borderRadius: 2,
		margin: '6px 0',
		transition: 'all 0.2s linear'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	collapse: {
		margin: 2,
		padding: 2,
		background: 'white',
		borderRadius: 1
	},
	switch: {
		display: 'flex',
		alignItems: 'center'
	},
	input: {
		padding: 2,
		'& > div': {
			margin: "0 !important"
		}
	},
	option: {
		margin: '6px 0'
	},
	inline: {
		display: 'flex',
		alignItems: 'center',
	},
	tooltip: {
		padding: 2,
		'& > *': {
			color: 'white'
		}
	},
	colorSelector: {
		height: 14,
		width: 14,
		borderRadius: 1,
		border: '2px solid grey',
		cursor: 'pointer',
		margin: '0 10px'
	},
	category: {
		marginTop: 3
	}
}

const fields = [
	{ label: "Texto", value: "text" },
	{ label: "RUT", value: "rut" },
	{ label: "Texto enriquecido", value: "rte" },
	{ label: "Lista con opciones", value: "select" },
	{ label: "Selección Múltiple", value: "multiselect" },
	{ label: "Imagen", value: "img" },
	{ label: "Cumplimiento", value: "compliance" },
	{ label: "IDS (Experimental)", value: "ids" }
]

const shadow = "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)"

function ColorSelector({ onSelect, selected }) {
	const [openMenu, toggleMenu] = useToggle(false)
	const menu = useRef(null)

	function selectColor(color) { toggleMenu(); onSelect(color) }

	return (
		<Box>
			<Box style={{ background: selected }} sx={css.colorSelector} ref={menu} onClick={toggleMenu}>

			</Box>
			<Menu anchorEl={menu.current} open={openMenu} onClose={toggleMenu}>
				<MenuItem onClick={() => selectColor("green")} ><Typography style={{ width: 100, color: "green" }} variant='subtitle1'>Verde</Typography></MenuItem>
				<MenuItem onClick={() => selectColor("gold")} ><Typography style={{ width: 100, color: "gold" }} variant='subtitle1'>Amarillo</Typography></MenuItem>
				<MenuItem onClick={() => selectColor("red")} ><Typography style={{ width: 100, color: "red" }} variant='subtitle1'>Rojo</Typography></MenuItem>
			</Menu>
		</Box>
	)
}

function Option({ field, option }) {
	const [config, toggleConfig] = useToggle(false)
	const [params, setParams] = useState({ ...option })
	const { editOption, createField, checklist, deleteOption } = useContext(SetupChecklistContext)

	function changeColor(color) {
		const body = { ...option, color }
		editOption(body)
	}

	function changeCut() {
		const body = { ...option, isCut: !option.isCut }
		editOption(body)
	}

	function onChange(e) {
		const { target } = e
		setParams(p => ({ ...p, [target.name]: target.value }))
	}

	function onBlur() {
		const body = { ...option, ...params }
		editOption(body)
	}

	function onDelete() {
		const body = { id: option.id, base_checklist_id: field.base_checklist_id }
		deleteOption(body)
	}

	const subquestions = option?.subquestions || []

	return (
		<Box sx={css.option}>
			<Box sx={css.header}>
				<Box sx={css.inline}>
					{field?.isCut &&
						<Tooltip title={
							<Box sx={css.tooltip}>
								<Typography variant='h4'>Establecer como opción de corte</Typography>
								<Typography variant='subtitle1' style={{ marginTop: 6 }}>Cuando cualquiera de las opciones de corte son seleccionadas como respuesta, entonces el registro público levantará una alerta al ser finalizado</Typography>
							</Box>
						}>
							<Checkbox
								checked={option.isCut}
								onClick={changeCut}
								size='small'
								color="secondary"
								checkedIcon={<Report style={{ height: 20, width: 20 }} />}
								icon={<ReportOutlined style={{ height: 20, width: 20 }} />}
							/>
						</Tooltip>
					}
					<SeamlessInput
						onBlur={onBlur}
						placeholder="Título de la opción..."
						name="label"
						padding={field?.isCut ? "6px" : "8px 12px"}
						color="transparent"
						value={params?.label}
						onChange={onChange}
					/>
				</Box>
				<Box>
					<DeleteButton onClick={onDelete} />
					<IconButton onClick={toggleConfig}>
						<KeyboardArrowDown />
					</IconButton>
				</Box>
			</Box>
			<Box sx={css.header}>
				<Box sx={css.inline}>
					<ColorSelector onSelect={changeColor} selected={option?.color} />
					<Typography variant='subtitle1'>Mensaje opcional al ser seleccionado:</Typography>
					<SeamlessInput
						onBlur={onBlur}
						placeholder="Sin mensaje..."
						name="message"
						onChange={onChange}
						color="transparent"
						fontColor={option?.color}
						value={params?.message}
					/>
				</Box>
			</Box>
			{config &&
				<Box>
					<Box sx={css.header}>
						<Typography variant='h4' style={{ padding: 12 }} >Preguntas relacionadas</Typography>
						<Button size="small" color="info" variant="outlined" onClick={() => createField({ base_checklist_id: checklist.id, base_field_option_id: option.id })}>
							Crear pregunta relacionada
						</Button>
					</Box>
					{!!subquestions.length ?
						subquestions.map(field => <Field field={field} />) :
						<Box style={{ padding: '0 12px' }}>
							<Typography variant='caption'>No hay preguntas relacionadas para esta opción...</Typography>
						</Box>
					}
				</Box>
			}
		</Box>
	)
}

function Field({ field }) {

	const [config, toggleConfig] = useToggle(false)
	const [params, setParams] = useState({ ...field })
	const options = field?.options || []
	const typeName = fields.find(f => f.value === field.type)?.label || "Sin seleccionar"
	const { editField, changeFieldCategory, createOption, deleteField } = useContext(SetupChecklistContext)

	function updateParams(e) {
		const { target } = e
		const body = { ...params }
		body[target.name] = target.value
		editField(body)
	}

	function onBlur() {
		const body = { ...params }
		editField(body)
	}

	function changeCategory(option) {
		const body = { ...field, option }
		changeFieldCategory(body)
	}

	function onCreate() {
		const body = { ...field }
		createOption(body)
	}

	function onDelete() {
		const body = { ...field }
		deleteField(body)
	}

	useEffect(() => {
		setParams({ ...field })
	}, [field])

	console.log(field)

	return (
		<Box sx={css.field} style={{ boxShadow: config ? shadow : "", margin: config ? "18px 0" : "6px 0" }} >
			<Box sx={css.header}>
				<Box sx={css.inline}>
					<Box style={{ padding: '6px 0' }} >
						<SeamlessInput
							placeholder="Ingrese título de la pregunta..."
							color="transparent"
							value={params?.label}
							name="label"
							onBlur={onBlur}
							onChange={e => setParams(p => ({ ...p, label: e.target.value }))}
							padding={"0 12px"}
						/>
						<Box style={{ padding: '0 0 0px 12px' }} >
							<Typography variant='caption'>{typeName}{params?.required ? "- Obligatoria" : ""}</Typography>
						</Box>
					</Box>
					{params?.isCut &&
						<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>{"Pregunta de corte"}</Typography>}>
							<ReportOutlined color="secondary" style={{ marginLeft: 6 }} />
						</Tooltip>
					}
				</Box>
				<Box style={{ paddingRight: 6 }}>
					{!field.base_field_option_id &&
						<>
							<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >Mover a la categoría de Abajo</Typography>}>
								<IconButton onClick={() => changeCategory("down")}>
									<KeyboardArrowDown />
								</IconButton>
							</Tooltip>
							<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >Mover a la categoría de Arriba</Typography>}>
								<IconButton onClick={() => changeCategory("up")}>
									<KeyboardArrowUp />
								</IconButton>
							</Tooltip>
						</>
					}
					<IconButton onClick={toggleConfig}>
						<Settings />
					</IconButton>
					<DeleteButton onClick={onDelete} />
				</Box>
			</Box>
			{config &&
				<Box style={{ padding: 1 }}>
					<Box sx={css.collapse}>
						<Typography variant='h4' style={{ marginBottom: 12 }} >Configuración</Typography>
						<Box sx={css.input}>
							<Typography variant='subtitle1'>Tipo de pregunta</Typography>
							<SelectInput name="field_type" onChange={updateParams} value={params.field_type} label="" options={fields} />
						</Box>
						{params.field_type === "compliance" &&
							<Box>
								<Box sx={css.input}>
									<Typography variant='subtitle1'>Titulo para caso desfavorable</Typography>
									<SeamlessInput
										label=""
										name="compliance_case"
										onBlur={onBlur}
										onChange={e => setParams(p => ({ ...p, compliance_case: e.target.value }))}
										value={params.compliance_case}
									/>
								</Box>
								<Box sx={css.input}>
									<Typography variant='subtitle1'>Titulo de comentario de incumplimiento</Typography>
									<SeamlessInput
										label=""
										name="compliance_observation"
										onBlur={onBlur}
										onChange={e => setParams(p => ({ ...p, compliance_observation: e.target.value }))}
										value={params.compliance_observation}
									/>
								</Box>
							</Box>
						}
						<Box sx={css.switch}>
							<SwitchInput name="required" onChange={updateParams} value={params.required} />
							<Typography variant='subtitle1'>Pregunta Obligatoria</Typography>
						</Box>
						<Box sx={css.switch}>
							<SwitchInput name="isCut" onChange={updateParams} value={params.isCut} />
							<Typography variant='subtitle1'>Pregunta de Corte</Typography>
						</Box>
					</Box>
					{["select", "multiselect"].includes(params.field_type) &&
						<Box sx={css.collapse}>
							<Box sx={css.header}>
								<Typography variant='h4'>Opciones</Typography>
								<IconButton color="info" onClick={onCreate}>
									<AddCircleOutline />
								</IconButton>
							</Box>
							{options.map((option, index) =>
								<>
									{index > 0 && <Divider />}
									<Option option={option} field={field} />
								</>
							)}
						</Box>
					}
				</Box>
			}
		</Box>
	)
}

function FieldCategory({ category, fields }) {

	const [name, setName] = useState(category.name)
	const color = category.color.slice(0, 7) + "88"
	const { editCategory, checklist, createField } = useContext(SetupChecklistContext)

	function onBlur() {
		if (name === category.name) return false
		const body = { ...category, name }
		editCategory(body)
	}

	return (
		<Box sx={css.category}>
			<Box sx={css.header}>
				<SeamlessInput
					value={name}
					onBlur={onBlur}
					onChange={e => setName(e.target.value)}
					variant="h4"
					fontColor={color}
					color="transparent"
					padding={"0"}
				/>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >Agregar pregunta en esta categoría</Typography>}>
					<IconButton color="info" onClick={() => createField({ base_checklist_id: checklist.id, base_field_category_id: category.id })}>
						<AddCircleOutline />
					</IconButton>
				</Tooltip>
			</Box>
			{!!fields.length ? fields.map(field => <Field field={field} key={field.id} />) : <Typography variant='caption'>No hay elementos en esta categoría...</Typography>}
		</Box>
	)
}

function ExtraFieldsSetup({ value }) {

	const { checklist, createField } = useContext(SetupChecklistContext)
	const fields = checklist.real_extra_fields || []
	const categories = checklist.fields_categories || []

	function renderCategories() {
		return categories.map(category => {
			const category_fields = fields.filter(f => f.base_field_category_id === category.id)
			return <FieldCategory key={category.id} category={category} fields={category_fields} />
		})
	}

	function renderWithoutCategory() {
		const category_fields = fields.filter(f => f.base_field_category_id === null)
		return (
			<Box sx={css.category} >
				<Box sx={css.header}>
					<Typography style={{ padding: "0px" }} variant='h4'>Sin categoría</Typography>
					<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >Agregar pregunta en esta categoría</Typography>}>
						<IconButton color="info" onClick={() => createField({ base_checklist_id: checklist.id })}>
							<AddCircleOutline />
						</IconButton>
					</Tooltip>
				</Box>
				{category_fields.map(field => <Field field={field} key={field.id} />)}
			</Box>
		)
	}

	return (
		<Box>
			{renderCategories()}
			{renderWithoutCategory()}
		</Box>
	)
}

export default ExtraFieldsSetup