import { ArrowForward, KeyboardArrowDown } from '@material-ui/icons'
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import useToggle from '../../../Hooks/ToogleHook'

const css = {
	container: {
		padding: '6px 12px',
		background: 'whitesmoke',
		borderRadius: 1,
		margin: '6px 0'
	},
	requirement: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr 50px',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	checklist: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	checklists: {
		background: 'white',
		borderRadius: 1,
		padding: 1
	}
}

function Checklist({ checklist, name }) {
	return (
		<Box sx={css.checklist}>
			<Typography variant='subtitle1'>{moment(checklist.date).format("DD [de] MMMM [del] YYYY")}</Typography>
			<Box>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}><strong>Ver registro</strong></Typography>}>
					<IconButton href={`/checklists/${checklist.id}`}>
						<ArrowForward />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	)
}

function Requirements({ requirements }) {

	const [open, toggleOpen] = useToggle(false)
	return (
		<Box>
			{requirements.map((req, index) => {
				const checklists = req.checklists || []
				return (
					<Box sx={css.container}>
						{index > 0 && <Divider />}
						<Box sx={css.requirement}>
							<Typography variant='subtitle1'><strong>{req.checklist}</strong></Typography>
							<Typography variant='subtitle1' style={{ textAlign: 'end' }} >Cantidad mínima: {req.amount}</Typography>
							<Typography variant='subtitle1' style={{ textAlign: 'end' }} >Realizadas: {req.compliance}</Typography>
							{!!checklists.length &&
								<Box style={{ textAlign: 'end' }}>
									<IconButton onClick={toggleOpen}>
										<KeyboardArrowDown />
									</IconButton>
								</Box>
							}
						</Box>
						{open &&
							<Box sx={css.checklists}>
								{checklists.map(checklist => <Checklist checklist={checklist} name={req.checklist} />)}
							</Box>
						}
					</Box>
				)
			})}
		</Box>
	)
}

export default Requirements