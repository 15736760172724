import React, { Component, Fragment } from "react"
import { Dialog, FormControl, InputLabel, MenuItem, Radio, Select, Typography, withStyles } from "@material-ui/core"
import autobind, { addToggle } from "../../Utils/autobind"
import TextInput from "./TextInput"
import { Box } from "@mui/material"

const style = ({
	dialog: {
		padding: 24,
		'& > *': {
			margin: '12px 0'
		}
	},
	option: {
		display: 'flex',
		alignItems: 'center'
	},
	options: {
		maxHeight: 300,
		overflowX: 'auto'
	}
})
class SelectInput extends Component {
	constructor() {
		super()
		this.state = {
			open: false,
			search: ""
		}
		addToggle(SelectInput, this, "dialog")
		autobind(SelectInput, this)
	}

	handleClick() {
		this.setState({ open: !this.state.open })
	}

	handleChange(event, value) {
		const { onChange, name } = this.props
		if (!value) return null
		const newEvent = {
			target: {
				name, value: value.value.toString()
			}
		}
		onChange(newEvent)
	}

	handleChangeLong(option) {
		return () => {
			const { name, onChange } = this.props
			const event = {
				target: {
					name,
					value: option.value
				}
			}
			onChange(event)
			this.handleOpenDialog()
		}
	}

	handleSearch(event) {
		const { target: { value } } = event
		this.setState({ search: value })
	}

	renderMenu() {
		const { options, big } = this.props
		const allOptions = options || []
		return allOptions.map((option) => {
			return (
				<MenuItem value={option.value} key={option.value} style={{ fontSize: big ? 15 : "unset" }}>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						{option.color &&
							<div style={{ height: 12, width: 12, background: option.color, borderRadius: '50%', marginRight: 12 }} />
						}
						<Typography variant='subtitle1'>{option.label}</Typography>
					</Box>
				</MenuItem>
			)
		})
	}

	renderLongMenu() {
		const { openDialog } = this.state
		const { classes, label, options, value, groupBy } = this.props
		const { search } = this.state
		const sValue = value ? value + "" : ""
		const filteredOptions = options.filter(option => option?.label?.toLowerCase()?.includes(search?.toLocaleLowerCase()))
		const groups = Array.from(new Set(options.map(groupBy)))
		return (
			<Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
				<div className={classes.dialog}>
					<Typography variant='h1'>Seleccionar {label}</Typography>
					<TextInput
						label="Buscar"
						placeholder="Buscar..."
						value={search}
						onChange={this.handleSearch}
					/>
					{groups.map((group, gindex) => {
						const groupOptions = filteredOptions.filter(option => groupBy(option) === group).sort((a, b) => {
							if (a.label > b.label) return 1
							if (a.label < b.label) return -1
							return 0
						})
						return (
							<Fragment key={gindex}>
								<Typography variant='h4'>{group}</Typography>
								<div className={classes.options}>
									{groupOptions.map((option, index) => {
										const isSelected = option.value === sValue
										return (
											<div key={option.label + index} className={classes.option} onClick={this.handleChangeLong(option)}>
												<Radio checked={isSelected} />
												<Typography variant='subtitle1'>{option.label}</Typography>
											</div>
										)
									})}
								</div>
							</Fragment>
						)
					})}
				</div>
			</Dialog>
		)
	}

	render() {
		const { value, label, name, disabled = false, required, inputStyle, options, onChange } = this.props
		const sValue = value ? value + "" : ""
		if (!options.length) return null

		if (options.length < 10) {
			return (
				<>
					<FormControl fullWidth style={{ margin: "12px 0" }}>
						<Select
							value={sValue || ""}
							fullWidth
							name={String(name)}
							onChange={onChange}
							variant="outlined"
							displayEmpty
							disabled={disabled}
							className={inputStyle}
						>
							{this.renderMenu()}
						</Select>
						<InputLabel shrink variant="outlined">
							{`${label}${required ? "*" : ""}`}
						</InputLabel>
					</FormControl>
				</>
			)
		}

		return (
			<div style={{ position: 'relative', width: '100%' }}>
				<FormControl fullWidth style={{ margin: "12px 0" }}>
					<Select
						value={sValue || ""}
						fullWidth
						name={name}
						onChange={onChange}
						variant="outlined"
						displayEmpty
						disabled
						className={inputStyle}
						onClick={this.handleOpenDialog}
					>
						{this.renderMenu()}
					</Select>
					<InputLabel shrink variant="outlined">
						{`${label}${required ? "*" : ""}`}
					</InputLabel>
				</FormControl>
				{this.renderLongMenu()}
			</div>
		)
	}
}

SelectInput.propTypes = {}

SelectInput.defaultProps = {
	options: [],
	placeholder: "",
	groupBy: (option) => option.isSupervised !== undefined ? option.isSupervised ? "Supervisado" : "Otro" : ""
}

export default withStyles(style)(SelectInput)