import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'
import ComplianceDonut from './ComplianceDonut'

const style = theme => ({
  container: {
    padding: 12,
  },
  dataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > *': {
      margin: '0 36px 24px 0'
    }
  },
  complianceElement: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    padding: 12,
    margin: '12px 0 0 0',
    '& > *': {
      marginRight: 12,
      color: 'white',
      minWidth: 25
    },
    '& > svg': {
      height: 24,
      width: 24
    }
  },
  green: { background: theme.palette.green.main },
  red: { background: theme.palette.red.main },
  blue: { background: theme.palette.blue.main }
})

class ComplianceReport extends Component {

  render() {
    const { classes, data } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h4">Avance de actividades</Typography>
        <Divider style={{ margin: '12px 0' }} />
        <div className={classes.dataContainer}>
          <div>
            <div className={`${classes.complianceElement} ${classes.blue}`}>
              <CheckCircleOutline />
              <Typography variant="h4">{data?.totalPending ?? 0}</Typography>
              <Typography variant="subtitle1">Programadas</Typography>
            </div>
            <div className={`${classes.complianceElement} ${classes.green}`}>
              <CheckCircleOutline />
              <Typography variant="h4">{data?.totalDone ?? 0}</Typography>
              <Typography variant="subtitle1">Realizadas</Typography>
            </div>
            <div className={`${classes.complianceElement} ${classes.red}`}>
              <CheckCircleOutline />
              <Typography variant="h4">{data?.totalOverdue ?? 0}</Typography>
              <Typography variant="subtitle1">Vencidas</Typography>
            </div>
          </div>
          <div>
            <ComplianceDonut data={data} />
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ComplianceReport)