import { AddCircleOutline, ArrowDropDownRounded, Delete, Edit, FileCopy, HighlightOff, KeyboardArrowRightRounded } from '@material-ui/icons'
import { IconButton, Box, Typography, Button, Paper, Divider, Tooltip } from '@mui/material'
import { createContext, useEffect, useMemo, useState } from 'react'
import { getSettingsChecklist } from '../../API/checklists'
import { addRequirement, cloneSubdivisions, createSubdivision, deleteSubdivision, editSubdivision, getSubdivisions } from '../../API/subdivisions'
import useToggle from '../../Hooks/ToogleHook'
import newTheme from '../../newTheme'
import FixedTable from '../../Shared/FixedTable/FixedTable'
import SelectInput from '../../Shared/Inputs/SelectInput'
import TextInput from '../../Shared/Inputs/TextInput'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import { transformToOptions } from '../../Utils/functions'
import CloneDialog from './Structure/CloneDialog'
import NewSubdivision from './Structure/NewSubdivision'
import Requirements from './Structure/Requirements'
import Subdivision from './Subdivision'

const css = {
	container: {
		margin: '24px 0'
	},
	structure: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		minHeight: 300,
		margin: '12px 0',
		position: 'relative'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	create: {
		alignItems: 'center',
		position: 'absolute',
		right: 6,
		top: 6,
		textAlign: 'end'
	},
	breadcrums: {
		display: 'flex',
		padding: 2,
		alignItems: 'center'
	},
	subdivisions_container: {
		display: 'flex',
		alignItems: 'stretch',
		gap: '24px'
	},
	sub_content: {
		background: 'whitesmoke',
		borderRadius: 2,
		padding: 3,
		flexGrow: 1,
		overflow: 'hidden'
	},
	section: {
		padding: 2,
		margin: '12px 0'
	}
}

function checkIfShouldBeOpen(subdivision, selected) {
	const imSelected = selected?.id === subdivision.id
	const children = subdivision.children || []
	const oneChildSelected = children.map(child => checkIfShouldBeOpen(child, selected)).some(Boolean)
	return imSelected || oneChildSelected
}

function calculateCompliance(subdivision) {
	const children = subdivision.children
	const requirements = subdivision.requirements
	let total = requirements.reduce((a, b) => a + b.amount, 0)
	let compliance = requirements.reduce((a, b) => a + b.compliance, 0)
	children.forEach(child => {
		const [child_total, child_compliance] = calculateCompliance(child)
		total += child_total
		compliance += child_compliance
	})
	return [total, compliance]
}

function calculateRequirementCompliance(subdivision, requirement_name) {
	const children = subdivision.children
	const requirements = [...subdivision.requirements].filter(r => r.checklist === requirement_name)
	let total = requirements.reduce((a, b) => a + b.amount, 0)
	let compliance = requirements.reduce((a, b) => a + b.compliance, 0)
	children.forEach(child => {
		const [child_total, child_compliance] = calculateRequirementCompliance(child, requirement_name)
		total += child_total
		compliance += child_compliance
	})
	return [total, compliance]
}

function calculateTable(selected) {
	console.log(selected)
	if (!selected) return []
	const table_requirements = []

	function DSrequirements(subdivision, requirements) {
		subdivision.requirements.forEach(req => {
			if (!requirements.includes(req.checklist)) {
				requirements.push(req.checklist)
			}
		})
		const children = subdivision.children
		children.forEach(child => {
			DSrequirements(child, requirements)
		})
	}

	DSrequirements(selected, table_requirements)
	const children = selected.children
	const columns = children
	const rows = table_requirements.map(req => {
		const data = columns.map(col => {
			const [total, comp] = calculateRequirementCompliance(col, req)
			return `${comp}/${total}`
		})
		return [req].concat(data)
	})
	return [[" "].concat(columns.map(col => col.name)), rows]
}

export const StructureContext = createContext({})

function InternalStructure({ branch }) {

	const [subdivisions, setStructure] = useState([])
	const [selected, setSelected] = useState(null)
	const [openCreate, toggleCreate] = useToggle(false)
	const [openClone, toggleClone] = useToggle(false)
	const [selecting, toggleSelecting] = useToggle(false)
	const [selected_requirement, setRequireSelected] = useState([])
	const [baseChecklists, setBaseChecklists] = useState([])
	const [params, setParams] = useState({})
	const [columns, rows] = useMemo(() => calculateTable(selected), [selected])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			const response = await getSubdivisions({ branch_id: branch.id })
			setStructure(response.data.info)
			const response2 = await getSettingsChecklist()
			setBaseChecklists(response2.data.info)
			setLoading(false)
		}

		fetchData()
	}, [branch])

	async function childCreate(body) {
		body.branch_id = branch.id
		const response = await createSubdivision(body)
		setStructure(response.data.info)
	}

	async function childEdit(body) {
		body.branch_id = branch.id
		const response = await editSubdivision(body)
		setStructure(response.data.info)
	}

	async function childDelete(body) {
		body.branch_id = branch.id
		const response = await deleteSubdivision(body)
		setStructure(response.data.info)
	}

	async function onClone(body) {
		const response = await cloneSubdivisions(body)
		setStructure(response.data.info)
		toggleCreate()
	}

	function startSelecting() {
		setRequireSelected([selected.id])
		toggleSelecting()
	}

	function getBreadCrums() {
		const names = [{ name: branch.name, element: null }]
		function addToBread(subs) {
			subs.forEach(subdivision => {
				if (checkIfShouldBeOpen(subdivision, selected)) {
					names.push({ name: subdivision.name, element: subdivision })
					const children = subdivision.children || []
					addToBread(children)
				}
			})
		}

		addToBread(subdivisions)

		return names
	}

	function selectForRequirement(value) {
		const newSelected = [...selected_requirement]
		if (newSelected.includes(value)) {
			const index = newSelected.findIndex(e => e === value)
			newSelected.splice(index, 1)
		} else {
			newSelected.push(value)
		}
		setRequireSelected(newSelected)
	}

	function changeSelected(value) {
		if (selecting) return null
		setSelected(value)
	}

	function onChange(e) {
		const { target } = e
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	async function onCreateRequirement() {
		const ids = [...selected_requirement]
		const { base_checklist_id, amount } = params
		const body = {
			ids,
			base_checklist_id,
			amount
		}
		body.branch_id = branch.id
		toggleSelecting()
		setRequireSelected([])
		setParams({})
		await addRequirement(body)
	}

	const crums = getBreadCrums()
	const requirements = selected?.requirements || []
	const [total, compliance] = !!selected ? calculateCompliance(selected) : [0, 0]
	console.log(columns, rows)

	return (
		<StructureContext.Provider value={{
			setSelected: changeSelected,
			selected,
			childCreate,
			childEdit,
			childDelete,
			toggleClone,
			selecting,
			selected_requirement,
			selectForRequirement
		}}>
			<Box sx={css.container}>
				<CloneDialog onClone={onClone} open={openClone} onClose={toggleClone} selected={selected} crums={crums} subdivisions={subdivisions} branch={branch} />
				<Typography variant='h1'>Estructura interna del centro de trabajo</Typography>
				<Box sx={css.structure}>
					<Box sx={css.breadcrums}>
						{crums.map((crum, index) => {
							return (
								<>
									{index > 0 && <KeyboardArrowRightRounded style={{ height: 28, width: 28, color: newTheme.palette.grey.dark }} />}
									<Typography
										variant='h4'
										style={{ cursor: 'pointer', color: index === crums.length - 1 ? newTheme.palette.blue.main : "" }}
										onClick={() => changeSelected(crum.element)}
									>
										{crum.name}
									</Typography>
								</>
							)
						})}
					</Box>
					<Box sx={css.header} onClick={() => changeSelected(null)}>
						<ArrowDropDownRounded style={{ color: newTheme.palette.blue.main, height: 32, width: 32 }} />
						<Typography variant='h4' style={{ color: newTheme.palette.blue.main }}>{branch.name}</Typography>
						{!selected &&
							<IconButton style={{ marginLeft: 12 }} size="small" onClick={toggleCreate}>
								<AddCircleOutline />
							</IconButton>
						}
					</Box>
					{
						loading ? <LoaderAnimator /> :
							<Box sx={css.subdivisions_container}>
								<Box style={{ paddingLeft: 18 }}>
									{subdivisions.map(subdivision => <Subdivision subdivision={subdivision} />)}
									{!selected && openCreate && <NewSubdivision onClose={toggleCreate} />}
								</Box>
								{!!selected &&
									<Box sx={css.sub_content}>
										<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
											<Box>
												<Typography variant='h4'>{selected?.name}</Typography>
												<Typography variant='caption'>{`Realizados: ${compliance} de ${total}`}</Typography>
											</Box>
											{!selecting &&
												<Button onClick={startSelecting} color="primary" variant="outlined" size="small">
													Agregar requerimientos
												</Button>
											}
										</Box>
										{selecting &&
											<Paper sx={css.section}>
												<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
													<Typography variant='h4'>Nuevo requerimiento</Typography>
													<IconButton onClick={toggleSelecting}>
														<HighlightOff />
													</IconButton>
												</Box>
												<SelectInput value={params.base_checklist_id} name="base_checklist_id" onChange={onChange} label="Tipo de registro" options={transformToOptions(baseChecklists)} />
												<TextInput value={params.amount} name="amount" onChange={onChange} label="Cantidad mínima" type="number" />
												<Box>
													<Typography variant='caption'>Los requerimientos serán aplicados a todas las subdivisiones seleccionadas</Typography>
												</Box>
												<Box style={{ textAlign: 'end' }}>
													<Button onClick={onCreateRequirement}>Crear requerimientos</Button>
												</Box>
											</Paper>
										}
										<Paper sx={css.section}>
											<Typography variant='h4'>Requerimientos</Typography>
											{
												!!requirements.length ?
													<Requirements requirements={requirements} />
													:
													<Typography variant='subtitle1'>No se han agregado requerimientos para esta subdivisión</Typography>
											}
										</Paper>
										{!!rows.length && rows[0].length > 1 &&
											< Paper sx={css.section}>
												<Typography variant='h4'>Cumplimiento</Typography>
												<FixedTable columns={columns} rows={rows} />
											</Paper>
										}
									</Box>
								}
							</Box>}
				</Box>
			</Box>
		</StructureContext.Provider >
	)
}

export default InternalStructure