import stateMaker from "../Utils/stateMaker"

const ActivityReducer = (
    state = { global: [], selected: {}, all: [], params: [{}] },
    action
) => {
    switch (action.type) {
        case "GET_GLOBAL_ACTIVITIES":
            return stateMaker(state, "global", "fetch", action)
        case "GET_ACTIVITIES":
            return stateMaker(state, "all", "fetch", action)
        case "GET_ACTIVITY":
            return stateMaker(state, "selected", "fetch", action)
        case "GET_ACTIVITY_PARAMS":
            return stateMaker(state, "params", "fetch", action)
        case "CREATE_ACTIVITY": {
            const newState = { ...state }
            if (action.payload.data.status === 'success') {
                newState.all.push(action.payload.data.info[0])
            }
            return newState
        }
        case "CREATE_MULTIPLE_ACTIVITY": {
            const newState = { ...state }
            if (action.payload.data.status === 'success') {
                newState.all.concat(action.payload.data.info)
            }
            return newState
        }
        default:
            return state
    }
}

export default ActivityReducer
