import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import moment from 'moment'
import { InsertDriveFileOutlined } from '@material-ui/icons'

const style = () => ({
  answer: {
    padding: '6px 12px',
    borderRadius: 8,
    margin: 6,
    background: 'white',
    maxWidth: 150,
    minWidth: 150,
    transition: 'all 0.5s ease-in-out',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
})

class FilePhaseReport extends Component {
  handleOpen(file) {
    return () => {
      console.log(file)
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}${file}`
      window.open(url)
    }
  }

  render() {
    const { classes, answers } = this.props
    return (
      <div className={classes.container}>
        {answers.map(answer => {
          const value = answer?.data?.value
          if (!value) return null
          return value?.split("#")?.map(file => {
            return (
              <div className={`${classes.answer}`} onClick={this.handleOpen(file)}>
                <Typography variant="subtitle1">{moment(answer?.data?.date).format("YYYY-MM-DD")}</Typography>
                <InsertDriveFileOutlined color="primary" />
              </div>
            )
          })
        })}
      </div>
    )
  }
}

export default withStyles(style)(FilePhaseReport)