import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import BaseWorkflow from './BaseWorkflow'
import autobind from '../../../Utils/autobind'
import AllStepReport from './AllStepReport'

const style = () => ({

})

class BasesContainer extends Component {
  constructor() {
    super()
    this.state = {
      selectedBase: null
    }
    autobind(BasesContainer, this)
  }

  handleSelectBase(option) {
    return () => {
      const { selectedBase } = this.state
      if (selectedBase === option) return this.setState({ selectedBase: null })
      return this.setState({ selectedBase: option })
    }
  }

  render() {
    const { classes, bases, workflows } = this.props
    const { selectedBase } = this.state
    const selectedWorkflows = workflows.filter(wf => selectedBase === "all" || wf.workflow_base_id === selectedBase)
    return (
      <div className={classes.container}>
        <AllStepReport
          onSelect={this.handleSelectBase}
          selected={selectedBase}
          workflows={selectedWorkflows}
        />
        {bases
          .filter(base => !selectedBase || base.id === selectedBase)
          .map(base => <BaseWorkflow
            key={base.id}
            base={base}
            onSelect={this.handleSelectBase}
            selected={selectedBase}
            workflows={selectedWorkflows}
          />)}
      </div>
    )
  }
}

export default withStyles(style)(BasesContainer)