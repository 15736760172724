import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import DayEvent from './DayEvent'

const style = () => ({
  header: {
    background: '#6c6c6c',
    color: 'white',
    padding: '6px 12px',
    width: 250,
    borderRadius: '0 24px 24px 0',
    marginTop: 24
  }
})
class MobileDay extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(MobileDay, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { classes, day } = this.props
    const { open } = this.state
    const events = day.events
    const visible = events.slice(0, 4)
    const hidden = events.slice(4)
    return (
      <div className={classes.day}>
        <Typography className={classes.header} variant='h4'>{day.date.format("dddd DD [de] MMMM [del] YYYY")}</Typography>
        {visible.map(event => {
          return <DayEvent event={event} />
        })}
        <div style={{ overflow: 'hidden', height: open ? "100%" : 0, marginTop: -2 }}>
          {hidden.map(event => {
            return <DayEvent event={event} />
          })}
        </div>
        {!!hidden.length &&
          <Button onClick={this.handleOpen}>
            {`Ver ${open ? "Menos" : "Más"}`}
          </Button>}
      </div>
    )
  }
}

export default withStyles(style)(MobileDay)