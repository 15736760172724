import React from "react"
import "./App.css"
import Sidebar from "./Components/Sidebar/Sidebar"
import { Button, Dialog, IconButton, Typography, withStyles } from "@material-ui/core"
import Header from "./Components/Header/Header"
import Routes from "./Routes/Routes"
import PublicRoutes from "./Routes/PublicRoutes"
import autobind from "./Utils/autobind"
import { connect } from "react-redux"
import { setupSnacks } from "./Actions/MainActions"
import { withSnackbar } from "notistack"
import instance from "./Utils/instance"
import { setOnlineStatus } from "./Actions/OnlineActions"
import { removeDoneRequests, setRequest, updateRequestsActions } from "./Actions/RequestAction"
import moment from "moment"
import "moment/locale/es"
import ErrorBoundary from "./Shared/ErrorBoundary"
import packageJson from "../package.json"
import UpdateDialog from "./Shared/UpdateDialog"
import Footer from "./Components/Footer/Footer"
import GlobalDeletePopup from "./Shared/Dialogs/GlobalDeletePopup"
import { KeyboardArrowLeft } from "@material-ui/icons"
import { withRouter } from "react-router"
import { getWorkersAction } from "./Actions/EnterpriseAction"
import BigLoaderAnimator from "./Shared/BigLoaderAnimator"
import ReactGA, { exception } from 'react-ga'
import AppContext from "./AppContext"
import { getSubEnterprises } from "./API/enterprise"
import instance2 from "./Utils/instance2"
import { getInputUsers } from "./API/users"
import DatabaseManager from "./DatabaseManagers/DatabaseManager"
import OfflineRequestManager from "./OfflineRequestManager"
localStorage.setItem("version", packageJson.version)

const style = () => ({
    content: {
        marginLeft: 56,
        padding: '15px 50px',
        "@media (max-width:500px)": {
            padding: 0,
            margin: 0,
            marginBottom: 80
        },
    },
    version: {
        position: 'fixed',
        bottom: 12,
        right: 12,
        textAlign: 'end'
    },
    backButton: {
        background: "#202d4c",
        color: "#ADF5FF",
        '&:hover': {
            background: "#202d4c99",
        },
        opacity: 0.7
    },
    backButtonContainer: {
        position: 'fixed',
        bottom: 12,
        left: 0,
        transition: 'all 0.2s ease-in-out',
        zIndex: 500
    }
})

const start_url = window.location.origin

let enterprise = "tazki"
if (start_url.includes("essal")) { enterprise = "essal" }
if (start_url.includes("bricsa")) { enterprise = "bricsa" }
if (start_url.includes("befco")) { enterprise = "befco" }
if (start_url.includes("saesa")) { enterprise = "saesa" }

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g)

    const versionsB = versionB.split(/\./g)
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift())

        const b = Number(versionsB.shift())
        // eslint-disable-next-line no-continue
        if (a === b) {
            continue
        }
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b)
    }
    return false
}

function ConectionLossDialog(props) {
    const { action, open, onClose } = props
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <div style={{ padding: 24 }}>
                <Typography variant="subtitle1">Notamos perdida en la conexión, te gustaría trabajar sin conexión mientras recuperas acceso a la red?</Typography>
                <div style={{ textAlign: 'end' }}>
                    <Button color="secondary" variant="contained" onClick={() => {
                        action()
                        onClose()
                    }
                    } style={{ marginTop: 24 }}>
                        Trabajar sin conexión
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

function ConectionBackDialog(props) {
    const { action, open, onClose } = props
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <div style={{ padding: 24 }}>
                <Typography variant="subtitle1">Se recuperó el acceso a la red, deseas volver a trabajar en linea?</Typography>
                <div style={{ textAlign: 'end' }}>
                    <Button color="secondary" variant="contained" onClick={() => {
                        action()
                        onClose()
                    }
                    } style={{ marginTop: 24 }}>
                        Trabajar en linea
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            requests: [],
            version: "",
            old: "",
            updateRequired: false,
            openOffline: false,
            openOnline: false,
            loading: false,
            sub_enterprises: []
        }

        autobind(App, this)
    }

    async saveUsersForOffline() {
        const response = await getInputUsers()
        localStorage.setItem("user-input", JSON.stringify(response.data.info))
    }

    async componentDidMount() {
        this.saveUsersForOffline()
        moment.locale("es")
        const { setupSnack, enqueueSnackbar, getWorkers, user } = this.props
        const isLogged = !!user?.account?.user?.id

        if (isLogged) {
            this.setState({ loading: true })
            getWorkers()
            const subResponse = await getSubEnterprises()
            const { data: { info: sub_enterprises } } = subResponse
            this.setState({ loading: false, sub_enterprises })

        }
        await setupSnack(enqueueSnackbar)


        const self = this

        window.addEventListener('online', () => {
            this.setState({ openOnline: true, openOffline: false })
        })
        window.addEventListener('offline', () => {
            this.setState({ openOffline: true, openOnline: false })
        })

        fetch("/meta.json")
            .then(response => response.json())
            .then(meta => {
                const latestVersion = meta.version
                const localVersion = localStorage.getItem("version")
                self.setState({ old: localVersion, version: latestVersion })
                console.log(localVersion, latestVersion)
                if (semverGreaterThan(latestVersion, localVersion)) {
                    self.setState({ updateRequired: true })
                }
            })

        window.addEventListener('click', async () => {
            try {
                const response = await fetch("/meta.json")
                const meta = await response.json()
                const latestVersion = meta.version
                const localVersion = localStorage.getItem("version")
                self.setState({ old: localVersion, version: latestVersion })
                console.log(localVersion, latestVersion)
                if (semverGreaterThan(latestVersion, localVersion)) {
                    self.setState({ updateRequired: true })
                }
            } catch (err) {
                console.log("can't fetch update offline")
            }

        })

        window.addEventListener("scroll", () => {
            const element = document.getElementById("back-button")
            if (!element) return null
            if (window.scrollY >= 300) {
                element.style.left = window.innerWidth <= 500 ? "6px" : "62px"
                element.style.bottom = window.innerWidth <= 500 ? "70px" : "6px"
            } else {
                element.style.left = "-52px"
            }
        })

    }

    handleOpenSidebar(value) {
        return () => {
            if (value === undefined) {
                this.setState({ open: !this.state.open })
            } else {
                this.setState({ open: value })
            }
        }
    }

    handleToggleOnline(option) {
        const { setOnline } = this.props
        if (option) {
            setOnline(true)
            const pendingRequests = JSON.parse(localStorage.getItem("SavedRequests"))
            this.setState({ requests: pendingRequests }, this.handleSyncRequests)
        } else {
            setOnline(false)
        }
    }

    handleBack() {
        const { history } = this.props
        history.goBack()
    }

    render() {
        const { classes, user, setOnline, history, workers, online, enterprise } = this.props
        const { open, updateRequired, openOffline, openOnline, loading, sub_enterprises } = this.state
        const { account } = user
        const jwt = account.jwt
        const isMobile = window.innerWidth <= 500

        if (loading) return (
            <div style={{ height: '100vh', width: '100vw', position: 'relative', background: 'white' }}>
                <div style={{ position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <img src={`/enterprises/${enterprise}/192.png`} alt="cargando" width="192px" />
                    <BigLoaderAnimator />
                </div>
            </div>
        )

        return (
            <div>
                <DatabaseManager>
                    <AppContext.Provider value={{ history, workers, account, level: account?.user?.userType, sub_enterprises, online, setOnline, enterprise }}>
                        <OfflineRequestManager />
                        <GlobalDeletePopup />
                        <ConectionBackDialog action={() => setOnline(true)} open={openOnline} onClose={() => this.setState({ openOnline: false })} />
                        <ConectionLossDialog action={() => setOnline(false)} open={openOffline} onClose={() => this.setState({ openOffline: false })} />
                        <UpdateDialog open={updateRequired} />
                        {jwt ? (
                            <div id="app-window">
                                <div id="back-button" className={classes.backButtonContainer}>
                                    <IconButton size="small" className={classes.backButton} onClick={this.handleBack}>
                                        <KeyboardArrowLeft />
                                    </IconButton>
                                </div>
                                <ErrorBoundary>

                                    <Sidebar
                                        open={open}
                                        handleClose={this.handleOpenSidebar}
                                    />
                                    <div className={classes.content}>
                                        <Header handleOpen={this.handleOpenSidebar} />
                                        {/* <button onClick={() => this.handleToggleOnline(!online.status)}>Apagar</button> */}
                                        <Routes />
                                    </div>
                                    {isMobile && <Footer />}
                                </ErrorBoundary>
                            </div>
                        ) : (
                            <PublicRoutes />
                        )}
                    </AppContext.Provider>
                </DatabaseManager>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    online: state.online,
    workers: state.workers.all,
    enterprise: state.enterprise
})

const mapDispatchToProps = (dispatch) => ({
    setupSnack: (action) => dispatch(setupSnacks(action)),
    setOnline: (status) => dispatch(setOnlineStatus(status)),
    updateRequests: (requests) => dispatch(updateRequestsActions(requests)),
    removeDoneRequests: () => dispatch(removeDoneRequests()),
    setRequest: (index, status) => dispatch(setRequest(index, status)),
    getWorkers: () => dispatch(getWorkersAction())
})

export default withRouter(withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(App)))
)
