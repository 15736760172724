import React, { Component } from 'react'
import { Dialog, Paper, Typography, withStyles } from '@material-ui/core'
import ImageContainer from '../../../Shared/ImageContainer'
import moment from 'moment'

const style = theme => ({
  docSigns: {
    padding: 12,
    display: 'flex',
    flexWrap: 'wrap',
  },
  docSign: {
    width: 240,
    padding: 12,
    margin: 12,
    "@media (max-width:500px)": {
      width: '100%',
    },
  },
  signTitle: {
    color: 'white',
    background: theme.palette.primary.main,
    padding: 12
  },
  caption: {
    textAlign: 'end'
  },
})
class DocumentSigns extends Component {

  renderSigns() {
    const { classes, signs } = this.props
    if (signs.length > 0) {
      return signs.map(sign => {
        const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
        return (
          <Paper className={classes.docSign}>
            <Typography variant="subtitle1">{sign.name}</Typography>
            <ImageContainer src={url || "/noimage.png"} height="100px" width="200" />
            <div className={classes.caption}>
              <Typography variant="caption">{moment(sign.date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            </div>
          </Paper>
        )
      })
    } else {
      return (
        <div>
          <Typography variant="subtitle1">No hay firmas aún en el documento</Typography>
        </div>
      )
    }
  }

  render() {
    const { classes, open, onClose } = this.props
    return (
      <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
        <Typography variant="h2" className={classes.signTitle}>Firmas del documento</Typography>
        <div className={classes.docSigns}>
          {this.renderSigns()}
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(DocumentSigns)