import { KeyboardArrowLeft, KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useRef } from 'react'
import useToggle from '../../Hooks/ToogleHook'

const css = {
	pager: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		margin: '12px 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
}

function Pager({ page, limit, total, setLimit, setPage }) {

	const [openMenu, toggleMenu] = useToggle(false)
	const menu = useRef(null)
	const leftLimit = page * limit + 1
	let rightLimit = (page + 1) * limit
	const isOverRight = rightLimit >= total
	const isOverLeft = page === 0
	rightLimit = rightLimit < total ? rightLimit : total

	return (
		<Box>
			<Box sx={css.pager}>
				<Typography variant='subtitle1'>Página {page + 1}</Typography>
				<Box>
					<Typography variant='subtitle1'>Mostrando {leftLimit}-{rightLimit} de {total}</Typography>
				</Box>
				<Box>
					<IconButton onClick={() => !isOverLeft && setPage(p => p - 1)}>
						<KeyboardArrowLeft />
					</IconButton>
					<IconButton onClick={() => !isOverRight && setPage(p => p + 1)}>
						<KeyboardArrowRight />
					</IconButton>
					<IconButton ref={menu} onClick={toggleMenu}>
						<MoreVert />
					</IconButton>
				</Box>
			</Box>
			<Menu open={openMenu} anchorEl={menu.current} onClose={toggleMenu}>
				<MenuItem onClick={() => { setLimit(10); toggleMenu() }}>Mostrar 10 elementos</MenuItem>
				<MenuItem onClick={() => { setLimit(25); toggleMenu() }}>Mostrar 25 elementos</MenuItem>
				<MenuItem onClick={() => { setLimit(50); toggleMenu() }}>Mostrar 50 elementos</MenuItem>
			</Menu>
		</Box>
	)
}

export default Pager