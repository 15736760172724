import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { getPrograms } from '../../../API/programs'
import ProgramCard from './ProgramCard'

const style = () => ({
  programs: {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

class ProgramsVisualization extends Component {
  constructor() {
    super()
    this.state = {
      programs: []
    }
  }
  async componentDidMount() {
    const response = await getPrograms()
    console.log(response)

    const { data: { info: programs } } = response
    this.setState({ programs })
  }

  renderPrograms() {
    const { programs } = this.state
    return programs.map(program => <ProgramCard program={program} />)
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h1" style={{ marginBottom: 24 }}>Monitoreo de programas</Typography>
        <div className={classes.programs}>
          {this.renderPrograms()}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ProgramsVisualization)