import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import GeneralTable from '../../../Shared/GeneralTable'

const style = theme => ({
  container: {
    padding: 12
  },
  titleContainer: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > *": {
      color: "white"
    },
    maxWidth: 500,
    marginBottom: 12
  }
})

class FindingsResume extends Component {

  render() {
    const { classes, match, findings, workers } = this.props
    const option = match.params.option
    const allFindings = findings.all || []
    const allWorkers = workers.all || []
    const pendings = allFindings.filter(finding => finding.status === "Pendiente")
    const overdue = allFindings.filter(finding => finding.status === "Vencido")
    const processing = allFindings.filter(finding => finding.status === "Procesando" || finding.status === "Vencido")
    const done = allFindings.filter(finding => finding.status === "Terminado")

    let findingsToUse = []
    switch (option) {
      case "pending": {
        findingsToUse = pendings
        break
      }
      case "assigned": {
        findingsToUse = processing
        break
      }
      case "overdue": {
        findingsToUse = overdue
        break
      }
      case "finished": {
        findingsToUse = done
        break
      }
      default: {
        findingsToUse = pendings
        break
      }
    }

    const tableData = allWorkers.map(worker => {
      return {
        name: worker.name,
        amount: findingsToUse.filter(finding => finding.user_creator_id.toString() === worker.id.toString()).length,
      }
    }).filter(data => data.amount !== 0)

    const optionsDict = {
      pending: "no asignado",
      assigned: "en proceso",
      overdue: "vencidos",
      finished: "por aprobar"
    }

    const tableInfo = [
      { label: "name", name: "Nombre" },
      { label: "amount", name: `Total ${optionsDict[option]}` },
    ]


    return (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1">{`Hallazgos ${optionsDict[option]}`}</Typography>
        </div>
        <GeneralTable data={tableData} info={tableInfo} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  findings: state.findings,
  workers: state.workers
})

export default connect(mapStateToProps)(withStyles(style)(FindingsResume))