import { ImportExport } from '@material-ui/icons'
import { Box, Button, Dialog, Divider, getTableBodyUtilityClass, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { uploadExcel } from '../../../API/users'
import ImageContainer from '../../../Shared/ImageContainer'
import LoadingBox from '../../../Shared/Loader/LoadingBox'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import { inline, inline_space, text_space } from '../../../Utils/defaultStyles'
import { callSnackbar } from '../../../Utils/snackbar'

const css = {
	container: {
		padding: 3
	},
	name: {
		...inline_space,
		margin: '6px 0',
	},
	image: {
		maxWidth: '100%',
		overflow: 'auto'
	},
	buttons: {
		...text_space,
		textAlign: 'end'
	}
}

function ImportExcelDialog({ value, open, onClose }) {

	const input = useRef(null)
	const [loading, setLoading] = useState(false)

	async function onFileSelect(event) {
		const { target } = event
		const file = target?.files?.[0]
		const body = new FormData()
		body.append("file", file)
		setLoading(true)
		await uploadExcel(body)
		callSnackbar("Colaboradores ingresados correctamente", "success")
		onClose()
		setLoading(false)
	}


	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Box sx={css.container}>
				<Box sx={inline}>
					<ImportExport />
					<Typography variant='h1'>Carga masiva de colaboradores</Typography>
				</Box>
				<Box>
					<Typography sx={text_space} variant='h4'>Información importante:</Typography>
					<Typography sx={text_space} variant='subtitle1'>
						A continuación se detallan los datos
						necesarios en el excel y su formato para que la carga masiva resulte exitosa.
						Es importante que se respeten estos formatos, de lo contrario, podrían ocurrir errores o inconsistencias.
					</Typography>
				</Box>
				<Box sx={css.names}>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Nombre(*): </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Primer nombre del colaborador</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Apellido(*): </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Apellido del colaborador</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>RUT(*): </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Rut del colaborador sin puntos y con guión (11111111-1)</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Correo(*): </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Correo electrónico del colaborador</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Dirección: </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Dirección física del colaborador</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Teléfono: </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Teléfono del colaborador</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Fecha de ingreso: </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Fecha en la que ingresó el colaborador a la empresa</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Fecha de nacimiento: </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Fecha de nacimiento del colaborador</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Cargo: </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Cargo del colaborador</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Tipo de usuario(*): </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >El tipo de usuario en Tazki (1 para admin, 2 supervisor, 3 para colaborador)</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Centro de trabajo(*): </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Nombre del centro de trabajo donde trabaja</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>SubEmpresa: </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Nombre de la sub empresa donde trabaja</Typography>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Empresa contratista: </Typography>
						<Box style={{ flexGrow: 1 }} >
							<Typography variant='subtitle1' style={{ flexGrow: 1 }} >Nombre de la empresa contratista donde trabaja</Typography>
							<Typography variant='caption'>Fijarse que la empresa contratista corresponda con el centro de trabajo / sucursal</Typography>
						</Box>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Rut supervisor: </Typography>
						<Box style={{ flexGrow: 1 }} >
							<Typography variant='subtitle1' >Ingrese el RUT del usuario que supervisará al colaborador sin puntos y con guión (11111111-1)</Typography>
							<Typography variant='caption'>el supervisor debe existir previamente en el sistema</Typography>
						</Box>
					</Box>
					<Box sx={css.name}>
						<Typography style={{ flexBasis: 180 }} variant='subtitle2'>Correo Bienvenida: </Typography>
						<Typography variant='subtitle1' style={{ flexGrow: 1 }} >1 si desea enviar el correo automaticamente, 0 si no.</Typography>
					</Box>
				</Box>
				<Typography color="error" variant='caption'>Los campos con * son obligatorios, el resto de los elementos pueden quedar vacíos al momento de crear los usuarios</Typography>
				<Divider sx={text_space} />
				<Button color="info" variant="text" href='/Usuarios.xlsx' >Descargar documento de ejemplo</Button>
				{loading ?
					<LoadingBox />
					:
					<Box sx={css.buttons}>
						<Button color="primary" variant="contained" onClick={() => input.current.click()}>
							Subir Archivo excel
						</Button>
						<input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={onFileSelect} hidden ref={input} />
					</Box>
				}
			</Box>
		</Dialog>
	)
}

export default ImportExcelDialog