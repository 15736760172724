import { Check, CheckCircleOutline } from '@material-ui/icons'
import { Box } from '@mui/material'
import { useState } from 'react'
import newTheme from '../../newTheme'
import SeamlessInput from '../../Shared/Inputs/SeamlessInput'

const css = {
	tag: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	color: {
		height: 24,
		width: 24,
		borderRadius: 1,
		margin: 1,
		cursor: 'pointer',
		position: 'relative'
	}
}

const colors = [
	newTheme.palette.primary.main + "a4",
	newTheme.palette.green.main + "a4",
	newTheme.palette.secondary.main + "a4",
	newTheme.palette.error.main + "a4",
	newTheme.palette.grey.main + "a4",
	newTheme.palette.blue.main + "a4"
]

console.log(colors)

function BranchTag({ tag, onEdit }) {

	const [name, setName] = useState(tag.name)

	function onBlur() {
		const body = { ...tag, name }
		onEdit(body)
	}

	function selectColor(color) {
		const body = { ...tag, color }
		onEdit(body)
	}

	return (
		<Box sx={css.tag}>
			<SeamlessInput variant="h4" color={tag.color} onBlur={onBlur} placeholder="Agregue nombre del grupo..." value={name} onChange={e => setName(e.target.value)} />
			<Box sx={css.tag}>
				{colors.map(color => {
					const selected = color === tag.color
					return (
						<Box sx={css.color} style={{ background: color }} onClick={() => selectColor(color)}>
							{selected && <CheckCircleOutline style={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50% , -50%)',
								opacity: 0.6
							}} />}
						</Box>
					)
				})}
			</Box>
		</Box>
	)
}

export default BranchTag