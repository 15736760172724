import React, { Component } from "react"
import propTypes from "prop-types"
import Chart from "chart.js"
import { withStyles, Typography } from "@material-ui/core"
import Conditional from "../Conditional"

const style = () => ({
    labels: {
        marginTop: 24,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 6,
    },
    label: {
        display: "flex",
        alignItems: "center",
    },
    dot: {
        width: 14,
        height: 14,
        borderRadius: "50%",
        marginRight: 12,
    },
})

class LineChart extends Component {
    componentDidMount() {
        this.renderChart()
    }

    renderChart() {
        const { name, data, options } = this.props
        const chartElement = document.getElementById(name)
        const myChartRef = chartElement.getContext("2d")
        chartElement.parentNode.style.height = options.height || "100%"
        chartElement.parentNode.style.width = options.width || "100%"

        const labels = data.map((element) => element.label)
        const values = data.map((element) => element.value)
        const colors = data.map((element) => element.color)
        // eslint-disable-next-line no-new
        new Chart(myChartRef, {
            type: "line",
            data: {
                // Bring in data
                labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: colors[0],
                        fill: false,
                        borderWidth: 4,
                        borderColor: colors[0],
                        lineTension: 0.5,
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                hover: {
                    mode: null,
                },
                tooltip: {
                    enabled: false,
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                display: !options.detachedLabels, // Detach here
                                fontFamily: "Montserrat, sans-serif",
                                fontStyle: "bold",
                                min: options.minValue || 0,
                                max: options.maxValue || 30,
                                stepSize: options.stepSize || 5,
                            },
                            gridLines: {
                                display: options.xEnableGrid || false,
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: options.xHasTitle,
                                labelString: options.xTitle,
                                fontFamily: "Montserrat, sans-serif",
                                fontStyle: "bold",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                fontFamily: "Montserrat, sans-serif",
                                fontStyle: "bold",
                                display: true,
                                min: options.minValue || 0,
                                max: options.maxValue || 30,
                                stepSize: options.stepSize || 5,
                                // callback(value) {
                                //   return `${options[value]}    `;
                                // }
                            },
                            gridLines: {
                                display: options.yEnableGrid || true,
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: options.yHasTitle,
                                labelString: options.yTitle,
                                fontFamily: "Montserrat, sans-serif",
                                fontStyle: "bold",
                            },
                        },
                    ],
                },
            },
        })
    }

    renderLabels() {
        const { classes, data } = this.props
        return data.map((element, index) => {
            const toReturn = (
                <div className={classes.label} key={index}>
                    <div
                        className={classes.dot}
                        style={{ background: element.color }}
                    />
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                        {element.label}
                    </Typography>
                </div>
            )
            return toReturn
        })
    }

    render() {
        const { name, title, classes, options } = this.props
        return (
            <div>
                <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ textAlign: "start", marginBottom: 24 }}
                >
                    {title}
                </Typography>
                <div>
                    <canvas id={name} ref={this.chartRef} />
                </div>
                <Conditional condition={options.detachedLabels}>
                    <div className={classes.labels}>{this.renderLabels()}</div>
                </Conditional>
            </div>
        )
    }
}

LineChart.propTypes = {
    classes: propTypes.object.isRequired,
    title: propTypes.string,
    name: propTypes.string,
    data: propTypes.arrayOf(propTypes.object),
    options: propTypes.object,
}

LineChart.defaultProps = {
    data: [],
    title: "Gráfico",
    options: {},
    name: "noName"
}

export default withStyles(style)(LineChart)
