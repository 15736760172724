import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import TextInput from './TextInput'
import autobind from '../../Utils/autobind'
import { Box, Checkbox } from '@mui/material'

const style = () => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		'& > *': {
			flexBasis: 500
		}
	}
})
class ComplianceInput extends Component {
	constructor() {
		super()
		this.state = {
			values: {},
		}
		autobind(ComplianceInput, this)
	}

	componentDidMount() {
		const { value } = this.props
		this.setState({
			values: value ? JSON.parse(value) : {
				desfav: 0,
				total: 0
			}
		})
	}

	handleChange(event) {
		const { onChange, name } = this.props
		const { target } = event
		const { values } = this.state
		values[target.name] = target.value
		this.setState({ values })
		const newEvent = {
			target: {
				name,
				value: JSON.stringify(values)
			}
		}
		onChange(newEvent)
	}

	handleCheck() {
		const { onChange, name } = this.props
		const { values } = this.state
		values.solved = !values.solved
		this.setState({ values })
		const newEvent = {
			target: {
				name,
				value: JSON.stringify(values)
			}
		}
		onChange(newEvent)
	}

	render() {
		const { classes, value, field } = this.props
		const values = value ? JSON.parse(value) : {}
		const des = values.desfav || 0
		const compliance = values.total > 0 ? 100 - ((des * 100) / values.total) : 100
		return (
			<>
				<div className={classes.container}>
					<div>
						<Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
						<TextInput onChange={this.handleChange} value={values.total} name="total" type="number" label="" />
					</div>
					<div>
						<Typography variant="subtitle1">{`${field.compliance_case} ${!!field.required ? "*" : ""}`}</Typography>
						<TextInput onChange={this.handleChange} value={values.desfav} name="desfav" type="number" label="" />
					</div>
					<div>
						{values?.solved && <Typography variant='h4'>Cumplimiento Parcial: {compliance.toFixed(1)} %</Typography>}
						<Box style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
							<Checkbox checked={values?.solved} onClick={this.handleCheck} />
							<Typography variant='subtitle1'>Se solucionó el incumplimiento en el lugar?</Typography>
						</Box>
						<Typography variant='h4'>Cumplimiento final: {values?.solved ? 100 : compliance.toFixed(1)} %</Typography>
					</div>
				</div>
				<Collapse in={compliance < 100}>
					<div style={{ marginTop: 12 }}>
						<Typography variant="subtitle1">{`${field.compliance_observation} ${!!field.required ? "*" : ""}`}</Typography>
						<TextInput onChange={this.handleChange} value={values.comment} name="comment" label="" />
					</div>
				</Collapse>
			</>
		)
	}
}

export default withStyles(style)(ComplianceInput)