import React, { Component } from 'react'
import { Button, Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import MySignaturePad from '../Talks/MySignaturePad'
import autobind from '../../Utils/autobind'
import { connect } from 'react-redux'
import { signDocumentAction } from '../../Actions/WorkerActions'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

const style = () => ({
  registeredSign: {
    padding: 12,
    textAlign: 'center',
  }
})

class SignDocument extends Component {
  constructor() {
    super()
    autobind(SignDocument, this)
  }

  gotoSign() {
    const { history, document } = this.props
    const object = btoa(JSON.stringify(document))
    history.push(`/signpdf/${object}`)
  }

  handleSave(value) {
    fetch(value)
      .then(res => res.blob())
      .then(blob => {
        const { signDocument, document, workers, onClose, user, onSubmit } = this.props
        const currentWorker = workers.all.find(worker => worker.id === user.account.user.id)
        const file = new File([blob], "File name", { type: "image/png" })
        const data = new FormData()
        data.append("document_id", document.id)
        data.append("user_id", currentWorker.id)
        data.append("name", currentWorker.name)
        data.append("file", file)
        data.append("user_sign", "")
        data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
        onSubmit === undefined ? signDocument(data) : onSubmit(data)
        onClose()
      })
  }

  handleUseSavedSign() {
    const { workers, signDocument, document, onClose, user, onSubmit } = this.props
    const currentWorker = workers?.all.find(worker => worker.id === user.account.user.id)
    const sign = currentWorker.signs[currentWorker.signs.length - 1]
    const body = new FormData()
    body.append("document_id", document.id)
    body.append("user_id", currentWorker.id)
    body.append("name", currentWorker.name)
    body.append("user_sign", sign.sign)
    body.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
    onSubmit === undefined ? signDocument(body) : onSubmit(body)
    onClose()
  }

  render() {
    const { classes, open, document, onClose, workers, user } = this.props
    // console.log(document)
    const currentWorker = workers.all.find(worker => worker.id === user.account.user.id)
    // if (document?.file.includes(".pdf")) {
    //   this.gotoSign()
    //   return null
    // }
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <div className={classes.container}>
          <div className={classes.registeredSign}>
            <Typography variant="h2">{`Firmando: ${document?.name}`}</Typography>
          </div>
          <div className={classes.signatureContainer}>
            <MySignaturePad
              clearButton="true"
              save={this.handleSave}
              disabled={false}
            />
          </div>
          <Divider />
          <div className={classes.registeredSign}>
            <Button color="primary" variant="contained" onClick={this.handleUseSavedSign} disabled={currentWorker?.signs?.length === 0}>
              Usar firma registrada
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

SignDocument.propTypes = {

}


SignDocument.defaultProps = {
  onSubmit: undefined
}

const mapStateToProps = state => ({
  workers: state.workers,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  signDocument: body => dispatch(signDocumentAction(body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SignDocument)))