import React, { Component } from 'react'
import { Checkbox, Typography, withStyles } from '@material-ui/core'
import { getTags } from '../../API/tags'
import autobind from '../../Utils/autobind'
import { connect } from 'react-redux'

const style = () => ({
	container: {
		padding: 24,
		width: 300
	},
	tag: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	tagChip: {
		padding: '3px 8px',
		borderRadius: 18,
		minWidth: 70,
		textAlign: 'center'
	}
})
class TagsCard extends Component {
	constructor() {
		super()
		this.state = {
			tags: [],
			filters: []
		}
		autobind(TagsCard, this)
	}

	async componentDidMount() {
		const response = await getTags()
		const { data: { info: tags } } = response
		const filtersData = localStorage.getItem("filters")
		const filters = filtersData ? JSON.parse(filtersData) : []
		this.setState({ tags, filters })
	}

	handleSetFilter(value) {
		return () => {
			const { filters } = this.state
			const { enterprise } = this.props
			if (filters.includes(value)) {
				filters.splice(filters.indexOf(value), 1)
			} else {
				filters.push(value)
			}
			localStorage.setItem("filters", JSON.stringify(filters))
			this.setState({ filters })

			const selected = enterprise.selected

			const event = new CustomEvent("branch_change", { detail: { branch: selected } })
			console.log(event)
			window.dispatchEvent(event)
		}
	}

	render() {
		const { tags, filters } = this.state
		console.log(filters)
		const { classes } = this.props
		return (
			<div className={classes.container}>
				<Typography variant='h4'>Filtro por Etiquetas</Typography>
				{tags.map(tag => <Tag tag={tag} classes={classes} filters={filters} onSelect={this.handleSetFilter} />)}
			</div>
		)
	}
}

function Tag({ tag, classes, filters, onSelect }) {
	const isSelected = filters.includes(tag.id)
	return (
		<div className={classes.tag}>
			<div style={{ background: tag.color }} className={classes.tagChip}>
				<Typography variant='subtitle1' style={{ color: 'white' }} >{tag.name}</Typography>
			</div>
			<Checkbox checked={isSelected} onClick={onSelect(tag.id)} />
		</div>
	)
}

const mapStateToProps = state => ({
	enterprise: state.enterprise
})

export default connect(mapStateToProps)(withStyles(style)(TagsCard))