import React, { Component } from 'react'
import { Collapse, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import ChecklistContext from '../ChecklistContext'
import { AddCircle, Clear, FileCopyOutlined, NotificationsActiveOutlined } from '@material-ui/icons'
import TextInput from '../../../Shared/Inputs/TextInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import ImgInput from '../../../Shared/Inputs/ImgInput'
import autobind from '../../../Utils/autobind'
import Subquestions from '../../Checklists/ChecklistComponents/Subquestions'
import NewExtraField from '../../Checklists/NewExtraField'
import instance from '../../../Utils/instance'
import ImageContainer from '../../../Shared/ImageContainer'
import RTE from '../../../Shared/RTE'
import RutInput from '../../../Shared/Inputs/RutInput'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import ComplianceInput from '../../../Shared/Inputs/ComplianceInput'
import PermissionChecker from '../../../Shared/Permissions/PermissionChecker'

const style = () => ({
  container: {
    borderRadius: 12,
    background: 'white',
    padding: 12,
    margin: '12px 0'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  extraTitle: {
    marginBottom: 12
  },
  finishedField: {
    margin: '12px 0',
    '& > *': {
      margin: '6px 0',
      padding: '0 12px',
    },
    '& > h4': {
      padding: '0 12px 12px',
    }
  },
  multigrid: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexBasis: '30%',
      minWidth: 300
    }
  },
  extraField: {
    margin: '24px 6px',
    '& > *:first-child': {
      marginBottom: -6
    }
  },
  group: {
    padding: 12,
    borderRadius: 8,
    margin: '12px 0',
    position: 'relative'
  },
  cloneGroup: {
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  cloneDelete: {
    position: 'absolute',
    top: 0,
    right: 0
  }
})

function renderMessages(options, value) {
  if (!options) return null
  return options.map((option, index) => {
    if (typeof option === "string") {
      const [expected, message, color] = option.split('&')
      return (
        <Collapse key={option.label + index} in={expected === value && message}>
          <Typography style={{ color }} variant="subtitle1">{message}</Typography>
        </Collapse>
      )
    }
    const { label: expected, message, color } = option
    return (
      <Collapse key={option.label + index} in={expected === value && message}>
        <Typography style={{ color }} variant="subtitle1">{message}</Typography>
      </Collapse>
    )

  })
}

function getTotalCounters(checklist) {
  function getFactor(item) {
    const crit = item?.answer?.criticality
    if (crit === 4) return 2
    if (crit === 3) return 1.5
    return 1
  }
  const items = checklist.checklist_items
  const filtered = items
    .filter(item => item.counter === 1 && item.answer.counter !== null)
  if (filtered.length === 0) return 0
  const total = filtered
    .reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
  return total.answer.counter
}

class ExtraFields extends Component {
  constructor() {
    super()
    this.state = {
      extra: {},
      openFields: false,
      extension: []
    }
    autobind(ExtraFields, this)
  }

  handleDeleteImage(field) {
    return (name, index) => {
      const { onAnswerExtra } = this.context
      const imageString = field?.value
      const images = imageString.split("&#&")
      images.splice(index, 1)
      const value = images.join("&#&")
      const newEvent = { target: { name, value } }
      onAnswerExtra(newEvent)
    }
  }

  handleChangeImage(field) {
    return (event) => {
      const { target } = event
      const { onAnswerExtra } = this.context
      const file = target.value

      //Upload File and get the name of the saved one
      const body = new FormData()
      body.append("file", file)
      const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/extra-fields-file.php`
      const oldImages = field?.value
      instance({
        method: 'post',
        url,
        data: body
      }).then(response => {
        if (oldImages) {
          const newEvent = {
            target: {
              name: target.name,
              value: `${oldImages}&#&${response.data?.info?.file_name}`
            }
          }
          return onAnswerExtra(newEvent)
        } else {
          const newEvent = {
            target: {
              name: target.name,
              value: response.data?.info?.file_name
            }
          }
          return onAnswerExtra(newEvent)
        }
      })
    }
  }

  handleAddNewField(body) {
    const { onAddExtraField } = this.context
    onAddExtraField(body)
  }

  handleOpenNewField() {
    this.setState({ openFields: !this.state.openFields })
  }

  handleCloneGroup(group, key, groups) {
    return () => {
      const { onAddExtraField } = this.context

      const matchingKeys = Object.keys(groups).filter(keys => keys.match(`${key}-[0-9].*`)).map(k => k.split("-")[1])
        .sort((a, b) => {
          if (parseInt(a, 10) > parseInt(b, 10)) return 1
          if (parseInt(a, 10) < parseInt(b, 10)) return 1
          return 0
        })
      const latestIndex = parseInt(matchingKeys[matchingKeys.length - 1], 10)

      group.forEach(field => {
        const newField = { ...field }
        newField.value = ""
        newField.group_index = latestIndex + 1
        onAddExtraField(newField)
      })
    }
  }

  handleDeleteGroup(group, key, groups) {
    return () => {
      const { onUpdateExtraFields } = this.context
      delete groups[key]
      const newFields = Object.values(groups).flat()
      onUpdateExtraFields(newFields)
    }
  }

  renderSubquestion(options, answer, field) {
    const { checklist, disabled } = this.props
    if (!options) return null
    const selectedOption = options.find(option => option.label === answer)
    const hasSubquestions = selectedOption?.subquestions?.length > 0
    if (!hasSubquestions) return null
    const subquestions = selectedOption.subquestions
    return subquestions.map((question, index) => {
      return (
        <Subquestions
          option={selectedOption}
          question={question}
          index={index}
          selected={checklist}
          field={field}
          disabled={disabled} />
      )
    })
  }

  renderFields() {
    const { classes, disabled } = this.props
    const { checklist, onAnswerExtra, fields } = this.context
    const { extension } = this.state
    function transofrmToExtraOptions(options) {
      if (!options) return []
      return options.map(option => {
        if (typeof option === "string") {
          return {
            label: option.split('&')[0],
            value: option.split('&')[0]
          }
        }
        return { label: option.label, value: option.label }
      })
    }

    const extrafields = fields || []

    const categories = fields[0]?.categories || []

    const groups = {}
    extrafields.forEach(ef => {
      const efCategory = !!ef.category ? ef.category : false
      const categoryInfo = categories.find(cat => cat.value === efCategory)
      const groupIndex = ef.group_index || 0
      const groupName = `${efCategory}-${groupIndex}-${categoryInfo?.label}-${categoryInfo?.color}`
      if (Object.keys(groups).includes(groupName)) {
        groups[groupName].push(ef)
      } else {
        groups[groupName] = [ef]
      }
    })

    return Object.entries(groups).sort((a, b) => {
      if (b[0].includes("Sin Categoría")) return 1
      if (a[0].includes("Sin Categoría")) return 1
      if (a[0].split("-")[0] > b[0].split("-")[0]) return 1
      if (a[0].split("-")[0] < b[0].split("-")[0]) return 1
      if (parseInt(a[0].split("-")[1], 10) > parseInt(b[0].split("-")[1], 10)) return 1
      if (parseInt(a[0].split("-")[1], 10) < parseInt(b[0].split("-")[1], 10)) return 1
      return 0
    }).map(([key, group]) => {
      if (!group.length) return null
      const [categoryValue, groupIndex, label, color] = key.split("-")
      let fieldsToRender = group
      if (label === "Sin Categoría") {
        fieldsToRender = group.concat(extension)
      }
      const groupFields = fieldsToRender.map((field) => {
        const index = field.index
        switch (field.type) {
          case "text": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <TextInput
                  disabled={disabled}
                  name={index}
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={""}
                />
              </div>
            )
          }
          case "rut": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <RutInput
                  disabled={disabled}
                  name={index}
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={""}
                />
              </div>
            )
          }
          case "compliance": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <ComplianceInput
                  disabled={disabled}
                  name={index}
                  field={field}
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={""}
                />
              </div>
            )
          }
          case "rte": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography style={{ marginBottom: 8 }} variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <RTE
                  disabled={disabled}
                  name={index}
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={""}
                />
              </div>
            )
          }
          case "select": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <SelectInput
                  disabled={disabled}
                  name={index}
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={""}
                  options={transofrmToExtraOptions(field.options)}
                  big
                />
                {renderMessages(field.options, field?.value)}
                {this.renderSubquestion(field.options, field?.value, field)}
              </div>
            )
          }
          case "multiselect": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <NewMultiSelectInput
                  hasComments
                  disabled={disabled}
                  name={index}
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={""}
                  options={transofrmToExtraOptions(field.options)}
                  withText
                />
                {renderMessages(field.options, field?.value)}
                {this.renderSubquestion(field.options, field?.value, field)}
              </div>
            )
          }
          case "img": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <ImgInput
                  disabled={disabled}
                  name={index}
                  value={field?.value}
                  onChange={this.handleChangeImage(field)}
                  onDelete={this.handleDeleteImage(field)}
                  label={""}
                  url={`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`}
                />
              </div>
            )
          }
          case "ids": {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <TextInput
                  disabled={disabled}
                  name={index}
                  type="number"
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={"Cantidad de trabajadores"}
                />
                <Typography variant="subtitle1">Eventos Ponderados: {getTotalCounters(checklist)}</Typography>
                <Typography variant="subtitle1" style={{ marginBottom: 12 }}>Resultado: {field?.value ? (getTotalCounters(checklist) * 100) / field?.value : 0}</Typography>
              </div>
            )
          }
          default: {
            return (
              <div key={field.label + index} className={classes.extraField}>
                <Divider style={{ margin: '8px 0' }} />
                <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
                <TextInput
                  disabled={disabled}
                  name={index}
                  value={field?.value}
                  onChange={onAnswerExtra}
                  label={""}
                />
              </div>
            )
          }
        }
      })
      const isMainCategory = categoryValue !== "false"
      const isCopiedCategory = groupIndex !== "0"
      return (
        <div style={{ background: color }} className={isMainCategory ? classes.group : ""}>
          {isMainCategory && <Typography variant='h4'>{parseInt(groupIndex, 10) + 1}. {label}</Typography>}
          {groupFields}
          {isMainCategory &&
            <>
              <IconButton className={classes.cloneGroup} onClick={this.handleCloneGroup(group, categoryValue, groups)}>
                <FileCopyOutlined />
              </IconButton>
              <>
                {isCopiedCategory &&
                  <IconButton className={classes.cloneDelete} onClick={this.handleDeleteGroup(group, key, groups)}>
                    <Clear />
                  </IconButton>
                }
              </>
            </>
          }
        </div>
      )
    })
  }

  renderFinishedSubquestions(field) {
    const { classes } = this.props
    if (!field.options || field.options.length === 0) return null
    const selectedOption = field.options.find(option => option.label === field.value)
    if (!selectedOption) return null
    const { subquestions = [] } = selectedOption
    if (subquestions.length === 0) return null
    return subquestions.map(question => {
      let value = <Typography variant="h4">{question?.value || "Sin respuesta"}</Typography>
      if (question.value)
        if (question.type === "img") {
          let imgs = !!question.value ? question.value.split("&#&") : []
          value = imgs.map(img => <ImageContainer
            src={question?.value ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${img}` : "/noimage.png"}
            height={64}
            width={64}
            border={12}
          />
          )
        }
      if (question.type === "rte") {
        value = <div id={`${question.label}-rte`} dangerouslySetInnerHTML={{ __html: question.value }}></div>
      }
      if (question.type === "compliance") {
        const values = question.value ? JSON.parse(question.value) : {}
        const des = values.desfav
        const compliance = Math.round(100 - ((des * 100) / values.total))
        value = (<div>
          <Typography variant='subtitle1'>{`${question.label}: `}{<span style={{ fontWeight: 600 }}>{values.total || 0}</span>}</Typography>
          <Typography variant='subtitle1'>{`${question.compliance_case}: `}{<span style={{ fontWeight: 600 }}>{values.desfav || 0}</span>}</Typography>
          <Typography variant='subtitle1'>{`Cumplimiento: `}<strong>{compliance}%</strong></Typography>
          {compliance < 100 && <Typography variant='subtitle1'>{`${question?.compliance_observation}: `}<strong>{values.comment || "Sin observación"}</strong></Typography>}
        </div>)

      }
      if (question.type === "multiselect") {
        if (Array.isArray(question.value)) {
          const values = question.value.map((val, index) => {
            const [name, comment] = val.split(":")
            return (
              <div style={{ marginLeft: 12 }} key={val}>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>{index + 1}. {name}</Typography>
                <Typography variant="subtitle1" style={{ marginLeft: 12 }}>{comment && comment !== "undefined" ? `Comentarios: ${comment}` : ""}</Typography>
              </div>
            )
          })
          value = (
            <div className={classes.multigrid}>
              {values}
            </div>
          )
        }
      }
      return (
        <div style={{ paddingLeft: 12 }}>
          <div className={classes.finishedField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            {value}
            {this.renderFinishedSubquestions(question)}
          </div>
        </div>
      )
    })
  }

  renderFinishedFields() {
    const { classes } = this.props
    const { checklist: { extra_fields: fields = [] }, checklist } = this.context
    const extrafields = fields || []
    const categories = fields[0]?.categories || []

    const groups = {}
    extrafields.forEach(ef => {
      const efCategory = !!ef.category ? ef.category : false
      const categoryInfo = categories.find(cat => cat.value === efCategory)
      const groupIndex = ef.group_index || 0
      const groupName = `${efCategory}-${groupIndex}-${categoryInfo?.label}-${categoryInfo?.color}`
      if (Object.keys(groups).includes(groupName)) {
        groups[groupName].push(ef)
      } else {
        groups[groupName] = [ef]
      }
    })

    return Object.entries(groups).sort((a, b) => {
      if (b[0].includes("Sin Categoría")) return 1
      if (a[0].includes("Sin Categoría")) return 1
      if (a[0] > b[0]) return 1
      if (a[0] < b[0]) return -1
      return 0
    }).map(([key, group]) => {
      if (!group.length) return null
      const [categoryValue, groupIndex, label, color] = key.split("-")
      let fieldsToRender = group
      const groupFields = fieldsToRender.map((field) => {
        const index = field.index
        let value = <Typography variant="h4">{field?.value || "Sin respuesta"}</Typography>
        if (field.value)
          if (field.type === "img") {
            let imgs = !!field.value ? field.value.split("&#&") : []
            value = imgs.map(img => <ImageContainer
              src={field?.value ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${img}` : "/noimage.png"}
              height={64}
              width={64}
              border={12}
            />
            )
          }
        if (field.type === "rte") {
          value = <div id={`${field.label}-rte`} dangerouslySetInnerHTML={{ __html: field.value }}></div>
        }
        if (field.type === "compliance") {
          const values = field.value ? JSON.parse(field.value) : {}
          const des = values.desfav
          const compliance = Math.round(100 - ((des * 100) / values.total))
          value = (<div>
            <Typography variant='subtitle1'>{`${field.label}: `}{<span style={{ fontWeight: 600 }}>{values.total || 0}</span>}</Typography>
            <Typography variant='subtitle1'>{`${field.compliance_case}: `}{<span style={{ fontWeight: 600 }}>{values.desfav || 0}</span>}</Typography>
            <Typography variant='subtitle1'>{`Cumplimiento: `}<strong>{compliance}%</strong></Typography>
            {compliance < 100 && <Typography variant='subtitle1'>{`${field?.compliance_observation}: `}<strong>{values.comment || "Sin observación"}</strong></Typography>}
          </div>)

        }
        if (field.type === "multiselect") {
          if (Array.isArray(field.value)) {
            const values = field.value.map((val, index) => {
              const [name, comment] = val.split(":")
              return (
                <div style={{ marginLeft: 12 }} key={val}>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>{index + 1}. {name}</Typography>
                  <Typography variant="subtitle1" style={{ marginLeft: 12 }}>{comment && comment !== "undefined" ? `Comentarios: ${comment}` : ""}</Typography>
                </div>
              )
            })
            value = (
              <div className={classes.multigrid}>
                {values}
              </div>
            )
          }
        }

        return (
          <div className={classes.finishedField} >
            {index > 0 && <Divider style={{ marginBottom: 18 }} />}
            <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
            {value}
            {field.type === "ids" &&
              <>
                <Typography variant="subtitle1">Eventos Ponderados: {getTotalCounters(checklist)}</Typography>
                <Typography variant="subtitle1">Resultado: {field?.value ? (getTotalCounters(checklist) * 100) / field?.value : 0}</Typography>
              </>
            }
            {this.renderFinishedSubquestions(field)}
          </div>
        )
      })
      const isMainCategory = categoryValue !== "false"
      return (
        <div style={{ background: color }} className={isMainCategory ? classes.group : ""}>
          {isMainCategory && <Typography variant='h4'>{parseInt(groupIndex, 10) + 1}. {label}</Typography>}
          {groupFields}
        </div>
      )
    })
  }

  render() {
    const { classes, disableNew = false, disabled, disableAlert } = this.props
    const { openFields } = this.state
    const { finished, checklist: { extra_fields: fields = [] }, onGlobalNotify } = this.context
    const isFinished = finished || disabled
    if (finished && !fields) return null
    return (
      <div className={classes.container}>
        <Typography variant="h4" className={classes.extraTitle}>Información {isFinished ? "ingresada" : "a completar"}</Typography>
        <div className={classes.fields}>
          {isFinished ? this.renderFinishedFields() : this.renderFields()}
          {!isFinished && !disableNew &&
            <>
              <Divider style={{ margin: '12px 0' }} />
              {/* <Button style={{ width: '100%', marginTop: 24, padding: 12 }} onClick={this.handleOpenNewField} variant="outlined">
                <div className={classes.inline} style={{ width: '100%' }}>
                  <Typography variant="subtitle1">
                    Agregar campo
                  </Typography>
                  <AddCircle color="secondary" />
                </div>
              </Button> */}
              <div className={classes.inline}>
                <PermissionChecker expectedPermissions={["aef"]}>
                  <IconButton onClick={this.handleOpenNewField}>
                    <AddCircle color="secondary" />
                  </IconButton>
                </PermissionChecker>
                {!disableAlert &&
                  <IconButton onClick={onGlobalNotify}>
                    <NotificationsActiveOutlined />
                  </IconButton>
                }
              </div>
            </>
          }
          <NewExtraField onSubmit={this.handleAddNewField} open={openFields} onClose={this.handleOpenNewField} />
        </div>
      </div>
    )
  }
}

ExtraFields.contextType = ChecklistContext

export default withStyles(style)(ExtraFields)