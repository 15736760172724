import React, { Component } from "react"
import { TextField } from "@material-ui/core"
import autobind from "../../Utils/autobind"

export default class TextInput extends Component {
    constructor() {
        super()
        autobind(TextInput, this)
    }
    handleChange(event) {
        const { onChange, input } = this.props
        onChange(event)
        const { target } = event
        if (input?.label?.toLowerCase() === "Nombres y Apellidos".toLowerCase()) {
            const newEvent = new CustomEvent("userbyid", { detail: { name: target.value } })
            window.dispatchEvent(newEvent)
        }
    }
    render() {
        const { value, label, name, type, disabled, options, required, onBlur, autoFocus } = this.props
        return (
            <TextField
                value={value || ""}
                disabled={disabled}
                onChange={this.handleChange}
                label={`${label}${required ? "*" : ""}`}
                name={String(name)}
                variant="outlined"
                fullWidth
                style={{ margin: "12px 0" }}
                InputLabelProps={{
                    shrink: true,
                }}
                type={type}
                rows={options.rows}
                onBlur={onBlur}
                autoFocus={autoFocus}
                multiline={options.multiline || false}
                placeholder={options.placeholder}
            />
        )
    }
}

TextInput.propTypes = {

}


TextInput.defaultProps = {
    options: {}
}