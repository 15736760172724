import { HelpOutline } from '@material-ui/icons'
import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { createBasefield, createFieldOption, deleteBasefield, deleteFieldOption, editBaseField, editFieldCategory, editFieldOption, moveFieldCategory } from '../../../../API/base_checklists'
import { getSettingsChecklist } from '../../../../API/checklists'
import { createSubtitle, setChecklistSupplyTypes, switchOrder } from '../../../../API/settings_checklist'
import { getSettingsSupplies } from '../../../../API/supplies'
import MultiSelectInput from '../../../../Shared/Inputs/MultiSelectInput'
import BaseChecklistAuthorizations from '../SingleChecklist/BaseChecklistAuthorizations'
import BaseConfig from '../SingleChecklist/BaseConfig'
import ChangeSections from '../SingleChecklist/ChangeSections'
import ChecklistNotifications from '../SingleChecklist/ChecklistNotifications'
import ChecklistSupplyTypes from '../SingleChecklist/ChecklistSupplyTypes'
import ChecklistItemsSetup from './ChecklistItemsSetup'
import ExtraFieldsSetup from './ExtraFieldsSetup'

const css = {
	container: {
		padding: 3,
		maxWidth: 1200
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	section: {
		padding: 2,
		'& > div': {
			margin: '0 !important'
		},
		flexGrow: 1,
		flexBasis: 600,
		maxWidth: 1000
	},
	mainSection: {
		margin: '24px 0'
	},
	content: {
		display: 'flex',
		gap: 4,
		flexWrap: 'wrap',
	},
	sectionsContainer: {
		display: 'flex',
		gap: 4,
		flexWrap: 'wrap',
	}
}

export const SetupChecklistContext = createContext({})

function ChecklistSetup({ value, match }) {
	const { id } = match.params
	const [checklist, setChecklist] = useState({})
	const [supplies, setSupplies] = useState([])

	useEffect(() => {
		async function fetchData() {
			const responses = await Promise.all([getSettingsChecklist({ id }), getSettingsSupplies()])
			const datas = responses.map(r => r.data.info)
			setChecklist(datas[0])
			setSupplies(datas[1])
		}

		fetchData()
	}, [])

	async function onEdit(body) {
		console.log(body)
	}

	async function createCategory() {
		const response = await createSubtitle({ id })
		setChecklist(response.data.info)
	}

	async function editCategory(body) {
		const response = await editFieldCategory(body)
		setChecklist(response.data.info)
	}

	async function createField(body) {
		const response = await createBasefield(body)
		setChecklist(response.data.info)
	}

	async function editField(body) {
		const response = await editBaseField(body)
		setChecklist(response.data.info)
	}

	async function deleteField(body) {
		const response = await deleteBasefield(body)
		setChecklist(response.data.info)
	}

	async function createOption(body) {
		const response = await createFieldOption(body)
		setChecklist(response.data.info)
	}

	async function editOption(body) {
		const response = await editFieldOption(body)
		setChecklist(response.data.info)
	}

	async function deleteOption(body) {
		const response = await deleteFieldOption(body)
		setChecklist(response.data.info)
	}

	async function changeFieldCategory(body) {
		const response = await moveFieldCategory(body)
		setChecklist(response.data.info)
	}

	async function switchChecklistOrder(body) {
		body.id = checklist.id
		const response = await switchOrder(body)
		setChecklist(response.data.info)
	}

	async function ChangeSupplyTypes(values) {
		const body = { types: values, id }
		const response = await setChecklistSupplyTypes(body)
		setChecklist(response.data.info)
	}

	return (
		<Box sx={css.container}>
			<Box sx={css.header}>
				<Typography variant='h1'>Configuración {checklist.name}</Typography>
				<BaseConfig checklist={checklist} />
			</Box>
			<Box sx={css.sectionsContainer}>
				<Box style={{ maxWidth: 800, flexGrow: 1, flexBasis: 500 }}>
					<Box sx={css.mainSection}>
						<Paper sx={css.section} >
							<ChecklistSupplyTypes supplies={supplies} onSelectTypes={ChangeSupplyTypes} selectedValues={checklist?.supply_types || []} />
						</Paper>
					</Box>
					<SetupChecklistContext.Provider value={{
						checklist,
						setChecklist,
						editField,
						editCategory,
						createField,
						createOption,
						editOption,
						changeFieldCategory,
						deleteOption,
						deleteField
					}}>
						<Box sx={css.mainSection}>
							<Box sx={css.content}>
								<Paper sx={css.section}>
									<Box sx={css.header}>
										<Typography variant='h4'>Items del registro</Typography>
										<Box>
											<Button color="secondary" variant="outlined" size="small" onClick={createCategory}>
												Crear Categoría
											</Button>
											<IconButton>
												<HelpOutline />
											</IconButton>
										</Box>
									</Box>
									<ChecklistItemsSetup />
								</Paper>
								<Paper sx={css.section}>
									<Box sx={css.header}>
										<Typography variant='h4'>Formulario del registro</Typography>
										<IconButton>
											<HelpOutline />
										</IconButton>
									</Box>
									<ExtraFieldsSetup />
								</Paper>
							</Box>
						</Box>
					</SetupChecklistContext.Provider>
				</Box>
				<Box style={{ maxWidth: 800, flexBasis: 400 }}>
					<Box sx={css.mainSection}>
						<Paper sx={css.section}>
							<BaseChecklistAuthorizations checklist={checklist} />
						</Paper>
					</Box>
					<Box sx={css.mainSection}>
						<Paper sx={css.section}>
							<ChecklistNotifications checklist={checklist} />
						</Paper>
					</Box>
					<Box>
						<ChangeSections onSwitch={switchChecklistOrder} order={checklist?.invert_order} />
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default withRouter(ChecklistSetup)