import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export function getSubEnterprises() {
	return instance2({
		method: 'get',
		url: 'company/get_sub_enterprises.php'
	})
}

export function editSubEnterprise(body) {
	return instance({
		method: 'post',
		url: 'company/edit_sub_enterprise.php',
		data: body
	})
}

export function createSubEnterprise(body) {
	return instance({
		method: 'post',
		url: 'company/create_sub_enterprise.php',
		data: body
	})
}

export function changeSubEnterpriseLogo(body) {
	return instance({
		method: 'post',
		url: 'company/sub_enterprise_logo.php',
		data: body
	})
}

export const changeOrderRule = () => {
	return instance2({
		method: 'put',
		url: 'enterprises/order'
	})
}

export const getOrderRule = () => {
	return instance2({
		method: 'get',
		url: 'enterprises/order'
	})
}

export const getWelcome = body => {
	return instance2({
		method: 'get',
		url: 'enterprises/welcome',
		params: body
	})
}

export const setWelcome = body => {
	return instance2({
		method: 'put',
		url: 'enterprises/welcome',
		data: body
	})
}

export const csv_to_excel = body => {
	return instance2({
		responseType: "blob",
		method: 'post',
		url: 'excel',
		data: body
	})
}

export const getFastFindingStatus = () => {
	return instance2({
		method: 'get',
		url: 'enterprises/fast_findings'
	})
}

export const setFastFindingStatus = () => {
	return instance2({
		method: 'put',
		url: 'enterprises/fast_findings'
	})
}