import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Dialog, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getParents } from '../../API/subdivisions'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'

const css = {
	dialog: {
		padding: 3,
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap'
	}
}

function SubdivisionInfoDialog({ subdivision, open, onClose, branch_name }) {

	const [parents, setParents] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			const response = await getParents({ id: subdivision?.id })
			setParents(response.data.info)
			setLoading(false)
		}

		if (!!subdivision) { fetchData() }

	}, [subdivision])

	const parents_bread = parents.reverse()

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={css.dialog}>
				<Typography variant='h4'>{branch_name}</Typography>
				{loading ?
					<MiniLoaderAnimator /> :
					parents_bread.map((subd, index) => (
						<>
							<KeyboardArrowRight style={{ margin: '0 6px ' }} />
							<Typography variant='h4'>{subd?.name}</Typography>
						</>
					))
				}
			</Box>
		</Dialog>
	)
}

export default SubdivisionInfoDialog