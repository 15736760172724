import { AssignmentTurnedInOutlined, Delete, KeyboardArrowRight } from '@material-ui/icons'
import { Box, Collapse, Tab, Tabs, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { createTalk, deleteTalk, getAllTalks } from '../../API/talks'
import AppContext from '../../AppContext'
import useToggle from '../../Hooks/ToogleHook'
import useTabs from '../../Hooks/UseTabs'
import AddElementButton from '../../Shared/AddElementButton'
import DownloadButton from '../../Shared/DownloadButton'
import GeneralTable from '../../Shared/GeneralTable'
import LoadingDialog from '../../Shared/LoadingDialog'
import SignedCell from '../../Shared/TableRenders/SignedCell'
import { inline_space, text_space } from '../../Utils/defaultStyles'
import { addQrToFile, fetchFunction, mergeMultiplePdfFilesAndGet } from '../../Utils/functions'
import TalkForm from './TalkForm'

const css = {
	tabs: {
		background: 'white',
		borderRadius: 2,
		margin: '12px 0'
	}
}

const tableInfo = [
	{ name: "Fecha Programación", label: "date_scheduled", format: (value) => moment(value).format("YYYY-MM-DD") },
	{ name: "Tipo", label: "category_name" },
	{ name: "Tema", label: "name" },
	{ name: "Responsable", label: "user_name" },
	{ name: AssignmentTurnedInOutlined, label: "all", render: SignedCell },
]

const doneTableInfo = [
	{ name: "Fecha Realizado", label: "date_saved", format: (value) => moment(value).format("YYYY-MM-DD") },
	{ name: "Tipo", label: "category_name" },
	{ name: "Tema", label: "name" },
	{ name: "Responsable", label: "user_name" },
	{ name: "Participantes", label: "participants_signs", format: (value) => value.length }
]

function isFinished(talk) { return !moment(talk.date_saved).format("YYYY-MM-DD").includes("Fecha") }
function isPending(talk) { return !isFinished(talk) }

function TalksList() {

	const [talks, setTalks] = useState([])
	const [openCreate, toggleCreate] = useToggle(false)
	const [tab, setTab] = useTabs(0)
	const { account, history } = useContext(AppContext)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		fetchFunction(getAllTalks, setTalks)()
	}, [])

	async function onCreate(body) {
		setLoading(true)
		const response = await createTalk(body)
		setTalks(t => t.concat(response.data.info))
		setLoading(false)
	}

	function openTalk(row) {
		return () => {
			history.push(`talks/${row.id}`)
		}
	}

	function downloadTalk(row, params) {
		return async () => {
			const file = row.file
			const url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_PDF}?id=${row.id}&u=${account.user.id}`
			const parsed_url = new URL(url1)
			if (params) {
				Object.keys(params).forEach(key => {
					parsed_url.searchParams.append(key, params[key])
				})
			}
			console.log(parsed_url.toString())
			const final_url = parsed_url.toString()
			const url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file
			if (file.includes(".pdf")) {
				const signedFile = await mergeMultiplePdfFilesAndGet([final_url, url2], "Reporte Charla")
				const code = `${row.id}--k`
				const validateUrl = `${window.location.origin}/validate/${code}`
				return addQrToFile(signedFile, "Reporte Charla", validateUrl)
			} else {
				const signedFile = await mergeMultiplePdfFilesAndGet([final_url], "Reporte Charla")
				const code = `${row.id}--k`
				const validateUrl = `${window.location.origin}/validate/${code}`
				return addQrToFile(signedFile, `${row.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}`, validateUrl)
			}
		}
	}

	function onDelete(row) {
		return async () => {
			setLoading(true)
			await deleteTalk({ id: row.id })
			const newTalks = [...talks]
			const index = newTalks.findIndex(t => t.id === row.id)
			newTalks.splice(index, 1)
			setTalks(newTalks)
			setLoading(false)
		}
	}

	const level = account.user.userType

	const pendingTalks = useMemo(() => talks.filter(isPending), [talks])
	const doneTalks = useMemo(() => talks.filter(isFinished), [talks])

	const pendingActions = [
		{ name: "Ver Charla", icon: KeyboardArrowRight, action: openTalk },
	]
	const doneActions = [
		{ name: "Ver Charla", icon: KeyboardArrowRight, action: openTalk },
		{ name: "Descargar Resumen", component: DownloadButton, action: downloadTalk, color: "primary" },
	]

	const deleteAction = { name: "Eliminar Charla", icon: Delete, action: onDelete }
	if (level === 1) {
		pendingActions.push(deleteAction)
	}

	return (
		<Box>
			<LoadingDialog open={loading} />
			<Box sx={inline_space}>
				<Typography variant='h1'>Charlas</Typography>
				<AddElementButton open={openCreate} onClick={toggleCreate} name="Programar Charla" />
			</Box>
			<Collapse in={openCreate}>
				<TalkForm onCreate={onCreate} />
			</Collapse>
			<Box sx={css.tabs}>
				<Tabs value={tab} onChange={setTab}>
					<Tab label="Programadas" />
					<Tab label="Realizadas" />
				</Tabs>
			</Box>
			{tab === 0 &&
				<Box>
					<Typography sx={text_space} variant='h4'>Charlas Programadas</Typography>
					<GeneralTable info={tableInfo} data={pendingTalks} actions={pendingActions} />
				</Box>
			}
			{tab === 1 &&
				<Box>
					<Typography sx={text_space} variant='h4'>Charlas Terminadas</Typography>
					<GeneralTable info={doneTableInfo} data={doneTalks} actions={doneActions} />
				</Box>
			}
		</Box>
	)
}

export default TalksList