import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import SelectField from './ReportFields/SelectField'
import MainField from './MainField'
import TextFields from './ReportFields/TextFields'
import MultipleField from './ReportFields/MultipleField'
import ImgField from './ReportFields/ImgField'
import IDSField from './ReportFields/IDSField'

const style = () => ({
  container: {
    '& > h4': {
      padding: '24px 12px 12px'
    }
  },
})

class ExtrafieldReport extends Component {
  renderFields() {
    const { fields, data } = this.props
    return fields.map((field, index) => {
      if (field.type === "select") return (
        <MainField field={field}>
          <SelectField data={data} key={index} index={index} field={field} />
        </MainField>
      )
      if (field.type === "text") return (
        <MainField field={field}>
          <TextFields data={data} key={index} index={index} field={field} />
        </MainField>
      )
      if (field.type === "multiselect") return (
        <MainField field={field}>
          <MultipleField data={data} key={index} index={index} field={field} />
        </MainField>
      )
      if (field.type === "img") return (
        <MainField field={field}>
          <ImgField data={data} key={index} index={index} field={field} />
        </MainField>
      )
      if (field.type === "ids") return (
        <MainField field={field}>
          <IDSField data={data} key={index} index={index} field={field} />
        </MainField>
      )
      return <Typography variant="subtitle1">{field.label}</Typography>
    })
  }

  render() {
    const { classes, fields } = this.props
    return (
      <div className={classes.container}>
        {fields.length > 0 && <Typography variant="h4">Distribución de respuestas a información extra</Typography>}
        {this.renderFields()}
      </div>
    )
  }
}

export default withStyles(style)(ExtrafieldReport)