import { Box, Divider, Paper, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		margin: '24px 0',
	}
}

function SecondaryInfo() {
	const { checklist } = useContext(ChecklistContext)

	useEffect(() => {
		const element = document.getElementById('html-description')
		if (!element) return null
		element.innerHTML = checklist?.preventive_measure_description

	}, [checklist])

	return (
		<Box>
			<Box sx={css.container}>
				<Typography variant='h1'>{checklist?.activity}</Typography>
				<Typography variant='caption'>{checklist?.preventive_measure}</Typography>
			</Box>
			{checklist?.preventive_measure_description &&
				<Box sx={css.container}>
					<Typography variant='h4'>Descripción</Typography>
					<Box id="html-description"></Box>
				</Box>
			}
			<Divider />
		</Box>
	)
}

export default SecondaryInfo