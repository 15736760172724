import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { getSettingsChecklist } from '../../../../../API/checklists'
import SelectInput from '../../../../../Shared/Inputs/SelectInput'
import TextInput from '../../../../../Shared/Inputs/TextInput'
import { transformToOptions } from '../../../../../Utils/functions'

function ChecklistStepCreate({ params, onChange }) {

	const [baseChecklists, setChecklists] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getSettingsChecklist()
			setChecklists(response.data.info)
		}
		fetchData()
	}, [])

	return (
		<Box>
			<TextInput name="label" value={params.label} onChange={onChange} label="Título" />
			<SelectInput name="base_checklist_id" value={params.base_checklist_id} onChange={onChange} label="Seleccione tipo de registro" options={transformToOptions(baseChecklists)} />
		</Box>
	)
}

export default ChecklistStepCreate