import React, { Component } from 'react'
import { Collapse, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import { Delete, Edit, KeyboardArrowDown } from '@material-ui/icons'
import EditField from './EditField'
import { editSettingsFinding, editSettingsNonConformity } from '../../../API/settings'
import { callSnackbar } from '../../../Utils/snackbar'

const style = () => ({
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const types = {
  text: "Texto",
  select: "Selección",
  date: "Fecha",
  multiselect: "Selección múltiple"
}

class FindingField extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      edit: false
    }
    autobind(FindingField, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleEdit() {
    this.setState({ edit: !this.state.edit })
  }

  async handleDelete() {
    const { index, finding, reload, nonconf } = this.props
    const newFields = [...finding.extra_fields]
    newFields.splice(index, 1)
    finding.extra_fields = newFields
    if (nonconf) {
      await editSettingsNonConformity(finding)
    } else {
      await editSettingsFinding(finding)
    }
    callSnackbar("Eliminado correctamente", "success")
    reload()
  }

  render() {
    const { classes, field, index, finding, reload, nonconf } = this.props
    const { open, edit } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.main}>
          <div>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>{`${index + 1}. ${field.text}`}</Typography>
            <Typography variant="caption">Tipo: {types[field.type]}</Typography>
          </div>
          <div>
            <IconButton onClick={this.handleEdit}>
              <Edit />
            </IconButton>
            <IconButton onClick={this.handleDelete}>
              <Delete />
            </IconButton>
            <IconButton disabled={field?.options?.length === 0} onClick={this.handleOpen}>
              <KeyboardArrowDown />
            </IconButton>
          </div>
        </div>
        {field?.options?.length > 0 &&
          <Collapse in={open}>
            <div style={{ margin: 12 }}>
              {field.options.map(option => {
                return <Typography variant="subtitle1" key={option}>- {option}</Typography>
              })}
            </div>
          </Collapse>
        }
        <Collapse in={edit}>
          <EditField field={field} index={index} finding={finding} reload={reload} onClose={this.handleEdit} nonconf={nonconf} />
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(FindingField)