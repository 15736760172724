import { Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'

function TableBodyCells({ data, info, actions, selectable, selected = [], onSelect }) {
	return data.map(element => {
		let cells = []
		const body_cells = info.map(header => {
			return (<TableCell>{element[header.label]}</TableCell>)
		})
		const checked = selected.includes(element.id)
		if (selectable) {
			cells.push(<TableCell><Checkbox checked={checked} onClick={() => onSelect(element?.id)} /></TableCell>)
		}
		cells = cells.concat(body_cells)
		if (!!actions.length) {
			cells.push(
				<TableCell>
					{actions.map(action => (
						<Tooltip title={<Typography style={{ color: 'white' }} variant='subtitle1'><strong>{action?.name}</strong></Typography>}>
							<IconButton onClick={action?.action(element)}>
								<action.icon />
							</IconButton>
						</Tooltip>
					))}
				</TableCell>
			)
		}
		return <TableRow>{cells}</TableRow>
	})
}

export default TableBodyCells