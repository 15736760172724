import React, { Component } from "react"
import { withStyles, Tabs, IconButton, Typography } from "@material-ui/core"
import autobind from "../Utils/autobind"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"

const style = () => ({
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    flex: {
        overflow: 'auto'
    }
})

class MyTabs extends Component {
    constructor(props) {
        super(props)
        autobind(MyTabs, this)
    }

    renderMobile() {
        const { value } = this.props
        const child = this.props.children[value]
        const htmlChild = React.cloneElement(child)
        return (
            <Typography variant="subtitle2">
                {htmlChild.props.label.props.children}
            </Typography>
        )
    }

    handleNext() {
        const { onChange, value } = this.props
        const newValue = (value + 1) % this.props.children.length
        onChange("", newValue)
    }

    handlePrev() {
        const { onChange, value } = this.props
        const newValue =
            (value - 1 + this.props.children.length) %
            this.props.children.length
        onChange("", newValue)
    }

    render() {
        const { value, onChange, classes } = this.props
        const isMobile = window.innerWidth < 500
        return (
            <>
                {isMobile ? (
                    <div className={classes.inline}>
                        <IconButton onClick={this.handlePrev}>
                            <KeyboardArrowLeft />
                        </IconButton>
                        {this.renderMobile()}
                        <IconButton onClick={this.handleNext}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </div>
                ) : (
                    <Tabs
                        value={value}
                        onChange={onChange}
                        indicatorColor="primary"
                        classes={{ flexContainer: classes.flex }}
                    >
                        {this.props.children}
                    </Tabs>
                )}
            </>
        )
    }
}

export default withStyles(style)(MyTabs)
