import React, { Component } from 'react'
import { Collapse, Divider, Typography, withStyles } from '@material-ui/core'
import ImageContainer from '../../Shared/ImageContainer'
import autobind from '../../Utils/autobind'
import ArrowButton from '../../Shared/ArrowButton'

const style = () => ({
  container: {
    padding: 24
  },
  finishedField: {
    margin: '12px 0',
    '& > *': {
      margin: '6px 0'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

class ExtraFieldContainer extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(ExtraFieldContainer, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  renderFinishedSubquestions(field) {
    const { classes } = this.props
    if (!field.options || field.options.length === 0) return null
    const selectedOption = field.options.find(option => option.label === field.value)
    if (!selectedOption) return null
    const { subquestions = [] } = selectedOption
    if (subquestions.length === 0) return null
    return subquestions.map(question => {
      return (
        <div style={{ paddingLeft: 12 }}>
          <div className={classes.finishedField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <Typography variant="h4">{question?.value || "Sin respuesta"}</Typography>
            {this.renderFinishedSubquestions(question)}
          </div>
        </div>
      )
    })
  }

  renderFinishedFields(fields) {
    const { classes } = this.props
    const extrafields = fields || []
    return extrafields.map((field, index) => {
      let value = <Typography variant="h4">{field?.value || "Sin respuesta"}</Typography>
      if (field.type === "img") {
        value = <ImageContainer
          src={field?.value ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${field?.value}` : "/noimage.png"}
          height={64}
          width={64}
          border={12}
        />
      }
      if (field.type === "rte") {
        value = <div id={`${field.label}-rte`} dangerouslySetInnerHTML={{ __html: field.value }}></div>
      }

      return (
        <div className={classes.finishedField}>
          {index > 0 && <Divider style={{ marginBottom: 18 }} />}
          <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
          {value}
          {this.renderFinishedSubquestions(field)}
        </div>
      )
    })
  }

  render() {
    const { classes, fields = [], title = "" } = this.props
    const { open } = this.state
    if (!fields?.length) return null
    return (
      <div className={classes.container}>
        <div className={classes.header} onClick={this.handleOpen}>
          <Typography variant="h1">Más información {title}:</Typography>
          <ArrowButton open={open} />
        </div>
        <Collapse in={open}>
          {this.renderFinishedFields(fields)}
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(ExtraFieldContainer)