import React, { Component } from 'react'
import { Button, Collapse, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import SubmitButton from '../../Shared/SubmitButton'
import autobind from '../../Utils/autobind'
import { createChecklistAction } from '../../Actions/CheckListActions'
import moment from 'moment'
import { withRouter } from 'react-router'
import { getBranches, getWorkersAction } from '../../Actions/EnterpriseAction'
import { setBranchAction } from '../../Actions/BranchActions'
import { openGlobalDialog } from '../../Actions/GlobalActions'
import { getQuickAccess } from '../../API/activities'

const style = theme => ({
  container: {
    position: 'relative',
    minHeight: 150
  },
  lists: {
    padding: 24
  },
  quick: {
    background: theme.palette.blue.transparent,
    borderRadius: 15,
    padding: 12,
    '& > h4': {
      color: theme.palette.purple.light
    }
  },
  quicks: {
    '& > *': {
      marginBottom: 6
    }
  },
  centerButton: {
    textAlign: 'center',
    marginTop: 12,
    '& > button': {
      width: 200
    }
  }
})

class QuickAccessChecklists extends Component {
  constructor() {
    super()
    autobind(QuickAccessChecklists, this)
    this.state = {
      quickChecklists: [],
      selected: null,
      open: false,
      loading: false
    }
  }
  componentDidMount() {
    this.handleFetchData()
    window.addEventListener("branch_change", this.handleFetchData)
  }

  componentWillUnmount() {
    window.removeEventListener("branch_change", this.handleFetchData)
  }

  async handleFetchData() {
    this.setState({ loading: true })
    const response = await getQuickAccess()
    this.setState({ quickChecklists: response.data.info, loading: false })
  }

  handleCreate(selected) {
    return () => {
      this.setState({ loading: true })
      const { createChecklist, user, checklists, openGlobalDialog } = this.props
      // Check if a checklist is already created this date
      const pendings = checklists.pending
      console.log(pendings)

      const isCreated = pendings.find(ch =>
        ch.user_id === user?.account?.user?.id &&
        ch.date_scheduled === moment(new Date()).format("YYYY-MM-DD") &&
        ch.preventive_measure_id === selected.checklist.id &&
        ch.activity_id === selected.activity.id
      )

      console.log(!!isCreated)

      const body = {
        user_id: user?.account?.user?.id,
        date_scheduled: moment(new Date()).format("YYYY-MM-DD"),
        preventive_measure_id: selected.checklist.id,
        activity_id: selected.activity.id,
      }
      console.log(body)

      const self = this

      const callback = response => {
        const checklist_id = response.payload.data.info.id
        this.setState({ loading: false })
        const { history } = self.props
        history.push(`/checklists/${checklist_id}`)
      }
      if (!isCreated) {
        createChecklist(body).then(response => {
          const checklist_id = response.payload.data.info.id
          this.setState({ loading: false })
          const { history } = this.props
          history.push(`/checklists/${checklist_id}`)
        })
      } else {
        openGlobalDialog(createChecklist, body, {
          callback: callback,
          message: "Ya realizaste este registro o formulario hoy, ¿quieres realizar otro?",
          title: "Registro o formulario ya realizado",
          submit: "Volver a realizar"
        })
      }
    }
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  setupInformation() {
    const { values, user } = this.props
    const allQuickLists = []
    const allActivities = values.activities || []
    allActivities.forEach(activity => {
      const checklists = activity?.preventive_measures || []
      checklists.forEach(checklist => {
        if (!checklist.quick_access) return false
        if (!checklist.authorized_users) return allQuickLists.push({ activity, checklist })
        if (checklist.authorized_users.includes(user.account.user.id)) return allQuickLists.push({ activity, checklist })
      })
    })
    this.setState({ quickChecklists: allQuickLists })
  }

  renderChecklists() {
    const { quickChecklists, open } = this.state
    const { classes } = this.props
    if (quickChecklists.length === 0) return <Typography variant="subtitle1">No hay registros o formularios con acceso rápido</Typography>
    const lists = quickChecklists.slice(0, 5).map(element => {
      return (
        <div className={classes.quick}>
          <Typography variant="h4">{element.activity.name}</Typography>
          <Typography variant="subtitle1">{element.checklist.name}</Typography>
          <SubmitButton color="secondary" onClick={this.handleCreate(element)}>Realizar</SubmitButton>
        </div>
      )
    })
    const hiddenLists = quickChecklists.slice(5).map(element => {
      return (
        <div className={classes.quick}>
          <Typography variant="h4">{element.activity.name}</Typography>
          <Typography variant="subtitle1">{element.checklist.name}</Typography>
          <SubmitButton color="secondary" onClick={this.handleCreate(element)}>Realizar</SubmitButton>
        </div>
      )
    })
    return (
      <>
        <div className={classes.quicks}>
          {lists}
        </div>
        <Collapse in={open}>
          <div className={classes.quicks}>
            {hiddenLists}
          </div>
        </Collapse>
        {quickChecklists.length > 5 && <div className={classes.centerButton}>
          <Button onClick={this.handleOpen} color="primary" variant="outlined" size="large">
            Ver {open ? "Menos" : "Más"}
          </Button>
        </div>}
      </>
    )
  }

  render() {
    const { classes, loading } = this.props
    return (
      <div className={classes.container}>
        {loading
          ? <LoaderAnimator loading />
          :
          <div className={classes.lists}>
            {this.renderChecklists()}
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  activities: state.activities,
  user: state.user,
  workers: state.workers,
  branch: state.branch,
  checklists: state.checklists
})

const mapDispatchToProps = dispatch => ({
  createChecklist: body => dispatch(createChecklistAction(body)),
  getBranches: (body) => dispatch(getBranches(body)),
  setBranch: body => dispatch(setBranchAction(body)),
  getWorkers: () => dispatch(getWorkersAction()),
  openGlobalDialog: (action, body, options) => dispatch(openGlobalDialog(action, body, options)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(QuickAccessChecklists)))