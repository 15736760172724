function isItemAnswered(item) { return item.answer.value_id !== null }
function isAnswerYES(item) { return item.answer.value_id === 1 }
function isAnswerNO(item) { return item.answer.value_id === 2 }
function isAnswerNA(item) { return item.answer.value_id === 3 }
function isFieldAnswered(field) { return !!field.value }

export function getProgress(checklist) {
	if (!checklist) return 0
	const { metadata } = checklist
	const disabled_categories = metadata?.disabled_categories || []
	const items = checklist?.checklist_items2 || []
	if (items.length === 0) return 100

	const enabled_items = items.filter(element => !disabled_categories.includes(element?.subtitle_id))
	if (enabled_items.length === 0) return 100

	const total = enabled_items.length
	let added = 0
	enabled_items.forEach(element => { if (isItemAnswered(element)) { added++ } })
	return Math.round(added / total * 100, 10)
}

export function getCompliance(checklist, isPartial = false) {
	if (!checklist) return 0
	const items = checklist?.checklist_items2 || []
	const compliance_fields = checklist?.checklist_fields?.filter(field => field.type === "compliance") || []
	if (items.length === 0) return 100

	let total_items = 0
	let added_values = 0

	items.forEach(item => {
		if (!isItemAnswered(item)) return null
		if (isAnswerNA(item)) return null
		total_items += item.points
		if (isAnswerNO(item)) return null
		added_values += item.points
	})

	const itemsCompliance = total_items > 0 ? (added_values / total_items * 100) : 0

	let added_field_values = 0
	let total_fields = 0
	compliance_fields.forEach(field => {
		if (!isFieldAnswered(field)) return null
		total_fields += 1
		const field_values = JSON.parse(field.value)
		const not_favorable_cases = field_values.desfav || 0
		let fieldCompliance = 100

		// If solved in place and is asking for final compliance return 100%
		if (field_values.solved && !isPartial) { fieldCompliance = 100 }
		else {
			const nonCompliancePercentage = field_values.total > 0 ? (not_favorable_cases * 100) / field_values.total : 100
			fieldCompliance = 100 - nonCompliancePercentage
		}
		added_field_values += fieldCompliance
	})

	const fieldsTotalCompliance = total_fields > 0 ? (added_field_values / total_fields) : 0
	const added_totals = total_fields + total_items
	const weightedItemsCompliance = added_totals > 0 ? (total_items / added_totals) * itemsCompliance : 0
	const weightedFieldsCompliance = added_totals > 0 ? (total_fields / added_totals) * fieldsTotalCompliance : 0
	const finalCompliance = weightedItemsCompliance + weightedFieldsCompliance
	return (finalCompliance).toFixed(1)
}

export function getPoints(checklist) {
	const checklistElements = checklist?.checklist_items2 || []
	if (checklistElements.length === 0) return { total: 0, added: 0 }
	let total = 0
	let added = 0
	checklistElements.forEach(element => {
		if (element.answer.value_id !== null && element.answer.value_id !== 3) {
			total += element.points
			if (element.answer.value_id === 1) {
				added += element.points
			}
		}
	})
	return { total, added }
}

export function getTotalCounters(checklist) {
	function getFactor(item) {
		const crit = item?.answer?.criticality
		if (crit === 4) return 2
		if (crit === 3) return 1.5
		return 1
	}
	const items = checklist.checklist_items2 || []
	const filtered = items
		.filter(item => item.counter === 1 && item.answer.counter !== null)
	if (filtered.length === 0) return 0
	const total = filtered
		.reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
	return total.answer.counter
}