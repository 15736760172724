export const closeGlobalDialog = () => {
  return {
    type: "CLOSE_GLOBAL_DIALOG",
  }
}

export const openGlobalDialog = (action, body, options) => {
  return {
    type: "OPEN_GLOBAL_DIALOG",
    payload: { action, body, options }
  }
}