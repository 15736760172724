import { Search } from '@material-ui/icons'
import { Box, TextField } from '@mui/material'

const css = {
	container: {
		paddingTop: 2,
		paddingBottom: 1
	}
}

function TableSearchBar({ setSearch, search }) {
	return (
		<Box sx={css.container}>
			<TextField
				placeholder='Buscar...'
				variant="standard"
				InputProps={{
					endAdornment: <Search />
				}}
				onChange={e => setSearch(e.target.value)}
				value={search}
			/>
		</Box>
	)
}

export default TableSearchBar