const ParamsReducer = (state = { all: {} }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case "GET_PARAMS": {
      newState.all = action.payload.data.info
      return newState
    }
    default:
      return newState
  }
}

export default ParamsReducer