import { IconButton, Typography } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Paper } from '@mui/material'
import moment from 'moment'
import { useContext } from 'react'
import AppContext from '../../AppContext'

const css = {
	paper: {
		padding: 2,
		marginBottom: 2,
		transition: 'all 0.1s linear',
		'&:hover': {
			transform: 'scale(1.01)'
		},
		cursor: 'pointer'
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 2
	}
}

function WorkflowCard({ value, workflow = {} }) {

	const { history } = useContext(AppContext)
	function navigate(link) { history.push(link) }

	return (
		<Box>
			<Paper sx={css.paper} onClick={() => navigate(`workflows/${workflow.id}`)}>
				<Box sx={css.header}>
					<Typography variant='h4'>{workflow.name}</Typography>
					<IconButton color="primary" size="small">
						<KeyboardArrowRight />
					</IconButton>
				</Box>
				<Typography variant='subtitle1' color="secondary" >{workflow.workflow_base_name}</Typography>
				<Typography variant='subtitle1'>{workflow.user || "Sin responsable"}</Typography>
				<Typography variant='caption'>{moment(workflow.date_created).format("YYYY-MM-DD HH:mm")}</Typography>
			</Paper>
		</Box>
	)
}

export default WorkflowCard