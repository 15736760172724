import React, { Component } from "react"
import TextInput from "./TextInput"
import DateInput from "./DateInput"
import SelectInput from "./SelectInput"
import MultiSelectInput from "./MultiSelectInput"
import FileInput from "./FileInput"
import ImgInput from "./ImgInput"
import MultipleImgInput from "./MultipleImgInput"
import DateTimeInput from "./DateTimeInput"
import NewMultiSelectInput from "./NewMultiSelectInput"
import RutInput from "./RutInput"
import SelectCreateInput from "./SelectCreateInput"
import UsersInput from "./UsersInput"

export default class FieldRenrerer extends Component {
    selectInput() {
        const { input, onChange, value } = this.props
        switch (input.type) {
            case "text": {
                return (
                    <TextInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        type={input.type}
                    />
                )
            }
            case "file": {
                return (
                    <FileInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        type={input.type}
                    />
                )
            }
            case "date": {
                return (
                    <DateInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                    />
                )
            }
            case "datetime": {
                return (
                    <DateTimeInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                    />
                )
            }
            case "select": {
                return (
                    <SelectInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            case "select_create": {
                return (
                    <SelectCreateInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                        onFetch={input.onFetch}
                        onCreate={input.onCreate}
                    />
                )
            }
            case "multiselect": {
                return (
                    <NewMultiSelectInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                        grouped={input.grouped}
                        enableAll={input.enableAll}
                    />
                )
            }
            case "img": {
                return (
                    <ImgInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            case "multi-img": {
                return (
                    <MultipleImgInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            case "rut": {
                return (
                    <RutInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            case "users": {
                return (
                    <UsersInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        options={input.options}
                    />
                )
            }
            default:
                return (
                    <TextInput
                        value={value}
                        name={input.name}
                        onChange={onChange}
                        required={input.required}
                        label={input.label}
                        placeholder={input.placeholder}
                        type={input.type}
                    />
                )
        }
    }
    render() {
        return <>{this.selectInput()}</>
    }
}
