import { Settings } from '@material-ui/icons'
import { Box, Button, Collapse, Dialog, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { createSettingTalk, createTalkCategory, editSettingTalk, getSettingsTalks, getTalksCategories } from '../../../API/talks'
import useToggle from '../../../Hooks/ToogleHook'
import AddElementButton from '../../../Shared/AddElementButton'
import GeneralTable from '../../../Shared/GeneralTable'
import FileInput from '../../../Shared/Inputs/FileInput'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import SelectCreateInput from '../../../Shared/Inputs/SelectCreateInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import RTE from '../../../Shared/RTE'
import { dialog_style, end_buttons, inline_space, text_space } from '../../../Utils/defaultStyles'
import { filterActiveWorkers } from '../../../Utils/filters'
import { completePath, createDataFromJson, formatDownloadableDocument, transformToOptions } from '../../../Utils/functions'
import EditTalkDialog from './EditTalkDialog'

const css = {
	container: {
		maxWidth: 900
	},
	form: {
		background: 'white',
		padding: 3,
		borderRadius: 2,
		margin: '12px 0'
	},
	title: {
		marginBottom: 2
	},
	table: {
		padding: 2,
		background: 'white',
		borderRadius: 2
	}
}

const baseUrl = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_DOCUMENT_FOLDER}`

function TalksSetup({ value }) {

	const [openCreate, toggleCreate] = useToggle(false)
	const [talks, setTalks] = useState([])
	const [params, setParams] = useState({})
	const [selectedTalk, selectTalk] = useState(null)

	useEffect(() => {
		async function fetchData() {
			const responses = await Promise.all([getSettingsTalks(), getTalksCategories()])
			const datas = responses.map(r => r.data.info)
			setTalks(datas[0])
		}
		fetchData()
	}, [])

	function onChange(event) {
		const { target } = event
		if (target.name === "file") {
			setParams(p => ({ ...p, fileName: target.value?.name }))
		}
		setParams(p => ({ ...p, [target.name]: target.value }))
	}

	async function onCreate() {
		const data = { ...params }
		const body = createDataFromJson(data)
		const response = await createSettingTalk(body)
		setTalks(response.data.info)
	}

	async function onEdit(data) {
		const body = createDataFromJson(data)
		const response = await editSettingTalk(body)
		setTalks(response.data.info)
	}

	function seeDocument(doc) {
		const path = completePath(doc.file, baseUrl)
		window.open(path)
	}

	function openEditTalk(row) {
		return () => {
			selectTalk(row)
		}
	}

	const tableInfo = [
		{ name: "Nombre", label: "name" },
		{ name: "Categoría", label: "category_name" },
		{ name: "Documento", label: "file", clickable: true, action: seeDocument, format: (v) => v ? "Ver Documento" : "" }
	]

	const actions = [
		{ name: "Editar Charla", icon: Settings, action: openEditTalk }
	]

	const workers = filterActiveWorkers(false)

	return (
		<Box sx={css.container}>
			<Box sx={text_space}>
				<Box sx={inline_space}>
					<Typography variant='h1'>Configuración de Charlas</Typography>
					<AddElementButton onClick={toggleCreate} open={openCreate} name='Crear Charla' />
				</Box>
			</Box>
			<Collapse in={openCreate}>
				<Box sx={css.form}>
					<Typography sx={css.title} variant='h4'>Nueva Charla</Typography>
					<TextInput name="name" value={params.name} onChange={onChange} label="Nombre" />
					<SelectCreateInput onCreate={createTalkCategory} name="category_id" value={params.category_id} onChange={onChange} onFetch={getTalksCategories} label="Categoría" />
					<FileInput name="file" value={params.fileName} onChange={onChange} label="Documento" />
					<Box>
						<Typography variant='subtitle2'>Contenido</Typography>
						<RTE onChange={onChange} name="content" value={params.content} />
					</Box>
					<NewMultiSelectInput value={params.users} name="users" label="Responsables" options={transformToOptions(workers)} onChange={onChange} />
					<TextInput label="Video (Enlace de Youtube)" name="video" value={params.video} onChange={onChange} />
					<Box sx={end_buttons}>
						<Button disabled={!params.name || !params.category_id} color="primary" variant="outlined" onClick={onCreate}>
							Crear Charla
						</Button>
					</Box>
				</Box>
			</Collapse>
			<Box sx={css.table}>
				<GeneralTable disableExport data={talks} info={tableInfo} scrollable maxHeight={500} actions={actions} />
			</Box>
			<EditTalkDialog onEdit={onEdit} open={!!selectedTalk} onClose={openEditTalk(null)} talk={selectedTalk} />
		</Box>
	)
}

export default TalksSetup