import { Button } from "@material-ui/core"
import { Clear, Edit } from "@material-ui/icons"
import { IconButton, Typography, Box, Dialog } from "@mui/material"
import { useEffect, useState } from "react"
import useToggle from "../../../Hooks/ToogleHook"
import MultiSelectInput from "../../../Shared/Inputs/MultiSelectInput"
import SelectInput from "../../../Shared/Inputs/SelectInput"
import { filterActiveWorkers } from "../../../Utils/filters"
import { transformToOptions } from "../../../Utils/functions"

const css = {
	activity: {
		background: 'whitesmoke',
		padding: 2,
		borderRadius: 1,
		margin: '12px 0',
		position: 'relative',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between'
	},
	icons: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	title: {
		marginBottom: 3
	},
	dialog: {
		padding: 3
	},
	button: {
		textAlign: 'end'
	}
}

export const recordTypes = [
	"Registro o Formulario",
	"Charla",
	"Capacitación",
	"Evaluación"
]

const frequencies = [
	{ value: "0", label: "Solo una vez" },
	{ value: "1", label: "Diario" },
	{ value: "7", label: "Semanal" },
	{ value: "15", label: "Quincenal" },
	{ value: "30", label: "Mensual" },
	{ value: "60", label: "Bimensual" },
	{ value: "90", label: "Trimestral" },
	{ value: "180", label: "Semestral" },
	{ value: "365", label: "Anual" },
]

function UserActivity({ activity, index, functions: { getRecordName, getFrequencyName, getRecordTypeName }, data, onEdit, onDelete, options: filterOptions, order }) {

	const [openEdit, toggleEdit] = useToggle(false)
	const [openDelete, toggleDelete] = useToggle(false)
	const [params, setParams] = useState({})

	const options = recordTypes.map((rt, index) => ({ value: String(index), label: rt }))
	const filteredData = params.record_type !== null ? data[params.record_type] : []

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value

		if (target.name === "record_type") {
			newParams["record_id"] = null
		}

		setParams(newParams)
	}

	function onSubmit() {
		const body = { ...params }
		onEdit(body)
		toggleEdit()
	}

	function handleDelete() {
		const body = { id: activity.id }
		onDelete(body)
		toggleDelete()
	}

	useEffect(() => {
		function setup() {
			setParams({ ...activity })
		}
		setup()
	}, [activity])

	const [sub_enterprises, branches, positions] = filterOptions

	const selectedSubEnterprise = sub_enterprises.find(se => se.id === params.sub_enterprise_id)
	const selectedBranch = branches.find(b => b.id === String(params.branch_id))
	const selectedPosition = positions.find(p => p.id === params.position_id)
	const workers = filterActiveWorkers()

	return (
		<Box sx={css.activity}>
			<Box>
				<Typography sx={css.title} variant='h4'>{`${order ? `${index + 1}. ` : ""} ${getRecordName(activity.record_id, activity.record_type)}`}</Typography>
				{selectedPosition && <Typography variant='subtitle1'>Para: <strong>{`${selectedPosition?.name}`} </strong></Typography>}
				{selectedBranch && <Typography variant='subtitle1'>En: <strong>{`${selectedBranch?.name}`} </strong></Typography>}
				{selectedSubEnterprise && <Typography variant='subtitle1'>De: <strong>{`${selectedSubEnterprise?.name}`} </strong></Typography>}
				<Typography variant='caption'>{getRecordTypeName(activity.record_type)}</Typography>
			</Box>
			<Typography variant='subtitle1' color="secondary"><strong>{getFrequencyName(activity.frequency)}</strong></Typography>
			<Box sx={css.icons}>
				<IconButton onClick={toggleEdit}>
					<Edit />
				</IconButton>
				<IconButton onClick={toggleDelete}>
					<Clear />
				</IconButton>
			</Box>
			<Dialog open={openEdit} onClose={toggleEdit} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography variant='h4'>Editando actividad</Typography>
					<SelectInput value={params.record_type} name="record_type" options={options} label="Seleccionar tipo de actividad" onChange={onChange} />
					<SelectInput value={params.record_id} name="record_id" onChange={onChange} options={transformToOptions(filteredData)} label="Seleccionar registro" />
					<SelectInput value={params.frequency} name="frequency" onChange={onChange} options={frequencies} label="Seleccionar frecuencía de cumplimiento" />
					<SelectInput value={params.position_id} name="position_id" onChange={onChange} options={transformToOptions(positions)} label="Seleccionar cargo asociado" />
					<SelectInput value={params.branch_id} name="branch_id" onChange={onChange} options={transformToOptions(branches)} label="Seleccionar centro de trabajo" />
					<SelectInput value={params.sub_enterprise_id} name="sub_enterprise_id" onChange={onChange} options={transformToOptions(sub_enterprises)} label="Seleccionar empresa asociada" />
					<MultiSelectInput value={params.users_id} name="users_id" onChange={onChange} options={transformToOptions(workers)} label="Seleccionar usuarios directos" />
					<Box sx={css.button}>
						<Button color="primary" variant="contained" size="small" onClick={onSubmit} disabled={
							!params.record_id || !params.record_id || !params.frequency
						}>
							Editar actividad
						</Button>
					</Box>
				</Box>
			</Dialog>

			<Dialog open={openDelete} onClose={toggleDelete} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography variant='h4'>Eliminar actividad</Typography>
					<Box sx={css.activity}>
						<Box>
							<Typography sx={css.title} variant='h4'>{getRecordName(activity.record_id, activity.record_type)}</Typography>
							<Typography variant='caption'>{getRecordTypeName(activity.record_type)}</Typography>
						</Box>
						<Typography variant='subtitle1' color="secondary"><strong>{getFrequencyName(activity.frequency)}</strong></Typography>
					</Box>
					<Box sx={css.button}>
						<Button color="primary" variant="contained" size="small" onClick={handleDelete}>
							Eliminar actividad
						</Button>
					</Box>
				</Box>
			</Dialog>
		</Box>
	)
}

export default UserActivity