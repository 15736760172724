import { Delete } from '@material-ui/icons'
import { Box, IconButton } from '@mui/material'
import { useContext } from 'react'
import useChange from '../../../../Hooks/UseStateChange'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import { FindingStructureContext } from './DefaultFindingStructure'

const css = {
	container: {
		padding: 1,
		background: 'whitesmoke',
		borderRadius: 1,
		margin: '6px 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		'& > *': {
			flexGrow: 1
		}
	}
}

function DefaultMeasure({ measure }) {

	const [content, changeContent] = useChange(measure.content)
	const { editMeasure, deleteMeasure } = useContext(FindingStructureContext)

	function onBlur() {
		editMeasure({ ...measure, content })
	}

	function onDelete() {
		deleteMeasure(measure)
	}

	return (
		<Box sx={css.container}>
			<SeamlessInput onBlur={onBlur} value={content} onChange={changeContent} color="transparent" placeholder="Ingrese contenido de la medida de control." />
			<Box style={{ textAlign: 'end' }}>
				<IconButton onClick={onDelete}>
					<Delete />
				</IconButton>
			</Box>
		</Box>
	)
}

export default DefaultMeasure