import { Divider } from '@material-ui/core'
import { Delete, InfoOutlined, Settings } from '@material-ui/icons'
import { Box, Button, Checkbox, IconButton, Radio, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import useChange from '../../../Hooks/UseStateChange'
import useChangeParams from '../../../Hooks/UseStateChangeParams'
import BigTooltip from '../../../Shared/BigTooltip'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import { end_buttons, inline, inline_space, text_space } from '../../../Utils/defaultStyles'
import { transformToOptions } from '../../../Utils/functions'
import { WorkflowSetupContext } from './WorkflowSetup'

const css = {
	container: {
		padding: 2,
		borderRadius: 1,
		background: 'whitesmoke',
		margin: '12px 0'
	},
	settings: {
		padding: 2,
		background: 'white',
		borderRadius: 1,
	},
	option: {
		margin: '6px 0'
	},
	options: {
		background: 'whitesmoke',

		borderRadius: 1,
		margin: '12px 0'
	}
}

const types = [
	{ label: "Texto", value: 'text' },
	{ label: "Texto Enriquecido (word)", value: 'rte' },
	{ label: "Seleccionar una opción", value: "select" },
	{ label: "Selección Múltiple", value: "multiselect" },
	{ label: "Campo numérico", value: 'number' },
	{ label: "Subir un documento", value: "file" },
	{ label: "Confirmar acción", value: 'check' },
	{ label: "Ingreso de fecha", value: 'date' },
	{ label: "Levantamiento de Registros o Formularios", value: 'checklists' },
	{ label: "Levantamiento de Hallazgos", value: 'findings' },
]

function getTypeName(type) {
	return types.find(t => t.value === type)?.label || ""
}

function PhaseSetup({ item }) {
	const data = item.data || {}
	const [name, setName] = useChange(data.label)
	const { editPhase, deletePhase, baseChecklists } = useContext(WorkflowSetupContext)
	const [optionType, setOptionType] = useState("options")
	const [options, setOptions] = useState(data.options || [])
	const [openSettings, toggleSettings] = useToggle(!data.type)

	function onBlur() {
		const body = { ...item, phase_data: { ...data, label: name } }
		editPhase(body)
	}

	function selectOptionType(option) {
		setOptionType(option)
		const body = { ...item, phase_data: { ...data, entity: option } }
		editPhase(body)
	}

	function createOption() {
		options.push("")
		const body = { ...item, phase_data: { ...data, options } }
		editPhase(body)
	}

	function editOption(value, index) {
		const newOptions = [...options]
		newOptions[index] = value
		setOptions(newOptions)
	}

	function onBlurOption() {
		const body = { ...item, phase_data: { ...data, options } }
		editPhase(body)
	}

	function toggleRequired() {
		const required = !!data.required
		const body = { ...item, phase_data: { ...data, required: !required } }
		editPhase(body)
	}

	function selectType(e) {
		const { target } = e
		const body = { ...item, phase_data: { ...data, type: target.value } }
		editPhase(body)
	}

	function selectChecklist(e) {
		const { target } = e
		const body = { ...item, phase_data: { ...data, base_checklist_id: target.value } }
		editPhase(body)
	}

	function onDelete() {
		const body = { ...item }
		deletePhase(body)
	}

	function getBaseChecklistName(id) {
		if (!id) return ""
		return baseChecklists.find(bc => bc.id === id)?.name || ""
	}

	return (
		<Box sx={css.container}>
			<Box sx={inline_space} style={{ marginBottom: 12 }}>
				<Box sx={inline}>
					{!!data.required && <BigTooltip title="Campo obligatorio"><InfoOutlined color="error" /></BigTooltip>}
					<SeamlessInput onBlur={onBlur} value={name} onChange={setName} variant="subtitle2" color="transparent" placeholder="Ingrese título del Item..." />
				</Box>
				<Box>
					<BigTooltip title="Configurar Item">
						<IconButton onClick={toggleSettings}>
							<Settings />
						</IconButton>
					</BigTooltip>
					<BigTooltip title="Eliminar Item">
						<IconButton onClick={onDelete}>
							<Delete />
						</IconButton>
					</BigTooltip>
				</Box>
			</Box>
			{openSettings ?
				<Box sx={css.settings}>
					<Box sx={inline} onClick={toggleRequired}>
						<Checkbox color="warning" checked={!!data.required} />
						<Typography variant='subtitle2'>Hacer obligatorio</Typography>
					</Box>
					<SelectInput value={data.type} onChange={selectType} name="type" label="Seleccione Tipo" options={types} />
					{["select", "multiselect"].includes(data.type) &&
						<Box>
							<Typography variant='subtitle2'>Para las opciones del item desea...:</Typography>
							<Box sx={inline} onClick={() => selectOptionType("options")}>
								<Radio checked={optionType === "options"} />
								<Typography variant='subtitle1'>Crear Opciones</Typography>
							</Box>
							<Box sx={inline} onClick={() => selectOptionType("entity")}>
								<Radio checked={optionType === "entity"} />
								<Typography variant='subtitle1'>Usar a los colaboradores como opciones</Typography>
							</Box>
							{optionType === "options" &&
								<Box>
									<Box sx={end_buttons}>
										<Button color="info" variant="outlined" onClick={createOption}>
											Crear Opción
										</Button>
									</Box>
									<Box sx={css.options}>
										{options.map((option, index) =>
											<Box sx={css.option}>
												{index > 0 && <Divider />}
												<SeamlessInput color="transparent" onBlur={onBlurOption} onChange={e => editOption(e.target.value, index)} value={option} key={index} placeholder="Ingrese Título de la opción..." />
											</Box>
										)}
									</Box>
								</Box>
							}
						</Box>
					}
					{data.type === "checklists" &&
						<Box>
							<SelectInput value={data.base_checklist_id} onChange={selectChecklist} options={transformToOptions(baseChecklists)} label="Seleccione tipo de registro" />
						</Box>
					}
				</Box> :
				<Box>
					<Typography variant='subtitle1'>Tipo de campo: <strong>{getTypeName(data.type) || "Favor seleccionar..."}</strong></Typography>
					{["select", "multiselect"].includes(data.type) &&
						<Box sx={text_space}>
							<Typography variant='subtitle1'>Opciones: {data.entity === "options" ? "Creadas" : "Colaboradores del sistema"}</Typography>
							{optionType === "options" &&
								<Box>
									{options.map(option => <Typography variant='subtitle2'>* {option}</Typography>)}
								</Box>
							}
						</Box>
					}
					{data.type === "checklists" &&
						<Box>
							<Typography variant='subtitle1'>Tipo de registro o formulario: <strong>{getBaseChecklistName(data.base_checklist_id)}</strong></Typography>
						</Box>
					}
				</Box>
			}
		</Box>
	)
}

export default PhaseSetup