import { Box, Typography, Paper, Button } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { answerQuestion, finishEvaluation, getEvaluations } from '../../API/evaluations'
import DownloadButton from '../../Shared/DownloadButton'
import ImageContainer from '../../Shared/ImageContainer'
import { mergeMultiplePdfFiles } from '../../Utils/functions'
import EvaluationContext from '../Settings/Evaluations/EvaluationContext'
import Question from './Question'


const css = {
	container: {
		margin: 2,
		maxWidth: 950
	},
	info: {
		padding: 2,
		position: 'relative'
	},
	final: {
		padding: 2,
	},
	group: {
		background: 'white',
		padding: 2,
		margin: '12px 0',
		borderRadius: 2
	},
	float: {
		position: 'absolute',
		right: 6,
		top: 6
	},
	infoline: {
		display: 'flex',
		'& > :nth-child(1)': {
			width: 160
		}
	}
}

function Evaluation({ value, match }) {

	const [evaluation, setEvaluation] = useState({})

	const { id } = match.params
	useEffect(() => {
		async function fetchData() {
			const response = await getEvaluations({ id })
			setEvaluation(response.data.info)
		}

		fetchData()
	}, [])

	async function onAnswer(body) {
		const response = await answerQuestion(body)
		setEvaluation(response.data.info)
	}

	async function onFinish() {
		const response = await finishEvaluation({ id })
		setEvaluation(response.data.info)
	}

	function download(element, info) {
		return async () => {
			const url = `https://app.safeasy.cl/model/pdf/evaluations.php?id=${evaluation.id}&u=${evaluation.user_id}`
			const parsed_url = new URL(url)
			if (info) {
				Object.keys(info).forEach(key => {
					parsed_url.searchParams.append(key, info[key])
				})
			}
			console.log(parsed_url.toString())
			const final_url = parsed_url.toString()
			mergeMultiplePdfFiles([final_url], `Reporte de evaluación: ${evaluation.name} de ${evaluation.user}`)
		}
	}

	const groups = evaluation.groups || []
	const questions = evaluation.questions || []
	const finished = evaluation.finished
	const ready = questions.every(question => question.answered)

	return (
		<EvaluationContext.Provider value={{ onAnswer, finished }}>
			<Box sx={css.container}>
				<Paper sx={css.info}>
					<Box sx={css.float}>
						<DownloadButton onClick={download} disabled={!finished} />
					</Box>
					<Typography style={{ marginBottom: 12 }} variant='h1'>{evaluation?.name}</Typography>
					<Typography style={{ marginBottom: 12 }} variant='h4'>{evaluation?.user}</Typography>
					<Typography variant='caption'>{moment(evaluation?.created_at).format("YYYY-MM-DD HH:mm")}</Typography>
				</Paper>
				{groups.map(group => <Group group={group} />)}
				{!finished &&
					<Box style={{ textAlign: 'end' }}>
						<Button color="primary" variant="contained" disabled={!ready} onClick={onFinish}>
							Enviar formulario
						</Button>
					</Box>
				}
				{finished &&
					<Paper sx={css.final}>
						<Typography variant='subtitle1'><strong>{`Terminado por ${evaluation.user} el ${moment(evaluation.finished_at).format("YYYY-MM-DD [a las] HH:mm")}`}</strong></Typography>
						<Box sx={css.infoline}>
							<Typography variant='subtitle1'>{`Total Correctas:`}</Typography>
							<Typography variant='subtitle1'><strong>{`${evaluation.points}`}</strong></Typography>
						</Box>
						<Box sx={css.infoline}>
							<Typography variant='subtitle1'>{`Total Incorrectas: `}</Typography>
							<Typography variant='subtitle1'><strong>{`${questions.length - evaluation.points}`}</strong></Typography>
						</Box>
						<Box sx={css.infoline}>
							<Typography variant='subtitle1'>{`Porcentaje Obtenido: `}</Typography>
							<Typography variant='subtitle1'><strong>{`${((evaluation.points / questions.length) * 100).toFixed(1)}%`}</strong></Typography>
						</Box>
						<Box sx={css.infoline}>
							<Typography variant='subtitle1'>{`Porcentaje Aprobación:`}</Typography>
							<Typography variant='subtitle1'><strong>{`${evaluation.approval}%`}</strong></Typography>
						</Box>
						<Box sx={css.infoline}>
							<Typography variant='subtitle1'>{`Resultado final: `}</Typography>
							<Typography variant='subtitle1'><strong>{`${evaluation.approved ? "Aprobado" : "Reprobado"}`}</strong></Typography>
						</Box>
					</Paper>
				}
			</Box>
		</EvaluationContext.Provider>
	)
}

function Group({ group }) {
	const questions = group.questions || []
	return (
		<Box sx={css.group}>
			<Typography variant='h4'>{group.name}</Typography>
			{group.photo &&
				<Box style={{ maxWidth: 350, margin: '6px 0' }}>
					<ImageContainer src={group.photo} alt={"apoyo-item"} width="100%" border={6} />
				</Box>
			}
			{questions.map(question => <Question question={question} />)}
		</Box>
	)
}

export default withRouter(Evaluation)