import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import instance from '../Utils/instance'
import { connect } from 'react-redux'
import { logoutAction } from '../Actions/LoginActions'
import LoaderAnimator from './LoaderAnimator'
import { CheckCircleOutline, InfoOutlined, ReportProblemOutlined } from '@material-ui/icons'

const style = (theme) => ({
  container: {
    position: 'relative',
    height: '100vh'
  },
  card: {
    position: 'absolute',
    background: 'white',
    borderRadius: 12,
    padding: 24,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    minHeight: 300,
    maxHeight: '60%',
    overflow: 'auto'
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0'
  },
  icon: {
    height: 18,
    width: 18,
    color: theme.palette.blue.main,
    marginRight: 12
  }

})

class ValidateDocument extends Component {
  constructor() {
    super()
    this.state = {
      document: null,
      loading: false
    }
  }
  componentDidMount() {
    const { logout, match } = this.props
    const code = match.params.code
    const [id, docType] = code.split("--")
    logout()
    const body = { id }
    this.setState({ loading: true })
    switch (docType) {
      case "w": {
        instance({
          method: 'post',
          url: 'users/get_document.php',
          params: body
        }).then(response => {
          console.log(response)
          this.setState({ loading: false, document: response.data.info })
        })
        break
      }
      case "b": {
        instance({
          method: 'post',
          url: 'branches/get_document.php',
          params: body
        }).then(response => {
          console.log(response)
          this.setState({ loading: false, document: response.data.info })
        })
        break
      }
      case "k": {
        instance({
          method: 'get',
          url: 'talks/get_public.php',
          params: body
        })
          .then(response => {
            console.log(response)
            this.setState({ loading: false, document: response.data.info })
          })
        break
      }
      case "t": {
        instance({
          method: 'get',
          url: 'trainings/get_public.php',
          params: body
        })
          .then(response => {
            console.log(response)
            this.setState({ loading: false, document: response.data.info })
          })
        break
      }
      case "l": {
        instance({
          method: 'get',
          url: 'checklists/get_public_qr.php',
          params: body
        })
          .then(response => {
            console.log(response)
            this.setState({ loading: false, document: response.data.info })
          })
        break
      }
      case "c": {
        instance({
          method: 'get',
          url: 'checklists/get_multiple_public_qr.php',
          params: body
        })
          .then(response => {
            console.log(response)
            this.setState({ loading: false, document: response.data.info })
          })
        break
      }
      default: {
        instance({
          method: 'post',
          url: 'users/get_document.php',
          params: body
        }).then(response => {
          console.log(response)
          this.setState({ loading: false, document: response.data.info })
        })
        break
      }
    }

  }

  renderMultiple() {
    const { document } = this.state
    const { classes } = this.props
    return (
      <>
        <Typography variant="h1">{document?.preventive_measure}</Typography>
        <div className={classes.message}>
          <InfoOutlined className={classes.icon} />
          <Typography variant="subtitle1">Este documento ha sido válidamente firmado y verificado por Tazki</Typography>
        </div>
        <Divider />
        <div className={classes.message}>
          <Typography variant="h4">Documentos:</Typography>
        </div>
        {document?.checklists?.map(checklist => {
          return this.renderChecklistRegisterManual(checklist)
        })}
      </>
    )
  }

  renderDocument() {
    const { document } = this.state
    const { classes } = this.props
    return (
      <>
        <Typography variant="h1">{document.name}</Typography>
        <div className={classes.message}>
          <InfoOutlined className={classes.icon} />
          <Typography variant="subtitle1">Este documento ha sido válidamente firmado y verificado por Tazki</Typography>
        </div>
        <Divider />
        <div className={classes.message}>
          <Typography variant="subtitle1">El documento ha sido firmado por:</Typography>
        </div>
        {document?.signs?.map(sign => {
          return (
            <div className={classes.message}>
              <CheckCircleOutline className={classes.icon} />
              <Typography variant="subtitle1">{`${sign.name} - ${sign.date}`}</Typography>
            </div>
          )
        })}
      </>
    )
  }

  renderTalkOrTraining() {
    const { document } = this.state
    const { classes } = this.props
    return (
      <>
        <Typography variant="h1">{document.name}</Typography>
        <div className={classes.message}>
          <InfoOutlined className={classes.icon} />
          <Typography variant="subtitle1">Este documento ha sido válidamente firmado y verificado por Tazki</Typography>
        </div>
        <Divider />
        <div className={classes.message}>
          <Typography variant="subtitle1">Participantes:</Typography>
        </div>
        {document?.participants_signs?.map(sign => {
          return (
            <div className={classes.message}>
              <CheckCircleOutline className={classes.icon} />
              <Typography variant="subtitle1">{`${sign.name} - ${sign.date}`}</Typography>
            </div>
          )
        })}
        <div className={classes.message}>
          <Typography variant="subtitle1">Relatores:</Typography>
        </div>
        {document?.user_sign?.map(sign => {
          return (
            <div className={classes.message}>
              <CheckCircleOutline className={classes.icon} />
              <Typography variant="subtitle1">{`${sign.name} - ${sign.date}`}</Typography>
            </div>
          )
        })}
      </>
    )
  }

  renderChecklistRegister() {
    const { document } = this.state
    const { classes } = this.props
    return (
      <>
        <Typography variant="h1">{document.preventive_measure}</Typography>
        <div className={classes.message}>
          <InfoOutlined className={classes.icon} />
          <Typography variant="subtitle1">Este documento ha sido válidamente firmado y verificado por Tazki</Typography>
        </div>
        <Divider />
        <div className={classes.message}>
          <Typography variant="h4">{`Realizado por: ${document.user} - ${document.date_done}`}</Typography>
        </div>
        {document?.group_signs?.length > 0 &&
          <>
            <div className={classes.message}>
              <Typography variant="subtitle1">Grupos de firmas internas:</Typography>
            </div>
            {document?.group_signs?.length > 0 ?
              <>
                {document?.group_signs?.map(group => {
                  return group?.signs?.map(sign => {
                    return (
                      <div className={classes.message}>
                        <CheckCircleOutline className={classes.icon} />
                        <Typography variant="subtitle1">{`${sign.name} - ${sign.date}`}</Typography>
                      </div>
                    )
                  })
                })}
              </>
              :
              <Typography variant="subtitle1">No contiene firmas</Typography>
            }
          </>
        }
        {document?.signs?.length > 0 &&
          <>
            <div className={classes.message}>
              <Typography variant="subtitle1">El registro o formulario ha sido firmado por:</Typography>
            </div>
            {document?.signs?.map(sign => {
              return (
                <div className={classes.message}>
                  <CheckCircleOutline className={classes.icon} />
                  <Typography variant="subtitle1">{`${sign.name} - ${sign.date}`}</Typography>
                </div>
              )
            })}
          </>
        }
      </>
    )
  }

  renderChecklistRegisterManual(checklist) {
    const { classes } = this.props
    return (
      <>
        <Divider />
        <div className={classes.message}>
          <Typography variant="h4">{`Realizado por: ${checklist.user} - ${checklist.date_done}`}</Typography>
        </div>
        {checklist?.group_signs?.length > 0 &&
          <>
            <div className={classes.message}>
              <Typography variant="subtitle1">Grupos de firmas internas:</Typography>
            </div>
            {checklist?.group_signs?.map(group => group?.signs)?.flat()?.length > 0 ?
              <>
                {checklist?.group_signs?.map(group => {
                  return group?.signs?.map(sign => {
                    return (
                      <div className={classes.message}>
                        <CheckCircleOutline className={classes.icon} />
                        <Typography variant="subtitle1">{`${sign.name} - ${sign.date}`}</Typography>
                      </div>
                    )
                  })
                })}
              </>
              :
              <Typography variant="subtitle1">- No contiene firmas</Typography>
            }
          </>
        }
        {checklist?.signs?.length > 0 &&
          <>
            <div className={classes.message}>
              <Typography variant="subtitle1">El registro o formulario ha sido firmado por:</Typography>
            </div>
            {checklist?.signs?.map(sign => {
              return (
                <div className={classes.message}>
                  <CheckCircleOutline className={classes.icon} />
                  <Typography variant="subtitle1">{`${sign.name} - ${sign.date}`}</Typography>
                </div>
              )
            })}
          </>
        }
      </>
    )
  }

  renderErrorMessage() {
    const { classes } = this.props
    return (
      <>
        <div className={classes.message}>
          <ReportProblemOutlined className={classes.icon} />
          <Typography variant="h1">No se pudo verificar el documento</Typography>
        </div>
        <Divider />
        <div className={classes.message}>
          <Typography variant="subtitle1">Es posible que el documento haya sido eliminado del sistema, o bien que en enlace al que ingresó haya sido corrompido</Typography>
        </div>
      </>
    )
  }

  renderLoader() {
    return (
      <>
        <Typography variant="h3">
          Cargando información de validación...
        </Typography>
        <LoaderAnimator />
      </>
    )
  }

  getRender() {
    const { loading, document } = this.state
    const { match } = this.props
    const code = match.params.code
    const docType = code.split("--")?.[1]
    if (loading) return this.renderLoader()
    if (document?.id && docType === "w") return this.renderDocument()
    if (document?.id && docType === "b") return this.renderDocument()
    if (document?.id && docType === "t") return this.renderTalkOrTraining()
    if (document?.id && docType === "k") return this.renderTalkOrTraining()
    if (document?.id && docType === "l") return this.renderChecklistRegister()
    if (document?.id && docType === "c") return this.renderMultiple()
    return this.renderErrorMessage()
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.card}>
          {this.getRender()}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction())
})

export default connect(null, mapDispatchToProps)(withStyles(style)(ValidateDocument))