import instance2 from "../Utils/instance2"

export const getSubdivisions = (body) => {
  return instance2({
    method: 'get',
    url: "subdivisions",
    params: body
  })
}

export const createSubdivision = body => {
  return instance2({
    method: 'post',
    url: 'subdivisions',
    data: body
  })
}

export const editSubdivision = body => {
  return instance2({
    method: 'put',
    url: 'subdivisions',
    data: body
  })
}

export const deleteSubdivision = body => {
  return instance2({
    method: 'delete',
    url: 'subdivisions',
    data: body
  })
}

export const cloneSubdivisions = body => {
  return instance2({
    method: 'post',
    url: 'subdivisions/clone',
    data: body
  })
}

export const addRequirement = body => {
  return instance2({
    method: 'post',
    url: 'subdivisions/requirement',
    data: body
  })
}

export const getParents = body => {
  return instance2({
    method: 'get',
    url: 'subdivisions/parents',
    params: body
  })
}