import instance from "../../../Utils/instance"

export const createGroup = body => {
  return instance({
    method: 'post',
    url: "activities/create_group_signs.php",
    data: body
  })
}

export const editGroup = body => {
  return instance({
    method: 'post',
    url: "activities/edit_group_signs.php",
    data: body
  })
}


export const deleteGroup = body => {
  return instance({
    method: 'post',
    url: "activities/delete_group_signs.php",
    data: body
  })
}

export const generateRegistersDocument = body => {
  return instance({
    method: 'post',
    url: "activities/create_multiple_document.php",
    data: body
  })
}