import React, { Component } from 'react'
import { Button, Collapse, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import { Check, Close, Settings, } from '@material-ui/icons'
import CritSelector from './CritSelector'
import ImagesSelector from './ImagesSelector'
import autobind, { addToggle } from '../../../Utils/autobind'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import ChecklistContext from '../ChecklistContext'
import AssignFindingInline from './AssignFindingInline'

const style = theme => ({
  container: {
    padding: 12,
    margin: '12px 0',
    background: 'white',
    borderRadius: 8
  },
  mainPart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  button: {
    marginBottom: 6,
    marginLeft: 6,
    padding: '8px 16px'
  },
  buttons: {
    textAlign: 'end',
    display: 'flex',
    "@media (max-width:500px)": {
      flexDirection: 'column'
    },
  },
  red: {
    color: theme.palette.red.main,
  },
  active_red: {
    color: 'white',
    background: theme.palette.red.main,
    '&:hover': {
      background: theme.palette.red.main,
    }
  },
  blue: {
    color: theme.palette.blue.main,
  },
  active_blue: {
    color: 'white',
    background: theme.palette.blue.main,
    '&:hover': {
      background: theme.palette.blue.main,
    }
  },
  green: {
    color: theme.palette.green.main,
  },
  active_green: {
    color: 'white',
    background: theme.palette.green.main,
    '&:hover': {
      background: theme.palette.green.main,
    }
  },
  finalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& > *:first-child': {
      flexGrow: 1
    }
  }
})

class SingleElement extends Component {
  constructor() {
    super()
    this.state = {
      comment: "",
      counter: 0,
    }
    addToggle(SingleElement, this, "assign")
    autobind(SingleElement, this)
  }

  componentDidMount() {
    const { item: { answer } } = this.props
    const comment = answer.comments
    const counter = answer.counter
    this.setState({ comment, counter })
  }

  handleAnswer(value) {
    return async () => {
      const { onAnswer, onCrit } = this.context
      const { item: { id: checklist_item_id, default_criticality: criticality, answer } } = this.props
      const body = {
        checklist_item_id,
        answer: value
      }
      await onAnswer(body)

      // Has default criticality, and should apply now
      if (value === 2 && answer.criticality === null && criticality) {
        const critBody = { criticality: criticality, checklist_item_id }
        onCrit(critBody)
      }

    }
  }

  handleChange(name) {
    return (event) => {
      const { target } = event
      this.setState({ [name]: target.value })
    }
  }

  handleComment() {
    const { comment } = this.state
    const { item } = this.props
    const { onComment } = this.context
    const body = { comments: comment, checklist_item_id: item.id }
    onComment(body)
  }

  handleCount() {
    const { counter } = this.state
    const { item } = this.props
    const { onCount } = this.context
    const body = { counter, checklist_item_id: item.id }
    onCount(body)
  }

  renderButtons() {
    const { classes, item, disabled } = this.props
    const { finished } = this.context
    const value = item?.answer?.value_id
    return (
      <>
        {(!finished || ((finished || disabled) && value === 1)) &&
          <Button
            variant="outlined"
            className={`${classes.button} ${value === 1 ? classes.active_green : classes.green}`}
            onClick={this.handleAnswer(1)}
            disabled={finished || disabled}
          >
            <Check />
          </Button>
        }
        {(!finished || ((finished || disabled) && value === 2)) &&
          <Button
            variant="outlined"
            className={`${classes.button} ${value === 2 ? classes.active_red : classes.red}`}
            onClick={this.handleAnswer(2)}
            disabled={finished || disabled}
          >
            <Close />
          </Button>
        }
        {(!finished || ((finished || disabled) && value === 3)) &&
          <Button
            variant="outlined"
            className={`${classes.button} ${value === 3 ? classes.active_blue : classes.blue}`}
            onClick={this.handleAnswer(3)}
            disabled={finished || disabled}
          >
            N/A
          </Button>
        }
      </>
    )
  }

  render() {
    const { classes, item, disabled } = this.props
    const { finished } = this.context
    const { comment, counter, openAssign } = this.state
    const isAnswered = item?.answer?.value_id !== null
    const shouldOpenDetails = isAnswered && item?.answer?.value_id <= 2
    const isNegative = item?.answer?.value_id === 2

    return (
      <div className={classes.container}>
        <div className={classes.mainPart}>
          <Typography variant="subtitle1">{item?.name} {item?.points > 1 ? ` (${item?.points} Pts)` : ""}</Typography>
          <div className={classes.buttons}>
            {this.renderButtons()}
          </div>
        </div>
        <Collapse in={shouldOpenDetails}>
          {isNegative && <CritSelector disabled={disabled} item={item} />}
          <ImagesSelector disabled={disabled} item={item} />
        </Collapse>
        <Collapse in={isAnswered}>
          <div className={classes.finalSection}>
            <div>
              <SeamlessInput disabled={finished || disabled} label="Comentarios:" onChange={this.handleChange("comment")} onBlur={this.handleComment} value={comment} />
              {item.counter === 1 && isNegative &&
                <div className={classes.comment}>
                  <SeamlessInput type="number" disabled={finished || disabled} label="Cantidad:" onChange={this.handleChange("counter")} onBlur={this.handleCount} value={counter} />
                </div>
              }
            </div>

            {/* {isNegative &&
              <Tooltip title="Configurar Hallazgo">
                <IconButton onClick={this.handleOpenAssign}>
                  <Settings />
                </IconButton>
              </Tooltip>
            } */}
          </div>
        </Collapse>
        <AssignFindingInline open={openAssign} onClose={this.handleOpenAssign} />
      </div>
    )
  }
}

SingleElement.contextType = ChecklistContext

export default withStyles(style)(SingleElement)