import { Collapse, Typography, withStyles } from '@material-ui/core'
import { AccountCircle, CalendarToday, Comment, ErrorOutline, EventAvailable, Visibility } from '@material-ui/icons'
import React from 'react'
import ImageContainer from '../../../Shared/ImageContainer'
import autobind from '../../../Utils/autobind'
import { cardStyle } from '../../Findings/CardStyles'
import { criticities, criticities_index } from '../../Findings/Findings'

class CardInfo extends React.Component {
  constructor() {
    super()
    this.state = {
      openComment: false
    }
    autobind(CardInfo, this)
  }

  handleOpenComment() {
    this.setState({ openComment: !this.state.openComment })
  }

  render() {
    const { classes, element, urls, goto, openForms } = this.props
    const { openComment } = this.state
    const imageUrl = element?.evidence?.includes("amazonaws.com") ? element.evidence : `${urls[1]}${element.evidence}`
    return (
      <div className={classes.resume}>
        <div className={classes.statsContainer}>
          <div className={classes.stat}>
            <CalendarToday />
            <Typography variant="body1">Fecha de detección: {element.date_detected}</Typography>
          </div>
          <div className={classes.stat} onClick={this.handleOpenComment} style={{ cursor: 'pointer' }}>
            <Comment />
            <div>
              <Typography variant="body1">Ver Comentario: </Typography>
            </div>
          </div>
          <Collapse in={openComment} unmountOnExit>
            <Typography variant="body1">{element.comments}</Typography>
          </Collapse>
          <div className={classes.stat}>
            <EventAvailable />
            <Typography variant="body1">Fecha limite: {element.date_limit}</Typography>
          </div>
          <div className={classes.stat}>
            <AccountCircle />
            <Typography variant="body1">Detectado: {element.user_creator}</Typography>
          </div>
          <div className={classes.stat}>
            <AccountCircle />
            <Typography variant="body1">Responsable: {element.user}</Typography>
          </div>
          <div className={classes.stat}>
            <ErrorOutline style={{ color: criticities[criticities_index[element.criticality]] }} />
            <Typography variant="caption">
              {criticities_index[element.criticality]}
            </Typography>
          </div>
          {goto === "findings" &&
            <div className={classes.stat} onClick={openForms} style={{ cursor: 'pointer' }}>
              <Visibility />
              <Typography variant="body1">Ver más información</Typography>
            </div>
          }
        </div>
        <ImageContainer src={element.evidence ? imageUrl : "/noimage.png"} alt="foto" height="64px" width="64px" />
      </div>
    )
  }
}

export default withStyles(cardStyle)(CardInfo)