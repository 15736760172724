import { Box, Button, Slide, Typography } from '@mui/material'
import newTheme from '../../../newTheme'
import Sign from './Sign'
import SignForm from './SignForm'

const css = {
	container: {
		background: 'whitesmoke',
		padding: 2,
		borderRadius: 1,
		margin: '6px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: 2,
		marginBottom: 2
	},
	see_button: {
		flexGrow: 1,
		textAlign: 'end'
	},
	selected: {
		background: newTheme.palette.blue.light
	},
	signs: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2
	}
}

function SignGroup({ group, selected, onSelect }) {

	const out = !!selected && selected !== group.id
	const iAmSelected = !!selected && selected === group.id
	const container = iAmSelected ? { ...css.container, ...css.selected } : css.container
	const signs = group.signs || []
	console.log(group)
	console.log(signs)
	return (
		<Slide in={!out} direction="right" unmountOnExit>
			<Box>
				{iAmSelected &&
					<SignForm group={group} />
				}
				<Box sx={container}>
					<Box sx={css.header}>
						<Typography variant='h4'>{group.name}</Typography>
						<Box sx={css.see_button}>
							<Button
								variant={iAmSelected ? "contained" : "outlined"}
								color={iAmSelected ? "secondary" : "inherit"}
								onClick={() => onSelect(group.id)}
							>
								{iAmSelected ? "Cerrar" : "Agregar Firmas"}
							</Button>
						</Box>
					</Box>
					<Box sx={css.signs}>
						{signs.map(sign => <Sign sign={sign} />)}
					</Box>
				</Box>
			</Box>
		</Slide >
	)
}

export default SignGroup