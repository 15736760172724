import store from "../store"
import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"
import { enqueueRequest } from '../Utils/offline'

export const setChecklistParticipants = body => {
  return instance2({
    method: 'post',
    url: 'checklists/add_participants.php',
    data: body
  })
}

export const getChecklist = body => {
  return instance2({
    method: 'get',
    url: 'checklists/get.php',
    params: body
  })
}

export const getChecklistNew = body => {
  return instance2({
    method: 'get',
    url: 'checklists/show',
    params: body
  })
}

export const editChecklist = body => {
  return instance2({
    method: 'post',
    url: 'checklists/edit.php',
    data: body
  })
}

export const answerChecklist = body => {
  const status = store.getState().online?.status
  const request = { method: 'post', url: 'checklists/answer.php', data: body }
  if (!status) return enqueueRequest(request, "Respuesta registro o formulario")
  return instance2(request)
}

export const critChecklist = body => {
  const status = store.getState().online?.status
  const request = { method: 'post', url: 'checklists/criticality.php', data: body }
  if (!status) return enqueueRequest(request, "Criticidad elemento de registro o formulario")
  return instance2(request)
}

export const addFileToElementChecklist = (body, rawBody, index) => {
  const status = store.getState().online?.status
  const url = `checklists/file${index}.php`
  const request = { method: 'post', url, data: !status ? rawBody : body }
  if (!status) return enqueueRequest(request, "Imágen elemento de registro o formulario")
  return instance2(request)
}

export const commentElement = body => {
  const status = store.getState().online?.status
  const request = { method: 'post', url: 'checklists/comments.php', data: body }
  if (!status) return enqueueRequest(request, "Comentario elemento registro o formulario")
  return instance2(request)
}

export const finishChecklist = body => {
  const status = store.getState().online?.status
  const request = { method: 'post', url: 'checklists/finish.php', data: body }
  if (!status) return enqueueRequest(request, "Finalizar registro o formulario")
  return instance2(request)
}

export const saveChecklist = body => {
  const status = store.getState().online?.status
  const request = { method: 'post', url: 'checklists/extra_fields.php', data: body }
  if (!status) return enqueueRequest(request, "Guardar registro o formulario")
  return instance2(request)
}

export const createChecklist = body => {
  return instance({
    method: 'post',
    url: 'checklists/create.php',
    data: body
  })
}

export const deleteChecklist = body => {
  return instance({
    method: 'post',
    url: 'checklists/delete.php',
    data: body
  })
}

export const counterChecklist = body => {
  const status = store.getState().online?.status
  const request = { method: 'post', url: 'checklists/counter.php', data: body }
  if (!status) return enqueueRequest(request, "Finalizar registro o formulario")
  return instance2(request)
}

export const setSubdivision = body => {
  const status = store.getState().online?.status
  const request = { method: 'post', url: 'checklists/subdivision', data: body }
  if (!status) return enqueueRequest(request, "Asignar subdivisión a registro")
  return instance2(request)
}

export const createSubtitle = body => {
  return instance({
    method: 'post',
    url: 'settings/checklists/create_subtitle.php',
    data: body
  })
}

export const editSubtitle = body => {
  return instance2({
    method: 'post',
    url: 'settings/checklists/edit_subtitle.php',
    data: body
  })
}

export const deleteSubtitle = body => {
  return instance({
    method: 'post',
    url: 'settings/checklists/delete_subtitle.php',
    data: body
  })
}

export const getSettingsChecklist = body => {
  return instance2({
    method: 'get',
    url: 'settings/checklists/get.php',
    params: body
  })
}

export const setChecklistMetadata = body => {
  return instance({
    method: 'post',
    url: 'checklists/metadata.php',
    data: body
  })
}

export const createFindingFromChecklist = body => {
  const status = store.getState().online?.status
  const request = {
    method: 'post',
    url: 'checklists/create_finding.php',
    data: body
  }
  if (!status) return enqueueRequest(request, "Crear hallazgo en registro o formulario", "2")
  return instance2(request)
}

export const setChecklistSupply = body => {
  return instance2({
    method: 'post',
    url: 'checklists/set_supply',
    data: body
  })
}

export const checklistReportAmount = body => {
  return instance2({
    method: 'post',
    url: 'checklists/amount_report',
    data: body
  })
}

export const checklistReportPercent = body => {
  return instance2({
    method: 'post',
    url: 'checklists/percent_report',
    data: body
  })
}

export const setChecklistNotifications = body => {
  return instance2({
    method: 'post',
    url: 'checklists/notifications',
    data: body
  })
}

export const groupReportAmount = body => {
  return instance2({
    method: 'post',
    url: 'checklists/amount_report/group',
    data: body
  })
}

export const groupReportPercent = body => {
  return instance2({
    method: 'post',
    url: 'checklists/percent_report/group',
    data: body
  })
}

export const getCalendar = body => {
  return instance2({
    method: 'get',
    url: 'checklists/calendar',
    params: body
  })
}

export const fullChecklistReportPercent = body => {
  return instance2({
    responseType: "blob",
    method: 'post',
    url: 'checklists/full_percent_report',
    data: body
  })
}