// import stateMaker from "../Utils/stateMaker";

const MainReducer = (state = { snackbar: undefined }, action) => {
    switch (action.type) {
        case "SETUP_SNACK":
            const newState = { ...state };
            newState.snackbar = action.payload;
            return newState;
        default:
            return state;
    }
};

export default MainReducer;
