import { Box, Button, Collapse, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createEvaluation, getBaseEvaluations } from '../../API/evaluations'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { filterActiveBranches, filterActiveWorkers } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'

const css = {
	container: {
		padding: 2
	}
}

function NewEvaluation({ open, onClose, branches, branch, onCreate }) {

	const [evaluations, setEvaluations] = useState([])
	const [params, setParams] = useState({ branch_id: branch.id })

	useEffect(() => {
		async function fetchData() {
			const response = await getBaseEvaluations()
			setEvaluations(response.data.info)
		}

		fetchData()
	}, [])

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	function handleCreate() {
		const body = { ...params }
		onCreate(body)
	}

	const workers = filterActiveWorkers()
	const real_branches = filterActiveBranches(branches)

	return (
		<Collapse in={open} unmountOnExit>
			<Paper sx={css.container}>
				<Typography variant='h4'>Nueva evaluación</Typography>
				<SelectInput onChange={onChange} value={params.base_evaluation_id} name="base_evaluation_id" label="Seleccionar evaluación" options={transformToOptions(evaluations)} />
				<SelectInput onChange={onChange} value={params.branch_id} name="branch_id" label="Seleccionar centro de trabajo" options={transformToOptions(real_branches)} />
				<SelectInput onChange={onChange} value={params.user_id} name="user_id" label="Seleccionar evaluado" options={transformToOptions(workers)} />
				<Box style={{ textAlign: 'end' }}>
					<Button color="primary" variant="contained" onClick={handleCreate}>
						Crear evaluación
					</Button>
				</Box>
			</Paper>
		</Collapse>
	)
}

const mapStateToProps = state => ({
	branches: state.enterprise?.branches || [],
	branch: state.branch?.global || {}
})

export default connect(mapStateToProps)(NewEvaluation)