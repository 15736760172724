import React, { Component } from 'react'
import { Collapse, Divider, IconButton, Radio, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline } from '@material-ui/icons'
import autobind from '../../../../../Utils/autobind'
import TextInput from '../../../../../Shared/Inputs/TextInput'
import SelectInput from '../../../../../Shared/Inputs/SelectInput'

const style = () => ({
  radio: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonInput: {
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: 12
    }
  },
  divider: {
    margin: '12px 0'
  }
})

class SelectStepCreate extends Component {
  constructor() {
    super()
    this.state = {
      new_option: null,
      option: null,
      options: []
    }
    autobind(SelectStepCreate, this)
  }

  componentDidMount() {
    const { params } = this.props
    console.log(params)
    if (params?.entity === "workers") return this.setState({ option: 1 })
    if (!!params?.options) return this.setState({ option: 0, options: params.options })
    return true
  }

  handleChange(event) {
    const { target } = event
    this.setState({ new_option: target.value })
  }

  handleChangeOption(option) {
    return () => {
      this.setState({ option })
    }
  }

  handleAddOption() {
    const { new_option, options } = this.state
    const { onChange } = this.props
    if (new_option === null) return false
    options.push(new_option)
    this.setState({ options, new_option: null })
    const event = {
      target: {
        name: "options",
        value: options
      }
    }
    onChange(event)
  }

  render() {
    const { classes, params, onChange } = this.props
    const { option, new_option, options } = this.state
    return (
      <div className={classes.container}>
        <TextInput label="Titulo" name="label" value={params.label} onChange={onChange} />
        <Typography variant="subtitle1">A continuación seleccione</Typography>
        <div className={classes.radio} onClick={this.handleChangeOption(0)}>
          <Radio checked={option === 0} />
          <Typography variant="body1">Crear opciones</Typography>
        </div>
        <div className={classes.radio} onClick={this.handleChangeOption(1)}>
          <Radio checked={option === 1} />
          <Typography variant="body1">Usar entidades de la plataforma (Ej: seleccionar un colaborador)</Typography>
        </div>
        <Collapse in={option === 0}>
          <Divider className={classes.divider} />
          <Typography variant="subtitle1">A continuación agregue sus opciones</Typography>
          <div className={classes.buttonInput}>
            <TextInput label="Nueva opcion" name="new_option" value={new_option} onChange={this.handleChange} />
            <IconButton onClick={this.handleAddOption}>
              <AddCircleOutline />
            </IconButton>
          </div>
          <Typography variant="body1">{options.join(", ")}</Typography>
        </Collapse>
        <Collapse in={option === 1}>
          <Divider className={classes.divider} />
          <Typography variant="subtitle1">A continuación escoja</Typography>
          <div className={classes.buttonInput}>
            <SelectInput label="Entidad a seleccionar" name="entity" value={params.entity} onChange={onChange} options={[
              { label: "Colaboradores", value: "workers" }
            ]} />
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(SelectStepCreate)