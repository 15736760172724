import { Delete } from '@material-ui/icons'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import useToggle from '../../Hooks/ToogleHook'

const css = {
	dialog: {
		padding: 3
	},
	title: {
		marginBottom: 3
	},
	buttons: {
		display: 'flex',
		justifyContent: 'space-between'
	}
}

function DeleteButton({ onClick, sx }) {

	const [open, setOpen] = useToggle(false)

	return (
		<>
			<IconButton sx={sx} onClick={setOpen}>
				<Delete />
			</IconButton>
			<Dialog open={open} onClose={setOpen} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography sx={css.title} variant='h4'>¿Deseas eliminar este elemento?</Typography>
					<Box sx={css.buttons}>
						<Button color="warning" variant="outlined" onClick={onClick}>
							Si, eliminar
						</Button>
						<Button color="info" variant="contained" onClick={setOpen}>
							No, cancelar
						</Button>
					</Box>
				</Box>
			</Dialog>
		</>
	)
}

export default DeleteButton