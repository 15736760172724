import React, { Component } from 'react'
import { Badge, Paper, Typography, withStyles } from '@material-ui/core'
import { getFindingsAction } from '../../Actions/FindingActions'
import { getNonConformitiesAction } from '../../Actions/NoConfActions'
import { getPendingChecklistsAction } from '../../Actions/CheckListActions'
import { connect } from 'react-redux'
import autobind from '../../Utils/autobind'
import moment from 'moment'
import { getTalksAction } from '../../Actions/TalkActions'
import { getTrainingsAction } from '../../Actions/TrainingActions'
import { FiAlertCircle, FiCheckSquare, FiLogOut } from "react-icons/fi"
import Pending from './Pendings/Pending'
import LoaderAnimator from '../../Shared/LoaderAnimator'

const style = theme => ({
  message: {
    padding: 24,
    fontSize: 14
  },
  filter: {
    '& > svg': {
      height: 24,
      width: 24
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F2F5FF',
    borderRadius: 12,
    padding: 12,
    height: 56,
    width: 56,
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer'
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: '24px 0'
  },
  selected: {
    transform: 'scale(1.3)',
    zIndex: 2,
    '& > *': {
      color: 'white'
    }
  },
  badge: {
    background: '#6e6e6e'
  },
  green: {
    background: theme.palette.green.main,
  },
  red: {
    background: theme.palette.red.main,
  },
  blue: {
    background: theme.palette.blue.main,
  },
  desktopContainer: {
    display: 'flex',
    minHeight: 460,
    '& > .filters': {
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      display: 'flex',
      minWidth: 120
    },
    '& > .pendings': {
      marginLeft: 24,
      flexGrow: 1,
      maxHeight: 460,
      overflow: 'auto'
    }
  }
})

class Pendings extends Component {
  constructor() {
    super()
    this.state = {
      openList: false,
      selectedFilter: 1,
      documents: [],
      checklists: [],
      findings: [],
      unconformities: [],
      talks: [],
      trainings: [],
    }
    autobind(Pendings, this)
  }

  componentDidMount() {
    const { values } = this.props
    this.setState({ ...values })
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props
    const { values: preValues } = prevProps
    if (JSON.stringify(values) !== JSON.stringify(preValues)) {
      this.setState({ ...values })
    }
  }

  handleGotoPending(link) {
    return () => {
      const { history } = this.props
      history.push(link)
    }
  }

  handleOpenList() {
    this.setState({ openList: !this.state.openList })
  }

  handleChangeFilter(value) {
    return () => {
      this.setState({ selectedFilter: value })
    }
  }

  getAllPendings() {
    const { user, disableChecklists, disableFindings, disableUnconformities, values } = this.props
    const { documents, checklists, findings, unconformities, talks, trainings } = values
    const user_id = user.account.user.id
    const allChecklists = checklists || []
    const allFindings = findings || []
    const allNonConformities = unconformities || []
    const allTalks = talks || []
    const allTrainings = trainings || []
    const allDocuments = documents.filter(document => !document.signs.map(s => String(s.user_id)).includes(String(user.account.user.id)))

    const pendingChecklists = allChecklists

    const pendingSigns = allChecklists.filter(checklist => {
      const allSigns = checklist?.group_signs?.map(group => group?.signs)?.flat()
      return (
        checklist?.requested_participants?.map(String)?.includes(user_id.toString()) &&
        !allSigns?.map(s => s.user_id)?.map(String)?.includes(user_id.toString())
      )

    })

    const pendingFindings = allFindings.filter(finding => {
      const isSameCreator = finding.user_creator_id?.toString() === user_id.toString()
      const isSameUser = finding.user_id?.toString() === user_id.toString()
      // const isMine = isSameCreator || isSameUser
      const isNotDone = finding.status !== "Aprobado" && finding.status !== "Terminado" && finding.status !== "Desestimado"
      const isReadyForApprobal = finding.status === "Terminado"
      return (isSameUser && isNotDone) || (isSameCreator && isReadyForApprobal)
    })

    const pendingNonConformities = allNonConformities.filter(nonConformity => {
      const isSameCreator = nonConformity.user_creator_id?.toString() === user_id.toString()
      const isSameUser = nonConformity.user_id?.toString() === user_id.toString()
      const isMine = isSameCreator || isSameUser
      const isNotDone = nonConformity.status !== "Aprobado" && nonConformity.status !== "Terminado" && nonConformity.status !== "Desestimado"
      return isMine && isNotDone
    })

    const pendingTalks = allTalks.filter(talk => {
      const isSameUser = talk.user_id?.toString() === user_id.toString()
      const isInvited = talk.requested_participants.map(id => id.toString()).includes(user_id.toString())
      const isNotDone = talk.date_saved === "0000-00-00"
      const hasSigns = !!talk.participants_signs.find(sign => sign.user_id?.toString() === user_id.toString())
      const isInvitedAndHasntSigned = isInvited && !hasSigns
      return (isSameUser || isInvitedAndHasntSigned) && isNotDone
    })

    const pendingTrainings = allTrainings.filter(training => {
      const isSameUser = training.user_id?.toString() === user_id.toString()
      const isInvited = training.requested_participants.map(id => id.toString()).includes(user_id.toString())
      const isNotDone = training.date_saved === "0000-00-00"
      const hasSigns = !!training.participants_signs.find(sign => sign.user_id?.toString() === user_id.toString())
      const isInvitedAndHasntSigned = isInvited && !hasSigns
      return (isSameUser || isInvitedAndHasntSigned) && isNotDone
    })

    const allPendings = []

    if (!disableChecklists) {
      pendingChecklists.filter(checklist => {
        const threeDaysAhead = moment(new Date()).add(3, 'days')
        return moment(checklist.date_scheduled) <= threeDaysAhead
      }).forEach(checklist => {
        const pendingInfo = {
          name: "Registro o Formulario",
          link: `/checklists/${checklist.id}`,
          content: <>{checklist.activity}<br /><p style={{ fontSize: '0.8rem', color: 'grey', marginTop: -3 }}>{checklist.preventive_measure}</p></>
          ,
          date: checklist.date_scheduled,
          info: checklist
        }
        allPendings.push(pendingInfo)
      })
    }

    pendingSigns.filter(checklist => {
      const threeDaysAhead = moment(new Date()).add(3, 'days')
      return moment(checklist.date_scheduled) <= threeDaysAhead
    }).forEach(checklist => {
      const pendingInfo = {
        name: "Solicitud: firma de registro o formulario",
        link: `/checklists/${checklist.id}`,
        content: `${checklist.activity}`,
        date: checklist.date_scheduled
      }
      allPendings.push(pendingInfo)
    })

    if (!disableFindings) {
      pendingFindings.forEach(finding => {
        const isSameCreator = finding.user_creator_id?.toString() === user_id.toString()
        const isSameUser = finding.user_id?.toString() === user_id.toString()
        const isMine = isSameCreator && !isSameUser
        const isReadyForApprobal = finding.status === "Terminado"
        const pendingInfo = {
          name: `Hallazgo ${isMine ? "detectado por tí" : ""} ${isReadyForApprobal ? "listo para ser aprobado" : ""}`,
          link: `/findings`,
          content: `${finding.checklist_item_name || finding.free_description}`,
          date: finding.date_limit
        }
        allPendings.push(pendingInfo)
      })
    }
    if (!disableUnconformities) {
      pendingNonConformities.forEach(unconformity => {
        const pendingInfo = {
          name: "No Conformidad",
          link: `/unconformities`,
          content: `${unconformity.name}`,
          date: unconformity.date_limit
        }
        allPendings.push(pendingInfo)
      })
    }

    pendingTalks.forEach(talk => {
      const pendingInfo = {
        name: "Charla",
        link: `/talks/${talk.id}`,
        content: `${talk.name}`,
        date: talk.date_scheduled
      }
      allPendings.push(pendingInfo)
    })

    pendingTrainings.forEach(training => {
      const pendingInfo = {
        name: "Capacitación",
        link: `/trainings/${training.id}`,
        content: `${training.name}`,
        date: training.date_scheduled
      }
      allPendings.push(pendingInfo)
    })

    allDocuments.forEach(document => {
      const pendingInfo = {
        name: "Solicitud de firma",
        link: `/users/signs?id=${document.id}`,
        content: `${document.name}`,
        date: document.date
      }
      allPendings.push(pendingInfo)
    })

    allPendings.sort((a, b) => {
      if (!moment(a.date).isValid()) {
        return 1
      }
      if (!moment(b.date).isValid()) {
        return -1
      }
      if (moment(a.date) > moment(b.date)) {
        return 1
      }
      if (moment(a.date) < moment(b.date)) {
        return -1
      }
      return 0
    })

    return allPendings
  }

  filterPendings(info, forcedFilter = null) {
    let filter = forcedFilter
    if (filter === null) {
      const { selectedFilter } = this.state
      filter = selectedFilter
    }
    if (filter === 0) return info.filter(pending => moment(new Date()).isAfter(moment(pending.date), "days"))
    if (filter === 1) return info.filter(pending => moment(new Date()).isSame(moment(pending.date), "days"))
    if (filter === 2) return info.filter(pending => moment(new Date()).isBefore(moment(pending.date), "days"))
  }

  renderPendings() {
    const isMobile = window.innerWidth <= 500
    const allPendings = this.getAllPendings()
    const finalPendings = this.filterPendings(allPendings)
    if (finalPendings.length === 0) {
      const img = Math.round(Math.random())
      return isMobile ? <img src={`/mono${img + 1}.png`} alt="no tienes pendientes" width="100%" /> : <img src={`/mono${img + 1}.png`} alt="no tienes pendientes" height="100%" />
    }
    const initialGroup = finalPendings.map((pending, index) => {
      return <Pending pending={pending} onClick={this.handleGotoPending} key={pending.link + index} />
    })
    return (
      <>
        {initialGroup}
      </>
    )
  }

  render() {
    const { classes, loading } = this.props
    const { selectedFilter } = this.state
    const isMobile = window.innerWidth <= 500
    const allPendings = this.getAllPendings()
    const finalPendings = this.filterPendings(allPendings, 0)
    const finalActual = this.filterPendings(allPendings, 1)
    const finalNext = this.filterPendings(allPendings, 2)
    if (loading) return (
      <div className={isMobile ? classes.container : classes.desktopContainer}>
        <LoaderAnimator />
      </div>
    )
    return (
      <div className={isMobile ? classes.container : classes.desktopContainer}>
        <div className={`${classes.filters} filters`}>
          <Badge
            badgeContent={<Typography variant="subtitle1" style={{ color: 'white', fontWeight: 600 }}>{finalPendings?.length}</Typography>}
            color="secondary"
          >
            <div>
              <Paper elevation={3}
                className={`${classes.filter} ${selectedFilter === 0 ? `${classes.selected} ${classes.red}` : ""}`}
                onClick={this.handleChangeFilter(0)}
              >
                <FiAlertCircle />
                <Typography variant="subtitle1">Vencidas</Typography>
              </Paper>
            </div>
          </Badge>
          <Badge
            badgeContent={<Typography variant="subtitle1" style={{ color: 'white', fontWeight: 600 }}>{finalActual?.length}</Typography>}
            color="primary"
            classes={{ badge: classes.badge }}
          >
            <div>
              <Paper
                elevation={3}
                className={`${classes.filter} ${selectedFilter === 1 ? `${classes.selected} ${classes.green}` : ""}`}
                onClick={this.handleChangeFilter(1)}
              >
                <FiCheckSquare />
                <Typography variant="subtitle1">Actuales</Typography>
              </Paper>
            </div>
          </Badge>
          <Badge
            badgeContent={<Typography variant="subtitle1" style={{ color: 'white', fontWeight: 600 }}>{finalNext?.length}</Typography>}
            color="primary"
            classes={{ badge: classes.badge }}
          >
            <div>
              <Paper
                elevation={3}
                className={`${classes.filter} ${selectedFilter === 2 ? `${classes.selected} ${classes.blue}` : ""}`}
                onClick={this.handleChangeFilter(2)}
              >
                <FiLogOut />
                <Typography variant="subtitle1">Próximas</Typography>
              </Paper>
            </div>
          </Badge>
        </div>
        <div className="pendings">
          {this.renderPendings()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checklists: state.checklists,
  findings: state.findings,
  unconformities: state.unconformities,
  user: state.user,
  talks: state.talks,
  trainings: state.trainings
})

const mapDispatchToProps = dispatch => ({
  getFindings: () => dispatch(getFindingsAction()),
  getNonConformities: () => dispatch(getNonConformitiesAction()),
  getChecklists: () => dispatch(getPendingChecklistsAction()),
  getTalks: () => dispatch(getTalksAction()),
  getTrainings: () => dispatch(getTrainingsAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Pendings))