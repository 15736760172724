import { Delete, FileCopyOutlined, KeyboardArrowDown, NotificationsActiveOutlined } from '@material-ui/icons'
import { Box, Collapse, IconButton, Paper, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { cloneChecklistCategory, deleteChecklistCategory } from '../../../API/new_checklists'
import useToggle from '../../../Hooks/ToogleHook'
import { ChecklistContext } from '../ChecklistView'
import Field from './Field'

const css = {
	container: {
		padding: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function FieldCategory({ category, fields }) {

	const { setChecklist } = useContext(ChecklistContext)

	async function cloneCategory() {
		const body = { ...category }
		const response = await cloneChecklistCategory(body)
		setChecklist(response.data.info)
	}

	async function deleteCategory() {
		const body = { ...category }
		const response = await deleteChecklistCategory(body)
		setChecklist(response.data.info)
	}

	if (!fields.length) return null

	return (
		<Box style={{ background: category.color, padding: 12, borderRadius: 6, margin: '12px 0' }}>
			<Box sx={css.header}>
				<Typography variant='h4' style={{ marginBottom: 6 }} >{category?.name}</Typography>
				<IconButton onClick={deleteCategory} disabled={!category?.deletable}>
					<Delete />
				</IconButton>
			</Box>
			{fields.map(field => <Field field={field} key={field.label} />)}
			<Box style={{ textAlign: 'end' }}>
				<IconButton onClick={cloneCategory}>
					<FileCopyOutlined />
				</IconButton>
			</Box>
		</Box>
	)
}

function ExtraFields() {
	const { checklist, finished } = useContext(ChecklistContext)
	const [open, setOpen] = useToggle(false)

	const categories = checklist.checklist_field_categories || []
	categories.sort((c1, c2) => {
		if (c1.color > c2.color) return -1
		if (c1.color < c2.color) return 1
		if (moment(c1.created_at) > moment(c2.created_at)) return 1
		if (moment(c1.created_at) < moment(c2.created_at)) return -1
		return 0
	})
	const fields = checklist.checklist_fields || []
	const free_fields = fields.filter(field => field.checklist_field_category_id === null) || []

	return (
		<Paper sx={css.container}>
			<Box sx={css.header} onClick={setOpen}>
				<Typography variant='h1'>Información a completar</Typography>
				{!finished &&
					<IconButton>
						<KeyboardArrowDown />
					</IconButton>
				}
			</Box>
			{(open || finished) &&
				<Box>
					<Box sx={css.fields}>
						{categories.map(category => {
							const category_fields = fields.filter(field => field.checklist_field_category_id === category.id)
							return (
								<FieldCategory key={category.id} category={category} fields={category_fields} />
							)
						})}
						<Box style={{ padding: 12, borderRadius: 6, margin: '12px 0' }}>
							{free_fields.map(field => <Field field={field} key={field.id} />)}
						</Box>
					</Box>
					<Box style={{ textAlign: 'end' }}>
						<IconButton>
							<NotificationsActiveOutlined />
						</IconButton>
					</Box>
				</Box>
			}
		</Paper>
	)
}

export default ExtraFields