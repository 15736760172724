import React, { Component } from 'react'
import { Button, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { Delete, KeyboardArrowRight } from '@material-ui/icons'
import DateTimeInput from '../../../Shared/Inputs/DateTimeInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import autobind from '../../../Utils/autobind'
import MySignaturePad from '../../Talks/MySignaturePad'
import moment from 'moment'
import { callSnackbar } from '../../../Utils/snackbar'
import { connect } from 'react-redux'
import { openGlobalDialog } from '../../../Actions/GlobalActions'
import TNTContext from '../TNTContext'
import { notifyEnd } from '../../../API/notifications'
import { uploadFile } from '../../../API/users'
import { completePath } from '../../../Utils/functions'

const style = () => ({
  container: {
    margin: '24px 0'
  },
  paper: {
    padding: 12,
    marginTop: 12
  },
  short: {
    maxWidth: 500
  },
  hidden: {
    display: 'none'
  },
  signatureContainer: {
    padding: 12,
    display: "flex",
    justifyContent: "center",
  },
  button: {
    textAlign: 'end'
  },
  fileValue: {
    padding: 12,
    background: '#f2f3f8',
    borderRadius: 5,
    display: 'flex',
    margin: '12px 0',
    maxWidth: 500,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  signs: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
    margin: 12,
    "@media (max-width:500px)": {
      width: '100%',
      margin: '12px 0',
    },
  },
  signInfo: {
    '& > *': {
      margin: '3px 0'
    }
  },
  userSigns: {
    margin: '12px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    background: '#f2f3f8',
    borderRadius: 5
  },
})

function simulateClick() {
  document.getElementById("saved-file").click()
}

class ResponsibleResume extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(ResponsibleResume, this)
  }
  componentDidMount() {
    const { element } = this.props
    const params = { date_start: element.date_scheduled, file_name: element.file_saved }
    this.setState({ params })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleSeeFile(file) {
    return () => {
      const { urls } = this.context
      const url = completePath(file, process.env.REACT_APP_IMG_URL + urls.document)
      window.open(url)
    }
  }

  handleDownload() {
    return () => {

    }
  }

  handleGetTypeName(singular = true) {
    const { elementType } = this.context
    if (singular) return elementType === "talk" ? "Charla" : "Capacitación"
    return elementType === "talk" ? "Charlas" : "Capacitaciones"
  }

  async handleSelectFile(event) {
    const { target } = event
    const { params } = this.state
    const { element, onUpload } = this.props
    const file = target.files[0]
    const body = new FormData()
    body.append("file", file)
    const response = await uploadFile(body)
    if (response.data.status !== "success") return null
    const fileName = response.data.info
    const newFileNames = params.file_name ? params.file_name.split("#") : []
    newFileNames.push(fileName)
    params.file_name = newFileNames.join("#")
    const file_body = {
      id: element.id,
      file_name: newFileNames.join("#")
    }
    onUpload(file_body)
    this.setState({ params })
  }

  handleRemoveFile(index) {
    return () => {
      const { params } = this.state
      const newFileNames = params.file_name ? params.file_name.split("#") : []
      newFileNames.splice(index, 1)
      params.file_name = newFileNames.join("#")
      this.setState({ params })
    }
  }

  handleSign(value) {
    const { onSign, user, element } = this.props
    const signatures = element.user_sign
    if (!signatures.map(sign => sign.user_id?.toString()).includes(user.account.user.id.toString())) {
      fetch(value)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "File name", { type: "image/png" })
          const data = new FormData()
          data.append("user_id", user.account.user.id)
          data.append("file", file)
          data.append("name", user.account.user.name)
          data.append("id", element.id)
          data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
          onSign(data)
        })
    } else {
      callSnackbar("Solo se puede firmar una vez", "warning")
    }
  }

  handleSignUserRegistered() {
    const { onSign, user, workers, element } = this.props
    const signatures = element.user_sign
    const allWorkers = workers.all || []
    const currentWorker = allWorkers.find(worker => worker.id === user.account.user.id)
    const sign = currentWorker?.signs[0]
    if (!sign) return null
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
    if (!signatures.map(sign => sign.user_id?.toString()).includes(currentWorker.id.toString())) {
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "File name", { type: "image/png" })
          const data = new FormData()
          data.append("user_id", currentWorker.id)
          data.append("file", file)
          data.append("name", currentWorker?.name?.split("-")[0])
          data.append("id", element.id)
          data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
          onSign(data)
        })
    } else {
      callSnackbar("Solo se puede firmar una vez", "warning")
    }
  }

  handleEnd() {
    const { params } = this.state
    const { user, onEnd, openGlobalDialog, element } = this.props
    const { elementType } = this.context

    const allSigns = element?.participants_signs || []
    const signsIds = allSigns.map(s => s.user_id)
    const participants = element?.requested_participants || []

    let isFullySigned = true
    participants.forEach(participant => {
      if (!signsIds.includes(participant)) {
        isFullySigned = false
      }
    })

    const body = {}
    body["name"] = user.account.user.name
    body["id"] = element.id
    body["evidence"] = params.file_name
    body["date_start"] = moment(params.date_start).format("YYYY-MM-DD HH:mm:ss")
    body["date_end"] = moment(params.date_end).format("YYYY-MM-DD HH:mm:ss")
    body["date_saved"] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    body["participants_observations"] = params.participant_observation || ""
    body["user_observations"] = params.user_observation || ""

    if (!isFullySigned) {
      openGlobalDialog(onEnd, body, {
        message: `Esta ${this.handleGetTypeName()} no ha sido firmada por todos los participantes añadidos, seguro que deseas terminar la ${this.handleGetTypeName()}?`,
        title: `Faltan firmas por registrar!`,
        submit: `Si, terminar ${this.handleGetTypeName()}`
      })
    } else {
      onEnd(body)
      notifyEnd(user.account.user, elementType === "talk" ? "Charla" : "Capacitación", window.location.href, element.name)
    }


  }

  renderUserSignatures() {
    const { classes, element, workers } = this.props
    const { urls } = this.context
    const signatures = element.user_sign || []
    return signatures.map((sign) => {
      const imgUrl = process.env.REACT_APP_IMG_URL + urls.signs + '/' + sign.sign
      const selectedWorker = workers.all.find(worker => worker.id?.toString() === sign.user_id?.toString())
      return (
        <Paper className={classes.signs}>
          <img src={imgUrl} height="75px" width="150px" alt="firma" />
          <div className={classes.signInfo}>
            <Typography variant="body2">{sign.name}</Typography>
            <Typography variant="body1">{selectedWorker?.position}</Typography>
            <Typography variant="body1">{selectedWorker?.rut}</Typography>
            <Typography variant="caption">{sign.date}</Typography>
          </div>
        </Paper>
      )
    })
  }



  render() {
    const { classes, element } = this.props
    const { elementType } = this.context
    const { params } = this.state
    const isTooEarly = moment().isBefore(moment(element.date_scheduled))
    return (
      <div className={classes.container}>
        <Typography variant="h1">Firma supervisor e información final</Typography>
        <Paper className={classes.paper}>
          <div className={classes.userInputs}>
            <div className={classes.short}>
              <DateTimeInput name="date_start" value={params.date_start} onChange={this.handleChange} label="Fecha y hora de inicio" />
            </div>
            <div className={classes.short}>
              <DateTimeInput name="date_end" value={params.date_end} onChange={this.handleChange} label="Fecha y hora de término" />
            </div>
          </div>
          <div className={classes.userInputs}>
            <div className={classes.userInput}>
              <TextInput options={{ multiline: true, rows: 4 }} name="participant_observation" value={params.participant_observation} onChange={this.handleChange} label="Observación participantes" />
            </div>
            <div className={classes.userInput}>
              <TextInput options={{ multiline: true, rows: 4 }} name="user_observation" value={params.user_observation} onChange={this.handleChange} label="Observación supervisor" />
            </div>
          </div>
          <div className={classes.file}>
            <input type="file" id="saved-file" className={classes.hidden} onChange={this.handleSelectFile} />
            <div className={classes.fileInput}>
              <Typography variant="subtitle1">Fotos:</Typography>
              <Button onClick={simulateClick} color="primary" variant="outlined" style={{ marginBottom: 12 }}>
                Agregar Foto
              </Button>
              {params.file_name ?
                params.file_name.split("#").map((file, index) => {
                  return (
                    <div className={classes.fileValue}>
                      <Typography variant="body1">{`${file}`}</Typography>
                      <div>
                        <IconButton onClick={this.handleSeeFile(file)}>
                          <KeyboardArrowRight />
                        </IconButton>
                        <IconButton onClick={this.handleRemoveFile(index)}>
                          <Delete />
                        </IconButton>
                      </div>
                    </div>
                  )
                })
                :
                <Typography variant="body1">{`No hay fotos seleccionadas`}</Typography>
              }
            </div>
          </div>
          {isTooEarly ?
            <div>
              <Typography variant="h4" style={{ margin: '24px 0' }}>
                {`Aún no se pueden agregar firmas, debes esperar 
                hasta las 
                ${moment(element.date_scheduled).format("HH:mm [del] dddd DD [de] MMM [del] YYYY")}`}
              </Typography>
            </div>
            :
            <>
              <div className={classes.signatureContainer}>
                <Typography variant="h4">Firma Supervisor</Typography>
              </div>
              <div className={classes.signatureContainer}>
                <MySignaturePad
                  clearButton="true"
                  save={this.handleSign}
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={this.handleSignUserRegistered}>
                  Usar firma registrada
                </Button>
              </div>
            </>
          }
          <div className={classes.userSigns}>
            {this.renderUserSignatures()}
          </div>
          <div className={classes.button}>
            <Button color="primary" variant="contained" onClick={this.handleEnd} disabled={element?.user_sign?.length === 0}>
              Terminar {elementType === "talk" ? "Charla" : "Capacitación"}
            </Button>
          </div>
        </Paper>
      </div>
    )
  }
}

ResponsibleResume.contextType = TNTContext

const mapStateToProps = state => ({
  user: state.user,
  workers: state.workers
})

const mapDispatchToProps = dispatch => ({
  openGlobalDialog: (action, body, options) => dispatch(openGlobalDialog(action, body, options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ResponsibleResume))