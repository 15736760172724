import { Button } from '@material-ui/core'
import { Box, Collapse, Paper } from '@mui/material'
import { useState } from 'react'
import SelectInput from '../../Shared/Inputs/SelectInput'
import TextInput from '../../Shared/Inputs/TextInput'
import { transformToOptions } from '../../Utils/functions'

const css = {
	paper: {
		padding: 2,
		margin: '24px 0',
	},
	buttons: {
		textAlign: 'end'
	}
}

const types = {
	1: "Accidente",
	2: "Incidente",
	3: "Reclamo",
	5: "Proceso"
}

function AddWorkflow({ value, open, type, bases = [], onCreate }) {
	const [params, setParams] = useState({})

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	function onSubmit() {
		const body = { ...params }
		onCreate(body)
		setParams({})
	}

	return (
		<Collapse in={open}>
			<Paper sx={css.paper}>
				<TextInput value={params.name} onChange={onChange} name="name" label={`Nombre del ${types[type]}`} />
				<SelectInput
					options={transformToOptions(bases)}
					value={params.base_workflow_id}
					onChange={onChange}
					name="base_workflow_id"
					label={`Base del ${types[type]}`}
				/>
				<Box sx={css.buttons}>
					<Button
						color="primary"
						variant="contained"
						onClick={onSubmit}
					>
						Crear {types[type]}
					</Button>
				</Box>
			</Paper>
		</Collapse>
	)
}

export default AddWorkflow