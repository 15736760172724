import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { createSettingsProgram, editSettingsProgram } from '../../API/programs'
import { Delete, Edit, KeyboardArrowRight } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import NewProgram from './NewProgram'
import LoadingDialog from '../../Shared/LoadingDialog'
import GeneralTable from '../../Shared/GeneralTable'
import { withRouter } from 'react-router-dom'
import AddElementButton from '../../Shared/AddElementButton'

const style = () => ({
  subprograms: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 12
  },
  program: {
    background: 'white',
    height: 60,
    width: 100,
    borderRadius: 10,
    padding: 12,
    position: 'relative',
    margin: 6
  },
  addButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 0'
  }
})

const tableInfo = [
  { label: "name", name: "Nombre" },
  // { label: 'date_start', name: "Fecha de Inicio" },
  // { label: 'date_end', name: "Fecha de Término" },
  // { label: "branch_id", name: "Centro de trabajo" },
  { label: "compliance", name: "Puntaje" },
]

class subprograms extends Component {
  constructor() {
    super()
    this.state = {
      subprograms: [],
      openCreate: false,
      loading: false,
      openEdit: false,
      selectedProgram: null
    }
    autobind(subprograms, this)
  }

  async handleCreate(body) {
    const { reload, program } = this.props
    body.parent_id = program.id
    console.log(body)
    this.setState({ loading: true })
    await createSettingsProgram(body)
    await reload()
    this.setState({ loading: false })
  }

  async handleEdit(body) {
    const { reload, program } = this.props
    body.parent_id = program.id
    this.setState({ loading: true })
    await editSettingsProgram(body)
    await reload()
    this.setState({ loading: false })
  }

  // async handleDelete(body) {
  //   const {reload} = this.props
  //   this.setState({ loading: true })
  //   await deleteProgr(body)
  //   await reload()
  //   this.setState({ loading: false })
  // }

  handleOpenProgram(row) {
    return () => {
      const { history } = this.props
      history.push(`/programs/${row.id}`)
    }
  }

  handleOpenCreate() { this.setState({ openCreate: !this.state.openCreate }) }
  handleOpenEdit(row) { return () => { this.setState({ openEdit: !this.state.openEdit, selectedProgram: row }) } }

  render() {
    const { classes, subprograms } = this.props
    const { openCreate, loading, openEdit, selectedProgram } = this.state
    console.log(subprograms)
    const actions = [
      { name: "Editar programa", action: this.handleOpenEdit, icon: Edit },
      { name: "Ver programa", action: this.handleOpenProgram, icon: KeyboardArrowRight }
    ]
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <NewProgram open={openCreate} onClose={this.handleOpenCreate} onSubmit={this.handleCreate} />
        <NewProgram edit={selectedProgram} open={openEdit} onClose={this.handleOpenEdit()} onSubmit={this.handleEdit} />
        <div className={classes.inline}>
          <Typography variant="h4">Programas asociados</Typography>
          <AddElementButton open={openCreate} onClick={this.handleOpenCreate} name="Agregar programa" />
        </div>
        <NewProgram open={openCreate} onClose={this.handleOpenCreate} onSubmit={this.handleCreate} />
        <GeneralTable
          info={tableInfo}
          data={subprograms}
          actions={actions}
          scrollable
        />
      </div>
    )
  }
}

export default withRouter(withStyles(style)(subprograms))