import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const createChecklistItem = body => {
  return instance({
    method: 'post',
    url: 'settings/checklists/create_item.php',
    data: body
  })
}

export const editChecklistItem = body => {
  return instance({
    method: 'post',
    url: 'settings/checklists/edit_item.php',
    data: body
  })
}

export const deleteChecklistItem = body => {
  return instance({
    method: 'post',
    url: 'settings/checklists/delete_item.php',
    data: body
  })
}

export const updateExtraField = body => {
  return instance({
    method: 'post',
    url: 'settings/checklists/extra_fields.php',
    data: body
  })
}

export const switchOrder = body => {
  return instance2({
    method: 'post',
    url: 'settings/checklists/invert_order.php',
    data: body
  })
}

export const setChecklistSupplyTypes = body => {
  return instance2({
    method: 'post',
    url: 'settings/checklists/set_supply_types',
    data: body
  })
}

export const editBaseChecklistItem = body => {
  return instance2({
    method: 'put',
    url: 'settings/checklists/items',
    data: body
  })
}

export const editItemCategory = body => {
  return instance2({
    method: 'put',
    url: 'settings/checklists/item_category',
    data: body
  })
}

export const createSubtitle = body => {
  return instance2({
    method: 'post',
    url: 'settings/checklists/subtitle',
    data: body
  })
}

export const editSubtitle = body => {
  return instance2({
    method: 'put',
    url: 'settings/checklists/subtitle',
    data: body
  })
}

export const createBaseItem = body => {
  return instance2({
    method: 'post',
    url: 'settings/checklists/items',
    data: body
  })
}

export const changeCategoryOrder = body => {
  return instance2({
    method: 'put',
    url: 'settings/checklists/subtitles/order',
    data: body
  })
}

export const editCategoryUsers = body => {
  return instance2({
    method: 'put',
    url: 'settings/checklists/subtitles/users',
    data: body
  })
}