import React, { Component } from 'react'
import { Button, IconButton, Typography, withStyles } from '@material-ui/core'
import { degrees, PDFDocument } from 'pdf-lib'
import { connect } from 'react-redux'
import autobind from '../Utils/autobind'
import LoadingDialog from './LoadingDialog'
import { getWorkersAction } from '../Actions/EnterpriseAction'
import { withRouter } from 'react-router-dom'
import { editBranchDocumentAction, signBranchDocumentAction } from '../Actions/BranchActions'
import moment from 'moment'
import { RotateRightOutlined } from '@material-ui/icons'
import { editUserDocument } from '../API/users'

const style = () => ({
  frame: {
    margin: 24,
    width: '100%',
    height: '100%',
    maxWidth: 640,
    maxHeight: 810,
    overflow: 'auto'
  },
  rotatedFrame: {
    margin: 24,
    width: '100%',
    height: '100%',
    maxWidth: 810,
    maxHeight: 640,
    overflow: 'auto'
  },
  image: {
    position: 'absolute',
    height: 60,
    width: 120
  },
  inline: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  buttons: {
    textAlign: 'left',
    flexGrow: 1,
    marginLeft: 12,
    '& > *': {
      textAlign: 'start',
      '& > *': {
        width: 180,
        marginBottom: 24
      }
    }
  },
  flexline: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 12px'
  }
})

class SignPDF extends Component {
  constructor() {
    super()
    this.state = {
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      page: null,
      pageNumber: 0,
      originalDoc: null,
      startSigning: false,
      showingPreview: false,
      lastLeft: 0,
      lastBottom: 0,
      totalSigns: 0,
      rotated: false,
      rotatedPage: false,
      type: "branch"
    }

    this.totalSigned = 0
    autobind(SignPDF, this)
  }
  async componentDidMount() {
    const { match, getWorkers } = this.props
    getWorkers()
    const file = JSON.parse(atob(match.params.document))
    let url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_BRANCH_DOCS}${file.file}`
    if (!!file.base_url) {
      url = `${file.base_url}${file.file}`
    }
    const pdfResponse = await fetch(url)
    const pdfBytess = await pdfResponse.arrayBuffer()
    const pdfDoc = await PDFDocument.load(pdfBytess)

    const finalPDF = await PDFDocument.create()

    const copiedPages = await finalPDF.copyPages(pdfDoc, pdfDoc.getPageIndices())
    copiedPages.forEach(page => {
      finalPDF.addPage(page)
    })

    await finalPDF.save()
    const { width, height } = finalPDF.getPages()[0].getSize()
    let landscape = false
    if (width > height) { landscape = true }
    this.setState({ originalDoc: pdfDoc, finalDoc: finalPDF, rotatedPage: landscape, type: file.type }, this.renderPage)
    const sign = document.getElementById("sign")
    const self = this


    window.addEventListener('mousemove', (e) => {
      let newLeft = e.pageX
      let newTop = e.pageY
      const { startSigning, top, left, bottom, right, sizeRatio } = self.state

      const realTop = top
      const realLeft = left
      const realBottom = bottom
      const realRight = right
      if (!startSigning) return null
      if (realTop > newTop - (30 / sizeRatio)) return null
      if (realLeft > newLeft - (60 / sizeRatio)) return null
      if (realRight < newLeft + (60 / sizeRatio)) return null
      if (realBottom < newTop + (30 / sizeRatio)) return null

      sign.style.top = newTop - (30 / sizeRatio) + "px"
      sign.style.left = newLeft - (60 / sizeRatio) + "px"
    })

    window.addEventListener("click", (e) => {
      let newLeft = e.pageX
      let newTop = e.pageY
      const { startSigning, top, left, bottom, right, sizeRatio, rotated, rotatedPage, actualPage } = self.state
      const realTop = top
      const realLeft = left
      const realBottom = bottom
      const realRight = right

      const dimentions = actualPage ? this.getPageDimentions(actualPage) : []
      // console.log(dimentions)

      const widthRatio = dimentions[0] / width
      const heightRatio = dimentions[1] / height

      const rotatedWidthRatio = dimentions[0] / height
      const rotatedHeightRatio = dimentions[1] / width

      // console.log("RATIOS:")
      // console.log(rotatedWidthRatio, rotatedHeightRatio)

      // if (rotated) {
      //   console.log(sizeRatio)
      //   console.log(`WIDTH: Click: ${newLeft - left}, PageClick: ${(newLeft - left) / rotatedWidthRatio}, Actual width: ${width}`)
      //   console.log(`HEIGHT: Click: ${newTop - top}, PageClick: ${(newTop - top) / rotatedHeightRatio}, Actual height: ${height}`)
      //   console.log(`Dimensions: ${dimentions}`)
      // } else {
      //   console.log(sizeRatio)
      //   console.log(`WIDTH: Click: ${newLeft - left}, PageClick: ${(newLeft - left) / widthRatio}, Actual width: ${width}`)
      //   console.log(`HEIGHT: Click: ${bottom - newTop}, PageClick: ${(bottom - newTop) / heightRatio}, Actual height: ${height}`)
      //   console.log(`Dimensions: ${dimentions}`)
      // }


      let x = (newLeft - left) / widthRatio
      let y = (bottom - newTop) / heightRatio
      if (rotated ^ rotatedPage) {
        x = (newTop - top) / rotatedHeightRatio
        y = (newLeft - left) / rotatedWidthRatio
      }
      // console.log(`Coords: (${x}, ${y})`)

      if (!startSigning) return null
      if (realTop > newTop - (30 / sizeRatio)) return null
      if (realLeft > newLeft - (60 / sizeRatio)) return null
      if (realRight < newLeft + (60 / sizeRatio)) return null
      if (realBottom < newTop + (30 / sizeRatio)) return null
      // if (rotatedPage || rotated) {
      //   return self.handleAddImage((newLeft - realLeft - 60) * sizeRatio, (newTop - realTop - 30) * sizeRatio)
      // }
      // console.log("NOT ROTATED")
      const yOffset = (rotated ^ rotatedPage) ? 60 : 30
      const xOffset = (rotated ^ rotatedPage) ? -30 : 60
      return self.handleAddImage(x - xOffset, y - yOffset)
    })
  }

  getPageDimentions(page) {
    const { width, height } = page.getSize()
    const { rotated, rotatedPage } = this.state
    const ratio = rotatedPage ? height / width : width / height
    const maxWidth = rotated ? 800 : 630
    const maxHeight = rotated ? 630 : 800
    const sizeRatios = rotated ^ rotatedPage ? width / maxHeight : width / maxWidth
    const dimentions = rotated ? [maxWidth, ratio * maxWidth, sizeRatios] : [ratio * maxHeight, maxHeight, sizeRatios]
    return dimentions
  }

  async renderPage() {
    const { pageNumber, finalDoc, showingPreview, tempPDF, rotated, rotatedPage } = this.state

    if (showingPreview) {
      const blob = new Blob([tempPDF], { type: "application/pdf" })
      const docUrl = URL.createObjectURL(blob)

      const canvas = document.getElementById('pdf')

      canvas.src = docUrl + "#toolbar=0"
      return true
    } else {
      const pagePDF = await PDFDocument.create()
      const pages = await pagePDF.copyPages(finalDoc, finalDoc.getPageIndices())
      const actualPage = pages[pageNumber]

      const [width, height, sizeRatio] = this.getPageDimentions(actualPage)
      if (rotated ^ rotatedPage) { actualPage.setRotation(degrees(90)) }

      pagePDF.addPage(actualPage)

      const pdfBytes = await pagePDF.save()

      const blob = new Blob([pdfBytes], { type: "application/pdf" })
      const docUrl = URL.createObjectURL(blob)

      const canvas = document.getElementById('pdf')

      canvas.src = docUrl + "#toolbar=0"
      canvas.height = height
      canvas.width = width

      const rect = canvas.getBoundingClientRect()
      const { top, left, bottom, right } = rect
      this.setState({
        top: top + window.scrollY,
        left: left + window.scrollX,
        bottom: bottom + window.scrollY,
        right: right + window.scrollX,
        page: pagePDF,
        actualPage,
        sizeRatio,
        finalDoc
      })
      return true
    }
  }

  handleChangePage(option) {
    return () => {
      const { pageNumber, originalDoc } = this.state
      const pagesLength = originalDoc.getPages().length
      if (option === "+" && pageNumber < pagesLength - 1) return this.setState({ pageNumber: pageNumber + 1 }, this.renderPage)
      if (option === "-" && pageNumber > 0) return this.setState({ pageNumber: pageNumber - 1 }, this.renderPage)
    }
  }

  async handleAddImage(left, bottom) {
    const { page: tempPDF, rotatedPage, rotated } = this.state
    const { workers, user } = this.props
    const currentWorker = workers?.all.find(worker => worker.id === user.account.user.id)
    const sign = currentWorker.signs[currentWorker.signs.length - 1]
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
    const pngImageBytes = await fetch(url).then(response => response.arrayBuffer())
    const tempImage = await tempPDF.embedPng(pngImageBytes)
    const tempPage = tempPDF.getPages()[0]
    const isRotated = (rotatedPage ^ rotated)

    let x = left
    let y = bottom

    tempPage.drawImage(tempImage, {
      x: x,
      y: y,
      height: 60,
      width: 120,
      rotate: isRotated ? degrees(90) : degrees(0)
    })

    const tempResult = await tempPDF.save()

    this.setState({ tempPDF: tempResult, showingPreview: true, startSigning: false, lastLeft: left, lastBottom: bottom }, this.renderPage)
  }

  handleCancelAddedImage() {
    this.setState({ showingPreview: false, startSigning: false }, this.renderPage)
  }

  async handleSaveAddedImage() {
    const { pageNumber, originalDoc, lastLeft: left, lastBottom: bottom, totalSigns, rotated, rotatedPage } = this.state
    const { workers, user } = this.props
    const currentWorker = workers?.all.find(worker => worker.id === user.account.user.id)
    const sign = currentWorker.signs[currentWorker.signs.length - 1]
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
    const pngImageBytes = await fetch(url).then(response => response.arrayBuffer())
    const { finalDoc } = this.state

    const pngImage = await finalDoc.embedPng(pngImageBytes)
    const pagesLength = originalDoc.getPages().length

    const isRotated = (rotatedPage ^ rotated)

    const docPage = finalDoc.getPages()[pageNumber]
    docPage.drawImage(pngImage, {
      x: left,
      y: bottom,
      height: 60,
      width: 120,
      rotate: isRotated ? degrees(90) : degrees(0)
    })

    if (pageNumber < pagesLength - 1) {
      this.setState({ pageNumber: pageNumber + 1, finalDoc, showingPreview: false, totalSigns: totalSigns + 1 }, this.renderPage)
    } else {
      this.setState({ showingPreview: false, totalSigns: totalSigns + 1 })
    }
  }

  async handleFinish() {
    const { finalDoc, totalSigns, type } = this.state
    const resultFinalDoc = await finalDoc.save()
    var blob = new Blob([resultFinalDoc], { type: "application/pdf" })// change resultByte to bytes

    const { editBranchDocument, match } = this.props
    const file = JSON.parse(atob(match.params.document))
    const newFile = new File([blob], file.name, { type: "image/png" })
    const data = new FormData()
    data.append("name", file.name)
    data.append("file", newFile)
    data.append("category_id", file.category_id)
    data.append("subcategory_id", file.subcategory_id)
    data.append("sub_subcategory_id", file.sub_subcategory_id)
    data.append("id", file.id)
    data.append("branch_id", file.branch_id)
    data.append("date", file.date)
    data.append("user_id", file.user_id)
    this.setState({ loading: true, loadingMessage: "Generando documento..." })

    let action = editBranchDocument
    if (type === "worker") {
      action = editUserDocument
    }
    action(data).then(() => {
      this.setState({ loadingMessage: "Registrando Firmas..." })
      if (totalSigns === 0) {
        const { history } = this.props
        history.goBack()
        return 0
      }
      Array.from(new Array(totalSigns)).forEach(() => {
        this.addSignsToDocument()
      })

    })
  }

  addSignsToDocument() {
    const { signDocument, workers, user, match } = this.props
    const { totalSigns } = this.state
    const file = JSON.parse(atob(match.params.document))
    const currentWorker = workers?.all.find(worker => worker.id === user.account.user.id)
    const hasSign = currentWorker?.signs?.length > 0
    const sign = hasSign && currentWorker?.signs[currentWorker.signs.length - 1]
    const body = new FormData()
    console.log(file)
    body.append("document_id", file.id)
    body.append("user_id", currentWorker.id)
    body.append("name", currentWorker.name)
    body.append("user_sign", sign.sign)
    body.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
    signDocument(body).then(() => {
      this.totalSigned += 1
      if (this.totalSigned === totalSigns) {
        this.setState({ loading: false })
      }
    })
  }

  handleStartSigning() {
    this.setState({ startSigning: !this.state.startSigning })
  }

  handleRotateView() {
    const { rotated } = this.state
    this.setState({ rotated: !rotated }, this.renderPage)
  }

  handleRotatePage() {
    const { rotatedPage } = this.state
    this.setState({ rotatedPage: !rotatedPage }, this.renderPage)
  }

  render() {
    const { classes, workers, user, match } = this.props
    const { startSigning, pageNumber, originalDoc, showingPreview, loading, totalSigns, loadingMessage, rotated, sizeRatio } = this.state
    const file = JSON.parse(atob(match.params.document))
    const currentWorker = workers?.all.find(worker => worker.id === user.account.user.id)
    const hasSign = currentWorker?.signs?.length > 0
    const sign = hasSign && currentWorker?.signs[currentWorker.signs.length - 1]
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign?.sign}`
    const pages = originalDoc?.getPages()?.length || "Cargando..."
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} message={loadingMessage} />
        <Typography variant="h6">{file?.name}</Typography>
        <div className={classes.flexline}>
          <IconButton onClick={this.handleRotateView}>
            <RotateRightOutlined />
          </IconButton>
          <Typography variant='subtitle1'>Rotar PDF para firmar</Typography>
        </div>
        {/* <IconButton onClick={this.handleRotatePage}>
          <RotateRightOutlined />
        </IconButton> */}
        {!hasSign && <Typography style={{ color: '#ff6c87', marginBottom: 12 }} variant="h4">Al parecer no tienes firmas registradas en tu perfil, por favor accede a él y registra una para poder firmar el documento</Typography>}
        <div className={classes.inline}>
          <div className={rotated ? classes.rotatedFrame : classes.frame} id="frame">
            <iframe style={{ pointerEvents: 'none' }} title="unique" id="pdf" width='100%' height='100%' type="application/pdf">

            </iframe>
          </div>
          {hasSign &&
            <div className={classes.buttons}>
              <div><Typography variant="subtitle1">{`Página ${pageNumber + 1} de ${pages}`}</Typography></div>
              <div><Typography variant="subtitle1">{`El documento ha sido firmado ${totalSigns} ${totalSigns === 1 ? "Vez" : "Veces"}`}</Typography></div>
              {showingPreview ?
                <>
                  <div> <Typography style={{ width: 'unset' }} variant="subtitle1">¿Desea guardar la posición de la firma?</Typography></div>
                  <div> <Button color="primary" variant="contained" onClick={this.handleSaveAddedImage}>Confirmar posición</Button> </div>
                  <div> <Button color="primary" variant="contained" onClick={this.handleCancelAddedImage}>Cancelar</Button> </div>
                </>
                :
                <>
                  <div> <Button color="primary" variant="contained" onClick={this.handleStartSigning}>{!startSigning ? "Firmar Página" : "Cancelar"}</Button></div>
                  <div> <Button color="primary" variant="contained" onClick={this.handleChangePage('+')}>Página siguiente</Button></div>
                  <div> <Button color="primary" variant="contained" onClick={this.handleChangePage('-')}>Página anterior</Button></div>
                  <div> <Button color="primary" variant="contained" onClick={this.handleFinish}>Terminar y Guardar</Button></div>
                </>
              }
            </div>
          }
          <div
            style={{ display: !startSigning ? 'none' : 'block', height: `${60 / sizeRatio}px`, width: `${120 / sizeRatio}px` }}
            className={classes.image} id="sign"
          >
            <img src={url} alt="no" height={`${60 / sizeRatio}px`} width={`${120 / sizeRatio}px`} />
          </div>
        </div >
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  editBranchDocument: body => dispatch(editBranchDocumentAction(body)),
  getWorkers: (body) => dispatch(getWorkersAction(body)),
  signDocument: body => dispatch(signBranchDocumentAction(body))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SignPDF)))