import React, { Component } from 'react'
import { Button, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import moment from 'moment'
import { Edit, HighlightOffOutlined } from '@material-ui/icons'
import { filterActiveWorkers } from '../../../Utils/filters'
import { transformToOptions } from '../../../Utils/functions'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TNTContext from '../TNTContext'
import DateTimeInput from '../../../Shared/Inputs/DateTimeInput'

const style = theme => ({
  container: {
    padding: 12,
    background: ' white',
    borderRadius: 12,
    margin: '12px 0',
    position: 'relative'
  },
  selectedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  infoElement: {
    width: 300,
    margin: 12,
    '& > div:nth-child(2)': {
      '& > *': {
        fontSize: 16
      }
    }
  },
  button: {
    position: 'absolute',
    top: 6,
    right: 6
  },
  dialog: {
    padding: 24,
    position: 'relative'
  },
  downloadText: {
    color: theme.palette.blue.main,
    cursor: 'pointer'
  },
  dialogButton: {
    textAlign: 'end'
  },
  dialogTitle: {
    marginBottom: 24,
    color: theme.palette.purple.light
  }
})

class MainInformationCard extends Component {
  constructor() {
    super()
    this.state = {
      openEdit: false,
      params: {}
    }
    autobind(MainInformationCard, this)
  }

  handleSeeFile(file) {
    const { urls } = this.context
    return () => {
      if (file) {
        const url = process.env.REACT_APP_IMG_URL + urls.document + "/" + file
        window.open(url)
      }
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleOpenEdit() {
    const { element } = this.props
    const { openEdit } = this.state
    const newParams = {
      date_scheduled: moment(element.date_scheduled).format("YYYY-MM-DD HH:mm:ss"),
      user_id: element.user_id,
      document_id: element.document_id
    }
    if (openEdit) return this.setState({ openEdit: false })
    return this.setState({ openEdit: true, params: newParams })
  }

  handleEdit() {
    const { onEdit } = this.props
    const { params } = this.state
    const body = { ...params }
    this.setState({ openEdit: false })
    onEdit(body)
  }

  renderInfo() {
    const { element, classes } = this.props
    return (
      <div className={classes.selectedContainer}>
        <div className={classes.infoElement}>
          <div className={classes.text}>
            <Typography variant="subtitle2">
              Supervisor
            </Typography>
          </div>
          <div className={classes.text}>
            <Typography variant="body2">
              {element.user_name}
            </Typography>
          </div>
        </div>
        <div className={classes.infoElement}>
          <div className={classes.text}>
            <Typography variant="subtitle2">
              Fecha programado
            </Typography>
          </div>
          <div className={classes.text}>
            <Typography variant="body2">
              {moment(element.date_scheduled).format("YYYY-MM-DD")}
            </Typography>
          </div>
        </div>
        <div className={classes.infoElement}>
          <div className={classes.text}>
            <Typography variant="subtitle2">
              Tema
            </Typography>
          </div>
          <div className={classes.text}>
            <Typography variant="body2">
              {element.name}
            </Typography>
          </div>
        </div>
        <div className={classes.infoElement}>
          <div className={classes.text}>
            <Typography variant="subtitle2">
              Categoría
            </Typography>
          </div>
          <div className={classes.text}>
            <Typography variant="body2">
              {element.category_name}
            </Typography>
          </div>
        </div>
        <div className={classes.infoElement}>
          <div className={classes.text}>
            <Typography variant="subtitle2">
              Documento
            </Typography>
          </div>
          <div className={classes.text}>
            <Typography variant="body2" className={element.file ? classes.downloadText : ""} onClick={this.handleSeeFile(element.file)}>
              {element.file ? "Ver Documento" : "No hay documento"}
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { classes, talks } = this.props
    const { isFinished, level, isCurrentOwner } = this.context
    const { openEdit, params } = this.state
    return (
      <div className={classes.container}>
        {this.renderInfo()}
        <IconButton className={classes.button} onClick={this.handleOpenEdit} disabled={isFinished || !(level === 1 || isCurrentOwner)}>
          <Edit />
        </IconButton>
        <Dialog open={openEdit} onClose={this.handleOpenEdit}>
          <div className={classes.dialog}>
            <Typography variant="h1" className={classes.dialogTitle}>Editar Elemento</Typography>
            <SelectInput label="Responsable" options={transformToOptions(filterActiveWorkers())} onChange={this.handleChange} name="user_id" value={params.user_id} />
            <SelectInput label="Tipo" options={transformToOptions(talks)} onChange={this.handleChange} name="document_id" value={params.document_id} />
            <DateTimeInput label="Fecha de Realización" onChange={this.handleChange} name="date_scheduled" value={params.date_scheduled} />
            <div className={classes.dialogButton}>
              <Button color="primary" variant="contained" size="small" onClick={this.handleEdit}>
                Guardar
              </Button>
            </div>
            <IconButton style={{ position: 'absolute', top: 6, right: 6 }} onClick={this.handleOpenEdit} size="small">
              <HighlightOffOutlined />
            </IconButton>
          </div>
        </Dialog>
      </div>
    )
  }
}

MainInformationCard.contextType = TNTContext

export default withStyles(style)(MainInformationCard)