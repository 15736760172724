import React, { Component } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import { AddAPhoto } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import ChecklistContext from '../ChecklistContext'
import ImageContainer from '../../../Shared/ImageContainer'
import { resizeImageGetFile } from '../../../Utils/functions'

const style = () => ({
  image: {
    height: 64,
    width: 64,
    borderRadius: '50%',
    border: "1px dashed #6a6a6a",
    position: 'relative',
    margin: 6,
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    position: 'absolute',
    bottom: -6,
    right: -6
  },
  fullButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: 64,
    width: 64
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  input: {
    display: 'none'
  },
  img: {
    height: 64,
    width: 64,
    borderRadius: '50%',
  },
  finishedImage: {
    height: 96,
    width: 96,
    borderRadius: 12,
    position: 'relative',
    margin: 6,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  finishedContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '6px 0'
  }
})

function simulateClick(value, item) {
  return () => {
    const element = document.getElementById(`${item.id}-file${value}`)
    if (!element) return null
    return element.click()
  }
}

function completePath(file) {
  if (file.includes("amazonaws.com")) return file
  if (file.includes("data:image")) return file
  return `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${file}`
}

class ImagesSelector extends Component {
  constructor() {
    super()
    this.state = {

    }
    autobind(ImagesSelector, this)
  }

  async handleChange(event) {
    const { target } = event
    const { item } = this.props
    const { onFile } = this.context
    const file = target.files[0]
    const index = target.name
    const data = new FormData()
    const [imageData, newFile] = await resizeImageGetFile(file)
    data.append("file", newFile)
    data.append("checklist_item_id", item.id)
    const body = { file: imageData, checklist_item_id: item.id }

    onFile(data, body, index)
  }

  render() {
    const { classes, item, disabled } = this.props
    const { finished } = this.context
    const { answer = {} } = item

    if (finished || disabled) return (
      <div className={classes.finishedContainer}>
        {answer.file && <div className={classes.finishedImage}>
          <ImageContainer width="96px" border="12px" src={answer.file ? completePath(answer.file) : "/noimage.png"} alt="file" />
        </div>}
        {answer.file2 && <div className={classes.finishedImage}>
          <ImageContainer width="96px" border="12px" src={answer.file2 ? completePath(answer.file2) : "/noimage.png"} alt="file" />
        </div>}
        {answer.file3 && <div className={classes.finishedImage}>
          <ImageContainer width="96px" border="12px" src={answer.file3 ? completePath(answer.file3) : "/noimage.png"} alt="file" />
        </div>}
      </div>
    )

    return (
      <div className={classes.container}>
        <input type="file" accept="image/*;capture=camera" name="" className={classes.input} onChange={this.handleChange} id={`${item.id}-file1`} />
        <div className={classes.image}>
          <ImageContainer height="64px" border="50%" src={answer.file ? completePath(answer.file) : "/noimage.png"} alt="file" />
          <IconButton
            onClick={simulateClick("1", item)}
            className={answer.file ? classes.button : classes.fullButton}
            size="small"
          >
            <AddAPhoto />
          </IconButton>
        </div>
        <input type="file" accept="image/*;capture=camera" name="2" className={classes.input} onChange={this.handleChange} id={`${item.id}-file2`} />
        <div className={classes.image}>
          <ImageContainer height="64px" border="50%" src={answer.file2 ? completePath(answer.file2) : "/noimage.png"} alt="file" />
          <IconButton
            onClick={simulateClick("2", item)}
            className={answer.file2 ? classes.button : classes.fullButton}
            size="small"
          >
            <AddAPhoto />
          </IconButton>
        </div>
        <input type="file" accept="image/*;capture=camera" name="3" className={classes.input} onChange={this.handleChange} id={`${item.id}-file3`} />
        <div className={classes.image}>
          <ImageContainer height="64px" border="50%" src={answer.file3 ? completePath(answer.file3) : "/noimage.png"} alt="file" />
          <IconButton
            onClick={simulateClick("3", item)}
            className={answer.file3 ? classes.button : classes.fullButton}
            size="small"
          >
            <AddAPhoto />
          </IconButton>
        </div>
      </div>
    )
  }
}

ImagesSelector.contextType = ChecklistContext

export default withStyles(style)(ImagesSelector)