import React, { Component } from 'react'
import { IconButton, withStyles, Tooltip } from '@material-ui/core'
import CalendarContext from './CalendarContext'
import { HighlightOffOutlined } from '@material-ui/icons'
import theme from '../../theme'
import autobind from '../../Utils/autobind'

const style = (theme) => ({
  container: {
    padding: '3px 0 3px 6px',
    borderRadius: 4,
    margin: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *': {
      color: 'white'
    },
    cursor: 'pointer',
    transition: 'all 0.2s linear'
  },
  textWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'white',
    fontWeight: 600,
    cursor: 'move'
  }
})

class DayEvent extends Component {
  constructor() {
    super()
    autobind(DayEvent, this)
  }
  componentDidMount() {
    const { event, onClose } = this.props
    const element = document.getElementById(event.id)
    element.addEventListener("dragstart", (e) => {
      e.dataTransfer.effectAllowed = "copy"
      e.dataTransfer.setData('text/plain', JSON.stringify(event))
      element.style.opacity = "0.5"
      if (onClose) { onClose() }
    })

    element.addEventListener("dragend", () => {
      element.style.opacity = "1"
    })
  }

  handleClick(e) {
    e.stopPropagation()
    const { event } = this.props
    const { onEventClick } = this.context
    onEventClick && onEventClick(event)
  }

  handleDelete(e) {
    e.stopPropagation()
    const { event, onClose } = this.props
    const { onDeleteClick } = this.context
    onClose && onClose()
    onDeleteClick && onDeleteClick(event)
  }

  render() {
    const { classes, event } = this.props
    const { eventRender, tooltip } = this.context
    return (
      <div draggable={event.editable} id={event.id}>
        <Tooltip title={tooltip(event)}>
          <div
            className={`${classes.container} calendar-event`}
            style={{ background: event?.background || theme.palette.primary.main }}
            onClick={this.handleClick}
          >
            <div className={classes.textWrap} style={{ cursor: event.editable ? "move" : "pointer" }}>
              {eventRender(event)}
            </div>
            <IconButton size="small" onClick={this.handleDelete}>
              <HighlightOffOutlined />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    )
  }
}

DayEvent.contextType = CalendarContext

export default withStyles(style)(DayEvent)