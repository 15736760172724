const GlobalReducer = (state = { delete: {} }, action) => {
  switch (action.type) {
    case "OPEN_GLOBAL_DIALOG": {
      const newState = { ...state }
      const { action: dialogAction, body, options = {} } = action.payload
      newState.delete.action = dialogAction
      newState.delete.body = body
      newState.delete.open = true
      newState.delete.options = options
      return newState
    }
    case "CLOSE_GLOBAL_DIALOG": {
      const newState = { ...state }
      newState.delete.open = false
      newState.delete.body = null
      newState.delete.action = undefined
      newState.delete.options = {}
      return newState
    }
    default:
      return state
  }
}

export default GlobalReducer
