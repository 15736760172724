import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import CalendarContext from './CalendarContext'
import moment from 'moment'
import DayEvent from './DayEvent'
import autobind from '../../Utils/autobind'

const style = (theme) => ({
  container: {
    height: 150,
    border: '1px solid #ececec',
    '& > *': {
      fontWeight: 600
    },
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.purple.light}`,
    },
    position: 'relative'
  },
  out: {
    background: theme.palette.blue.main + "32",
  },
  today: {
    border: `1px solid ${theme.palette.blue.main}`
  },
  seemore: {
    padding: '7px 6px 6px 6px',
    outline: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    margin: 3,
    textAlign: 'center',
    '& > *': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    cursor: 'pointer',
  },
  dialog: {
    padding: 24
  }
})
class Day extends Component {
  constructor() {
    super()
    this.counter = 0
    this.state = {
      open: false,
      anchor: null
    }
    autobind(Day, this)
  }

  componentDidMount() {
    const { day } = this.props
    const { onDragDrop } = this.context
    const element = document.getElementById(day.format("YYYYMMDD"))
    element.addEventListener("dragenter", (e) => {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      this.counter++
      element.style.transform = "scale(1.03)"
      element.style.boxShadow = "0 0 20px rgb(8 21 66 / 5%)"
      element.style.zIndex = 3
    })
    element.addEventListener("dragover", (e) => { e.preventDefault() })
    element.addEventListener("dragleave", () => {
      this.counter--
      if (this.counter === 0) {
        element.style.transform = "scale(1)"
        element.style.boxShadow = "unset"
        element.style.zIndex = 0
      }
    })
    element.addEventListener("drop", (event) => {
      event.preventDefault()
      element.style.transform = "scale(1)"
      element.style.boxShadow = "unset"
      this.counter = 0
      const eventData = JSON.parse(event.dataTransfer.getData("text/plain"))
      console.log(eventData)
      console.log(day.format("YYYY-MM-DD"))
      onDragDrop && onDragDrop({ event: eventData, date: day.format("YYYY-MM-DD") })
    })
  }

  handleOpen(e) {
    e && e.stopPropagation()
    this.setState({ open: !this.state.open })
  }

  handleClick() {
    const { day } = this.props
    const { onDayClick } = this.context
    onDayClick && onDayClick(day)
  }

  render() {
    const { classes, day } = this.props
    const { open } = this.state
    const { date, events, today } = this.context
    const isOutDated = !day.isSame(date, 'month')
    const isToday = today.isSame(day, 'day')
    const myEvents = events.filter(event => moment(event.date_scheduled).isSame(day, 'day'))
    const visibleEvents = myEvents.slice(0, 2)
    const hiddenEvents = myEvents.slice(2)
    return (
      <>
        <div
          className={`
      ${classes.container} 
      ${isOutDated ? classes.out : ""} 
      ${isToday ? classes.today : ""}
      `}
          id={day.format("YYYYMMDD")}
          style={{ overflowY: open ? "visible" : "hidden" }}
          onClick={this.handleClick}
        >
          <Typography style={{ padding: 6 }} variant='subtitle1'>{day.format("DD")}</Typography>
          {visibleEvents.map(event => <DayEvent key={event.id} event={event} />)}
          {!!hiddenEvents.length &&
            <div className={classes.seemore} onClick={this.handleOpen}>
              <Typography variant='subtitle1'>{`${open ? "Ocultar" : "Ver"} ${hiddenEvents.length} ${open ? "" : "más"}`}</Typography>
            </div>
          }
        </div>
        <Dialog open={open} onClose={this.handleOpen} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant='h4' style={{ marginBottom: 12 }}>{day.format("dddd DD [de] MMMM [del] YYYY")}</Typography>
            {hiddenEvents.map(event => <DayEvent key={event.id} event={event} onClose={this.handleOpen} />)}
          </div>
        </Dialog>
      </>
    )
  }
}

Day.contextType = CalendarContext

export default withStyles(style)(Day)