import React, { Component } from 'react'
import { Button, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import SubmitButton from '../../../Shared/SubmitButton'
import { CheckCircleOutline, Delete } from '@material-ui/icons'
import instance from '../../../Utils/instance'
import { callSnackbar } from '../../../Utils/snackbar'

const style = (theme) => ({
  container: {
    padding: 12,
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  phaseStatus: {
    height: 85,
    width: 85,
    border: '1px solid grey',
    borderRadius: 15,
    position: 'relative'
  },
  phaseContent: {
    flexGrow: 1,
    marginRight: 24
  },
  checkIcon: {
    color: theme.palette.green.main,
    height: 64,
    width: 64,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.2s linear'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: 12
    }
  },
  link: {
    color: theme.palette.blue.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 170,
    justifyContent: 'space-between'
  }
})

class FilePhase extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      checked: false
    }
    this._id = FilePhase.increment()
    autobind(FilePhase, this)
  }
  componentDidMount() {
    const { onSubmit, phase } = this.props
    if (phase.value) {
      const startingParams = {
        [phase.label]: phase.value
      }
      const body = {
        ...phase,
        value: phase.value,
        checked: true
      }
      onSubmit(body, false)
      this.setState({ params: startingParams, checked: true })
    }

    if (!phase.required) {
      this.setState({ checked: true })
      const body = {
        ...phase,
        value: phase.value,
        checked: true
      }
      onSubmit(body, false)
    }
  }

  static increment() {
    if (!this.latestId) this.latestId = 1
    else this.latestId++
    return this.latestId
  }

  handleSimulateClick() {
    const { phase } = this.props
    document.getElementById(`${phase.label}-${this._id}`).click()
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state

    const file = target.files[0]

    const body = new FormData()
    body.append("file", file)

    const files = !!params[target.name] ? params[target.name].split("#") : []

    instance({
      method: 'post',
      url: 'workflows/upload-file.php',
      data: body
    }).then(response => {
      files.push(response.data.info.file_name)
      params[target.name] = files.join("#")
      this.setState({ params })
    })
  }

  handleEdit() {
    const { onSubmit, phase } = this.props
    const { params } = this.state
    const body = {
      ...phase,
      value: params[phase.label],
      checked: false
    }
    onSubmit(body, false)
    this.setState({ checked: false })
  }

  handleSubmit() {
    const { onSubmit, phase, index } = this.props
    const { params } = this.state
    const body = {
      ...phase,
      value: params[phase.label],
      checked: true
    }
    onSubmit(body, index)
    this.setState({ checked: true })
  }

  handleOpenDoc(url) {
    return () => {
      window.open(url)
    }
  }

  handleRemoveDocument(index) {
    return () => {
      const { phase } = this.props
      const { params } = this.state
      const documents = [...params[phase.label]?.split("#")]
      documents.splice(index, 1)
      if (documents.length > 0) {
        params[phase.label] = documents.join("#")
        return this.setState({ params })
      }
      params[phase.label] = undefined
      return this.setState({})

    }
  }

  handleDownloadDocuments() {
    const { phase } = this.props
    const { params } = this.state
    const documents = params[phase.label]?.split("#") || []
    documents.forEach((file, index) => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}${file}`
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement("a")
          a.href = url
          a.download = `${file}`
          a.click()
        })
      }).catch(() => callSnackbar("Error al descargar documento", "error"))
    })
  }

  renderDocuments() {
    const { phase, classes, disabled } = this.props
    const { params, checked } = this.state
    const documents = params[phase.label]?.split("#") || []
    const files = documents.map((document, index) => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}${document}`
      return (
        <div className={classes.file} key={index}>
          <Typography variant="subtitle1" className={classes.link} onClick={this.handleOpenDoc(url)}>{`${index + 1}. Ver Documento`}</Typography>
          <IconButton size="small" onClick={this.handleRemoveDocument(index)} disabled={checked || disabled}>
            <Delete />
          </IconButton>
        </div>
      )
    })
    return (
      <div>
        {files}
      </div>
    )
  }

  render() {
    const { params, checked } = this.state
    const { classes, phase, disabled } = this.props
    const documents = params[phase.label]?.split("#") || []
    return (
      <div className={classes.container}>
        <div className={classes.phaseContent}>
          <Typography variant="subtitle1">{phase.label}</Typography>
          {this.renderDocuments()}
          <input type="file" style={{ display: 'none' }} onChange={this.handleChange} id={`${phase.label}-${this._id}`} name={phase.label} />
          <Button
            disabled={checked || disabled}
            style={{ margin: '12px 0' }}
            color="primary"
            variant="contained"
            onClick={this.handleSimulateClick}>
            Agregar Documento
          </Button>

          {!!documents.length &&
            <div>
              <Button
                style={{ margin: '12px 0' }}
                color="primary"
                variant="contained"
                onClick={this.handleDownloadDocuments}>
                Descargar documentos
              </Button>
            </div>
          }

          <div className={classes.buttons}>
            {!disabled && checked && <Button variant="outlined" onClick={this.handleEdit} size="small">
              Editar
            </Button>}
            <SubmitButton onClick={this.handleSubmit} disabled={disabled || checked || !params[phase.label]}>Guardar</SubmitButton>
          </div>
        </div>
        <div className={classes.phaseStatus}>
          <CheckCircleOutline className={classes.checkIcon} style={{ opacity: checked ? 1 : 0 }} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(FilePhase)