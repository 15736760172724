import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getSettingsChecklist } from '../../../../../API/checklists'

const css = {
	container: {

	}
}

function ChecklistPhase({ phase }) {
	console.log(phase)
	const [checklists, setChecklists] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getSettingsChecklist()
			setChecklists(response.data.info)
		}
		fetchData()
	}, [])

	const selectedChecklist = checklists.find(ch => ch.id === phase.base_checklist_id)

	return (
		<Box sx={css.container}>
			<Typography variant="subtitle1">Tipo de fase: Ingreso de registro</Typography>
			<Typography variant="subtitle1">Nombre del registro: {selectedChecklist?.name}</Typography>
			<Typography variant="caption">Titulo: {phase.label}</Typography>
		</Box>
	)
}

export default ChecklistPhase