import React, { Component } from 'react'
import { Collapse, Dialog, Divider, IconButton, Switch, Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import { connect } from 'react-redux'
import { asignCalendarAction, getEnterpriseActivitiesAction, setAuthorizedUsersAction } from '../../Actions/ActivityActions'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import instance from '../../Utils/instance'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import { transformToOptions } from '../../Utils/functions'
import SubmitButton from '../../Shared/SubmitButton'
import TextInput from '../../Shared/Inputs/TextInput'
import { Delete, Edit, HighlightOffOutlined } from '@material-ui/icons'
import { createGroup, deleteGroup, editGroup } from './Utils/SettingsUtils'
import { filterActiveWorkersList } from '../../Utils/filters'

const style = theme => ({
  container: {
    padding: 24,
    minHeight: 300,
    position: 'relative'
  },
  section: {
    margin: '24px 0',
    '& > h4': {
      marginBottom: 12
    }
  },
  group: {
    background: `${theme.palette.blue.main}c4`,
    padding: 12,
    borderRadius: 12,
    margin: '12px 0',
    '& > *': {
      color: 'white'
    },
    '& > div > *': {
      color: 'white'
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12
  }
})

class ChecklistSettingsDialog extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      selectedWorkers: [],
      params: {},
      groupParams: {},
      selectedGroup: {},
      openDelete: false
    }
    autobind(ChecklistSettingsDialog, this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.checklist?.authorized_users !== this.props?.checklist?.authorized_users) {
      this.setState({ selectedWorkers: this.props?.checklist?.authorized_users })
    }
  }

  handleChange(event) {
    const { target } = event
    this.setState({ selectedWorkers: target.value })
  }

  handleChangeValue(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeGroup(event) {
    const { target } = event
    const { groupParams } = this.state
    groupParams[target.name] = target.value
    this.setState({ groupParams })
  }

  handleToggleVariable(variable) {
    return async () => {
      const { getActivity, checklist, base = null } = this.props
      const body = {}
      body[variable] = checklist[variable] === 0 ? 1 : 0
      body.preventive_measure_id = checklist.id
      body.activity_id = checklist.activity_id
      this.setState({ loading: true })
      await instance({
        method: 'post',
        url: base ? base[variable] : `activities/${variable}.php`,
        data: body
      })
      await getActivity()
      this.setState({ loading: false })
    }
  }

  async handleSend() {
    const { checklist, setUsers, getActivity } = this.props
    const { selectedWorkers } = this.state
    const body = {
      preventive_measure_id: checklist.id,
      activity_id: checklist.activity_id,
      authorized_users: selectedWorkers.length > 0 ? selectedWorkers : null
    }
    this.setState({ loading: true })
    await setUsers(body)
    await getActivity()
    this.setState({ loading: false })
  }

  handleEdit(group, openDelete = false) {
    return () => {
      this.setState({ selectedGroup: group, groupParams: { ...group }, openDelete: openDelete })
    }
  }

  async handleCreateGroup() {
    const { params } = this.state
    const { getActivity, checklist } = this.props
    const body = {
      ...params,
      preventive_measure_id: checklist.id,
      activity_id: checklist.activity_id
    }
    this.setState({ loading: true })
    await createGroup(body)
    await getActivity()
    this.setState({ loading: false, params: {} })
  }

  async handleEditGroup() {
    const { groupParams, selectedGroup } = this.state
    const { checklist, getActivity } = this.props
    const body = {
      ...groupParams,
      id: selectedGroup.id,
      preventive_measure_id: checklist.id,
      activity_id: checklist.activity_id
    }
    this.setState({ loading: true, selectedGroup: {}, groupParams: {} })
    await editGroup(body)
    await getActivity()
    this.setState({ loading: false })
  }

  async handleDeleteGroup() {
    const { checklist, getActivity } = this.props
    const { selectedGroup } = this.state
    const body = {
      id: selectedGroup.id,
      preventive_measure_id: checklist.id,
      activity_id: checklist.activity_id
    }
    this.setState({ loading: true, selectedGroup: {}, groupParams: {}, openDelete: false })
    await deleteGroup(body)
    await getActivity()
    this.setState({ loading: false })
  }

  renderGroups(groups) {
    const { classes } = this.props
    const { selectedGroup, groupParams, openDelete } = this.state
    return groups.map(group => {
      const isSelected = selectedGroup?.id === group.id && !openDelete
      return (
        <>
          {!isSelected ?
            <div className={classes.group}>
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: 600 }}>Nombre: {group.name}</Typography>
                <Typography variant="subtitle1">Descripción: {group.description}</Typography>
              </div>
              <div>
                <IconButton onClick={this.handleEdit(group)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={this.handleEdit(group, true)}>
                  <Delete />
                </IconButton>
              </div>
            </div>
            :
            <div className={classes.group}>
              <div>
                <TextInput label="Nombre" name="name" value={groupParams.name} onChange={this.handleChangeGroup} />
                <TextInput label="Descripción" name="description" value={groupParams.description} onChange={this.handleChangeGroup} />
                <SubmitButton onClick={this.handleEditGroup}>
                  Guardar
                </SubmitButton>
              </div>
            </div>
          }
        </>
      )
    })
  }

  render() {
    const { classes, open, onClose, checklist = {}, workers } = this.props
    const { loading, selectedWorkers, params, openDelete } = this.state
    const allWorkers = workers.all || []
    const filteredWorkers = filterActiveWorkersList(allWorkers)
    const groups = checklist?.group_signs || []
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h1">Configuración {checklist?.name}</Typography>
          <IconButton size="small" className={classes.close} onClick={onClose}>
            <HighlightOffOutlined />
          </IconButton>
          {loading ?
            <LoaderAnimator loading />
            :
            <div>
              <div className={classes.section}>
                <Typography variant="h4">Calendario</Typography>
                <Typography variant="subtitle1">Desea que la programación de esta registro o formulario se vea reflejada en el calendario </Typography>
                <Switch checked={!checklist?.calendar} onClick={this.handleToggleVariable("calendar")} />
                <Divider />
              </div>
              <div className={classes.section}>
                <Typography variant="h4">Acceso rápido</Typography>
                <Typography variant="subtitle1">Si activa esta opción, la registro o formulario aparecerá en la pagina de inicio, para que se pueda realizar de manera más directa y accesible </Typography>
                <Switch checked={checklist?.quick_access} onClick={this.handleToggleVariable("quick_access")} />
                <Collapse in={checklist?.quick_access === 1}>
                  <Typography variant="subtitle1">Configuración de quiénes tienen acceso rápido</Typography>
                  <MultiSelectInput
                    onChange={this.handleChange}
                    label="Colaboradores"
                    options={transformToOptions(filteredWorkers)}
                    value={selectedWorkers}
                  />
                  <div style={{ marginBottom: 24 }}>
                    <SubmitButton onClick={this.handleSend}>
                      Guardar
                    </SubmitButton>
                  </div>
                </Collapse>
                <Divider />
              </div>
              <div className={classes.section}>
                <Typography variant="h4">Firmas</Typography>
                <Typography variant="subtitle1">Solicitar firmas para los registros y formularios de esta registro o formulario</Typography>
                <Switch checked={checklist?.require_signs} onClick={this.handleToggleVariable("require_signs")} />
                <Collapse in={checklist?.require_signs === 1}>
                  <Typography variant="subtitle1">Grupos</Typography>
                  {groups.length === 0 ?
                    <Typography variant="subtitle1">No hay grupos actualmente...</Typography>
                    :
                    this.renderGroups(groups)
                  }
                  <Divider style={{ margin: '12px 0' }} />
                  <TextInput label="Nuevo grupo" name="name" onChange={this.handleChangeValue} value={params.name} />
                  <TextInput label="Descripción" name="description" onChange={this.handleChangeValue} value={params.description} />
                  <div style={{ marginBottom: 24 }}>
                    <SubmitButton onClick={this.handleCreateGroup}>
                      Guardar
                    </SubmitButton>
                  </div>
                </Collapse>
              </div>
            </div>
          }
        </div>
        <Dialog open={openDelete} onClose={() => this.setState({ openDelete: false, selectedGroup: {}, groupParams: {} })}>
          <div className={classes.dialog}>
            <Typography variant="h4">¿Seguro que quieres eliminar este grupo?</Typography>
            <SubmitButton onClick={this.handleDeleteGroup}>Eliminar</SubmitButton>
          </div>
        </Dialog>
      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setUsers: body => dispatch(setAuthorizedUsersAction(body)),
  editSettingsChecklist: (body) => dispatch(asignCalendarAction(body)),
  getActivity: () => dispatch(getEnterpriseActivitiesAction()),
})

const mapStateToProps = state => ({
  workers: state.workers
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ChecklistSettingsDialog))