import { Button, Collapse, Typography } from '@material-ui/core'
import { Block, Edit, KeyboardArrowDown } from '@material-ui/icons'
import { Box, Paper, IconButton } from '@mui/material'
import useToggle from '../../../Hooks/ToogleHook'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../../AppContext'
import WorkflowItem from './WorkflowItem'
import WorkflowUsers from './WorkflowUsers'
import { renderMailContent } from '../WorkflowMail'
import { sendMail } from '../../../API/workflows'

const css = {
	paper: {
		padding: 2,
		marginBottom: 4
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	info: {
		background: 'whitesmoke',
		padding: 1,
		borderRadius: 1,
		margin: 1,
		position: 'relative',
		'& > div > h4': {
			fontSize: 13,
		},
		'& > *': {
			marginBottom: 2
		}
	},
	infoButton: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	button: {
		textAlign: 'end',
		marginTop: 1
	}
}

export function check_if_step_ready(step) {
	const items = step.phases
	const booleans = items.map(item => (item.data.required && !!item.data.value) || !item.data.required)
	const isReady = booleans.every(Boolean)
	return isReady
}

export function get_finishing_time(step) {
	if (!check_if_step_ready(step)) return ""
	const items = step.phases
	const dates = items.map(item => item.data.updated_at).filter(Boolean).sort((a, b) => {
		if (moment(a) > moment(b)) return -1
		if (moment(a) < moment(b)) return 1
		return 0
	})
	return dates[0]
}

function RenderUsers({ user_id }) {
	const { workers } = useContext(AppContext)
	const selectedWorker = workers.find(w => String(w.id) === user_id)
	if (!selectedWorker) return null
	return (
		<Box>
			<Typography variant='subtitle1'>* {selectedWorker.name}</Typography>
		</Box>
	)
}

function WorkflowStep({ step, workflow, index, onEdit }) {

	const [openCollapse, toggleOpenCollapse] = useToggle(false)
	const [showInfo, toggleInfo] = useToggle(false)
	const [openDialog, toggleDialog] = useToggle(false)
	const [enabled, setEnabled] = useState(true)
	const { account, level, workers } = useContext(AppContext)
	const { data, phases } = step

	useEffect(() => {
		function check_if_previous_ready() {
			const steps = workflow.workflow_steps || []
			if (index === 0) return true
			const prev_step = steps[index - 1]
			return check_if_step_ready(prev_step) && !!(prev_step.data.finished_on || prev_step.data.finished)
		}
		setEnabled(check_if_previous_ready())
	}, [step, workflow, index])

	function check_if_responsible() {
		if (!data?.responsible_ids) return []
		const ownerResponsible = data?.owner_as_responsible ? [String(workflow.user_id)] : []
		const allResponsibles = data?.responsible_ids.concat(ownerResponsible)
		const iamResponsible = allResponsibles.includes(String(account.user.id))
		return iamResponsible || allResponsibles.length === 0
	}

	function getUsersNames(ids) {
		if (!Array.isArray(ids) || ids.length === 0) return "Sin asignar"
		return ids.map(id => (workers.find(w => w.id === id)?.name)).join(", ")
	}

	function finish() {
		const newData = { ...data }
		const steps = workflow.workflow_steps || []
		newData.finished_on = moment().format("YYYY-MM-DD HH:mm")
		const body = { ...step, data: newData }
		const details = renderMailContent(phases, "", getUsersNames)
		const content = `<p>Hola! Se ha terminado la etapa ${step?.data?.name}, y su contenido es:</p>
										${details}`

		let mail_ids = data.auth_ids
		if (index < steps.length - 1) {
			const nextStep = steps[index + 1]
			const nextStepResponsibles = nextStep?.data?.responsible_ids
			if (nextStep.data.owner_as_responsible && !nextStepResponsibles.includes(String(account.user.id))) {
				nextStepResponsibles.push(String(account.user.id))
			}
			mail_ids = mail_ids.concat(nextStepResponsibles)
		}
		const emails = mail_ids.map(user_id => workers.find(w => String(w.id) === user_id).email)
		const mailBody = { subject: `Avance de proceso: ${workflow?.name}`, content, emails }
		sendMail(mailBody)
		onEdit(body)
	}

	const isFinished = data.finished_on || data.finished
	const updated_at = data.finished_on || data.finished || get_finishing_time(step)
	const steps = workflow.workflow_steps || []
	const prev_step = index > 0 ? steps[index - 1] : null
	const isAdmin = level === 1
	const responsibles = data.responsible_ids || []
	const auths = data.auth_ids || []

	return (
		<Paper sx={css.paper}>
			<Box onClick={toggleOpenCollapse}>
				<Box sx={css.header}>
					<Typography variant='h4'>{data.name}</Typography>
					{enabled ? <KeyboardArrowDown /> : <Block />}
				</Box>
				<Box sx={css.additional}>
					<Box style={{ width: 'fit-content' }}>
						<Typography
							variant='subtitle1'
							style={{ color: "blue", cursor: 'pointer', textDecoration: 'underline', fontWeight: 600 }}
							onClick={(e) => { e.stopPropagation(); toggleInfo() }}
						>
							Ver más información
						</Typography>
					</Box>
					<Collapse in={showInfo}>
						<Box sx={css.info}>
							{isAdmin &&
								<IconButton sx={css.infoButton} onClick={(e) => { e.stopPropagation(); toggleDialog() }}>
									<Edit />
								</IconButton>
							}
							<Box>
								<Typography variant='h4'>Responsables:</Typography>
								{!data.owner_as_responsible && !data.responsible_ids?.length && <Typography variant="subtitle1" color="primary">Sin asignar</Typography>}
								{data.owner_as_responsible && <Typography variant="subtitle1" color="primary">* Creador</Typography>}
								{responsibles.map(uid => <RenderUsers user_id={uid} />)}
							</Box>
							<Box>
								<Typography variant='h4'>Serán notificados:</Typography>
								{!data.owner_as_auth && !data.auth_ids?.length && <Typography variant="subtitle1" color="primary">Sin asignar</Typography>}
								{data.owner_as_auth && <Typography variant="subtitle1" color="primary">* Creador</Typography>}
								{auths.map(uid => <RenderUsers user_id={uid} />)}
							</Box>
						</Box>
					</Collapse>
				</Box>
				{enabled ?
					<Box>
						{updated_at ?
							<Typography variant='caption'>{`${isFinished ? "Terminado el:" : "Última actualización:"} ${updated_at}`}</Typography>
							:
							<Typography variant='caption'>{`Tiempo transcurrido: ${moment(prev_step ? prev_step?.data?.finished_on : workflow.date_created).fromNow()}`}</Typography>
						}
					</Box>
					:
					<Typography variant='caption'>{`Etapa no iniciada aún`}</Typography>
				}
			</Box>
			<WorkflowUsers open={openDialog} onClose={toggleDialog} step={step} onEdit={onEdit} />
			<Collapse in={openCollapse && enabled}>
				{phases.map(item => <WorkflowItem item={item} disabled={!check_if_responsible()} />)}
			</Collapse>
			<Box sx={css.button}>
				{!isFinished &&
					<Button size="small" color="primary" variant="contained" onClick={finish} disabled={!check_if_step_ready(step)}>
						Terminar Etapa
					</Button>
				}
			</Box>
		</Paper>
	)
}

export default WorkflowStep