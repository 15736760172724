import React, { Component } from 'react'
import { IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { addQrToFile, mergeMultiplePdfFilesAndGet, signDocument } from '../../../Utils/functions'
import { connect } from 'react-redux'
import TNTContext from '../TNTContext'
import moment from 'moment'
import DownloadButton from '../../../Shared/DownloadButton'
import autobind from '../../../Utils/autobind'

const style = () => ({
  signs: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
    margin: 12,
    "@media (max-width:500px)": {
      width: '100%',
      margin: '12px 0',
    },
  },
  signInfo: {
    '& > *': {
      margin: '3px 0'
    }
  },
})
class PSign extends Component {
  constructor() {
    super()
    autobind(PSign, this)
  }

  handleGetTypeName(singular = true) {
    const { elementType } = this.context
    if (singular) return elementType === "talk" ? "Charla" : "Capacitación"
    return elementType === "talk" ? "Charlas" : "Capacitaciones"
  }

  handleDownloadWithSign(sign, params) {
    return () => {
      const signDoc = async () => {
        const { user, element, workers } = this.props
        const { urls, elementType } = this.context
        const selected = element
        const relatorSign = selected?.user_sign[0]
        const file = selected.file
        const url1 = `${process.env.REACT_APP_IMG_URL}${urls.pdf}?id=${selected.id}&u=${user.account.user.id}`
        const parsed_url = new URL(url1)
        if (params) {
          Object.keys(params).forEach(key => {
            parsed_url.searchParams.append(key, params[key])
          })
        }
        console.log(parsed_url.toString())
        const final_url = parsed_url.toString()
        const url2 = process.env.REACT_APP_IMG_URL + urls.document + "/" + file
        const imgUrl = process.env.REACT_APP_IMG_URL + urls.signs + '/' + sign.sign
        const relatorSignUrl = process.env.REACT_APP_IMG_URL + urls.signs + '/' + relatorSign?.sign
        const selectedWorker = workers.all.find(worker => worker.id?.toString() === sign.user_id?.toString())
        const relator = workers.all.find(worker => worker.id?.toString() === selected.user_id?.toString())
        let signedDocument
        if (file.includes(".pdf")) {
          signedDocument = await mergeMultiplePdfFilesAndGet([final_url, url2], `Reporte ${this.handleGetTypeName()}`)
        } else {
          signedDocument = await mergeMultiplePdfFilesAndGet([final_url], `Reporte ${this.handleGetTypeName()}`)
        }

        const finalDoc = await signDocument(signedDocument, sign, imgUrl, relatorSign, relatorSignUrl, `${this.handleGetTypeName()} ${sign.name}`, selectedWorker, relator, true)
        const code = `${selected.id}--${elementType === "talk" ? "k" : "t"}`
        const validateUrl = `${window.location.origin}/validate/${code}`
        addQrToFile(finalDoc, `${selected.name}-${sign.name}-${moment().format("YYYY-MM-DD")}`, validateUrl)
      }
      signDoc()
    }
  }

  render() {
    const { classes, imgUrl, sign, worker, disabled } = this.props
    return (
      <Paper className={classes.signs}>
        <img src={imgUrl} height="75px" width="150px" alt="firma" />
        <div className={classes.signInfo}>
          <Typography variant="body2">{sign.name}</Typography>
          <Typography variant="body1">{worker?.position}</Typography>
          <Typography variant="body1">{worker?.rut || sign?.rut}</Typography>
          <Typography variant="caption">{sign.date}</Typography>
        </div>
        <div className={classes.buttons}>
          <DownloadButton onClick={this.handleDownloadWithSign} params={sign} disabled={disabled} enableOnly={[worker?.sub_enterprise_id]} />
          {/* <IconButton onClick={this.handleDownloadWithSign(sign)} disabled={disabled}>
            <GetApp />
          </IconButton> */}
        </div>
      </Paper>
    )
  }
}

PSign.contextType = TNTContext

const mapStateToProps = state => ({
  user: state.user,
  workers: state.workers
})

export default connect(mapStateToProps)(withStyles(style)(PSign))