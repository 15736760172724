import { Button, Checkbox } from '@material-ui/core'
import { CheckCircleOutline, Clear, CloudUpload, InfoOutlined, KeyboardArrowRight } from '@material-ui/icons'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { uploadFile } from '../../../API/users'
import { createWorkflowFinding, createWorkflowChecklist } from '../../../API/workflows'
import AppContext from '../../../AppContext'
import useToggle from '../../../Hooks/ToogleHook'
import DateInput from '../../../Shared/Inputs/DateInput'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import RTE from '../../../Shared/RTE'
import { text_space, inline_space } from '../../../Utils/defaultStyles'
import { filterActiveWorkers } from '../../../Utils/filters'
import { transformToOptions } from '../../../Utils/functions'
import CreatedFinding from '../../ChecklistNew/Components/CreatedFinding'
import WorkflowContext from '../WorkflowContext'
import WorkflowFindingDialog from './Findings/WorkflowFindingDialog'

const css = {
	container: {
		padding: 2,
		background: 'whitesmoke',
		margin: '12px 0',
		borderRadius: 1
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 1
	},
	headerLine: {
		display: 'flex',
		alignItems: 'center'
	},
	documents: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: 2
	},
	files: {
		flexGrow: 1
	},
	check: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: 1
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	linkContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		maxWidth: 140
	},
	checklist: {
		background: 'white',
		padding: 1,
		borderRadius: 2,
		margin: '12px 0'
	}
}

function DefaultTitle({ label, data, item }) {
	const { addItem } = useContext(WorkflowContext)
	return (
		<Box>
			<Box sx={css.header}>
				<Box sx={css.headerLine}>
					{data.required && <InfoOutlined color="secondary" style={{ marginRight: 6 }} />}
					<Typography variant='h2'>{label}</Typography>
				</Box>
				{!!data.value && <CheckCircleOutline style={{ color: 'green' }} />}
			</Box>
			{data?.updated_at && <Typography variant='caption'>Última actualziación: {data?.updated_at}</Typography>}
			<Box sx={css.check}>
				<Checkbox size='small' onClick={() => addItem(item)} />
				<Typography variant='subtitle1' color="secondary" >Agregar item al correo</Typography>
			</Box>
		</Box>
	)
}

function TextPhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState("")

	function onBlur() { onChange(value) }

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<TextInput disabled={disabled} onBlur={onBlur} value={value} label="" onChange={e => setValue(e.target.value)} />
		</Box>
	)
}

function NumberPhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState("")

	function onBlur() { onChange(value) }

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<TextInput disabled={disabled} onBlur={onBlur} value={value} label="" onChange={e => setValue(e.target.value)} type="number" />
		</Box>
	)
}

function SelectPhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState("")

	function onBlur(e) { onChange(e.target.value) }

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	const entity = data.entity || "normal"
	let options = data?.options?.map(option => ({ label: option, value: option })) || []
	if (entity === "workers") {
		options = transformToOptions(filterActiveWorkers())
	}

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<SelectInput disabled={disabled} value={value} onChange={onBlur} options={options} label="" />
		</Box>
	)
}

function MultiSelectPhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState("")

	function onBlur(e) { onChange(e.target.value) }

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	const entity = data.entity || "normal"
	let options = data?.options?.map(option => ({ label: option, value: option })) || []
	if (entity === "workers") {
		options = transformToOptions(filterActiveWorkers())
	}

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<NewMultiSelectInput disabled={disabled} value={value} onChange={onBlur} options={options} label="" />
		</Box>
	)
}
function DatePhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState("")

	function onBlur(e) {
		onChange(e.target.value)
	}

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<DateInput disabled={disabled} value={value} label="" onChange={onBlur} />
		</Box>
	)
}

function RTEPhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState("")

	function onBlur() { onChange(value) }

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<Box style={{ marginTop: 12 }}>
				<RTE disabled={disabled} onBlur={onBlur} value={value} label="" onChange={e => setValue(e.target.value)} />
			</Box>
		</Box>
	)
}

function CheckPhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState(false)

	function changeValue() {
		const newValue = !value
		setValue(newValue)
		onChange(newValue)
	}

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<Box style={{ marginTop: 12 }}>
				<Box sx={css.headerLine}>
					<Checkbox disabled={disabled} checked={value} onClick={changeValue} />
					<Typography variant="subtitle1">Confirmar acción</Typography>
				</Box>
			</Box>
		</Box>
	)
}

function FilePhase({ data, onChange, disabled, item }) {

	const [value, setValue] = useState("")

	async function handleChange(e) {
		const { target } = e
		const file = target.files[0]
		const body = new FormData()
		body.append("name", file.name)
		body.append("file", file)
		const response = await uploadFile(body)
		const oldFiles = value ? value.split("#") : []
		oldFiles.push(response.data.info)
		const newFiles = oldFiles.join("#")
		setValue(newFiles)
		onChange(newFiles)
	}

	async function deleteDocument(index) {
		const oldFiles = value ? value.split("#") : []
		oldFiles.splice(index, 1)
		const newFiles = oldFiles.join("#")
		setValue(newFiles)
		onChange(newFiles)
	}

	function simulateClick() {
		document.getElementById(`file-${data.label}`).click()
	}

	useEffect(() => { if (!!data.value) { setValue(data.value) } }, [data])

	const files = value ? value.split("#") : []

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<input onChange={handleChange} id={`file-${data.label}`} type="file" style={{ position: 'absolute', opacity: 0 }} />
			<Box sx={css.documents}>
				<Box sx={css.files}>
					{files.map((file, index) => {
						const fileUrl = file.includes("tazki.s3.us-east-2.amazonaws.com") ? file :
							`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}${file}`
						return (
							<Box sx={css.linkContainer}>
								<Typography
									onClick={() => window.open(fileUrl)}
									sx={css.link}
									key={file}
									color="secondary"
								>
									{`Documento ${index + 1}`}
								</Typography>
								<IconButton size="small" onClick={() => deleteDocument(index)}>
									<Clear />
								</IconButton>
							</Box>
						)
					})}
				</Box>
				<Box style={{ textAlign: 'end' }}>
					<Button disabled={disabled} onClick={simulateClick} color="secondary" variant="contained" endIcon={<CloudUpload />}>
						Subir documento
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

function FindingStep({ item, data, onChange }) {

	const [openDialog, toggleDialog] = useToggle(false)

	async function onCreate(body) {
		body.id = item.id
		const response = await createWorkflowFinding(body)
		const finding = response.data.info
		const new_findings = data.value || []
		new_findings.push(finding)
		onChange(new_findings)
	}

	const findings = data.value || []

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<Button color="primary" variant="contained" onClick={toggleDialog}>
				Realizar Hallazgo
			</Button>
			<WorkflowFindingDialog open={openDialog} onClose={toggleDialog} onCreateFinding={onCreate} />
			<Box sx={css.findings}>
				{findings.map(finding =>
					<Box key={finding.id}>
						<Divider sx={text_space} />
						<CreatedFinding finding={finding} />
					</Box>
				)}
			</Box>
		</Box>
	)
}

function ChecklistPhase({ item, data, onChange }) {

	console.log(data)
	console.log(item)

	const { history } = useContext(AppContext)

	async function createChecklist() {
		const body = { base_checklist_id: data.base_checklist_id, phase_id: item.id }
		const response = await createWorkflowChecklist(body)
		const checklist = response.data.info
		const new_checklists = data.value || []
		new_checklists.push(checklist)
		onChange(new_checklists)
	}

	const checklists = data.value || []

	function gotoChecklist(id) {
		return () => {
			history.push(`/checklists/${id}`)
		}
	}

	return (
		<Box>
			<DefaultTitle item={item} data={data} label={data.label} />
			<Button color="primary" variant="contained" onClick={createChecklist}>
				Agregar registro
			</Button>
			<Box sx={css.checklists}>
				{checklists.map(checklist =>
					<Box sx={{ ...css.checklist, ...inline_space }} key={checklist.id}>
						<Box>
							<Typography variant='subtitle2' >{checklist.preventive_measure}</Typography>
							<Typography variant='caption'>{moment(checklist.created_at).format("YYYY-MM-DD HH:mm")}</Typography>
						</Box>
						<IconButton onClick={gotoChecklist(checklist.id)}>
							<KeyboardArrowRight />
						</IconButton>
					</Box>
				)}
			</Box>
		</Box>
	)
}

function WorkflowItem({ value, item, disabled }) {

	const { editItem } = useContext(WorkflowContext)
	const { data } = item

	function onEdit(value) {
		const newData = { ...data, updated_at: moment().format("YYYY-MM-DD HH:mm") }
		newData.value = value
		const body = { ...item, data: newData }
		console.log(body)
		editItem(body)
	}

	let Component = null
	switch (data.type) {
		case "text": { Component = <TextPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "rte": { Component = <RTEPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "date": { Component = <DatePhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "check": { Component = <CheckPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "number": { Component = <NumberPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "select": { Component = <SelectPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "file": { Component = <FilePhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "multiselect": { Component = <MultiSelectPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "findings": { Component = <FindingStep item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		case "checklists": { Component = <ChecklistPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
		default: { Component = <TextPhase item={item} disabled={disabled} onChange={onEdit} data={data} />; break }
	}
	return (
		<Box sx={css.container}>
			{Component}
		</Box>
	)
}

export default WorkflowItem