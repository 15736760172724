import { Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { setChecklistNotifications } from '../../../../API/checklists'
import AppContext from '../../../../AppContext'
import NewMultiSelectInput from '../../../../Shared/Inputs/NewMultiSelectInput'
import { transformToOptions } from '../../../../Utils/functions'

function ChecklistNotifications({ checklist = {} }) {

	const [selectedUsers, setSelected] = useState(checklist.notified_users)
	const { workers } = useContext(AppContext)

	useEffect(() => {
		setSelected(checklist.notified_users)
	}, [checklist])

	function setUsers() {
		const body = { id: checklist.id, users: selectedUsers }
		setChecklistNotifications(body)
	}

	return (
		<Box>
			<Typography variant='h4'>Usuarios notificados al finalizar</Typography>
			<NewMultiSelectInput
				grouped={(worker) => worker.branch?.name || "Sin asignar"}
				options={transformToOptions(workers)} label="Seleccione usuarios"
				value={selectedUsers}
				onChange={e => setSelected(e.target.value)}
				onBlur={setUsers}
			/>
		</Box>
	)
}

export default ChecklistNotifications