import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getBranches } from '../../../../API/branches'
import { getSubEnterprises } from '../../../../API/enterprise'
import { getPositions, getUserBaseActivitiesReport } from '../../../../API/users'
import DateIntervalSelector from '../../../../Shared/Inputs/DateIntervalSelector'
import LoaderAnimator from '../../../../Shared/LoaderAnimator'
import DocumentationCard from './DocumentationCard'

const css = {
	cards: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		gap: 2
	}
}

function MandatoryDocumentationReport({ value, branch }) {

	const [activities, setActivities] = useState([])
	const [options, setOptions] = useState([])
	const [dates, setDates] = useState({})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		async function fetchData() {
			setLoading(true)
			const response = await getUserBaseActivitiesReport({ branch_id: branch, ...dates })
			setActivities(response.data.info)
			const options_response = await Promise.all([getSubEnterprises(), getBranches(), getPositions()])
			const optionsData = options_response.map(r => r.data.info)
			setOptions(optionsData)
			setLoading(false)
		}

		fetchData()

	}, [branch, dates])

	function onDateChange(d) {
		setDates(d)
	}

	return (
		<Box>
			<Typography variant='h4' style={{ marginBottom: 12 }} >Reporte de Documentación Obligatoria</Typography>
			<DateIntervalSelector onDateChange={onDateChange} />
			{loading ?
				<Box style={{ position: 'relative', height: 200 }}>
					<LoaderAnimator />
				</Box>
				:
				<Box sx={css.cards}>
					{activities.map(ac => <DocumentationCard doc={ac} options={options} />)}
				</Box>
			}
		</Box>
	)
}

export default MandatoryDocumentationReport