import moment from "moment"
import { PDFDocument } from "pdf-lib"
import { addQrToFile, mergeMultiplePdfFilesAndGet } from "../../../Utils/functions"
import { callSnackbar } from "../../../Utils/snackbar"
import SignCell from "../../Activities/SignCell"
import { generateRegistersDocument } from "../../Activities/Utils/SettingsUtils"
import ImageCell from "../../Reports/Activities/ImageCell"

export function downloadRow(row, account) {
	return async () => {
		const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_CHECKLIST_PDF}?id=${row.id}&u=${account.user.id}`
		const signedDocument = await mergeMultiplePdfFilesAndGet([url], "Reporte de registro o formulario")
		const code = `${row.id}--l`
		const validateUrl = `${window.location.origin}/validate/${code}`
		addQrToFile(signedDocument, `${moment().format("DD-MM-YYYY")}-${row.preventive_measure}-${row.branch_name}`, validateUrl)
	}
}

export async function downloadReport(selectedRows, checklist, activity) {
	const body = {
		checklist_ids: selectedRows,
		preventive_measure_id: checklist.id,
		activity_id: activity.id
	}
	// Generate document and fetch id
	const documentResponse = await generateRegistersDocument(body)
	if (documentResponse.data.status !== "success") {
		return callSnackbar("No se pudo generar el documento", "error")
	}
	const documentInfo = documentResponse.data.info
	const documentId = documentInfo.id

	// Download document and add QR with ID
	const documentPDF = await fetch('https://app.safeasy.cl/api/model/pdf/multiple-checklists.php', {
		method: 'post',
		body: JSON.stringify(body),
	})

	const pdfBytes = await documentPDF.arrayBuffer()
	const bytes = new Uint8Array(pdfBytes)
	let reportPDF = await PDFDocument.load(bytes)

	const code = `${documentId}--c`
	const validateUrl = `${window.location.origin}/validate/${code}`
	await addQrToFile(reportPDF, `Reporte consolidado`, validateUrl)
}

export function createTableInfo(checklist) {
	const selectedExtraFields = checklist?.extra_fields || []
	const selectedChecklistElements = checklist?.checklist_items || []
	console.log(selectedExtraFields)
	console.log(selectedChecklistElements)

	const tableInfo = selectedChecklistElements.concat(selectedExtraFields).map(element => ({
		name: element.name || element.label, label: element.name || element.label, type: element?.type || "item"
	}))


	tableInfo.push({ name: "Observaciones", label: "observation" })
	tableInfo.push({ name: "Responsable", label: "user" })
	tableInfo.push({ name: "Fecha", label: "date_done" })
	tableInfo.push({ name: "Hora", label: "time_done" })
	tableInfo.push({ name: "Firmas", label: "signs", render: SignCell })

	const finalInfo = tableInfo.map(el => {
		if (el?.type === "img") return { ...el, render: ImageCell }
		if (el?.type === "item") return {
			...el, format: (value, element) => {
				return value
			}
		}
		return el
	})
	return finalInfo
}

export function createTableData(checklists) {
	const allAnswers = checklists.map(checklist => {
		const baseExtra = checklist.extra_fields || []
		return checklist.checklist_items
			.concat(baseExtra.concat([{ label: "observation", value: checklist.observations }])
				.concat([{ label: "user", value: checklist.user }])
				.concat([{ label: "date_done", value: checklist.date_done }])
				.concat([{ label: "time_done", value: checklist.hour_done }])
				.concat([{ label: "id", value: checklist.id }])
				.concat([{ label: "signs", value: checklist.signs }])
			)
	})

	const processedData = allAnswers.map(answer => {

		// Factor for IDS
		function getFactor(item) {
			const crit = item?.answer?.criticality
			if (crit === 4) return 2
			if (crit === 3) return 1.5
			return 1
		}

		const filtered = answer
			.filter(item => item?.counter === 1 && item?.answer?.counter !== null)
		let total = { answer: { counter: 0 } }
		if (filtered.length > 0) {
			total = filtered
				.reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
		}
		const totalCounters = total?.answer?.counter
		const keys = answer.map(element => element.name || element.label)
		const values = answer.map(element => {
			if (element.name) {
				return `${element.answer.value}${element.answer?.counter > 0 ? ` / ${element.answer.counter}` : ""}`
			} else if (element.label) {
				if (element.label === "IDS") {
					return element?.value ? ((totalCounters * 100) / element.value).toFixed(2) : 0
				}
				return element.value
			}
			return null
		})
		const result = {}
		keys.forEach((key, index) => { result[key] = values[index] })
		return result
	})

	const tableData = processedData.sort((a, b) => {
		if (moment(a.date_done) > moment(b.date_done)) return -1
		if (moment(a.date_done) < moment(b.date_done)) return 1
		return 0
	})

	return tableData
}