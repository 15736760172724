import React, { Component } from 'react'
import { IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import LoaderComponent from '../../../Shared/LoaderComponent'
import { GetApp } from '@material-ui/icons'
import ChecklistContext from '../ChecklistContext'
import DownloadButton from '../../../Shared/DownloadButton'

const style = () => ({
  paper: {
    marginTop: 24,
    padding: 12,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12
  }
})

class ChecklistTitle extends Component {
  render() {
    const { checklist, loading } = this.context
    const { classes, download } = this.props
    const activityName = checklist?.activity
    const checklistName = checklist?.preventive_measure
    return (
      <Paper className={classes.paper}>
        <LoaderComponent loading={loading}>
          <div className={classes.inline}>
            <div className={classes.title}>
              <Typography variant="h1" >
                {checklistName}
              </Typography>
              <Typography variant="caption">
                {activityName}
              </Typography>
            </div>
            <div>
              <DownloadButton onClick={download} params={checklist} disabled={checklist?.status !== "Terminado"} />
              {/* <IconButton onClick={download(checklist)} disabled={checklist?.status !== "Terminado"}>
                <GetApp />
              </IconButton> */}
            </div>
          </div>
        </LoaderComponent>
      </Paper>
    )
  }
}

ChecklistTitle.contextType = ChecklistContext

export default withStyles(style)(ChecklistTitle)