import React, { Component } from 'react'
import InnerTag from './InnerTag'
import { Box } from '@mui/material'

const css = {
  tag: {
    borderRadius: '50%',
    height: 16,
    width: 16,
    margin: 3,
    transition: 'all 0.2s linear',
    overflow: 'hidden',
    '& > *': {
      visibility: 'hidden',
    },
    '&:hover': {
      width: '100%',
      height: '100%',
      borderRadius: 24,
      padding: '3px 12px',
      '& > *': {
        color: 'white',
        fontWeight: 600,
        visibility: 'visible'
      }
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}


function TagCell({ value, as_string }) {
  if (as_string) return value.map(tag => tag.name).join("-")
  if (!value) return null
  return (
    <Box sx={css.container}>
      {value.map(tag => <InnerTag tag={tag} />)}
    </Box>
  )
}

export default TagCell