import React, { Component } from 'react'
import { Tab, Typography, withStyles } from '@material-ui/core'
import Programs from './Programs'
import MyTabs from '../../Shared/MyTabs'
import autobind from '../../Utils/autobind'
import TabPanel from '../../Shared/TabPanel'
import ProgramsVisualization from './Visualization/ProgramsVisualization'
import { getSettingsChecklist } from '../../API/checklists'
import { getSettingsTalks } from '../../API/talks'
import { getSettingsTrainings } from '../../API/trainings'
import { getSettingsWorkflows } from '../../API/workflows'
import { getSettingsActivities } from '../../API/activities'
import { getSettingsBranchDocuments } from '../../API/branches'
import ProgramContext from './ProgramContext'
import { recordTypes } from './info'
import ProgramContainer from './ProgramContainer'
import SingleProgram from './Visualization/SingleProgram'
import LoadingDialog from '../../Shared/LoadingDialog'

const style = () => ({
  container: {
    margin: 24
  },
  tabs: {
    background: ' white',
    marginBottom: 24
  }
})

class MainProgramsView extends Component {
  constructor() {
    super()
    this.state = {
      tab: 1,
      checklists: [],
      talks: [],
      trainings: [],
      workflows: [],
      activities: [],
      documents: [],
      loading: false
    }
    autobind(MainProgramsView, this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const responses = await Promise.all([
      getSettingsChecklist(),
      getSettingsTalks(),
      getSettingsTrainings(),
      getSettingsWorkflows(),
      getSettingsActivities(),
      getSettingsBranchDocuments()
    ])
    const [checklists, talks, trainings, workflows, activities, documents] = responses.map(response => response.data.info)
    this.setState({ checklists, talks, trainings, workflows, activities, documents: documents.map(d => d.subcategories).flat() })
    this.setState({ loading: false })
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
    const { history } = this.props
    history.push("/programs")
  }

  handleGetRecordName(type, id) {
    const recordType = recordTypes.find(t => String(t.value) === String(type))
    if (recordType.label === "Actividad") return this.state.activities.find(element => String(element.id) === String(id))?.name
    if (recordType.label === "Registro o formulario") return this.state.checklists.find(element => String(element.id) === String(id))?.name
    if (recordType.label === "Capacitación") return this.state.trainings.find(element => String(element.id) === String(id))?.name
    if (recordType.label === "Charla") return this.state.talks.find(element => String(element.id) === String(id))?.name
    if (recordType.label === "Proceso") return this.state.workflows.find(element => String(element.id) === String(id))?.name
    if (recordType.label === "Carpeta de documentos centro de trabajo") return this.state.documents.find(element => String(element.id) === String(id))?.name
  }

  handleGetTypeName(type) {
    return recordTypes.find(t => String(t.value) === String(type))?.label
  }

  render() {
    const { classes, match: { params: { id: programId } } } = this.props
    const { tab, loading } = this.state
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <div className={classes.tabs}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab
              label={
                <Typography variant="h4">
                  Configuración
                </Typography>
              }
              value={0}
            />
            <Tab
              label={
                <Typography variant="h4">
                  Visualización
                </Typography>
              }
              value={1}
            />
          </MyTabs>
        </div>
        <ProgramContext.Provider value={{ ...this.state, getRecordTypeName: this.handleGetTypeName, getRecordName: this.handleGetRecordName }}>
          <TabPanel value={tab} index={0}>
            {programId ? <ProgramContainer /> : <Programs />}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {programId ? <SingleProgram /> : <ProgramsVisualization />}
          </TabPanel>
        </ProgramContext.Provider>
      </div>
    )
  }
}

export default withStyles(style)(MainProgramsView)