import React, { Component } from 'react'
import { Button, Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import { filterActiveWorkers } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'
import Injured from './Injured'

const style = () => ({
  container: {
    margin: '12px 24px'
  },
  title: {
    margin: '12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialog: {
    padding: 24
  },
  button: {
    textAlign: 'end'
  }
})

class InjuredManager extends Component {
  constructor() {
    super()
    this.state = {
      openAdd: false,
      selected: []
    }
    autobind(InjuredManager, this)
  }

  handleOpenAdd() {
    this.setState({ openAdd: !this.state.openAdd })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ selected: target.value })
  }

  renderInjured() {
    const { injured = [], onEdit, onDelete } = this.props
    const allWorkers = filterActiveWorkers()
    if (!injured.length) return (<Typography variant="subtitle1">Aún no se ingresan afectados</Typography>)
    return injured.map(inj => {
      const name = allWorkers.find(w => w.id.toString() === inj.user_id.toString())?.name?.split(" - ")[0] || null
      inj.name = name
      return <Injured injuredUser={inj} onEdit={onEdit} onDelete={onDelete} />
    })
  }

  handleCreateInjured() {
    const { onCreate } = this.props
    const { selected } = this.state
    const injured = [...selected]
    onCreate(injured)
    this.setState({ openAdd: false, selected: [] })
  }

  render() {
    const { classes, injured } = this.props
    const { openAdd, selected } = this.state
    const usableWorkers = filterActiveWorkers().filter(user => {
      const injuredIds = injured.map(i => i.user_id.toString())
      return !injuredIds.includes(user.id.toString())
    })
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h4">Afectados por el accidente/incidente</Typography>
          <Button onClick={this.handleOpenAdd} color="primary" variant="contained">
            Agregar afectados
          </Button>
        </div>
        <Divider />

        {this.renderInjured()}
        <Dialog open={openAdd} onClose={this.handleOpenAdd} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <MultiSelectInput
              onChange={this.handleChange}
              value={selected}
              grouped
              options={transformToOptions(usableWorkers)}
              label="Seleccionar afectados"
            />
            <div className={classes.button}>
              <Button onClick={this.handleCreateInjured} color="primary" variant="contained">
                Terminar y seleccionar
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(style)(InjuredManager)