import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import Chart from "chart.js"

const style = () => ({
  container: {
    padding: 12,
    maxWidth: 700
  }
})

class BarActivitiesReport extends Component {
  componentDidMount() {
    const { data = { activities: {} } } = this.props
    const chartElement = document.getElementById("bars-activities")
    const myChartRef = chartElement.getContext("2d")
    const labels = Object.keys(data.activities)
    const finalData = Object.values(data.activities).map(activity => activity.amount)
    this.chart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: finalData,
            borderWidth: 1,
            backgroundColor: '#91deb1',
            label: "Cumplimiento"
          },
          {
            data: [],
            backgroundColor: '#7373e8',
            label: "N° Registros y formularios"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            offset: true,
            ticks: {
              min: 0,
              max: 100,
              stepSize: 10
            }
          }]
        }
      },
    })
  }

  componentDidUpdate(prevProps) {
    const { data = { activities: {} } } = this.props
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      const labels = Object.keys(data.activities)
      const finalData = Object.values(data.activities).map(activity => activity.amount)
      const lineData = Object.values(data.activities).map(activity => activity.finished)
      // const colors = data.map(element => element.color)
      this.chart.data.labels = labels
      this.chart.data.datasets = [
        {
          type: 'line',
          data: lineData,
          backgroundColor: "#91deb1",
          borderColor: "#91deb1",
          pointRadius: 6,
          fill: false,
          label: "Cumplimiento"
        },
        {
          data: finalData,
          backgroundColor: '#7373e8',
          label: "N° Registros y formularios"
        },
      ]
      this.chart.options = {
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            offset: true,
            ticks: {
              min: 0,
              max: 100,
              stepSize: 10
            }
          }]
        }
      }
      this.chart.update()
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h4">Actividades y cumplimiento</Typography>
        <Divider style={{ margin: '12px 0' }} />
        <div style={{ margin: 24 }}>
          <canvas id="bars-activities" ref={this.chartRef} />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(BarActivitiesReport)