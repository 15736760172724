import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import GeneralTable from '../../../Shared/GeneralTable'

const style = theme => ({
  container: {
    padding: 12
  },
  titleContainer: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > *": {
      color: "white"
    },
    maxWidth: 500,
    marginBottom: 12
  }
})

class NonConformitiesResume extends Component {

  render() {
    const { classes, match, unconformities, workers, enterprise } = this.props
    const option = match.params.option
    const allNonConformities = unconformities.all || []
    const allWorkers = workers.all || []
    const pendings = allNonConformities.filter(nonConfomity => nonConfomity.status === "Pendiente")
    const overdue = allNonConformities.filter(nonConfomity => nonConfomity.status === "Vencido")
    const processing = allNonConformities.filter(nonConfomity => nonConfomity.status === "Procesando" || nonConfomity.status === "Vencido")
    const done = allNonConformities.filter(nonConfomity => nonConfomity.status === "Terminado")

    const selectedId = enterprise?.selected?.id

    let nonConfomitysToUse = []
    switch (option) {
      case "pending": {
        nonConfomitysToUse = pendings
        break
      }
      case "assigned": {
        nonConfomitysToUse = processing
        break
      }
      case "overdue": {
        nonConfomitysToUse = overdue
        break
      }
      case "finished": {
        nonConfomitysToUse = done
        break
      }
      default: {
        nonConfomitysToUse = pendings
        break
      }
    }

    const tableData = allWorkers.map(worker => {
      return {
        name: worker.name,
        amount: nonConfomitysToUse.filter(nonConfomity => nonConfomity.user_creator_id.toString() === worker.id.toString()).length,
      }
    }).filter(data => data.amount !== 0)

    const optionsDict = {
      pending: "no asignado",
      assigned: "en proceso",
      overdue: "vencidos",
      finished: "por aprobar"
    }

    const tableInfo = [
      { label: "name", name: "Nombre" },
      { label: "amount", name: `Total ${optionsDict[option]}` },
    ]


    return (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1">{`${selectedId === 14 ? "IPAs" : "No Conformidades"} ${optionsDict[option]}`}</Typography>
        </div>
        <GeneralTable data={tableData} info={tableInfo} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  unconformities: state.unconformities,
  workers: state.workers,
  enterprise: state.enterprise
})

export default connect(mapStateToProps)(withStyles(style)(NonConformitiesResume))