import React, { Component, useState } from 'react'
import { Dialog, DialogContent, IconButton, withStyles } from '@material-ui/core'
import autobind from '../Utils/autobind'
import { KeyboardArrowLeft, KeyboardArrowRight, RotateRight } from '@material-ui/icons'
import { Badge, Box } from '@mui/material'
import useToggle from '../Hooks/ToogleHook'
import { end_buttons, inline_space } from '../Utils/defaultStyles'

const css = {
  images_container: {
    position: 'relative',
  },
  image: {
    position: 'absolute',
    cursor: 'pointer',
    left: 0,
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  }
}

function MultipleImageContainer({ images, height, width, border, alt }) {

  const [openDialog, toggleDialog] = useToggle(false)
  const [page, setPage] = useState(0)
  const [rotation, setRotation] = useState(0)
  const actualImage = images[page]

  function changePage(option) {
    if (option === "next") return setPage(p => (p + 1) % images.length)
    if (option === "prev") return setPage(p => (p - 1 + images.length) % images.length)
  }

  function onRotate() {
    setRotation(r => (r + 90) % 360)
  }

  const hasImages = !!images.length

  return (
    <Box>
      <Box>
        <Box sx={css.images_container} style={{ height, width }}>
          {hasImages ? images.map((image, index) =>
            <Box sx={css.image} style={{ transform: `translate(${index * 18}px, 0)`, borderRadius: border }}>
              <Badge badgeContent={index === images.length - 1 ? images.length : 0} color="info">
                <img src={image} height={height} width={width} alt={alt} onClick={toggleDialog} style={{ borderRadius: border }} />
              </Badge>
            </Box>
          ) :
            <img src={'/noimage.png'} height={height} width={width} alt={alt} style={{ borderRadius: border }} />
          }
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={toggleDialog} fullWidth maxWidth="sm">
        <DialogContent>
          <Box sx={end_buttons}>
            <IconButton onClick={onRotate}>
              <RotateRight />
            </IconButton>
          </Box>
          <Box style={{ overflow: 'auto', maxWidth: 600, maxHeight: 600 }} >
            <img style={{ transform: `rotate(${rotation}deg)` }} src={actualImage} alt={alt} width="100%" />
          </Box>
          <Box sx={inline_space}>
            <IconButton onClick={() => changePage("prev")}>
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton onClick={() => changePage("next")}>
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default MultipleImageContainer