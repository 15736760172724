import React, { Component, Fragment } from 'react'
import { Collapse, Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import { createPermissionsEppAction, createPermissionsEppCategoryAction, deletePermissionsEppCategoryAction, editPermissionsEppAction, editPermissionsEppCategoryAction, getPermissionsEppCategoriesAction, getPermissionsEppsAction } from '../../../../Actions/EppPermisionsAction'
import autobind from '../../../../Utils/autobind'
import { connect } from 'react-redux'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../../Shared/SubmitButton'
import LoaderAnimator from '../../../../Shared/LoaderAnimator'
import { Delete, Edit, KeyboardArrowDown } from '@material-ui/icons'

const style = theme => ({
  createForm: {
    background: 'white',
    maxWidth: 700,
    margin: 'auto',
    marginTop: 24,
    marginBottom: 24
  },
  title: {
    background: theme.palette.primary.main,
    padding: 12,
    color: 'white'
  },
  form: {
    padding: 12
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    '&:hover': {
      background: 'whitesmoke'
    },
    height: 48,
    padding: '0px 12px'
  },
  eppsContainer: {
    background: 'white',
    marginBottom: 12
  },
  epps: {
    padding: 12
  }
})

class Epps extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      params: {},
      selectedEdit: null,
      selectedDelete: null,
      openBar: -1,
      loadingEpps: false,
      epps: [],
      selectedEpp: null
    }
    autobind(Epps, this)
  }

  componentDidMount() {
    const { getPermissionsEppCategories } = this.props
    getPermissionsEppCategories().then(() => this.setState({ loading: false }))
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreateCategory() {
    const { createPermissionsEppCategory } = this.props
    const { params } = this.state
    const body = { ...params }
    createPermissionsEppCategory(body)
    this.setState({ params: {} })
  }

  handleOpenBar(value) {
    return () => {
      const { getPermissionsEpps } = this.props
      const { openBar } = this.state
      if (openBar === value) return this.setState({ openBar: -1 })
      this.setState({ openBar: value, loadingEpps: true })
      const body = { category_id: value }
      getPermissionsEpps(body).then(response => {
        this.setState({ epps: response?.payload?.data?.info?.epps || [], loadingEpps: false })
      })
    }
  }

  handleOpenEdit(selectedEdit) {
    return () => {
      const { params } = this.state
      params.editName = selectedEdit.name
      this.setState({ selectedEdit })
    }
  }

  handleOpenDelete(selectedDelete) {
    return () => {
      this.setState({ selectedDelete })
    }
  }

  handleOpenEditEpp(selectedEpp) {
    return () => {
      const { params } = this.state
      params.editEppName = selectedEpp.name
      this.setState({ selectedEpp })
    }
  }

  handleCloseModals() {
    this.setState({ selectedDelete: null, selectedEdit: null, selectedEpp: null, params: {} })
  }

  handleEditCategory() {
    const { selectedEdit, params } = this.state
    const { editPermissionsEppCategory } = this.props
    const body = {
      name: params.editName,
      id: selectedEdit.id
    }
    editPermissionsEppCategory(body)
    this.handleCloseModals()
    this.setState({ params: {} })
  }

  handleEditEpp() {
    const { selectedEpp, params, openBar } = this.state
    const { editPermissionsEpp } = this.props
    const body = {
      name: params.editEppName,
      id: selectedEpp.id,
      category_id: openBar
    }
    this.handleCloseModals()
    this.setState({ params: {} })
    editPermissionsEpp(body).then(response => {
      this.setState({ epps: response?.payload?.data?.info?.epps })
    })

  }

  handleDeleteCategory() {
    const { selectedDelete } = this.state
    const { deletePermissionsEppCategory } = this.props
    const body = {
      id: selectedDelete.id
    }
    deletePermissionsEppCategory(body)
    this.handleCloseModals()
  }

  renderEpps() {
    const { epps } = this.state
    const { classes } = this.props
    return epps.map(epp =>
    (
      <div className={classes.category}>
        <Typography variant="subtitle2">{epp.name}</Typography>
        <IconButton onClick={this.handleOpenEditEpp(epp)}>
          <Edit />
        </IconButton>
      </div>
    ))
  }

  handleCreateEpp() {
    const { openBar, params } = this.state
    const { createPermissionsEpp } = this.props
    const body = {
      name: params.eppName,
      category_id: openBar,
    }
    createPermissionsEpp(body).then(response => {
      this.setState({ epps: response?.payload?.data?.info?.epps || [] })
    })
  }

  renderCategories() {
    const { openBar, loadingEpps, params } = this.state
    const { classes, settings } = this.props
    const allCategories = settings?.epps_categories?.all || []
    const list = allCategories.map(category => {
      const isOpen = category.id === openBar
      return (
        <Fragment key={category.id}>
          <Divider />
          <div className={classes.category}>
            <Typography variant="subtitle1">{category.name}</Typography>
            <div className={classes.buttons}>
              <IconButton onClick={this.handleOpenBar(category.id)}>
                <KeyboardArrowDown />
              </IconButton>
              <IconButton onClick={this.handleOpenEdit(category)}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleOpenDelete(category)}>
                <Delete />
              </IconButton>
            </div>
          </div>
          <Collapse in={isOpen}>
            {loadingEpps ?
              <div style={{ height: 120, position: 'relative', background: 'white' }}>
                <LoaderAnimator loading />
              </div>
              :
              <div className={classes.eppsContainer}>
                <Typography variant="h2" className={classes.title}>Crear EPP</Typography>
                <div className={classes.form}>
                  <TextInput label="Nombre EPP" name="eppName" value={params.eppName} onChange={this.handleChange} />
                  <SubmitButton onClick={this.handleCreateEpp}>Crear</SubmitButton>
                </div>
                <Divider />
                <div className={classes.epps}>
                  {this.renderEpps()}
                </div>
              </div>
            }
          </Collapse>
        </Fragment>
      )
    })
    return (
      <>
        <div className={classes.category}>
          <div>
            <Typography variant="subtitle1">Nombre</Typography>
          </div>
          <div>
            <Typography variant="subtitle1">Acciones</Typography>
          </div>
        </div>
        {list}
      </>
    )
  }

  EditDialog() {
    const { classes } = this.props
    const { selectedEdit, params } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedEdit !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Editar Categoría</Typography>
        <div className={classes.form}>
          <TextInput label="Nombre" name="editName" value={params.editName} onChange={this.handleChange} />
          <SubmitButton onClick={this.handleEditCategory}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  EditEppDialog() {
    const { classes } = this.props
    const { selectedEpp, params } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedEpp !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Editar EPP</Typography>
        <div className={classes.form}>
          <TextInput label="Nombre" name="editEppName" value={params.editEppName} onChange={this.handleChange} />
          <SubmitButton onClick={this.handleEditEpp}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  DeleteDialog() {
    const { classes } = this.props
    const { selectedDelete } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedDelete !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Eliminar Categoría: {selectedDelete?.name}</Typography>
        <div className={classes.form}>
          <Typography variant="subtitle1">¿Seguro que deseas eliminar esta categoría?</Typography>
          <SubmitButton onClick={this.handleDeleteCategory}>Eliminar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  render() {
    const { classes } = this.props
    const { params, loading } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.createForm}>
          <Typography variant="h2" className={classes.title}>Crear categoría</Typography>
          <div className={classes.form}>
            <TextInput label="Nombre" name="name" value={params.name} onChange={this.handleChange} />
            <SubmitButton onClick={this.handleCreateCategory}>Crear</SubmitButton>
          </div>
        </div>
        {loading ?
          <div style={{ position: 'relative', height: 300 }}>
            <LoaderAnimator loading />
          </div>
          :
          this.renderCategories()
        }
        {this.EditDialog()}
        {this.DeleteDialog()}
        {this.EditEppDialog()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  createPermissionsEppCategory: body => dispatch(createPermissionsEppCategoryAction(body)),
  getPermissionsEppCategories: body => dispatch(getPermissionsEppCategoriesAction(body)),
  editPermissionsEppCategory: body => dispatch(editPermissionsEppCategoryAction(body)),
  deletePermissionsEppCategory: body => dispatch(deletePermissionsEppCategoryAction(body)),
  getPermissionsEpps: body => dispatch(getPermissionsEppsAction(body)),
  createPermissionsEpp: body => dispatch(createPermissionsEppAction(body)),
  editPermissionsEpp: body => dispatch(editPermissionsEppAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Epps))