import React, { Component } from 'react'
import { Button, Collapse, Typography, withStyles } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import TextInput from '../../../Shared/Inputs/TextInput'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import ImgInput from '../../../Shared/Inputs/ImgInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import instance from '../../../Utils/instance'
import autobind from '../../../Utils/autobind'
import Subquestions from './Subquestions'
import RutInput from '../../../Shared/Inputs/RutInput'

const style = () => ({
  inlineSep: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  extraField: {
    marginBottom: 24,
    "@media (max-width:500px)": {
      maxWidth: "unset",
      margin: 0,
      marginBottom: 24,
      width: '100%'
    }
  },
  extraTitle: {
    margin: "12px 0"
  },
})

function renderMessages(options, value) {
  return options.map(option => {
    if (typeof option === "string") {
      const [expected, message, color] = option.split('&')
      return (
        <Collapse in={expected === value && message}>
          <Typography style={{ color }} variant="subtitle1">{message}</Typography>
        </Collapse>
      )
    }
    const { label: expected, message, color } = option
    return (
      <Collapse in={expected === value && message}>
        <Typography style={{ color }} variant="subtitle1">{message}</Typography>
      </Collapse>
    )

  })
}

function getTotalCounters(checklist) {
  function getFactor(item) {
    const crit = item?.answer?.criticality
    if (crit === 4) return 2
    if (crit === 3) return 1.5
    return 1
  }
  const items = checklist.checklist_items
  const filtered = items
    .filter(item => item.counter === 1 && item.answer.counter !== null)
  if (filtered.length === 0) return 0
  const total = filtered
    .reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
  return total.answer.counter
}

class ExtraFields extends Component {
  constructor() {
    super()
    autobind(ExtraFields, this)
  }

  handleChangeImage(event) {
    const { target } = event
    const { extra, onChange } = this.props
    const file = target.value

    //Upload File and get the name of the saved one
    const body = new FormData()
    body.append("file", file)
    const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/extra-fields-file.php`
    const oldImages = extra[target.name]
    instance({
      method: 'post',
      url,
      data: body
    }).then(response => {
      if (oldImages) {
        extra[target.name] = `${oldImages}&#&${response.data?.info?.file_name}`
        const newEvent = {
          target: {
            name: target.name,
            value: `${oldImages}&#&${response.data?.info?.file_name}`
          }
        }
        return onChange(newEvent)
      } else {
        extra[target.name] = response.data?.info?.file_name
        const newEvent = {
          target: {
            name: target.name,
            value: response.data?.info?.file_name
          }
        }
        return onChange(newEvent)
      }
    })
  }

  handleDeleteImage(name, index) {
    const { extra, onChange } = this.props
    const imageString = extra[name]
    const images = imageString.split("&#&")
    images.splice(index, 1)
    const value = images.join("&#&")
    const newEvent = { target: { name, value } }
    onChange(newEvent)
  }

  renderSubquestion(options, answer) {
    const { checklist } = this.props
    const selectedOption = options.find(option => option.label === answer)
    const hasSubquestions = selectedOption?.subquestions?.length > 0
    if (!hasSubquestions) return null
    const subquestions = selectedOption.subquestions
    return subquestions.map((question, index) => {
      return (
        <Subquestions
          option={selectedOption}
          question={question}
          index={index}
          selected={checklist}
          disabled={checklist.status === "Terminado"}
        />
      )
    })
  }

  renderExtraFields() {
    const { classes, checklist, extra, extended, onChange, disabled } = this.props
    const selected = checklist
    const fields = selected?.extra_fields || []

    function transofrmToExtraOptions(options) {
      return options.map(option => {
        if (typeof option === "string") {
          return {
            label: option.split('&')[0],
            value: option.split('&')[0]
          }
        }
        return { label: option.label, value: option.label }
      })
    }

    return fields.concat(extended).map((field, index) => {
      switch (field.type) {
        case "text": {
          return (
            <div className={classes.extraField}>
              <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
              <TextInput
                disabled={selected.status === "Terminado" || disabled}
                name={index}
                value={extra[index]}
                onChange={onChange}
                label={""}
                input={field}
              />
            </div>
          )
        }
        case "rut": {
          return (
            <div className={classes.extraField}>
              <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
              <RutInput
                disabled={selected.status === "Terminado" || disabled}
                name={index}
                value={extra[index]}
                onChange={onChange}
                label={""}
                input={field}
              />
            </div>
          )
        }
        case "select": {
          return (
            <div className={classes.extraField}>
              <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
              <SelectInput
                disabled={selected.status === "Terminado" || disabled}
                name={index}
                value={extra[index]}
                onChange={onChange}
                label={""}
                options={transofrmToExtraOptions(field.options)}
                input={field}
              />
              {renderMessages(field.options, extra[index])}
              {this.renderSubquestion(field.options, extra[index], index)}
            </div>
          )
        }
        case "multiselect": {
          return (
            <div className={classes.extraField}>
              <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
              <MultiSelectInput
                disabled={selected.status === "Terminado" || disabled}
                name={index}
                value={extra[index]}
                onChange={onChange}
                label={""}
                options={transofrmToExtraOptions(field.options)}
                withText
                input={field}
              />
              {renderMessages(field.options, extra[index])}
              {this.renderSubquestion(field.options, extra[index])}
            </div>
          )
        }
        case "img": {
          return (
            <div className={classes.extraField}>
              <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
              <ImgInput
                disabled={selected.status === "Terminado" || disabled}
                name={index}
                value={extra[index]}
                onChange={this.handleChangeImage}
                label={""}
                onDelete={this.handleDeleteImage}
                url={`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`}
                input={field}
              />
            </div>
          )
        }
        case "ids": {
          return (
            <div className={classes.extraField}>
              <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
              <Typography variant="subtitle1">Eventos Ponderados: {getTotalCounters(selected)}</Typography>
              <TextInput
                disabled={selected.status === "Terminado" || disabled}
                name={index}
                type="number"
                value={extra[index]}
                onChange={onChange}
                label={"Cantidad de trabajadores"}
              />
              <Typography variant="subtitle1">Resultado: {extra[index] ? (getTotalCounters(selected) * 100) / extra[index] : 0}</Typography>
            </div>
          )
        }
        default: {
          return (
            <div className={classes.extraField}>
              <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
              <TextInput
                disabled={selected.status === "Terminado" || disabled}
                name={index}
                value={extra[index]}
                onChange={onChange}
                label={""}
                input={field}
              />
            </div>
          )
        }
      }

    })
  }


  render() {
    const { classes, disabled, handleOpen, disableNew = false } = this.props
    return (
      <div className={classes.container} >
        <Typography variant="h4" className={classes.extraTitle}>Información a completar</Typography>
        <div className={classes.fields}>
          {this.renderExtraFields()}
          {(!disabled && !disableNew) && <Button style={{ width: '100%' }} onClick={handleOpen}>
            <div className={classes.inlineSep} style={{ width: '100%' }}>
              <Typography variant="subtitle1">
                Agregar campo
              </Typography>
              <AddCircle color="secondary" />
            </div>
          </Button>}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ExtraFields)