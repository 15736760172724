import React, { Component, Fragment } from 'react'
import { Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../../Utils/autobind'
import { connect } from 'react-redux'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../../Shared/SubmitButton'
import LoaderAnimator from '../../../../Shared/LoaderAnimator'
import { Edit } from '@material-ui/icons'
import { createPermissionsTypeAction, editPermissionsTypeAction, getPermissionsTypeAction } from '../../../../Actions/TypesPermissionsAction'
import { getPermissionsRequirementCategoriesAction } from '../../../../Actions/RequirementsPermissionsActions'
import MultiSelectInput from '../../../../Shared/Inputs/MultiSelectInput'
import { transformToOptions } from '../../../../Utils/functions'
import { getPermissionsEppCategoriesAction, getPermissionsEppsAction } from '../../../../Actions/EppPermisionsAction'

const style = theme => ({
  createForm: {
    background: 'white',
    maxWidth: 700,
    margin: 'auto',
    marginTop: 24,
    marginBottom: 24
  },
  title: {
    background: theme.palette.primary.main,
    padding: 12,
    color: 'white'
  },
  form: {
    padding: 12
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    '&:hover': {
      background: 'whitesmoke'
    },
    height: 48,
    padding: '0px 12px'
  },
  epps: {
    padding: 12,
    background: 'white'
  },
})

class Types extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      params: {},
      selectedEdit: null,
      selectedDelete: null,
      openBar: -1,
      allEpps: []
    }
    autobind(Types, this)
  }

  componentDidMount() {
    const { getPermissionsType, getPermissionsRequirementsCategories, getPermissionsEppCategories, getPermissionsEpp } = this.props
    getPermissionsRequirementsCategories()
    getPermissionsEppCategories().then(response => {
      const categories = response?.payload?.data?.info || []
      let allEpps = []
      categories.forEach(category => {
        const body = { category_id: category.id }
        getPermissionsEpp(body).then(response2 => {
          const epps = response2?.payload?.data?.info?.epps || []
          allEpps = allEpps.concat(epps)
          this.setState({ allEpps })
        })
      })
    })
    getPermissionsType().then(() => this.setState({ loading: false }))

  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { createPermissionsType } = this.props
    const { params } = this.state
    const body = { ...params }
    createPermissionsType(body)
    this.setState({ params: {} })
  }

  handleOpenBar(value) {
    return () => {
      const { openBar } = this.state
      if (openBar === value) return this.setState({ openBar: -1 })
      this.setState({ openBar: value })
    }
  }

  handleOpenEdit(selectedEdit) {
    return () => {
      const { params } = this.state
      params.editName = selectedEdit.name
      params.editRequirements = selectedEdit.requirements.map(r => r.id.toString())
      params.editEpps = selectedEdit.epps.map(e => e.id.toString())
      this.setState({ selectedEdit })
    }
  }

  handleOpenDelete(selectedDelete) {
    return () => {
      this.setState({ selectedDelete })
    }
  }

  handleCloseModals() {
    this.setState({ selectedDelete: null, selectedEdit: null })
  }

  handleEdit() {
    const { selectedEdit, params } = this.state
    const { editPermissionsType } = this.props
    const body = {
      name: params.editName,
      epps_id: params.editEpps,
      requirements_id: params.editRequirements,
      id: selectedEdit.id
    }
    editPermissionsType(body)
    this.handleCloseModals()
    this.setState({ params: {} })
  }

  handleDelete() {
    const { selectedDelete } = this.state
    const { deletePermissionsTypes } = this.props
    const body = {
      id: selectedDelete.id
    }
    deletePermissionsTypes(body)
    this.handleCloseModals()
  }

  renderCategories() {
    const { classes, settings } = this.props
    const allCategories = settings?.types?.all || []
    const list = allCategories.map(category => {
      return (
        <Fragment key={category.id}>
          <Divider />
          <div className={classes.category}>
            <Typography variant="subtitle1">{category.name}</Typography>
            <div className={classes.buttons}>
              <IconButton onClick={this.handleOpenEdit(category)}>
                <Edit />
              </IconButton>
            </div>
          </div>
        </Fragment>
      )
    })
    return (
      <>
        <div className={classes.category}>
          <div>
            <Typography variant="subtitle1">Nombre</Typography>
          </div>
          <div>
            <Typography variant="subtitle1">Acciones</Typography>
          </div>
        </div>
        {list}
      </>
    )
  }

  EditDialog() {
    const { classes, settings } = this.props
    const { selectedEdit, params, allEpps } = this.state
    const allCategories = settings?.requirements_categories?.all || []
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedEdit !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Editar tipo de permiso</Typography>
        <div className={classes.form}>
          <TextInput label="Nombre" name="editName" value={params.editName} onChange={this.handleChange} />
          <MultiSelectInput value={params.editRequirements} onChange={this.handleChange} label="Categoría de exigencia" name="editRequirements" options={transformToOptions(allCategories)} />
          <MultiSelectInput value={params.editEpps} onChange={this.handleChange} label="Epps" name="editEpps" options={transformToOptions(allEpps)} />
          <SubmitButton onClick={this.handleEdit}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  DeleteDialog() {
    const { classes } = this.props
    const { selectedDelete } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedDelete !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Eliminar Área: {selectedDelete?.name}</Typography>
        <div className={classes.form}>
          <Typography variant="subtitle1">¿Seguro que deseas eliminar esta área?</Typography>
          <SubmitButton onClick={this.handleDelete}>Eliminar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  render() {
    const { classes, settings } = this.props
    const { params, loading, allEpps } = this.state
    const allCategories = settings?.requirements_categories?.all || []
    return (
      <div className={classes.container}>
        <div className={classes.createForm}>
          <Typography variant="h2" className={classes.title}>Crear tipo de permiso</Typography>
          <div className={classes.form}>
            <TextInput label="Nombre" name="name" value={params.name} onChange={this.handleChange} />
            <MultiSelectInput value={params.requirements_id} onChange={this.handleChange} label="Categoría de exigencia" name="requirements_id" options={transformToOptions(allCategories)} />
            <MultiSelectInput value={params.epps_id} onChange={this.handleChange} label="Epps" name="epps_id" options={transformToOptions(allEpps)} />
            <SubmitButton onClick={this.handleCreate}>Crear</SubmitButton>
          </div>
        </div>
        {loading ?
          <div style={{ position: 'relative', height: 300 }}>
            <LoaderAnimator loading />
          </div>
          :
          this.renderCategories()
        }
        {this.EditDialog()}
        {this.DeleteDialog()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  createPermissionsType: body => dispatch(createPermissionsTypeAction(body)),
  getPermissionsRequirementsCategories: body => dispatch(getPermissionsRequirementCategoriesAction(body)),
  getPermissionsType: body => dispatch(getPermissionsTypeAction(body)),
  editPermissionsType: body => dispatch(editPermissionsTypeAction(body)),
  getPermissionsEppCategories: body => dispatch(getPermissionsEppCategoriesAction(body)),
  getPermissionsEpp: body => dispatch(getPermissionsEppsAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Types))