import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import NewMultiSelectInput from '../../../../Shared/Inputs/NewMultiSelectInput'


function transformSuppliesToOptions(supplies) {
	if (!supplies) return []
	const options = []
	supplies.forEach(family => {
		const types = family.supply_types
		types.forEach(type => {
			options.push({ label: type.name, value: String(type.id), family: family.name })
		})
	})
	return options
}

function ChecklistSupplyTypes({ supplies, onSelectTypes, selectedValues }) {

	const [selected, setSelected] = useState(selectedValues.map(x => String(x.id)))

	function onBlur(values) {
		onSelectTypes(values)
	}

	useEffect(() => {
		setSelected(selectedValues.map(x => String(x.id)))
	}, [selectedValues])

	return (
		<Box>
			<Typography variant='h4'>Tipos de insumos relacionados</Typography>
			<NewMultiSelectInput onBlur={onBlur} value={selected} onChange={e => setSelected(e.target.value)} label="Tipos de insumos" grouped={(value) => value.family} options={transformSuppliesToOptions(supplies)} />
		</Box>
	)
}

export default ChecklistSupplyTypes