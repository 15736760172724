import createSnackbar from "../Utils/snackbar"
import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getEnterpriseAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: `/company/get.php`,
        params: body,
    })
    return {
        type: "GET_ENTERPRISE",
        payload: REQUEST,
    }
}

export const editEnterpriseAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "/company/edit.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "UPDATE_ENTERPRISE",
        payload: REQUEST,
    }
}

export const getBranches = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/branches/get.php",
        params: body,
    })
    return {
        type: "GET_BRANCHES",
        payload: REQUEST,
    }
}

export const editBranchAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "/branches/edit.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "UPDATE_BRANCH",
        payload: REQUEST,
    }
}

export const getWorkersAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/users/get.php",
        params: body,
    })
    return {
        type: "GET_WORKERS",
        payload: REQUEST,
    }
}

export const getWorkerAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/users/get.php",
        params: body,
    })
    return {
        type: "GET_WORKER",
        payload: REQUEST,
    }
}

export const editWorkerAction = (body) => {
    const REQUEST = instance2({
        method: "post",
        url: "/users/edit.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "UPDATE_WORKER",
        payload: REQUEST,
    }
}

export const createWorkerAction = (body) => {
    const REQUEST = instance2({
        method: "post",
        url: "/users/create.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_WORKER",
        payload: REQUEST,
    }
}

export const getEnterpriseDocumentsAction = body => {
    const REQUEST = instance2({
        method: 'get',
        url: 'company/get_documents.php'
    })
    return {
        type: "GET_ENTERPRISE_DOCUMENTS",
        payload: REQUEST
    }
}

export const editEnterpriseDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'company/edit_document.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "EDIT_ENTERPRISE_DOCUMENT",
        payload: REQUEST
    }
}

export const createEnterpriseDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'company/create_document.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_ENTERPRISE_DOCUMENT",
        payload: REQUEST
    }
}

export const deleteEnterpriseDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'company/delete_document.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "DELETE_ENTERPRISE_DOCUMENT",
        payload: REQUEST
    }
}

export const changeEnterpriseLogoAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'company/logo.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "UPDATE_ENTERPRISE_LOGO",
        payload: REQUEST
    }
}