import { AddPhotoAlternate, AddPhotoAlternateOutlined } from '@material-ui/icons'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { createBaseGroup, createBaseOption, createBaseQuestion, deleteBaseOption, deleteBaseQuestion, editBaseGroup, editBaseGroupPhoto, editBaseOption, editBaseOptionPhoto, editBaseQuestion, editBaseQuestionPhoto, getBaseEvaluations } from '../../../API/evaluations'
import AddElementButton from '../../../Shared/AddElementButton'
import ImageContainer from '../../../Shared/ImageContainer'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import BaseQuestion from './BaseQuestion'
import EvaluationContext from './EvaluationContext'

const css = {
  container: {
    padding: 2,
    maxWidth: 900
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 1,
    background: 'white',
    borderRadius: 2,
    margin: '12px 0'
  },
  group: {
    background: 'white',
    borderRadius: 2,
    padding: 2,
    paddingRight: 1,
    margin: '12px 0'
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}

function SetupEvaluation({ value, match }) {

  const [evaluation, setEvaluation] = useState({})
  const { id } = match.params

  useEffect(() => {
    async function fetchData() {
      const response = await getBaseEvaluations({ id })
      setEvaluation(response.data.info)
    }

    fetchData()

  }, [])

  async function editGroup(body) {
    body.evaluation_id = id
    const response = await editBaseGroup(body)
    setEvaluation(response.data.info)
  }

  async function editGroupPhoto(body) {
    body.append("evaluation_id", id)
    const response = await editBaseGroupPhoto(body)
    setEvaluation(response.data.info)
  }

  async function createGroup() {
    const response = await createBaseGroup({ id })
    setEvaluation(response.data.info)
  }

  async function createQuestion(body) {
    body.evaluation_id = id
    const response = await createBaseQuestion(body)
    setEvaluation(response.data.info)
  }

  async function editQuestion(body) {
    body.evaluation_id = id
    const response = await editBaseQuestion(body)
    setEvaluation(response.data.info)
  }

  async function editQuestionPhoto(body) {
    body.append("evaluation_id", id)
    const response = await editBaseQuestionPhoto(body)
    setEvaluation(response.data.info)
  }

  async function deleteQuestion(body) {
    const response = await deleteBaseQuestion(body)
    setEvaluation(response.data.info)
  }

  async function createOption(body) {
    body.evaluation_id = id
    const response = await createBaseOption(body)
    setEvaluation(response.data.info)
  }

  async function editOption(body) {
    body.evaluation_id = id
    const response = await editBaseOption(body)
    setEvaluation(response.data.info)
  }

  async function editOptionPhoto(body) {
    body.append("evaluation_id", id)
    const response = await editBaseOptionPhoto(body)
    setEvaluation(response.data.info)
  }

  async function deleteOption(body) {
    body.evaluation_id = id
    const response = await deleteBaseOption(body)
    setEvaluation(response.data.info)
  }

  const { groups = [] } = evaluation

  return (
    <EvaluationContext.Provider value={{
      editQuestion,
      deleteQuestion,
      createOption,
      editOption,
      deleteOption,
      createQuestion,
      editGroup,
      editGroupPhoto,
      editQuestionPhoto,
      editOptionPhoto
    }} >
      <Box sx={css.container}>
        <Typography variant='h1' style={{ marginBottom: 24 }}>Configuración: {evaluation.name}</Typography>
        <Box sx={css.header}>
          <Typography variant='h4' style={{ paddingLeft: 12 }}>Formulario:</Typography>
          <AddElementButton name="Crear item de preguntas" onClick={createGroup} />
        </Box>
        {groups.map((group) => <QuestionGroup key={group.id} group={group} />)}
      </Box>
    </EvaluationContext.Provider>
  )
}

function QuestionGroup({ group }) {

  const [name, setName] = useState(group.name)
  const { createQuestion, editGroup, editGroupPhoto } = useContext(EvaluationContext)
  const inputFile = useRef(null)

  function onBlur() {
    const body = { ...group, name }
    editGroup(body)
  }

  function onCreate() {
    const body = { id: group.id }
    createQuestion(body)
  }

  function selectFile(event) {
    const { target } = event
    const file = target.files[0]
    const body = new FormData()
    body.append("file", file)
    body.append("id", group.id)
    editGroupPhoto(body)
  }

  const questions = group.questions || []

  return (
    <Box sx={css.group}>
      <input type='file' accept='image/*' hidden ref={inputFile} onChange={selectFile} />
      <Box sx={css.inline}>
        <SeamlessInput color="transparent" placeholder="Ingrese nombre del Item..." onBlur={onBlur} variant="h4" value={name} onChange={e => setName(e.target.value)} />
        <Tooltip title="Ingresar imágen de apoyo para el Item">
          <IconButton onClick={() => inputFile.current.click()}>
            <AddPhotoAlternateOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      {group.photo && <ImageContainer src={group.photo} alt="apoyo-item" height={64} width={64} border={6} />}
      <Box sx={css.inline}>
        <Typography variant='h4' style={{ paddingLeft: 6 }}>Preguntas:</Typography>
        <AddElementButton name="Crear Pregunta" onClick={onCreate} />
      </Box>
      {questions.map(question => <BaseQuestion key={question.id} question={question} />)}
    </Box>
  )
}

export default withRouter(SetupEvaluation)