const event = new Event("offlineRequest")

export const enqueueRequest = (request, name, origin) => {
  request.name = name
  request.origin = origin
  let savedRequests = localStorage.getItem("SavedRequests")
  if (savedRequests) {
    const saved = JSON.parse(savedRequests)
    saved.push(request)
    localStorage.setItem("SavedRequests", JSON.stringify(saved))
  } else {
    savedRequests = [request]
    localStorage.setItem("SavedRequests", JSON.stringify(savedRequests))
  }
  return window.dispatchEvent(event)
}