import React, { Component } from 'react'
import { Checkbox, Collapse, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../../Utils/autobind'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

const style = () => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 8,
		paddingLeft: 8,
		transition: 'all 0.2s linear',
		'&:hover': {
			background: 'whitesmoke'
		}
	},
	option: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	options: {
		maxHeight: 300,
		overflowX: 'auto'
	},
	group: {
		marginBottom: 24
	},
})
class MultiGroups extends Component {
	constructor() {
		super()
		this.state = {
			openGroup: true
		}
		addToggle(MultiGroups, this, "group", true)
		autobind(MultiGroups, this)
	}

	render() {
		const { classes, group, options, value, onChange } = this.props
		const { openGroup } = this.state
		return (
			<div className={classes.group}>
				<div className={classes.header} onClick={this.handleOpenGroup}>
					<Typography variant='h4'>{group}</Typography>
					{!!group &&
						<IconButton size="small">
							{openGroup ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
					}
				</div>
				<Collapse in={openGroup}>
					<div className={classes.options}>
						{options.map((option, index) => {
							const isSelected = value.includes(option.value)
							return (
								<div key={option.label + index} className={classes.option} onClick={onChange(option)}>
									<Checkbox checked={isSelected} />
									<Typography variant='subtitle1'>{option.label}</Typography>
								</div>
							)
						})}
					</div>
				</Collapse>
			</div>
		)
	}
}

export default withStyles(style)(MultiGroups)