import { ErrorOutline } from '@material-ui/icons'
import { Box, Menu, Typography, MenuItem } from '@mui/material'
import newTheme from '../../../../newTheme'

const css = {
	menuItem: {
		'& > svg': {
			marginRight: 2
		}
	}
}

function CritsMenu({ open, anchorEl, onClose, onSelect }) {

	function selectCrit(value) {
		onSelect(value)
		onClose()
	}

	return (
		<Menu open={open} anchorEl={anchorEl} onClose={onClose}>
			<MenuItem sx={css.menuItem} onClick={() => selectCrit(0)} >
				<ErrorOutline style={{ color: newTheme.palette.grey.dark }} />
				<Typography variant='subtitle1'>Sin Criticidad</Typography>
			</MenuItem>
			<MenuItem sx={css.menuItem} onClick={() => selectCrit(1)} >
				<ErrorOutline style={{ color: newTheme.palette.blue.main }} />
				<Typography variant='subtitle1'>No Crítico</Typography>
			</MenuItem>
			<MenuItem sx={css.menuItem} onClick={() => selectCrit(2)} >
				<ErrorOutline style={{ color: newTheme.palette.gold.main }} />
				<Typography variant='subtitle1'>Poco Crítico</Typography>
			</MenuItem>
			<MenuItem sx={css.menuItem} onClick={() => selectCrit(3)} >
				<ErrorOutline style={{ color: newTheme.palette.warning.main }} />
				<Typography variant='subtitle1'>Medianamente Crítico</Typography>
			</MenuItem>
			<MenuItem sx={css.menuItem} onClick={() => selectCrit(4)} >
				<ErrorOutline style={{ color: newTheme.palette.error.main }} />
				<Typography variant='subtitle1'>Altamente Crítico</Typography>
			</MenuItem>
		</Menu>
	)
}

export default CritsMenu