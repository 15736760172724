import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'

const style = () => ({
  container: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  buttons: {
    margin: 12,
    textAlign: 'end',
    '& > *': {
      margin: '0 6px'
    }
  }
})

class SendWarning extends Component {

  render() {
    const { classes, open, onClose, onSubmit } = this.props
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h1">Correos enviados exitosamente</Typography>
          <Typography variant="subtitle1">Desea limpiar el contenido del correo para comenzar de nuevo (recomendado), o continuar con la información actual para enviar más correos</Typography>
          <div className={classes.buttons}>
            <Button color="primary" variant="contained" onClick={onSubmit}>Limpiar (recomendado)</Button>
            <Button color="primary" variant="outlined" onClick={onClose}>Cerrar</Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(SendWarning)