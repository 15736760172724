import { ErrorOutline } from '@material-ui/icons'
import { Box, MenuItem } from '@mui/material'
import newTheme from '../../../../newTheme'

const css = {
	icons: {
		blue: { color: newTheme.palette.blue.main, marginRight: 6 },
		yellow: { color: newTheme.palette.middle.main, marginRight: 6 },
		orange: { color: newTheme.palette.orange.main, marginRight: 6 },
		red: { color: newTheme.palette.error.main, marginRight: 6 },
	},
}

function CritMenu({ value, onCrit }) {
	return (
		<Box>
			<MenuItem onClick={onCrit(1)}>
				<ErrorOutline style={css.icons.blue} />
				No Crítico
			</MenuItem>
			<MenuItem onClick={onCrit(2)}>
				<ErrorOutline style={css.icons.yellow} />
				Poco Crítico
			</MenuItem>
			<MenuItem onClick={onCrit(3)}>
				<ErrorOutline style={css.icons.orange} />
				Medianamente Crítico
			</MenuItem>
			<MenuItem onClick={onCrit(4)}>
				<ErrorOutline style={css.icons.red} />
				Altamente Crítico
			</MenuItem>
		</Box>
	)
}

export default CritMenu