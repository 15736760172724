import React, { Component } from 'react'
import { Button, Dialog, Divider, IconButton, Menu, MenuItem, Paper, Typography, withStyles } from '@material-ui/core'
import { BookmarkBorder, Check, Delete, Edit, MoreVert } from '@material-ui/icons'
import autobind, { addToggle } from '../../Utils/autobind'
import { criticities, criticities_index } from '../Findings/Findings'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { cardStyle } from '../Findings/CardStyles'
import ClickTooltip from '../../Shared/ClickTooltip'
import PermissionChecker from '../../Shared/Permissions/PermissionChecker'
import Status from './Card/Status'
import CardInfo from './Card/CardInfo'
import CardActionSection from './Card/CardActionSection'
import ExtraFieldDialog from './ExtraFieldDialog'
import CardTags from './Card/CardTags'

class PendingCard extends Component {
  constructor() {
    super()
    this.state = {
      openMenu: false,
      anchorEl: "",
      showExtraFields: false
    }
    addToggle(PendingCard, this, "warning")
    autobind(PendingCard, this)
  }

  handleOpenMenu(event) {
    this.setState({ openMenu: !this.state.openMenu, anchorEl: event.target })
  }

  handleApprove() {
    const { approve, group, online } = this.props
    group.forEach(finding => {
      const body = {
        id: finding.id,
        status: 1,
        online: online.status,
        date_approved: moment(new Date()).format("YYYY-MM-DD")
      }
      approve(body)
    })
  }

  handleShowExtrafields() {
    this.setState({ showExtraFields: !this.state.showExtraFields, openMenu: false })
  }

  handleDismiss() {
    const { dismiss, group, online } = this.props
    group.forEach(finding => {
      const body = {
        id: finding.id,
        status: 4,
        online: online.status
      }
      dismiss(body)
    })
    this.setState({ openMenu: false })
  }

  handleGoto() {
    const { history, finding, goto } = this.props
    history.push(`/${goto}/${finding.id}`)
  }

  renderTitle() {
    const { title = { main: [], text: [] }, classes } = this.props
    const { main, text } = title
    return (
      <div>
        {main.map((m, index) => (
          <div className={classes.mainText} key={index + m}>
            <ClickTooltip title={<Typography variant="subtitle1" className={classes.tooltip}>{m}</Typography>}>
              <Typography variant="h4">{m}</Typography>
            </ClickTooltip>
          </div>
        ))}
        {text.map((t, index) => (
          <div className={classes.titleText} key={index + t}>
            <ClickTooltip title={<Typography variant="subtitle1" className={classes.tooltip}>{t}</Typography>}>
              <Typography variant="caption" className={classes.titleText}>{t}</Typography>
            </ClickTooltip>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { openMenu, anchorEl, showExtraFields, openWarning } = this.state
    const { classes, finding, urls, user, group, assign, solve, goto } = this.props
    const level = user.account.user.userType
    const iAmCreator = String(user.account.user.id) === String(finding.user_creator_id)
    return (
      <Paper className={classes.container}>
        <div className={classes.title}>
          {this.renderTitle()}
        </div>
        <div className={classes.subtitle}>
          <div>
            <IconButton size="small" onClick={this.handleOpenMenu}>
              <MoreVert />
            </IconButton>
            <CardTags tags={finding?.tags || []} />
          </div>
          <Status status={finding.status} />
        </div>
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <CardInfo element={finding} urls={urls} goto={goto} openForms={this.handleShowExtrafields} />
        <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
        <CardActionSection element={finding} group={group} assign={assign} solve={solve} urls={urls} />
        <PermissionChecker expectedPermissions={["appf"]} element={finding}>
          {finding.status === "Terminado" &&
            <>
              <Divider style={{ background: criticities[criticities_index[finding.criticality]] }} />
              <div className={classes.finalContainer}>
                <div className={classes.finalButton}>
                  {(level === 1) || (level === 2 && iAmCreator) ?
                    <Button
                      variant="contained" classes={{
                        label: classes.label
                      }}
                      className={classes.greenButton}
                      onClick={this.handleApprove}
                    >
                      Aprobar
                      <Check />
                    </Button>
                    :
                    <Typography variant="caption">Esperando aprobación de administrador...</Typography>
                  }
                </div>
              </div>
            </>
          }
        </PermissionChecker>
        <Menu open={openMenu} anchorEl={anchorEl} onClose={this.handleOpenMenu}>
          <MenuItem className={classes.menuItem} onClick={this.handleGoto}>
            <Typography variant="subtitle1">Ver</Typography>
            <Edit />
          </MenuItem>
          {level === 1 &&
            <MenuItem className={classes.menuItem} onClick={this.handleOpenWarning}>
              <Typography variant="subtitle1">Desestimar</Typography>
              <Delete />
            </MenuItem>
          }
        </Menu>
        <ExtraFieldDialog
          open={showExtraFields}
          onClose={this.handleShowExtrafields}
          title={finding.preventive_measure}
          fields={finding.checklist_extra_fields}
        />
        <Dialog open={openWarning} onClose={this.handleOpenWarning} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant='h1'>Desestimar Hallazgo</Typography>
            <Typography variant='h4'>Seguro que deseas desestimar este hallazgo</Typography>
            <div style={{ textAlign: 'end' }}>
              <Button style={{ marginLeft: 6 }} color="primary" variant="contained" onClick={this.handleOpenWarning}>Cancelar</Button>
              <Button style={{ marginLeft: 6 }} color="primary" variant="outlined" onClick={this.handleDismiss}>Si, Desestimar</Button>
            </div>
          </div>
        </Dialog>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
  online: state.online,
  user: state.user
})

export default withRouter(connect(mapStateToProps)(withStyles(cardStyle)(PendingCard)))