import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import ChecklistDashboard from '../../Home/Dashboard/ChecklistDashboard'
import autobind from '../../../Utils/autobind'
import GeneralTable from '../../../Shared/GeneralTable'

const style = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  table: {
    flexGrow: 1,
    margin: 12,
  },
  dashboard: {
    background: 'white',
    borderRadius: 12,
    padding: 12,
    margin: 12
  },
  chip: {
    background: 'grey',
    color: 'white',
    padding: 12,
    borderRadius: 8,
    marginBottom: 6
  }
})

class ResultsReport extends Component {
  constructor() {
    super()
    this.state = {
      selectedType: null
    }
    autobind(ResultsReport, this)
  }

  handleSelect(value) {
    return () => {
      const { selectedType } = this.state
      if (value === selectedType) return this.setState({ selectedType: null })
      return this.setState({ selectedType: value })
    }
  }

  render() {
    const { classes, data } = this.props
    const { selectedType } = this.state

    const totalPending = data.filter(ch => ch.estado === "Programado").length
    const totalOverdue = data.filter(ch => ch.estado === "Vencido").length
    const totalDone = data.filter(ch => ch.estado === "Terminado").length
    const totalTotal = totalPending + totalOverdue + totalDone
    const finalData = { totalDone, totalOverdue, totalPending, totalTotal }

    const selectedChecklists = data.filter(ch => selectedType === "Totales" || ch.estado === selectedType)

    return (
      <div className={classes.container}>
        <div className={classes.dashboard}>
          <ChecklistDashboard selected={selectedType} data={finalData} selectable onSelect={this.handleSelect} />
        </div>
        {selectedChecklists.length > 0 &&
          <div className={classes.table}>
            <GeneralTable data={selectedChecklists} info={[
              { name: "Fecha", label: "fecha_programado" },
              { name: "Actividad", label: "actividad" },
              { name: "Responsable", label: "responsable" },
              { name: "Centro", label: "centro_de_trabajo" },
            ]} disableFilters scrollable maxHeight={400}
            />
          </div>
        }
      </div>
    )
  }
}

export default withStyles(style)(ResultsReport)