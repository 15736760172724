import { Delete } from '@material-ui/icons'
import { Box, IconButton, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { deleteActivitySignGroup, editActivitySignGroup } from '../../../API/activities'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import { ActivityContext } from './ActivityNew'

const css = {
	container: {
		padding: 2,
		borderRadius: 2,
		background: 'whitesmoke',
		margin: '6px 0',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	}
}

function ActivitySignGroup({ group }) {

	const { setActivity } = useContext(ActivityContext)
	const [params, setParams] = useState({ ...group })

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	async function onBlur() {
		const body = { ...params }
		const response = await editActivitySignGroup(body)
		setActivity(response.data.info)
	}

	async function onDelete() {
		const body = { ...params }
		const response = await deleteActivitySignGroup(body)
		setActivity(response.data.info)
	}

	return (
		<Box sx={css.container}>
			<Box>
				<SeamlessInput color="transparent" name="name" onChange={onChange} onBlur={onBlur} variant='subtitle2' value={params?.name} />
				<SeamlessInput color="transparent" name="description" onChange={onChange} onBlur={onBlur} variant='subtitle1' value={params?.description} />
			</Box>
			<IconButton onClick={onDelete}>
				<Delete />
			</IconButton>
		</Box>
	)
}

export default ActivitySignGroup