import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'
import ComplianceDonut from '../../Workers/WorkerReports/ComplianceDonut'

const style = theme => ({
  container: {
    padding: 12,
  },
  dataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > *': {
      margin: '0 36px 24px 0'
    }
  },
  complianceElement: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    padding: 12,
    margin: '12px 0 0 0',
    '& > *': {
      marginRight: 12,
      color: 'white',
      minWidth: 25
    },
    '& > svg': {
      height: 24,
      width: 24
    }
  },
  green: { background: theme.palette.green.main },
  red: { background: theme.palette.red.main },
  blue: { background: theme.palette.blue.main },
  black: { background: theme.palette.primary.main },
  selectable: {
    cursor: 'pointer',
    transition: 'all 0.1s linear',
    '&:hover': {
      transform: 'scale(1.04)'
    }
  },
  inactive: {
    opacity: 0.5,
    transform: 'scale(0.9)'
  }

})

class ChecklistDashboard extends Component {

  render() {
    const { classes, title = "Registros y formularios", data, selectable, onSelect, selected } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h4">{title}</Typography>
        <Divider style={{ margin: '12px 0' }} />
        <div className={classes.dataContainer}>
          <div>
            <div
              className={`
            ${classes.complianceElement} 
            ${classes.blue} 
            ${selectable ? classes.selectable : ""} 
            ${selected && (selected === "Programado" ? classes.selected : classes.inactive)}
            `}
              onClick={selectable ? onSelect("Programado") : null}
            >
              <CheckCircleOutline />
              <Typography variant="h4">{data?.totalPending ?? 0}</Typography>
              <Typography variant="subtitle1">Programadas</Typography>
            </div>
            <div
              className={`
            ${classes.complianceElement} 
            ${classes.green} 
            ${selectable ? classes.selectable : ""} 
            ${selected && (selected === "Terminado" ? classes.selected : classes.inactive)}
            `}
              onClick={selectable ? onSelect("Terminado") : null}
            >
              <CheckCircleOutline />
              <Typography variant="h4">{data?.totalDone ?? 0}</Typography>
              <Typography variant="subtitle1">Terminadas</Typography>
            </div>
            <div
              className={`
            ${classes.complianceElement} 
            ${classes.red} 
            ${selectable ? classes.selectable : ""} 
            ${selected && (selected === "Vencido" ? classes.selected : classes.inactive)}
            `}
              onClick={selectable ? onSelect("Vencido") : null}
            >
              <CheckCircleOutline />
              <Typography variant="h4">{data?.totalOverdue ?? 0}</Typography>
              <Typography variant="subtitle1">Vencidas</Typography>
            </div>
            <div
              className={`
            ${classes.complianceElement} 
            ${classes.black} 
            ${selectable ? classes.selectable : ""} 
            ${selected && (selected === "Totales" ? classes.selected : classes.inactive)}
            `}
              onClick={selectable ? onSelect("Totales") : null}
            >
              <CheckCircleOutline />
              <Typography variant="h4">{data?.totalTotal ?? 0}</Typography>
              <Typography variant="subtitle1">Totales</Typography>
            </div>

          </div>
          <div>
            <Typography variant='h4'>Avance:</Typography>
            <ComplianceDonut name={title} data={data} />
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ChecklistDashboard)