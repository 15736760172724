import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import { getAskedSigns } from '../../API/users'
import Document from '../Branch/Documents/Document'
import './animation.css'
import DocumentContext from '../Branch/Documents/DocumentContext'

const style = () => ({
  documents: {
    background: 'white',
    borderRadius: 8,
    padding: 12,
    margin: '24px 0'
  },
  container: {
    margin: 12
  }
})

class AskedSignsContainer extends Component {
  constructor() {
    super()
    this.state = {
      documents: []
    }
    autobind(AskedSignsContainer, this)
  }

  async componentDidMount() {
    const response = await getAskedSigns()
    console.log(response)
    const { data: { info: documents } } = response
    this.setState({ documents })
  }

  renderDocuments() {
    const { documents } = this.state
    const query = new URLSearchParams(window.location.search)
    const document_id = query.get("id")
    return documents.map(document => {
      const isAsked = String(document.id) === document_id
      return (
        <DocumentContext.Provider value={{
          base_url: `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_BRANCH_DOCS}`
        }}>
          <div className={isAsked ? "highlight" : ""}>
            <Document document={document} disableEdit />
          </div>
        </DocumentContext.Provider>)
    }

    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h1">Solicitudes de firma</Typography>
        <div className={classes.documents}>
          {this.renderDocuments()}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(AskedSignsContainer)