import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import MyCalendar from '../MyCalendar/MyCalendar'
import autobind, { addToggle } from '../../Utils/autobind'
import { withRouter } from 'react-router-dom'
import SubmitButton from '../../Shared/SubmitButton'
import SelectInput from '../../Shared/Inputs/SelectInput'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import moment from 'moment'
import { transformToOptions } from '../../Utils/functions'
import { getWorkers } from '../../API/users'
import { filterActiveWorkersList } from '../../Utils/filters'
import { createMultipleTrainings, deleteTraining, getAllTrainings, getSettingsTrainings, getTrainingsCategories } from '../../API/trainings'

const style = () => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'white',
    fontWeight: 600,
    cursor: 'move'
  },
  dialog: {
    padding: 24
  },
  input: {
    background: 'white',
    padding: '1px 10px',
    borderRadius: 8,
    maxWidth: 400,
    marginBottom: 12
  }
})

class TrainingsCalendar extends Component {
  constructor() {
    super()
    this.state = {
      events: [],
      dates: {},
      selectedDate: "",
      selectedEvent: {},
      params: {},
      workers: [],
      categories: [],
      talks: [],
      user_id: ""
    }
    addToggle(TrainingsCalendar, this, "create")
    addToggle(TrainingsCalendar, this, "delete")
    autobind(TrainingsCalendar, this)
  }

  async componentDidMount() {
    const responses = await Promise.all([getWorkers(), getTrainingsCategories(), getSettingsTrainings()])
    const [workers, categories, talks] = responses.map(r => r?.data?.info)
    this.setState({ workers, categories, talks })
    window.addEventListener("branch_change", this.handleReload)
  }

  componentWillUnmount() {
    window.removeEventListener("branch_change", this.handleReload)
  }

  async handleGetTalksCalendarInfo(dates) {
    const talks = await getAllTrainings({ ...dates })
    const events = talks.data.info.map(talk => {
      const newTalk = { ...talk }
      newTalk.background = "#0abb87"
      return newTalk
    })
    this.setState({ events })
  }

  async handleReload() {
    const { dates } = this.state
    this.setState({ loading: true })
    const talks = await getAllTrainings({ ...dates })
    const events = talks.data.info.map(talk => {
      const newTalk = { ...talk }
      newTalk.background = "#0abb87"
      return newTalk
    })
    this.setState({ events, loading: false })
  }

  handleRenderTalkEvent(event) {
    const { classes } = this.props
    return (
      <Typography variant='body1' className={classes.text}>{event.name}</Typography>
    )
  }

  handleSetDate(dates) {
    this.setState({ dates })
  }

  handleChange(event) {
    const { target: { name, value } } = event
    const { params } = this.state
    params[name] = value
    this.setState({ params })
  }

  handleRenderTalkTooltip(event) {
    return (
      <div>
        <Typography variant="subtitle1" style={{ color: 'white', fontWeight: 600 }}>{event.name}</Typography>
        <Typography variant="subtitle2" style={{ color: 'white' }}>Categoría: {event.category_name}</Typography>
        <Typography variant="subtitle2" style={{ color: 'white' }}>Responsable: {event.user_name}</Typography>
      </div>
    )
  }

  handleClickDay(date) {
    const { params } = this.state
    params.date_scheduled = date.format("YYYY-MM-DD")
    this.setState({ selectedDate: date.format("YYYY-MM-DD"), params })
    this.handleOpenCreate()
  }

  handleClickDelete(event) {
    this.setState({ selectedEvent: event })
    this.handleOpenDelete()
  }

  handleClickEvent(event) {
    const { history } = this.props
    history.push(`/trainings/${event.id}`)
  }

  handleSelectWorker(event) {
    const { target } = event
    this.setState({ user_id: target.value })
  }

  async handleDelete() {
    const { selectedEvent, events } = this.state
    const indexOfEvent = events.findIndex(ev => ev.id === selectedEvent.id)
    events.splice(indexOfEvent, 1)
    this.setState({ events })
    this.handleOpenDelete()
    await deleteTraining({ id: selectedEvent.id })
    this.handleReload()
  }

  async handleCreate() {
    const { params } = this.state
    const body = { ...params }
    this.handleOpenCreate()
    await createMultipleTrainings(body)
    this.handleReload()
  }

  filter(events) {
    const { user_id } = this.state
    return events.filter(event => user_id === "" || String(event.user_id) === user_id)
  }

  render() {
    const { classes } = this.props
    const { events, loading, openDelete, openCreate, selectedEvent, params, workers, categories, talks, user_id } = this.state
    const documents = talks.filter(talk => talk.category_id.toString() === params?.category_id)
    const usersInEvents = events.map(ev => String(ev.user_id))
    const activeWorkers = filterActiveWorkersList(workers).filter(worker => usersInEvents.includes(worker.id))
    activeWorkers.push({ id: "", name: "Todos" })
    return (
      <>
        <div className={classes.input}>
          <SelectInput
            value={user_id}
            options={transformToOptions(activeWorkers)}
            label="Responsable"
            name="user_id"
            onChange={this.handleSelectWorker}
          />
        </div>
        <MyCalendar
          key="trainings"
          load={this.handleGetTalksCalendarInfo}
          events={this.filter(events)}
          loading={loading}
          setDate={this.handleSetDate}
          event={this.handleRenderTalkEvent}
          tooltip={this.handleRenderTalkTooltip}
          onDayClick={this.handleClickDay}
          // onDragDrop={this.handleDrop}
          onDeleteClick={this.handleClickDelete}
          onEventClick={this.handleClickEvent}
        />
        {/* <NewTalk /> */}
        <Dialog fullWidth maxWidth="sm"
          open={openDelete}
          onClose={this.handleOpenDelete}>
          <div className={classes.dialog}>
            <Typography variant="h4">¿Borrar {selectedEvent?.name}?</Typography>
            <Typography variant="caption">Evento agendado el: {selectedEvent?.date_scheduled}</Typography>
            <SubmitButton onClick={this.handleDelete} >Eliminar</SubmitButton>
          </div>
        </Dialog>
        <Dialog fullWidth maxwidth="sm" open={openCreate} onClose={this.handleOpenCreate}>
          <div className={classes.dialog}>
            <Typography variant="h4">Crear capacitación</Typography>
            <Typography variant="caption">Capacitación agendada para el: {' '}
              <span style={{ fontWeight: 600 }}>
                {moment(params?.date_scheduled).format("DD [de] MMMM [del] YYYY")}
              </span>
            </Typography>
            <SelectInput
              value={params?.user_id}
              options={transformToOptions(filterActiveWorkersList(workers))}
              label="Responsable"
              name="user_id"
              onChange={this.handleChange}
            />
            <SelectInput
              value={params?.category_id}
              options={transformToOptions(categories)}
              label="Categoría"
              name="category_id"
              onChange={this.handleChange}
            />
            <MultiSelectInput
              value={params?.documents_ids}
              options={transformToOptions(documents)}
              label="Charla"
              name="documents_ids"
              onChange={this.handleChange}
            />
            <SubmitButton
              variant="contained"
              onClick={this.handleCreate}
              disabled={params && Object.keys(params)?.length < 3}>
              Crear
            </SubmitButton>
          </div>
        </Dialog>
      </>
    )
  }
}

export default withRouter(withStyles(style)(TrainingsCalendar))