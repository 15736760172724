import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import autobind from '../../../../Utils/autobind'

const style = () => ({
	container: {
		padding: 24
	}
})

class QuestionCategoryDialog extends Component {
	constructor() {
		super()
		autobind(QuestionCategoryDialog, this)
	}

	handleChange(event) {
		const { target } = event
		const { onSubmit } = this.props
		onSubmit(target.value)
	}

	render() {
		const { classes, value, open, onClose, categories } = this.props
		return (
			<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
				<div className={classes.container}>
					<Typography variant='h1'>Asignar Categoría</Typography>
					<SelectInput onChange={this.handleChange} label="" options={categories} name="category" value={value} />
				</div>
			</Dialog>
		)
	}
}

export default withStyles(style)(QuestionCategoryDialog)