import { createPosition, getPositions } from "../../API/users"

export const userInfo = [
  // {
  //   name: "userTypeId",
  //   label: "Tipo de usuario",
  //   type: "select",
  //   options: [
  //     { value: "1", label: "Admin" },
  //     { value: "2", label: "Supervisor" },
  //     { value: "3", label: "Colaborador" },
  //   ],
  // },
  // { name: "email", label: "Correo" },
  // { name: "firstname", label: "Nombre" },
  // { name: "lastname", label: "Apellido" },
  // { name: "rut", label: "RUT" },
  // { name: "phone", label: "Teléfono" },
  // { name: "address", label: "Dirección" },
  // { name: "dateBirth", label: "Fecha de Nacimiento", type: "date" },
  // { name: "position", label: "Cargo" },
  {
    name: "userTypeId",
    label: "Tipo de usuario",
    type: "select",
    options: [
      { value: "1", label: "Admin" },
      { value: "2", label: "Supervisor" },
      { value: "3", label: "Colaborador" },
    ],
    required: true,
  },
  { name: "email", label: "Correo", required: true, },
  { name: "firstname", label: "Nombre", required: true, },
  { name: "lastname", label: "Apellido", required: true, },
  { name: "rut", label: "RUT", required: true, type: "rut" },
  { name: "phone", label: "Teléfono", required: true, },
  { name: "address", label: "Dirección", required: true, },
  { name: "dateBirth", label: "Fecha de Nacimiento", type: "date", required: true, },
  {
    name: "position_id",
    label: "Cargo",
    required: true,
    type: "select_create",
    options: [],
    placeholder: "Seleccionar cargo",
    onFetch: getPositions,
    onCreate: createPosition
  },
  // {
  //   name: "branch_ids",
  //   label: "Sucursal(es)",
  //   type: "multiselect",
  //   options: [],
  //   placeholder: "Seleccionar Sucursal",
  //   required: true,
  // },
  {
    name: "user_id",
    label: "Supervisor",
    type: "select",
    options: [],
    placeholder: "Seleccionar Supervisor",
  },
  {
    name: "sub_enterprise_id",
    label: "Empresa",
    type: "select",
    options: [],
    placeholder: "Seleccionar Empresa",
  },
  { name: "date_entry", label: "Fecha de ingreso", required: false, type: "date" },
  { name: "nationality", label: "Nacionalidad", required: false, },
  { name: "profession", label: "Profesión / Oficio", required: false, },
  { name: "emergency_contact", label: "Contacto de emergencia", required: false, },
  { name: "emergency_phone", label: "Teléfono de emergencia", required: false, },
]