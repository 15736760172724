import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import "./animation.css"
import SubmitButton from './SubmitButton'
import autobind from '../Utils/autobind'

const style = () => ({
  container: {
    position: 'relative',
    height: 250,
    width: 250
  },
  button: {
    position: 'absolute',
    bottom: 12,
    right: 12
  },
  message: {
    padding: 24,
    textAlign: 'center'
  }
})

class LoadingDialog extends Component {
  constructor() {
    super()
    this.state = {
      showOption: false,
      forceClose: false
    }
    autobind(LoadingDialog, this)
    this.timeout = null
  }
  componentDidMount() {
    const { open } = this.props
    if (open) {
      this.timeout = setTimeout(() => this.setState({ showOption: true }), 5000)
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props
    if (prevProps.open !== open) {
      if (open) {
        this.timeout = setTimeout(() => this.setState({ showOption: true }), 5000)
      } else {
        clearTimeout(this.timeout)
        this.setState({ showOption: false })
      }
    }
  }

  handleForceClose() {
    this.setState({ forceClose: true })
  }

  render() {
    const { classes, open, loadingMessage, disableCancel = false } = this.props
    const { forceClose, showOption } = this.state
    return (
      <Dialog open={open && !forceClose} >
        <div className={classes.container}>
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
          <Typography className={classes.message} variant="h4">{loadingMessage}</Typography>
        </div>
        {showOption && !disableCancel &&
          <div className={classes.button}>
            <SubmitButton onClick={this.handleForceClose} color="secondary">
              Cerrar
            </SubmitButton>
          </div>
        }
      </Dialog>
    )
  }
}

LoadingDialog.propTypes = {

}


LoadingDialog.defaultProps = {
  loadingMessage: ""
}

export default withStyles(style)(LoadingDialog)