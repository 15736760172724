import { AddCircleOutline, Delete, Settings } from '@material-ui/icons'
import { Box, Button, Checkbox, Collapse, Divider, IconButton, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import useChange from '../../../Hooks/UseStateChange'
import newTheme from '../../../newTheme'
import BigTooltip from '../../../Shared/BigTooltip'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import { inline, inline_space, text_space } from '../../../Utils/defaultStyles'
import { filterActiveWorkers } from '../../../Utils/filters'
import { transformToOptions } from '../../../Utils/functions'
import PhaseSetup from './PhaseSetup'
import { WorkflowSetupContext } from './WorkflowSetup'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		margin: '12px 0'
	},
	items: {
		padding: '0 6px'
	},
	users: {
		padding: 1,
	}
}

function StepSetup({ step }) {
	const data = step.data || {}
	const { editStep, createPhase, deleteStep } = useContext(WorkflowSetupContext)
	const [name, setName] = useChange(data?.name || "")
	const [openSettings, toggleSettings] = useToggle(false)
	const workers = filterActiveWorkers(false)
	const [responsibles, setResponsibles] = useState(data.responsible_ids || [])
	const [auths, setAuths] = useState(data.auth_ids || [])

	function onBlur() {
		const body = { ...step, step_data: { ...data, name, responsible_ids: responsibles, auth_ids: auths } }
		editStep(body)
	}

	function onCreate() {
		const body = { step_id: step.id }
		createPhase(body)
	}

	function onDelete() {
		const body = { ...step }
		deleteStep(body)
	}

	function onToggle(option) {
		if (option === "responsible") {
			const body = { ...step, step_data: { ...data, owner_as_responsible: !data.owner_as_responsible } }
			editStep(body)
		} else {
			const body = { ...step, step_data: { ...data, owner_as_auth: !data.owner_as_auth } }
			editStep(body)
		}
	}

	const items = step?.phases || []

	return (
		<Box sx={css.container}>
			<Box sx={inline_space}>
				<SeamlessInput onBlur={onBlur} value={name} onChange={setName} color="transparent" variant="h4" placeholder="Ingrese título de la etapa..." />
				<Box>
					<Button variant="outlined" color="info" endIcon={<AddCircleOutline />} onClick={onCreate}>
						Agregar Item
					</Button>
					<BigTooltip title="Configurar Etapa">
						<IconButton onClick={toggleSettings}>
							<Settings />
						</IconButton>
					</BigTooltip>
					<BigTooltip title="Eliminar Etapa">
						<IconButton onClick={onDelete}>
							<Delete />
						</IconButton>
					</BigTooltip>
				</Box>
			</Box>
			<Collapse in={openSettings}>
				<Box sx={css.users}>
					<Typography variant='subtitle2'>Responsables:</Typography>
					<Box sx={inline} onClick={() => onToggle("responsible")}>
						<Checkbox checked={!!data.owner_as_responsible} />
						<Typography variant='subtitle1'>Asignar al creador del proceso como responsable</Typography>
					</Box>
					<NewMultiSelectInput onBlur={onBlur} onChange={(e) => setResponsibles(e.target.value)} value={responsibles} options={transformToOptions(workers)} label="Seleccionar usuarios" />
					<Typography variant='subtitle2'>Notificados:</Typography>
					<Box sx={inline} onClick={() => onToggle("auth")}>
						<Checkbox checked={!!data.owner_as_auth} />
						<Typography variant='subtitle1'>Notificar al creador del proceso en esta etapa</Typography>
					</Box>
					<NewMultiSelectInput onBlur={onBlur} onChange={(e) => setAuths(e.target.value)} value={auths} options={transformToOptions(workers)} label="Seleccionar usuarios" />
				</Box>
			</Collapse>
			<Divider sx={text_space} />
			<Box sx={css.items}>
				<Typography variant='h4' >Items:</Typography>
				{!items.length && <Typography variant='subtitle1'>No hay items creados para esta etapa...</Typography>}
				{items.map(item => <PhaseSetup item={item} key={item.id} />)}
			</Box>
		</Box>
	)
}

export default StepSetup