import React, { Component } from 'react'
import { Button, Dialog, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { createSettingsBranchCategoryAction, createSettingsBranchSubCategoryAction, deleteSettingsBranchCategoryAction, deleteSettingsBranchSubCategoryAction, editSettingsBranchCategoryAction, editSettingsBranchSubCategoryAction, getSettingsBranchCategoryAction } from '../../../Actions/SettingsActions'
import { connect } from 'react-redux'
import { Add, Delete, Edit, ExitToApp, KeyboardArrowLeft } from '@material-ui/icons'
import TextInput from '../../../Shared/Inputs/TextInput'
import autobind from '../../../Utils/autobind'
import SubmitButton from '../../../Shared/SubmitButton'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import SelectInput from '../../../Shared/Inputs/SelectInput'

const style = theme => ({
  title: {
    padding: 12,
    background: theme.palette.primary.main,
    color: "white",
    maxWidth: 700
  },
  container: {
    padding: 12
  },
  card: {
  },
  category: {
    position: 'relative',
    "@media (max-width:500px)": {
      width: '100%'
    },
    width: 300,
    height: 150,
    overflow: 'hidden',
    background: '#FFF',
    borderRadius: "0 12px 12px 12px",
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: 100,
      width: 100,
      transform: "translate(-50%, -50%) rotate(45deg)",
      background: "#f2f3f8"
    }
  },
  subtitle: {
    padding: 12,
    background: theme.palette.primary.main,
    color: "white",
  },
  categoryContainer: {
    padding: 12,
    "@media (max-width:500px)": {
      width: '100%'
    },
    width: 300,
    height: 150,
  },
  categoryName: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 600,
    fontSize: 14,
    textAlign: 'center',
    width: '60%'
  },
  buttons: {
    position: 'absolute',
    display: 'flex',
    top: 6,
    right: 6,
    alignItems: 'center'
  },
  addCategory: {
    maxWidth: 700,
    margin: 'auto',
    marginTop: 24,
    marginBottom: 24
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    '& > button': {
      marginLeft: 12,
      height: 50
    }
  },
  categories: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dialogForm: {
    padding: 12
  },
  submitButtons: {
    padding: 12,
    textAlign: 'end',
    '& > *': {
      marginLeft: 12
    }
  },
  action: {
    position: 'absolute',
    bottom: 6,
    right: 6
  }
})

class BranchDocuments extends Component {
  constructor() {
    super()
    this.state = {
      params: {
        frequency: 2
      },
      openDialog: false,
      editParams: {
        frequency: 2
      },
      subParams: {},
      openWarning: false,
      category: {},
      loading: true,
      openCategory: null,
      isSubcategory: false
    }
    autobind(BranchDocuments, this)
  }

  componentDidMount() {
    const { getSettingsBranchCategory } = this.props
    getSettingsBranchCategory().then(() => this.setState({ loading: false }))
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeEdit(event) {
    const { target } = event
    const { editParams } = this.state
    editParams[target.name] = target.value
    this.setState({ editParams })
  }

  handleOpenCategory(value) {
    return () => {
      this.setState({ openCategory: value })
    }
  }

  handleCloseCategory() {
    this.setState({ openCategory: null })
  }

  handleCreate() {
    const { createSettingsBranchCategory } = this.props
    const { params } = this.state
    const body = {
      ...params,
    }
    this.setState({ params: {} })
    createSettingsBranchCategory(body)
  }

  handleChangeSub(event) {
    const { target } = event
    const { subParams } = this.state
    subParams[target.name] = target.value
    this.setState({ subParams })
  }

  handleCreateSub() {
    const { createSettingsBranchSubCategory } = this.props
    const { subParams, openCategory } = this.state
    const body = {
      ...subParams,
      frequency: 0,
      cut_day: 0,
      category_id: openCategory.id
    }
    this.setState({ subParams: {} })
    createSettingsBranchSubCategory(body)
  }

  handleOpenDialog() {
    this.setState({ openDialog: !this.state.openDialog })
  }

  handleEdit(category, sub = false) {
    return () => {
      const { openCategory } = this.state
      this.setState({
        isSubcategory: sub,
        openDialog: true,
        editParams: {
          ...this.state.editParams,
          name: category.name,
          cut_day: category.cut_day,
          frequency: category.frequency,
          id: category.id,
          category_id: openCategory?.id
        }
      })
    }
  }

  handleSaveEdit() {
    const { editSettingsBranchCategory, editSettingsUserSubCategory } = this.props
    const { editParams, isSubcategory } = this.state
    const body = {
      ...editParams,
    }
    if (isSubcategory) {
      editSettingsUserSubCategory(body)
    } else {
      editSettingsBranchCategory(body)
    }
    this.setState({ openDialog: false })
  }

  handleOpenWarning() {
    this.setState({ openWarning: !this.state.openWarning })
  }

  handleDelete(category, sub = false) {
    return () => {
      this.setState({ openWarning: true, category, isSubcategory: sub })
    }
  }

  handleSendDelete() {
    const { deleteSettingsBranchCategory, deleteSettingsBranchSubCategory } = this.props
    const { category, isSubcategory, openCategory } = this.state
    const body = {
      id: category.id,
      category_id: openCategory?.id
    }
    if (isSubcategory) {
      deleteSettingsBranchSubCategory(body)
    } else {
      deleteSettingsBranchCategory(body)
    }
    this.setState({ openWarning: false })
  }

  renderCategories() {
    const { settings, classes } = this.props
    const { openCategory, subParams } = this.state
    const allCategories = settings.branch_documents.all || []
    const subCategories = allCategories.find(category => category.id === openCategory?.id)?.subcategories || []
    const allSubcategories = subCategories.map(category => {
      return (
        <div className={classes.categoryContainer} key={category.id}>
          <div className={classes.category}>
            <Typography variant="h3" className={classes.categoryName}>{category.name}</Typography>
            <div className={classes.buttons}>
              <IconButton size="small" onClick={this.handleEdit(category, true)}>
                <Edit />
              </IconButton>
              <IconButton size="small" onClick={this.handleDelete(category, true)}>
                <Delete />
              </IconButton>
            </div>
          </div>
        </div>
      )
    })
    if (openCategory) {
      return (
        <>
          <div>
            <IconButton onClick={this.handleCloseCategory}>
              <KeyboardArrowLeft />
            </IconButton>
          </div>
          <Paper className={classes.addCategory} square>
            <Typography className={classes.subtitle} variant="h2">Agregar Sub-Carpeta</Typography>
            <div className={classes.form}>
              <TextInput label="Nombre Sub-Carpeta" value={subParams.name} name="name" onChange={this.handleChangeSub} />
              <Button variant="outlined" color="primary" onClick={this.handleCreateSub} disabled={!subParams.name}>
                <Add />
              </Button>
            </div>
          </Paper>
          <div className={classes.categories}>
            {allSubcategories.length > 0 ? allSubcategories : <Typography variant="subtitle1">No hay sub-carpetas en este directorio</Typography>}
          </div>
        </>
      )
    } else {
      return (
        <div className={classes.categories}>
          {
            allCategories.map(category => {
              return (
                <div className={classes.categoryContainer} key={category.id}>
                  <div className={classes.category}>
                    <Typography variant="h3" className={classes.categoryName}>{category.name}</Typography>
                    <div className={classes.buttons}>
                      <IconButton size="small" onClick={this.handleEdit(category)}>
                        <Edit />
                      </IconButton>
                      <IconButton size="small" onClick={this.handleDelete(category)}>
                        <Delete />
                      </IconButton>
                    </div>
                    <div className={classes.action}>
                      <IconButton size="small" onClick={this.handleOpenCategory(category)}>
                        <ExitToApp />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>)
    }
  }

  render() {
    const { classes } = this.props
    const { params, openDialog, editParams, openWarning, category, loading, openCategory } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h1">Carpetas de documentos de centros de trabajo</Typography>

        <Paper className={classes.addCategory} square>
          <Typography className={classes.subtitle} variant="h2">Agregar Carpeta</Typography>
          <div className={classes.form}>
            <TextInput label="Nombre Carpeta" value={params.name} name="name" onChange={this.handleChange} />
          </div>
          <div className={classes.form}>
            <SelectInput label="Frecuencia" value={params.frequency} name="frequency" onChange={this.handleChange}
              options={[
                { value: 2, label: "Sin frecuencia" },
                { value: 1, label: "Solo una vez" },
                { value: 30, label: "Mensual" },
                { value: 365, label: "Anual" }
              ]}
            />
          </div>
          <div className={classes.form}>
            <TextInput
              label={`${params.frequency === 365 ? "Mes" : "Día"} de corte (en número)`}
              value={params.cut_day}
              disabled={!params.frequency || params.frequency < 30}
              name="cut_day"
              onChange={this.handleChange}
              type="number"
            />
            <Button variant="outlined" color="primary" onClick={this.handleCreate} disabled={!params.name}>
              <Add />
            </Button>
          </div>
        </Paper>

        <div className={classes.card}>
          <Typography className={classes.subtitle} variant="h2">{`Carpetas ${openCategory ? `> ${openCategory.name}` : ""}`}</Typography>
          {loading ?
            <div className={classes.loaderContainer}>
              <LoaderAnimator loading={loading} />
            </div>
            :
            this.renderCategories()
          }
        </div>
        <Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
          <Typography variant="h2" className={classes.subtitle}>Editar Carpeta</Typography>
          <div className={classes.dialogForm}>
            <TextInput label="Nuevo nombre" value={editParams.name} name="name" onChange={this.handleChangeEdit} />
            <SelectInput label="Frecuencia" value={editParams.frequency} name="frequency" onChange={this.handleChangeEdit}
              options={[
                { value: 2, label: "Sin frecuencia" },
                { value: 1, label: "Solo una vez" },
                { value: 30, label: "Mensual" },
                { value: 365, label: "Anual" }
              ]} />
            <TextInput
              label={`${editParams.frequency === 365 ? "Mes" : "Día"} de corte (en número)`}
              value={editParams.cut_day}
              disabled={!editParams.frequency || editParams.frequency < 30}
              name="cut_day"
              onChange={this.handleChangeEdit}
              type="number"
            />
            <SubmitButton onClick={this.handleSaveEdit}>
              Guardar
            </SubmitButton>
          </div>
        </Dialog>
        <Dialog open={openWarning} onClose={this.handleOpenWarning} fullWidth maxWidth="sm">
          <Typography variant="h2" className={classes.subtitle}>Eliminar Carpeta</Typography>
          <div className={classes.dialogForm}>
            <Typography variant="subtitle1">¿Seguro que desea eliminar este elemento?</Typography>
            <Typography variant="subtitle1">Carpeta: {category.name}</Typography>
            <div className={classes.submitButtons}>
              <Button color="primary" variant="outlined" size="small" onClick={this.handleOpenWarning}>
                Cancelar
              </Button>
              <Button color="primary" variant="contained" size="small" onClick={this.handleSendDelete}>
                Eliminar
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getSettingsBranchCategory: () => dispatch(getSettingsBranchCategoryAction()),
  createSettingsBranchCategory: body => dispatch(createSettingsBranchCategoryAction(body)),
  editSettingsBranchCategory: body => dispatch(editSettingsBranchCategoryAction(body)),
  deleteSettingsBranchCategory: body => dispatch(deleteSettingsBranchCategoryAction(body)),
  createSettingsBranchSubCategory: body => dispatch(createSettingsBranchSubCategoryAction(body)),
  editSettingsUserSubCategory: body => dispatch(editSettingsBranchSubCategoryAction(body)),
  deleteSettingsBranchSubCategory: body => dispatch(deleteSettingsBranchSubCategoryAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(BranchDocuments))