import React, { Component } from 'react'
import { withStyles, Dialog, Typography, Button, Divider, Collapse } from '@material-ui/core'
import { connect } from 'react-redux'
import { getWorkersAction } from '../../Actions/EnterpriseAction'
import autobind from '../../Utils/autobind'
import MySignaturePad from '../Talks/MySignaturePad'
import { addUserSignAction } from '../../Actions/WorkerActions'
import moment from 'moment'
import ImageContainer from '../../Shared/ImageContainer'
import TextInput from '../../Shared/Inputs/TextInput'

const style = () => ({
  container: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  centerButton: {
    textAlign: 'end'
  },
  sign: {
    width: 250,
    height: 150,
    padding: 12,
    border: "solid 1px",
    borderRadius: 24,
    "@media (max-width:500px)": {
      width: '100%',
      boxSizing: 'border-box'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
})

class SignRegisters extends Component {
  constructor() {
    super()
    this.state = {
      openNewSign: false,
      reason: ""
    }
    autobind(SignRegisters, this)
  }

  componentDidMount() {
    const { getWorkers } = this.props
    getWorkers()
  }

  handleOpenNewSign() {
    this.setState({ openNewSign: !this.state.openNewSign })
  }

  handleSave(value) {
    fetch(value)
      .then(res => res.blob())
      .then(blob => {
        const { addUserSign, getWorkers, user } = this.props
        const file = new File([blob], "File name", { type: "image/png" })
        const data = new FormData()
        data.append("file", file)
        data.append("user_id", user.account.user.id)
        data.append("date", moment(new Date()).format("YYYY-MM-DD"))
        data.append("name", user.account.user.name)
        addUserSign(data).then(() => {
          getWorkers()
          this.setState({ openNewSign: false })
        })
      })
  }

  handleSign() {
    const { signRegisters, onClose } = this.props
    signRegisters()
    onClose()
  }

  render() {
    const { openNewSign } = this.state
    const { classes, open, onClose, user, workers, changeReason, reason } = this.props
    const allWorkers = workers.all || []
    const selectedWorker = allWorkers.find(worker => worker.id === user.account.user.id)
    const signs = selectedWorker?.signs || []
    const sign = signs[signs.length - 1]
    const url = !!sign?.sign ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign?.sign}` : false
    const hasSigns = signs.length > 0
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h1">Firma de registros y formularios</Typography>
          <Typography variant="subtitle1">¿Firmar usando firma registrada en perfil?</Typography>
          {!hasSigns && <Typography variant="caption">No tienes firmas actualmente registradas en tu perfil</Typography>}
          {hasSigns && <div className={classes.sign} >
            <ImageContainer src={url || '/noimage.png'} width="140px" />
          </div>
          }
          <TextInput label="Motivo" name="reason" value={reason} onChange={changeReason} />
          <div className={classes.centerButton}>
            <Button color="secondary" variant="contained" disabled={!hasSigns} onClick={this.handleSign}>
              Firmar
            </Button>
          </div>
          <Divider />
          <Collapse in={openNewSign}>
            <MySignaturePad
              clearButton="true"
              save={this.handleSave}
            />
          </Collapse>
          <div className={classes.centerButton}>
            <Button color="secondary" variant="outlined" onClick={this.handleOpenNewSign}>
              {openNewSign ? "Cancelar" : "Registrar nueva firma"}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  workers: state.workers
})

const mapDispatchToProps = dispatch => ({
  getWorkers: () => dispatch(getWorkersAction()),
  addUserSign: body => dispatch(addUserSignAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SignRegisters))