import React, { Component } from "react"
import {
	withStyles,
	Paper,
	Tab,
	Typography,
	Divider,
} from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
// import Panel from "../Activities/Panel"
import AddElementButton from "../../Shared/AddElementButton"
import { createMultipleTalksAction, createTalkAction, getTalkAction, getTalksAction } from "../../Actions/TalkActions"
import { connect } from "react-redux"
import { AssignmentTurnedInOutlined, Delete, ExitToApp } from "@material-ui/icons"
import moment from 'moment'
import { getSettingsTalksAction, getTalksCategoriesAction } from "../../Actions/SettingsActions"
import GeneralForm from "../../Shared/GeneralForm"
import FormContext from "../../Shared/Cards/Provider"
import { addQrToFile, mergeMultiplePdfFilesAndGet, transformToOptions } from "../../Utils/functions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import { filterActiveWorkers } from "../../Utils/filters"
import SignedCell from "../../Shared/TableRenders/SignedCell"
import { openGlobalDialog } from "../../Actions/GlobalActions"
import { deleteTalk, editTalkParticipants } from "../../API/talks"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import LoadingDialog from "../../Shared/LoadingDialog"
import DownloadButton from "../../Shared/DownloadButton"
import TalksList from "./TalksList"

const style = () => ({
	container: {
		margin: 12,
	},
	title: {
		margin: "36px 0 12px 0 ",
	},
	divider: {
		marginBottom: 24,
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 24,
	},
	inline: {
		display: "flex",
		alignItems: "center",
		gap: "12px",
	},
})

const tableInfo = [
	{ name: "Fecha Programación", label: "date_scheduled", format: (value) => moment(value).format("YYYY-MM-DD") },
	{ name: "Tipo", label: "category_name" },
	{ name: "Tema", label: "name" },
	{ name: "Responsable", label: "user_name" },
	{ name: AssignmentTurnedInOutlined, label: "all", render: SignedCell },
]

const doneTableInfo = [
	{ name: "Fecha Realizado", label: "date_saved", format: (value) => moment(value).format("YYYY-MM-DD") },
	{ name: "Tipo", label: "category_name" },
	{ name: "Tema", label: "name" },
	{ name: "Responsable", label: "user_name" },
	{ name: "Participantes", label: "participants_signs", format: (value) => value.length }
]

function checkIfFinished(element) {
	return moment(element.date_saved).format("YYYY-MM-DD").includes("Fecha")
}

function checkIfNotFinished(element) {
	return !moment(element.date_saved).format("YYYY-MM-DD").includes("Fecha")
}

class Talks extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tab: 0,
			params: {
				date_scheduled: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
			},
			openForm: true,
			loading: false,
			loadingParticipants: false
		}
		this.listener = null
		autobind(Talks, this)
	}

	componentDidMount() {
		window.addEventListener("branch_change", this.handleReload)
		this.handleReload()
	}

	componentWillUnmount() {
		window.removeEventListener("branch_change", this.handleReload)
	}

	async handleReload() {
		const { getTalks, getSettingsTalks, getWorkers, getTalkCategories } = this.props
		getWorkers()
		getTalkCategories()
		this.setState({ loading: true })
		await getTalks()
		await getSettingsTalks()
		this.setState({ loading: false })
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value

		// Select supervised when responsible is selected
		if (target.name === "user_id") {
			const filteredWorkers = filterActiveWorkers()
			const supervised = filteredWorkers.filter(worker => String(worker.user.id) === target.value)
			const supervisedIds = supervised.map(user => user.id)
			params.participant_ids = supervisedIds
		}
		this.setState({ params })
	}

	handleGoto(row) {
		return () => {
			const { history, selectTalk } = this.props
			selectTalk(row.id)
			history.push(`/talks/${row.id}`)
		}
	}

	handleOpen() {
		this.setState({ openForm: !this.state.openForm })
	}

	handleDelete(row) {
		return () => {
			const { openGlobalDialog } = this.props
			openGlobalDialog(deleteTalk, { id: row.id }, {
				callback: this.handleReload,
				message: "Seguro que quieres eliminar esta charla",
				title: "Eliminar Charla",
				submit: "Eliminar"
			})
		}
	}

	async handleCreate() {
		const { params } = this.state
		const { createTalks } = this.props
		const body = { ...params }
		const participants = [...params.participant_ids]
		const response = await createTalks(body)
		const createdTalks = [...response.payload.data.info]

		// Setting participants
		this.setState({ loadingParticipants: true })
		await Promise.all(createdTalks.map(async (talk) => {
			const body = { id: talk.id, participants }
			await editTalkParticipants(body)
		}))
		this.setState({ loadingParticipants: false })

		this.setState({
			openForm: false, params: {
				date_scheduled: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
			},
		})
	}

	handleDownload(row, params) {
		const { user } = this.props
		return async () => {
			const file = row.file
			const url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_PDF}?id=${row.id}&u=${user.account.user.id}`
			const parsed_url = new URL(url1)
			if (params) {
				Object.keys(params).forEach(key => {
					parsed_url.searchParams.append(key, params[key])
				})
			}
			console.log(parsed_url.toString())
			const final_url = parsed_url.toString()
			const url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file
			if (file.includes(".pdf")) {
				const signedFile = await mergeMultiplePdfFilesAndGet([final_url, url2], "Reporte Charla")
				const code = `${row.id}--k`
				const validateUrl = `${window.location.origin}/validate/${code}`
				return addQrToFile(signedFile, "Reporte Charla", validateUrl)
			} else {
				const signedFile = await mergeMultiplePdfFilesAndGet([final_url], "Reporte Charla")
				const code = `${row.id}--k`
				const validateUrl = `${window.location.origin}/validate/${code}`
				return addQrToFile(signedFile, `${row.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}`, validateUrl)
			}
		}
	}

	render() {
		const { classes, talks, settings, user } = this.props
		const { tab, params, openForm, loading, loadingParticipants } = this.state
		const allTalks = talks.all || []
		const allSettingsTalks = settings.talks.all || []
		const allCategories = settings.talks.categories || []
		const filterTalks = allSettingsTalks.filter(talk => talk.category_id.toString() === params.category_id)
		const pendingTalks = allTalks.filter(talk => moment(talk.date_saved).format("YYYY-MM-DD").includes("Fecha"))
		const doneTalks = allTalks.filter(talk => !moment(talk.date_saved).format("YYYY-MM-DD").includes("Fecha"))
		let actions = [
			{ name: "Ver Charla", icon: ExitToApp, action: this.handleGoto },
			{ name: "Descargar Resumen", component: DownloadButton, action: this.handleDownload, color: "primary", disabled: checkIfFinished },
		]

		const formInfo = [
			{ name: "user_id", label: "Responsable", type: "users" },
			{ name: "participant_ids", label: "Participantes", type: "users", options: "multiple" },
			{ name: "category_id", label: "Categoría", type: "select", options: transformToOptions(allCategories) },
			{ name: "documents_ids", label: "Charla", type: "multiselect", options: transformToOptions(filterTalks) },
			{ name: "date_scheduled", label: "Fecha de realización", type: "datetime" }
		]

		const level = user.account.user.userType

		if (level === 1) {
			actions = [
				{ name: "Eliminar Charla", icon: Delete, action: this.handleDelete, disabled: checkIfNotFinished },
				{ name: "Ver Charla", icon: ExitToApp, action: this.handleGoto },
				{ name: "Descargar Resumen", component: DownloadButton, action: this.handleDownload, color: "primary", disabled: checkIfFinished },
			]
		}

		if (loading) return <LoaderAnimator />

		return (
			<div className={classes.container}>
				<TalksList />
				<LoadingDialog open={loadingParticipants} loadingMessage="Invitando participantes..." />
				<div className={classes.header}>
					<Typography variant="h1">Charlas</Typography>
					{level !== 3 && <AddElementButton name="Agregar Charla" onClick={this.handleOpen} open={openForm} />}
				</div>
				<FormContext.Provider value={{ handleChange: this.handleChange, params }}>
					<GeneralForm open={openForm} title="Crear Charla" submit="Crear" info={formInfo} onSubmit={this.handleCreate} />
				</FormContext.Provider>
				{/* <InviteUserOnCreate /> */}
				<Divider className={classes.divider} />
				<Paper square>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h1">
									Programadas
								</Typography>
							}
							value={0}
						/>
						<Tab
							label={
								<Typography variant="h1">Realizadas</Typography>
							}
							value={1}
						/>
					</MyTabs>
				</Paper>
				<TabPanel value={tab} index={0}>
					<Typography variant="h1" className={classes.title}>
						Charlas Programadas
					</Typography>
					<Divider className={classes.divider} />
					<GeneralTable info={tableInfo} data={pendingTalks} actions={actions} />
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Typography variant="h1" className={classes.title}>
						Charlas Realizadas
					</Typography>
					<Divider className={classes.divider} />
					<GeneralTable info={doneTableInfo} data={doneTalks} actions={actions} />
				</TabPanel>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	talks: state.talks,
	settings: state.settings,
	workers: state.workers,
	user: state.user,
	branch: state.branch
})

const mapDispatchToProps = dispatch => ({
	getTalks: () => dispatch(getTalksAction()),
	createTalk: body => dispatch(createTalkAction(body)),
	createTalks: body => dispatch(createMultipleTalksAction(body)),
	getSettingsTalks: () => dispatch(getSettingsTalksAction()),
	getTalkCategories: () => dispatch(getTalksCategoriesAction()),
	selectTalk: id => dispatch(getTalkAction(id)),
	getWorkers: () => dispatch(getWorkersAction()),
	openGlobalDialog: (action, body, options) => dispatch(openGlobalDialog(action, body, options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Talks))
