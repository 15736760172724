import { Box, Button, Collapse, Dialog, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { getBaseFindingActions, getBaseFindingMeasures, getBaseFindingTimings, getFindingType } from '../../../API/findings&nc'
import { uploadFile } from '../../../API/users'
import DateTimeInput from '../../../Shared/Inputs/DateTimeInput'
import ImgInput from '../../../Shared/Inputs/ImgInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import UsersInput from '../../../Shared/Inputs/UsersInput'
import { dialog_style, end_buttons, end_flex_buttons, text_space } from '../../../Utils/defaultStyles'
import { getBase64Image, transformToOptions } from '../../../Utils/functions'
import { callSnackbar } from '../../../Utils/snackbar'

const css = {
	dialog: {
		margin: '24px 0'
	}
}

function transformToOptionsByContent(options) {
	return options.map(op => ({ ...op, label: op.content, value: op.id }))
}

function FindingForm({ open, onClose, onCreateFinding }) {

	const [params, setParams] = useState({ date_limit: moment().format('YYYY-MM-DD HH:mm:ss'), images: [] })
	const [findingTypes, setTypes] = useState([])
	const [baseActions, setActions] = useState([])
	const [baseMeasures, setMeasures] = useState([])
	const [baseTimings, setTimings] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getFindingType()
			setTypes(response.data.info)
		}

		fetchData()
	}, [])

	useEffect(() => {
		if (!!params.finding_type_id) {
			setParams(p => ({ ...p, date_limit: moment().format("YYYY-MM-DD HH:mm:ss"), finding_type_id: p.finding_type_id }))
			async function fetchDefaults() {
				const body = { type: params.finding_type_id }
				const responses = await Promise.all([
					getBaseFindingActions(body),
					getBaseFindingMeasures(body),
					getBaseFindingTimings(body),
				])
				const datas = responses.map(r => r.data.info)
				setActions(datas[0])
				setMeasures(datas[1])
				setTimings(datas[2])
			}

			fetchDefaults()
		}
	}, [params.finding_type_id])

	useEffect(() => {
		if (!!params.base_finding_action_id) {
			const selectedBaseAction = baseActions.find(ba => ba.id === params.base_finding_action_id)
			const selectedTiming = baseTimings.find(t => t.default_criticality === selectedBaseAction?.default_criticality)
			setParams(p => ({ ...p, free_criticality: selectedBaseAction?.default_criticality, free_description: selectedBaseAction?.content, date_limit: moment().add(selectedTiming?.offset_hours || 0, "hours").format("YYYY-MM-DD HH:mm:ss") }))
		}
	}, [params.base_finding_action_id, baseActions, baseTimings])

	useEffect(() => {
		if (!!params.base_finding_measure_id) {
			const selectedBaseMeasure = baseMeasures.find(ba => ba.id === params.base_finding_measure_id)
			setParams(p => ({ ...p, inmediate_action: selectedBaseMeasure.content }))
		}
	}, [params.base_finding_measure_id, baseMeasures])

	useEffect(() => {
		if (!!params.date_limit) {
			const selectedBaseAction = baseActions.find(ba => ba.id === params.base_finding_action_id)
			const selectedTiming = baseTimings.find(t => t.default_criticality === selectedBaseAction?.default_criticality)
			const offset_hours = selectedTiming?.offset_hours || null
			if (!!offset_hours && moment(params.date_limit).diff(moment(), 'hours') > offset_hours) {
				callSnackbar(`La fecha limite no puede ser mayor a ${offset_hours} horas desde el momento de creación`, "warning")
				setParams(p => ({ ...p, date_limit: moment().add(offset_hours, "hours").format("YYYY-MM-DD HH:mm:ss") }))
			}
		}

	}, [params.date_limit, baseActions, baseTimings])

	function handleChange(event) {
		const { target } = event
		setParams(p => ({ ...p, [target.name]: target.value }))
	}

	async function handleChangeImage(event) {
		const { target } = event
		const file = target.value
		const new_images = [...params.images] || []
		const base64 = await getBase64Image(file)
		new_images.push(base64)
		setParams(p => ({ ...p, images: new_images }))
	}

	async function handleCreate() {
		const body = { ...params }
		onCreateFinding(body)
		setParams({ date_limit: moment().format('YYYY-MM-DD HH:mm:ss'), images: [] })
		onClose()
	}

	function validateForm() {
		const to_validate = ["free_description", "description", "user_id", "date_limit", "free_criticality"]
		const isValid = to_validate.map(key => !!params[key])
		return isValid.every(Boolean)
	}

	function onDeleteImage(name, index) {
		const new_images = [...params.images]
		new_images.splice(index, 1)
		setParams(p => ({ ...p, images: new_images }))
	}

	const crits = [
		{ value: 0, label: "No Asignado" },
		{ value: 1, label: 'No Critico' },
		{ value: 2, label: "Poco Critico" },
		{ value: 3, label: "Medianamente Critico" },
		{ value: 4, label: "Altamente Critico" }
	]

	const selectedBaseAction = params.base_finding_action_id ? baseActions.find(ba => ba.id === params.base_finding_action_id) : null
	const filteredMeasures = selectedBaseAction ? baseMeasures.filter(bm => bm.default_criticality === selectedBaseAction.default_criticality) : []

	return (
		<Collapse in={open}>
			<Box sx={css.dialog}>
				<Typography variant='h1' sx={text_space} >Creando Hallazgo</Typography>
				<SelectInput options={transformToOptions(findingTypes)} value={params.finding_type_id} label="Seleccione tipo de hallazgo" onChange={handleChange} name="finding_type_id" />
				<SelectInput options={transformToOptionsByContent(baseActions)} value={params.base_finding_action_id} label="Descripciones predefinidas" onChange={handleChange} name="base_finding_action_id" />
				<TextInput value={params.free_description} label="Descripción" name="free_description" onChange={handleChange} />
				<SelectInput options={crits} disabled={!!selectedBaseAction?.default_criticality} value={selectedBaseAction?.default_criticality || params.free_criticality} label="Criticidad" name="free_criticality" onChange={handleChange} />
				<SelectInput options={transformToOptionsByContent(filteredMeasures)} value={params.base_finding_measure_id} label="Medidas de control predefinidas" onChange={handleChange} name="base_finding_measure_id" />
				<TextInput value={params.description} label="Acción inmediata" name="description" onChange={handleChange} />
				<DateTimeInput value={params.date_limit} label="Fecha Limite" name="date_limit" onChange={handleChange} />
				<UsersInput value={params.user_id} label="Responsable" name="user_id" onChange={handleChange} />
				<ImgInput
					value={params.images.join("&#&")}
					label="Evidencia"
					name="free_file"
					onChange={handleChangeImage}
					onDelete={onDeleteImage}
					url={``}
				/>
				<Box sx={end_flex_buttons} style={{ marginTop: 24 }} >
					<Button onClick={onClose}>
						Cancelar
					</Button>
					<Button color="primary" variant="contained" onClick={handleCreate} disabled={!validateForm()}>
						Crear Hallazgo
					</Button>
				</Box>
			</Box>
		</Collapse>
	)
}

export default FindingForm