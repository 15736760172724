import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const toggleActiveBranch = body => {
  return instance({
    method: 'post',
    url: 'branches/active.php',
    data: body
  })
}

export const toggleOverwriteBranch = body => {
  return instance({
    method: 'post',
    url: 'branches/overwrite.php',
    data: body
  })
}

export const changeBranchLogo = body => {
  return instance({
    method: 'post',
    url: 'branches/logo.php',
    data: body
  })
}

export const getBranches = body => {
  return instance2({
    method: 'get',
    url: 'branches/get.php',
    params: body
  })
}

export const getSettingsBranchDocuments = () => {
  return instance2({
    method: 'get',
    url: 'settings/branch_documents/get_category.php'
  })
}

export const getBranchDocuments = body => {
  return instance2({
    method: 'get',
    url: 'branches/get_documents.php',
    params: body
  })
}

export const editBranchDocument = body => {
  return instance({
    method: 'post',
    url: "branches/edit_document.php",
    data: body
  })
}

export const deleteBranchDocument = body => {
  return instance({
    method: 'post',
    url: "branches/delete_document.php",
    data: body
  })
}

export const signDocument = body => {
  return instance({
    method: 'post',
    url: 'branches/user_document_sign.php',
    data: body
  })
}

export const createDocument = body => {
  return instance({
    method: 'post',
    url: 'branches/create_document.php',
    data: body
  })
}

export const editDocumentParticipants = body => {
  return instance({
    method: 'post',
    url: 'branches/add_document_participants.php',
    data: body
  })
}

export const getBranchTags = body => {
  return instance2({
    method: 'get',
    url: 'branches/tags',
    params: body
  })
}

export const createBranchTag = body => {
  return instance2({
    method: 'post',
    url: 'branches/tags',
    data: body
  })
}

export const editBranchTag = body => {
  return instance2({
    method: 'put',
    url: 'branches/tags',
    data: body
  })
}

export const deleteBranchTag = body => {
  return instance2({
    method: 'delete',
    url: 'branches/tags',
    data: body
  })
}

export const assignTag = body => {
  return instance2({
    method: 'post',
    url: 'branches/assign',
    data: body
  })
}

export const getSubBranches = body => {
  return instance2({
    method: 'get',
    url: 'subbranches/get.php',
    params: body
  })
}

export const createSubBranch = body => {
  return instance2({
    method: 'post',
    url: 'subbranches/create/form',
    data: body
  })
}