import React, { Component } from 'react'
import { Button, Collapse, Dialog, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../Shared/Inputs/TextInput'
import { AddCircleOutline, Edit, KeyboardArrowRight, LocalOfferOutlined } from '@material-ui/icons'
import GeneralTable from '../../../Shared/GeneralTable'
import { createSettingChecklistAction, editSettingChecklistAction } from '../../../Actions/SettingsActions'
import { connect } from 'react-redux'
import autobind, { addToggle } from '../../../Utils/autobind'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import SubmitButton from '../../../Shared/SubmitButton'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import MyUploadAdapter from '../../Talks/MyUploader'
import TagsDialog from './TagsDialog'
import { addTag, createTag, deleteTag, editTag, getTags, removeTag } from '../../../API/tags'
import TagContainer from './TagContainer'
import TagCell from '../../../Shared/TableRenders/TagCell'
import { getSettingsChecklist } from '../../../API/checklists'
import NewTag from './NewTag'
import SelectedFilters from './SelectedFilters'

const style = theme => ({
  container: {
    padding: 12,
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
    borderRadius: 15,
    padding: 24
  },
  cardContent: {
    margin: '12px 0'
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    marginRight: 24,
    width: '100%'
  },
  title: {
    marginBottom: 24
  },
  table: {
    background: theme.palette.blue.main,
    padding: 12,
    "& > *": {
      color: 'white'
    },
    marginTop: 36,
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dialogInput: {
  },
  dialog: {
    padding: 24
  },
  editButton: {
    padding: 12,
    textAlign: 'end'
  },
  dialogTitle: {
    marginBottom: 24
  },
})

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

class ChecklistSettings extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      openDialog: false,
      openCreate: false,
      selected: null,
      selectedChecklist: null,
      loading: true,
      checklists: [],
      tags: [],
      filters: []
    }
    addToggle(ChecklistSettings, this, "tagconfig")
    autobind(ChecklistSettings, this)
  }

  async componentDidMount() {
    const filters = JSON.parse(localStorage.getItem("filters")) || []
    const checklistResponse = await getSettingsChecklist()
    const { data: { info: checklists } } = checklistResponse
    this.setState({ loading: false, checklists, filters })
    const response = await getTags()
    const { data: { info: tags } } = response
    this.setState({ tags })
  }

  async handleSelectTag(tag) {
    const { selectedChecklist } = this.state
    const body = {
      tag_id: tag,
      preventive_measure_ids: [selectedChecklist.id]
    }
    const response = await addTag(body)
    const { data: { info: checklists } } = response
    this.setState({ checklists })
  }

  async handleEditTag(body) {
    await editTag(body)
    const response = await getTags()
    const { data: { info: tags } } = response
    this.setState({ tags })
  }

  handleDeleteTag(body) {
    return async () => {
      await deleteTag(body)
      const response = await getTags()
      const { data: { info: tags } } = response
      this.setState({ tags })
    }
  }

  handleRemoveTag(tag) {
    return async () => {
      const { selectedChecklist } = this.state
      const body = {
        tag_id: tag,
        preventive_measure_ids: [selectedChecklist.id]
      }
      const response = await removeTag(body)
      const { data: { info: checklists } } = response
      this.setState({ checklists })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeContent(edit = false) {
    return (event, editor) => {
      const data = editor.getData()
      const { params } = this.state
      if (!edit) {
        params.description = data
      } else {
        params.newDescription = data
      }
      this.setState({ params })
    }
  }

  handleGoto(row) {
    return () => {
      const { history } = this.props
      history.push(`/settings/checklists/${row.id}`)
    }
  }

  handleEdit(row) {
    return () => {
      this.setState({
        openDialog: true, selected: row,
        params: {
          ...this.state.params,
          newName: row.name,
          newDescription: row.description,
          newCode: row.code,
          newRevision: row.revision
        }
      })
    }
  }

  handleCloseDialog() {
    this.setState({ openDialog: false })
  }

  handleCreate() {
    const { params } = this.state
    const { createSettingsChecklist } = this.props
    const body = { ...params }
    this.setState({ params: { description: "" } })
    createSettingsChecklist(body)
  }

  handleOpenTags(row) {
    return () => {
      this.setState({ selectedChecklist: row, openTags: !!row })
    }
  }

  async handleCreateTag(body) {
    const response = await createTag(body)
    const { tags } = this.state
    const { data: { info: tag } } = response
    tags.push(tag)
    this.setState({ tags })
  }

  handleSendEdit() {
    const { params, selected } = this.state
    const { editSettingsChecklist } = this.props
    const body = {
      name: params.newName,
      code: params.newCode,
      revision: params.newRevision,
      description: params.newDescription,
      quick_access: selected.quick_access,
      id: selected.id
    }
    editSettingsChecklist(body)
    this.setState({ openDialog: false })
  }

  handleOpenCreate() {
    this.setState({ openCreate: !this.state.openCreate })
  }

  handleSetFilter(value) {
    return () => {
      const { filters } = this.state
      if (filters.includes(value)) {
        filters.splice(filters.indexOf(value), 1)
      } else {
        filters.push(value)
      }
      localStorage.setItem("filters", JSON.stringify(filters))
      this.setState({ filters })
    }
  }

  render() {
    const { classes } = this.props
    const { params, openDialog, loading, openCreate, selectedChecklist, openTags, tags, openTagconfig, checklists, filters } = this.state
    const allChecklists = checklists
      .filter(checklist => !filters.length || checklist.tags.some(tag_id => filters.includes(tag_id)))
    const actions = [
      { name: "Etiquetas del registro", icon: LocalOfferOutlined, action: this.handleOpenTags, color: "primary" },
      { name: "Editar Registro o formulario", icon: Edit, action: this.handleEdit, color: "primary" },
      { name: "Ver Registro o formulario", icon: KeyboardArrowRight, action: this.handleGoto, color: "primary" },
    ]

    const tableInfo = [
      { name: "Nombre", label: "name" },
      { name: "Etiquetas", label: "tags", format: (value => value.map(id => tags.find(t => t.id === id))), render: TagCell }
    ]



    return (
      <div className={classes.container}>
        <Typography variant="h1" className={classes.title}>Configuración Registros y formularios</Typography>
        <Paper className={classes.card} square>
          <div className={classes.cardTitle}>
            <Typography variant="h1">Crear Registro o formulario</Typography>
            <IconButton onClick={this.handleOpenCreate}>
              <AddCircleOutline />
            </IconButton>
          </div>
          <Collapse in={openCreate}>
            <div className={classes.cardContent}>
              <div className={classes.input} >
                <TextInput label="Nombre" placeholder="Ingresar Registro o formulario" name="name" value={params.name} onChange={this.handleChange} />
              </div>
              <div className={classes.input} >
                <div className={classes.rte}>
                  <CKEditor
                    editor={Editor}
                    data={params.description}
                    onChange={this.handleChangeContent(false)}
                    config={{
                      // fontSize: {
                      //   supportAllValues: true
                      // },
                      toolbar: ['bold', 'italic', '|',
                        "Undo",
                        "Redo",
                        "imageUpload",
                        "insertTable",
                        "link",
                        '|',
                        "alignment:left",
                        "alignment:right",
                        "alignment:center",
                        "alignment:justify",
                        '|',
                        'fontColor',
                        'fontBackgroundColor',
                        'fontSize'
                      ],
                      language: 'es',
                      extraPlugins: [MyCustomUploadAdapterPlugin]
                    }}
                    onReady={(editor) => {
                      if (!editor) return null
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "min-height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        )
                      })
                    }}
                  />
                </div>
              </div>
              <div className={classes.input} >
                <TextInput label="Código" name="code" value={params.code} onChange={this.handleChange} />
              </div>
              <div className={classes.input} >
                <TextInput label="Revisión" name="revision" value={params.revision} onChange={this.handleChange} />
              </div>
              <SubmitButton onClick={this.handleCreate}>
                Crear Registro o formulario
              </SubmitButton>
            </div>
          </Collapse>
        </Paper>
        <Paper className={classes.table}>
          <Typography variant="h1">Registros y formularios</Typography>
          <IconButton onClick={this.handleOpenTagconfig}>
            <LocalOfferOutlined />
          </IconButton>
        </Paper>
        <Collapse in={!openTagconfig}>
          <SelectedFilters filters={filters} tags={tags} removeFilter={this.handleSetFilter} />
        </Collapse>
        <Collapse in={openTagconfig}>
          {tags.map(tag => <TagContainer filters={filters} onFilter={this.handleSetFilter(tag.id)} tag={tag} onEdit={this.handleEditTag} onDelete={this.handleDeleteTag(tag)} />)}
          <NewTag onCreate={this.handleCreateTag} />
        </Collapse>
        <TagsDialog
          open={openTags}
          onClose={this.handleOpenTags(null)}
          element={selectedChecklist}
          tags={tags}
          onSelect={this.handleSelectTag}
          onCreate={this.handleCreateTag}
          onRemove={this.handleRemoveTag}
        />
        {loading ?
          <LoaderAnimator />
          :
          <GeneralTable data={allChecklists} info={tableInfo} actions={actions} />
        }
        <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={this.handleCloseDialog}>
          <div className={classes.dialog}>
            <div className={classes.dialogTitle}>
              <Typography variant="h1">Editar Registro o formulario</Typography>
            </div>
            <div className={classes.dialogInput} >
              <TextInput label="Nuevo Nombre" name="newName" value={params.newName} onChange={this.handleChange} />
            </div>
            <div className={classes.rte}>
              <CKEditor
                editor={Editor}
                data={params.newDescription}
                onChange={this.handleChangeContent(true)}
                config={{
                  // fontSize: {
                  //   supportAllValues: true
                  // },
                  toolbar: ['bold', 'italic', '|',
                    "Undo",
                    "Redo",
                    "imageUpload",
                    "insertTable",
                    "link",
                    '|',
                    "alignment:left",
                    "alignment:right",
                    "alignment:center",
                    "alignment:justify",
                    '|',
                    'fontColor',
                    'fontBackgroundColor',
                    'fontSize'
                  ],
                  language: 'es',
                  extraPlugins: [MyCustomUploadAdapterPlugin]
                }}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "min-height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    )
                  })
                }}
              />
            </div>
            <div className={classes.dialogInput} >
              <TextInput label="Código" name="newCode" value={params.newCode} onChange={this.handleChange} />
            </div>
            <div className={classes.dialogInput} >
              <TextInput label="Revisión" name="newRevision" value={params.newRevision} onChange={this.handleChange} />
            </div>
            <div className={classes.editButton}>
              <Button onClick={this.handleSendEdit} color="primary" variant="contained">Editar</Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  createSettingsChecklist: (body) => dispatch(createSettingChecklistAction(body)),
  editSettingsChecklist: (body) => dispatch(editSettingChecklistAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ChecklistSettings))