import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = () => ({
  element: {
    margin: "4px 0"
  }
})

class MultipleData extends Component {
  renderElements() {
    const { value, classes } = this.props
    return value[0].map(element => {
      return (<Typography className={classes.element} key={element.id} variant="subtitle1">* {element.name}</Typography>)
    })
  }
  render() {
    // const {element, header, value} = this.props
    const { classes, } = this.props
    return (
      <div className={classes.container}>
        {this.renderElements()}
      </div>
    )
  }
}

export default withStyles(style)(MultipleData)