import React, { Component } from 'react'
import { Button, Collapse, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../../Utils/autobind'
import { getPrograms } from '../../../API/programs'
import SingleProgram from './SingleProgram'
import ToggleIcon from '../../../Shared/Icons/ToggleIcon'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

const style = () => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 6
    }
  },
  container: {
    background: 'white',
    margin: '12px 0',
    padding: 12,
    borderRadius: 8,
    position: 'relative'
  },
  insideProgram: {
    marginTop: 24,
    paddingLeft: 12
  },
  button: {
    position: 'absolute',
    right: 6,
    top: 9
  }
})

class SuperProgramCard extends Component {
  constructor() {
    super()
    this.state = {
      program: null,
      openProgram: false
    }
    addToggle(SuperProgramCard, this, "program")
    autobind(SuperProgramCard, this)
  }

  async componentDidMount() {
    const { program_info: { id } } = this.props
    const response = await getPrograms({ id })
    const { data: { info: programs } } = response
    this.setState({ program: programs[0] })
  }

  handleGotoProgram() {
    const { program } = this.state
    const { history } = this.props
    history.push(`/programs/view/${program.id}`)
  }

  handleGetCompliance(values, element) {
    const { date_end, date_start } = this.props
    const days = moment(date_end).diff(moment(date_start), "days") + 1

    const validRegisters = values
      .filter(value => !!value.date_done)
      .length

    const expectedAmount = element.frequency !== "0" ? Math.floor(days / parseInt(element.frequency, 10)) : 1
    return validRegisters < expectedAmount ? 0 : 100
  }

  render() {
    const { classes, date_start, date_end } = this.props
    const { program, openProgram } = this.state

    return (
      <div className={classes.container}>
        <Button color="primary" variant="outlined" size="small" onClick={this.handleGotoProgram} className={classes.button}>
          Ver
        </Button>
        <div onClick={this.handleOpenProgram} className={classes.header}>
          <ToggleIcon open={openProgram} />
          <Typography variant="h4" style={{ color: openProgram ? "#7373e8" : "#7373e87d", transition: 'all 0.2s linear' }} >
            {program?.name}
          </Typography>
        </div>
        <Collapse in={openProgram} mountOnEnter>
          <div className={classes.insideProgram}>
            {!!program && <SingleProgram program={program} isSub={true} date_start={date_start} date_end={date_end} />}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withRouter(withStyles(style)(SuperProgramCard))