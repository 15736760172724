import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import { BarChart } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import ComplianceReport from './ComplianceReport'
import DateIntervalPicker from './DateIntervalPicker'
import BarActivitiesReport from './BarActivitiesReport'
import FindingsDonut from './FindingsDonut'
import instance from '../../../Utils/instance'
import moment from 'moment'
import { withRouter } from 'react-router'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import FrequencySimulator from './FrequencySimulator'

const style = () => ({
  container: {
    padding: 12,
    borderRadius: 15,
    background: 'white',
    margin: '12px 0'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      flexBasis: 500
    },
    '& > *:first-child': {
      flexBasis: 700
    }
  }
})

class ReportMain extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      date_start: moment(new Date()).startOf("week").format("YYYY-MM-DD"),
      date_end: moment(new Date()).endOf("week").format("YYYY-MM-DD"),
      loading: false,
    }
    autobind(ReportMain, this)
  }

  async componentDidMount() {
    const { match } = this.props
    const { date_start, date_end } = this.state
    const body = {
      user_id: match.params.id,
      date_start,
      date_end,
    }
    this.setState({ loading: true })
    const response = await instance({
      method: 'get',
      url: 'checklists/get_report.php',
      params: body
    })
    this.setState({ loading: false })
    if (response.status !== 200) return null
    const { data } = response
    this.setState({ data })
  }

  processInformation() {
    const { data } = this.state
    let totalPending = 0
    let totalOverdue = 0
    let totalDone = 0
    let totalWithFinding = 0
    let totalWithoutFinding = 0
    let totalFindings = 0
    const activities = {}
    data.forEach(register => {
      const { estado, cantidad_hallazgos, nombre_registro, hallazgos, cumplimiento } = register
      if (estado === "Terminado") { totalDone += 1 }
      if (estado === "Vencido") { totalOverdue += 1 }
      if (estado === "Programado") { totalPending += 1 }
      if (hallazgos === "Si") { totalWithFinding += 1 }
      if (hallazgos === "No") { totalWithoutFinding += 1 }
      totalFindings += cantidad_hallazgos
      if (Object.keys(activities).includes(nombre_registro)) {
        activities[nombre_registro].amount += 1
        activities[nombre_registro].cumplimiento += cumplimiento
        if (estado === "Terminado") {
          activities[nombre_registro].finished += 1
        }
      } else {
        activities[nombre_registro] = {
          amount: 1,
          cumplimiento,
          finished: estado === "Terminado" ? 1 : 0
        }
      }
    })
    return { totalPending, totalOverdue, totalDone, totalWithoutFinding, totalWithFinding, totalFindings, activities }
  }

  handleChange(event) {
    const { target } = event
    this.setState({ [target.name]: target.value }, this.componentDidMount)
  }

  render() {
    const { classes } = this.props
    const { date_start, date_end, loading, data } = this.state
    const dates = { date_start, date_end }
    const processedData = this.processInformation()
    return (
      <div className={classes.container}>
        <div className={classes.title} onClick={this.handleOpen}>
          <Typography variant="h4">Reportes</Typography>
          <IconButton size="small">
            <BarChart />
          </IconButton>
        </div>
        <div style={{ minHeight: 300, position: 'relative' }}>
          <DateIntervalPicker onChange={this.handleChange} dates={dates} />
          {loading ? <LoaderAnimator loading /> :
            <>
              <ComplianceReport data={processedData} />
              <div className={classes.inline}>
                <BarActivitiesReport data={processedData} />
                <FindingsDonut data={processedData} />
              </div>
              <FrequencySimulator data={data} dates={dates} />
            </>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(withStyles(style)(ReportMain))