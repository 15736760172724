import { AddAPhotoOutlined, CategoryOutlined, Check, CheckCircle, Clear, Delete, ErrorOutline, InfoOutlined, KeyboardArrowDown, NotificationsActiveOutlined, Settings } from '@material-ui/icons'
import { Box, Button, Checkbox, IconButton, Menu, Paper, Switch, Typography, MenuItem, Chip } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { answerAllCategory, answerChecklistItem } from '../../../API/new_checklists'
import AppContext from '../../../AppContext'
import { RequestManagerContext } from '../../../DatabaseManagers/RequestsManager'
import useToggle from '../../../Hooks/ToogleHook'
import newTheme from '../../../newTheme'
import ImageContainer from '../../../Shared/ImageContainer'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import { filterActiveWorkers } from '../../../Utils/filters'
import { jsonToFormData, resizeImageGetFile } from '../../../Utils/functions'
import { ChecklistContext } from '../ChecklistView'
import Item from './Item'
import NewFinding from './NewFinding'

const css = {
	container: {
		padding: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: 2
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	},
	item: {
		background: 'white',
		borderRadius: 2,
		padding: 2,
		margin: '12px 0'
	},
	option_buttons: {
		display: 'flex',
		flexDirection: 'row',
		gap: 2,
		'@media (max-width:600px)': {
			flexDirection: 'column',
		}
	},
	item_header: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	},
	images: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
		justifyContent: 'flex-end',
		margin: '12px 0'
	},
	imageContainer: {
		borderRadius: 2,
		border: '1px dashed black',
		height: 68,
		width: 68,
		position: 'relative'
	},
	image_button: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	delete_image_button: {
		position: 'absolute',
		left: '65%',
		top: '65%',
		background: 'white',
		opacity: 0.8
	},
	category: {
		margin: '30px 0 6px 0'
	},
	users: {
		background: 'white',
		borderRadius: 2,
		position: 'relative',
		padding: 1
	},
	users_not: {
		position: 'absolute',
		top: 0,
		right: 0
	},
	option_button: {
		padding: "8px 24px"
	},
	criticality: {
		margin: '12px 0',
		textAlign: 'end',
		'& > button': {
			borderRadius: 24
		}
	},
	icons: {
		blue: { color: newTheme.palette.blue.main, marginRight: 6 },
		yellow: { color: newTheme.palette.middle.main, marginRight: 6 },
		orange: { color: newTheme.palette.orange.main, marginRight: 6 },
		red: { color: newTheme.palette.error.main, marginRight: 6 },
	},
	all_button: {
		textAlign: 'end',
		margin: '12px 0'
	},
	user: {
		padding: 1
	}
}

function getSubtitleItems(subtitle, items) {
	return items.filter(item => item.subtitle_id === subtitle.id)
}

function FinishedItem({ item }) {
	const answer = item.answer
	const colors = [newTheme.palette.green.dark, newTheme.palette.error.main, newTheme.palette.info.main]
	const icons = [
		<Check style={{ color: newTheme.palette.green.dark, marginRight: 6 }} />,
		<Clear style={{ color: newTheme.palette.error.main, marginRight: 6 }} />,
		<Typography style={{ color: newTheme.palette.info.main, marginRight: 6 }} variant='h4'>[N/A]</Typography>
	]
	const crits = ["No asignado", "No Crítico", "Poco Crítico", "Medianamente Crítico", "Altamente Crítico"]
	const crit_colors = ["primary", "info", "middle", "warning", "error"]
	return (
		<Box sx={css.item}>
			<Box sx={css.header}>
				<Box sx={css.inline}>
					{icons[answer.value_id - 1]}
					<Typography variant='h4' style={{ color: colors[answer.value_id - 1] }}>{item.name}</Typography>
				</Box>
				{answer.value_id === 2 &&
					<Box style={{ flexGrow: 1, textAlign: 'end' }}>
						<Chip color={crit_colors[answer.criticality]} sx={css.tag} label={crits[answer.criticality]} icon={<ErrorOutline />} />
					</Box>
				}
			</Box>
			<Box sx={css.images}>
				{!!answer.file &&
					<Box sx={css.imageContainer}>
						<ImageContainer height={68} width={68} border={'6px'} src={answer.file || "/noimage.png"} />
					</Box>
				}
				{!!answer.file2 &&
					<Box sx={css.imageContainer}>
						<ImageContainer height={68} width={68} border={'6px'} src={answer.file2 || "/noimage.png"} />
					</Box>
				}
				{!!answer.file3 &&
					<Box sx={css.imageContainer}>
						<ImageContainer height={68} width={68} border={'6px'} src={answer.file3 || "/noimage.png"} />
					</Box>
				}
			</Box>
			<Box>
				<Typography variant='subtitle1'><strong>Comentarios:</strong> {answer.comments || "Sin comentarios..."}</Typography>
			</Box>
			{!!item.counter && answer.value_id === 2 &&
				<Box>
					<Typography variant='subtitle1'><strong>Cantidad:</strong> {answer.counter || 1}</Typography>
				</Box>
			}
		</Box>
	)
}

function Subtitle({ subtitle, items }) {

	const { checklist, setMetadata, isAdmin, isResponsible, setChecklist, finished } = useContext(ChecklistContext)
	const { account: { user }, online: { status: online } } = useContext(AppContext)
	const { addRequest } = useContext(RequestManagerContext)
	const workers = filterActiveWorkers()
	const metadata = checklist.metadata || {}
	const disabledCategories = metadata.disabled_categories || []
	const disabledUsers = metadata.disabled?.[subtitle.id] || []
	const subtitle_users = subtitle.users_object || []
	const branch_users_id = workers.map(u => u.id)
	const users = subtitle_users.filter(u => branch_users_id.includes(String(u.id)))
	const iAmDisabled = disabledUsers.includes(parseInt(user.id, 10))
	const iAmResponsible = isAdmin || subtitle_users.map(u => u.id).includes(parseInt(user.id, 10)) || isResponsible
	const isCategoryDisabled = disabledCategories.includes(subtitle.id)
	const shouldDisableCategory = isCategoryDisabled || iAmDisabled || !iAmResponsible
	const [open, toggleOpen] = useToggle(!shouldDisableCategory)

	function onDisable() {
		const metadata = checklist.metadata || {}
		const disabled = metadata.disabled_categories || []
		const newDisabled = [...disabled]
		if (newDisabled.includes(subtitle.id)) {
			newDisabled.splice(newDisabled.findIndex(e => e === subtitle.id), 1)
		} else {
			newDisabled.push(subtitle.id)
		}
		metadata.disabled_categories = newDisabled
		setMetadata(metadata)
	}

	function onDisableUser(user_id) {
		const metadata = checklist.metadata || {}
		const disabled_users_object = metadata.disabled ? { ...metadata.disabled } : {}
		const disabled_users = disabled_users_object[subtitle.id] ? [...disabled_users_object[subtitle.id]] : []
		if (disabled_users.includes(user_id)) {
			disabled_users.splice(disabled_users.findIndex(u => u === user_id), 1)
		} else {
			disabled_users.push(user_id)
		}
		disabled_users_object[subtitle.id] = disabled_users
		metadata.disabled = disabled_users_object
		setMetadata(metadata)
	}

	function answerAllOffline(body) {
		const new_checklist = { ...checklist }
		const new_items = [...checklist.checklist_items2]
		const items_to_answer = new_items.filter(item => item.subtitle_id === body.id)
		for (const item of items_to_answer) {
			item.answer = { ...item.answer, value_id: 3, value: "N/A" }
		}
		new_checklist.checklist_items2 = new_items
		setChecklist(new_checklist)
		addRequest({ name: "Marcar sección como no aplica", method: answerAllCategory(body, true) })
	}

	async function answerAll() {
		const body = { id: subtitle.id }
		body.checklist_id = checklist.id
		if (!online) return answerAllOffline(body)
		const response = await answerAllCategory(body)
		setChecklist(response.data.info)
	}

	const hasPrivileges = isAdmin || isResponsible
	const showSwitch = hasPrivileges && !!subtitle.id && !finished

	return (
		<Box sx={css.category}>
			<Box sx={css.header}>
				<Box sx={css.inline} style={{ opacity: shouldDisableCategory ? 0.7 : 1 }}>
					<CategoryOutlined color={shouldDisableCategory ? "disabled" : "secondary"} style={{ marginRight: 6 }} />
					<Typography variant='h4' color={shouldDisableCategory ? "disabled" : "secondary"}>{subtitle.name}</Typography>
				</Box>
				<Box sx={css.inline}>
					{showSwitch && <Switch onClick={onDisable} checked={!shouldDisableCategory} />}
					<IconButton onClick={toggleOpen}>
						<KeyboardArrowDown />
					</IconButton>
				</Box>
			</Box>
			{!!users.length && hasPrivileges &&
				<Box sx={css.users}>
					<Typography variant='subtitle1'><strong>Responsables</strong></Typography>
					<IconButton sx={css.users_not}>
						<NotificationsActiveOutlined />
					</IconButton>
					{users.map(user => <Box sx={css.inline}>
						<Checkbox disabled={shouldDisableCategory} checked={!disabledUsers.includes(user.id)} onClick={() => onDisableUser(user.id)} />
						<Typography variant='subtitle1'>{user.name}</Typography>
					</Box>
					)}
				</Box>
			}

			{open &&
				<>
					<Box sx={css.all_button}>
						<Button color="info" variant="outlined" onClick={answerAll}>
							Marcar Todos N/A
						</Button>
					</Box>
					{items.map(item => <Item key={item.id} disabled={shouldDisableCategory} item={item} />)}
				</>
			}
		</Box>
	)
}

function FinishedSubtitle({ subtitle, items }) {

	const { isAdmin, isResponsible } = useContext(ChecklistContext)
	const [open, toggleOpen] = useToggle(true)
	const hasPrivileges = isAdmin || isResponsible
	const workers = filterActiveWorkers(false)
	const subtitle_users = subtitle.users_object || []
	const branch_users_id = workers.map(u => u.id)
	const users = subtitle_users.filter(u => branch_users_id.includes(String(u.id)))


	return (
		<Box sx={css.category}>
			<Box sx={css.header}>
				<Box sx={css.inline}>
					<CategoryOutlined color={"secondary"} style={{ marginRight: 6 }} />
					<Typography variant='h4' color={"secondary"}>{subtitle.name}</Typography>
				</Box>
				<Box sx={css.inline}>
					<IconButton onClick={toggleOpen}>
						<KeyboardArrowDown />
					</IconButton>
				</Box>
			</Box>
			{!!users.length && hasPrivileges &&
				<Box sx={css.users}>
					<Typography sx={css.user} variant='subtitle1'><strong>Responsables:</strong></Typography>
					{users.map(user => <Box sx={css.user}>
						<Typography variant='subtitle1'>{user.name}</Typography>
					</Box>
					)}
				</Box>
			}

			{open &&
				<>
					{items.map(item => <FinishedItem key={item.id} item={item} />)}
				</>
			}
		</Box>
	)
}

function Items({ value }) {

	const { checklist, finished } = useContext(ChecklistContext)
	const items = checklist.checklist_items2 || []
	const subtitles = checklist.subtitles || []
	const subtitles_id = subtitles.map(s => s.id)
	const orphan_items = items.filter(item => !subtitles_id.includes(item.subtitle_id))
	// console.log(items)
	// console.log(subtitles)

	const [open, toggleOpen] = useToggle(false)

	return (
		<Box>
			<Paper sx={css.container}>
				<Box sx={css.header}>
					<Typography variant='h1'>Contenido</Typography>
					{!finished &&
						<IconButton onClick={toggleOpen}>
							<KeyboardArrowDown />
						</IconButton>
					}
				</Box>
			</Paper>
			{(open || finished) &&
				<>
					<Box>
						{subtitles.map(subtitle => finished ?
							<FinishedSubtitle key={subtitle.id} subtitle={subtitle} items={getSubtitleItems(subtitle, items)} /> :
							<Subtitle key={subtitle.id} subtitle={subtitle} items={getSubtitleItems(subtitle, items)} />
						)}
						{finished ?
							<FinishedSubtitle subtitle={{ name: "Sin Categoría", id: 0 }} items={orphan_items} /> :
							<Subtitle subtitle={{ name: "Sin Categoría", id: 0 }} items={orphan_items} />
						}
					</Box>
					<NewFinding />
				</>
			}
		</Box>
	)
}

export default Items