

const RequestReducer = (state = { all: [] }, action) => {
  const newState = { ...state }
  switch (action.type) {
    case "UPDATE_REQUESTS": {
      newState.all = action.payload.data
      return newState
    }
    case "SET_REQUEST": {
      const newAll = newState.all
      newAll[action.payload.index].status = action.payload.status
      return newState
    }
    case "REMOVE_REQUESTS": {
      const newAll = state.all.filter(request => request.status !== "success" && request.status !== "error")
      newState.all = newAll
      return newState
    }
    default:
      return newState
  }
}

export default RequestReducer
