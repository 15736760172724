import store from "../store"
import { completePath } from "../Utils/functions"
import instance from "../Utils/instance"
// import { callSnackbar } from "../Utils/snackbar"
const enterprise = store.getState().enterprise?.selected?.name || "Tazki"
const logo = store.getState().enterprise?.selected?.logo

const logo_string = !!logo ? `<div>
	<img src=${completePath(logo, `${process.env.REACT_APP_IMG_URL}/${process.env.REACT_APP_ENTERPRISE_LOGO}`)} height=64px />
	</div>` : ""

function completePathHere(file) {
	if (file.includes("amazonaws.com")) return file
	if (file.includes("data:image")) return file
	return `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${file}`
}

export const sendMail = body => {
	return instance({
		method: 'post',
		url: 'workflows/notification.php',
		data: body
	})
}

export const notifySign = (worker, registerType, link, name = "") => {
	const body = {
		subject: `${enterprise} - Registro firmado en Tazki`,
		content: `
			${logo_string}
			<strong>${worker.name}</strong>
			<p>Has firmado un(a) ${registerType} - ${name} en Tazki</p>
			<p>En el enlace a continuación podrás revisar el registro: <a href="${link}" target="_blank">Ver ${registerType}</a></p>
			`,
		emails: [worker.email],
		extra_mails: []
	}
	return sendMail(body)
}

export const notifyEnd = (worker, registerType, link, name = "") => {
	const body = {
		subject: `${enterprise} - Registro realizado`,
		content: `
			${logo_string}
			<strong>${worker.name}</strong>
			<p>Se ha terminado un(a) ${registerType} - ${name} en Tazki</p>
			<p>En el enlace a continuación podrás revisar el registro: <a href="${link}" target="_blank">Ver ${registerType}</a></p>
			`,
		emails: [worker.email],
		extra_mails: []
	}
	return sendMail(body)
}

export const notifyEndChecklist = (worker, link, name = "", checklist) => {
	const extrafields = checklist.extra_fields || []
	let extra_info = `<strong>${name}</strong>`
	extrafields.forEach(ef => {
		if (ef.type === "img") {
			const images = ef.value ? ef.value.split("&#&") : []
			const imgs = images.map(im => `<a href=${completePathHere(im)}>Ver imagen</a>`)
			extra_info += `<p><strong>${ef.label}</strong>: ${imgs}</p>`
		} else {
			extra_info += `<p><strong>${ef.label}</strong>: ${ef.value || "Sin respuesta..."}</p>`
		}
	})

	const body = {
		subject: `${enterprise} - Registro ${name} realizado`,
		content: `
			${logo_string}
			<strong>${worker.name}</strong>
			<p>Registro de <strong>${checklist?.user}</strong> ha sido realizado en Tazki: <strong>${name}</strong> (${checklist?.branch_name})</p>
			<p>En el enlace a continuación podrás revisar el registro: <a href="${link}" target="_blank">Ver Registro</a></p>
			<br/>
			${!!extrafields.length ? extra_info : ""}
			`,
		emails: [worker.email],
		extra_mails: []
	}
	return sendMail(body)
}

export const notifyAuthorization = (worker, link, name = "", checklist) => {
	const body = {
		subject: `${enterprise} - Revisión requerida en Tazki`,
		content: `
			${logo_string}
			<strong>${worker.name}</strong>
			<p>${checklist?.user} ha terminado un registro - <strong>${name}</strong> en Tazki (${checklist?.branch_name}) que requiere de tu revisión para continuar</p>
			<p>En el enlace a continuación podrás revisar el registro: <a href="${link}" target="_blank">Ver registro</a></p>
			`,
		emails: [worker.email],
		extra_mails: []
	}
	return sendMail(body)
}


