import instance2 from "../Utils/instance2"

export const getSettingsSupplies = () => {
	return instance2({
		method: 'get',
		url: 'settings/supplies'
	})
}

export const getSupplies = body => {
	return instance2({
		method: 'get',
		url: 'supplies',
		params: body
	})
}

export const createSupply = body => {
	return instance2({
		method: 'post',
		url: 'supplies',
		data: body
	})
}

export const editSupply = body => {
	return instance2({
		method: 'put',
		url: 'supplies',
		data: body
	})
}

export const deleteSupply = body => {
	return instance2({
		method: 'delete',
		url: 'supplies',
		data: body
	})
}

export const createFamily = body => {
	return instance2({
		method: 'post',
		url: 'settings/supplies/create_family',
		data: body
	})
}

export const editFamily = body => {
	return instance2({
		method: 'post',
		url: 'settings/supplies/edit_family',
		data: body
	})
}

export const createType = body => {
	return instance2({
		method: 'post',
		url: 'settings/supplies/create_type',
		data: body
	})
}

export const editType = body => {
	return instance2({
		method: 'post',
		url: 'settings/supplies/edit_type',
		data: body
	})
}

export const moveSupply = body => {
	return instance2({
		method: 'post',
		url: 'supplies/move',
		data: body
	})
}

export const supplyReport = body => {
	return instance2({
		method: 'post',
		url: 'supplies/report',
		data: body
	})
}