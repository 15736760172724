import { Switch } from '@mui/material'

function SwitchInput({ value, onChange, name }) {

	function onClick() {
		const event = {
			target: {
				name,
				value: !value
			}
		}
		onChange(event)
	}

	return (
		<Switch checked={value} onClick={onClick} />
	)
}

export default SwitchInput