import { Box, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import newTheme from '../../newTheme'
import ChecklistContent from './ChecklistContent'
import ChecklistSignContent from './ChecklistSignContent'

const css = {
	container: {
		padding: 3,
		margin: '24px 0',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		maxWidth: 800,
		boxSizing: 'border-box'
	},
	box: {
		height: 100,
		maxWidth: 100,
		flexGrow: 1,
		background: 'whitesmoke',
		borderRadius: 2,
		padding: 1,
		'@media (max-width:680px)': {
			width: '100%',
			maxWidth: 'unset',
			height: 50
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		'&:hover': {
			background: newTheme.palette.primary.light
		}
	},
	selected: {
		background: newTheme.palette.blue.light,
		'&:hover': {
			background: newTheme.palette.blue.light
		}
	},
	main: {
		paddingBottom: 8
	}
}

function ContentSelector() {

	const [selected, setSelected] = useState(0)

	const boxcss = css.box
	const selectedcss = { ...css.box, ...css.selected }
	const ContentToRender = !!selected ? ChecklistSignContent : ChecklistContent

	return (
		<Box sx={css.main} >
			<Paper sx={css.container}>
				<Box sx={selected === 0 ? selectedcss : boxcss} onClick={() => setSelected(0)}>
					<Typography variant='h4'>Registro</Typography>
				</Box>
				<Box sx={selected === 1 ? selectedcss : boxcss} onClick={() => setSelected(1)}>
					<Typography variant='h4'>Firmas</Typography>
				</Box>
			</Paper>
			<ContentToRender />
		</Box>
	)
}

export default ContentSelector