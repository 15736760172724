import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import autobind from '../../Utils/autobind'

const style = () => ({
  stepSubtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 300,
    cursor: 'pointer',
    background: '#f2f3f8',
    padding: '4px 8px',
    borderRadius: 8,
    marginBottom: 8
  },
})
class ExtraInfoToggler extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(ExtraInfoToggler, this)
  }

  handleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { classes } = this.props
    const { open } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.stepSubtitle} onClick={this.handleOpen}>
          <Typography variant="subtitle1">Información extra</Typography>
          <KeyboardArrowDown />
        </div>
        <Collapse in={open}>
          {this.props.children}
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(ExtraInfoToggler)