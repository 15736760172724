import { GetApp, Settings } from '@material-ui/icons'
import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getActivity, getActivityChecklists } from '../../../API/activities'
import AppContext from '../../../AppContext'
import useToggle from '../../../Hooks/ToogleHook'
import useChangeParams from '../../../Hooks/UseStateChangeParams'
import newTheme from '../../../newTheme'
import GeneralTable from '../../../Shared/GeneralTable'
import InfoGrid from '../../../Shared/InfoGrid'
import InfoLine from '../../../Shared/InfoLine'
import DateIntervalSelectorNew from '../../../Shared/Inputs/DateIntervalSelectorNew'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import MiniLoaderAnimator from '../../../Shared/MiniLoaderAnimator'
import { end_buttons, inline_space, section_separation, text_space, title_style } from '../../../Utils/defaultStyles'
import { filterActiveWorkers } from '../../../Utils/filters'
import { addQrToFile, mergeMultiplePdfFilesAndGet, transformToOptions } from '../../../Utils/functions'
import { callSnackbar } from '../../../Utils/snackbar'
import SignCell from '../../Activities/SignCell'
import { generateRegistersDocument } from '../../Activities/Utils/SettingsUtils'
import ImageCell from '../../Reports/Activities/ImageCell'
import ActivityChecklistSettings from './ActivityChecklistSettings'
import EditActivityForm from './EditActivityForm'
import NotDoneUsers from './NotDoneUsers'
import SignRegisters from './SignRegisters'
import { createTableData, createTableInfo, downloadReport, downloadRow } from './utils'

const css = {
	container: {
		maxWidth: 900,
		margin: 3
	},
	info: {
		maxWidth: 500,
		padding: 2,
		flexGrow: 1
	},
	flex: {
		display: 'flex',
		gap: 2,
		flexWrap: 'wrap'
	},
	paper: {
		position: 'relative'
	},
	loader: {
		position: 'absolute',
		bottom: 6,
		right: 6,
		height: 32,
		width: 32
	}
}

const frequencies = [
	{ value: 1000, label: "Sin Frecuencia" },
	{ value: 1, label: "Diario" },
	{ value: 2, label: "Cada dos días" },
	{ value: 3, label: "Cada tres días" },
	{ value: 7, label: "Semanal" },
	{ value: 15, label: "Quincenal" },
	{ value: 30, label: "Mensual" },
	{ value: 60, label: "Bimensual" },
	{ value: 90, label: "Trimestral" },
	{ value: 180, label: "Semestral" },
]

export const ActivityContext = createContext({})

function ActivityNew({ match }) {
	const workers = filterActiveWorkers()
	const [activity, setActivity] = useState({})
	const [openEdit, toggleEdit] = useToggle(false)
	const [selectedPM, selectPM] = useState("")
	const [loading, setLoading] = useState(false)
	const [done_checklists, setChecklists] = useState([])
	const [dates, setDates] = useChangeParams({
		date_start: moment().startOf("week").format("YYYY-MM-DD"),
		date_end: moment().endOf("week").format("YYYY-MM-DD")
	})
	const [selectedRows, setSelectedRows] = useState([])
	const { account } = useContext(AppContext)
	const [openSigns, toggleSigns] = useToggle(false)
	const [openUsers, toggleUsers] = useToggle(false)

	useEffect(() => {
		async function fetchData() {
			const { id } = match.params
			const response = await getActivity({ id })
			setActivity(response.data.info)
		}
		fetchData()
	}, [])

	useEffect(() => {
		const checklists = activity?.preventive_measures || []
		const selected = checklists[0] || {}
		selectPM(selected?.id)
	}, [activity])

	useEffect(() => {
		const body = { ...dates, status: "done", id: activity.id }
		async function fetchChecklists() {
			setLoading(true)
			const response = await getActivityChecklists(body)
			setChecklists(response.data.info)
			setLoading(false)
		}
		if (!!activity.id) { fetchChecklists() }
	}, [dates, selectedPM])

	const checklists = useMemo(() => activity?.preventive_measures || [], [activity])
	const checklist = useMemo(() => checklists.find(ch => String(ch.id) === String(selectedPM)), [checklists, selectedPM])
	const tableInfo = useMemo(() => createTableInfo(checklist), [checklist])
	const tableData = useMemo(() => createTableData(done_checklists), [done_checklists])

	function selectAllRows() {
		if (!selectedRows.length) return setSelectedRows([...done_checklists].map(ch => String(ch.id)))
		return setSelectedRows([])
	}

	function onSelectRow(row) {
		return () => {
			const new_selected_rows = [...selectedRows]
			if (new_selected_rows.includes(row.id)) {
				const index = new_selected_rows.findIndex(id => id === row.id)
				new_selected_rows.splice(index, 1)
				return setSelectedRows(new_selected_rows)
			} else {
				new_selected_rows.push(row.id)
				return setSelectedRows(new_selected_rows)
			}
		}
	}

	function onDownloadReport() {
		return () => downloadReport(selectedRows, checklist, activity)
	}

	function onDownloadRow(row) {
		return () => downloadRow(row, account)
	}

	const responsibleUsers = done_checklists.map(ch => ch.user_id)
	console.log(responsibleUsers)
	const irresponsibleUsers = workers.filter(worker => !responsibleUsers.includes(String(worker.id)))

	return (
		<Box sx={css.container}>
			<ActivityContext.Provider value={{ activity, setActivity, frequencies }} >
				<Box sx={{ ...inline_space, ...title_style }}>
					<Typography variant='h1'>Actividad {activity?.name}</Typography>
					<IconButton onClick={toggleEdit}>
						<Settings />
					</IconButton>
				</Box>
				<Box sx={css.flex}>
					<Paper sx={css.info}>
						<InfoGrid>
							<InfoLine primary="Centro de trabajo" secondary={activity.branch?.name} />
							<InfoLine primary="Registros y Formularios" secondary={activity?.preventive_measures?.map(p => p?.name)} />
							<InfoLine primary="Responsable" secondary={activity.user?.name} />
							<InfoLine primary="Fecha de Inicio" secondary={activity.date_start} />
							<InfoLine primary="Fecha de Término" secondary={activity.date_end} />
							<InfoLine primary="Frecuencia" secondary={frequencies.find(f => f.value === activity.frequency)?.label} />
						</InfoGrid>
					</Paper>
					<EditActivityForm open={openEdit} onClose={toggleEdit} />
				</Box>
				<Box sx={section_separation}>
					<ActivityChecklistSettings />
				</Box>
				<Paper sx={css.paper}>
					<SelectInput value={selectedPM} onChange={e => selectPM(e.target.value)} label="Registro o Formulario" options={transformToOptions(checklists)} />
					<DateIntervalSelectorNew dates={dates} onChange={setDates} />
					<Box sx={css.loader}>
						{loading && <MiniLoaderAnimator />}
					</Box>
					<Button color="info" onClick={toggleUsers}>
						Mostrar colaboradores que no han participado
					</Button>
				</Paper>
				{!!selectedRows.length &&
					<Box sx={{ ...end_buttons, ...section_separation }}>
						<Box sx={text_space}>
							<Button color="info" variant="contained" onClick={toggleSigns}>Firmar Registros</Button>
						</Box>
						<Box sx={text_space}>
							<Button color="info" variant="contained" onClick={onDownloadReport} >Descargar Informe de registros</Button>
						</Box>
					</Box>
				}
				<Box sx={section_separation}>
					<GeneralTable
						data={tableData}
						info={tableInfo}
						handleSelect={onSelectRow}
						selectable
						selected={selectedRows}
						selectAll={selectAllRows}
						name="tabla-registros y formularios"
						actions={[{ name: "Descargar Resumen", icon: GetApp, action: onDownloadRow, color: "primary" }]}
					/>
				</Box>
				<SignRegisters selectedRows={selectedRows} open={openSigns} onClose={toggleSigns} />
				<NotDoneUsers users={irresponsibleUsers} open={openUsers} onClose={toggleUsers} />
			</ActivityContext.Provider>
		</Box>
	)
}

export default withRouter(ActivityNew)