import { AddCircle, ArrowDropDownRounded, ArrowRightRounded, Delete, Edit, FileCopy } from '@material-ui/icons'
import { Box, Checkbox, Collapse, IconButton, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import useToggle from '../../Hooks/ToogleHook'
import newTheme from '../../newTheme'
import SeamlessInput from '../../Shared/Inputs/SeamlessInput'
import { StructureContext } from './InternalStructure'
import NewSubdivision from './Structure/NewSubdivision'

const css = {
	container: {

	},
	children: {
		paddingLeft: 3
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		// marginBottom: 1,
		cursor: 'pointer',
		height: 30
	},
	line: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function checkIfShouldBeOpen(subdivision, selected) {
	const imSelected = selected?.id === subdivision.id
	const children = subdivision.children || []
	const oneChildSelected = children.map(child => checkIfShouldBeOpen(child, selected)).some(Boolean)
	return imSelected || oneChildSelected
}

function calculateCompliance(subdivision) {
	const children = subdivision.children
	const requirements = subdivision.requirements
	let total = requirements.reduce((a, b) => a + b.amount, 0)
	let compliance = requirements.reduce((a, b) => a + b.compliance, 0)
	children.forEach(child => {
		const [child_total, child_compliance] = calculateCompliance(child)
		total += child_total
		compliance += child_compliance
	})
	return [total, compliance]
}

function Subdivision({ subdivision }) {

	const children = subdivision.children || []
	const {
		setSelected,
		selected,
		childEdit,
		childDelete,
		toggleClone,
		selecting,
		selected_requirement,
		selectForRequirement
	} = useContext(StructureContext)
	const isActualSelected = selected?.id === subdivision.id
	const imSelected = checkIfShouldBeOpen(subdivision, selected)
	const [creating, toggleCreate] = useToggle(false)
	const [hover, setHover] = useState(false)
	const [edit, toggleEdit] = useToggle(false)
	const [name, setName] = useState(subdivision?.name)

	function openEdit(e) {
		e.stopPropagation()
		toggleEdit()
	}

	useEffect(() => {
		setName(subdivision?.name)
	}, [subdivision])

	function checkEnter(e) {
		if (e.key === "Enter") {
			e.preventDefault()
			toggleEdit()
			const body = { name, id: subdivision.id }
			childEdit(body)
		}
		if (e.key === "Escape") {
			toggleEdit()
		}
	}

	function onBlur() {
		const body = { name, id: subdivision.id }
		childEdit(body)
		toggleEdit()
	}

	function onDelete(e) {
		e.stopPropagation()
		const body = { ...subdivision }
		childDelete(body)
	}

	function onClone() {
		setSelected(subdivision)
		toggleClone()
	}

	const [total, compliance] = calculateCompliance(subdivision)

	return (
		<Box>
			<Box sx={css.line} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
				<Box sx={css.header} onClick={() => setSelected(subdivision)} >
					{selecting ?
						<Checkbox onClick={() => selectForRequirement(subdivision?.id)} checked={selected_requirement.includes(subdivision?.id)} />
						:
						<>
							{imSelected ?
								<ArrowDropDownRounded style={{ height: 28, width: 28, color: newTheme.palette.blue.main }} />
								:
								<ArrowRightRounded style={{ height: 28, width: 28 }} />
							}
						</>
					}
					{edit ?
						<SeamlessInput onBlur={onBlur} focus onKeyPress={checkEnter} variant="h4" value={name} onChange={e => setName(e.target.value)} />
						:
						<Box sx={css.header}>
							<Typography
								style={{ color: imSelected ? newTheme.palette.blue.main : newTheme.palette.primary.main, marginRight: 6 }}
								variant='h4'>
								{subdivision.name}
							</Typography>
							<Typography variant='caption'>{`(${compliance}/${total})`}</Typography>
						</Box>
					}
				</Box>
				{!selecting &&
					<Box>
						{isActualSelected &&
							<IconButton size="small" style={{ marginLeft: 12 }} onClick={toggleCreate}>
								<AddCircle />
							</IconButton>
						}
						{(hover || isActualSelected) &&
							<>
								<IconButton size="small" style={{ marginLeft: 12 }} onClick={openEdit}>
									<Edit />
								</IconButton>
								<IconButton size="small" style={{ marginLeft: 12 }} onClick={onDelete}>
									<Delete />
								</IconButton>
								<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Clonar la estructura de otro elemento</Typography>}>
									<IconButton size="small" style={{ marginLeft: 12 }} onClick={onClone}>
										<FileCopy />
									</IconButton>
								</Tooltip>
							</>
						}
					</Box>
				}
			</Box>
			<Collapse in={imSelected || selecting} unmountOnExit>
				<Box sx={css.children}>
					{children.map(child => <Subdivision subdivision={child} />)}
					{creating && <NewSubdivision focus onClose={toggleCreate} parent={subdivision.id} parent_element={subdivision} />}
				</Box>
			</Collapse>
		</Box>
	)
}

export default Subdivision