import { Box, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { createBaseEvaluation, editBaseEvaluation, getBaseEvaluations } from '../../../API/evaluations'
import useToggle from '../../../Hooks/ToogleHook'
import AddElementButton from '../../../Shared/AddElementButton'
import BaseEvaluation from './BaseEvaluation'

const css = {
  container: {
    margin: 2,
    '& > h1': {
      marginBottom: 2
    }
  },
  paper: {
    padding: 2
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}

function EvaluationSettings({ value }) {

  const [evaluations, setEvaluations] = useState([])

  useEffect(() => {
    async function fetchData() {
      const response = await getBaseEvaluations()
      setEvaluations(response.data.info)
    }

    fetchData()

  }, [])

  async function create() {
    const response = await createBaseEvaluation()
    setEvaluations(response.data.info)
  }

  async function edit(body) {
    const response = await editBaseEvaluation(body)
    setEvaluations(response.data.info)
  }

  console.log(evaluations)

  return (
    <Box sx={css.container}>
      <Typography variant='h1'>Configuración evaluaciones</Typography>
      <Paper sx={css.paper}>
        <Box sx={css.header}>
          <Typography variant='h4'>Listado de evaluaciones</Typography>
          <AddElementButton onClick={create} name="Agregar evaluación" />
        </Box>
        <Box sx={css.evaluations}>
          {evaluations.map(evaluation => <BaseEvaluation evaluation={evaluation} onEdit={edit} />)}
        </Box>
      </Paper>
    </Box>
  )
}

export default EvaluationSettings