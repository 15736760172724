import React, { Component } from 'react'
import { Typography, withStyles, Collapse } from '@material-ui/core'
import TextInput from '../../../Shared/Inputs/TextInput'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import ImgInput from '../../../Shared/Inputs/ImgInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import autobind from '../../../Utils/autobind'
import instance from '../../../Utils/instance'
import ChecklistContext from '../../ChecklistNew/ChecklistContext'

const style = () => ({

})

function renderMessages(options, value) {
  if (!options) return null
  return options.map(option => {
    if (typeof option === "string") {
      const [expected, message, color] = option.split('&')
      return (
        <Collapse in={expected === value && message}>
          <Typography style={{ color }} variant="subtitle1">{message}</Typography>
        </Collapse>
      )
    }
    const { label: expected, message, color } = option
    return (
      <Collapse in={expected === value && message}>
        <Typography style={{ color }} variant="subtitle1">{message}</Typography>
      </Collapse>
    )

  })
}

function getTotalCounters(checklist) {
  function getFactor(item) {
    const crit = item?.answer?.criticality
    if (crit === 4) return 2
    if (crit === 3) return 1.5
    return 1
  }
  const items = checklist.checklist_items
  const filtered = items
    .filter(item => item.counter === 1 && item.answer.counter !== null)
  if (filtered.length === 0) return 0
  const total = filtered
    .reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
  return total.answer.counter
}


class Subquestions extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(Subquestions, this)
  }

  componentDidMount() {
    const { question, index } = this.props
    if (question.value) {
      const params = { [index]: question.value }
      this.setState({ params })
    }
  }

  handleChange(event) {
    const { index, option } = this.props
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
    option.subquestions[index].value = target.value
  }

  handleChangeImage() {
    return (event) => {
      const { index, option, field } = this.props
      const { onAnswerExtra } = this.context
      const { target } = event
      const file = target.value

      //Upload File and get the name of the saved one
      const body = new FormData()
      body.append("file", file)
      const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/extra-fields-file.php`
      const oldImages = option.subquestions[index].value
      instance({
        method: 'post',
        url,
        data: body
      }).then(response => {
        if (oldImages) {
          option.subquestions[index].value = `${oldImages}&#&${response.data?.info?.file_name}`

        } else {
          option.subquestions[index].value = response.data?.info?.file_name
        }
        const originalEvent = {
          target: {
            name: field?.index,
            value: field?.value
          }
        }
        onAnswerExtra(originalEvent)
      })
    }
  }

  renderSubquestion(options, answer) {
    const { selected, disabled, field } = this.props
    if (!options) return null
    const selectedOption = options.find(option => option.label === answer)
    const hasSubquestions = selectedOption?.subquestions?.length > 0
    if (!hasSubquestions) return null
    const subquestions = selectedOption.subquestions
    return subquestions.map((question, index) => {
      return (
        <Subquestions
          option={selectedOption}
          question={question}
          index={index}
          field={field}
          selected={selected}
          disabled={disabled}
        />
      )
    })
  }

  render() {
    const { classes, question, disabled, index, selected, field } = this.props
    const { params } = this.state

    function transofrmToExtraOptions(options) {
      if (!options) return []
      return options.map(option => {
        if (typeof option === "string") {
          return {
            label: option.split('&')[0],
            value: option.split('&')[0]
          }
        }
        return { label: option.label, value: option.label }
      })
    }

    switch (question.type) {
      case "text": {
        return (
          <div>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <TextInput
              disabled={disabled}
              name={index}
              value={params[index]}
              onChange={this.handleChange}
              label={""}
            />
          </div>
        )
      }
      case "select": {
        return (
          <div className={classes?.extraField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <SelectInput
              disabled={disabled}
              name={index}
              value={params[index]}
              onChange={this.handleChange}
              label={""}
              options={transofrmToExtraOptions(question.options)}
            />
            {renderMessages(question.options, params[index])}
            {this.renderSubquestion(question.options, params[index])}
          </div>
        )
      }
      case "multiselect": {
        return (
          <div className={classes?.extraField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <MultiSelectInput
              disabled={disabled}
              name={index}
              value={params[index]}
              onChange={this.handleChange}
              label={""}
              options={transofrmToExtraOptions(question.options)}
              withText
            />
            {renderMessages(question.options, params[index])}
            {this.renderSubquestion(question.options, params[index])}
          </div>
        )
      }
      case "img": {
        return (
          <div className={classes?.extraField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <ImgInput
              disabled={disabled}
              name={index}
              value={params[index]}
              onChange={this.handleChangeImage()}
              label={""}
              onDelete={this.handleDeleteImage}
              url={`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`}
            />
          </div>
        )
      }
      case "ids": {
        return (
          <div className={classes?.extraField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <Typography variant="subtitle1">Eventos Ponderados: {getTotalCounters(selected)}</Typography>
            <TextInput
              disabled={disabled}
              name={index}
              type="number"
              value={params[index]}
              onChange={this.handleChange}
              label={"Cantidad de trabajadores"}
            />
            <Typography variant="subtitle1">Resultado: {params[index] ? (getTotalCounters(selected) * 100) / params[index] : 0}</Typography>
          </div>
        )
      }
      default: {
        return (
          <div className={classes?.extraField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <TextInput
              disabled={disabled}
              name={index}
              value={params[index]}
              onChange={this.handleChange}
              label={""}
            />
          </div>
        )
      }
    }
  }
}

Subquestions.contextType = ChecklistContext

export default withStyles(style)(Subquestions)