import React, { Component } from 'react'
import { Collapse, IconButton, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline } from '@material-ui/icons'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import SubmitButton from '../../../../Shared/SubmitButton'
import autobind from '../../../../Utils/autobind'

const style = () => ({
  container: {
    padding: '6px 12px',
    margin: 12,
    background: '#f2f3f8',
    borderRadius: 8,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

class CreateOption extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      open: false
    }
    autobind(CreateOption, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { onSubmit } = this.props
    const { params } = this.state
    const body = { ...params }
    this.setState({ params: {} })
    onSubmit(body)
  }

  render() {
    const { classes } = this.props
    const { params, open } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.header} onClick={this.handleOpen}>
          <Typography variant="subtitle1">Agregar nueva opción a la lista</Typography>
          <IconButton size="small">
            <AddCircleOutline />
          </IconButton>
        </div>
        <Collapse in={open}>
          <div className={classes.container}>
            <div className={classes.optionsCreator}>
              <div className={classes.extraField}>
                <TextInput label="Agregar Opción" onChange={this.handleChange} name="label" value={params.label} />
              </div>
              {<div className={classes.extraField}>
                <TextInput label="Mensaje (Opcional)" onChange={this.handleChange} name="message" value={params.message} />
              </div>}
              {<div className={classes.extraField}>
                <SelectInput options={[
                  { label: "Rojo", value: "red" },
                  { label: "Verde", value: "green" },
                  { label: "Amarillo", value: "gold" },
                  { label: "Sin Color", value: "unset" },
                ]}
                  label="Color" onChange={this.handleChange} name="color" value={params.color} />
              </div>}
              <SubmitButton color="primary" onClick={this.handleCreate} disabled={!params.label}>
                Agregar opción
              </SubmitButton>
            </div>
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(CreateOption)