import { Box, Divider } from '@mui/material'
import { useContext } from 'react'
import NewFindingFromChecklist from '../ChecklistNew/Components/NewFindingFromChecklist'
import { ChecklistContext } from './ChecklistView'
import ExtraFields from './Content/ExtraFields'
import Items from './Content/Items'
import NewFinding from './Content/NewFinding'

const css = {
	container: {
	},
	section: {
		flexGrow: 1,
		maxWidth: 800,
		margin: '24px 0'
	}
}

function ChecklistContent({ value }) {

	const { checklist } = useContext(ChecklistContext)

	const inverted = checklist.invert_order === 1
	const sections = [Items, ExtraFields]
	const elements = inverted ? sections.reverse() : sections
	return (
		<Box sx={css.container}>
			{elements.map(Section =>
				<Box sx={css.section}>
					<Section />
				</Box>
			)}
		</Box>
	)
}

export default ChecklistContent