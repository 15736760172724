import React, { Component } from "react"
import { withSnackbar } from "notistack"
import autobind from "../../Utils/autobind"
import { TextField, Button, withStyles, Typography } from "@material-ui/core"
import { sendRecover } from "../../API/users"
import { callSnackbar } from "../../Utils/snackbar"

const style = () => ({
  container: {
    padding: 24,
    textAlign: "left",
    maxWidth: 600,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "@media (max-width:500px)": {
      width: "80%",
    },
  },
  input: {
    margin: 'auto',
    maxWidth: 370,
    marginTop: 12
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  },
  welcome: {
    margin: "24px 0",
    fontSize: 30,
    textAlign: 'center',
    color: 'white'
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    height: '100vh',
    width: '100vw'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 12
  },
  logo: {
    width: '80%'
  },
  loginButton: {
    background: '#ADF4FF',
    width: 370,
    borderRadius: 12,
    '& > span': {
      fontWeight: 600,
      color: "#212D4C",
      fontSize: '1.5vh'
    },
    padding: 12
  },
  inputWithUnderline: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      height: 2,
      width: '100%',
      bottom: 0,
      left: 0,
      background: '#212D4C'
    }
  },
  inputWithOutline: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: '70%',
      width: '100%',
      bottom: 0,
      left: 0,
      borderRadius: '12px',
      border: '2px solid white',
    }
  },
  recovery: {
    textAlign: 'center',
    marginTop: 6,
    '& > *': {
      color: '#212D4C',
      fontSize: '0.8rem'
    }
  },
  mobileRecovery: {
    textAlign: 'center',
    marginTop: 6,
    '& > *': {
      color: 'white',
      fontSize: '1.2rem'
    }
  },
  newAccount: {
    textAlign: 'center',
    marginTop: 48,
    '& > *': {
      margin: '6px 0'
    }
  },
  backgroundImage: {
    zIndex: 0,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  name: {
    position: 'absolute',
    bottom: 64,
    left: '75%',
    transform: 'translate(-50%, 0)'
  },
  sideLogin: {
    background: 'white',
    position: 'absolute',
    width: '45%',
    zIndex: 2,
    height: '100%',
    borderRadius: '0 100px 100px 0',
    textAlign: 'center',
  },
  title: {
    fontSize: '5vh',
    textAlign: 'start',
    lineHeight: 1,
    color: '#212D4C',
    marginBottom: 12
  },
  mobileTitle: {
    fontSize: '3vh',
    textAlign: 'start',
    lineHeight: 1,
    color: 'white',
    marginBottom: 12,
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  link: {
    color: '#212D4C',
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  mobileLink: {
    color: 'white',
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: 16
  },
  mobileContainer: {
    background: 'white',
    textAlign: 'center',
    width: '100%',
    height: '100vh'
  },
  mobileBackground: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '75%',

    background: 'linear-gradient(0deg, rgba(33,51,103,1) 0%, rgba(33,45,76,1) 100%)', // to #213367
    borderRadius: '0 126px 0 0'
  },
  mobileLoginContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  }
})

class RecoverPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
    }

    autobind(RecoverPassword, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  async handleSend() {
    const { params } = this.state
    const body = { ...params }
    const response = await sendRecover(body)
    callSnackbar(response.data.message, response.data.status)
  }

  renderDesktop() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <>
        <div className={classes.backgroundImage}>
          <div style={{ position: 'absolute', zIndex: 1, height: '100%', width: '100%', opacity: 0.9, overflow: 'hidden' }}>
            <img style={{ position: 'absolute' }} alt="fondo" src="/login/desktop/layer.jpg" width={'100%'} height={'100%'} />
            <img style={{ position: 'absolute', transform: 'translate(20%, 0)', top: 0, left: 0 }} alt="lineas" src="/login/desktop/lineas.svg" height={'100%'} />
          </div>
          <div style={{ position: 'absolute', overflow: 'hidden', height: '100%', width: '100%' }}>
            <img style={{ position: 'absolute' }} alt="fondo" src="/login/desktop/fondo.jpg" width={'100%'} height={'100%'} />
          </div>
        </div>
        <img className={classes.name} alt="name" src="/login/desktop/name.png" width={260} />
        <div className={classes.sideLogin}>
          <img alt="logo" src="/enterprises/tazki/192.png" width={90} style={{ padding: '48px 0 24px 0' }} />
          <div className={classes.center}>
            <Typography variant="h1">Recuperación de contraseña</Typography>
          </div>
          <div className={classes.input}>
            <TextField
              fullWidth
              value={params.username || ""}
              type="text"
              name="username"
              label="Ingrese su usuario"
              className={classes.inputWithUnderline}
              onChange={this.handleChange}
              inputProps={{
                style: {
                  padding: "12px 18px",
                  color: '#212D4C',
                  fontSize: 15
                },
              }}
              InputProps={{
                style: { borderRadius: 12 },
                disableUnderline: true
              }}
              InputLabelProps={{
                style: { color: '#212D4C', paddingLeft: 12 },
              }}
            />
          </div>
          <Typography
            variant='subtitle1'
            style={{ margin: 'auto', maxWidth: 370, marginTop: 24 }}>
            Se le enviará un correo con un enlace para cambiar su contraseña
          </Typography>
          <div className={classes.button}>
            <Button
              variant="contained"
              className={classes.loginButton}
              onClick={this.handleSend}
            >
              Enviar Correo
            </Button>
          </div>
        </div>
      </>
    )
  }

  renderMobile() {
    const { params } = this.state
    const { classes } = this.props
    return (
      <div className={classes.mobileContainer}>
        <img alt="logo" src="/enterprises/tazki/192.png" width={'80vw'} style={{ padding: '48px 0 24px 0' }} />

        <div className={classes.mobileBackground}>
          <img style={{
            position: 'absolute',
            top: 0, left: 0,
            transform: "translate(-24%, -32%)",
            opacity: 0.5
          }} alt="lineas" src="/login/mobile/lineas.svg" height={'120%'} />
          <img style={{ position: 'absolute', bottom: 24, left: '50%', transform: 'translate(-50%, 0)' }} alt="name" src="/login/mobile/name.png" width='45%' />
          <div className={classes.mobileLoginContent}>
            <div style={{ maxWidth: '75%' }}>
              <div className={classes.center}>
                <Typography className={classes.mobileTitle}>Recuperación de contraseña</Typography>
              </div>
              <div className={classes.input}>
                <TextField
                  fullWidth
                  value={params.username || ""}
                  type="text"
                  name="username"
                  label="Ingrese su usuario"
                  className={classes.inputWithOutline}
                  onChange={this.handleChange}
                  inputProps={{
                    style: {
                      padding: "16px 18px",
                      color: 'white',
                      fontSize: 16
                    },
                  }}
                  InputProps={{
                    style: { borderRadius: 12 },
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    style: { color: 'white', paddingLeft: 12 },
                  }}
                />
              </div>
              <Typography
                variant='subtitle1'
                style={{ color: "white", margin: 'auto', maxWidth: 370, marginTop: 24 }}>
                Se le enviará un correo con un enlace para cambiar su contraseña
              </Typography>
              <div className={classes.button} style={{ margin: '24px 0' }}>
                <Button
                  variant="contained"
                  className={classes.loginButton}
                  onClick={this.handleSend}
                >
                  Enviar Correo
                </Button>
              </div>
              {/* <div className={classes.newAccount}>
								<div className={classes.mobileRecovery}>
									<Typography variant="body1">¿Olvidaste tu contraseña?</Typography>
								</div>
								<Typography
									className={classes.mobileLink}
									onClick={() => window.open("https://tazki.cl/cotizar/")}
								>
									RECUPÉRALA AQUÍ
								</Typography>
							</div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const isMobile = window.innerWidth <= 1000
    if (!isMobile) return this.renderDesktop()
    return this.renderMobile()
  }
}

export default withStyles(style)(withSnackbar(RecoverPassword))
