import { Box, Button, Dialog, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { createMultipleActivitiesFromBranches } from '../../API/activities'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { dialog_style, end_buttons, inline_space } from '../../Utils/defaultStyles'
import { filterActiveWorkers } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'
import { callSnackbar } from '../../Utils/snackbar'
import { ActivitiesContext } from './ActivitiesNew'

function SelectResponsiblesForActivities({ branch_ids = [], open, onClose, base_activity = {} }) {

	const { formData } = useContext(ActivitiesContext)

	const [activities, setActivities] = useState([])
	const [page, setPage] = useState(0)

	useEffect(() => {
		const new_activities = branch_ids.map(branch_id => ({ ...base_activity, branch_id }))
		setActivities(new_activities)
	}, [branch_ids, base_activity])

	function setResponsible(event) {
		const { target } = event
		const new_activities = [...activities]
		const new_activity = new_activities[page]
		new_activity.user_id = target.value
		new_activities[page] = new_activity
		setActivities(new_activities)
	}

	async function onCreate() {
		const body = [...activities]
		await createMultipleActivitiesFromBranches(body)
		callSnackbar("Actividades Programadas", "success")
		onClose()
	}

	function checkForEveryActWithUser() {
		return activities.every(ac => !!ac.user_id)
	}

	const actualBranch = useMemo(() => formData?.branches?.find(b => b.id === branch_ids[page]), [page, formData, branch_ids])
	const workers = filterActiveWorkers(false)
	const filteredWorkers = workers.filter(w => w.branch_ids.map(String).includes(String(actualBranch?.id)))


	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={dialog_style}>
				<Typography variant='h4'>Seleccionar responsable para {actualBranch?.name}</Typography>
				<SelectInput onChange={setResponsible} value={activities[page]?.user_id} options={transformToOptions(filteredWorkers)} label="Responsable" />
				<Box sx={inline_space}>
					<Button color="info" onClick={() => setPage(p => (p - 1 + branch_ids.length) % branch_ids.length)}>
						Anterior
					</Button>
					<Button color="info" onClick={() => setPage(p => (p + 1) % branch_ids.length)}>
						Siguiente
					</Button>
				</Box>
				<Box sx={end_buttons}>
					<Button variant="contained" color="primary" onClick={onCreate} disabled={!checkForEveryActWithUser()}>
						Terminar y Planificar
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default SelectResponsiblesForActivities