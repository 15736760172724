import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import SubmitButton from '../../../Shared/SubmitButton'
import autobind from '../../../Utils/autobind'
import { transformToOptions } from '../../../Utils/functions'
import moment from 'moment'
import { getActivities } from '../../../API/activities'
import { getWorkers } from '../../../API/users'

const style = () => ({
  dialog: {
    padding: 24
  }
})
class NewChecklist extends Component {
  constructor() {
    super()
    this.state = {
      workers: [],
      activities: [],
      params: {}
    }
    autobind(NewChecklist, this)
  }

  async componentDidMount() {
    const { date } = this.props

    const activitiesResponse = await getActivities()
    const workersResponse = await getWorkers()

    const { data: { info: activities } } = activitiesResponse
    const { data: { info: workers } } = workersResponse

    const params = {}
    params.date_scheduled = date
    this.setState({ params, activities, workers })
  }

  componentDidUpdate(prevProps) {
    const { date } = this.props
    const { date: prevdate } = prevProps
    if (date !== prevdate) {
      const { params } = this.state
      params.date_scheduled = date
      this.setState({ params })
    }
  }

  handleChange(event) {
    const { target: { name, value } } = event
    const { params } = this.state
    params[name] = value
    this.setState({ params })
  }

  handleSend() {
    const { params } = this.state
    const { onSubmit, onClose, date } = this.props
    const body = { ...params }
    onSubmit(body)
    this.setState({ params: { date_scheduled: date } })
    onClose()
  }

  render() {
    const { classes, open, onClose } = this.props
    const { params, activities, workers } = this.state
    const selectedActivity = activities.find(ac => ac.id === params.activity_id)
    console.log(selectedActivity)
    const pms = selectedActivity?.preventive_measures.flat() || []
    console.log(pms)
    return (
      <Dialog fullWidth maxwidth="sm" open={open} onClose={onClose}>
        <div className={classes.dialog}>
          <Typography variant="h4">Crear registro o formulario</Typography>
          <Typography variant="caption">Registro agendado para el: {' '}
            <span style={{ fontWeight: 600 }}>
              {moment(params?.date_scheduled).format("DD [de] MMMM [del] YYYY")}
            </span>
          </Typography>

          <SelectInput
            label="Actividad"
            value={params?.activity_id}
            onChange={this.handleChange}
            options={transformToOptions(activities.map(activity => { return { ...activity, name: `${activity.name} (${activity.start_date} - ${activity.end_date})` } }))}
            name="activity_id"
          />
          <SelectInput
            disabled={!params?.activity_id}
            label="Medida preventiva"
            value={params?.preventive_measure_id}
            onChange={this.handleChange}
            options={transformToOptions(pms)}
            name="preventive_measure_id" />
          <SelectInput
            value={params?.user_id}
            options={transformToOptions(workers)}
            label="Responsable"
            name="user_id"
            onChange={this.handleChange}
          />
          <SubmitButton variant="contained" onClick={this.handleSend} disabled={params && Object.keys(params)?.length < 3}>Crear</SubmitButton>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(NewChecklist)