import { AddCircle } from '@material-ui/icons'
import { Box, Button, Typography } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getSettingsChecklist } from '../../../API/checklists'
import { createSettingsWorkflow, createWorkflowPhase, createWorkflowStep, deleteWorkflowPhase, deleteWorkflowStep, editSettingsWorkflow, editWorkflowPhase, editWorkflowStep, getSettingsWorkflows } from '../../../API/workflows'
import useChange from '../../../Hooks/UseStateChange'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import { inline_space } from '../../../Utils/defaultStyles'
import { fetchFunction } from '../../../Utils/functions'
import StepSetup from './StepSetup'

const css = {
	title: {
		background: 'white',
		padding: 2,
		margin: '12px 0',
		borderRadius: 2
	},
	container: {
		maxWidth: 1000
	}
}

export const WorkflowSetupContext = createContext({})

function WorkflowSetup({ match }) {

	const [workflow, setWorkflow] = useState({})
	const [name, setName, forceName] = useChange("")
	const [baseChecklists, setChecklists] = useState([])
	const id = match.params.id

	useEffect(() => {
		const fetchData = fetchFunction(getSettingsWorkflows, setWorkflow, { id })
		fetchData()
		const fetchChecklists = fetchFunction(getSettingsChecklist, setChecklists)
		fetchChecklists()
	}, [])


	useEffect(() => {
		forceName(workflow?.name)
	}, [workflow, forceName])

	async function onBlur() {
		const body = { ...workflow, name }
		fetchFunction(editSettingsWorkflow, setWorkflow, body)()
	}

	async function createStep() {
		const body = { workflow_id: workflow.id }
		fetchFunction(createWorkflowStep, setWorkflow, body)()
	}

	async function editStep(body) {
		body.workflow_id = workflow.id
		fetchFunction(editWorkflowStep, setWorkflow, body)()
	}

	async function createPhase(body) {
		body.workflow_id = workflow.id
		fetchFunction(createWorkflowPhase, setWorkflow, body)()
	}

	async function editPhase(body) {
		body.workflow_id = workflow.id
		fetchFunction(editWorkflowPhase, setWorkflow, body)()
	}

	async function deletePhase(body) {
		body.workflow_id = workflow.id
		fetchFunction(deleteWorkflowPhase, setWorkflow, body)()
	}

	async function deleteStep(body) {
		body.workflow_id = workflow.id
		fetchFunction(deleteWorkflowStep, setWorkflow, body)()
	}

	const steps = workflow.workflow_steps || []

	return (
		<Box sx={css.container}>
			<WorkflowSetupContext.Provider value={{ workflow, editStep, createPhase, editPhase, deletePhase, deleteStep, baseChecklists }}>
				<Box sx={css.container}>
					<Box sx={css.title}>
						<SeamlessInput onBlur={onBlur} variant="h1" color="transparent" value={name} onChange={setName} />
					</Box>
				</Box>
				<Button color="primary" variant="contained" endIcon={<AddCircle />} onClick={createStep}>
					Crear Etapa
				</Button>
				{steps.map(step => <StepSetup key={step.id} step={step} />)}
			</WorkflowSetupContext.Provider>
		</Box>
	)
}

export default withRouter(WorkflowSetup)