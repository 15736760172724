import stateMaker from "../Utils/stateMaker"

const WorkerReducer = (state = { all: [], selected: {}, documents: [] }, action) => {
    switch (action.type) {
        case "GET_WORKERS":
            return stateMaker(state, "all", "fetch", action)
        case "CREATE_WORKER":
            return stateMaker(state, "all", "create", action)
        case "GET_WORKER":
            return stateMaker(state, "selected", "fetch", action)
        case "UPDATE_WORKER":
            return stateMaker(state, "selected", "fetch", action)
        case "GET_USER_DOCUMENTS":
            return stateMaker(state, "documents", "fetch", action)
        case "EDIT_USER_DOCUMENT": {
            const newState = { ...state }
            const newDocuments = newState.documents
            if (action.payload.data.status === "success") {
                const newDocument = action.payload.data.info
                const index = newDocuments.findIndex(document => document.id === newDocument.id)
                newDocuments[index] = newDocument
            }
            return newState
        }
        case "DELETE_USER_DOCUMENT":
            return stateMaker(state, "documents", "fetch", action)
        case "CREATE_USER_DOCUMENT": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newDocument = action.payload.data.info
                newState.documents.push(newDocument)
            }
            return newState
        }
        case "CREATE_MULTIPLE_USER_DOCUMENTS": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newDocuments = action.payload.data.info
                newState.documents = newState.documents.concat(newDocuments)
            }
            return newState
        }
        case "ADD_USER_SIGN": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newSign = action.payload.data.info
                newState.selected.signs ? newState.selected.signs.push(newSign) : newState.selected.signs = [newSign]
            }
            return newState
        }
        case "SIGN_USER_DOCUMENT": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newSign = action.payload.data.info
                const documents = newState.documents
                const newDocIndex = documents.findIndex(doc => doc.id.toString() === newSign.document_id.toString())
                documents[newDocIndex].signs.push(newSign)
                newState.documents = documents
            }
            return newState
        }
        case "CHANGE_PERMISSIONS": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newUser = action.payload.data.info
                newState.selected = newUser
            }
            return newState
        }
        case "TOGGLE_ACTIVE": {
            const newState = { ...state }
            if (action.payload.data.status === "success") {
                const newUser = action.payload.data.info
                newState.selected = newUser
            }
            return newState
        }
        default:
            return state
    }
}

export default WorkerReducer
