import React, { Component } from 'react'
import { Collapse, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../../Utils/autobind'
import { getPrograms } from '../../../API/programs'
import LoadingDialog from '../../../Shared/LoadingDialog'
import GeneralTable from '../../../Shared/GeneralTable'
import NumberRender from '../../Checklists/NumberRender'
import { withRouter } from 'react-router-dom'
import ProgramContext from '../ProgramContext'
import SuperProgramCard from './SuperProgramCard'
import ToggleIcon from '../../../Shared/Icons/ToggleIcon'
import DateIntervalSelector from '../../../Shared/Inputs/DateIntervalSelector'
import InnerRecordCard from './InnerRecordCard'
import moment from 'moment'
import BarChart from '../../../Shared/Charts/BarChart'
import { BarChartOutlined, KeyboardArrowLeft } from '@material-ui/icons'
import MiniLoaderAnimator from '../../../Shared/MiniLoaderAnimator'

const style = () => ({
  recordTable: {
    margin: '24px 0'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 6
    }
  },
  top: {
    // display: 'flex',
    // alignItems: 'flex-start',
    // justifyContent: 'space-between',
    // '& > *': {
    //   flexBasis: '50%'
    // }
  }
})

const frequencies = [
  { label: "Sin frecuencia", value: 0 },
  { label: "Semanal", value: 7 },
  { label: "Mensual", value: 30 },
  { label: "Trimestral", value: 90 },
  { label: "Semestral", value: 180 },
  { label: "Anual", value: 365 }
]

class SingleProgram extends Component {
  constructor() {
    super()
    this.state = {
      program: {},
      loading: false,
      loadingData: false,
      date_start: moment().startOf("year").format("YYYY-MM-DD"),
      date_end: moment().endOf("week").format("YYYY-MM-DD"),
      totalPoints: 0,
      resumeData: {},
      insides: []
    }
    addToggle(SingleProgram, this, "chart")
    addToggle(SingleProgram, this, "programs")
    addToggle(SingleProgram, this, "records")
    autobind(SingleProgram, this)
  }

  async componentDidMount() {
    const { date_start, date_end } = this.state
    const { match: { params: { id } }, program, isSub } = this.props

    let program_data = { id }
    if (isSub) {
      this.setState({ loading: true })
      program_data = { id: program.id }
      const response = await getPrograms({ id: program.id, date_start, date_end })
      const { data: { info: programs } } = response
      this.setState({ program: programs[0], loading: false, insides: [programs[0]?.name] })
    } else {
      this.setState({ loading: true })
      const response = await getPrograms({ id, date_start, date_end })
      const { data: { info: programs } } = response
      this.setState({ program: programs[0], loading: false, insides: [programs[0]?.name] })
    }

    this.setState({ loadingData: true })
    const [totalPoints, maxPoints] = await this.recursivelyCalculateScore(program_data, true, {})
    this.setState({ totalPoints, maxPoints, loadingData: false })
  }

  async recursivelyCalculateScore(program_data, final, resumeData = {}) {
    if (!program_data) return 0
    const { date_start, date_end } = this.state
    const response = await getPrograms({ id: program_data.id, date_start, date_end })
    const { data: { info: programs } } = response
    const program = programs[0]
    resumeData[program.name] = {}

    const items = program?.program_items || []

    const defaultScore = !!items.length ? parseInt(program.compliance) : 0

    const registerPoints = items.map(item => this.handleGetCompliance(item.records, item) === 100).every(Boolean) ? parseInt(program?.compliance) : 0
    const subprograms = program?.child_programs
    const calculatePromises = subprograms.map(async subprogram => {
      const [subprogramPoints, subDefaultScore] = await this.recursivelyCalculateScore(subprogram, false, resumeData[program.name])
      return [subprogramPoints, subDefaultScore]
    })
    const programPoints = await Promise.all(calculatePromises)
    const actualPoints = registerPoints + programPoints.map(x => x[0]).reduce((x, y) => parseInt(x) + parseInt(y), 0)
    const defaultPoints = defaultScore + programPoints.map(x => x[1]).reduce((x, y) => parseInt(x) + parseInt(y), 0)


    resumeData[program.name].score = actualPoints

    final && this.setState({ resumeData })
    return [actualPoints, defaultPoints]
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } }, isSub, date_end, date_start } = this.props
    const { match: { params: { id: prevId } } } = prevProps
    const { date_start: ds, date_end: de } = this.state
    if ((!!date_start && !!date_end) && (date_start !== ds || date_end !== de)) {
      this.setState({ date_end, date_start }, this.componentDidMount)
    }

    if (isSub) return null
    if (id !== prevId) {
      this.componentDidMount()
    }
  }

  handleChangeDates(dates) {
    const { date_end, date_start } = dates
    this.setState({ date_end, date_start }, this.componentDidMount)
  }

  handleGetCompliance(values, element) {
    const { date_end, date_start } = this.state
    const days = moment(date_end).diff(moment(date_start), "days") + 1
    const validRegisters = this.handleCheckCompliance(values, element)
    const expectedAmount = element.frequency !== "0" ? Math.floor(days / parseInt(element.frequency, 10)) : values.length
    return validRegisters < expectedAmount ? 0 : 100
  }

  handleClickReport(item) {
    if (!item.length) return null
    const index = item[0]._index
    const { insides, resumeData } = this.state
    let actualLevel = resumeData
    insides.forEach(inside => {
      actualLevel = actualLevel[inside] || {}
    })
    const labels = Object.keys(actualLevel).filter(l => l !== "score").sort((a, b) => {
      if (a === b) return 0
      return a > b ? 1 : -1
    })
    const newLevel = labels[index]
    insides.push(newLevel)
    this.setState({ insides })
  }

  handleOutside() {
    const { insides } = this.state
    insides.pop()
    this.setState({ insides })
  }

  handleCheckCompliance(values, element) {
    switch (element.record_type_id) {
      case 1: {
        return values.filter(value => value.status !== "Vencido").length
      }
      case 2: {
        return values.filter(value => value.status !== "Vencido").length
      }
      case 3: {
        return values.filter(value => value.date_saved !== "0000-00-00 00:00:00").length
      }
      case 4: {
        return values.filter(value => value.date_saved !== "0000-00-00 00:00:00").length
      }
      case 5: {
        return values.length
      }
      case 6: {
        return values.length
      }
      default: {
        return values.length
      }
    }
  }

  renderRecords() {
    const { program, date_start, date_end } = this.state
    const { classes } = this.props
    const { getRecordTypeName, getRecordName } = this.context

    const days = moment(date_end).diff(moment(date_start), "days") + 1

    const items = program.program_items || []

    const recordTableInfo = [
      { name: "Tipo", label: "record_type_id", format: (value) => getRecordTypeName(value) },
      { name: "Nombre de Registro", label: "record_id", format: (value, element) => getRecordName(element.record_type_id, value) },
      { name: "Totales (T)", label: "records", format: (value) => value?.length },
      { name: "En Cumplimiento (EC)", label: "records", format: this.handleCheckCompliance },
      { name: "Esperados", label: "frequency", format: (value) => value !== "0" ? Math.floor(days / parseInt(value, 10)) : "T = EC" },
      { name: "Frecuencia", label: "frequency", format: (value) => frequencies.find(f => String(f.value) === value)?.label },
      { name: "Cumplimiento", label: "records", format: this.handleGetCompliance, render: NumberRender }
    ]

    return (
      <div className={classes.recordTable}>
        <GeneralTable data={items} info={recordTableInfo} scrollable disableSearch innerCard={InnerRecordCard} />
      </div>
    )
  }

  render() {
    const { classes, isSub } = this.props
    const {
      program,
      loading,
      openRecords,
      openPrograms,
      date_start,
      date_end,
      totalPoints,
      maxPoints,
      resumeData,
      insides,
      openChart,
      loadingData
    } = this.state
    const subprograms = program.child_programs || []
    const items = program.program_items || []
    const registerPoints = items.map(item => this.handleGetCompliance(item.records, item) === 100).every(Boolean) ? program?.compliance : 0
    const hasRegisters = !!items.length
    const hasSubprograms = !!subprograms.length
    const hasBoth = hasRegisters && hasSubprograms
    const offsetValue = hasBoth ? registerPoints : 0
    let actualLevel = resumeData || {}
    insides.forEach(inside => {
      actualLevel = actualLevel[inside] || {}
    })
    const labels = Object.keys(actualLevel).filter(l => l !== "score").sort((a, b) => {
      if (a === b) return 0
      return a > b ? 1 : -1
    })
    const finalData = labels.map(label => ({ label, value: actualLevel[label].score, color: "#202d4c99" }))
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} loadingMessage="Cargando información..." />
        <div className={classes.top}>
          <div>
            {!isSub && <Typography variant="h1" style={{ marginBottom: 6 }} >{program?.name}</Typography>}
            {hasRegisters && <Typography
              variant='h4'
              style={{ marginBottom: 24 }}
            >
              {`Puntaje${hasBoth ? " Registros" : ""}: ${registerPoints}/${program?.compliance}`}
            </Typography>
            }
            {hasSubprograms &&
              <Typography
                variant='h4'
                style={{ marginBottom: 24 }}
              >
                {`Puntaje${hasBoth ? " Programas" : ""}: ${totalPoints - registerPoints}/${maxPoints - offsetValue}`}
              </Typography>
            }
            {!isSub && <div style={{ marginBottom: 12 }} ><DateIntervalSelector onDateChange={this.handleChangeDates} /></div>}
          </div>
          <div>
            {!isSub &&
              <div style={{ margin: '12px 0', padding: 12, borderRadius: 8, background: 'white' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {insides.length > 1 &&
                      <IconButton onClick={this.handleOutside}>
                        <KeyboardArrowLeft />
                      </IconButton>
                    }
                    <Typography variant='h4'>{`Reportes programa ${insides.map((inside, index) => {
                      if (index > 0) return `> ${inside}`
                      return `${inside}`
                    }).join(" ")
                      }`}</Typography>
                  </div>
                  {loadingData ?
                    <MiniLoaderAnimator />
                    :
                    <IconButton onClick={this.handleOpenChart} disabled={!Object.keys(finalData).length}>
                      <BarChartOutlined />
                    </IconButton>}
                </div>
                {!!Object.keys(finalData).length &&
                  <Collapse in={openChart}>
                    <div style={{ marginTop: 12 }}>
                      <BarChart name="reporte-programa" title={`Resumen del programa ${program?.name}`} data={finalData}
                        showTitle={false}
                        options={{
                          minValue: 0,
                          maxValue: maxPoints || 0,
                          barThickness: 16,
                          enableTooltip: true,
                          height: 400,
                          stepSize: 100,
                          onClick: this.handleClickReport,
                          yEnableGrid: true,
                        }}
                      />
                    </div>
                  </Collapse>
                }
              </div>
            }
          </div>
        </div>
        {!!items.length ?
          <>
            <div className={classes.header} onClick={this.handleOpenRecords}>
              <ToggleIcon open={openRecords} />
              <Typography variant='h4'>Registros y formularios</Typography>
            </div>
            <Collapse in={openRecords}>
              {this.renderRecords()}
            </Collapse>
          </>
          :
          <div>
            <Typography variant="caption">No posee registros...</Typography>
          </div>
        }
        {!!subprograms.length &&
          <>
            <Divider style={{ margin: '24px 0' }} />
            <div className={classes.header} onClick={this.handleOpenPrograms}>
              <ToggleIcon open={openPrograms} />
              <Typography variant="h4">Programas asociados</Typography>
            </div>
            <Collapse in={openPrograms}>
              <div className={classes.programs}>
                {
                  subprograms.map(subprogram => {
                    return <SuperProgramCard key={subprogram.id} program_info={subprogram} date_start={date_start} date_end={date_end} />
                  })
                }
              </div>
            </Collapse>
          </>
        }
      </div>
    )
  }
}

SingleProgram.contextType = ProgramContext

export default withRouter(withStyles(style)(SingleProgram))