import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { createSettingsProgram, editSettingsProgram, getSettingPrograms } from '../../API/programs'
import { Edit, KeyboardArrowRight } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import NewProgram from './NewProgram'
import LoadingDialog from '../../Shared/LoadingDialog'
import GeneralTable from '../../Shared/GeneralTable'
import AddElementButton from '../../Shared/AddElementButton'
import { withRouter } from 'react-router-dom'

const style = () => ({
  programs: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 12
  },
  program: {
    background: 'white',
    height: 60,
    width: 100,
    borderRadius: 10,
    padding: 12,
    position: 'relative',
    margin: 6
  },
  addButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24
  }
})

const tableInfo = [
  { label: "name", name: "Nombre" },
  // { label: 'date_start', name: "Fecha de Inicio" },
  // { label: 'date_end', name: "Fecha de Término" },
  // { label: "branch_id", name: "Centro de trabajo" },
  { label: "compliance", name: "Puntaje" },
]

class Programs extends Component {
  constructor() {
    super()
    this.state = {
      programs: [],
      openCreate: false,
      openEdit: false,
      loading: false,
      selectedProgram: null
    }
    autobind(Programs, this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.reload()
    this.setState({ loading: false })
  }

  async reload() {
    const response = await getSettingPrograms()
    const { info: programs } = response.data
    this.setState({ programs })
  }

  async handleCreate(body) {
    this.setState({ loading: true })
    await createSettingsProgram(body)
    await this.reload()
    this.setState({ loading: false })
  }

  async handleEdit(body) {
    console.log(body)
    this.setState({ loading: true })
    await editSettingsProgram(body)
    await this.reload()
    this.setState({ loading: false })
  }

  handleOpenProgram(row) {
    return () => {
      const { history } = this.props
      history.push(`/programs/${row.id}`)
    }
  }

  // async handleDelete(body) {
  //   this.setState({ loading: true })
  //   await deleteProgr(body)
  //   await this.reload()
  //   this.setState({ loading: false })
  // }

  handleOpenCreate() { this.setState({ openCreate: !this.state.openCreate }) }
  handleOpenEdit(row) { return () => { this.setState({ openEdit: !this.state.openEdit, selectedProgram: row }) } }

  render() {
    const { classes } = this.props
    const { programs, openCreate, loading, openEdit, selectedProgram } = this.state
    console.log(programs)
    const actions = [
      { name: "Editar programa", action: this.handleOpenEdit, icon: Edit },
      { name: "Ver programa", action: this.handleOpenProgram, icon: KeyboardArrowRight },
    ]
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <NewProgram open={openCreate} onClose={this.handleOpenCreate} onSubmit={this.handleCreate} />
        <NewProgram edit={selectedProgram} open={openEdit} onClose={this.handleOpenEdit()} onSubmit={this.handleEdit} />
        <div className={classes.inline}>
          <Typography variant="h1">Programas</Typography>
          <AddElementButton onClick={this.handleOpenCreate} name="Agregar programa" open={openCreate} />
        </div>
        <GeneralTable
          info={tableInfo}
          data={programs}
          actions={actions}
          scrollable
        />
      </div>
    )
  }
}

export default withRouter(withStyles(style)(Programs))