import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import { FiChevronRight } from 'react-icons/fi'
import moment from 'moment'
import autobind, { addToggle } from '../../../Utils/autobind'
import ExtraFieldDialog from '../../Finding&Conformities/ExtraFieldDialog'
import newTheme from '../../../newTheme'

const style = theme => ({
  floatingButton: {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: 'translate(0, -50%)',
    '& > *': {
      color: theme.palette.red.main,
      '& > * > *': {
        height: 24,
        width: 24
      }
    }
  },
  pending: {
    // padding: 24,
    margin: "12px 0",
    maxWidth: 700,
    position: 'relative',
    border: `2px solid ${theme.palette.red.main}`,
    borderRadius: 18,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between'
  },
  pendingTitle: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 3
  },
  pendingDate: {
    textAlign: 'left',
    marginTop: 6,
    position: 'absolute',
    bottom: 12,
    '& > *': {
      fontWeight: 600,
      fontSize: 14
    }
  },
  pendingContent: {
    maxWidth: "90%",
    textAlign: 'justify',
    lineHeight: 1.75,
    marginBottom: 12,
  },
  todayContainer: {
    borderColor: theme.palette.green.main
  },
  today: {
    '& > *': {
      color: theme.palette.green.main
    }
  },
  todayButton: {
    background: theme.palette.green.main,
    '& > *': {
      background: theme.palette.green.main,
      color: 'white'
    }
  },
  dueContainer: {
    borderColor: theme.palette.red.main
  },
  due: {
    '& > *': {
      color: theme.palette.red.main
    }
  },
  dueButton: {
    background: theme.palette.red.main,
    '& > *': {
      background: theme.palette.red.main,
      color: 'white'
    }
  },
  nextContainer: {
    borderColor: theme.palette.blue.main
  },
  next: {
    '& > *': {
      color: theme.palette.blue.main,
    }
  },
  nextButton: {
    background: theme.palette.blue.main,
    '& > *': {
      background: theme.palette.blue.main,
      color: 'white'
    }
  },
  goButton: {
    width: 96,
    borderRadius: "0 12px 12px 0 ",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  pendingInfo: {
    padding: 12,
    paddingBottom: 24,
    flexGrow: 1,
    position: 'relative'
  },
  tags: {
    position: 'absolute',
    top: 6,
    right: 6
  },
  tag: {
    padding: '3px 8px',
    borderRadius: 16,
    textAlign: 'center',
    '& > *': {
      color: 'white',
      '@media (max-width:650px)': {
        position: 'absolute',
        opacity: 0
      }
    },
    margin: '3px 0',
    minWidth: 70,
    '@media (max-width:650px)': {
      width: 12,
      height: 12,
      padding: 3,
      minWidth: 0,
      borderRadius: '50%'
    }
  },
  subdivision: {
    color: newTheme.palette.blue.main,
    fontWeight: 600,
    marginBottom: 12
  }
})

function getStatus(pending) {
  if (moment(new Date()).isAfter(moment(pending.date), "days")) return "Vencido"
  if (moment(new Date()).isBefore(moment(pending.date), "days")) return "Proximo"
  return "Hoy"
}

class Pending extends Component {
  constructor() {
    super()
    addToggle(Pending, this, "more")
    autobind(Pending, this)
  }
  getStatusClasses() {
    const { pending, classes } = this.props
    const status = getStatus(pending)
    console.log(status)
    switch (status) {
      case "Vencido": {
        return {
          pending: `${classes.pending} ${classes.dueContainer}`,
          pendingTitle: `${classes.pendingTitle} ${classes.due}`,
          pendingContent: `${classes.pendingContent} ${classes.due}`,
          pendingDate: `${classes.pendingDate} ${classes.due}`,
          floatingButton: `${classes.goButton} ${classes.dueButton}`,
        }
      }
      case "Hoy": {
        return {
          pending: `${classes.pending} ${classes.todayContainer}`,
          pendingTitle: `${classes.pendingTitle} ${classes.today}`,
          pendingContent: `${classes.pendingContent} ${classes.today}`,
          pendingDate: `${classes.pendingDate} ${classes.today}`,
          floatingButton: `${classes.goButton} ${classes.todayButton}`,
        }
      }
      case "Proximo": {
        return {
          pending: `${classes.pending} ${classes.nextContainer}`,
          pendingTitle: `${classes.pendingTitle} ${classes.next}`,
          pendingContent: `${classes.pendingContent} ${classes.next}`,
          pendingDate: `${classes.pendingDate} ${classes.next}`,
          floatingButton: `${classes.goButton} ${classes.nextButton}`,
        }
      }
      default: {
        return {
          pending: `${classes.pending} ${classes.dueContainer}`,
          pendingTitle: `${classes.pendingTitle} ${classes.due}`,
          pendingContent: `${classes.pendingContent} ${classes.due}`,
          pendingDate: `${classes.pendingDate} ${classes.due}`,
          floatingButton: `${classes.goButton} ${classes.dueButton}`,
        }
      }
    }
  }

  render() {
    const { pending, onClick, classes } = this.props
    const { openMore } = this.state
    const ownClasses = this.getStatusClasses()
    const isChecklist = pending.name === "Registro o Formulario"
    const isFinding = pending.name.includes("Hallazgo")
    const tags = isChecklist ? pending?.info?.tags : []
    const dateFormat = (isChecklist || isFinding) ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"
    const subdivision = isChecklist ? pending?.info?.subdivision?.name : null
    return (
      <div key={pending.link + pending.name + pending.content + pending.date} className={ownClasses.pending}>
        <div className={classes.pendingInfo} onClick={this.handleOpenMore}>
          <Typography variant="h2" className={ownClasses.pendingTitle}>{pending.name}</Typography>
          <Typography variant="body1" className={ownClasses.pendingContent}>{pending.content}</Typography>
          {subdivision && <Typography variant="body1" className={classes.subdivision}>{subdivision}</Typography>}
          <div className={ownClasses.pendingDate}>
            <Typography variant="h2" >{moment(pending.date).isValid() ? moment(pending.date).format(dateFormat) : "Sin fecha asignada"}</Typography>
          </div>
          {isChecklist &&
            <div className={classes.tags}>
              {tags.map(tag => {
                return (
                  <div className={classes.tag} style={{ background: tag.color }}>
                    <Typography variant='subtitle1'>{tag.name}</Typography>
                  </div>
                )
              })}
            </div>
          }
        </div>
        <div className={ownClasses.floatingButton} onClick={onClick(pending.link)}>
          <IconButton size="small">
            <FiChevronRight />
          </IconButton>
        </div>
        <ExtraFieldDialog
          open={openMore && isChecklist}
          onClose={this.handleOpenMore}
          title={"Información extra"}
          fields={pending?.info?.extra_fields}
        />
      </div>
    )
  }
}

export default withStyles(style)(Pending)