import { Box, Button, Paper, Typography } from '@mui/material'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getChecklist, getChecklistNew, setChecklistSupply } from '../../API/checklists'
import { changeMetadata, editChecklist, finishChecklist } from '../../API/new_checklists'
import { notifyAuthorization, notifyEndChecklist } from '../../API/notifications'
import AppContext from '../../AppContext'
import SeamlessInput from '../../Shared/Inputs/SeamlessInput'
import { signAuthorization } from '../../API/authorizations'
import TextInput from '../../Shared/Inputs/TextInput'
import { filterActiveWorkers } from '../../Utils/filters'
import { callSnackbar } from '../../Utils/snackbar'
import Authorizations from './Authorizations'
import ChecklistStructure from './ChecklistStructure'
import Comments from './Comments'
import ContentSelector from './ContentSelector'
import MainInfoCard from './MainInfoCard'
import SecondaryInfo from './SecondaryInfo'
import ChecklistSupplies from './ChecklistSupplies'
import ChecklistPoints from './ChecklistPoints'
import ComplianceAndProgress from './ComplianceAndProgress'
import ChecklistHistory from './ChecklistHistory'
import { ChecklistManagerContext } from '../../DatabaseManagers/ChecklistsManager'
import { RequestManagerContext } from '../../DatabaseManagers/RequestsManager'
import moment from 'moment'

const css = {
	container: {
		margin: 2,
		display: 'flex',
		gap: 4,
		flexWrap: 'wrap',
		position: 'relative',
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end'
	},
	mainContainer: {
		maxWidth: 800
	},
	end_button: {
		maxWidth: 800,
		textAlign: 'end'
	},
	paper: {
		padding: 2,
		margin: '12px 0'
	},
	leftContainer: {
		maxWidth: 800,
		flexBasis: 800,
		flexGrow: 1
	},
	rightContainer: {
		maxWidth: 800,
		flexBasis: 400,
		flexGrow: 1,
	}
}

export const ChecklistContext = createContext({})

function CheckIfItemsAreAnswered(checklist) {
	const items = checklist.checklist_items2 || []
	const answers = items.map(item => item.answer)
	const areAllAnswered = answers.map(answer => !!answer.value).every(Boolean)
	return areAllAnswered
}

function CheckIfFieldsAreAnswered(checklist) {
	const fields = checklist.checklist_fields || []
	const allRequiredAnswered = fields.filter(field => field.required).map(field => field.value !== "").every(Boolean)
	return allRequiredAnswered
}


function ChecklistView({ match }) {

	const [checklist, setChecklist] = useState({})
	const [observations, setObservations] = useState("")
	const { account: { user }, online: { status: online } } = useContext(AppContext)
	const { getChecklist, addChecklist } = useContext(ChecklistManagerContext)
	const { addRequest } = useContext(RequestManagerContext)

	const isAdmin = user.userType === 1
	const isReponsible = user.id === checklist.user_id
	const workers = filterActiveWorkers(false)
	const responsible = workers.find(worker => String(worker.id) === String(checklist.user_id))

	useEffect(() => {
		async function fetchData() {
			const id = match.params.id
			if (online) {
				const response = await getChecklistNew({ id })
				setChecklist(response.data.info)
			} else {
				const response = await getChecklist(id)
				console.log("GETTING OFFLINE")
				setChecklist(response || {})
			}
		}

		fetchData()
	}, [])

	useEffect(() => {
		setObservations(checklist.observations)
	}, [checklist])

	function setAsyncChecklist(checklist) {
		addChecklist(checklist)
		setChecklist(checklist)
		console.log(checklist)
	}

	async function setMetadata(metadata) {
		const body = {
			id: checklist.id,
			metadata
		}
		const newChecklist = { ...checklist }
		const response = await changeMetadata(body)
		newChecklist.metadata = response.data.info
		setChecklist(newChecklist)
	}

	async function changeObservation() {
		const body = { ...checklist, observations }
		if (!online) {
			addRequest({ name: "Cambio observación registro", method: editChecklist(body, true) })
			return setAsyncChecklist(body)
		}
		const response = await editChecklist(body)
		setAsyncChecklist(response.data.info)
	}

	async function onFinish() {
		const body = { id: checklist.id }
		const new_checklist = {
			...checklist,
			date_done: moment().format("YYYY-MM-DD"),
			time_done: moment().format("HH:mm")
		}
		if (!online) {
			addRequest({ name: "Realizar registro", method: finishChecklist(body, true) })
			return setAsyncChecklist(new_checklist)
		}
		const response = await finishChecklist(body)
		setAsyncChecklist(response.data.info)
	}

	async function editSupply(body) {
		const response = await setChecklistSupply(body)
		setAsyncChecklist(response.data.info)
	}

	async function onSignAuthorization(body) {
		const { data, notify, index, last } = body
		const response = await signAuthorization(data)
		if (notify && !last) {
			const next_authorization = checklist.authorizations[index + 1]
			next_authorization.users.forEach(user => {
				notifyAuthorization(user, window.location.href, checklist.preventive_measure, checklist)
			})
			callSnackbar("Notificación de avance enviada", "info")
		}
		if (notify && last) {
			const users_to_notify_end = checklist.notified_users
			console.log(users_to_notify_end)
			users_to_notify_end.forEach(user => {
				notifyEndChecklist(user, window.location.href, checklist.preventive_measure, checklist)
			})
			callSnackbar("Notificación de términio enviada", "info")
		}
		setChecklist(response.data.info)
	}



	const itemsReady = useMemo(() => CheckIfItemsAreAnswered(checklist), [checklist])
	const fieldsReady = useMemo(() => CheckIfFieldsAreAnswered(checklist), [checklist])
	const finished = useMemo(() => checklist.date_done !== "0000-00-00", [checklist])
	const checklist_ready = itemsReady && fieldsReady


	return (
		<Box sx={css.container}>
			<ChecklistContext.Provider value={{
				checklist,
				setChecklist: setAsyncChecklist,
				setMetadata,
				isAdmin,
				isReponsible,
				finished,
				responsible,
				current_user: user,
			}} >
				<Box sx={css.rightContainer}>
					<ChecklistHistory />
					<ComplianceAndProgress />
				</Box>
				<Box sx={css.leftContainer}>
					<Box sx={css.mainContainer}>
						<MainInfoCard />
						<ChecklistStructure />
						<ChecklistSupplies onEdit={editSupply} />
						<SecondaryInfo />
						<ContentSelector />
						<Paper sx={css.paper}>
							<Typography variant='subtitle1'><strong>Observaciones</strong></Typography>
							{finished ?
								<Typography variant='subtitle1'>{observations}</Typography> :
								<TextInput onBlur={changeObservation} label="" value={observations} onChange={e => setObservations(e.target.value)} />
							}
						</Paper>
						<ChecklistPoints />

					</Box>

					{!finished &&
						<Box sx={css.end_button}>
							<Button color="primary" variant="contained" disabled={!checklist_ready} onClick={onFinish}>
								Finalizar Registro
							</Button>
						</Box>
					}
					{finished &&
						<Box>
							<Authorizations onSign={onSignAuthorization} />
							<Comments />
						</Box>
					}
				</Box>
			</ChecklistContext.Provider>
		</Box>
	)
}

export default withRouter(ChecklistView)