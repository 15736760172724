import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline, KeyboardArrowRight } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import { withRouter } from 'react-router'
import TextInput from '../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../Shared/SubmitButton'

const style = () => ({
  container: {
    margin: '24px 0',
    marginTop: 48
  },
  process: {
    padding: 12,
    background: 'white',
    position: 'relative',
    borderRadius: 12,
    margin: '12px 0',
    maxWidth: 500,
    display: 'flex',
    minHeight: 80,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *:first-child > *:first-child': {
      fontWeight: 600
    }
  },
  processCard: {
    padding: 12,
    background: 'white',
    position: 'relative',
    borderRadius: 12,
    margin: '12px 0',
    maxWidth: 500,
    display: 'flex',
    minHeight: 80,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    '& > *:first-child > *:first-child': {
      fontWeight: 600
    },
    cursor: 'pointer'
  },
  invprocess: {
    background: 'white',
    position: 'relative',
    borderRadius: 12,
    margin: '12px 0',
    maxWidth: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'lightgrey',
    height: 48,
    width: 48,
    cursor: 'pointer',
    transition: 'all 0.2s linear'
  },
  processText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  processIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
})

class Accidents extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      open: false,
    }
    autobind(Accidents, this)
  }

  handleOpenProcess(process) {
    return () => {
      const { history } = this.props
      history.push(`/settings/processes/${process}`)
    }
  }

  handleCreate() {
    this.setState({ open: !this.state.open })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ name: target.value })
  }

  handleSubmit() {
    const { name } = this.state
    const { onCreate, type } = this.props
    const body = {
      name,
      workflow_type_id: type
    }
    onCreate(body)
    this.setState({ name: "", open: false })
  }

  renderProcesses() {
    const { classes, workflows, type } = this.props
    return workflows.filter(wf => wf.workflow_type_id === type).map(process => (
      <>
        <div className={classes.processCard} onClick={this.handleOpenProcess(process.id)}>
          <div className={classes.processText}>
            <Typography variant="subtitle1">{`Proceso ${process.name}`}</Typography>
            <Typography variant="caption">Etapas: {process.workflow_steps.length}</Typography>
          </div>
          <div className={classes.processIcon}>
            <KeyboardArrowRight />
          </div>
        </div>
      </>
    ))
  }
  render() {
    const { classes } = this.props
    const { open, name } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h4">Procesos de esta categoría</Typography>
        <div className={classes.process} style={{ height: 130 }}>
          <Collapse in={open} style={{ width: '100%' }}>
            <div>
              <TextInput label="Nombre Proceso" value={name} onChange={this.handleChange} />
              <SubmitButton onClick={this.handleSubmit}>
                Crear
              </SubmitButton>
            </div>
          </Collapse>
          <div style={{ width: '100%', height: '100%' }} onClick={this.handleCreate} >
            <AddCircleOutline
              className={classes.center}
              style={{ left: open ? "75%" : "50%", transform: open ? "translate(-50%, -50%) rotate(45deg)" : "translate(-50%, -50%) rotate(0deg)" }}
              onClick={this.handleCreate} />
          </div>
        </div>
        {this.renderProcesses()}
      </div >
    )
  }
}

export default withRouter(withStyles(style)(Accidents))