import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { createSupply, editSupply, getSettingsSupplies, getSupplies } from '../../API/supplies'
import AppContext from '../../AppContext'
import useToggle from '../../Hooks/ToogleHook'
import AddElementButton from '../../Shared/AddElementButton'
import GeneralTable from '../../Shared/GeneralTable'
import SelectInput from '../../Shared/Inputs/SelectInput'
import TextInput from '../../Shared/Inputs/TextInput'
import Chip from '../Checklists/Chip'
import Supply from './Supply'

const css = {
	container: {
		margin: 2
	},
	dialog: {
		padding: 3
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	button: {
		textAlign: 'end'
	}
}

const tableInfo = [
	{ label: 'date_scheduled', name: "Fecha de Programación" },
	{ label: "preventive_measure", name: "Registro o formulario" },
	{ label: "user", name: "Responsable" },
	{ label: "compliance", name: "Cumplimiento", format: (value) => `${value}%` },
	{ label: "progress", name: "Avance", format: (value) => `${value}%` },
	{ label: "status", name: "Estado", render: Chip },
]

const supply_info = [
	{ label: 'code', name: "Código" },
	{ label: 'model', name: "Modelo" },
	{ label: 'brand', name: "Marca" },
	{ label: 'type', name: "Tipo" },
	{ label: 'family', name: "Familia" },

]

function SuppliesView() {

	const [supplies, setSupplies] = useState([])
	const [families, setFamilies] = useState([])
	const [openCreate, toggleCreate] = useToggle(false)
	const [params, setParams] = useState({})
	const { history } = useContext(AppContext)

	useEffect(() => {
		async function fetchData() {
			const response = await Promise.all([getSettingsSupplies(), getSupplies()])
			const [r_families, r_supplies] = response.map(r => r.data.info)
			setSupplies(r_supplies)
			setFamilies(r_families)

		}

		fetchData()
	}, [])

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	async function onCreate() {
		const body = { ...params }
		const response = await createSupply(body)
		const { data: { info } } = response
		setSupplies(info)
		setParams({})
		toggleCreate()
	}

	const family_options = families.map(fam => ({ label: fam.name, value: fam.id }))
	const type_options = params.family_id ? families.find(f => f.id === params.family_id).supply_types.map(st => ({ label: st.name, value: st.id })) : []

	const actions = [
		{ name: "Ver insumo", icon: KeyboardArrowRight, action: (value) => () => history.push(`/supplies/${value.id}`) }
	]

	return (
		<Box sx={css.container}>
			<Box sx={css.header}>
				<Typography variant='h1'>Insumos y Maquinarias</Typography>
				<AddElementButton name="Crear insumo" open={openCreate} onClick={toggleCreate} />
			</Box>
			{!supplies.length && <Typography variant='subtitle1'>No hay insumos creados para este centro de trabajo</Typography>}
			<Dialog open={openCreate} onClose={toggleCreate} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography variant='h4'>Crear nuevo insumo</Typography>
					<SelectInput value={params.family_id} onChange={onChange} name="family_id" label="Familia" options={family_options} />
					<SelectInput value={params.supply_type_id} onChange={onChange} name="supply_type_id" label="Tipo de insumo" options={type_options} />
					<TextInput value={params.code} onChange={onChange} name="code" label="Código" />
					<TextInput value={params.model} onChange={onChange} name="model" label="Modelo" />
					<TextInput value={params.brand} onChange={onChange} name="brand" label="Marca" />
					<Box sx={css.button}>
						<Button color="primary" variant="contained" size="small" onClick={onCreate}
							disabled={
								!params.family_id ||
								!params.supply_type_id ||
								!params.code
							}>
							Crear Insumo
						</Button>
					</Box>
				</Box>
			</Dialog>
			<GeneralTable info={supply_info} data={supplies} actions={actions} />
		</Box>
	)
}

export default SuppliesView