import { AccountCircleOutlined, AddAPhotoOutlined, Check, Clear, Delete, ErrorOutline, PersonAdd, SupervisedUserCircle } from "@material-ui/icons"
import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import moment from "moment"
import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { answerChecklistItem, assignFindingToItem, createItemFinding } from "../../../API/new_checklists"
import AppContext from "../../../AppContext"
import { ChecklistManagerContext } from "../../../DatabaseManagers/ChecklistsManager"
import { RequestManagerContext } from "../../../DatabaseManagers/RequestsManager"
import useToggle from "../../../Hooks/ToogleHook"
import useChangeParams from "../../../Hooks/UseStateChangeParams"
import newTheme from "../../../newTheme"
import ImageContainer from "../../../Shared/ImageContainer"
import DateTimeInput from "../../../Shared/Inputs/DateTimeInput"
import SeamlessInput from "../../../Shared/Inputs/SeamlessInput"
import TextInput from "../../../Shared/Inputs/TextInput"
import UsersInput from "../../../Shared/Inputs/UsersInput"
import { end_buttons, inline_space, text_space } from "../../../Utils/defaultStyles"
import { getBase64Image, transformOfflineRequestToOnline } from "../../../Utils/functions"
import { ChecklistContext } from "../ChecklistView"
import CritMenu from "./Item/CritMenu"
import ItemFindingForm from "./Item/ItemFindingForm"

const css = {
	container: {
		padding: 2
	},
	item: {
		background: 'white',
		borderRadius: 2,
		padding: 2,
		margin: '12px 0'
	},
	option_buttons: {
		display: 'flex',
		flexDirection: 'row',
		gap: 2,
		'@media (max-width:600px)': {
			flexDirection: 'column',
		}
	},
	item_header: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	},
	images: {
		display: 'flex',
		alignItems: 'center',
		gap: 2,
		justifyContent: 'flex-end',
		margin: '12px 0'
	},
	imageContainer: {
		borderRadius: 2,
		border: '1px dashed black',
		height: 68,
		width: 68,
		position: 'relative'
	},
	image_button: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	delete_image_button: {
		position: 'absolute',
		left: '65%',
		top: '65%',
		background: 'white',
		opacity: 0.8
	},
	option_button: {
		padding: "8px 24px"
	},
	criticality: {
		margin: '12px 0',
		textAlign: 'end',
		'& > button': {
			borderRadius: 24
		}
	},
	user: {
		padding: 1
	}
}

const option_buttons = [
	{ name: <Check />, color: "success", value: 1 },
	{ name: <Clear />, color: "error", value: 2 },
	{ name: "N/A", color: "info", value: 3 },
]
const files = ["file", "file2", "file3"]
const crit_names = ["Seleccionar Criticidad", "No Crítico", "Poco Crítico", "Medianamente Crítico", "Altamente Crítico"]
const colors = ["primary", "info", "middle", "warning", "error"]

function Item({ item, disabled }) {

	const { setChecklist, checklist } = useContext(ChecklistContext)
	const { addRequest } = useContext(RequestManagerContext)
	const { online: { status: online } } = useContext(AppContext)
	const [params, setParams] = useState({})
	const [extra_params, setExtraInfo, initializeExtra] = useChangeParams({ date_limit: moment().format("YYYY-MM-DD HH:mm") })
	const file = useRef(null)
	const file2 = useRef(null)
	const file3 = useRef(null)
	const critMenu = useRef(null)
	const [openMenu, toggleMenu] = useToggle(false)
	const file_refs = { file, file2, file3 }
	const [openFinding, toggleFinding] = useToggle(false)

	useEffect(() => {
		setParams({ ...item.answer })
		const base_finding = item.findings[0]
		if (base_finding) {
			initializeExtra({
				date_limit: base_finding.date_limit,
				inmediate_action: base_finding.inmediate_action,
				user_id: base_finding.user_id
			})
		}
	}, [item])

	useEffect(() => {
		if (item.answer.value_id === 2) { addFinding(true) }
	}, [item])

	function answerChecklistItemOffline(body) {
		const new_items = [...checklist.checklist_items2]
		const new_item_index = new_items.findIndex(i => i.id === item.id)
		const new_item = { ...item, answer: body }
		new_items[new_item_index] = new_item
		const new_checklist = { ...checklist }
		new_checklist.checklist_items2 = new_items
		console.log(new_checklist)
		setChecklist(new_checklist)
		// if (body.value_id === 2) { addFinding(true, new_checklist) }
		addRequest({ name: "Respuesta item de registro", method: answerChecklistItem(body, true) })
	}

	function addFindingToItemOffline(finding, updated_checklist) {
		const new_checklist = updated_checklist ? { ...updated_checklist } : { ...checklist }
		const new_items = [...new_checklist.checklist_items2]
		const new_item_index = new_items.findIndex(i => i.id === item.id)
		const new_findings = [...item.findings, finding]
		const new_item = { ...item, findings: new_findings }
		new_items[new_item_index] = new_item
		new_checklist.checklist_items2 = new_items
		setChecklist(new_checklist)
		addRequest({ name: "Agregar nuevo hallazgo desde un item", method: createItemFinding(finding, true) })
	}

	async function updateChecklistAnswer(body) {
		if (!online) return answerChecklistItemOffline(body)
		const parsed_body = await transformOfflineRequestToOnline(body)
		const response = await answerChecklistItem(parsed_body)
		setChecklist(response.data.info)
	}

	async function answerItem(option) {
		const body = { ...item.answer, type: "answer" }
		body.value_id = option
		body.value = option > 0 ? ["SI", "NO", "N/A"][option - 1] : ""
		body.checklist_id = checklist.id
		body.criticality = parseInt(item.default_criticality, 10)
		await updateChecklistAnswer(body)
	}

	function onChange(e) { setParams(p => ({ ...p, [e.target.name]: e.target.value })) }

	async function onBlur() {
		const body = { ...item.answer, ...params }
		body.checklist_id = checklist.id
		await updateChecklistAnswer(body)
	}

	function uploadImage(name) {
		return async (e) => {
			const file = e.target.files[0]
			const image = await getBase64Image(file)
			setParams(p => ({ ...p, [name]: image }))
			const body = { ...params, [name]: image }
			body.checklist_id = checklist.id
			await updateChecklistAnswer(body)
		}
	}

	function deleteImage(name) {
		return async () => {
			const body = { ...params, [name]: null }
			body[`destroy_${name}`] = true
			body.checklist_id = checklist.id
			await updateChecklistAnswer(body)
		}
	}

	function onCrit(value) {
		return async () => {
			const body = { ...params }
			body.criticality = value
			body.checklist_id = checklist.id
			await updateChecklistAnswer(body)
			toggleMenu()
		}
	}

	async function addFinding(first = false, updated_checklist = null) {
		const new_finding = {
			checklist_item_id: item.id,
			checklist_id: checklist.id,
			owner_id: checklist.user_id,
			checklist_index: item.findings.length,
			branch_id: checklist.branch_id,
			free_criticality: item.answer.criticality || 0,
			free_description: item.name,
		}
		if (!first || (first && !item.findings.length)) {
			if (!online) return addFindingToItemOffline(new_finding, updated_checklist)
			const response = await createItemFinding(new_finding)
			setChecklist(response.data.info)
		}
	}

	async function updateFinding(body, index) {
		const new_findings = [...item.findings]
		const new_finding_index = new_findings.findIndex(finding => {
			return finding.checklist_index === body.checklist_index
		})
		new_findings[new_finding_index] = body
		const new_items = [...checklist.checklist_items2]
		const new_item_index = new_items.findIndex(i => i.id === item.id)
		const new_item = { ...item, findings: new_findings }
		new_items[new_item_index] = new_item
		const new_checklist = { ...checklist }
		new_checklist.checklist_items2 = new_items
		if (!online) {
			setChecklist(new_checklist)
			addRequest({ name: "Actualizar hallazgo desde item", method: assignFindingToItem(body, true) })
		} else {
			const response = await assignFindingToItem(await transformOfflineRequestToOnline(body))
			setChecklist(response.data.info)
		}
	}


	const answer = item.answer
	const criticality = !!params.criticality ? params.criticality : 0
	const isAnswered = !!answer.value_id
	const isNegativeAnswer = answer.value_id === 2
	const isPositiveAnswer = answer.value_id === 1
	const findings = item.findings || []
	const hasFindings = !!findings.length
	// if (hasFindings) { console.log(item) }

	return (
		<Box sx={css.item}>
			<Box sx={css.item_header}>
				<Typography variant='subtitle1'><strong>{item?.name}</strong>{` (${item?.points} Pts)`}</Typography>
				<Box sx={css.option_buttons}>
					{option_buttons.map(button =>
						<Button
							disabled={disabled}
							key={button.value}
							sx={css.option_button}
							onClick={() => answerItem(button.value)}
							color={button.color}
							variant={answer.value_id === button.value ? "contained" : "outlined"}
						>
							{button.name}
						</Button>
					)}
				</Box>
			</Box>
			{answer.value_id === 2 &&
				<Box sx={css.criticality}>
					<Button
						disabled={disabled}
						ref={critMenu}
						startIcon={<ErrorOutline />}
						variant="outlined"
						color={colors[criticality]}
						onClick={toggleMenu}>
						{crit_names[criticality]}
					</Button>
					<Menu open={openMenu} onClose={toggleMenu} anchorEl={critMenu.current}>
						<CritMenu onCrit={onCrit} />
					</Menu>
				</Box>
			}
			{isPositiveAnswer &&
				<Box sx={css.images}>
					{files.map(file_input => (
						<Fragment key={file_input}>
							<input accept="image/png, image/jpeg" type="file" hidden ref={file_refs[file_input]} onChange={uploadImage(file_input)} />
							<Box sx={css.imageContainer}>
								{!!params[file_input.replace("1", "")] && <ImageContainer height={68} width={68} border={'6px'} src={params[file_input.replace("1", "")] || "/noimage.png"} />}
								{!params[file_input.replace("1", "")] && <IconButton disabled={disabled} sx={css.image_button} onClick={() => file_refs[file_input].current.click()}>
									<AddAPhotoOutlined />
								</IconButton>}
								{!!params[file_input.replace("1", "")] &&
									<IconButton disabled={disabled}
										onClick={deleteImage(file_input)}
										size="small"
										sx={css.delete_image_button}
									>
										<Delete />
									</IconButton>}
							</Box>
						</Fragment>
					))}
				</Box>
			}
			{isNegativeAnswer &&
				<Box>
					<Box sx={inline_space}>
						<Typography variant='h4' sx={text_space}>Hallazgos asociados:</Typography>
						<Button onClick={() => addFinding()}>
							Agregar Nuevo hallazgo
						</Button>
					</Box>
					{findings.map(finding => <ItemFindingForm finding={finding} updateFinding={updateFinding} />)}
				</Box>
			}
			{isAnswered &&
				<Box>
					<Typography variant='subtitle1'>Comentarios:</Typography>
					<SeamlessInput disabled={disabled} onBlur={onBlur} name="comments" value={params.comments} onChange={onChange} />
				</Box>
			}
		</Box>
	)
}

export default Item