import React, { Component } from 'react'
import { Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import ImageContainer from '../../Shared/ImageContainer'

const style = () => ({
  container: {
    padding: 24
  },
  finishedField: {
    margin: '12px 0',
    '& > *': {
      margin: '6px 0',
      padding: '0 12px',
    },
    '& > h4': {
      padding: '0 12px 12px',
    }
  },
  multigrid: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexBasis: '30%',
      minWidth: 300
    }
  },
  extraField: {
    margin: '24px 6px',
    '& > *:first-child': {
      marginBottom: -6
    }
  },
  group: {
    padding: 12,
    borderRadius: 8,
    margin: '12px 0',
    position: 'relative'
  },
})

class ExtraFieldDialog extends Component {
  renderFinishedSubquestions(field) {
    const { classes } = this.props
    if (!field.options || field.options.length === 0) return null
    const selectedOption = field.options.find(option => option.label === field.value)
    if (!selectedOption) return null
    const { subquestions = [] } = selectedOption
    if (subquestions.length === 0) return null
    return subquestions.map(question => {
      return (
        <div style={{ paddingLeft: 12 }}>
          <div className={classes.finishedField}>
            <Typography variant="subtitle1">{`${question.label} ${!!question.required ? "*" : ""}`}</Typography>
            <Typography variant="h4">{question?.value || "Sin respuesta"}</Typography>
            {this.renderFinishedSubquestions(question)}
          </div>
        </div>
      )
    })
  }

  renderFinishedFields(fields) {
    const { classes } = this.props
    const extrafields = fields || []
    const categories = fields[0]?.categories || []

    const groups = {}
    extrafields.forEach(ef => {
      const efCategory = !!ef.category ? ef.category : false
      const categoryInfo = categories.find(cat => cat.value === efCategory)
      const groupIndex = ef.group_index || 0
      const groupName = `${efCategory}-${groupIndex}-${categoryInfo?.label}-${categoryInfo?.color}`
      if (Object.keys(groups).includes(groupName)) {
        groups[groupName].push(ef)
      } else {
        groups[groupName] = [ef]
      }
    })

    return Object.entries(groups).sort((a, b) => {
      if (b[0].includes("Sin Categoría")) return 1
      if (a[0].includes("Sin Categoría")) return 1
      if (a[0] > b[0]) return 1
      if (a[0] < b[0]) return -1
      return 0
    }).map(([key, group]) => {
      if (!group.length) return null
      const [categoryValue, groupIndex, label, color] = key.split("-")
      let fieldsToRender = group
      const groupFields = fieldsToRender.map((field) => {
        const index = field.index
        let value = <Typography variant="h4">{field?.value || "Sin respuesta"}</Typography>
        if (field.value)
          if (field.type === "img") {
            console.log(field.value)
            let imgs = !!field.value ? field.value.split("&#&") : []
            value = imgs.map(img => <ImageContainer
              src={field?.value ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${img}` : "/noimage.png"}
              height={64}
              width={64}
              border={12}
            />
            )
          }
        if (field.type === "rte") {
          value = <div id={`${field.label}-rte`} dangerouslySetInnerHTML={{ __html: field.value }}></div>
        }
        if (field.type === "compliance") {
          const values = field.value ? JSON.parse(field.value) : {}
          const des = values.desfav
          const compliance = Math.round(100 - ((des * 100) / values.total))
          value = (<div>
            <Typography variant='subtitle1'>{`${field.label}: `}{<span style={{ fontWeight: 600 }}>{values.total || 0}</span>}</Typography>
            <Typography variant='subtitle1'>{`${field.compliance_case}: `}{<span style={{ fontWeight: 600 }}>{values.desfav || 0}</span>}</Typography>
            <Typography variant='subtitle1'>{`Cumplimiento: `}<strong>{compliance}%</strong></Typography>
            {compliance < 100 && <Typography variant='subtitle1'>{`${field?.compliance_observation}: `}<strong>{values.comment || "Sin observación"}</strong></Typography>}
          </div>)

        }
        if (field.type === "multiselect") {
          if (Array.isArray(field.value)) {
            const values = field.value.map((val, index) => {
              const [name, comment] = val.split(":")
              return (
                <div style={{ marginLeft: 12 }} key={val}>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>{index + 1}. {name}</Typography>
                  <Typography variant="subtitle1" style={{ marginLeft: 12 }}>{comment && comment !== "undefined" ? `Comentarios: ${comment}` : ""}</Typography>
                </div>
              )
            })
            value = (
              <div className={classes.multigrid}>
                {values}
              </div>
            )
          }
        }

        return (
          <div className={classes.finishedField} >
            {index > 0 && <Divider style={{ marginBottom: 18 }} />}
            <Typography variant="subtitle1">{`${field.label} ${!!field.required ? "*" : ""}`}</Typography>
            {value}
            {this.renderFinishedSubquestions(field)}
          </div>
        )
      })
      const isMainCategory = categoryValue !== "false"
      return (
        <div style={{ background: color }} className={isMainCategory ? classes.group : ""}>
          {isMainCategory && <Typography variant='h4'>{parseInt(groupIndex, 10) + 1}. {label}</Typography>}
          {groupFields}
        </div>
      )
    })
  }

  render() {
    const { classes, fields = [], open, onClose, title = "" } = this.props
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h1">Formulario {title}:</Typography>
          {this.renderFinishedFields(fields || [])}
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(ExtraFieldDialog)