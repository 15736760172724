import React, { Component } from 'react'
import { Dialog, Divider, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../Shared/Inputs/SelectInput'

const style = () => ({
  container: {
    padding: 24
  }
})

class FiltersDialog extends Component {

  render() {
    const { classes, open, onClose, data, onChange, user, activity, branch } = this.props

    const uniqueResponsibles = []
    const uniqueActivity = []
    const uniqueBranch = []

    const responsableOptions = data.map(checklist => {
      if (!uniqueResponsibles.includes(checklist.responsable)) {
        uniqueResponsibles.push(checklist.responsable)
        return { label: checklist.responsable, value: checklist.responsable }
      }
      return false
    }).filter(Boolean)

    const activityOptions = data.map(checklist => {
      if (!uniqueActivity.includes(checklist.actividad)) {
        uniqueActivity.push(checklist.actividad)
        return { label: checklist.actividad, value: checklist.actividad }
      }
      return false
    }).filter(Boolean)

    const branchOptions = data.map(checklist => {
      if (!uniqueBranch.includes(checklist.centro_de_trabajo)) {
        uniqueBranch.push(checklist.centro_de_trabajo)
        return { label: checklist.centro_de_trabajo, value: checklist.centro_de_trabajo }
      }
      return false
    }).filter(Boolean)

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h4">Seleccion de filtros</Typography>
          <Typography variant="subtitle1">Actividad:</Typography>
          <SelectInput onChange={onChange} value={activity} name="activity" label="" options={activityOptions} />
          <Divider />
          <Typography variant="subtitle1">Responsable</Typography>
          <SelectInput onChange={onChange} value={user} name="user" label="" options={responsableOptions} />
          <Divider />
          <Typography variant="subtitle1">Centro de trabajo</Typography>
          <SelectInput onChange={onChange} value={branch} name="branch" label="" options={branchOptions} />
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(FiltersDialog)