import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from 'react';

const style = theme => ({
  titleState: {
    padding: "6px 12px",
    borderRadius: 24,
    minWidth: '25%',
    textAlign: 'center',
    "& > p": {
      color: "white"
    }
  },
  red: { background: theme.palette.red.main, },
  green: { background: theme.palette.green.main },
  blue: { background: theme.palette.blue.main, }
})

class Status extends React.Component {
  render() {
    const { status, classes } = this.props
    if (status === "Pendiente") return (
      <div className={`${classes.titleState} ${classes.red}`}>
        <Typography>{status}</Typography>
      </div>
    )
    if (status === "Procesando") return (
      <div className={`${classes.titleState} ${classes.blue}`}>
        <Typography>{status}</Typography>
      </div>
    )
    if (status === "Vencido") return (
      <div className={`${classes.titleState} ${classes.red}`}>
        <Typography>{status}</Typography>
      </div>
    )
    if (status === "Terminado") return (
      <div className={`${classes.titleState} ${classes.green}`}>
        <Typography>{status}</Typography>
      </div>
    )
    if (status === "Aprobado") return (
      <div className={`${classes.titleState} ${classes.green}`}>
        <Typography>{status}</Typography>
      </div>
    )
    return <div></div>;
  }
}

export default withStyles(style)(Status);