import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import { HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
import StepsReport from './StepsReport'

const style = () => ({
  container: {
    background: 'white',
    borderRadius: 12,
    padding: 12,
    margin: '12px 0',
    transition: 'all 0.2s ease-in-out 0.2s',
    height: 16
  },
  selected: {
    minHeight: 600,
    height: 'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

class BaseWorkflow extends Component {

  render() {
    const { classes, base, onSelect, selected, workflows } = this.props
    const isSelected = selected === base.id
    const { workflow_steps = [] } = base
    return (
      <div className={`${classes.container} ${isSelected ? classes.selected : ""}`} >
        <div className={classes.header} onClick={onSelect(base.id)}>
          <Typography variant="h4">{base.name}</Typography>
          {isSelected ? <HighlightOffOutlined /> : <KeyboardArrowDown />}
        </div>
        <Collapse in={isSelected}>
          <div className={classes.selected}>
            {workflow_steps.map(step => <StepsReport step={step} workflows={workflows} />)}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(BaseWorkflow)