import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

const style = theme => ({
  successChip: {
    background: theme.palette.green.main,
    padding: '6px 12px',
    textAlign: 'center',
    borderRadius: 24,
    '& > *': {
      color: 'white'
    }
  },
  notChip: {
    background: theme.palette.red.main,
    padding: '6px 12px',
    textAlign: 'center',
    borderRadius: 24,
    '& > *': {
      color: 'white'
    }
  }
})

class SignedCell extends Component {

  render() {
    const { classes, element, user } = this.props
    const current = user?.account?.user
    const isCurrentOwner = element?.user_id?.toString() === current?.id?.toString()
    if (isCurrentOwner) return <Typography variant="subtitle1" style={{ textAlign: 'center' }}>{element?.participants_signs?.length || 0}</Typography>
    const hasSigned = element?.participants_signs?.filter(sign => sign?.user_id?.toString() === current?.id?.toString())?.length > 0
    return (
      <div className={classes.container}>
        {hasSigned ?
          <>
            <div className={classes.successChip}>
              <Typography variant="subtitle1">
                Firmado
              </Typography>
            </div>
          </>
          :
          <>
            <div className={classes.notChip}>
              <Typography variant="subtitle1">
                No firmado
              </Typography>
            </div>
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(SignedCell))