import { createSubBranch, getSubBranches } from "../../API/branches"
import { createPosition, getPositions } from "../../API/users"

export const userInfo = [
    {
        name: "userTypeId",
        label: "Tipo de usuario",
        type: "select",
        options: [
            { value: "1", label: "Admin" },
            { value: "2", label: "Supervisor" },
            { value: "3", label: "Colaborador" },
        ],
        required: true,
    },
    { name: "email", label: "Correo", required: true, },
    { name: "firstname", label: "Nombre", required: true, },
    { name: "lastname", label: "Apellido", required: true, },
    { name: "rut", label: "RUT", required: true, type: "rut" },
    { name: "phone", label: "Teléfono", required: true, },
    { name: "address", label: "Dirección", required: true, },
    { name: "dateBirth", label: "Fecha de Nacimiento", type: "date", required: true, },
    {
        name: "position_id",
        label: "Cargo",
        required: true,
        type: "select_create",
        options: [],
        placeholder: "Seleccionar cargo",
        onFetch: getPositions,
        onCreate: createPosition
    },
    {
        name: "user_id",
        label: "Supervisor",
        type: "select",
        options: [],
        placeholder: "Seleccionar Supervisor",
    },
    {
        name: "branch_ids",
        label: "Sucursal(es)",
        type: "multiselect",
        options: [],
        placeholder: "Seleccionar Sucursal",
        required: true,
    },
    {
        name: "sub_branch_id",
        label: "Empresa contratista",
        required: false,
        type: "select_create",
        options: [],
        placeholder: "Seleccionar empresa contratista",
        onFetch: getSubBranches,
        onCreate: createSubBranch
    },
    {
        name: "sub_enterprise_id",
        label: "Empresa",
        type: "select",
        options: [],
        placeholder: "Seleccionar Empresa",
    },
    { name: "date_entry", label: "Fecha de ingreso", required: false, type: "date" },
    { name: "nationality", label: "Nacionalidad", required: false, },
    { name: "profession", label: "Profesión / Oficio", required: false, },
    { name: "emergency_contact", label: "Contacto de emergencia", required: false, },
    { name: "emergency_phone", label: "Teléfono de emergencia", required: false, },
]

export const userEditInfo = [
    { name: "email", label: "Correo" },
    { name: "firstname", label: "Nombre" },
    { name: "lastname", label: "Apellido" },
    { name: "rut", label: "RUT" },
    { name: "phone", label: "Teléfono" },
    { name: "address", label: "Dirección" },
    { name: "dateBirth", label: "Fecha de Nacimiento", type: "date" },
    {
        name: "position_id",
        label: "Cargo",
        required: true,
        type: "select_create",
        options: [],
        placeholder: "Seleccionar cargo",
        onFetch: getPositions,
        onCreate: createPosition
    },
    {
        name: "userTypeId",
        label: "Tipo de usuario",
        type: "select",
        options: [
            { value: "1", label: "Admin" },
            { value: "2", label: "Supervisor" },
            { value: "3", label: "Colaborador" },
        ],
    },
    // {
    //     name: "branch_id",
    //     label: "Sucursal",
    //     type: "select",
    //     options: [],
    // },
    {
        name: "branch_ids",
        label: "Sucursal(es)",
        type: "multiselect",
        options: [],
    },
    {
        name: "user_id",
        label: "Supervisor",
        type: "select",
        options: [],
    },
    {
        name: "sub_enterprise_id",
        label: "Empresa",
        type: "select",
        options: [],
        placeholder: "Seleccionar Sub Empresa",
    },
    { name: "date_entry", label: "Fecha de ingreso", required: false, type: "date" },
    { name: "nationality", label: "Nacionalidad", required: false, },
    { name: "profession", label: "Profesión / Oficio", required: false, },
    { name: "emergency_contact", label: "Contacto de emergencia", required: false, },
    { name: "emergency_phone", label: "Teléfono de emergencia", required: false, },
]

export const pwdForm = [
    { name: "newpassword", label: "Contraseña Nueva", type: "password" },
    {
        name: "newpasswordconfirm",
        label: "Confirmación de Contraseña",
        type: "password",
    },
]

export const userTableInfo = [
    { name: "Nombre", label: "name" },
    { name: "RUT", label: "rut" },
    { name: "Empresa", label: "sub_branch", format: (value, element) => value || element.enterprise },
    { name: "Cargo", label: "position" },
    { name: "Correo", label: "email" },
]

export const permissionList = [
    {
        section: "Administración", actions: [
            { name: "SuperAdmin (Activar y Desactivar permisos)", label: "sa" }
        ]
    },
    {
        section: "Actividades", actions: [
            { name: "Planificar actividades", label: "pact" },
            { name: "Editar actividades", label: "eact" }
        ]
    },
    {
        section: "Empresa", actions: [
            { name: "Crear centro de trabajo", label: "cbr" },
        ]
    },
    {
        section: "Colaboradores", actions: [
            { name: "Crear colaborador", label: "cw" },
            { name: "Editar colaborador", label: "ew" }
        ]
    },
    {
        section: "Registros y formularios", actions: [
            { name: "Crear registros y formularios", label: "cch" },
            { name: "Eliminar registros y formularios", label: "dch" },
            { name: "Agregar campos extra", label: "aef" },
        ]
    },
    {
        section: "Hallazgos", actions: [
            { name: "Aprobar hallazgos", label: "appf" },
            { name: "Resolver hallazgos", label: 'resf' }
        ]
    },
    {
        section: "Menú", subsections: [
            {
                section: "Empresa", label: "Empresa", actions: [
                    { name: "Datos Empresa", label: "Datos Empresa" },
                    { name: "Centros de trabajo", label: "Centros de trabajo" },
                    { name: "Documentos", label: "Documentos" },
                    { name: "Colaboradores", label: "Colaboradores" },
                ]
            },
            {
                section: "Módulos", label: "Módulos", actions: [
                    { name: "Planificación Actividades", label: "Planificación Actividades" },
                    { name: "Registros y formularios", label: "Registros y formularios" },
                    { name: "Hallazgos", label: "Hallazgos" },
                    { name: "No Conformidades", label: "No Conformidades" },
                    { name: "Charlas", label: "Charlas" },
                    { name: "Capacitaciones", label: "Capacitaciones" },
                    { name: "Procesos", label: "Procesos" },
                    { name: "IPER", label: "IPER" },
                ]
            },
            { section: "Calendario", label: "Calendario" },
            {
                section: "Configuración", label: "Configuración", actions: [
                    { name: "Registros y formularios", label: "Registros y formularios" },
                    { name: "Actividades", label: "Actividades" },
                    { name: "Charlas", label: "Charlas" },
                    { name: "Capacitaciones", label: "Capacitaciones" },
                    { name: "Documentos Colaboradores", label: "Documentos Colaboradores" },
                    { name: "Documentos Empresa", label: "Documentos Empresa" },
                    { name: "Documentos Centros de Trabajo", label: "Documentos Centros de Trabajo" },
                    { name: "Contratistas", label: "Contratistas" },
                    { name: "Procesos", label: "Procesos" },
                ]
            },
            {
                section: "Reportes", label: "Reportes", actions: [
                    { name: "Hallazgos", label: "Hallazgos" },
                    { name: "Actividades", label: "Actividades" },
                ]
            }
        ],
    }
]