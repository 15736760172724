import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import Chart from "chart.js"

const style = () => ({
  container: {
    position: 'relative'
  },
  number: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
})

class InternalComplianceDonut extends Component {
  componentDidMount() {
    const { name, data } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext("2d")
    chartElement.parentNode.style.height = "100%"
    chartElement.parentNode.style.width = "100%"

    const chartData = [data, 100 - data]
    this.chart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: ["Avance de actividades"],
        datasets: [{
          data: chartData,
          backgroundColor: ['#7373e8', 'whitesmoke']
        }]
      },
      options: {
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        legend: {
          display: false
        },
        tooltips: false
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      const chartData = [data, 100 - data]
      this.chart.data.datasets = [{
        data: chartData,
        backgroundColor: ['#7373e8', 'whitesmoke']
      }]
      this.chart.update()
    }
  }

  render() {
    const { classes, name, data } = this.props
    return (
      <div className={classes.container}>
        <canvas id={name} ref={this.chartRef} />
        <div className={classes.number}>
          <Typography variant="h4">{`${data} %`}</Typography>
        </div>
      </div>
    )
  }
}

InternalComplianceDonut.propTypes = {

}


InternalComplianceDonut.defaultProps = {
  name: "reporte"
}

export default withStyles(style)(InternalComplianceDonut)