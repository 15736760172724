import { FindInPageOutlined } from '@material-ui/icons'
import { Box, Button, IconButton } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { getFastFindingStatus } from '../../../API/enterprise'
import { createFreeFinding } from '../../../API/findings&nc'
import AppContext from '../../../AppContext'
import useToggle from '../../../Hooks/ToogleHook'
import WorkflowFindingDialog from '../../NewWorkflows/Steps/Findings/WorkflowFindingDialog'

const css = {
	container: {
		position: 'fixed',
		top: '25%',
		right: -150,
		background: 'white',
		zIndex: 100,
		transition: 'all 0.2s linear'
	},
	button: {
		padding: 3
	}
}

function FastFindingManager({ value }) {

	const [hover, setHover] = useState(false)
	const [openCreate, toggleOpen] = useToggle(false)
	const { history } = useContext(AppContext)
	const [status, setStatus] = useState(false)

	useEffect(() => {
		async function fetchStatus() {
			const response = await getFastFindingStatus()
			setStatus(response.data.info)
		}

		fetchStatus()

	}, [])

	async function onCreateFinding(body) {
		await createFreeFinding(body)
		history.push("/findings")
	}

	if (!status) return null

	return (
		<Box>
			<Box style={{ right: hover ? 12 : -150 }} sx={css.container} onMouseEnter={() => setHover(h => true)} onMouseLeave={() => setHover(h => false)}>
				<Button onClick={toggleOpen} sx={css.button} size="large" startIcon={<FindInPageOutlined style={{ padding: '0 12px 0 8px' }} />} color="warning" variant='outlined'>
					Realizar Hallazgo
				</Button>
			</Box>
			<WorkflowFindingDialog open={openCreate} onClose={toggleOpen} onCreateFinding={onCreateFinding} />
		</Box>
	)
}

export default FastFindingManager