import React, { Component } from 'react'
import { Checkbox, IconButton, Typography, withStyles } from '@material-ui/core'
import { Check, Delete } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'

const style = () => ({
	tag: {
		padding: '6px 12px',
		borderRadius: 5,
		margin: '6px 0',
		maxWidth: 450,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	colors: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: 12,
	},
	color: {
		height: 20,
		width: 20,
		margin: 12,
		borderRadius: 4,
		border: '1px solid',
		cursor: 'pointer'
	},
	main: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	}
})

const colors = [
	"#202d4c",
	"#8f4799",
	"#ff6c87",
	"#91deb1",
	"#7373e8",
]

class TagContainer extends Component {
	constructor() {
		super()
		this.state = {
			name: "",
			color: ""
		}
		autobind(TagContainer, this)
	}
	componentDidMount() {
		const { tag } = this.props
		this.setState({ ...tag })
	}

	handleChange(event, callback = undefined) {
		const { target } = event
		this.setState({ [target.name]: target.value }, callback)
	}

	handleSave() {
		const { onEdit } = this.props
		const body = { ...this.state }
		onEdit(body)
	}

	render() {
		const { name, color: tagColor } = this.state
		const { classes, tag, onDelete, onFilter, filters } = this.props
		return (
			<div className={classes.main}>
				<div className={classes.container}>
					<div className={classes.tag} style={{ background: tag.color + "70" }}>
						<SeamlessInput color={"transparent"} value={name} name="name" onChange={this.handleChange} onBlur={this.handleSave} />
						<div>
							<IconButton size="small" onClick={onDelete}>
								<Delete />
							</IconButton>
						</div>
					</div>
					<div className={classes.colors}>
						{colors.map(color => {
							const isSelected = color === tagColor
							return (
								<div className={classes.color} style={{ background: `${color}70`, borderColor: color }} onClick={() => {
									const target = { name: "color", value: color }
									const event = { target }
									this.handleChange(event, this.handleSave)
								}}>
									{isSelected && <Check className={classes.checkIcon} />}
								</div>
							)
						})}
					</div>
				</div>
				<div className={classes.container}>
					<Typography variant='subtitle1'>Filtrar </Typography>
					<Checkbox onClick={onFilter} checked={filters.includes(tag.id)} />
				</div>
			</div>
		)
	}
}

export default withStyles(style)(TagContainer)