import React, { Component } from "react"
import { withStyles, Typography, Divider, Tab, Paper, Collapse } from "@material-ui/core"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import { connect } from "react-redux"
import { createSubBranchUserAction, editSubBranchAction, getBranchAction, getSubBranchDocumentsAction, getSubBranchesAction, setSubBranch } from "../../Actions/BranchActions"
import GeneralTable from "../../Shared/GeneralTable"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import MyTabs from "../../Shared/MyTabs"
import { editBranchAction } from "../../Actions/EnterpriseAction"
import { subBranchInfo } from "../Enterprise/Info"
import { ExitToApp } from "@material-ui/icons"
import AddElementButton from "../../Shared/AddElementButton"
import FormContext from "../../Shared/Cards/Provider"
import GeneralForm from "../../Shared/GeneralForm"
import SubBranchDocuments from "./SubBranchDocuments"
import { getSettingsSubBranchCategoryAction } from "../../Actions/SettingsActions"
import { userInfo } from "./Info"
import { transformToOptions } from "../../Utils/functions"
import moment from "moment"
import Licenses from "./SubBranch/Licenses"
import Registers from "./SubBranch/Registers"
import { filterActiveBranches } from "../../Utils/filters"
import { getPositions } from "../../API/users"
import { Box, Switch } from "@mui/material"
import { getSubEnterprises, getWelcome } from "../../API/enterprise"
import { sendMail } from "../../API/workflows"

const style = () => ({
  container: { margin: 12 },
  title: {
    margin: "26px 0 12px 0 ",
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  switch: {
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    padding: 8,
    margin: '0 0 12px 0'
  }
})

function formatBaseText(data = {}) {
  const name = data.name || "Mati"
  const user = data.email || "mati.eynaudi@gmail.com"
  const baseText = `<p>${name},</p><p><br>Espero que estés muy bien. A continuación detallo los datos de&nbsp;acceso&nbsp;para&nbsp;<strong>Tazki</strong>:</p><p>Acceso:&nbsp;<a href="https://app1.safeasy.cl">Click Aquí</a></p><p>Usuario: ${user}</p><p>Clave:&nbsp;<span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">es tu </span><strong>Rut sin puntos, sin guión y sin dígito verificador</strong><span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">.</span></p><p>Ejemplo: Si tu rut es 20.059.250-9 tu contraseña será:&nbsp;20059250</p><p>Al ingresar por primera vez, <strong>por favor realizar cambio de clave</strong>. Esto se realiza ingresando en la esquina superior derecha en el ícono de "usuario" y luego donde dice: "Visitar mi perfil". Una vez dentro, está el botón para hacer el <strong>cambio de clave</strong>.</p><p>Por otro lado, para instalar la aplicación en el teléfono hay que seguir los&nbsp;siguientes&nbsp;pasos:</p><p>Android: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-android.pdf">Descarga el instructivo aquí</a>.</p><p>Iphone IOs: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-iphone.pdf">Descarga el instructivo aquí</a>.</p><p>De todas formas, siempre se va a poder&nbsp;acceder&nbsp;desde el navegador utilizando el link de&nbsp;acceso.</p>`
  return baseText
}

const info = [{ name: "Nombre", label: "name" }]

class Branch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      params: {},
      tab: 0,
      loading: true,
      open: false,
      openColaborator: false,
      create_params: {},
      colab_params: {
        dateBirth: moment(new Date()).format("YYYY-MM-DD")
      },
      positions: [],
      sub_enterprises: [],
      mustSend: false
    }
    autobind(Branch, this)
  }

  async componentDidMount() {
    const { match, setSubBranch, getSettingsSubBranchCategories, getSubBranchDocuments, getSubBranches } = this.props
    const { id, sub_branch_id } = match.params
    const body = { sub_branch_id: sub_branch_id }

    const query = new URLSearchParams(this.props.location.search)
    const queryOption = query.get("option")
    switch (queryOption) {
      case "workers": {
        this.setState({ tab: 0 })
        break
      }
      case "documents": {
        this.setState({ tab: 1 })
        break
      }
      case "permissions": {
        this.setState({ tab: 2 })
        break
      }
      case "lists": {
        this.setState({ tab: 3 })
        break
      }
      default: break
    }

    getSettingsSubBranchCategories()
    getSubBranchDocuments(body)
    getSubBranches({ branch_id: id }).then(() => {
      setSubBranch(body)
      this.setState({ loading: false })
    })
    const responses = await Promise.all([getPositions(), getSubEnterprises()])
    const datas = responses.map(r => r.data.info)
    const [positions, sub_enterprises] = datas
    this.setState({ positions, sub_enterprises })
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleSend(params) {
    const { editSubBranch, match } = this.props
    const body = {
      ...params,
      branch_id: match.params.id,
      id: match.params.sub_branch_id
    }
    editSubBranch(body)
  }

  handleGoto(option) {
    return (row) => {
      return () => {
        const { history } = this.props
        history.push(`${option}/${row.id}`)
      }
    }
  }

  handleChange(event) {
    const { target } = event
    const { colab_params } = this.state
    colab_params[target.name] = target.value
    this.setState({ colab_params })
  }

  async handleCreate() {
    const { createSubBranchUser, match, enterprise } = this.props
    const { colab_params, mustSend } = this.state
    const body = {
      ...colab_params,
      sub_branch_id: match.params.sub_branch_id,
      branch_id: match.params.id,
      companyId: enterprise.selected.id,
      name: colab_params.firstname
    }
    await createSubBranchUser(body)
    this.setState({ colab_params: {}, openColaborator: false })
    if (mustSend) {
      const welcome_body = { branch_id: match.params.id }
      const welcomes = await getWelcome(welcome_body)
      const welcome_item = welcomes.data.info
      const welcome = welcome_item.content
      const email_body = formatBaseText(body) + welcome + `<p>Bienvenido!</p><p>Equipo Tazki</p>`
      sendMail({
        subject: `Bienvenido a Tazki`,
        content: email_body,
        emails: [body.email],
        extra_mails: []
      })
    }
  }

  handleOpenColaborator() {
    this.setState({ openColaborator: !this.state.openColaborator })
  }

  setFormInfo(info) {
    const { positions, sub_enterprises } = this.state
    const { enterprise, workers } = this.props
    info.map((input) => {
      switch (input.name) {
        case "branch_id": {
          input.options = transformToOptions(filterActiveBranches(enterprise.branches))
          return input
        }
        case "user_id": {
          input.options = transformToOptions(workers.all)
          return input
        }
        case "position_id": {
          input.options = transformToOptions(positions)
          return input
        }
        case "sub_enterprise_id": {
          input.options = transformToOptions(sub_enterprises)
          return input
        }
        default:
          return input
      }
    })

    return info
  }

  render() {
    const { classes, branch, settings } = this.props
    const { loading, tab, openColaborator, colab_params, mustSend } = this.state
    const selected = branch.selected_sub_branch
    const users = selected ? selected.users : []

    const allCategories = settings.sub_branch_documents.all || []

    const actions = (url) => [
      {
        name: "Ver Colaborador",
        icon: ExitToApp,
        action: this.handleGoto(url),
      },
    ]

    const formattedInfo = this.setFormInfo(userInfo)

    return (
      <div className={classes.container}>
        <MutableInfoCard
          title="Datos contratista"
          submit="Guardar Cambios"
          subtitle="Editar Centro"
          submitAction={this.handleSend}
          formInfo={subBranchInfo}
          showInfo={subBranchInfo}
          response={selected}
          loading={loading}

        />
        <div />
        <Paper square className={classes.title}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab
              label={
                <Typography variant="h1">
                  Colaboradores
                </Typography>
              }
              value={0}
            />
            <Tab
              label={
                <Typography variant="h1">
                  Documentos
                </Typography>
              }
              value={1}
            />
          </MyTabs>
        </Paper>
        <TabPanel value={tab} index={0}>
          <div className={classes.inline}>
            <Typography variant="h1" className={classes.title}>
              Tabla de Colaboradores
            </Typography>
            <AddElementButton name="Crear colaborador" open={openColaborator} onClick={this.handleOpenColaborator} />
          </div>
          <Collapse in={openColaborator}>
            <div>
              <FormContext.Provider value={{ handleChange: this.handleChange, params: colab_params }}>
                <GeneralForm
                  info={formattedInfo}
                  onSubmit={this.handleCreate}
                  open
                />
              </FormContext.Provider>
              {openColaborator &&
                <Paper className={classes.paper}>
                  <Box className={classes.switch}>
                    <Switch checked={mustSend} onClick={() => this.setState({ mustSend: !mustSend })} />
                    <Typography variant='subtitle1'>Enviar correo de bienvenida al usuario creado</Typography>
                  </Box>
                </Paper>
              }
            </div>
          </Collapse>
          <Divider style={{ marginBottom: 24 }} />
          <GeneralTable
            data={users}
            info={info}
            actions={actions('/workers')}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div className={classes.inline}>
            <Typography variant="h1">
              Documentos de Contratista
            </Typography>
          </div>
          <SubBranchDocuments categories={allCategories} documents={[]} />
        </TabPanel>
        {/* <TabPanel value={tab} index={2}>
          <Licenses />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Registers />
        </TabPanel> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  enterprise: state.enterprise,
  user: state.user,
  branch: state.branch,
  settings: state.settings,
  workers: state.workers
})

const mapDispatchToProps = (dispatch) => ({
  getBranch: (body) => dispatch(getBranchAction(body)),
  editBranch: (body, snackbar) => dispatch(editBranchAction(body, snackbar)),
  setSubBranch: body => dispatch(setSubBranch(body)),
  getSettingsSubBranchCategories: body => dispatch(getSettingsSubBranchCategoryAction(body)),
  getSubBranchDocuments: body => dispatch(getSubBranchDocumentsAction(body)),
  createSubBranchUser: body => dispatch(createSubBranchUserAction(body)),
  getSubBranches: body => dispatch(getSubBranchesAction(body)),
  editSubBranch: body => dispatch(editSubBranchAction(body))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(Branch))
