import { Tab } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Tabs, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { createEvaluation, getEvaluations } from '../../API/evaluations'
import AppContext from '../../AppContext'
import useToggle from '../../Hooks/ToogleHook'
import AddElementButton from '../../Shared/AddElementButton'
import DownloadButton from '../../Shared/DownloadButton'
import GeneralTable from '../../Shared/GeneralTable'
import { mergeMultiplePdfFiles, mergeMultiplePdfFilesAndGet } from '../../Utils/functions'
import NewEvaluation from './NewEvaluation'

const css = {
	container: {
		padding: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	card: {
		background: 'white',
		borderRadius: 2,
		margin: '24px 0'
	}
}

const tableInfo = [
	{ name: "Nombre", label: "name" },
	{ name: "Fecha de creación", label: "created_at", format: value => moment(value).format("YYYY-MM-DD HH:mm") },
	{ name: "Evaluado", label: "user" },
	{ name: "% de aprobación", label: "approval" },
	{ name: "Centro de trabajo", label: "branch" }
]

const finishedInfo = [
	{ name: "Nombre", label: "name" },
	{ name: "Fecha de creación", label: "created_at", format: value => moment(value).format("YYYY-MM-DD HH:mm") },
	{ name: "Evaluado", label: "user" },
	{ name: "Estado", label: "approved", format: value => value ? "Aprobado" : "Reprobado" },
	{ name: "Puntaje", label: "percentage", format: value => `${value}%` },
	{ name: "Centro de trabajo", label: "branch" },
	{ name: "Terminado el", label: "finished_at", format: value => moment(value).format("YYYY-MM-DD HH:mm") }
]

function Evaluations() {

	const [openCreate, toggleCreate] = useToggle(false)
	const [evaluations, setEvaluations] = useState([])
	const [tab, setTab] = useState(0)
	const { history, account } = useContext(AppContext)

	useEffect(() => {
		async function fetchData() {
			const response = await getEvaluations()
			setEvaluations(response.data.info)
		}

		fetchData()
		window.addEventListener("branch_change", fetchData)
		return () => { window.removeEventListener("branch_change", fetchData) }

	}, [])

	async function onCreate(body) {
		const response = await createEvaluation(body)
		setEvaluations(response.data.info)
	}

	function gotoEvaluation(row) {
		return () => {
			history.push(`/evaluations/${row.id}`)
		}
	}

	function download(element, info) {
		return async () => {
			const url = `https://app.safeasy.cl/model/pdf/evaluations.php?id=${element.id}&u=${element.user_id}`
			const parsed_url = new URL(url)
			if (info) {
				Object.keys(info).forEach(key => {
					parsed_url.searchParams.append(key, info[key])
				})
			}
			console.log(parsed_url.toString())
			const final_url = parsed_url.toString()
			mergeMultiplePdfFiles([final_url], `Reporte de evaluación: ${element.name} de ${element.user}`)
		}
	}

	const actions = [
		{ name: "Entrar a evaluación", icon: KeyboardArrowRight, action: gotoEvaluation },
		{ name: "Descargar", component: DownloadButton, action: download, color: "primary", disabled: ev => !ev.finished }
	]
	const openEvaluations = evaluations.filter(ev => !ev.finished)
	const finishedEvaluations = evaluations.filter(ev => ev.finished)

	return (
		<Box sx={css.container}>
			<Box sx={css.header}>
				<Typography variant='h1'>Evaluaciones</Typography>
				<AddElementButton name="Crear evaluación" onClick={toggleCreate} open={openCreate} />
			</Box>
			<Box sx={css.card}>
				<Tabs value={tab} onChange={(e, v) => setTab(v)}>
					<Tab label="Activas" />
					<Tab label="Cerradas" />
				</Tabs>
			</Box>
			<NewEvaluation open={openCreate} onCreate={onCreate} />
			<GeneralTable
				info={!!tab ? finishedInfo : tableInfo}
				data={!!tab ? finishedEvaluations : openEvaluations}
				actions={actions}
			/>
		</Box>
	)
}

export default Evaluations