import React, { Component } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'

const style = () => ({
  title: {
    padding: 12,
  },
  paper: {
    marginTop: 24,
    padding: 12,
  },
  divisor: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > *': {
      flexBasis: 'calc(50% - 36px)',
    }
  },
})

class ChDescription extends Component {
  drawDescription(content) {
    const element = document.getElementById('html-description')
    if (!element) return null
    element.innerHTML = content
  }

  componentDidUpdate() {
    const { checklist } = this.props
    this.drawDescription(checklist?.preventive_measure_description)
  }

  renderCode() {
    const { classes, checklist } = this.props
    if (!checklist?.preventive_measure_code) return null
    return (
      <Paper className={classes.paper}>
        <div className={classes.title}>
          <Typography variant="h1" >
            Código
          </Typography>
          <Typography variant="subtitle1">
            {checklist?.preventive_measure_code}
          </Typography>
        </div>
      </Paper>
    )
  }

  renderRevision() {
    const { classes, checklist } = this.props
    if (!checklist?.preventive_measure_revision) return null
    return (
      <Paper className={classes.paper}>
        <div className={classes.title}>
          <Typography variant="h1" >
            Revisión
          </Typography>
          <Typography variant="subtitle1">
            {checklist?.preventive_measure_revision}
          </Typography>
        </div>
      </Paper>
    )

  }

  renderDescription() {
    const { classes, checklist } = this.props
    if (!checklist?.preventive_measure_description) return null
    return (
      <Paper className={classes.paper}>
        <div className={classes.title}>
          <Typography variant="h1" >
            Descripción
          </Typography>
          <div id="html-description"></div>
        </div>
      </Paper>
    )
  }

  render() {
    const { classes, checklist } = this.props
    if (Object.keys(checklist).length === 0) return null
    return (
      <>
        <div className={classes.divisor}>
          {this.renderCode()}
          {this.renderRevision()}
        </div>
        {this.renderDescription()}
      </>
    )
  }
}

export default withStyles(style)(ChDescription)