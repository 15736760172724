import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const deleteUserSign = body => {
  return instance({
    method: 'post',
    url: 'users/delete_user_sign.php',
    data: body
  })
}

export const getAskedSigns = () => {
  return instance2({
    method: 'get',
    url: 'users/get_branch_documents.php'
  })
}

export const registerLogin = body => {
  return instance({
    method: 'post',
    url: 'users/register_login.php',
    data: body
  })
}

export const getWorkers = body => {
  return instance2({
    method: 'get',
    url: 'users/get.php',
    params: body
  })
}

export const getUserDocuments = body => {
  return instance2({
    method: 'get',
    url: 'users/get_documents.php',
    params: body
  })
}

export const getUserDocumentCategories = body => {
  return instance2({
    method: 'get',
    url: 'settings/users_documents/get_category.php',
    params: body
  })
}

export const createUserDocument = body => {
  return instance({
    method: 'post',
    url: 'users/create_document.php',
    data: body
  })
}

export const editUserDocument = body => {
  return instance({
    method: 'post',
    url: "users/edit_document.php",
    data: body
  })
}

export const deleteUserDocument = body => {
  return instance({
    method: 'post',
    url: 'users/delete_document.php',
    data: body
  })
}

export const signUserDocument = body => {
  return instance({
    method: 'post',
    url: 'users/user_document_sign.php',
    data: body
  })
}

export const getUserByRut = body => {
  return instance2({
    method: 'get',
    url: 'users/get_by_rut.php',
    params: body
  })
}

export const uploadFile = body => {
  return instance2({
    method: 'post',
    url: 'upload_file',
    data: body
  })
}

export const getBaseActivities = () => {
  return instance2({
    method: 'get',
    url: 'users/base_activities'
  })
}

export const getUserBaseActivitiesReport = (body) => {
  return instance2({
    method: 'get',
    url: 'user_base_activities/report',
    params: body
  })
}

export const createBaseActivity = (body) => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/create',
    data: body
  })
}
export const editBaseActivity = (body) => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/edit',
    data: body
  })
}

export const deleteBaseActivity = (body) => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/delete',
    data: body
  })
}

export const getBaseActivitiesReport = body => {
  return instance2({
    method: 'get',
    url: 'users/base_activities/report',
    params: body
  })
}

export const startUserActivity = body => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/start',
    data: body
  })
}

export const createUserActivityRegister = body => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/create/register',
    data: body
  })
}

export const getPositions = () => {
  return instance2({
    method: 'get',
    url: 'users/positions'
  })
}

export const createPosition = (body) => {
  return instance2({
    method: 'post',
    url: 'users/positions',
    data: body
  })
}

export const getParticipations = body => {
  return instance2({
    method: 'get',
    url: 'users/participations',
    params: body
  })
}

export const getUserPendings = body => {
  return instance2({
    method: 'get',
    url: 'users/pendings',
    params: body
  })
}

export const getUserOtherPendings = body => {
  return instance2({
    method: 'get',
    url: 'users/other_pendings',
    params: body
  })
}

export const usersReportAmount = body => {
  return instance2({
    method: 'post',
    url: 'users/amount_report',
    data: body
  })
}

export const usersReportPercent = body => {
  return instance2({
    method: 'post',
    url: 'users/percent_report',
    data: body
  })
}

export const userComplianceReport = body => {
  return instance2({
    method: 'get',
    url: 'users/compliance_report',
    params: body
  })
}

export const sendRecover = body => {
  return instance2({
    method: 'post',
    url: 'users/password/send',
    data: body
  })
}

export const getUserFromCode = body => {
  return instance2({
    method: 'get',
    url: 'users/from_code',
    params: body
  })
}

export const setNewPassword = body => {
  return instance2({
    method: 'post',
    url: 'users/password/new',
    data: body
  })
}

export const getTableUsers = (body, signal) => {
  return instance2({
    method: 'get',
    url: 'users/table',
    params: body,
    signal
  })
}

export const getInputUsers = () => {
  return instance2({
    method: 'get',
    url: 'users/input'
  })
}

export const toggleActiveUser = body => {
  return instance2({
    method: 'put',
    url: 'users/active',
    data: body
  })
}

export const uploadExcel = body => {
  return instance2({
    method: 'post',
    url: 'users/massive',
    data: body
  })
}

export const getSupervised = body => {
  return instance2({
    method: 'get',
    url: 'users/supervised',
    params: body
  })
}

export const getSupervisedData = body => {
  return instance2({
    method: 'get',
    url: 'users/supervised/data',
    params: body
  })
}

export const getSupervisedDetails = body => {
  return instance2({
    method: 'get',
    url: 'users/supervised/details',
    params: body
  })
}