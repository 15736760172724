import createSnackbar from "../Utils/snackbar"
import instance from "../Utils/instance"

export const getPermissionsEppCategoriesAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: 'settings/permissions/epps/get_category.php'
  })
  return {
    type: "GET_PERMISSION_EPP_CATEGORIES",
    payload: REQUEST
  }
}

export const getPermissionsEppsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: 'settings/permissions/epps/get.php',
    params: body
  })
  return {
    type: "GET_PERMISSION_EPPS",
    payload: REQUEST
  }
}

export const createPermissionsEppCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/permissions/epps/create_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_PERMISSION_EPP_CATEGORY",
    payload: REQUEST
  }
}

export const createPermissionsEppAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/permissions/epps/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_PERMISSION_EPP",
    payload: REQUEST
  }
}

export const editPermissionsEppCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/permissions/epps/edit_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_PERMISSION_EPP_CATEGORY",
    payload: REQUEST
  }
}

export const editPermissionsEppAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/permissions/epps/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_PERMISSION_EPP",
    payload: REQUEST
  }
}

export const deletePermissionsEppCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/permissions/epps/delete_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_PERMISSION_EPP_CATEGORY',
    payload: REQUEST
  }
}