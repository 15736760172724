import { AddCircle, AddCircleOutline, KeyboardArrowDown, KeyboardArrowUp, Settings } from "@material-ui/icons"
import { Box, Dialog, IconButton, Tooltip, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { changeCategoryOrder, createBaseItem, editCategoryUsers, editSubtitle } from "../../../../API/settings_checklist"
import useToggle from "../../../../Hooks/ToogleHook"
import newTheme from "../../../../newTheme"
import MultiSelectInput from "../../../../Shared/Inputs/MultiSelectInput"
import NewMultiSelectInput from "../../../../Shared/Inputs/NewMultiSelectInput"
import SeamlessInput from "../../../../Shared/Inputs/SeamlessInput"
import { filterActiveWorkers } from "../../../../Utils/filters"
import { transformToOptions } from "../../../../Utils/functions"
import { SetupChecklistContext } from "./ChecklistSetup"
import Item from "./Item"

const css = {
	header: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		marginTop: 2
	},
	dialog: {
		padding: 4
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flexGrow: 1
	}
}

function Subtitle({ subtitle, items }) {

	const [name, setName] = useState(subtitle.name)
	const [openUsers, toggleUsers] = useToggle(false)
	const [users, setUsers] = useState(subtitle.users_object)
	const { setChecklist, checklist } = useContext(SetupChecklistContext)

	async function onBlur() {
		const body = { ...subtitle, name }
		const response = await editSubtitle(body)
		setChecklist(response.data.info)
	}

	async function onCreate() {
		const body = { subtitle_id: subtitle.id, base_checklist_id: checklist.id }
		const response = await createBaseItem(body)
		setChecklist(response.data.info)
	}

	async function moveCategory(direction) {
		const body = { ...subtitle, direction, base_checklist_id: checklist.id }
		const response = await changeCategoryOrder(body)
		setChecklist(response.data.info)
	}

	async function onEditCategoryUsers() {
		const body = { ...subtitle, users_object: users }
		const response = await editCategoryUsers(body)
		setChecklist(response.data.info)
	}

	return (
		<Box>
			<Box sx={css.header}>
				<SeamlessInput
					onBlur={onBlur}
					placeholder="Nombre de categoría..."
					padding={0}
					color="transparent"
					variant="h4"
					value={name}
					fontColor={newTheme.palette.secondary.main}
					onChange={e => setName(e.target.value)}
				/>
				<Box sx={css.actions}>
					<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Crear elemento en la categoría</Typography>}>
						<IconButton color="info" onClick={onCreate}>
							<AddCircleOutline />
						</IconButton>
					</Tooltip>
					<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Mover hacia abajo</Typography>}>
						<IconButton onClick={() => moveCategory("down")}>
							<KeyboardArrowDown />
						</IconButton>
					</Tooltip>
					<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Mover hacia arriba</Typography>}>
						<IconButton onClick={() => moveCategory("up")}>
							<KeyboardArrowUp />
						</IconButton>
					</Tooltip>
					<IconButton onClick={toggleUsers}>
						<Settings />
					</IconButton>
				</Box>
				<Dialog open={openUsers} onClose={toggleUsers} fullWidth maxWidth="sm">
					<Box sx={css.dialog}>
						<Typography variant='h4'>Asignación de usuarios responsables de {subtitle.name}</Typography>
						<NewMultiSelectInput onBlur={onEditCategoryUsers} value={users} onChange={e => setUsers(e.target.value)} label="Usuarios responsables" options={transformToOptions(filterActiveWorkers(false))} />
					</Box>
				</Dialog>
			</Box>
			{items.map(item => <Item item={item} key={item.id} />)}
		</Box>
	)
}

export default Subtitle