import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'

const style = () => ({
  container: {
    background: 'white',
    margin: 12,
    padding: 12,
    borderRadius: 12
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  }
})

class MainField extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(MainField, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { classes, children, field = {} } = this.props
    const { open } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.header} onClick={this.handleOpen}>
          <Typography variant="h4">{field.label}</Typography>
          <KeyboardArrowDown />
        </div>
        <Collapse in={open}>
          {children}
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(MainField)