import { Box, Tab, Tabs, Typography } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import { createSettingsWorkflow, getSettingsWorkflows } from '../../../API/workflows'
import useTabs from '../../../Hooks/UseTabs'
import TabPanel from '../../../Shared/TabPanel'
import { fetchFunction } from '../../../Utils/functions'
import WorkflowList from './WorkflowList'

const css = {
	tabs: {
		background: 'white',
		borderRadius: 1,
		margin: '12px 0'
	}
}

export const WorkflowsSetupContext = createContext({})

function WorkflowsSetup({ value }) {

	const [tab, setTab] = useTabs()
	const [workflows, setWorkflows] = useState([])

	useEffect(() => {
		const fetchData = fetchFunction(getSettingsWorkflows, setWorkflows)
		fetchData()
	}, [])

	async function onCreate(body) {
		fetchFunction(createSettingsWorkflow, setWorkflows, body)()
	}

	return (
		<Box sx={css.container}>
			<Typography variant="h1">Configuración de procesos</Typography>
			<Box sx={css.tabs}>
				<Tabs value={tab} onChange={setTab}>
					<Tab label={<Typography variant="h2">Accidentes</Typography>} />
					<Tab label={<Typography variant="h2">Incidentes</Typography>} />
					<Tab label={<Typography variant="h2">Reclamos</Typography>} />
					<Tab label={<Typography variant="h2">Generales</Typography>} />
				</Tabs>
			</Box>
			<WorkflowsSetupContext.Provider value={{ onCreate }}>
				<TabPanel index={0} value={tab}>
					<WorkflowList type={1} workflows={workflows} />
				</TabPanel>
				<TabPanel index={1} value={tab}>
					<WorkflowList type={2} workflows={workflows} />
				</TabPanel>
				<TabPanel index={2} value={tab}>
					<WorkflowList type={3} workflows={workflows} />
				</TabPanel>
				<TabPanel index={3} value={tab}>
					<WorkflowList type={5} workflows={workflows} />
				</TabPanel>
			</WorkflowsSetupContext.Provider>
		</Box>
	)
}

export default WorkflowsSetup