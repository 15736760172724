import React, { Component } from "react"
import {
    Paper,
    Typography,
    Divider,
    withStyles,
} from "@material-ui/core"
import "./animation.css"
import SelectInput from "./Inputs/SelectInput"
import { transformToOptions } from "../Utils/functions"

const style = () => ({
    container: {
        width: 400,
        maxWidth: "100%",
    },
    title: {
        padding: 12,
    },
})

class EnterpriseCard extends Component {

    renderStations() {
        const { branches, user, workers } = this.props
        const level = user.account.user.userType
        const allWorkers = workers.all || []
        const filtered = branches.filter(branch => {
            if (level > 1) {
                const mySelf = allWorkers.find(worker => worker.id === user.account.user.id)
                return mySelf?.branch_ids?.map(b => b.toString()).includes(branch.id.toString())
            } else if (level === 1) {
                const mySelf = allWorkers.find(worker => worker.id === user.account.user.id)
                if (!!mySelf?.branch_id) return mySelf?.branch_ids?.map(b => b.toString()).includes(branch.id.toString())
                return true
            }
            return true
        })
        const finalOptions = transformToOptions(filtered)
        const allOptionValues = []
        finalOptions.forEach(option => allOptionValues.push(option.value))
        const allOption = [{ label: "Todos", value: allOptionValues.reverse().join(",") }]
        return allOption.concat(finalOptions)
    }

    render() {
        const { classes, branch, onChange, name } = this.props
        return (
            <Paper className={classes.container} square>
                <div className={classes.title}>
                    <Typography variant="subtitle1">{name}</Typography>
                </div>
                <Divider style={{ margin: 0 }} />
                <div className={classes.title}>
                    <Typography variant="subtitle2">Centro de trabajo</Typography>
                    {/* <Select
                        value={branch.id}
                        style={{ width: 200 }}
                        onChange={onChange}
                        variant="outlined"
                        margin="dense"
                    >
                        {this.renderStations()}
                    </Select> */}
                    <SelectInput
                        value={branch.id}
                        onChange={onChange}
                        variant="outlined"
                        margin="dense"
                        label=""
                        options={this.renderStations()}
                    >
                        { }
                    </SelectInput>
                </div>
            </Paper>
        )
    }
}

export default withStyles(style)(EnterpriseCard)
