import React, { Component } from "react"
import propTypes from "prop-types"
import "./animation.css"

class LoaderComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        const { action, params, loading } = this.props
        if (action) {
            action(...params).then(() => {
                this.setState({ loading: false })
            })
        }
        if (typeof loading !== 'undefined') {
            this.setState({ loading })
        }
    }

    componentDidUpdate(prevProps) {
        const { loading, action, params } = this.props
        if (prevProps.loading !== loading) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ loading })
        }
        if (JSON.stringify(prevProps.params) !== JSON.stringify(params)) {
            this.setState({ loading: true })
            if (action) {
                action(...params).then(() => {
                    this.setState({ loading: false })
                })
            }
        }

    }

    render() {
        const { children } = this.props
        const { loading } = this.state
        return (
            <>
                {loading ? (
                    <div style={{ position: 'relative', minHeight: 64 }}>
                        <div className="lds-ring">
                            <div />
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                ) : (
                        <>{children}</>
                    )}
            </>
        )
    }
}

LoaderComponent.propTypes = {
    loading: propTypes.bool,
    action: propTypes.func,
    children: propTypes.node.isRequired,
    params: propTypes.arrayOf(
        propTypes.oneOfType([
            propTypes.string,
            propTypes.bool,
            propTypes.func,
            propTypes.number,
            propTypes.object,
        ])
    ),
}

LoaderComponent.defaultProps = {
    loading: undefined,
    action: undefined,
    params: [],
}

export default LoaderComponent
