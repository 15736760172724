import React, { Component } from 'react'
import { Collapse, Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline, Delete, Edit, InfoOutlined } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import NewPhaseDialog from './Steps/NewPhaseDialog'
import TextInput from '../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../Shared/SubmitButton'
import { withRouter } from 'react-router'
import PhaseRenderer from './Steps/Phases/PhaseRenderer'
import ArrowButton from '../../../Shared/ArrowButton'
import EditPhaseDialog from './Steps/EditPhaseDialog'
import { transformToOptions } from '../../../Utils/functions'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import { filterActiveWorkers } from '../../../Utils/filters'
import WorkflowContext from './WorkflowProvider'
import { openGlobalDialog } from '../../../Actions/GlobalActions'
import { connect } from 'react-redux'
import CheckboxInput from '../../../Shared/Inputs/CheckboxInput'

const style = () => ({
  container: {
    background: 'white',
    borderRadius: 12,
    padding: 12,
    minHeight: 300,
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    margin: '12px 0'
  },
  buttons: {
    position: 'absolute',
    top: 6,
    right: 6
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  phases: {
    marginBottom: 48
  },
  dialog: {
    padding: 12,
    overflow: 'unset'
  },
  separator: {
    margin: '6px 0'
  },
  title: {
    margin: '12px 0'
  },
  phase: {
    background: '#f2f3f8',
    borderRadius: 12,
    padding: 12,
    margin: '12px 0'
  },
  sameLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
})

class Stage extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      openNewPhase: false,
      name: "",
      auth_ids: [],
      responsible_ids: [],
      openDialog: false,
      openEditPhase: false,
      owner_as_responsible: false,
      owner_as_auth: false,
      phase: null,
      index: -1
    }
    autobind(Stage, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleOpenNewPhase() {
    const { openNewPhase } = this.state
    this.setState({ openNewPhase: !openNewPhase })
  }

  handleEditPhase(phase, index) {
    return () => {
      this.setState({ openEditPhase: !this.state.openEditPhase, phase, index })
    }
  }

  handleDeletePhase(phase) {
    return () => {
      const { onDeletePhase } = this.context
      const { openGlobalDialog, match, stage } = this.props
      openGlobalDialog(onDeletePhase, {
        id: phase.id,
        workflow_id: match.params.id,
        step_id: stage.id
      }, {
        message: "Seguro que quieres eliminar este item?",
        title: "Eliminar item",
        submit: "Eliminar"
      })
    }
  }

  renderPhases() {
    const { open } = this.state
    const { classes, stage } = this.props
    const phases = stage?.phases || []
    let index = -1
    const alwaysShowPhases = phases.slice(0, 4).map((phase) => {
      index += 1
      return (
        <div className={classes.phase}>
          {phase?.data?.required &&
            <div className={classes.sameLine}>
              <InfoOutlined style={{ height: 16, width: 16, color: 'red', marginRight: 6 }} />
              <Typography variant="subtitle1">Campo obligatorio</Typography>
            </div>
          }
          <div className={classes.inline}>
            <PhaseRenderer phase={phase.data} />
            <div>
              <IconButton onClick={this.handleEditPhase(phase, index)}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleDeletePhase(phase)}>
                <Delete />
              </IconButton>
            </div>
          </div>
        </div>
      )
    })
    const collapsePhases = phases.slice(4).map((phase) => {
      index += 1
      return (
        <div className={classes.phase}>
          <div className={classes.inline}>
            <PhaseRenderer phase={phase.data} />
            <div>
              <IconButton onClick={this.handleEditPhase(phase, index)}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleDeletePhase(phase)}>
                <Delete />
              </IconButton>
            </div>
          </div>
        </div>
      )
    })
    return (
      <>
        {alwaysShowPhases}
        <Collapse in={open}>
          {collapsePhases}
        </Collapse>
        {collapsePhases.length > 0 && <ArrowButton open={open} onClick={this.handleOpen} />}
      </>
    )
  }

  handleChangeName(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleOpenDialog() {
    this.setState({ openDialog: !this.state.openDialog })
  }

  handleOpenEdit() {
    const { stage } = this.props
    const { openDialog } = this.state
    this.setState({
      name: stage?.data?.name,
      responsible_ids: stage?.data?.responsible_ids || [],
      auth_ids: stage?.data?.auth_ids || [],
      owner_as_auth: stage?.data?.owner_as_auth,
      owner_as_responsible: stage?.data?.owner_as_responsible,
      openDialog: !openDialog
    })
  }

  handleEdit() {
    const { onEditStep } = this.context
    const { name, auth_ids, responsible_ids, owner_as_responsible, owner_as_auth } = this.state
    const { stage, match } = this.props
    const newData = { ...stage.data }
    newData.name = name
    newData.responsible_ids = responsible_ids
    newData.auth_ids = auth_ids
    newData.owner_as_responsible = owner_as_responsible
    newData.owner_as_auth = owner_as_auth
    const body = {
      id: stage.id,
      workflow_id: match.params.id,
      step_data: newData
    }
    onEditStep(body)
    this.setState({ name: null, openDialog: false })
  }

  getUsersNames(ids) {
    if (ids.length === 0) return (
      <div>
        <Typography variant="subtitle1">Sin asignar</Typography>
      </div>
    )
    const { workers } = this.props
    const allWorkers = workers.all || []
    return ids.map(id => {
      return (
        <div>
          <Typography variant="subtitle1">* {allWorkers.find(w => w.id === id)?.name || null}</Typography>
        </div>
      )
    })
  }

  render() {
    const { onCreatePhase, onEditPhase } = this.context
    const { classes, stage } = this.props
    const { openNewPhase, name, openDialog, openEditPhase, phase, index, responsible_ids, auth_ids, owner_as_auth, owner_as_responsible } = this.state
    const filteredWorkers = filterActiveWorkers()
    // Switch for phase entity selected
    const options = transformToOptions(filteredWorkers)
    const responsibles = stage?.data?.responsible_ids || []
    const auths = stage?.data?.auth_ids || []
    return (
      <div className={classes.container}>
        <Typography variant="h4" className={classes.title} >{`Etapa ${stage?.data?.name || "Sin nombre"}`}</Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }} >Responsables:
          {stage?.data?.owner_as_responsible && <Typography variant="subtitle1">* Creador del proceso</Typography>}
          {this.getUsersNames(responsibles)}
        </Typography>
        <Typography variant="subtitle1" style={{ fontWeight: 600, marginTop: 12 }} >Serán notificados:
          {stage?.data?.owner_as_auth && <Typography variant="subtitle1">* Creador del proceso</Typography>}
          {this.getUsersNames(auths)}
        </Typography>
        <Divider style={{ margin: '12px 0' }} />
        <div className={classes.inline}>
          <Typography variant="h4">Items:</Typography>
          <IconButton onClick={this.handleOpenNewPhase}>
            <AddCircleOutline />
          </IconButton>
        </div>
        <NewPhaseDialog onSubmit={onCreatePhase} open={openNewPhase} onClose={this.handleOpenNewPhase} stage={stage} />
        <EditPhaseDialog onSubmit={onEditPhase} open={openEditPhase} onClose={this.handleEditPhase(null, null)} stage={stage} phase={phase} index={index} />
        <div className={classes.phases}>
          {this.renderPhases()}
        </div>
        <div className={classes.buttons}>
          <IconButton onClick={this.handleOpenEdit}>
            <Edit />
          </IconButton>
        </div>
        <Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="subtitle1">Editar Etapa</Typography>
            <TextInput label="Nuevo nombre" name={"name"} value={name} onChange={this.handleChangeName}></TextInput>
            <MultiSelectInput label="Responsables (opcional)" name={"responsible_ids"} value={responsible_ids} onChange={this.handleChangeName} options={options} />
            <CheckboxInput label="Agregar usuario creador como responsable" value={owner_as_responsible} onChange={this.handleChangeName} name="owner_as_responsible" />
            <MultiSelectInput label="Serán notificados (opcional)" name={"auth_ids"} value={auth_ids} onChange={this.handleChangeName} options={options} />
            <CheckboxInput label="Agregar usuario creador como notificado" value={owner_as_auth} onChange={this.handleChangeName} name="owner_as_auth" />
            <SubmitButton onClick={this.handleEdit}>
              Guardar
            </SubmitButton>
          </div>
        </Dialog>
      </div >
    )
  }
}

Stage.contextType = WorkflowContext

const mapDispatchToProps = dispatch => ({
  openGlobalDialog: (action, body, options) => dispatch(openGlobalDialog(action, body, options)),
})

export default connect(null, mapDispatchToProps)(withRouter(withStyles(style)(Stage)))