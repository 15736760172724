import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { getBranches } from '../../../API/branches'
import { getSubEnterprises, getWelcome, setWelcome } from '../../../API/enterprise'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import RTE from '../../../Shared/RTE'
import { transformToOptions } from '../../../Utils/functions'

const css = {
	title: {
		marginBottom: 2
	},
	baseBody: {
		padding: 2,
		borderRadius: 2,
		border: '1px solid whitesmoke',
		marginBottom: 2
	}
}

const baseText = `<p>[Nombre],</p><p><br>Espero que estés muy bien. A continuación detallo los datos de&nbsp;acceso&nbsp;para&nbsp;<strong>Tazki</strong>:</p><p>Acceso:&nbsp;<a href="https://app1.safeasy.cl">Click Aquí</a></p><p>Usuario: [Usuario]</p><p>Clave:&nbsp;<span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">es tu </span><strong>Rut sin puntos, sin guión y sin dígito verificador</strong><span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">.</span></p><p>Ejemplo: Si tu rut es 20.059.250-9 tu contraseña será:&nbsp;20059250</p><p>Al ingresar por primera vez, <strong>por favor realizar cambio de clave</strong>. Esto se realiza ingresando en la esquina superior derecha en el ícono de "usuario" y luego donde dice: "Visitar mi perfil". Una vez dentro, está el botón para hacer el <strong>cambio de clave</strong>.</p><p>Por otro lado, para instalar la aplicación en el teléfono hay que seguir los&nbsp;siguientes&nbsp;pasos:</p><p>Android: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-android.pdf">Descarga el instructivo aquí</a>.</p><p>Iphone IOs: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-iphone.pdf">Descarga el instructivo aquí</a>.</p><p>De todas formas, siempre se va a poder&nbsp;acceder&nbsp;desde el navegador utilizando el link de&nbsp;acceso.</p><p><i>[Aquí irá el contenido personalizado]</i></p><p>Bienvenido!</p><p>Equipo Tazki</p>`

function WelcomeMessage() {

	const [content, setContent] = useState("")
	const [branches, setBranches] = useState([])
	const [enterprises, setEnterprises] = useState([])
	const [tab, setTab] = useState(0)
	const baseBody = useRef(null)
	const [branch_id, setBranch] = useState(null)
	const [sub_enterprise_id, setSubEnterprise] = useState(null)

	useEffect(() => {
		baseBody.current.innerHTML = baseText

		async function fetchData() {
			const responses = await Promise.all([getWelcome(), getBranches(), getSubEnterprises()])
			const datas = responses.map(r => r.data.info)
			const [welcome, branches, sub_enterprises] = datas
			setContent(welcome.content)
			setBranches(branches)
			setEnterprises(sub_enterprises)
		}

		fetchData()

	}, [])

	async function onBlur() {
		const body = tab !== 0 ? { content, branch_id, sub_enterprise_id } : { content }
		const response = await setWelcome(body)
		setContent(response.data.info?.content)
	}

	useEffect(() => {
		async function fetchData() {
			const body = tab !== 0 ? { branch_id, sub_enterprise_id } : {}
			const response = await getWelcome(body)
			const welcome = response.data.info
			setContent(welcome.content)
		}

		if (!!branch_id || !!sub_enterprise_id || tab === 0) {
			fetchData()
		}

	}, [branch_id, sub_enterprise_id, tab])

	function shouldShowRTE() {
		if (tab === 0) return true
		if (tab === 1 && !!branch_id) return true
		if (tab === 2 && !!sub_enterprise_id) return true
		return false
	}

	const label = tab === 1 ? "Seleccionar centro de trabajo" : "Seleccionar Empresa"
	const options = tab === 1 ? transformToOptions(branches) : transformToOptions(enterprises)
	const value = tab === 1 ? branch_id : sub_enterprise_id
	const onChange = tab === 1 ?
		e => { setBranch(e.target.value); setSubEnterprise(null) } :
		e => { setSubEnterprise(e.target.value); setBranch(null) }
	return (
		<Box>
			<Typography sx={css.title} variant='h1'>Correo de bienvenida para colaboradores nuevos</Typography>
			<Typography variant='h4'>Cuerpo del correo:</Typography>
			<Box ref={baseBody} sx={css.baseBody}>

			</Box>
			<Box style={{ paddingLeft: 6 }}>
				<Typography variant='caption'>Agregar contenido personalizado al cuerpo del correo...</Typography>
			</Box>
			<Tabs style={{ margin: '12px 0' }} value={tab} onChange={(e, v) => setTab(v)} >
				<Tab label="Por defecto" />
				<Tab label="Por centro de trabajo" />
				<Tab label="Por empresa" />
			</Tabs>
			{tab !== 0 && <SelectInput options={options} label={label} value={value} onChange={onChange} />}
			{shouldShowRTE() && <RTE onBlur={onBlur} onChange={e => setContent(e.target.value)} value={content} />}
		</Box>
	)
}

export default WelcomeMessage