import { WifiOff } from '@material-ui/icons'
import { Box, Typography } from '@mui/material'
import newTheme from '../../../newTheme'
import ImageContainer from '../../../Shared/ImageContainer'
import { completePath } from '../../../Utils/functions'

const css = {
	container: {
		background: 'white',
		padding: 2,
		borderRadius: 1,
		maxWidth: 200,
		position: 'relative'
	},
	offline: {
		position: 'absolute',
		top: 6,
		right: 6,
		color: newTheme.palette.info.main
	}
}

function Sign({ sign }) {
	const isOffline = sign?.sign_url.includes("data:")
	const old_sign_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}`
	return (
		<Box sx={css.container}>
			<Typography variant='subtitle1'><strong>{sign?.sign_name}</strong></Typography>
			<Typography variant='caption'>{sign?.date}</Typography>
			<Box>
				<ImageContainer width={200} src={completePath(sign?.sign_url, old_sign_url)} alt="firma" />
			</Box>
			{isOffline &&
				<Box sx={css.offline}>
					<WifiOff />
				</Box>
			}
		</Box>
	)
}

export default Sign