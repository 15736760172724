import React, { Component } from 'react'
import { Tooltip, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'
import autobind from '../../../Utils/autobind'
import DateIntervalSelector from '../../../Shared/Inputs/DateIntervalSelector'
import { getFindings, getUnconformities } from '../../../API/findings&nc'
import BarChart from '../../../Shared/Charts/BarChart'
import { FiberManualRecord } from '@material-ui/icons'
import DonutChart from '../../../Shared/Charts/DonutChart'
import GeneralTable from '../../../Shared/GeneralTable'
import SelectInput from '../../../Shared/Inputs/SelectInput'

const style = theme => ({
  container: {
    padding: 12
  },
  titleContainer: {
    padding: 12,
    background: theme.palette.primary.main,
    maxWidth: 600,
    marginBottom: 24
  },
  title: {
    color: "white"
  },
  paper: {
    maxWidth: 700,
    position: 'relative',
    marginTop: 24
  },
  chartTitle: {
    padding: 12,
    color: "white",
    background: theme.palette.primary.main
  },
  chartContainer: {
    padding: 24,
  },
  chartsContainer: {
    flexBasis: '50%',
    maxWidth: 700,
    margin: 12
  },
  dateSelectors: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 700,
    '& > *': {
      maxWidth: 300
    },
    margin: 'auto'
  },
  inlineCharts: {

    flexWrap: 'wrap'
  },
  inline: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  dates: {
    background: 'white',
    borderRadius: 8,
    padding: 24,
    width: 'fit-content'
  },
  chart: {
    background: 'white',
    borderRadius: 8,
    padding: 12,
    marginTop: 24
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  chartSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '12px 0'
  },
  chartShow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '12px 0'
  },
  filters: {
    flexBasis: 400
  },
  donut: {
    width: 250,
    marginBottom: 24
  },
  crit: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)'
    }
  }
})

const labelDict = {
  all: "Todos",
  pending: "Pendientes",
  processing: "En Proceso",
  overdue: "Vencidos",
  finished: "Terminados",
  approved: "Aprobados",
  dismissed: "Desestimados"
}

const colorDict = {
  all: "black",
  pending: "#ff6c87",
  processing: "#7373e8",
  overdue: "#ff6c87",
  finished: "#91deb1",
  approved: "#91deb1",
  dismissed: "#6a6a6a"
}

const criticalityFormat = (level) => {
  switch (String(level)) {
    case "0": { return "No Asignado" }
    case "1": { return 'No Critico' }
    case "2": { return "Poco Critico" }
    case "3": { return "Medianamente Critico" }
    case "4": { return "Altamente Critico" }
    default: { return "No Asignado" }
  }
}

const criticalityFormatColor = (level) => {
  switch (String(level)) {
    case "0": { return "#6a6a6a" }
    case "1": { return '#f2f5ff' }
    case "2": { return "darkgoldenrod" }
    case "3": { return "#ffab6e" }
    case "4": { return "#ff6c87" }
    default: { return "#6a6a6a" }
  }
}

const renderCrit = ({ value }) => {
  return (
    <Tooltip title={criticalityFormat(value)}>
      <FiberManualRecord style={{ color: criticalityFormatColor(value) }} />
    </Tooltip>
  )
}

const tableInfo = [
  { name: "Criticidad", label: "criticality", render: renderCrit },
  { name: "Nombre", label: "name" },
  { name: "Descripción", label: "description" },
  { name: "Estado", label: "status" },
  { name: "Detectado por", label: "user_creator" },
  { name: "Responsable", label: "user" },
  { name: "Fecha Detectado", label: "date_detected" },
  { name: "Fecha Resuelto", label: "date_solved" },
]

const arrayToOptions = (array) => {
  return array.map(item => ({ label: item, value: item })).concat({ label: "Todos", value: "" })
}

class Reports extends Component {
  constructor() {
    super()
    this.state = {
      dates: {},
      findings: [],
      unconformities: [],
      data: {},
      responsible: "",
      pm: "",
      detector: "",
      crit: "",
      selectedStatusIndex: -1
    }
    autobind(Reports, this)
  }

  async componentDidMount() {
    const responses = await Promise.all([getFindings(), getUnconformities()])
    const [findings, unconformities] = responses.map(res => res.data.info)
    this.setState({ findings, unconformities }, this.setupData)
  }

  handleChangeFilter(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  setupData() {
    const { unconformities } = this.state
    const preventiveMeasuresArray = []
    const pendings = []
    const processing = []
    const overdue = []
    const finished = []
    const dismissed = []
    const approved = []
    unconformities.forEach(finding => {
      const pm = finding.preventive_measure
      if (!preventiveMeasuresArray.includes(pm)) { preventiveMeasuresArray.push(pm) }
      switch (finding.status) {
        case "Pendiente": { pendings.push(finding); break }
        case "Procesando": { processing.push(finding); break }
        case "Vencido": { overdue.push(finding); break }
        case "Terminado": { finished.push(finding); break }
        case "Desestimado": { dismissed.push(finding); break }
        case "Aprobado": { approved.push(finding); break }
        default: { pendings.push(finding); break }
      }
    })
    const data = { preventiveMeasuresArray, pendings, overdue, finished, dismissed, approved, processing }
    this.setState({ data })
  }

  filterInDates(data) {
    if (!data) return []
    const { dates: { date_start, date_end } } = this.state
    return data.filter(element => moment(element.date_detected).isBetween(moment(date_start), moment(date_end)))
  }

  handleChange(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleSelectCrit(crit) {
    return () => {
      const { crit: actualCrit } = this.state
      if (actualCrit === crit) return this.setState({ crit: "" })
      return this.setState({ crit: String(crit) })
    }
  }

  handleChangeDates(dates) { this.setState({ dates }) }

  handleSelectStatus(item) {
    if (!item.length) return null
    const { selectedStatusIndex } = this.state
    const index = item[0]._index
    if (index === selectedStatusIndex) return this.setState({ selectedStatusIndex: -1 })
    return this.setState({ selectedStatusIndex: index })
  }

  render() {
    const { classes } = this.props
    const { unconformities, data, selectedStatusIndex, responsible, pm, detector, crit: selectedCrit } = this.state

    const all = this.filterInDates(unconformities)
    const pending = this.filterInDates(data.pendings)
    const processing = this.filterInDates(data.processing)
    const overdue = this.filterInDates(data.overdue)
    const dismissed = this.filterInDates(data.dismissed)
    const finished = this.filterInDates(data.finished)
    const approved = this.filterInDates(data.approved)

    const amountMetadata = { pending, processing, overdue, dismissed, finished, approved }
    const dataLabels = Object.keys(amountMetadata)
    const amountData = dataLabels.map(key => ({ label: labelDict[key], value: amountMetadata[key].length, color: colorDict[key] }))
    const maxAmount = Math.round(Math.max(...amountData.map(d => d.value)) / 10) * 10
    const selected = selectedStatusIndex !== -1 ? dataLabels[selectedStatusIndex] : "all"
    const selectedData = selectedStatusIndex !== -1 ? amountMetadata[selected] : all
    const criticityDistribution = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 }
    const posibleResponsibles = []
    const posiblePM = []
    const posibleCreators = []

    const filteredData = selectedData
      .filter(element => !pm || element.preventive_measure === pm)
      .filter(element => !responsible || element.user === responsible)
      .filter(element => !detector || element.user_creator === detector)
      .filter(element => !selectedCrit || String(element.criticality) === selectedCrit)

    selectedData.forEach(finding => {
      criticityDistribution[finding.criticality] += 1
      if (!posibleCreators.includes(finding.user_creator)) { posibleCreators.push(finding.user_creator) }
      if (!!finding.user && !posibleResponsibles.includes(finding.user)) { posibleResponsibles.push(finding.user) }
      if (!posiblePM.includes(finding.preventive_measure)) { posiblePM.push(finding.preventive_measure) }
    })
    const critData = Object.keys(criticityDistribution).map(crit => (
      {
        label: criticalityFormat(crit),
        value: criticityDistribution[crit],
        color: criticalityFormatColor(crit)
      }))





    // console.log(maxAmount)

    return (
      <div className={classes.container}>
        <Typography variant='h6'>Reportes de No Conformidades</Typography>
        <div className={classes.dates}>
          <DateIntervalSelector onDateChange={this.handleChangeDates} />
        </div>
        <div className={classes.chart}>
          <div style={{ marginBottom: 12 }}>
            <Typography variant='h4'>Distribución de no conformidades</Typography>
            <Typography variant='caption'>Presione una barra para ver más detalles</Typography>
          </div>
          <BarChart
            showTitle={false}
            name="amount-distribution"
            options={{
              minValue: 0,
              maxValue: maxAmount + 10,
              stepSize: 5,
              barThickness: 32,
              yEnableGrid: true,
              onClick: this.handleSelectStatus
            }}
            data={amountData}
          />
        </div>
        <div className={classes.chart}>
          <div className={classes.header}>
            <FiberManualRecord style={{ color: colorDict[selected], marginRight: 6 }} />
            <Typography variant='h4'>{labelDict[selected]}</Typography>
          </div>
          <div className={classes.chartSection}>
            <div className={classes.chartShow}>
              <div className={classes.donut}>
                <DonutChart data={critData.map(c => c.value)} color={critData.map(c => c.color)} />
              </div>
              <div>
                {Object.keys(criticityDistribution).map(crit => {
                  const isSelected = selectedCrit === crit
                  return (
                    <div key={crit} className={classes.crit} onClick={this.handleSelectCrit(crit)}>
                      <FiberManualRecord style={{ color: criticalityFormatColor(crit), marginRight: 6 }} />
                      <Typography variant='subtitle1' style={{ fontWeight: isSelected ? 600 : 400 }}>{`${criticalityFormat(crit)} (${criticityDistribution[crit]})`} </Typography>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={classes.filters}>
              <SelectInput onChange={this.handleChangeFilter} value={detector} name="detector" options={arrayToOptions(posibleCreators)} label="Detector" />
              <SelectInput onChange={this.handleChangeFilter} value={responsible} name="responsible" options={arrayToOptions(posibleResponsibles)} label="Responsable" />
            </div>
          </div>
          <GeneralTable maxHeight="350px" data={filteredData} info={tableInfo} scrollable disableSearch />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  findings: state.findings,
  unconformities: state.unconformities,
  enterprise: state.enterprise
})


export default connect(mapStateToProps)(withStyles(style)(Reports))