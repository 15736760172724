import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import { Box, IconButton, Menu, MenuItem, Tab, Tabs, Typography } from '@mui/material'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import useToggle from '../../Hooks/ToogleHook'
import useTabs from '../../Hooks/UseTabs'
import GeneralTable from '../../Shared/GeneralTable'
import { inline_space } from '../../Utils/defaultStyles'
import { getActivities, getSettingsActivities } from '../../API/activities'
import { frequencyFormat } from '../../Utils/formats'
import AppContext from '../../AppContext'
import { filterActiveWorkers } from '../../Utils/filters'
import moment from 'moment'
import CreateFromPosition from './CreateFromPosition'
import { getPositions } from '../../API/users'
import { getBranches } from '../../API/branches'
import CreateSingleActivity from './CreateSingleActivity'
import CreateMultipleByBranch from './CreateMultipleByBranch'
import CreateMultipleByUsers from './CreateMultipleByUsers'
import SignRegisters from './Activity/SignRegisters'

const css = {
	container: {
		maxWidth: 1100,
		margin: 3
	},
	paper: {
		background: 'white',
		borderRadius: 2,
		margin: '12px 0'
	}
}

export const ActivitiesContext = createContext({})

const tableInfo = [
	{ label: "name", name: "Nombre" },
	{ label: "start_date", name: "Fecha Inicio" },
	{ label: "end_date", name: "Fecha Término" },
	{ label: "frequency", name: "Frecuencia", format: frequencyFormat },
	{ label: "branch", name: "Centro de Trabajo", format: (value) => value?.name },
	{ label: "user", name: "Responsable", format: (value) => value.name }
]

const frequencies = [
	{ value: 1000, label: "Sin Frecuencia" },
	{ value: 1, label: "Diario" },
	{ value: 2, label: "Cada dos días" },
	{ value: 3, label: "Cada tres días" },
	{ value: 7, label: "Semanal" },
	{ value: 15, label: "Quincenal" },
	{ value: 30, label: "Mensual" },
	{ value: 60, label: "Bimensual" },
	{ value: 90, label: "Trimestral" },
	{ value: 180, label: "Semestral" },
]

function ActivitiesNew() {

	const [openMenu, toggleMenu] = useToggle(false)
	const [tab, setTab] = useTabs(0)
	const [activities, setActivities] = useState([])
	const [createOption, setCreateOption] = useState(null)
	const [formData, setFormData] = useState({})
	const menu = useRef(null)
	const { history } = useContext(AppContext)

	useEffect(() => {
		async function fetchData() {
			const response = await getActivities()
			setActivities(response.data.info)
		}
		fetchData()

		window.addEventListener("branch_change", fetchData)
		return () => window.removeEventListener("branch_change", fetchData)
	}, [])

	useEffect(() => {
		async function fetchFormData() {
			const responses = await Promise.all([getSettingsActivities(), getPositions(), getBranches()])
			const datas = responses.map(r => r.data.info)
			const newFormData = {}
			newFormData.activities = datas[0]
			newFormData.positions = datas[1]
			newFormData.branches = datas[2]
			newFormData.frequencies = frequencies
			setFormData(newFormData)
		}
		fetchFormData()
	}, [])

	function openActivity(row) {
		return () => {
			history.push(`/activities/${row.id}`)
		}
	}

	function formatFilter(value) {
		return filterActiveWorkers(false).find(worker => worker.id === value)?.name
	}

	function unformatFilter(value) {
		return filterActiveWorkers(false).find(worker => worker.name === value)?.id
	}

	function Active(activity) {
		return moment(activity?.end_date).isAfter(moment())
	}

	function Archived(activity) {
		return moment(activity?.end_date).isBefore(moment())
	}

	function selectOption(option) {
		return () => {
			toggleMenu()
			setCreateOption(option)
		}
	}

	const actions = [{ name: "Ver actividad", icon: KeyboardArrowRight, action: openActivity },]
	const filters = [{ name: "Responsable", label: "user_id", format: formatFilter, unformat: unformatFilter },]
	const tabActivities = activities.filter(tab === 0 ? Active : Archived)
	const planOptions = ["una actividad", "para múltiples centros de trabajo", "para múltiples usuarios", "actividades por cargo"]

	return (
		<Box sx={css.container}>
			<ActivitiesContext.Provider value={{ formData, setActivities }}>
				<Box sx={inline_space}>
					<Typography variant='h1'>Actividades</Typography>
					<IconButton size="large" onClick={toggleMenu} ref={menu}>
						<MoreVert />
					</IconButton>
				</Box>
				<Menu anchorEl={menu.current} open={openMenu} onClose={toggleMenu}>
					{planOptions.map((plan, index) => (
						<MenuItem onClick={selectOption(index)}>
							<Typography variant='subtitle1' color="primary">
								{`Planificar ${plan}`}
							</Typography>
						</MenuItem>
					))}
				</Menu>
				<CreateSingleActivity open={createOption === 0} onClose={() => setCreateOption(null)} />
				<CreateMultipleByBranch open={createOption === 1} onClose={() => setCreateOption(null)} />
				<CreateMultipleByUsers open={createOption === 2} onClose={() => setCreateOption(null)} />
				<CreateFromPosition open={createOption === 3} onClose={() => setCreateOption(null)} />
				<Tabs sx={css.paper} value={tab} onChange={setTab}>
					<Tab label="Activas" />
					<Tab label="Archivadas" />
				</Tabs>
				<GeneralTable data={tabActivities} info={tableInfo} actions={actions} filters={filters} />
			</ActivitiesContext.Provider>
		</Box>
	)
}

export default ActivitiesNew