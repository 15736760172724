import React, { Component } from 'react'
import { Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import { getSubBranchesAction } from '../../Actions/BranchActions'
import { connect } from 'react-redux'
import autobind from '../../Utils/autobind'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { CloudDownloadOutlined } from '@material-ui/icons'

const style = () => ({
  container: {
    padding: 24,
  },
  user: {
    padding: 12
  },
  text: {
    '& > *': {
      margin: '6px 0'
    },
    marginBottom: 12
  },
  users: {
    maxHeight: 350,
    overflow: 'auto'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

class IrresponsableUsers extends Component {
  constructor() {
    super()
    this.state = {
      filters: "Todos"
    }
    this.posibleBranches = ["Todos", "No Contratista"]
    autobind(IrresponsableUsers, this)
  }


  componentDidMount() {
    const { getSubBranches, id, enterprise } = this.props
    this.posibleBranches = ["Todos", enterprise?.selected?.name]
    getSubBranches({ branch_id: id })
  }

  handleChangeFilter(event) {
    const { target } = event
    this.setState({ filters: target.value })
  }

  handleDownload(users) {
    return () => {
      const headers = "Nombre,Rut,Email,Cargo"
      const body = users.map(user => `${user.name.split("-")[0]},${user.name.split("-")[1]},${user.email},${user.position}\n`).join("")
      const file = `${headers}\n${body}`
      var element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=iso-88591,' + file)
      element.setAttribute('download', `UsuariosSinRegistro.csv`)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    }
  }

  render() {
    const { classes, users, open, onClose, branch, enterprise } = this.props
    const { filters } = this.state
    const subBranches = branch.sub_branches || []
    const options = this.posibleBranches.map(branch => ({
      label: branch, value: branch
    }))
    const filteredUsers = users.filter(user => {
      if (filters === "Todos") return true
      if (filters === enterprise?.selected?.name) return !user?.sub_branch_id
      return filters === subBranches.find(sb => sb.id === user.sub_branch_id.toString())?.name
    })

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <div className={classes.container}>
          <div className={classes.text}>
            <Typography variant="h1">Usuarios que no han participado</Typography>
            <Typography variant="caption" color="secondary">
              Los siguientes usuarios no han realizado ninguno de los registros y formularios
              presentes en la tabla, correspondientes a la actividad y registro o formulario seleccionada
            </Typography>
          </div>
          <div className={classes.inline}>
            <SelectInput value={filters} options={options} label="Filtro" onChange={this.handleChangeFilter} />
            <IconButton onClick={this.handleDownload(filteredUsers)}>
              <CloudDownloadOutlined />
            </IconButton>
          </div>
          <Divider />
          <div className={classes.users}>
            {filteredUsers.map(user => {
              const hasSubBranch = !!user?.sub_branch_id
              const subBranch = hasSubBranch ?
                subBranches.find(sb => sb.id === user.sub_branch_id.toString())?.name :
                enterprise?.selected?.name
              if (hasSubBranch) {
                if (!this.posibleBranches.includes(subBranch)) {
                  this.posibleBranches.push(subBranch)
                }
              }

              return (
                <div className={classes.user}>
                  <Typography variant="subtitle1">{user.name} - {subBranch}</Typography>
                </div>
              )
            })}
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  branch: state.branch,
  enterprise: state.enterprise
})

const mapDispatchToProps = dispatch => ({
  getSubBranches: body => dispatch(getSubBranchesAction(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(IrresponsableUsers))