import { Button, withStyles } from '@material-ui/core'
import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import { getWorkersAction } from '../../../Actions/EnterpriseAction'
import { transformToOptions } from '../../../Utils/functions'
import { filterActiveWorkersList } from '../../../Utils/filters'
import autobind from '../../../Utils/autobind'
import { CheckCircleOutline, InfoOutlined } from '@material-ui/icons'

const style = theme => ({
  section: {
    padding: 12
  },
  subsection: {
    maxWidth: 500,
    flexBasis: '100%',
    margin: 12

  },
  participants: {
    background: theme.palette.purple.light,
    padding: 12,
    borderRadius: 12,
    '& > *': {
      color: 'white'
    }
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      color: 'white',
      marginRight: 6
    }
  }
})

class Participants extends React.Component {
  constructor() {
    super()
    this.state = {
      workers: [],
      selected: [],
      hasChanged: false
    }
    autobind(Participants, this)
  }

  async componentDidMount() {
    const { participants = [] } = this.props
    const response = await getWorkersAction().payload
    if (response.data.status !== "success") return null
    const allWorkers = response.data.info
    const filteredWorkers = filterActiveWorkersList(allWorkers)
    return this.setState({ workers: filteredWorkers, selected: participants })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ selected: target.value, hasChanged: true })
  }

  handleSend() {
    const { selected } = this.state
    const { onSubmit, id } = this.props
    const body = {
      id,
      participants: selected
    }
    onSubmit(body)
    this.setState({ hasChanged: false })
  }

  renderSelected() {
    const { classes, element } = this.props
    const { selected, workers } = this.state
    const group_signs = element?.group_signs || []
    const signs_users_id = group_signs?.map(g => g.signs)?.flat()?.map(s => String(s.user_id)) || []
    return workers
      .filter(worker => selected?.map(x => x.toString()).includes(worker.id.toString()))
      .map(user => {
        return (
          <div className={classes.user}>
            {signs_users_id.includes(String(user.id)) && <CheckCircleOutline />}
            <Typography variant="subtitle1">{user.name}</Typography>
          </div>
        )
      })
  }

  render() {
    const { classes } = this.props
    const { workers, selected, hasChanged } = this.state
    return (
      <div className={classes.container}>
        <Paper className={classes.section}>
          <Typography variant="h4">Solicitar firma a:</Typography>
          <div className={classes.flexSection}>
            <div className={classes.subsection}>
              <MultiSelectInput
                label="Participantes"
                options={transformToOptions(workers)}
                value={selected || []}
                onChange={this.handleChange}
                grouped
              />
              {hasChanged &&
                <Button onClick={this.handleSend} color="primary" variant="contained">
                  <InfoOutlined style={{ marginRight: 12 }} />
                  Guardar cambios
                </Button>}
            </div>
            <div className={classes.subsection}>
              <div className={classes.participants}>
                {selected?.length > 0 ?
                  this.renderSelected()
                  :
                  <Typography variant="subtitle1">No hay participantes invitados...</Typography>
                }
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

export default withStyles(style)(Participants)