import React, { Component, useContext, useEffect, useState } from 'react'
import { Button, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../../Utils/autobind'
import TextInput from '../../../Shared/Inputs/TextInput'
import ImgInput from '../../../Shared/Inputs/ImgInput'
import moment from 'moment'
import ImageContainer from '../../../Shared/ImageContainer'
import CreatedFinding from './CreatedFinding'
import ChecklistContext from '../ChecklistContext'
import instance from '../../../Utils/instance'
import { FindInPageOutlined } from '@material-ui/icons'
import DateInput from '../../../Shared/Inputs/DateInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { resizeImageGetFile, transformToOptions } from '../../../Utils/functions'
import UsersInput from '../../../Shared/Inputs/UsersInput'
import useToggle from '../../../Hooks/ToogleHook'
import { getBaseFindingActions, getBaseFindingMeasures, getBaseFindingTimings, getFindingType } from '../../../API/findings&nc'
import DateTimeInput from '../../../Shared/Inputs/DateTimeInput'
import { callSnackbar } from '../../../Utils/snackbar'
import AppContext from '../../../AppContext'

const style = () => ({
	link: {
		fontWeight: 600,
		textDecoration: 'underline',
		cursor: 'pointer',
		margin: '24px 0'
	},
	dialog: {
		padding: '12px 24px',
		'& > *': {
			margin: '12px 0'
		}
	},
	findings: {
		background: 'white',
		borderRadius: 8,
		padding: 12
	},
	container: {
		marginBottom: 12
	}
})

function transformToOptionsByContent(options) {
	return options.map(op => ({ ...op, label: op.content, value: op.id }))
}

function NewFindingFromChecklist({ classes }) {

	const [params, setParams] = useState({ date_limit: moment().format("YYYY-MM-DD HH:mm:ss") })
	const [openCreate, toggleOpenCreate] = useToggle(false)
	const { onCreateFinding, checklist } = useContext(ChecklistContext)
	const [findingTypes, setTypes] = useState([])
	const [baseActions, setActions] = useState([])
	const [baseMeasures, setMeasures] = useState([])
	const [baseTimings, setTimings] = useState([])
	const [loadingImage, setLoading] = useState(false)
	const { online } = useContext(AppContext)

	function handleChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	function handleChangeImage() {
		return async (event) => {
			setLoading(true)
			const { target } = event
			const file = target.value
			const [image, resized] = await resizeImageGetFile(file)

			//Upload File and get the name of the saved one
			const body = new FormData()
			body.append("file", resized)
			console.log(online)
			const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/extra-fields-file.php`
			if (online.status) {
				const response = await instance({ method: 'post', url, data: body })
				params.free_file = response.data?.info?.file_name
				params.file = image
				setParams(params)
				setLoading(false)
			} else {
				params.file = image
				setParams(params)
				setLoading(false)
			}


		}
	}

	function handleCreate() {
		const body = { ...params, id: checklist.id }
		onCreateFinding(body)
		setParams({ date_limit: moment().format('YYYY-MM-DD HH:mm:ss') })
		toggleOpenCreate()
	}

	useEffect(() => {
		async function fetchData() {
			const response = await getFindingType()
			setTypes(response.data.info)
		}

		fetchData()
	}, [])

	useEffect(() => {
		if (!!params.finding_type_id) {
			setParams(p => ({ date_limit: moment().format("YYYY-MM-DD HH:mm:ss"), finding_type_id: p.finding_type_id }))
			async function fetchDefaults() {
				const body = { type: params.finding_type_id }
				const responses = await Promise.all([
					getBaseFindingActions(body),
					getBaseFindingMeasures(body),
					getBaseFindingTimings(body),
				])
				const datas = responses.map(r => r.data.info)
				setActions(datas[0])
				setMeasures(datas[1])
				setTimings(datas[2])
			}

			fetchDefaults()
		}
	}, [params.finding_type_id])

	useEffect(() => {
		if (!!params.base_finding_action_id) {
			const selectedBaseAction = baseActions.find(ba => ba.id === params.base_finding_action_id)
			const selectedTiming = baseTimings.find(t => t.default_criticality === selectedBaseAction?.default_criticality)
			setParams(p => ({ ...p, free_criticality: selectedBaseAction?.default_criticality, free_description: selectedBaseAction?.content, date_limit: moment().add(selectedTiming?.offset_hours || 0, "hours").format("YYYY-MM-DD HH:mm:ss") }))
		}
	}, [params.base_finding_action_id, baseActions, baseTimings])

	useEffect(() => {
		if (!!params.base_finding_measure_id) {
			const selectedBaseMeasure = baseMeasures.find(ba => ba.id === params.base_finding_measure_id)
			setParams(p => ({ ...p, inmediate_action: selectedBaseMeasure.content }))
		}
	}, [params.base_finding_measure_id, baseMeasures])

	useEffect(() => {
		if (!!params.date_limit) {
			const selectedBaseAction = baseActions.find(ba => ba.id === params.base_finding_action_id)
			const selectedTiming = baseTimings.find(t => t.default_criticality === selectedBaseAction?.default_criticality)
			const offset_hours = selectedTiming?.offset_hours || null
			if (!!offset_hours && moment(params.date_limit).diff(moment(), 'hours') > offset_hours) {
				callSnackbar(`La fecha limite no puede ser mayor a ${offset_hours} horas desde el momento de creación`, "warning")
				setParams(p => ({ ...p, date_limit: moment().add(offset_hours, "hours").format("YYYY-MM-DD HH:mm:ss") }))
			}
		}

	}, [params.date_limit, baseActions, baseTimings])

	function validateForm() {
		const to_validate = ["free_description", "inmediate_action", "user_id", "date_limit", "free_criticality"]
		const isValid = to_validate.map(key => !!params[key]).every(Boolean)
		return isValid
	}

	const findings = checklist?.findings || []
	const crits = [
		{ value: 0, label: "No Asignado" },
		{ value: 1, label: 'No Critico' },
		{ value: 2, label: "Poco Critico" },
		{ value: 3, label: "Medianamente Critico" },
		{ value: 4, label: "Altamente Critico" }
	]


	const selectedBaseAction = params.base_finding_action_id ? baseActions.find(ba => ba.id === params.base_finding_action_id) : null
	const filteredMeasures = selectedBaseAction ? baseMeasures.filter(bm => bm.default_criticality === selectedBaseAction.default_criticality) : []

	return (
		<div className={classes.container}>
			<Dialog open={openCreate} onClose={toggleOpenCreate} fullWidth maxWidth="sm">
				<div className={classes.dialog}>
					<Typography variant='h1'>Realizar nuevo Hallazgo</Typography>
					{/* TODO: Poner los inputs necesarios */}
					<SelectInput options={transformToOptions(findingTypes)} value={params.finding_type_id} label="Seleccione tipo de hallazgo" onChange={handleChange} name="finding_type_id" />
					<SelectInput options={transformToOptionsByContent(baseActions)} value={params.base_finding_action_id} label="Descripciones predefinidas" onChange={handleChange} name="base_finding_action_id" />
					<TextInput value={params.free_description} label="Descripción" name="free_description" onChange={handleChange} />
					<SelectInput options={crits} disabled={!!selectedBaseAction?.default_criticality} value={selectedBaseAction?.default_criticality || params.free_criticality} label="Criticidad" name="free_criticality" onChange={handleChange} />
					<SelectInput options={transformToOptionsByContent(filteredMeasures)} value={params.base_finding_measure_id} label="Medidas de control predefinidas" onChange={handleChange} name="base_finding_measure_id" />
					<TextInput value={params.inmediate_action} label="Acción inmediata" name="inmediate_action" onChange={handleChange} />
					<DateTimeInput value={params.date_limit} label="Fecha Limite" name="date_limit" onChange={handleChange} />
					<UsersInput value={params.user_id} label="Responsable" name="user_id" onChange={handleChange} />
					<ImgInput
						value={params.free_file}
						label="Evidencia"
						name="free_file"
						onChange={handleChangeImage()}
						url={`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`}
					/>
					<div style={{ textAlign: 'end' }}>
						<Button color="primary" variant="contained" onClick={handleCreate} disabled={!validateForm() || loadingImage}>
							Crear Hallazgo
						</Button>
					</div>
				</div>
			</Dialog>
			<div className={classes.findings}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{/* <Button variant="outlined" onClick={handleOpenCreate} color="primary" style={{ marginBottom: 12 }} >
						Crear Hallazgo a partir del registro
					</Button> */}
					<Typography variant='h4'>{!!findings.length ? "Hallazgos del registro" : ""}</Typography>
					<IconButton onClick={toggleOpenCreate}>
						<FindInPageOutlined />
					</IconButton>
				</div>
				{findings.map(finding => {
					return (
						<CreatedFinding finding={finding} />
					)
				})}
			</div>
		</div>
	)
}

NewFindingFromChecklist.contextType = ChecklistContext

export default withStyles(style)(NewFindingFromChecklist)