import React, { Component, useContext, useEffect, useState } from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import { CancelOutlined, CheckCircleOutline, WifiOff } from '@material-ui/icons'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'
import newTheme from '../../newTheme'
import AppContext from '../../AppContext'
import { RequestManagerContext } from '../../DatabaseManagers/RequestsManager'
import instance2 from '../../Utils/instance2'
import { transformOfflineRequestToOnline } from '../../Utils/functions'

const css = {
  request: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 12px',
    position: 'relative'
  },
  container: {
    width: 400,
    maxWidth: "100%",
  },
  success: {
    color: newTheme.palette.green.main,
    width: 32,
    height: 32,
  },
  error: {
    color: newTheme.palette.red.main,
    width: 32,
    height: 32,
  }
}

function WifiCard({ open }) {

  const { online: { status: online }, setOnline } = useContext(AppContext)
  const { deleteRequest, addRequest, requests, addRequests } = useContext(RequestManagerContext)
  const filtered_requests = requests.filter(request => request.status !== "saved")

  function renderRequests() {
    return filtered_requests.map(request => {
      let secondary = null
      switch (request?.status) {
        case "pending": {
          secondary = <MiniLoaderAnimator />
          break
        }
        case "success": {
          secondary = <CheckCircleOutline style={css.success} />
          break
        }
        case "error": {
          secondary = <CancelOutlined style={css.error} />
          break
        }
        default: {
          secondary = <WifiOff />
          break
        }
      }
      return (
        <>
          <Box sx={css.request}>
            <Typography variant="subtitle1">{request?.name}</Typography>
            {secondary}
          </Box>
          <Divider />
        </>
      )
    })
  }

  function handleSetOnline(value) {
    return () => {
      const event = new Event("manualconection")
      setOnline(value)
      window.dispatchEvent(event)
    }
  }

  useEffect(() => {
    async function parseRequests() {
      for (const request of filtered_requests) {
        if (request.status === "success") continue
        const new_request = { ...request }
        new_request.status = "pending"
        await addRequest(new_request)
        try {
          const online_request = { ...new_request }
          const new_method = { ...new_request.method }
          const body = await transformOfflineRequestToOnline(new_method.data)
          new_method.data = body
          online_request.method = new_method
          await instance2(online_request.method)
          new_request.status = "success"
          await addRequest(new_request)
        } catch (err) {
          console.log(err)
          new_request.status = "error"
          await addRequest(new_request)
        }
      }
    }

    if (online) {
      console.log(online)
      parseRequests()
    }

  }, [online])

  useEffect(() => {
    async function cleanRequests() {
      const ready_to_save = requests.filter(request => ["success", "error"].includes(request.status))
      for (const request of ready_to_save) {
        const saved_request = { ...request, status: "saved" }
        await addRequest(saved_request)
      }
      const saved_requests = requests.filter(request => request.status === "saved")
      const reverse_requests = [...saved_requests].reverse()
      if (saved_requests.length > 50) {
        const amount_to_be_deleted = reverse_requests.length - 50
        let i = 0
        while (i < amount_to_be_deleted) {
          deleteRequest(reverse_requests[i]?.id)
          i += 1
        }
      }
    }
    if (!open) { cleanRequests() }

  }, [open])




  return (
    <Box sx={css.container}>
      {filtered_requests.length > 0 ?
        renderRequests()
        :
        (
          <Box sx={css.request}>
            <Typography variant="subtitle2">Todos los cambios han sido guardados...</Typography>
          </Box>
        )
      }
      <Box style={{ textAlign: 'end', padding: 12 }}>
        <Button color="secondary" variant="contained" onClick={handleSetOnline(!online)}>
          {online ? "Trabajar sin conexión" : "Trabajar en línea"}
        </Button>
      </Box>
    </Box>
  )
}

export default WifiCard