import { Delete, ErrorOutline } from '@material-ui/icons'
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useContext, useRef } from 'react'
import useToggle from '../../../../Hooks/ToogleHook'
import useChange from '../../../../Hooks/UseStateChange'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import { FindingStructureContext } from './DefaultFindingStructure'

const css = {
	container: {
		background: 'whitesmoke',
		borderRadius: 1,
		padding: 1,
		marginBottom: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	item: {
		display: 'flex',
		alignItems: 'flex-end',
		gap: 1
	},
	buttons: {
		textAlign: 'end'
	}
}

function DefaultAction({ action }) {
	const [content, changeContent] = useChange(action.content)
	const [openMenu, toggleMenu] = useToggle(false)
	const { editAction, crits, deleteAction } = useContext(FindingStructureContext)
	const menu = useRef(null)

	function onBlur() {
		const body = { ...action, content }
		editAction(body)
	}

	function onSelectCrit(value) {
		const body = { ...action, default_criticality: value }
		editAction(body)
		toggleMenu()
	}

	function onDelete() {
		deleteAction(action)
	}

	const buttonText = action.default_criticality ? crits.find(crit => crit.value === action.default_criticality).name : "Seleccionar Criticidad"
	const buttonColor = !!action.default_criticality ? ["info", "middle", "warning", "error"][action.default_criticality - 1] : "inherit"

	return (
		<Box sx={css.container}>
			<SeamlessInput onBlur={onBlur} value={content} onChange={changeContent} color="transparent" placeholder="Ingrese contenido de la acción..." />
			<Box sx={css.buttons} >
				<Button variant="outlined" color={buttonColor} startIcon={<ErrorOutline />} ref={menu} onClick={toggleMenu}>
					{buttonText}
				</Button>
				<IconButton onClick={onDelete}>
					<Delete />
				</IconButton>
			</Box>
			<Menu open={openMenu} anchorEl={menu.current} onClose={toggleMenu}>
				{crits.map(crit =>
					<MenuItem onClick={() => onSelectCrit(crit.value)}>
						<Box sx={css.item}>
							<ErrorOutline style={{ color: crit.color }} />
							<Typography variant='subtitle1' style={{ color: crit.color }} >{crit.name}</Typography>
						</Box>
					</MenuItem>)
				}
			</Menu>
		</Box>
	)
}

export default DefaultAction