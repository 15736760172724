import { AddPhotoAlternateOutlined, Brightness1Outlined, CheckCircle } from '@material-ui/icons'
import { Box, Checkbox, IconButton, Tooltip } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import ImageContainer from '../../../Shared/ImageContainer'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import EvaluationContext from './EvaluationContext'

const css = {
	main: {
		padding: 1
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	checkbox: {
		display: 'flex',
		alignItems: 'center',
	}
}

function BaseOption({ option }) {

	const [params, setParams] = useState({ ...option })
	const { editOption, editOptionPhoto } = useContext(EvaluationContext)
	const inputFile = useRef(null)

	useEffect(() => {
		setParams({ ...option })
	}, [option])

	function changeCorrect() {
		const body = { ...params }
		body.is_correct = !params.is_correct
		editOption(body)
	}

	function onBlur() {
		const body = { ...params }
		editOption(body)
	}

	function selectFile(event) {
		const { target } = event
		const file = target.files[0]
		const body = new FormData()
		body.append("file", file)
		body.append("id", option.id)
		editOptionPhoto(body)
	}

	return (
		<Box sx={css.main}>
			<Box sx={css.container}>
				<input type="file" accept='image/*' hidden onChange={selectFile} ref={inputFile} />
				<Box sx={css.checkbox}>
					<Checkbox icon={<Brightness1Outlined />} color='success' checkedIcon={<CheckCircle />} size="medium" checked={params.is_correct} onClick={changeCorrect} />
					<SeamlessInput placeholder="ingrese texto opción..." onBlur={onBlur} color="transparent" onChange={e => setParams({ ...params, name: e.target.value })} value={params.name} />
				</Box>
				<Tooltip title="Agregar imágen de apoyo para la opción">
					<IconButton onClick={() => inputFile.current.click()}>
						<AddPhotoAlternateOutlined />
					</IconButton>
				</Tooltip>
			</Box>
			{option.photo &&
				<Box style={{ textAlign: 'end', padding: 6 }}>
					<ImageContainer src={option.photo} alt="apoyo-item" height={64} width={64} border={6} />
				</Box>
			}
		</Box>
	)
}

export default BaseOption