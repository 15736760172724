import React, { Component } from 'react'
import { Button, Collapse, Divider, IconButton, Typography, withStyles, Dialog, Checkbox, Tooltip } from '@material-ui/core'
import { Delete, KeyboardArrowDown, Edit, Report, ReportOutlined } from '@material-ui/icons'
import autobind from '../../../../Utils/autobind'
import AddExtraField from './AddExtraField'
import Question from './Question'
import ChangeOptions from './ChangeOptions'

const style = () => ({
  container: {
    padding: '0 12px',
    margin: 12,
    borderRadius: 7
  },
  mainField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 0'
  },
  dialog: {
    padding: 24
  },
  dialogButtons: {
    marginTop: 24,
    textAlign: 'right',
    '& > *': {
      marginLeft: 12
    }
  },
  buttons: {
    '& > *': {
      marginLeft: 6
    }
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 6
    }
  },
  tooltip: {
    '& > *': {
      color: 'white'
    },
    padding: 12
  }
})

function parseOption(option) {
  if (typeof option === "string") {
    const [value, message, color] = option.split('&')
    return (
      <div style={{ display: 'flex' }}>
        <Typography variant="subtitle1">{`${value} ${message ? " - " : ""} `}</Typography>
        {' '}
        {message && <Typography style={{ color }} variant="subtitle1">{` ${message}`}</Typography>}
      </div>
    )
  }
  const { label, message, color } = option
  return (
    <div style={{ display: 'flex' }}>
      <Typography variant="subtitle1">{`${label} ${message ? " - " : ""} `}
        {' '}
        {message && <span style={{ color }} variant="subtitle1">{` ${message}`}</span>}
      </Typography>
    </div>
  )
}

class FieldOption extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      newQuestion: false,
      openEdit: false,
      openDelete: false,
      params: {}
    }
    autobind(FieldOption, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleOpenNewQuestion() {
    this.setState({ newQuestion: !this.state.newQuestion })
  }

  handleCreate(subquestion) {
    const { option } = this.props
    const { subquestions } = option
    subquestions.push(subquestion)
    this.setState({ newQuestion: false })
  }

  handleDeleteSubquestion(subquestionIndex) {
    const { option, index, onEdit } = this.props
    const { subquestions } = option
    subquestions.splice(subquestionIndex, 1)
    onEdit(option, index)
  }

  handleEditSubquestion(subquestion, subquestionIndex) {
    const { option, index, onEdit } = this.props
    const { subquestions } = option
    subquestions[subquestionIndex] = subquestion
    onEdit(option, index)
  }

  renderSubquestions() {
    const { option } = this.props
    const subquestions = option.subquestions || []
    return subquestions.map((subquestion, index) => {
      return <Question
        key={index}
        question={subquestion}
        onDelete={this.handleDeleteSubquestion}
        onEdit={this.handleEditSubquestion}
        index={index}
      />
    })
  }

  handleDelete() {
    const { index, onDelete } = this.props
    onDelete(index)
  }

  handleEdit(body) {
    const { onEdit, index } = this.props
    onEdit(body, index)
    this.setState({ openEdit: false })
  }

  handleSetCutOption() {
    const { onEdit, option, index } = this.props
    const body = { ...option }
    body.isCut = !body?.isCut
    onEdit(body, index)
  }

  handleOpenEdit() {
    this.setState({ openEdit: !this.state.openEdit })
  }

  handleOpenDelete() {
    this.setState({ openDelete: !this.state.openDelete })
  }

  render() {
    const { classes, option, readOnly = false, field } = this.props
    const { open, newQuestion, openEdit, openDelete } = this.state
    const subquestions = option.subquestions || []
    return (
      <div className={classes.container} style={{ margin: readOnly ? 0 : 12, padding: readOnly ? 0 : '0 12px' }} >
        <div className={classes.mainField}>
          <div className={classes.inline}>
            {field?.isCut &&
              <Tooltip title={
                <div className={classes.tooltip}>
                  <Typography variant='h4'>Establecer como opción de corte</Typography>
                  <Typography variant='subtitle1' style={{ marginTop: 6 }}>Cuando cualquiera de las opciones de corte son seleccionadas como respuesta, entonces el registro público levantará una alerta al ser finalizado</Typography>
                </div>
              }>
                <Checkbox
                  checked={option.isCut}
                  onClick={this.handleSetCutOption}
                  size='small'
                  checkedIcon={<Report />}
                  icon={<ReportOutlined />}
                  style={{ padding: 3 }}
                />
              </Tooltip>
            }
            {parseOption(option)}
          </div>
          <div className={classes.buttons}>
            {!readOnly &&
              <IconButton size="small" onClick={this.handleOpen}>
                <KeyboardArrowDown />
              </IconButton>
            }
            <IconButton size="small" onClick={this.handleOpenEdit}>
              <Edit />
            </IconButton>
            <IconButton size="small" onClick={this.handleOpenDelete}>
              <Delete />
            </IconButton>
          </div>
        </div>
        {subquestions.length > 0 && readOnly &&
          <>
            <div className={classes.title}>
              <Typography variant="h4">Subpreguntas creadas</Typography>
            </div>
            {this.renderSubquestions()}
          </>
        }
        <Dialog open={openEdit} onClose={this.handleOpenEdit} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <ChangeOptions option={option} onEdit={this.handleEdit} />
          </div>
        </Dialog>
        <Dialog open={openDelete} onClose={this.handleOpenDelete} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h4">Eliminar opción?</Typography>
            {parseOption(option)}
            <div className={classes.dialogButtons}>
              <Button color="primary" variant="contained" onClick={this.handleOpenDelete}>Cancelar</Button>
              <Button color="primary" variant="outlined" onClick={this.handleDelete}>Eliminar</Button>
            </div>
          </div>
        </Dialog>
        <Collapse in={open}>
          <div className={classes.title}>
            <Typography variant="h4">Relacionar preguntas a la opción seleccionada</Typography>
            <Button onClick={this.handleOpenNewQuestion}>{newQuestion ? "Cancelar" : "Agregar pregunta"}</Button>
          </div>
          <Collapse in={newQuestion}>
            <AddExtraField onCreate={this.handleCreate} final={false} />
          </Collapse>
          <Divider />
          {subquestions.length > 0 &&
            <>
              <div className={classes.title}>
                <Typography variant="h4">Subpreguntas creadas</Typography>
              </div>
              {this.renderSubquestions()}
            </>
          }
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(FieldOption)