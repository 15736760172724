import React, { Component } from "react"
import moment from "moment"
import { KeyboardDateTimePicker } from '@material-ui/pickers/'
import autobind from "../../Utils/autobind"

export default class DateTimeInput extends Component {
    constructor(props) {
        super(props)
        autobind(DateTimeInput, this)
    }
    handleChange(value) {
        const { onChange, name } = this.props
        const result = value?.format('YYYY-MM-DD HH:mm:ss') || 'YYYY-MM-DD HH:mm:ss'
        const event = { target: { name, value: result } }
        onChange(event)
    }
    render() {
        const { value, label, name, disabled } = this.props
        return (
            // <TextField
            //     value={value || moment().format("YYYY-MM-DD")}
            //     onChange={onChange}
            //     label={`${label}${required ? "*" : ""}`}
            //     name={name}
            //     variant="outlined"
            //     fullWidth
            //     style={{ margin: "12px 0" }}
            //     InputLabelProps={{
            //         shrink: true,
            //     }}
            //     type="datetime-local"
            // />
            <KeyboardDateTimePicker
                // disableToolbar
                autoOk
                // disablePast
                label={label}
                name={name}
                fullWidth
                disabled={disabled}
                format="YYYY-MM-DD HH:mm:ss"
                value={value || moment().format("YYYY-MM-DD")}
                onChange={this.handleChange}
                style={{ margin: "12px 0" }}
                inputVariant="outlined"
            />
        )
    }
}
