import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../Shared/Inputs/TextInput'
// import SelectInput from '../../Shared/Inputs/SelectInput'
import DateInput from '../../Shared/Inputs/DateInput'
import autobind from '../../Utils/autobind'
import moment from 'moment'
// import { getBranches } from '../../API/branches'
// import { transformToOptions } from '../../Utils/functions'

const style = () => ({
  container: {
    padding: 24
  }
})

const originalParams = {
  date_start: moment().startOf("month").format("YYYY-MM-DD"),
  date_end: moment().endOf("month").format("YYYY-MM-DD"), // Permanente: 1000-01-01
  parent_id: 0,
  data: "",
  compliance: 0,
  branch_id: 0
}

const validKeys = ["id", "name", "branch_id", "parent_id", "date_start", "date_end", "compliance", "data"]

class NewProgram extends Component {
  constructor() {
    super()
    this.state = {
      branches: [],
      params: { ...originalParams }
    }
    autobind(NewProgram, this)
  }

  async componentDidMount() {
    const { edit } = this.props
    if (edit) {
      const params = {}
      Object.keys(edit).forEach(key => validKeys.includes(key) ? params[key] = edit[key] : null)
      params.parent_id = edit.parent_program_id
      this.setState({ params })
    }
    // const response = await getBranches()
    // const { info: branches } = response.data
    // this.setState({ branches })
  }

  componentDidUpdate(prevProps) {
    const { edit } = this.props
    const { edit: prevEdit } = prevProps
    if (!!edit && (JSON.stringify(edit) !== JSON.stringify(prevEdit))) {
      const newparams = {}
      Object.keys(edit).forEach(key => validKeys.includes(key) ? newparams[key] = edit[key] : null)
      newparams.parent_id = edit.parent_program_id
      this.setState({ params: newparams })
    }
  }

  handleChange(event) {
    const { target: { name, value } } = event
    const { params } = this.state
    params[name] = value
    this.setState({ params })
  }

  handleSend() {
    const { onSubmit, onClose } = this.props
    const { params } = this.state
    const body = { ...params }
    onSubmit(body)
    onClose()
    this.setState({ params: { ...originalParams } })
  }

  render() {
    const { classes, open, onClose, edit } = this.props
    const { params } = this.state
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h4">Crear programa</Typography>
          <div className={classes.input}>
            <TextInput label="Nombre" onChange={this.handleChange} name="name" value={params.name} />
          </div>
          {/* <div className={classes.input}>
            <SelectInput options={transformToOptions(branches)} label="Centro de trabajo" onChange={this.handleChange} name="branch_id" value={params.branch_id} />
          </div> */}
          {/* <div className={classes.input}>
            <DateInput label="Fecha de inicio" onChange={this.handleChange} name="date_start" value={params.date_start} />
          </div>
          <div className={classes.input}>
            <DateInput label="Fecha de término" onChange={this.handleChange} name="date_end" value={params.date_end} />
          </div> */}
          <TextInput type="number" label="Puntaje" onChange={this.handleChange} name="compliance" value={params.compliance} />
          <Button color="primary" variant="contained" onClick={this.handleSend}>
            {!!edit ? "Editar" : "Crear"}
          </Button>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(NewProgram)