import React, { Component, useContext, useState } from 'react'
import moment from 'moment'
import { Box, Dialog, Typography, Button, Divider, Collapse } from '@mui/material'
import { findWorker } from '../../../Utils/filters'
import AppContext from '../../../AppContext'
import useChangeParams from '../../../Hooks/UseStateChangeParams'
import { signActivityChecklist } from '../../../API/activities'
import ImageContainer from '../../../Shared/ImageContainer'
import TextInput from '../../../Shared/Inputs/TextInput'

const css = {
	container: {
		padding: 3,
		'& > *': {
			margin: '12px 0'
		}
	},
	centerButton: {
		textAlign: 'end'
	},
	sign: {
		width: 250,
		height: 150,
		padding: 2,
		border: "solid 1px",
		borderRadius: 2,
		"@media (max-width:500px)": {
			width: '100%',
			boxSizing: 'border-box'
		},
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
}

function SignRegisters({ open, onClose, selectedRows }) {

	const { account } = useContext(AppContext)
	const [reason, changeReason] = useState("")

	async function handleSign() {
		const body = {
			checklist_ids: selectedRows,
			reason,
			date_signed: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
		}
		await signActivityChecklist(body)
		onClose()
	}

	const selectedWorker = findWorker(account?.user?.id)
	const signs = selectedWorker?.signs || []
	const sign = signs[signs.length - 1]
	const url = !!sign?.sign ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign?.sign}` : false
	const hasSigns = signs.length > 0

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={css.container}>
				<Typography variant="h1">Firma de registros y formularios</Typography>
				<Typography variant="subtitle1">¿Firmar usando firma registrada en perfil?</Typography>
				{!hasSigns && <Typography variant="caption">No tienes firmas actualmente registradas en tu perfil</Typography>}
				{hasSigns && <Box sx={css.sign} >
					<ImageContainer src={url || '/noimage.png'} width="140px" />
				</Box>
				}
				<TextInput label="Motivo" name="reason" value={reason} onChange={e => changeReason(e.target.value)} />
				<Box sx={css.centerButton}>
					<Button color="secondary" variant="contained" disabled={!hasSigns} onClick={handleSign}>
						Firmar
					</Button>
				</Box>
				<Divider />
			</Box>
		</Dialog>
	)
}

export default SignRegisters