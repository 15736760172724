import { AccessTimeOutlined, CheckCircleOutline, WifiOff } from '@material-ui/icons'
import { Paper, Box, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { addParticipants } from '../../API/new_checklists'
import AppContext from '../../AppContext'
import { RequestManagerContext } from '../../DatabaseManagers/RequestsManager'
import newTheme from '../../newTheme'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import NewMultiSelectInput from '../../Shared/Inputs/NewMultiSelectInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { filterActiveWorkers } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'
import { ChecklistContext } from './ChecklistView'
import SignsGroups from './Signs/SignsGroups'

const css = {
	container: {
		maxWidth: 800
	},
	paper: {
		padding: 2
	},
	title: {
		margin: "12px 0"
	},
	subtitle: {
		margin: '24px 0 12px 0'
	},
	participants: {
		padding: 2,
		background: newTheme.palette.blue.light,
		borderRadius: 2
	},
	participant: {
		display: 'flex',
		alignItems: 'center',
		margin: '6px 0'
	}
}

function ChecklistSignContent({ value }) {

	const [selected, setSelected] = useState([])

	const { checklist, setChecklist } = useContext(ChecklistContext)
	const { online: { status: online } } = useContext(AppContext)
	const { addRequest } = useContext(RequestManagerContext)

	useEffect(() => {
		setSelected(checklist?.requested_participants || [])
	}, [checklist])

	const workers = filterActiveWorkers()
	const participants = checklist.requested_participants || []

	function addParticipantOffline(body) {
		const new_checklist = { ...checklist }
		new_checklist.requested_participants = selected
		setChecklist(new_checklist)
		addRequest({ name: "Solicitud de firma", method: addParticipants(body, true) })
	}

	async function onParticipantsSelection() {
		const body = { participants: selected, id: checklist?.id }
		if (!online) return addParticipantOffline(body)
		const response = await addParticipants(body)
		setChecklist(response.data.info)
	}

	function getUserName(id) {
		const user = workers.find(w => String(w.id) === String(id))?.name
		return user
	}

	const groups = checklist.group_signs || []
	const allSigns = groups.map(group => group.signs).flat().map(sign => String(sign.user_id))
	return (
		<Box sx={css.container}>
			<Typography variant='h1' sx={css.title}>Solicitar Firmas</Typography>
			<Paper sx={css.paper}>
				<NewMultiSelectInput onBlur={onParticipantsSelection} label="Colaboradores" onChange={e => setSelected(e.target.value)} value={selected} options={transformToOptions(workers)} />
				<Box sx={css.participants}>
					<Typography variant='h4'>Solicitudes</Typography>
					{participants.map(user => {
						const hasSigned = allSigns.includes(String(user))
						let checkIcon = hasSigned ?
							<CheckCircleOutline style={{ color: '#6a6a6a', marginRight: 6 }} /> :
							<AccessTimeOutlined style={{ color: '#6a6a6a', marginRight: 6 }} />
						checkIcon = online ? checkIcon : <WifiOff style={{ color: '#6a6a6a', marginRight: 6 }} />
						return (
							<Box sx={css.participant}>
								{checkIcon}
								<Typography variant='subtitle1'>{getUserName(user)}</Typography>
							</Box>
						)
					})}
				</Box>
				{!online && <Typography variant='caption'>Sin conexión las solicitudes no serán enviadas por correo</Typography>}
			</Paper>
			<Typography variant='h1' sx={css.subtitle}>Firmas registradas</Typography>
			<SignsGroups />
		</Box>
	)
}

export default ChecklistSignContent