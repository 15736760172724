import { InfoOutlined, KeyboardArrowDown } from '@material-ui/icons'
import { Box, Collapse, IconButton, Typography, Button, Dialog } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import { createUserActivityRegister, startUserActivity } from '../../API/users'
import AppContext from '../../AppContext'
import useToggle from '../../Hooks/ToogleHook'
import newTheme from '../../newTheme'

const css = {
	container: {
		background: 'whitesmoke',
		padding: 2,
		borderRadius: 1,
		margin: '12px 0',
		position: 'relative'
	},
	activity: {
		position: 'relative',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
	icons: {
		position: 'absolute',
		top: 0,
		right: 0
	},
	title: {
		marginBottom: 3
	},
	dialog: {
		padding: 3
	},
	button: {
		textAlign: 'end'
	},
	collapse: {
		marginTop: 2
	},
	participation: {
		background: 'white',
		padding: 2,
		borderRadius: 1.5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '6px 0'
	},
	option: {
		background: 'whitesmoke',
		padding: 2,
		borderRadius: 2,
		margin: '12px 0'
	},
	options: {
		maxHeight: 300,
		overflow: 'auto'
	}
}

const recordTypes = [
	"Registro o Formulario",
	"Charla",
	"Capacitación",
	"Evaluación"
]

const frequencies = [
	{ value: "0", label: "Solo una vez", nick: "Período" },
	{ value: "1", label: "Diario", nick: "Día" },
	{ value: "7", label: "Semanal", nick: "Semana" },
	{ value: "15", label: "Quincenal", nick: "Quincena" },
	{ value: "30", label: "Mensual", nick: "Mes" },
	{ value: "60", label: "Bimensual", nick: "Bimestre" },
	{ value: "90", label: "Trimestral", nick: "Trimestre" },
	{ value: "180", label: "Semestral", nick: "Semestre" },
	{ value: "365", label: "Anual", nick: "Año" },
]

function Participation({ participation, activity }) {

	const { history } = useContext(AppContext)

	function gotoParticipation() {
		if (activity.record_type === "0") return history.push(`/checklists/${participation.id}`)
		if (activity.record_type === "1") return history.push(`/talks/${participation.id}`)
		if (activity.record_type === "2") return history.push(`/trainings/${participation.id}`)
		if (activity.record_type === "3") return history.push(`/evaluations/${participation.id}`)
	}

	const date_param = activity.record_type === "3" ? "created_at" : "date_scheduled"

	return (
		<Box sx={css.participation}>
			<Typography variant='subtitle1'>{moment(participation?.[date_param]).format("DD [de] MMM [del] YYYY")}</Typography>
			<Button color="primary" variant="outlined" size="small" onClick={gotoParticipation}>
				Ver Participación
			</Button>
		</Box>
	)
}

function WorkerBaseActivity({ activity, disabled, user_id }) {

	const [openInfo, toggleInfo] = useToggle(false)
	const [openCreateNew, toggleCreate] = useToggle(false)
	const [createdOptions, setOptions] = useState([])
	const { history } = useContext(AppContext)

	function getRecordTypeName(id) {
		const name = recordTypes[id]
		return name || "Sin nombre"
	}

	function getFrequencyName(value) {
		const freq = frequencies.find(f => f.value === value)
		return freq.label
	}

	function getFrequencyLabel(value) {
		const freq = frequencies.find(f => f.value === value)
		return freq.nick
	}

	function checkIfItsComply() {
		const date_params = activity.record_type !== "3" ? "date_scheduled" : "created_at"
		if (!activity.participations.length) return 2
		let isCreated = false
		switch (activity.frequency) {
			case "0": { isCreated = !!activity?.participations.length; break }
			case "1": { isCreated = moment(activity?.participations[0]?.[date_params]).isSame(moment(), "day"); break }
			case "7": { isCreated = moment(activity?.participations[0]?.[date_params]).isSame(moment(), "week"); break }
			case "15": {
				const start = moment().endOf("week").subtract("2", "weeks")
				const end = moment().endOf("week")
				isCreated = moment(activity?.participations[0]?.[date_params]).isBetween(start, end)
				break
			}
			case "30": { isCreated = moment(activity?.participations[0]?.[date_params]).isSame(moment(), "month"); break }
			case "60": {
				const start = moment().endOf("month").subtract("2", "months")
				const end = moment().endOf("month")
				isCreated = moment(activity?.participations[0]?.[date_params]).isBetween(start, end)
				break
			}
			case "90": {
				const start = moment().endOf("month").subtract("3", "months")
				const end = moment().endOf("month")
				isCreated = moment(activity?.participations[0]?.[date_params]).isBetween(start, end)
				break
			}
			case "180": {
				const start = moment().startOf("year")
				const end = moment().startOf("year").add("6", "months")
				isCreated = moment(activity?.participations[0]?.[date_params]).isBetween(start, end)
				break
			}
			case "365": { isCreated = moment(activity?.participations[0]?.[date_params]).isSame(moment(), "year"); break }
			default: { isCreated = false; break }
		}

		function checkDone(participation, r_type) {
			if (r_type === "0") return participation.date_done !== "0000-00-00"
			if (r_type === "1") return !moment(participation.date_saved).format("YYYY-MM-DD").includes("Fecha")
			if (r_type === "2") return !moment(participation.date_saved).format("YYYY-MM-DD").includes("Fecha")
			if (r_type === "3") return !!participation.finished

		}

		if (!isCreated) return 2
		if (isCreated && !checkDone(activity.participations[0], activity.record_type)) return 1
		return 0
	}

	async function createOneNow() {


		if (activity.record_type === "3") {
			return createNewRegister()
		}
		const body = { ...activity, user_id }
		const response = await startUserActivity(body)
		const { data: { info } } = response
		setOptions(info.created)
		toggleCreate()
	}

	async function createNewRegister(option) {
		const body = { activity, option, user_id }
		if (activity.record_type === "1") return history.push(`/talks/${option.id}`)
		if (activity.record_type === "2") return history.push(`/trainings/${option.id}`)
		const response = await createUserActivityRegister(body)
		const { data: { info } } = response
		if (activity.record_type === "0") return history.push(`/checklists/${info.id}`)
		if (activity.record_type === "3") return history.push(`/evaluations/${info.id}`)
	}

	const complianceIndex = checkIfItsComply()
	const colors = [newTheme.palette.green.main, newTheme.palette.middle.main, newTheme.palette.error.main]

	return (
		<Box sx={css.container}>
			<Box sx={css.activity}>
				<Box>
					<Typography sx={css.title} style={{ color: colors[complianceIndex] }} variant='h4'>{activity?.name}</Typography>
					<Typography variant='caption'>{getRecordTypeName(activity.record_type)}</Typography>
				</Box>
				<Typography variant='subtitle1' color="secondary"><strong>{getFrequencyName(activity.frequency)}</strong></Typography>
			</Box>
			{complianceIndex === 2 &&
				<Box style={{ display: 'flex', alignItems: 'center', marginTop: 12, justifyContent: 'space-between', flexWrap: 'wrap', gap: 12 }}>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<InfoOutlined color="error" style={{ marginRight: 6 }} />
						<Typography variant='subtitle1' style={{ color: newTheme.palette.error.main, fontWeight: 600 }}>
							Aún no se ha participado en un registro durante este/a {getFrequencyLabel(activity.frequency)}
						</Typography>
					</Box>
					<Box style={{ textAlign: 'end', flexGrow: 1 }}>
						<Button color="error" variant="outlined" size="small" style={{ marginLeft: 12 }} onClick={createOneNow} disabled={disabled}>
							{disabled ? "Se debe cumplir con la documentación anterior" : "Realizar ahora"}
						</Button>
					</Box>
				</Box>
			}
			<Box sx={css.icons}>
				<IconButton onClick={toggleInfo}>
					<KeyboardArrowDown />
				</IconButton>
			</Box>
			<Collapse in={openInfo}>
				<Box sx={css.collapse}>
					<Typography variant='h4'>Participaciones ({activity.participations?.length}):</Typography>
					{activity.participations.map(participation => <Participation participation={participation} activity={activity} />)}
				</Box>
			</Collapse>
			<Dialog open={openCreateNew} onClose={toggleCreate} maxWidth="sm" fullWidth>
				<Box sx={css.dialog}>
					<Typography variant='h4' style={{ margin: '12px 0' }} >Opciones de actividades disponibles</Typography>
					<Box sx={css.options}>
						{!!createdOptions.length ?
							createdOptions.map(option => {
								return (
									<Box sx={css.option}>
										<Typography variant='subtitle1'>{option.name}</Typography>
										<Typography variant='caption'>Iniciada el: {moment(option.date_scheduled || option.date_start).format("YYYY-MM-DD HH:mm")}</Typography>
										<Box style={{ textAlign: 'end' }}>
											<Button color="primary" variant="outlined" size="small" onClick={() => createNewRegister(option)}>
												{activity.record_type === "0" ? "Crear nuevo registro" : "Ingresar a ver"}
											</Button>
										</Box>
									</Box>
								)
							})
							:
							<Typography variant='subtitle1'>No hay actividades creadas relacionadas al registro</Typography>
						}
					</Box>
				</Box>
			</Dialog>
		</Box >
	)
}

export default WorkerBaseActivity