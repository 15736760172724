import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'

const style = (theme) => ({
  container: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 8,
    padding: 12,
    margin: 6,
    cursor: 'pointer',
    '& > *': {
      color: theme.palette.secondary.main,
      fontWeight: 600
    },
    background: 'white',
    transition: 'all 0.2s linear',
    '&:hover': {
      transform: 'scale(1.01)'
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selected: {
    background: theme.palette.secondary.main,
    '& > *': {
      color: 'white'
    },
  }
})
class FilterTag extends Component {

  render() {
    const { classes, element, onSelect, selected } = this.props
    const isSelected = selected.includes(element.id)
    return (
      <div className={`${classes.container} ${isSelected ? classes.selected : ""}`} onClick={onSelect(element.id)}>
        <Typography variant='subtitle1'>{element.id !== "0" ? element.name : "No ingresado"}</Typography>
        {isSelected && <CheckCircleOutline />}
      </div>
    )
  }
}

export default withStyles(style)(FilterTag)