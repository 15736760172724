import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import autobind from '../Utils/autobind'

const style = () => ({
  container: {
    padding: 12,
    textAlign: 'center',
    '& > *': {
      margin: "3px 0",
    },
    '& button': {
      marginTop: 16
    }
  }

})

class UpdateDialog extends Component {
  constructor() {
    super()
    autobind(UpdateDialog, this)
  }

  componentDidMount() {
    const { open } = this.props
    if (open) return this.handleUpdate()
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props
    const { open: prevOpen } = prevProps
    if (open && open !== prevOpen) return this.handleUpdate()
  }

  async handleUpdate() {
    // alert("La aplicación se actualizará")
    console.log("Actualizando")
    const registrations = await navigator.serviceWorker.getRegistrations()
    console.log("Deleting old service workers...")
    for (let registration of registrations) {
      console.log(registration)
      await registration.unregister()
    }
    console.log("Deleting cache...")
    await caches.delete("cache-tazki")
    console.log("registering new service worker...")
    await navigator.serviceWorker.register("/sw.js")
    const response = await fetch("/meta.json")
    const meta = response.json()
    const latestVersion = meta.version
    localStorage.setItem("version", latestVersion)
    window.location.reload()
  }

  render() {
    const { classes, open, onClose } = this.props
    return (
      <Dialog open={false} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h1">¡Hola!</Typography>
          <Typography variant="subtitle1">Tenemos una nueva versión y necesitamos que actualices la app</Typography>
          <Typography variant="body1">Para ello presiona el botón a continuación</Typography>
          <Button color="primary" variant="outlined" size="small" onClick={this.handleUpdate}>¡Actualizar!</Button>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(UpdateDialog))