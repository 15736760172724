import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'

const style = () => ({
  container: {
    padding: 24,
    '& > *': {
      margin: '6px 0'
    }
  }
})
class DeleteChecklist extends Component {
  render() {
    const { classes, open, onClose, event, onSubmit } = this.props
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant='h4'>¿Eliminar registro {event?.preventive_measure}?</Typography>
          <div style={{ textAlign: 'end' }}>
            <Button variant="outlined" color="primary" onClick={() => {
              onClose()
              onSubmit(event)()
            }}>
              Eliminar
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(DeleteChecklist)