import { GetApp, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { csv_to_excel } from '../../API/enterprise'
import newTheme from '../../newTheme'
import SelectInput from '../Inputs/SelectInput'
import LoaderAnimator from '../LoaderAnimator'

const css = {
	paper: {
		padding: 2
	},
	calendar: {
		display: 'grid',
		gridTemplateColumns: "minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr)",
		overflow: 'auto',
		'& > *': {
			padding: '8px 6px',
			borderBottom: '1px solid lightgrey'
		},
		position: 'relative',
		maxHeight: 500
	},
	filters: {
		display: 'flex',
		alignItems: 'center'
	},
	buttons: {
		display: 'flex',
		alignItems: 'center'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function getWeekSteps(steps) {
	const results = []
	steps.forEach(step => {
		const start = moment().startOf("month").startOf("week")
		const start_date = start.add(step, "week")
		const end_date = moment(start_date).endOf("week")
		const is_actual = start_date.isSame(moment(), "week")
		results.push({
			start_date: start_date.format("YYYY-MM-DD"),
			end_date: end_date.format("YYYY-MM-DD"),
			is_actual
		})
	})
	return results
}

function getMonthSteps(steps) {
	const results = []
	steps.forEach(step => {
		const start = moment().startOf("month")
		const start_date = start.add(step, "month")
		const end_date = moment(start_date).endOf("month")
		const is_actual = start_date.isSame(moment(), "month")
		results.push({
			start_date: start_date.format("YYYY-MM-DD"),
			end_date: end_date.format("YYYY-MM-DD"),
			is_actual
		})
	})
	return results
}

function getDailySteps(steps) {
	const results = []
	steps.forEach(step => {
		const start = moment().startOf("week")
		const start_date = start.add(step, "day")
		const end_date = moment(start_date).endOf("day")
		const is_actual = start_date.isSame(moment(), "day")
		results.push({
			start_date: start_date.format("YYYY-MM-DD"),
			end_date: end_date.format("YYYY-MM-DD"),
			is_actual
		})
	})
	return results
}

function PeriodicalReportTable({
	fetchEndpoint,
	branches,
	element,
	title = "Resumen",
	subtitle = "Centro de trabajo",
	lastRowCallback = (col) => { return col.reduce((x, y) => Number.isFinite(y) ? x + y : 0, 0) },
	exportCallback = null,
}) {

	const [data, setData] = useState([])
	const [granularity, setGranularity] = useState("week")
	const [offset, setOffset] = useState(0)
	const [loading, setLoading] = useState(false)

	const step_values = [0, 1, 2, 3, 4].map(s => s + offset)
	let format = ""
	let steps = []
	if (granularity === "week") { steps = getWeekSteps(step_values); format = "[Semana] DD MMM" }
	if (granularity === "month") { steps = getMonthSteps(step_values); format = "MMMM YYYY" }
	if (granularity === "day") { steps = getDailySteps(step_values); format = "dddd-DD" }

	useEffect(() => {

		async function fetchData() {
			const request_body = branches.map((branch) => {
				return steps.map(step => {
					return { ...step, branch_id: branch.id, id: element?.id }
				})
			})
			setLoading(true)
			const response = await fetchEndpoint({ data: request_body, id: element?.id })
			setData(response.data.info)
			setLoading(false)
		}

		if (!!branches.length && !!element) { fetchData() }

	}, [offset, granularity, element, branches, fetchEndpoint])

	async function exportTable() {

		if (!!exportCallback) {
			const request_body = branches.map((branch) => {
				return steps.map(step => {
					return { ...step, branch_id: branch.id, id: element?.id }
				})
			})

			const response = await exportCallback({ data: request_body, id: element?.id, name: "Reporte registros por periodo" })
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${"Reporte registros por periodo"}.xlsx`)
			document.body.appendChild(link)
			link.click()

		} else {

			const first_line = [subtitle + ";" + steps.map(step => `${moment(step.start_date).format(format)}`).join(";")]
			const other_lines = branches.map((branch, index) => {
				const line_data = [branch.name].concat(steps.map((step, sub_index) => String(data?.[index]?.[sub_index]).replace(".", ","))).join(";")
				return line_data
			})
			const final_file = first_line.concat(other_lines).join("\n")

			const response = await csv_to_excel({ csv: final_file, name: "Reporte registros por periodo" })
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `${"Reporte registros por periodo"}.xlsx`)
			document.body.appendChild(link)
			link.click()
		}
	}

	function getLastRow() {
		const result = [[], [], [], [], []]
		branches.forEach((branch, index) => {
			steps.forEach((step, sub_index) => {
				const element = data?.[index]?.[sub_index]
				result[sub_index].push(element)
			})
		})
		const final = result.map(col => lastRowCallback(col))
		return final

	}

	const totals = getLastRow()

	return (
		<Box>
			<Box sx={css.paper}>
				<Box sx={css.header}>
					<Typography variant='h4' style={{ marginBottom: 12 }} >{title}</Typography>
					<Button color="secondary" variant="outlined" size="small" endIcon={<GetApp />} onClick={exportTable}>
						Exportar
					</Button>
				</Box>
				<Box sx={css.filters}>
					<SelectInput
						label="Visualización"
						options={[{ label: "Diaria", value: "day" }, { label: "Semanal", value: "week" }, { label: "Mensual", value: "month" }]}
						onChange={e => setGranularity(e.target.value)}
						value={granularity}
					/>
					<Box sx={css.buttons}>
						<IconButton onClick={() => setOffset(offset - 1)}>
							<KeyboardArrowLeft />
						</IconButton>
						<Button onClick={() => setOffset(0)}>
							HOY
						</Button>
						<IconButton onClick={() => setOffset(offset + 1)}>
							<KeyboardArrowRight />
						</IconButton>
					</Box>
				</Box>
				<Box sx={css.calendar}>
					<Box style={{ zIndex: 1, position: 'sticky', top: 0, background: 'whitesmoke' }}>
						<Typography variant='subtitle1' style={{ fontWeight: 600 }}>{subtitle}</Typography>
					</Box>
					{steps.map(step => {
						return (
							<Box style={{ zIndex: 1, position: 'sticky', top: 0, background: 'whitesmoke' }}>
								<Typography variant='subtitle1' style={{ fontWeight: 600, color: step.is_actual ? newTheme.palette.info.main : "" }}>{
									`${moment(step.start_date).format(format)}`
								}</Typography>
							</Box>
						)
					})}
					{
						<>
							{branches.map((branch, index) => {
								return (
									<>
										<Box style={{ position: 'sticky', left: 0, background: 'whitesmoke' }}>
											<Typography variant='subtitle1'>{branch.name}</Typography>
										</Box>
										{steps.map((step, sub_index) => {
											return (
												<Box>
													<Typography style={{ fontWeight: 600, color: step.is_actual ? newTheme.palette.info.main : "" }} variant='subtitle1'>
														{!loading ? data?.[index]?.[sub_index] : ""}
													</Typography>
												</Box>
											)
										})}
									</>
								)
							})}
							<>
								<Box style={{ position: 'sticky', left: 0, bottom: 0, background: 'whitesmoke', zIndex: 2 }}>
									<Typography variant='subtitle1'>Totales</Typography>
								</Box>
								{steps.map((step, sub_index) => {
									return (
										<Box style={{ position: 'sticky', bottom: 0, background: 'whitesmoke' }}>
											<Typography style={{ fontWeight: 600, color: step.is_actual ? newTheme.palette.info.main : "" }} variant='subtitle1'>
												{totals[sub_index]}
											</Typography>
										</Box>
									)
								})}
							</>
							{loading &&
								<LoaderAnimator />
							}
						</>
					}
				</Box>
			</Box>
		</Box>
	)
}

export default PeriodicalReportTable