import { Box, Button, Collapse, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { createFamily, createType, editFamily, editType, getSettingsSupplies } from '../../../API/supplies'
import useToggle from '../../../Hooks/ToogleHook'
import AddElementButton from '../../../Shared/AddElementButton'
import TextInput from '../../../Shared/Inputs/TextInput'
import Family from './Family'

const css = {
	container: {
		padding: 2
	},
	title: {
		marginBottom: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	paper: {
		padding: 2
	},
	button: {
		marginTop: 1,
		textAlign: 'end'
	}
}

function SuppliesSettings({ value }) {

	const [families, setFamilies] = useState([])
	const [openCreate, toggleCreate] = useToggle(false)
	const [name, setName] = useState("")

	useEffect(() => {
		async function fetchData() {
			const response = await getSettingsSupplies()
			const { data: { info } } = response
			setFamilies(info)
		}

		fetchData()
	}, [])

	async function onCreate() {
		const body = { name }
		const response = await createFamily(body)
		const { data: { info } } = response
		setFamilies(info)
		setName("")
		toggleCreate()
	}

	async function onCreateType(body) {
		const response = await createType(body)
		const { data: { info } } = response
		setFamilies(info)
	}

	async function onEditFamily(body) {
		const response = await editFamily(body)
		const { data: { info } } = response
		setFamilies(info)
	}

	async function onEditType(body) {
		const response = await editType(body)
		const { data: { info } } = response
		setFamilies(info)
	}

	return (
		<Box sx={css.container}>
			<Box sx={css.header}>
				<Typography sx={css.title} variant='h1'>Configuración de Insumos y Maquinaria</Typography>
				<AddElementButton name="Agregar Familia" open={openCreate} onClick={toggleCreate} />
			</Box>
			<Collapse in={openCreate}>
				<Paper sx={css.paper}>
					<TextInput name="name" onChange={(e) => setName(e.target.value)} value={name} label="Nombre nueva familia" />
					<Box sx={css.button}>
						<Button color="primary" variant="contained" onClick={onCreate} disabled={!name}>
							Crear familia
						</Button>
					</Box>
				</Paper>
			</Collapse>
			{!families.length && <Typography variant='subtitle1'>No existen familias de insumos o maquinarias en el sistema...</Typography>}
			{families.map(family => <Family family={family} onEdit={onEditFamily} onCreateType={onCreateType} onEditType={onEditType} />)}
		</Box>
	)
}

export default SuppliesSettings