import React, { Component } from "react"
import propTypes from "prop-types"
import Chart from "chart.js"
import "./Setup"
import { Typography, withStyles } from "@material-ui/core"
import Conditional from "../Conditional"

const style = () => ({
	labels: {
		marginTop: 24,
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		maxHeight: 100,
	},
	label: {
		display: "flex",
		alignItems: "center",
	},
	dot: {
		width: 14,
		height: 14,
		borderRadius: "50%",
		marginRight: 12,
	},
})

class BarChart extends Component {
	componentDidMount() {
		const { name, data, options } = this.props
		const chartElement = document.getElementById(name)
		const myChartRef = chartElement.getContext("2d")
		chartElement.parentNode.style.height = options.height || "100%"
		chartElement.parentNode.style.width = options.width || "100%"

		const labels = data.map((element) => element.label)
		const values = data.map((element) => element.value)
		const colors = data.map((element) => element.color)
		// eslint-disable-next-line no-new
		this.chart = new Chart(myChartRef, {
			type: options.type || "bar",
			data: {
				// Bring in data
				labels,
				datasets: [
					{
						data: values,
						barThickness: options.barThickness,
						backgroundColor: colors,
					},
				],
			},
			options: {
				maintainAspectRatio: false,
				responsive: true,
				tooltips: {
					enabled: options.enableTooltip || false,
				},
				hover: {

				},
				legend: {
					display: false,
				},
				onClick: (event, item) => {
					options.onClick && options.onClick(item)
				},
				scales: {
					xAxes: [
						{
							ticks: {
								display: !options.detachedLabels, // Detach here
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
								min: options.minValue || 0,
								max: options.maxValue || 0,
								stepSize: options.stepSize || 5,
								callback: (value) => {
									return value.slice(0, 8) + "..."
								}
							},
							gridLines: {
								display: options.xEnableGrid || false,
								drawBorder: false,
							},
							scaleLabel: {
								display: options.xHasTitle,
								labelString: options.xTitle,
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
							},
						},
					],
					yAxes: [
						{
							ticks: {
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
								display: true,
								min: options.minValue || 0,
								max: options.maxValue || 0,
								stepSize: options.stepSize || 5,
							},
							gridLines: {
								display: options.yEnableGrid || false,
								drawBorder: false,
							},
							scaleLabel: {
								display: options.yHasTitle,
								labelString: options.yTitle,
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
							},
						},
					],
				},
				animation: {
					onComplete: () => {
						const chartInstance = this.chart
						const { ctx } = chartInstance
						ctx.font = 'Montserrat, sans-serif'
						ctx.textAlign = 'center'
						ctx.textBaseline = 'bottom'

						this.chart.data.datasets.forEach((dataset, i) => {
							const meta = chartInstance.controller.getDatasetMeta(i)
							meta.data.forEach((bar, index) => {
								const metadata = dataset.data[index]
								// eslint-disable-next-line no-underscore-dangle
								if (options.type === 'horizontalBar') {
									ctx.fillText(metadata, bar._model.x + 12, bar._model.y + 5)
								} else {
									ctx.fillText(metadata, bar._model.x, bar._model.y - 5)
								}
							})
						})
					}
				}
			},
		})
	}

	componentDidUpdate(prevProps) {
		const { data, options } = this.props
		if (JSON.stringify(prevProps.data) !== JSON.stringify(data) || JSON.stringify(prevProps.options) !== JSON.stringify(options)) {
			const labels = data.map((element) => element.label)
			const values = data.map((element) => element.value)
			const colors = data.map((element) => element.color)
			this.chart.data.labels = labels
			this.chart.data.datasets = [{
				data: values,
				barThickness: options.barThickness,
				backgroundColor: colors
			}]

			this.chart.options = {
				maintainAspectRatio: false,
				responsive: true,
				tooltips: {
					enabled: options.enableTooltip || false,
				},
				hover: {

				},
				legend: {
					display: false,
				},
				onClick: (event, item) => {
					options.onClick && options.onClick(item)
				},
				scales: {
					xAxes: [
						{
							ticks: {
								display: !options.detachedLabels, // Detach here
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
								min: options.minValue || 0,
								max: options.maxValue || 0,
								stepSize: options.stepSize || 5,
								callback: (value) => {
									if (value.length > 11) return value.slice(0, 8) + "..."
									return value
								}
							},
							gridLines: {
								display: options.xEnableGrid || false,
								drawBorder: false,
							},
							scaleLabel: {
								display: options.xHasTitle,
								labelString: options.xTitle,
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
							},
						},
					],
					yAxes: [
						{
							ticks: {
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
								display: true,
								min: options.minValue || 0,
								max: options.maxValue || 0,
								stepSize: options.stepSize || 5,
								// callback(value) {
								//   return `${options[value]}    `;
								// }
							},
							gridLines: {
								display: options.yEnableGrid || false,
								drawBorder: false,
							},
							scaleLabel: {
								display: options.yHasTitle,
								labelString: options.yTitle,
								fontFamily: "Montserrat, sans-serif",
								fontStyle: "bold",
							},
						},
					],
				},
				animation: {
					onComplete: () => {
						const chartInstance = this.chart
						const { ctx } = chartInstance
						ctx.font = 'Montserrat, sans-serif'
						ctx.textAlign = 'center'
						ctx.textBaseline = 'bottom'

						this.chart.data.datasets.forEach((dataset, i) => {
							const meta = chartInstance.controller.getDatasetMeta(i)
							meta.data.forEach((bar, index) => {
								const metadata = dataset.data[index]
								// eslint-disable-next-line no-underscore-dangle
								if (options.type === 'horizontalBar') {
									ctx.fillText(metadata, bar._model.x + 12, bar._model.y + 5)
								} else {
									ctx.fillText(metadata, bar._model.x, bar._model.y - 5)
								}
							})
						})
					}
				}
			}

			this.chart.update()
		}
	}

	renderLabels() {
		const { classes, data } = this.props
		return data.map((element, index) => {
			const toReturn = (
				<div className={classes.label} key={index}>
					<div
						className={classes.dot}
						style={{ background: element.color }}
					/>
					<Typography variant="body1" style={{ fontWeight: 600 }}>
						{element.label}
					</Typography>
				</div>
			)
			return toReturn
		})
	}

	render() {
		const { name, title, classes, options, showTitle } = this.props
		return (
			<div>
				{showTitle && (
					<Typography
						variant="subtitle1"
						color="primary"
						style={{ textAlign: "start", marginBottom: 24 }}
					>
						{title}
					</Typography>
				)}
				<div style={{ height: 400 }}>
					<canvas id={name} ref={this.chartRef} />
				</div>
				<Conditional condition={options.detachedLabels}>
					<div className={classes.labels}>{this.renderLabels()}</div>
				</Conditional>
			</div>
		)
	}
}

BarChart.propTypes = {
	title: propTypes.string,
	name: propTypes.string.isRequired,
	classes: propTypes.object.isRequired,
	data: propTypes.arrayOf(propTypes.object),
	options: propTypes.object,
	showTitle: propTypes.bool,
}

BarChart.defaultProps = {
	title: "Gráfico",
	showTitle: true,
	data: [],
	options: {
		height: "200px",
		width: "50%",
		barThickness: 64,
		enableHover: false,
		detachedLabels: true,
		minValue: 0,
		maxValue: 100,
		stepSize: 20,
	},
}

export default withStyles(style)(BarChart)
