import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Chart from 'chart.js'

const style = () => ({

})
class DonutChart extends Component {
  componentDidMount() {
    const { name, data, color, onClick } = this.props
    const chartElement = document.getElementById(name)
    const myChartRef = chartElement.getContext("2d")
    chartElement.parentNode.style.width = "100%"
    this.chart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: ["Avance de actividades"],
        datasets: [{
          data,
          backgroundColor: color,
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        rotation: 1 * Math.PI,
        circumference: 2 * Math.PI,
        cutoutPercentage: 75,
        legend: {
          display: false
        },
        tooltips: false,
        onClick: (event, item) => {
          onClick && onClick(item)
        },
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { data, color } = this.props
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      this.chart.data.datasets = [{
        data,
        backgroundColor: color
      }]
      this.chart.update()
    }
  }

  render() {
    const { classes, name } = this.props
    return (
      <div className={classes.container}>
        <canvas id={name} ref={this.chartRef} />
      </div>
    )
  }
}

DonutChart.propTypes = {

}


DonutChart.defaultProps = {
  name: "donut-chart",
  color: ['#91deb1', '#ff6c87'],
}

export default withStyles(style)(DonutChart)