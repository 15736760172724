import { resizeImageAndGetFile } from "../../Utils/functions"
import instance from "../../Utils/instance"


export default class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader
  }

  // Starts the upload process.
  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_IMG_URL}/api/checklists/extra-fields-file.php`
        const final_url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`



        const callback = (image, newFile) => {
          const body = new FormData()
          body.append("file", newFile)
          return instance({
            method: 'post',
            url,
            data: body
          })
            .then((response) => {
              if (response.data.status === "success") {
                this.loader.uploaded = true
                resolve({
                  default: `${final_url}/${response.data.info.file_name}`
                })
              } else {
                reject(`Couldn't upload file: ${newFile.name}.`)
              }
            })
        }

        return resizeImageAndGetFile(file, callback)
      }

      ))
  }
}
