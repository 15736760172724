import { WifiOff } from '@material-ui/icons'
import { Box, Badge, Button, Paper, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { FiAlertCircle, FiCheckSquare, FiClock, FiLogOut } from 'react-icons/fi'
import { getUserPendings } from '../../API/users'
import AppContext from '../../AppContext'
import { ChecklistManagerContext } from '../../DatabaseManagers/ChecklistsManager'
import newTheme from '../../newTheme'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import { inline } from '../../Utils/defaultStyles'
import OtherBranchPendings from './Pendings/OtherBranchPendings'
import Pending from './Pendings/Pending'
import { transformChecklistToPendings, transformDocumentsToPendings, transformEvaluationsToPendings, transformFindingsToPendings, transformNonConformitiesToPendings, transformTalksToPendings, transformTrainingsToPendings } from './Pendings/pendingUtils'

const css = {
  message: {
    padding: 4,
    fontSize: 14
  },
  filter: {
    '& > svg': {
      height: 24,
      width: 24
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F2F5FF !important',
    borderRadius: 2,
    padding: 2,
    height: 56,
    width: 56,
    transition: 'all 0.2s ease-in-out !important',
    cursor: 'pointer'
  },
  filters: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '24px 0',
    gap: 2,
  },
  selected: {
    transform: 'scale(1.08)',
    '& > *': {
      color: 'white !important'
    }
  },
  badge: {
    background: '#6e6e6e'
  },
  green: {
    background: `${newTheme.palette.green.main} !important`,
  },
  red: {
    background: `${newTheme.palette.red.main} !important`,
  },
  blue: {
    background: `${newTheme.palette.blue.main} !important`,
  },
  desktopContainer: {
    minHeight: 460,
  },
  mainContainer: {
    background: 'white',
    borderRadius: 2,
    padding: 2,
    margin: '12px 0',
    position: 'relative'
  },
  title: {
    color: ` ${newTheme.palette.secondary.light} !important`,
    fontSize: "20px !important"
  },
  pendings: {
    maxHeight: 450,
    overflow: 'auto'
  },
  floating: {
    position: 'absolute',
    top: 12,
    right: 12
  }
}

function NewPendings({ value }) {

  const [selectedFilter, setFiler] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { account, history, online: { status: online } } = useContext(AppContext)
  const { setChecklists, getChecklists } = useContext(ChecklistManagerContext)

  useEffect(() => {
    async function fetchData() {
      const body = {
        date_start: moment().startOf("week").subtract(5, 'days').format("YYYY-MM-DD"),
        date_end: moment().endOf("week").format("YYYY-MM-DD")
      }
      setLoading(true)
      const response = await getUserPendings(body)
      const datas = response.data.info
      setData(datas)
      setLoading(false)
      const { checklists } = datas
      setChecklists(checklists)
    }

    async function fetchOffline() {
      setLoading(true)
      const datas = {}
      const response = await getChecklists()
      datas.checklists = response
      setData(datas)
      setLoading(false)
    }

    function fetchFunction() {
      console.log(online)
      if (online) { fetchData() }
      else { fetchOffline() }
    }

    fetchFunction()

    window.addEventListener("branch_change", fetchFunction)
    return () => {
      window.removeEventListener("branch_change", fetchFunction)
    }
  }, [online])

  function selectFilter(value) {
    return () => {
      setFiler(value)
    }
  }

  function openPending(link) {
    return () => {
      history.push(link)
    }
  }

  function renderPendings() {
    const isMobile = window.innerWidth <= 500
    const allPendings = getAllPendings()
    const finalPendings = filterPendings(allPendings)
    if (finalPendings.length === 0) {
      const img = Math.round(Math.random())
      return isMobile ? <img src={`/mono${img + 1}.png`} alt="no tienes pendientes" width="100%" /> : <img src={`/mono${img + 1}.png`} alt="no tienes pendientes" height="400px" />
    }
    const initialGroup = finalPendings.map((pending, index) => {
      return <Pending pending={pending} onClick={openPending} key={pending.link + index} />
    })
    return (
      <>
        {initialGroup}
      </>
    )
  }

  function getAllPendings() {
    let allPendings = []
    const uid = account.user.id
    Object.keys(data).forEach(key => {
      let keyPendingsInfo = []
      if (key === "checklists") { keyPendingsInfo = transformChecklistToPendings(data[key]) }
      if (key === "findings") { keyPendingsInfo = transformFindingsToPendings(data[key], uid) }
      if (key === "talks") { keyPendingsInfo = transformTalksToPendings(data[key]) }
      if (key === "trainings") { keyPendingsInfo = transformTrainingsToPendings(data[key]) }
      if (key === "nonconformities") { keyPendingsInfo = transformNonConformitiesToPendings(data[key]) }
      if (key === "documents") { keyPendingsInfo = transformDocumentsToPendings(data[key]) }
      if (key === "evaluations") { keyPendingsInfo = transformEvaluationsToPendings(data[key]) }
      allPendings = allPendings.concat(keyPendingsInfo)
    })
    allPendings.sort((a, b) => {
      if (!moment(a.date).isValid()) {
        return 1
      }
      if (!moment(b.date).isValid()) {
        return -1
      }
      if (moment(a.date) > moment(b.date)) {
        return 1
      }
      if (moment(a.date) < moment(b.date)) {
        return -1
      }
      return 0
    })
    return allPendings
  }

  function filterPendings(info, forcedFilter = null) {
    let filter = forcedFilter
    if (filter === null) { filter = selectedFilter }
    if (filter === 0) return info.filter(pending => moment(new Date()).isAfter(moment(pending.date), "days"))
    if (filter === 1) return info.filter(pending => moment(new Date()).isSame(moment(pending.date), "days"))
    if (filter === 2) return info.filter(pending => moment(new Date()).isBefore(moment(pending.date), "days"))
  }

  const isMobile = window.innerWidth <= 500
  const allPendings = getAllPendings()
  const badges = [filterPendings(allPendings, 0), filterPendings(allPendings, 1), filterPendings(allPendings, 2)]

  if (loading) return (
    <Box sx={css.mainContainer}>
      <Box sx={isMobile ? css.container : css.desktopContainer}>
        <LoaderAnimator />
      </Box>
    </Box>
  )

  const buttons = [
    { name: "Vencidas", icon: <FiAlertCircle />, color: css.red },
    { name: "Actuales", icon: <FiCheckSquare />, color: css.green },
    { name: "Próximas", icon: <FiLogOut />, color: css.blue },
  ]

  return (
    <Box sx={css.mainContainer}>
      {!online &&
        <Box sx={css.floating}>
          <WifiOff style={{ color: newTheme.palette.info.main }} />
        </Box>
      }
      <Box sx={inline}>
        <FiClock color={newTheme.palette.secondary.light} size={22} />
        <Typography sx={css.title} variant="h1" >PENDIENTES</Typography>
      </Box>
      {online && <OtherBranchPendings />}
      <Box sx={isMobile ? css.container : css.desktopContainer}>
        <Box sx={css.filters}>
          {buttons.map((button, index) => {
            const isSelected = selectedFilter === index
            const style = isSelected ? { ...css.selected, ...button.color } : {}
            return (
              <Badge badgeContent={badges[index].length} color="info">
                <Paper sx={{ ...css.filter, ...style }} elevation={3} onClick={selectFilter(index)}>
                  {button.icon}
                  <Typography variant='subtitle1'>{button.name}</Typography>
                </Paper>
              </Badge>
            )
          })}
        </Box >
        <Box sx={css.pendings}>
          {renderPendings()}
        </Box>
      </Box >
    </Box>
  )
}

export default NewPendings