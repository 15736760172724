import React, { Component } from 'react'
import { Collapse, Paper, Typography, withStyles, Button, Checkbox } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import { addSignToGroup, createSignGroup } from './utils'
import MySignaturePad from '../../Talks/MySignaturePad'
import { getWorkersAction } from '../../../Actions/EnterpriseAction'
import { connect } from 'react-redux'
import moment from 'moment'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../../Utils/functions'
import LoadingDialog from '../../../Shared/LoadingDialog'
import { callSnackbar } from '../../../Utils/snackbar'
import TextInput from '../../../Shared/Inputs/TextInput'
import { formatRut } from '../../../Utils/formats'
import { filterActiveWorkersList } from '../../../Utils/filters'
import { notifySign } from '../../../API/notifications'

const style = (theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  group: {
    padding: 12,
    transition: 'all 0.2s linear',
    margin: '12px 0',
    background: '#f2f3f8',
    borderRadius: 8
  },
  openGroup: {
    background: theme.palette.purple.light,
    '& > * > * > *': {
      color: 'white'
    }
  },
  signatureContainer: {
    padding: 12,
    display: "flex",
    justifyContent: "center",
  },
  signs: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
    margin: 12,
    borderRadius: 8,
    "@media (max-width:500px)": {
      width: '100%',
      margin: '12px 0',
    },
  },
  signsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class ChSigns extends Component {
  constructor() {
    super()
    this.state = {
      groups: [{
        name: "Mati",
        description: "Descripción"
      }],
      openGroups: false,
      params: {},
      workers: [],
      selectedGroup: "",
      loading: false,
      isVisit: false
    }
    autobind(ChSigns, this)
  }

  async componentDidMount() {
    const { checklist, isInvited, user } = this.props
    const groups = checklist?.group_signs || []

    this.setState({ groups })
    const action = getWorkersAction()
    const response = await action.payload
    const workers = response?.data?.info || []
    const isAdmin = user?.account?.user?.userType <= 2

    if (isAdmin) {
      const params = { user_id: user?.account?.user?.id }
      return this.setState({ workers, params })
    }

    if (isInvited) {
      const invitedWorker = workers.find(worker => String(worker.id) === user.account.user.id)
      const params = { user_id: invitedWorker.id }
      return this.setState({ workers, params })
    }
    return this.setState({ workers })

  }

  componentDidUpdate(prevProps) {
    const { isInvited } = this.props
    if (isInvited !== prevProps.isInvited) {
      this.componentDidMount()
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    // Format rut
    if (target.name === "rut") {
      const newValue = formatRut(target.value)
      params[target.name] = newValue
      return this.setState({ params })
    }
    params[target.name] = target.value
    return this.setState({ params })
  }

  handleOpen() {
    const { openGroups } = this.state
    this.setState({ openGroups: !openGroups })
  }

  handleToggleVisit() {
    const { isVisit } = this.state
    this.setState({ isVisit: !isVisit })
  }

  async handleAddGroup() {
    const { params, groups } = this.state
    const { checklist } = this.props
    const newGroup = { ...params }
    newGroup.checklist_id = checklist.id
    const response = await createSignGroup(newGroup)
    if (response?.data?.status !== "success") return null
    groups.push(response?.data?.info)
    return this.setState({ params: {}, groups, openGroups: false })
  }

  async handleSignWithRegistered() {
    const { workers, selectedGroup, groups } = this.state
    const { user, checklist } = this.props
    const currentWorker = workers.find(worker => worker.id.toString() === user?.account?.user?.id)
    if (!currentWorker) return null
    if (this.checkIfHasSigned(currentWorker)) return callSnackbar("Solo se puede firmar una vez", "warning")
    const currentSign = currentWorker?.signs?.[0]
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${currentSign.sign}`
    this.setState({ loading: true })
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], `sign-${currentWorker?.name}`, { type: "image/png" })
        const data = new FormData()
        data.append("user_id", currentWorker.id)
        data.append("file", file)
        data.append("name", currentWorker.name)
        data.append("checklist_id", checklist.id)
        data.append("checklist_group_sign_id", selectedGroup)
        data.append("user_sign", "")
        data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
        addSignToGroup(data).then(response => {
          // Add sign to group
          this.setState({ loading: false })
          if (response.data.status !== "success") return null
          const newGroups = [...groups]
          const groupIndex = groups.findIndex(group => group.id === selectedGroup)
          newGroups[groupIndex].signs.push(response.data.info)
          notifySign(currentWorker, "Registro o Formulario", window.location.href, checklist.preventive_measure)
          return this.setState({ groups: newGroups, params: {}, loading: false })
        })
      })
  }

  handleSign(value) {
    const { params, workers, selectedGroup, groups, isVisit } = this.state
    const { checklist } = this.props
    const selectedWorker = workers.find(worker => worker.id === params.user_id)

    let email_worker = { ...selectedWorker }
    let user_name = selectedWorker?.name || ""
    let rut = ""
    if (isVisit) {
      user_name = params.user_name
      rut = params.rut
      email_worker = { email: params.visit_email, name: user_name }
    }

    if (!isVisit && this.checkIfHasSigned(selectedWorker)) return callSnackbar("Solo se puede firmar una vez", "warning")
    this.setState({ loading: true })
    fetch(value)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], `sign-${user_name.replaceAll(" ", "")}`, { type: "image/png" })
        const data = new FormData()
        data.append("user_id", isVisit ? 0 : params.user_id)
        data.append("file", file)
        data.append("name", user_name)
        if (!!rut) { data.append("rut", rut) }
        data.append("checklist_id", checklist.id)
        data.append("checklist_group_sign_id", selectedGroup)
        data.append("user_sign", "")
        data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
        addSignToGroup(data).then(response => {
          // Add sign to group
          this.setState({ loading: false })
          if (response.data.status !== "success") return null
          const newGroups = [...groups]
          const groupIndex = groups.findIndex(group => group.id === selectedGroup)
          newGroups[groupIndex].signs.push(response.data.info)
          notifySign(email_worker, "Registro o Formulario", window.location.href, checklist.preventive_measure)
          return this.setState({ groups: newGroups, params: {} })
        })
      })
  }

  handleOpenGroup(value) {
    return () => {
      const { selectedGroup } = this.state
      if (selectedGroup === value) return this.setState({ selectedGroup: "" })
      return this.setState({ selectedGroup: value })
    }
  }

  checkIfHasSigned(worker) {
    const { groups } = this.state
    const signs = groups.map(group => group.signs).flat().map(sign => sign.user_id).map(String)
    return signs.includes(worker.id.toString())

  }

  renderSigns(signs) {
    const { classes } = this.props
    const { workers } = this.state
    if (!signs) return null
    return signs.map(sign => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
      const selectedWorker = workers.find(worker => worker.id?.toString() === sign.user_id?.toString())
      return (
        <Paper className={classes.signs} square key={sign.id}>
          <img src={url} height="75px" width="150px" alt="firma" />
          <div className={classes.signInfo}>
            <Typography variant="body2">{sign.name}</Typography>
            <Typography variant="body1">{selectedWorker?.position}</Typography>
            <Typography variant="body1">{selectedWorker?.rut || sign?.rut}</Typography>
            <Typography variant="caption">{sign.date}</Typography>
          </div>
        </Paper>
      )
    })

  }

  renderGroups() {
    const { classes, disabled = false } = this.props
    const { groups, selectedGroup } = this.state
    return groups.map((group, index) => {
      const isCurrent = selectedGroup === group.id
      return (
        <div key={group.id + index} className={`${classes.group} ${isCurrent ? classes.openGroup : ""}`}>
          <div className={classes.title}>
            <div>
              <Typography variant="h4">{group.name}</Typography>
              <Typography variant="subtitle1">{group.description}</Typography>
            </div>
            {!disabled && <Button onClick={this.handleOpenGroup(group.id)} color="primary" variant="contained">
              {isCurrent ? "Terminar" : "Agregar firma"}
            </Button>}
          </div>
          <div className={classes.signsContainer}>
            {this.renderSigns(group?.signs)}
          </div>
        </div>
      )
    })
  }

  renderVisit() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <div className={classes.visitInputs}>
        <div className={classes.input}>
          <TextInput value={params.user_name} onChange={this.handleChange} label="Nombre" name="user_name" />
        </div>
        <div className={classes.input}>
          <TextInput value={params.rut} onChange={this.handleChange} label="Rut (sin puntos y con guión)" name="rut" />
        </div>
        <div className={classes.input}>
          <TextInput value={params.visit_email} onChange={this.handleChange} label="Correo (opcional)" name="visit_email" />
        </div>
      </div>
    )
  }

  render() {
    const { classes, isInvited, user } = this.props
    const { params, workers, selectedGroup, loading, isVisit } = this.state
    let finalWorkers = filterActiveWorkersList(workers)
    if (isInvited) {
      finalWorkers = workers.filter(worker => String(worker.id) === user.account.user.id)
    }

    const iAmSelected = params.user_id === user.account.user.id

    const isAbleToSign = (!isVisit && params.user_id) || (isVisit && params.user_name && params.rut)
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <div className={classes.title}>
          <Typography variant="h4">Firmas</Typography>
        </div>
        <Collapse in={!!selectedGroup}>
          {!isInvited && <div className={classes.checkbox}>
            <Checkbox checked={isVisit} onClick={this.handleToggleVisit} />
            <Typography variant="subtitle1">Firmar como visita</Typography>
          </div>}
          {isVisit ?
            this.renderVisit()
            :
            <SelectInput
              label="Colaborador"
              options={transformToOptions(finalWorkers)}
              value={params.user_id}
              onChange={this.handleChange}
              name="user_id"
              disabled={isInvited}
            />
          }
          <div className={classes.signatureContainer}>
            <MySignaturePad
              clearButton="true"
              save={this.handleSign}
              disabled={!isAbleToSign}
            />
          </div>
          {(isInvited || iAmSelected) &&
            <div style={{ textAlign: 'center', marginBottom: 24 }}>
              <Button variant="contained" color="primary" onClick={this.handleSignWithRegistered}>
                Usar firma registrada
              </Button>
            </div>
          }
        </Collapse>
        {this.renderGroups()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(ChSigns))