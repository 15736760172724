import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import autobind from '../../../Utils/autobind'
import { createSettingsFinding, createSettingsNonConformity } from '../../../API/settings'
import { callSnackbar } from '../../../Utils/snackbar'

const style = () => ({
  container: {
    marginTop: 24,
    background: 'white',
    borderRadius: 12,
    padding: 12,
    '& > h4': {
      marginBottom: 24
    }
  },
  button: {
    textAlign: 'end'
  }
})
class CreateConfiguration extends Component {
  constructor() {
    super()
    this.state = {
      activation_criticality: 0
    }
    autobind(CreateConfiguration, this)
  }

  handleChange(event) {
    const { target } = event
    this.setState({ activation_criticality: target.value })
  }

  async handleCreate() {
    const { nonconf, reload } = this.props
    const { activation_criticality } = this.state
    const body = {
      activation_criticality,
      extra_fields: []
    }
    if (nonconf) {
      await createSettingsNonConformity(body)
    } else {
      await createSettingsFinding(body)
    }
    callSnackbar("Creado correctamente", "success")
    reload()
  }

  render() {
    const { classes, title } = this.props
    const { activation_criticality } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="subtitle1">Nivel mínimo de criticidad </Typography>
        <div>
          <SelectInput options={[
            { label: "No crítico", value: 1 },
            { label: "Poco crítico", value: 2 },
            { label: "Medianamente crítico", value: 3 },
            { label: "Altamente crítico", value: 4 },
          ]} label=""
            onChange={this.handleChange}
            value={activation_criticality}
          />
        </div>
        <div className={classes.button}>
          <Button color="primary" variant="contained" onClick={this.handleCreate} disabled={!activation_criticality}>
            Crear configuración
          </Button>
        </div>
      </div>
    )
  }
}

CreateConfiguration.propTypes = {

}


CreateConfiguration.defaultProps = {
  title: "Crear configuración"
}

export default withStyles(style)(CreateConfiguration)