import { CategoryOutlined } from '@material-ui/icons'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { createBranchTag, editBranchTag, getBranchTags } from '../../API/branches'
import AddElementButton from '../../Shared/AddElementButton'
import BranchTag from './BranchTag'

const css = {
	container: {
		margin: '24px 0',
		padding: 2,
		background: 'white',
		borderRadius: 2,
		maxWidth: 700
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function BranchesTags({ value }) {

	const [tags, setTags] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getBranchTags()
			setTags(response.data.info)
		}

		fetchData()
	}, [])

	async function createTag() {
		const response = await createBranchTag()
		setTags(response.data.info)
	}

	async function editTag(body) {
		const response = await editBranchTag(body)
		setTags(response.data.info)
	}

	return (
		<Box sx={css.container}>
			<CategoryOutlined />
			<Box sx={css.header}>
				<Typography variant='h4'>Agrupación de centros</Typography>
				<AddElementButton onClick={createTag} name="Crear grupo" />
			</Box>
			{tags.map(tag => <BranchTag tag={tag} onEdit={editTag} />)}
		</Box>
	)
}

export default BranchesTags