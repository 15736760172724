import { Box, Button } from '@mui/material'
import moment from 'moment'
import { useContext, useState } from 'react'
import { signChecklistGroup } from '../../../API/new_checklists'
import AppContext from '../../../AppContext'
import { RequestManagerContext } from '../../../DatabaseManagers/RequestsManager'
import useToggle from '../../../Hooks/ToogleHook'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { filterActiveWorkers } from '../../../Utils/filters'
import { transformOfflineRequestToOnline, transformToOptions } from '../../../Utils/functions'
import MySignaturePad from '../../Talks/MySignaturePad'
import { ChecklistContext } from '../ChecklistView'

const css = {
	signPad: {
		display: 'flex',
		justifyContent: 'center'
	},
	button: {
		margin: '6px 0',
		width: 250
	}
}

function SignForm({ group }) {

	const [openManual, toggleManual] = useToggle(false)
	const [worker, setWorker] = useState(null)
	const [params, setParams] = useState({})
	const [isVisit, toggleVisit] = useToggle(false)
	const { checklist, setChecklist } = useContext(ChecklistContext)
	const { online: { status: online } } = useContext(AppContext)
	const { addRequest } = useContext(RequestManagerContext)

	const workers = filterActiveWorkers()
	const selectedWorker = workers.find(w => String(w.id) === String(worker))

	function signOffline(body) {
		const new_groups = [...checklist.group_signs]
		const new_group = { ...group }
		const new_group_index = new_groups.findIndex(g => g.id === group.id)
		new_group.signs.push(body)
		new_groups[new_group_index] = new_group
		const new_checklist = { ...checklist }
		new_checklist.group_signs = new_groups
		setChecklist(new_checklist)
		addRequest({ name: "Firma de registro", method: signChecklistGroup(body, true) })
	}

	async function onSign(value) {

		let email_worker = { ...selectedWorker }
		let user_name = selectedWorker?.name || ""
		user_name = user_name?.split("-")[0].trim()
		let rut = ""

		if (isVisit) {
			user_name = params.user_name
			rut = params.rut
			email_worker = { email: params.visit_email, name: user_name }
		}

		const data = {
			user_id: isVisit ? 0 : worker,
			file: value,
			sign_url: value,
			name: user_name,
			sign_name: user_name,
			rut: rut,
			checklist_id: checklist.id,
			checklist_group_sign_id: group.id,
			user_sign: "",
			date: moment().format("YYYY-MM-DD HH:mm")
		}
		if (!online) return signOffline(data)
		const response = await signChecklistGroup(await transformOfflineRequestToOnline(data))
		setChecklist(response.data.info)

		// TODO Send mail with sign
		// Add sign to group
		// this.setState({ loading: false })
		// if (response.data.status !== "success") return null
		// const newGroups = [...groups]
		// const groupIndex = groups.findIndex(group => group.id === selectedGroup)
		// newGroups[groupIndex].signs.push(response.data.info)
		// notifySign(email_worker, "Registro o Formulario", window.location.href, checklist.preventive_measure)
		// return this.setState({ groups: newGroups, params: {} })
	}

	return (
		<Box>
			<SelectInput
				value={worker}
				onChange={e => setWorker(e.target.value)}
				label="Seleccionar firmante"
				options={transformToOptions(workers)}
			/>
			<Box sx={css.button}>
				<Button
					fullWidth
					color="primary"
					variant="contained"
					disabled={!worker}
				>
					Usar firma registrada
				</Button>
			</Box>
			<Box sx={css.button} >
				<Button
					onClick={toggleManual}
					fullWidth
					color="primary"
					variant="outlined"
					disabled={!worker}
				>
					Ingresar firma manualmente
				</Button>
			</Box>
			{openManual &&
				<Box sx={css.signPad}>
					<MySignaturePad
						clearButton="true"
						save={onSign}
					/>
				</Box>
			}
		</Box>
	)
}

export default SignForm