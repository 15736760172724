import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import ImageContainer from '../../../Shared/ImageContainer'

const style = theme => ({
  dialog: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: 12
    }
  },
  link: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.blue.main,
    cursor: 'pointer',
  }
})

class ImageCell extends Component {
  constructor() {
    super()
    this.state = {
      openDialog: false
    }
    autobind(ImageCell, this)
  }

  handleOpenDialog() {
    const { openDialog } = this.state
    this.setState({ openDialog: !openDialog })
  }

  render() {
    const { openDialog } = this.state
    const { classes, value } = this.props
    if (!value) return null
    const images = value.split("&#&")
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
    return (
      <>
        <Typography variant="subtitle1" onClick={this.handleOpenDialog} className={classes.link}>Ver imágenes</Typography>
        <Dialog open={openDialog} onClose={this.handleOpenDialog}>
          <div className={classes.dialog}>
            {images.map(image => <ImageContainer height="80px" width="80px" src={`${url}/${image}`} />)}
          </div>
        </Dialog>
      </>
    )
  }
}

export default withStyles(style)(ImageCell)