import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import { filterActiveWorkers } from '../../../Utils/filters'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import autobind from '../../../Utils/autobind'
import DateInput from '../../../Shared/Inputs/DateInput'
import { transformToOptions } from '../../../Utils/functions'
import TextInput from '../../../Shared/Inputs/TextInput'

const style = () => ({
	container: {
		padding: 24,
		'& > *': {
			marginBottom: 6
		}
	}
})

class AssignFindingInline extends Component {
	constructor() {
		super()
		this.state = {
			params: {}
		}
		autobind(AssignFindingInline, this)
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	render() {
		const { classes, open, onClose } = this.props
		const { params } = this.state
		const workers = filterActiveWorkers()
		return (
			<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
				<div className={classes.container}>
					<Typography variant='h1'>Asignar hallazgo</Typography>
					<SelectInput label="Responsable" options={transformToOptions(workers)} onChange={this.handleChange} value={params.user_id} name="user_id" />
					<DateInput label="Fecha límite de resolución" onChange={this.handleChange} value={params.date_limit} name="date_limit" />
					<TextInput label="Acción Inmediata" onChange={this.handleChange} value={params.inmediate_action} name="inmediate_action" />
					<div style={{ textAlign: 'end' }}>
						<Button color="primary" variant="contained">
							Asignar Hallazgo
						</Button>
					</div>
				</div>
			</Dialog>
		)
	}
}

export default withStyles(style)(AssignFindingInline)