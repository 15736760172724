import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getSettingsWorkflows = body => {
  return instance2({
    method: 'get',
    url: 'settings/workflows/get.php',
    params: body
  })
}

export const createSettingsWorkflow = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/create.php',
    data: body
  })
}

export const editSettingsWorkflow = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/edit.php',
    data: body
  })
}

export const createWorkflowStep = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/create_step.php',
    data: body
  })
}

export const createWorkflowPhase = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/create_phase.php',
    data: body
  })
}

export const editWorkflowStep = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/edit_step.php',
    data: body
  })
}

export const editWorkflowPhase = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/edit_phase.php',
    data: body
  })
}

export const deleteWorkflowPhase = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/delete_phase.php',
    data: body
  })
}

export const deleteWorkflowStep = body => {
  return instance2({
    method: 'post',
    url: 'settings/workflows/delete_step.php',
    data: body
  })
}

export const editPhase = body => {
  return instance2({
    method: 'post',
    url: 'workflows/edit_phase.php',
    data: body
  })
}

export const editStep = body => {
  return instance2({
    method: 'post',
    url: 'workflows/edit_step.php',
    data: body
  })
}

export const editWorkflow = body => {
  return instance2({
    method: 'post',
    url: 'workflows/edit.php',
    data: body
  })
}

export const createWorkflow = body => {
  return instance2({
    method: 'post',
    url: 'workflows/create.php',
    data: body
  })
}

export const sendMail = body => {
  return instance({
    method: 'post',
    url: 'workflows/notification.php',
    data: body
  })
}

export const createInjured = body => {
  return instance2({
    method: 'post',
    url: 'workflows/create_injured_users.php',
    data: body
  })
}

export const editInjured = body => {
  return instance2({
    method: 'post',
    url: 'workflows/edit_injured_users.php',
    data: body
  })
}

export const deleteInjured = body => {
  return instance2({
    method: 'post',
    url: 'workflows/delete_injured_users.php',
    data: body
  })
}

export const getWorkflows = (body) => {
  return instance2({
    method: 'get',
    url: 'workflows/get.php',
    params: body
  })
}

export const createWorkflowFinding = body => {
  return instance2({
    method: 'post',
    url: 'workflows/finding',
    data: body
  })
}

export const createWorkflowChecklist = body => {
  return instance2({
    method: 'post',
    url: 'workflows/checklist',
    data: body
  })
}