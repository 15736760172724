import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import ItemCard from './ItemCard'

const style = () => ({
  item: {
    background: 'white',
    padding: 12,
    borderRadius: 8,
    margin: 6,
    height: 250,
    width: 250,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.04)'
    },
    '&.dissapear': {
      opacity: 0,
      transform: 'scale(0)',
    }
  },
  items: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  answerChip: {
    padding: '6px 18px',
    borderRadius: 24,
    display: 'grid',
    gridTemplateColumns: '30px 1fr auto',
    '& > *': {
      padding: '0 6px',
      color: 'white'
    },
    margin: 6
  },
  green: {
    background: '#91deb1',
  },
  red: {
    background: '#ff6c87',
  }
})

class ItemsReport extends Component {
  constructor() {
    super()
    this.state = {
      items: []
    }
    autobind(ItemsReport, this)
  }

  gatherItems() {
    const { data } = this.props
    const firstCheck = data[0]
    const items = firstCheck?.checklist_items?.map(item => ({ id: item.id, name: item.name })) || []
    return items
  }

  calculateTotals() {
    const items = this.gatherItems()
    const itemsObject = {}
    items.forEach(item => {
      itemsObject[item.id] = { ...item, yes: 0, no: 0 }
    })
    const { data } = this.props
    data.forEach(checklist => {
      checklist.checklist_items.forEach(item => {
        if (item.answer.value === "Si") {
          itemsObject[item.id].yes += 1
        } if (item.answer.value === "No") {
          itemsObject[item.id].no += 1
        }
      })
    })
    return itemsObject
  }

  renderItems() {
    const { classes, onSelect, selected } = this.props
    const items = this.gatherItems()
    const calculations = this.calculateTotals()
    return items.map(item => {
      if (selected && selected?.id !== item.id) return null
      return (
        <div className={`${classes.item}`} onClick={onSelect(item)}>
          <ItemCard item={item} data={calculations[item.id]} />
        </div>
      )
    })
  }

  renderSelected() {
    const { data, selected, classes } = this.props
    const answers = []
    data.forEach(checklist => {
      const element = checklist.checklist_items.find(item => item.id === selected?.id)
      if (element.answer.value === "N/A") return null
      if (element.answer.value === null) return null
      answers.push({ resonsable: checklist.responsable, answer: element.answer.value, date: checklist.fecha_programado })
    })
    return answers.map(answer => {
      return (
        <div className={`${classes.answerChip} ${answer.answer === "Si" ? classes.green : classes.red}`}>
          <Typography variant="subtitle1">{answer.answer}</Typography>
          <Typography variant="subtitle1">{answer.resonsable}</Typography>
          <Typography variant="subtitle1">{answer.date}</Typography>
        </div>
      )
    })
  }

  render() {
    const { classes, selected } = this.props
    const items = this.gatherItems()
    return (
      <div>
        {items.length > 0 && <Typography variant="h4" style={{ padding: '24px 12px 12px' }}>
          Distribución de elementos de la lista
        </Typography>}
        <div className={classes.container}>
          <div className={classes.items}>
            {this.renderItems()}
          </div>
          {selected &&
            <div className={classes.chips}>
              {this.renderSelected()}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withStyles(style)(ItemsReport)