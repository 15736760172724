import React, { Component } from 'react'
import { Tab, Tabs, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import TrainingsCalendar from './TrainingsCalendar'
import ChecklistCalendar from './ChecklistCalendar'
import NewTalksCalendar from './NewTalksCalendar'

const style = () => ({
  paper: {
    background: 'white',
    margin: '12px 0',
    borderRadius: 4
  },
})

class CalendarTabs extends Component {
  constructor() {
    super()
    this.state = {
      tab: 0
    }
    autobind(CalendarTabs, this)
  }

  handleChangeTab(event, tab) {
    this.setState({ tab })
  }

  renderTab() {
    const { tab } = this.state
    switch (tab) {
      case 0: {
        return <ChecklistCalendar />
      }
      case 1: {
        return <NewTalksCalendar />
      }
      case 2: {
        return <TrainingsCalendar />
      }
      default: {
        return <ChecklistCalendar />
      }
    }
  }

  render() {
    const { classes } = this.props
    const { tab } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.paper}>
          <Tabs onChange={this.handleChangeTab} value={tab}>
            <Tab label="Registros y formularios" />
            <Tab label="Charlas" />
            <Tab label="Capacitaciones" />
          </Tabs>
        </div>
        {this.renderTab()}
      </div>
    )
  }
}

export default withStyles(style)(CalendarTabs)