import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import moment from 'moment'
import { CheckCircleOutline } from '@material-ui/icons'

const style = () => ({
  answer: {
    padding: '6px 12px',
    borderRadius: 8,
    margin: 6,
    background: 'white',
    maxWidth: 150,
    minWidth: 150,
    transition: 'all 0.5s ease-in-out',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
})

class CheckPhaseReport extends Component {
  render() {
    const { classes, answers } = this.props
    return (
      <div className={classes.container}>
        {answers.map(answer => {
          if (!answer?.data?.value) return null
          return (
            <div className={`${classes.answer}`}>
              <Typography variant="subtitle1">{moment(answer?.data?.date).format("YYYY-MM-DD")}</Typography>
              <CheckCircleOutline />
            </div>
          )
        })}
      </div>
    )
  }
}

export default withStyles(style)(CheckPhaseReport)