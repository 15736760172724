import { Component } from 'react'
import { withStyles } from '@material-ui/core'

const style = () => ({

})
class NumberRender extends Component {

  render() {
    const { value, element } = this.props
    if (element?.checklist_items?.length === 0) return "-"
    return `${value}%`
  }
}

export default withStyles(style)(NumberRender)