import { Badge } from '@material-ui/core'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setBranchAction } from '../../../Actions/BranchActions'
import { getUserOtherPendings } from '../../../API/users'
import newTheme from '../../../newTheme'

const css = {
	pending: {
		background: newTheme.palette.primary.main,
		borderRadius: 2,
		padding: '3px 18px',
		cursor: 'pointer',
		transition: 'all 0.2s linear',
		'&:hover': {
			transform: 'scale(1.02)'
		},
	},
	pendings: {
		display: 'flex',
		margin: '12px 0'
	}
}

function OtherBranchPendings({ value, setBranch, enterprise }) {

	const [data, setData] = useState([])

	useEffect(() => {
		window.addEventListener("branch_change", fetchData)
		async function fetchData() {
			const body = {
				date_start: moment().startOf("week").subtract(5, 'days').format("YYYY-MM-DD"),
				date_end: moment().endOf("week").format("YYYY-MM-DD")
			}
			const response = await getUserOtherPendings(body)
			setData(response.data.info)
		}

		fetchData()
		return () => window.removeEventListener("branch_change", fetchData)
	}, [])

	function gotoBranch(id) {
		return () => {
			const selected = enterprise.branches.find(branch => String(branch.id) === String(id))
			setBranch(selected)
			const event = new CustomEvent("branch_change", { detail: { branch: selected } })
			window.dispatchEvent(event)
		}
	}

	return (
		<Box sx={css.pendings}>
			{data.map(pending => {
				return (
					<Box style={{ marginRight: 12 }}>
						<Badge badgeContent={pending.total} color="secondary">
							<Box sx={css.pending} onClick={gotoBranch(pending.id)}>
								<Typography variant='subtitle1' style={{ color: 'white' }}>
									{`Pendientes en ${pending.branch}`}
								</Typography>
							</Box>
						</Badge>
					</Box>
				)
			})}
		</Box>
	)
}

const mapStateToProps = (state) => ({
	enterprise: state.enterprise,
})

const mapDispatchToProps = (dispatch) => ({
	setBranch: (branch) => dispatch(setBranchAction(branch)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OtherBranchPendings)