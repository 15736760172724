import { Checkbox, TableCell, TableRow } from '@mui/material'

function TableHeadCells({ info, actions, selectable, selected }) {
	let cells = []
	const body_cells = info.map(header => {
		return (
			<TableCell>
				{header.name}
			</TableCell>
		)
	})
	if (selectable) { cells.push(<TableCell><Checkbox /></TableCell>) }
	cells = cells.concat(body_cells)
	if (!!actions.length) { cells.push(<TableCell>Acciones</TableCell>) }

	return <TableRow>{cells}</TableRow>
}

export default TableHeadCells