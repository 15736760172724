import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { getDoneChecklistsAction, getPendingChecklistsAction } from '../../Actions/CheckListActions'
import { connect } from 'react-redux'
import autobind from '../../Utils/autobind'
import { withRouter } from 'react-router-dom'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import ChecklistDashboard from './Dashboard/ChecklistDashboard'
import GeneralTable from '../../Shared/GeneralTable'
import InnerChecklists from './Dashboard/InnerChecklists'
import { KeyboardArrowRight } from '@material-ui/icons'
import DonutChart from '../../Shared/Charts/DonutChart'
import ComplianceDonut from '../Workers/WorkerReports/ComplianceDonut'
import InternalComplianceDonut from '../Workers/WorkerReports/InternalComplianceDonut'
import ExtraFieldDialog from '../Finding&Conformities/ExtraFieldDialog'
import UsersComplianceResume from './UsersComplianceResume'

const style = (theme) => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: 12,
    "& > *": {
      margin: 12
    }
  },
  loader: {
    position: 'relative',
    height: 300
  },
  table: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  circle: {
    height: 18,
    width: 18,
    borderRadius: '50%',
  },
  green: { background: theme.palette.green.main },
  red: { background: theme.palette.red.main },
  blue: { background: theme.palette.blue.main },
  tag: {
    padding: '6px 12px',
    borderRadius: 24,
    background: theme.palette.blue.main,
    textAlign: 'center',
    '& > *': {
      color: 'white',
      fontWeight: 600,
      margin: '0 6px'
    },
    margin: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tags: {
    margin: '12px 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > *': {
      flexBasis: '40%',
      flexGrow: 1
    }
  },
  label: {
    height: 18,
    width: 18,
    borderRadius: '50%',
    '& > *': {
      marginLeft: 24,
      fontWeight: 600
    },
    margin: '6px 0'
  },
  donut: {
    background: '#f2f3f8',
    padding: 24,
    borderRadius: 12,
    '& > h4': {
      marginBottom: 24
    },
    margin: 12
  }
})

class ChecklistsManagement extends Component {
  constructor() {
    super()
    this.state = {
      selectedWeek: null,
      selectedMonth: null,
      selectedActivities: [],
      selectedWorkers: [],
      updated: true,
      selectedFields: []
    }
    autobind(ChecklistsManagement, this)
  }

  handleClick(option) {
    return () => {
      const { history } = this.props
      history.push(`/panel/checklists/${option}`)
    }
  }

  handleSelectOption(mainOption) {
    return (option) => {
      return () => {
        const { selectedWeek, selectedMonth } = this.state
        if (mainOption === "week") {
          if (selectedWeek === option) return this.setState({ selectedWeek: null })
          return this.setState({ selectedWeek: option })
        } else {
          if (selectedMonth === option) return this.setState({ selectedMonth: null })
          return this.setState({ selectedMonth: option })
        }

      }
    }
  }

  handleSelectActivity(activity) {
    return () => {
      const actualActivities = [...this.state.selectedActivities]
      if (actualActivities.includes(activity)) {
        console.log(actualActivities, activity)
        const index = actualActivities.indexOf(activity)
        actualActivities.splice(index, 1)
        return this.setState({ selectedActivities: actualActivities })
      }
      actualActivities.push(activity)
      this.setState({ selectedActivities: actualActivities })
    }
  }

  handleSelectWorker(worker) {
    return () => {
      const actualWorkers = [...this.state.selectedWorkers]
      if (actualWorkers.includes(worker)) {
        const index = actualWorkers.indexOf(worker)
        actualWorkers.splice(index, 1)
        return this.setState({ selectedWorkers: actualWorkers })
      }
      actualWorkers.push(worker)
      this.setState({ selectedWorkers: actualWorkers })
    }
  }

  handleGotoRegister(row) {
    return () => {
      const { history } = this.props
      history.push(`/checklists/${row.id}`)
    }
  }

  handleShowInfo(row) {
    if (row === null) return this.setState({ selectedFields: [] })
    this.setState({ selectedFields: row.extra_fields })
  }

  render() {
    const { classes, values, loading } = this.props
    const { selectedMonth, selectedActivities, selectedWorkers, selectedFields } = this.state

    const pendings = values.pending || []
    const done = values.done || []

    let totalCompliance = 0
    let amountOfChecklists = 0

    // all
    const monthPending = pendings
    const monthDone = done
    const monthOverdue = monthPending.filter(checklist => checklist.status === "Vencido")
    const monthProgramed = monthPending.filter(checklist => checklist.status !== "Vencido")

    const monthData = { totalPending: monthProgramed.length, totalDone: monthDone.length, totalOverdue: monthOverdue.length, totalTotal: monthProgramed.concat(monthDone).concat(monthOverdue).length }

    const monthArray = [monthProgramed, monthDone, monthOverdue, monthProgramed.concat(monthDone).concat(monthOverdue)]
    const optionsArray = ["Programado", "Terminado", "Vencido", "Totales"]

    const selectedMonthChecklists = monthArray[optionsArray.indexOf(selectedMonth)] || []

    const actions = [
      { name: "Ver Registro", icon: KeyboardArrowRight, action: this.handleGotoRegister }
    ]

    // console.log(selectedActivities)
    // console.log(selectedWorkers)

    // If an option is selected and an activity before selected doesn't make sense now
    const selectedMonthActivities = selectedMonthChecklists.map(ch => ch.preventive_measure_id)
    const selectedMonthWorkers = selectedMonthChecklists.map(ch => ch.user_id)
    const filteredSelectedActivities = selectedActivities.filter(ac => selectedMonthActivities.includes(ac))
    const filteredSelectedWorkers = selectedWorkers.filter(wk => selectedMonthWorkers.includes(wk))

    const filteredChecklists = selectedMonthChecklists.filter(checklist => {
      return !filteredSelectedActivities.length || filteredSelectedActivities.includes(checklist.preventive_measure_id)
    }).filter(checklist => {
      return !filteredSelectedWorkers.length || filteredSelectedWorkers.includes(checklist.user_id)
    })

    const filteredPending = filteredChecklists.filter(checklist => checklist.status !== "Terminado")
    const filteredDone = filteredChecklists.filter(checklist => checklist.status === "Terminado")
    const filteredOverdue = filteredPending.filter(checklist => checklist.status === "Vencido")
    const filteredProgramed = filteredPending.filter(checklist => checklist.status !== "Vencido")

    const filteredData = {
      totalPending: filteredProgramed.length,
      totalDone: filteredDone.length,
      totalOverdue: filteredOverdue.length,
      totalTotal: filteredProgramed.concat(filteredDone).concat(filteredOverdue).length
    }

    const complianceData = {
      totalPending: 0,
      totalDone: filteredDone.length,
      totalOverdue: filteredOverdue.length,
      totalTotal: filteredProgramed.concat(filteredDone).concat(filteredOverdue).length
    }

    filteredChecklists.forEach(checklist => {
      totalCompliance += checklist.compliance
      amountOfChecklists += 1
    })

    const InternalCompliance = amountOfChecklists > 0 ? (totalCompliance / amountOfChecklists).toFixed(1) : 0

    const donutData = [filteredDone.length, filteredProgramed.length, filteredOverdue.length]

    return (
      <div className={classes.container}>
        {loading ?
          <div className={classes.loader}>
            <LoaderAnimator />
          </div>
          :
          <>
            <div className={classes.cardContainer}>
              <ChecklistDashboard onSelect={this.handleSelectOption("month")} selected={selectedMonth} selectable data={monthData} title="Registros y formularios" />
              {selectedMonthChecklists.length > 0 &&
                <>
                  <InnerChecklists
                    type={'Month'}
                    selectedChecklists={selectedMonthChecklists}
                    onSelect={this.handleSelectActivity}
                    onSelectWorker={this.handleSelectWorker}
                    selectedActivities={selectedActivities}
                    selectedWorkers={selectedWorkers}
                  />
                  <div className={classes.table}>
                    <div className={classes.tags}>
                      <div className={classes.donut}>
                        <Typography variant='h4'>Distribución selección</Typography>
                        <div style={{ display: 'flex' }}>
                          <div style={{ width: 300 }}>
                            <DonutChart data={donutData} color={['#91deb1', '#7373e8', '#ff6c87']} name={`donutdinamica`} />
                          </div>
                          <div className={classes.labels}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div className={`${classes.label} ${classes.green}`} />
                              <Typography variant='subtitle1' style={{ fontWeight: 600, marginLeft: 6 }}>Terminadas ({donutData[0]})</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div className={`${classes.label} ${classes.blue}`} />
                              <Typography variant='subtitle1' style={{ fontWeight: 600, marginLeft: 6 }}>Programadas ({donutData[1]})</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div className={`${classes.label} ${classes.red}`} />
                              <Typography variant='subtitle1' style={{ fontWeight: 600, marginLeft: 6 }}>Vencidas ({donutData[2]})</Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.donut}>
                        <Typography variant='h4'>Avance selección</Typography>
                        <div style={{ width: 300, margin: 'auto' }}>
                          <ComplianceDonut name="semidonutdinamica" data={filteredData} />
                        </div>
                      </div>
                      <div className={classes.donut}>
                        <Typography variant='h4'>Cumplimiento selección</Typography>
                        <div style={{ width: 300, margin: 'auto' }}>
                          <ComplianceDonut name="semidonutdinamict" data={complianceData} />
                        </div>
                      </div>
                      <div className={classes.donut}>
                        <Typography variant='h4'>Cumplimiento interno promedio registros</Typography>
                        <div style={{ width: 300, margin: 'auto' }}>
                          <InternalComplianceDonut name="semidonutinterno" data={InternalCompliance} />
                        </div>
                      </div>
                    </div>
                    <ExtraFieldDialog
                      open={!!selectedFields.length}
                      onClose={() => this.handleShowInfo(null)}
                      title={"Información extra"}
                      fields={selectedFields}
                    />
                    <GeneralTable data={filteredChecklists} info={[
                      {
                        name: "Estado", label: "status", format: value =>
                        (<div
                          className={`${classes.circle} ${value === "Programado" ? classes.blue : value === "Vencido" ? classes.red : classes.green}`}>

                        </div>)
                      },
                      { name: "Fecha", label: "date_scheduled" },
                      { name: "Fecha Realizado", label: "date_done" },
                      { name: "Registro", label: "preventive_measure", clickable: true, action: this.handleShowInfo },
                      { name: "Responsable", label: "user" }
                    ]} disableFilters scrollable maxHeight={400} actions={actions}
                    />
                  </div>
                </>
              }
            </div>
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checklists: state.checklists,
  enterprise: state.enterprise,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getDoneChecklist: (body) => dispatch(getDoneChecklistsAction(body)),
  getPendingChecklists: (body) => dispatch(getPendingChecklistsAction(body)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ChecklistsManagement)))