import { Box, Tooltip, Typography } from '@mui/material'
import newTheme from '../../newTheme'

const css = {
	container: {
		padding: 2,
		background: 'whitesmoke',
		borderRadius: 2,
		width: '100%'
	},
	headers: {
		display: 'flex',
		alignItems: 'center',
	},
	row: {
		display: 'flex',
		alignItems: 'center'
	},
	cell: {
		padding: 1,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		borderBottom: '1px lightgrey solid',
		minWidth: 200
	},
	table: {
		display: 'grid',
		width: '100%',
		overflow: 'auto',
		background: 'whitesmoke',
		borderRadius: 2,
		padding: 2
	},
	number: {
		color: 'white',
		fontWeight: 600,
		fontSize: 14
	}
}

function generateDataset(checklists) {
	const dataset = {}
	const rows = []
	const columns = []
	checklists.forEach(checklist => {
		const activity = checklist.preventive_measure
		const worker = checklist.user
		if (!rows.includes(activity)) { rows.push(activity) }
		if (!columns.includes(worker)) { columns.push(worker) }
		const key = String([activity, worker])
		if (Object.keys(dataset).includes(key)) {
			dataset[key].total += 1
			if (checklist.status === "Vencido") { dataset[key].overdue += 1 }
			if (checklist.status === "Programado") { dataset[key].pending += 1 }
			if (checklist.status === "Terminado") { dataset[key].done += 1 }
		} else {
			dataset[key] = {
				total: 1,
				overdue: checklist.status === "Vencido" ? 1 : 0,
				pending: checklist.status === "Programado" ? 1 : 0,
				done: checklist.status === "Terminado" ? 1 : 0
			}
		}
	})
	return [dataset, rows, columns]
}

function UsersComplianceResume({ checklists }) {

	const [dataset, rows, columns] = generateDataset(checklists)
	const gridTemplateColumns = `repeat(${columns.length + 1}, minmax(200px, 1fr))`
	const colors = [newTheme.palette.green.dark, newTheme.palette.error.dark, "goldenrod"]
	return (
		<Box sx={css.table} style={{ gridTemplateColumns }}>
			<Box sx={css.cell}>
				<Typography variant='subtitle1'><strong>Actividades</strong></Typography>
			</Box>
			{columns.map(column => <Box sx={css.cell}>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}><strong>{column}</strong></Typography>}>
					<Typography variant='subtitle1'><strong>{column}</strong></Typography>
				</Tooltip>
			</Box>)}
			{rows.map(row => {
				return (
					<>
						<Box sx={css.cell}>
							<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}><strong>{row}</strong></Typography>}>
								<Typography variant='subtitle1'><strong>{row}</strong></Typography>
							</Tooltip>
						</Box>
						{columns.map(column => {
							const key = String([row, column])
							const total = dataset[key]?.done + dataset[key]?.overdue || 0
							const done = dataset[key]?.done || 0
							const percentage = done / total
							let color = colors[0]
							if (percentage < 1 && percentage >= 0.5) { color = colors[2] }
							if (percentage < 0.5) { color = colors[1] }
							console.log(key)
							return (
								<Box sx={css.cell} style={{ background: color }}>
									<Typography sx={css.number} variant='subtitle1'>{`${dataset[key]?.done || 0} / ${dataset[key]?.done + dataset[key]?.overdue || 0}`}</Typography>
								</Box>)
						})}
					</>
				)
			})}
		</Box>
	)
}

export default UsersComplianceResume