import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/lib/integration/react"
import { SnackbarProvider } from "notistack"
import { MuiThemeProvider } from "@material-ui/core"
import { ThemeProvider } from '@mui/material'
import theme from "./theme"
import store from "./store"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from '@date-io/moment'
import newTheme from "./newTheme"
import ChecklistManager from "./DatabaseManagers/ChecklistsManager"
// import CacheBuster from "./CacheBuster"

const persistor = persistStore(store)

ReactDOM.render(
    <ThemeProvider theme={newTheme}>
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <BrowserRouter>
                            <SnackbarProvider
                                disableWindowBlurListener
                                autoHideDuration={2000}
                                maxSnack={3}
                            >
                                <App />
                            </SnackbarProvider>
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </ThemeProvider>,
    document.getElementById("root")
)
