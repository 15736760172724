import React from "react"
import { withStyles } from "@material-ui/core"
import { Box } from "@mui/material"

const css = {
    container: {
        padding: 6,
        borderRadius: 12,
        color: "white",
        textAlign: "center",
        minWidth: 60,
    },
}

function TableChip({ header, element, value, to_string }) {
    if (to_string) return value
    function getColor() {
        if (header.label === "all" && header.variant !== "residual") {
            const crit = element.consequence.value * element.probability.value
            if (crit >= 9) {
                return "#ff000094"
            } else if (crit >= 4) {
                return "#d2b100bd"
            } else if (crit < 4 && crit > 0) {
                return "#008000b3"
            } else {
                return "lightgrey"
            }
        } else if (header.label === "probability&name") {
            const colors = [
                "#791d1d9c",
                "#ff000094",
                "#d2b100bd",
                "#008000b3",
            ].reverse()
            return colors[element.probability ? element.probability.value - 1 : 0]
        } else if (header.label === "consequence&name") {
            const colors = [
                "#791d1d9c",
                "#ff000094",
                "#d2b100bd",
                "#008000b3",
            ].reverse()
            return colors[element.consequence ? element.consequence.value - 1 : 0]
        } else if (header.label === "residual_consequence&name") {
            const colors = [
                "#791d1d9c",
                "#ff000094",
                "#d2b100bd",
                "#008000b3",
            ].reverse()
            return colors[element.residual_consequence ? element.residual_consequence.value - 1 : 0]
        } else if (header.label === "residual_probability&name") {
            const colors = [
                "#791d1d9c",
                "#ff000094",
                "#d2b100bd",
                "#008000b3",
            ].reverse()
            return colors[element.residual_probability ? element.residual_probability.value - 1 : 0]
        } else if (header.variant === "residual") {
            let crit = 0
            if (element.residual_consequence && element.residual_probability) {
                crit = element.residual_consequence.value * element.residual_probability.value
            }
            if (crit >= 9) {
                return "#ff000094"
            } else if (crit >= 4) {
                return "#d2b100bd"
            } else if (crit < 4 && crit > 0) {
                return "#008000b3"
            } else {
                return "lightgrey"
            }
        }
    }

    return (
        <Box
            sx={css.container}
            style={{ background: getColor() }}
        >
            {value}
        </Box>
    )

}

export default TableChip
