import store from "../store"

export const filterActiveWorkers = (rut = true) => {
  const user = store.getState().user
  const workers = store.getState().workers?.all || []
  const branches = store.getState().branch?.global?.id?.split(",") || []
  const filteredWorkers = workers
    .filter(worker => worker.active === 0)
    .filter(worker => worker.branch_id === null || worker.branch_ids.filter(x => branches.includes(x.toString())).length > 0)
    .map(worker => ({ ...worker, name: `${worker.name} ${rut ? `- ${worker.rut}` : ""}`, isSupervised: worker?.user?.id?.toString() === user?.account?.user?.id?.toString() }))
    .sort((wa, wb) => {
      if (wa.lastname < wb.lastname) return -1
      if (wa.lastname > wb.lastname) return 1
      return 0
    })
    .sort((wa, wb) => {
      if (wa.isSupervised > wb.isSupervised) return -1
      if (wa.isSupervised < wb.isSupervised) return 1
      return 0
    })
  return filteredWorkers
}

export const filterActiveWorkersList = (workers = [], rut = true) => {
  const user = store.getState().user
  const branches = store.getState().branch?.global?.id?.split(",") || []
  const filteredWorkers = workers
    .filter(worker => worker.active === 0)
    .filter(worker => worker.branch_id === null || worker.branch_ids.filter(x => branches.includes(x.toString())).length > 0)
    .map(worker => ({ ...worker, name: `${worker.name} ${rut ? `- ${worker.rut}` : ""}`, isSupervised: worker?.user?.id?.toString() === user?.account?.user?.id?.toString() }))
    .sort((wa, wb) => {
      if (wa.lastname < wb.lastname) return -1
      if (wa.lastname > wb.lastname) return 1
      return 0
    })
    .sort((wa, wb) => {
      if (wa.isSupervised > wb.isSupervised) return -1
      if (wa.isSupervised < wb.isSupervised) return 1
      return 0
    })
  return filteredWorkers
}

export const filterActiveBranches = (branches) => {
  const user = store.getState().user
  const workers = store.getState().workers?.all || []
  const actualWorker = workers.find(w => w.id === user?.account?.user?.id)
  const permissionsString = actualWorker?.permissions || ""
  const permissionsList = permissionsString.split(",")
  const isSuperAdmin = !permissionsList.includes("sa") && user?.account?.user?.userType === 1
  if (isSuperAdmin) return branches
  const branches_ids = user?.account?.user?.branch_ids || []
  const active = branches.filter(b => branches_ids.map(String).includes(String(b.id)))
  return active
}

export const filterOwnBranches = (branches) => {
  const user = store.getState().user
  const branches_ids = user?.account?.user?.branch_ids || []
  if (!branches_ids.length) return branches
  const active = branches.filter(b => branches_ids.map(String).includes(String(b.id)))
  return active
}

export function findWorker(user_id) {
  const workers = filterActiveWorkers(false)
  return workers.find(worker => String(worker.id) === String(user_id)) || false
}