import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"
import createSnackbar from "../Utils/snackbar"

// --- Checklists

export const getSettingChecklistsAction = () => {
  const REQUEST = instance2({
    method: "get",
    url: "settings/checklists/get.php"
  })
  return {
    type: "GET_SETTINGS_CHECKLISTS",
    payload: REQUEST
  }
}

export const selectSettingsChecklistAction = (id) => {
  return {
    type: "SELECT_SETTINGS_CHECKLIST",
    payload: id
  }
}

export const createSettingChecklistAction = (body) => {
  const REQUEST = instance2({
    method: "post",
    url: "settings/checklists/create.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_SETTINGS_CHECKLIST",
    payload: REQUEST
  }
}

export const editSettingChecklistAction = (body) => {
  const REQUEST = instance2({
    method: "post",
    url: "settings/checklists/edit.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_CHECKLIST",
    payload: REQUEST
  }
}

export const createChecklistItemAction = (body) => {
  const REQUEST = instance({
    method: "post",
    url: "settings/checklists/create_item.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_CHECKLIST_ITEM",
    payload: REQUEST
  }
}

export const editChecklistItemAction = (body) => {
  const REQUEST = instance({
    method: "post",
    url: "settings/checklists/edit_item.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_CHECKLIST_ITEM",
    payload: REQUEST
  }
}

export const deleteChecklistItemAction = (body) => {
  const REQUEST = instance({
    method: 'post',
    url: "settings/checklists/delete_item.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_CHECKLIST_ITEM",
    payload: REQUEST
  }
}

export const updateChecklistExtraFieldAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/checklists/extra_fields.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "UPDATE_SETTING_EXTRA_FIELDS",
    payload: REQUEST
  }
}

// --- Activities

export const getSettingsActivitiesAction = () => {
  const REQUEST = instance2({
    method: 'get',
    url: "settings/activities/get.php"
  })
  return {
    type: "GET_SETTINGS_ACTIVITIES",
    payload: REQUEST
  }
}

export const createSettingsActivityAction = (body) => {
  const REQUEST = instance({
    method: "post",
    url: "settings/activities/create.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_SETTINGS_ACTIVITY",
    payload: REQUEST
  }
}

export const editSettingsActivityAction = (body) => {
  const REQUEST = instance({
    method: 'post',
    url: "settings/activities/edit.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_ACTIVITY",
    payload: REQUEST
  }
}

// --- TALKS ---

export const getSettingsTalksAction = () => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/talks/get.php'
  })
  return {
    type: "GET_SETTINGS_TALKS",
    payload: REQUEST
  }
}

export const createSettingsTalkAction = (body) => {
  const REQUEST = instance({
    method: 'post',
    url: "settings/talks/create.php",
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_SETTINGS_TALK",
    payload: REQUEST
  }
}

export const editSettingsTalkAction = (body) => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/talks/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_TALK",
    payload: REQUEST
  }
}

export const getTalksCategoriesAction = () => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/talks/get_category.php'
  })
  return {
    type: 'GET_TALKS_CATEGORIES',
    payload: REQUEST
  }
}

export const editTalksCategoryAction = (body) => {
  const REQUEST = instance({
    methor: 'post',
    url: 'settings/talks/edit_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_TALKS_CATEGORY",
    payload: REQUEST
  }
}

export const deleteTalksCategoryAction = (body) => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/talks/delete_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "DELETE_TALKS_CATEGORY",
    payload: REQUEST
  }
}

export const createTalksCategoryAction = (body) => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/talks/create_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_TALKS_CATEGORY',
    payload: REQUEST
  }
}

// --- Trainings

export const getSettingsTrainingsAction = () => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/trainings/get.php'
  })
  return {
    type: "GET_SETTINGS_TRAININGS",
    payload: REQUEST
  }
}

export const editSettingsTrainingAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/trainings/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_SETTINGS_TRAINING',
    payload: REQUEST
  }
}

export const createSettingsTrainingAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/trainings/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_TRAINING',
    payload: REQUEST
  }
}

export const getTrainingsCategoriesAction = () => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/trainings/get_category.php'
  })
  return {
    type: "GET_TRAININGS_CATEGORIES",
    payload: REQUEST
  }
}

export const editTrainingsCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/trainings/edit_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_TRAININGS_CATEGORY",
    payload: REQUEST
  }
}

export const createTrainingsCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/trainings/create_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_TRAININGS_CATEGORY",
    payload: REQUEST
  }
}

export const deleteTrainingsCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/trainings/delete_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "DELETE_TRAININGS_CATEGORY",
    payload: REQUEST
  }
}

// --- User Docuements

export const getSettingsUserCategoriesAction = body => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/users_documents/get_category.php',
    params: body
  })
  return {
    type: "GET_SETTINGS_USER_CATEGORIES",
    payload: REQUEST
  }
}

export const editSettingsUserCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/edit_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_USER_CATEGORY",
    payload: REQUEST
  }
}

export const createSettingsUserCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/create_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_SETTINGS_USER_CATEGORY",
    payload: REQUEST
  }
}

export const deleteSettingsUserCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/delete_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_USER_CATEGORY',
    payload: REQUEST
  }
}

export const createSettingsUserSubcategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/create_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_SETTINGS_USER_SUBCATEGORY",
    payload: REQUEST
  }
}

export const editSettingsUserSubcategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/edit_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_USER_SUBCATEGORY",
    payload: REQUEST
  }
}

export const deleteSettingsUserSubcategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/delete_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_USER_SUBCATEGORY',
    payload: REQUEST
  }
}

export const createSettingsUserSubsubcategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/create_subsubcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_SETTINGS_USER_SUB_SUBCATEGORY",
    payload: REQUEST
  }
}

export const editSettingsUserSubsubcategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/edit_subsubcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_USER_SUB_SUBCATEGORY",
    payload: REQUEST
  }
}

export const deleteSettingsUserSubsubcategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/users_documents/delete_subsubcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_USER_SUB_SUBCATEGORY',
    payload: REQUEST,
    meta: { id: body.category_id }
  }
}


// Enterprise Documents

export const getSettingsEnterpriseCategoriesAction = body => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/company_documents/get_category.php',
    params: body
  })
  return {
    type: "GET_SETTINGS_ENTERPRISE_CATEGORIES",
    payload: REQUEST
  }
}

export const editSettingsEnterpriseCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/company_documents/edit_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_SETTINGS_ENTERPRISE_CATEGORY',
    payload: REQUEST
  }
}

export const createSettingsEnterpriseCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/company_documents/create_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_ENTERPRISE_CATEGORY',
    payload: REQUEST
  }
}

export const deleteSettingsEnterpriseCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/company_documents/delete_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_ENTERPRISE_CATEGORY',
    payload: REQUEST
  }
}

// SubBranch Documents

export const getSettingsSubBranchCategoryAction = body => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/sub_branch_documents/get_category.php'
  })
  return {
    type: "GET_SETTINGS_SUB_BRANCH_CATEGORIES",
    payload: REQUEST
  }
}

export const editSettingsSubBranchCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/sub_branch_documents/edit_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_SETTINGS_SUB_BRANCH_CATEGORY',
    payload: REQUEST
  }
}

export const createSettingsSubBranchCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/sub_branch_documents/create_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_SUB_BRANCH_CATEGORY',
    payload: REQUEST
  }
}

export const deleteSettingsSubBranchCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/sub_branch_documents/delete_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_SUB_BRANCH_CATEGORY',
    payload: REQUEST
  }
}

export const editSettingsSubBranchSubCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/sub_branch_documents/edit_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_SETTINGS_SUB_BRANCH_SUBCATEGORY',
    payload: REQUEST
  }
}

export const createSettingsSubBranchSubCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/sub_branch_documents/create_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_SUB_BRANCH_SUBCATEGORY',
    payload: REQUEST
  }
}

export const deleteSettingsSubBranchSubCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/sub_branch_documents/delete_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_SUB_BRANCH_SUBCATEGORY',
    payload: REQUEST
  }
}

// Branch Documents

export const getSettingsBranchCategoryAction = body => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/branch_documents/get_category.php'
  })
  return {
    type: "GET_SETTINGS_BRANCH_CATEGORIES",
    payload: REQUEST
  }
}

export const editSettingsBranchCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/branch_documents/edit_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_SETTINGS_BRANCH_CATEGORY',
    payload: REQUEST
  }
}

export const createSettingsBranchCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/branch_documents/create_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_BRANCH_CATEGORY',
    payload: REQUEST
  }
}

export const deleteSettingsBranchCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/branch_documents/delete_category.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_BRANCH_CATEGORY',
    payload: REQUEST
  }
}

export const editSettingsBranchSubCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/branch_documents/edit_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_SETTINGS_BRANCH_SUBCATEGORY',
    payload: REQUEST
  }
}

export const createSettingsBranchSubCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/branch_documents/create_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_BRANCH_SUBCATEGORY',
    payload: REQUEST
  }
}

export const deleteSettingsBranchSubCategoryAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/branch_documents/delete_subcategory.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'DELETE_SETTINGS_BRANCH_SUBCATEGORY',
    payload: REQUEST
  }
}