import { Typography } from '@material-ui/core'
import React from 'react'
import { callSnackbar } from './snackbar'
import Resizer from 'react-image-file-resizer'
import { PDFDocument, StandardFonts } from 'pdf-lib'
import QRCode from 'qrcode'
import store from '../store'
import { getSubBranchesAction } from '../Actions/BranchActions'

export const compareValuesByKey = (orderBy, orderOn) => {
	const order = orderOn === "desc" ? -1 : 1
	return function sort(a, b) {
		let result = 0
		if (a[orderBy] < b[orderBy]) {
			result = -1
		}
		if (a[orderBy] > b[orderBy]) {
			result = 1
		}
		return result * order
	}
}

export const transformToOptions = (arrayOfObjects) => {
	if (Array.isArray(arrayOfObjects)) {
		const result = arrayOfObjects.map((element) => {
			return { value: element.id.toString(), label: element.name, ...element }
		})
		return result
	}
	return []
}

export const getCellValue = (data, cell) => {
	let value = data[cell.name]
	const type = cell.type ? cell.type : false
	if (type && type === "select") {
		const elem_id = data[cell.name]
		const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
		value = option ? option.label : "No asignado..."
	}
	if (type && type === "select_create") {
		const elem_id = data[cell.name]
		const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
		value = option ? option.label : "No asignado..."
	}
	if (type && type === "multiselect") {
		const elem_ids = data[cell.name] || []
		value = elem_ids.map((elem_id) => {
			const option = cell.options.find((o) => elem_id ? o.value.toString() === elem_id.toString() : null)
			return option ? `${option.label} ` : "No asignado..."
		})
		value = value.length > 0 ? value : "No asignado..."
	}
	return value
}

export const getTableCellValue = (element, header) => {
	const keys = header.label.split("&")
	if (header.label === "all") {
		return element
	}
	if (keys.length === 1) {
		return element[header.label]
	} else {
		return element[keys[0]] ? element[keys[0]][keys[1]] : ""
	}
}

export const superDispatch = (dispatch, func, type) => {
	dispatch({ type: `REQUEST_${type}` })
	return dispatch(func)
		.then((response) => {
			dispatch({ type: `SUCCESS_${type}`, payload: response })
		})
		.catch(() => {
			dispatch({ type: `ERROR_${type}` })
		})
}

export const transformToSuperDispatch = (mapDispatchToProps) => {
	return (dispatch) => {
		const toReturn = mapDispatchToProps(dispatch)
		const newProps = {}
		Object.entries(toReturn).forEach(([name, func]) => {
			newProps[name] = transformToMakerDispatch(dispatch, func, name)
		})
		return newProps
	}
}

export const transformToMakerDispatch = (dispatch, dispatchedFunc, type) => {
	return (...args) => {
		dispatch({ type: `REQUEST_${type}` })
		return dispatchedFunc(...args)
			.then(() => {
				dispatch({ type: `SUCCESS_${type}` })
			})
			.catch(() => {
				dispatch({ type: `ERROR_${type}` })
			})
	}
}

export const formatDownloadableDocument = (basePath) => {
	return (fileName) => {
		const file_name = "Documento"
		const url = completePath(fileName, basePath)
		function downloadThisFile() {
			fetch(url).then((response) => {
				response.blob().then((blob) => {
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement("a")
					a.href = url
					a.download = file_name
					a.click()
				})
			}).catch(() => callSnackbar("Error al descargar documento", "error"))
		}

		return (<Typography variant="subtitle1" onClick={downloadThisFile} style={{ color: "#5867dd", cursor: "pointer" }}>Descargar Documento</Typography>)
	}
}

export const resizeImageAndGetFile = (file, callback) => {

	if (file === "remove") return callback(null, null)
	if (!file) return null

	const resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
			uri => {
				resolve(uri)
			},
			'base64'
		)
	})

	resizeFile(file).then(image => {
		fetch(image).then(res => res.blob())
			.then(blob => {
				const newFile = new File([blob], file.name.replace("png", "jpeg"), { type: "image/jpeg" })
				callback(image, newFile)
			})
	})
}

export const resizeImageGetFile = async (file) => {

	if (file === "remove") return [null, null]
	if (!file) return [null, null]

	const resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0,
			uri => {
				resolve(uri)
			},
			'base64'
		)
	})

	const image = await resizeFile(file)
	const res = await fetch(image)
	const blob = await res.blob()
	const newFile = new File([blob], file.name.replace("png", "jpeg"), { type: "image/jpeg" })
	return [image, newFile]
}

function checkForValidRut(rut) {
	const replacedValue = rut.replaceAll(".", "").replace("-", "")
	if (replacedValue.length < 7) return false

	const cuerpo = replacedValue.slice(0, -1)
	const dv = replacedValue.slice(-1).toUpperCase()

	let suma = 0
	let multiplo = 2

	for (let i = 1; i <= cuerpo.length; i++) {

		// Obtener su Producto con el Múltiplo Correspondiente
		let index = multiplo * replacedValue.charAt(cuerpo.length - i)

		// Sumar al Contador General
		suma = suma + index

		// Consolidar Múltiplo dentro del rango [2,7]
		if (multiplo < 7) { multiplo = multiplo + 1 } else { multiplo = 2 }

	}

	const dvEsperado = 11 - (suma % 11)

	// Casos Especiales (0 y K)
	let dvalue = parseInt(dv, 10)
	if (dv === 'K') { dvalue = 10 }
	if (dv === '0') { dvalue = 11 }

	if (dvEsperado !== dvalue) return false
	return true
}

export const validateForm = (info, params) => {
	const bools = info.map(input => {
		if (input.required) {
			console.log(input)

			if (input.type === "rut") {
				const isValid = checkForValidRut(params[input.name] || "")
				return isValid
			}

			const value = params[input.name]
			if (value === undefined) {
				return false
			}
		}
		return true
	})
	const isValid = bools.filter(b => !b).length === 0
	if (!isValid) callSnackbar(`Falta rellenar información`, 'warning')
	return isValid
}

export const mergeMultiplePdfFiles = async (files = [], fileName = "merged.pdf") => {
	const mergedPDF = await PDFDocument.create()

	const promises = files.map(async (file) => {
		const pdfBytes = await fetch(file).then(response => response.arrayBuffer())
		const bytes = new Uint8Array(pdfBytes)

		let document1 = await PDFDocument.load(bytes)
		const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

		copiedPages.forEach(page => mergedPDF.addPage(page))
	})

	await Promise.all(promises)

	const resultInBytes = await mergedPDF.save()

	var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

	var link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = fileName
	link.click()
}

export const mergeMultiplePdfBytesFiles = async (files = [], fileName = "merged.pdf") => {
	const mergedPDF = await PDFDocument.create()

	const promises = files.map(async (file) => {
		const bytes = file

		let document1 = await PDFDocument.load(bytes)
		const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

		copiedPages.forEach(page => mergedPDF.addPage(page))
	})

	await Promise.all(promises)

	const resultInBytes = await mergedPDF.save()

	var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

	var link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = fileName
	link.click()
}

export const mergeMultiplePdfFilesAndGet = async (files = [], fileName = "merged.pdf") => {
	const mergedPDF = await PDFDocument.create()

	const promises = files.map(async (file) => {
		const pdfBytes = await fetch(file).then(response => response.arrayBuffer())
		const bytes = new Uint8Array(pdfBytes)

		let document1 = await PDFDocument.load(bytes)
		const copiedPages = await mergedPDF.copyPages(document1, document1.getPageIndices())

		copiedPages.forEach(page => mergedPDF.addPage(page))
	})

	await Promise.all(promises)

	return mergedPDF
}


export const signDocument = async (documentToSign, sign, imageUrl, relatorSign, relatorSignUrl, fileName, worker, relator, get = false) => {

	// get subbranches
	let worker_sub_branch = null
	let relator_sub_branch = null

	if (worker?.sub_branch_id !== "") {
		await store.dispatch(getSubBranchesAction({ branch_id: worker?.branch_id }))
		const worker_subbranches = await store.getState().branch.sub_branches
		worker_sub_branch = worker_subbranches.find(sb => sb.id.toString() === worker.sub_branch_id.toString())
	}

	if (relator.sub_branch_id !== "") {
		await store.dispatch(getSubBranchesAction({ branch_id: relator.branch_id }))
		const relator_subbranches = await store.getState().branch.sub_branches
		relator_sub_branch = relator_subbranches.find(sb => sb.id.toString() === relator.sub_branch_id.toString())
	}

	const enterprise = store.getState().enterprise?.selected?.name




	const pngImageBytes = await fetch(imageUrl).then(response => response.arrayBuffer())
	const relatorPngImageBytes = await fetch(relatorSignUrl).then(response => response.arrayBuffer())
	const timesRomanFont = await documentToSign.embedFont(StandardFonts.HelveticaBold)
	const pngImage = await documentToSign.embedPng(pngImageBytes)
	const relatorPngImage = await documentToSign.embedPng(relatorPngImageBytes)
	const pages = documentToSign.getPages()
	pages.forEach((page, index) => {

		const { width, height } = page.getSize()
		console.log(width, height)

		page.drawImage(pngImage, {
			x: (width / 4) - 40,
			y: 100,
			height: 60,
			width: 120
		})

		page.drawText("Nombre Trabajador: " + sign.name, {
			x: (width / 4) - 30,
			y: 100,
			size: 8,
			font: timesRomanFont
		})
		page.drawText("Cargo: " + worker?.position, {
			x: (width / 4) - 30,
			y: 85,
			size: 8,
			font: timesRomanFont
		})

		page.drawText("Rut: " + worker?.rut, {
			x: (width / 4) - 30,
			y: 70,
			size: 8,
			font: timesRomanFont
		})

		page.drawText(`${worker_sub_branch === null ? `Empresa: ${enterprise}` : `Contratista: ${worker_sub_branch?.name}`}`, {
			x: (width / 4) - 30,
			y: 55,
			size: 8,
			font: timesRomanFont
		})


		page.drawText("Fecha: " + sign.date, {
			x: (width / 4) - 30,
			y: 40,
			size: 8,
			font: timesRomanFont
		})

		page.drawImage(relatorPngImage, {
			x: 2 * (width / 3) - 40,
			y: 100,
			height: 60,
			width: 120
		})

		page.drawText("Nombre Relator: " + relatorSign.name, {
			x: 2 * (width / 3) - 30,
			y: 100,
			size: 8,
			font: timesRomanFont
		})
		page.drawText("Cargo: " + relator?.position, {
			x: 2 * (width / 3) - 30,
			y: 85,
			size: 8,
			font: timesRomanFont
		})

		page.drawText("Rut: " + relator?.rut, {
			x: 2 * (width / 3) - 30,
			y: 70,
			size: 8,
			font: timesRomanFont
		})

		page.drawText(`${relator_sub_branch === null ? `Empresa: ${enterprise}` : `Contratista: ${relator_sub_branch?.name}`}`, {
			x: 2 * (width / 3) - 30,
			y: 55,
			size: 8,
			font: timesRomanFont
		})


		page.drawText("Fecha: " + relatorSign.date, {
			x: 2 * (width / 3) - 30,
			y: 40,
			size: 8,
			font: timesRomanFont
		})

		page.drawText(`Página ${index + 1} de ${pages.length}`, {
			x: (width / 2) - 20,
			y: 15,
			size: 8,
		})

	})

	if (get) return documentToSign

	const resultInBytes = await documentToSign.save()

	var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

	var link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = fileName
	link.click()
}

export const addQRToDocument = async (url, qrcode, name, getFile = false) => {
	if (!name.includes(".pdf")) {
		try {
			const response = await fetch(url)
			const blob = await response.blob()
			if (getFile) return blob
			let downurl = window.URL.createObjectURL(blob)
			let a = document.createElement("a")
			a.href = downurl
			a.download = `${name}`
			a.click()
		} catch {
			console.log("No se pudo descargar documento", "error")
		}

	} else {
		try {
			const pdfBytess = await fetch(url).then(response => response.arrayBuffer())
			const pdfDoc = await PDFDocument.load(pdfBytess)

			const pngImageBytes = await QRCode.toDataURL(qrcode)
			const pngImage = await pdfDoc.embedPng(pngImageBytes)

			const docPages = pdfDoc.getPages()
			docPages.forEach(page => {
				page.drawImage(pngImage, {
					x: 6,
					y: 9,
					height: 60,
					width: 60
				})
				page.drawText(`Este documento fue válidamente firmado usando Tazki`, {
					x: 6,
					y: 3,
					size: 6,
				})
			})

			const resultInBytes = await pdfDoc.save()

			var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes
			if (getFile) return blob
			var link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = name
			link.click()
		} catch {
			console.log("No se pudo descargar documento", "error")
		}
	}
}

export const addQrToFile = async (pdfDoc, name, qrcode, toReturn = false) => {

	const pngImageBytes = await QRCode.toDataURL(qrcode)
	const pngImage = await pdfDoc.embedPng(pngImageBytes)

	const docPages = pdfDoc.getPages()
	docPages.forEach(page => {
		page.drawImage(pngImage, {
			x: 6,
			y: 9,
			height: 60,
			width: 60
		})
		page.drawText(`Este documento fue válidamente firmado usando Tazki`, {
			x: 6,
			y: 3,
			size: 6,
		})
	})

	const resultInBytes = await pdfDoc.save()

	if (toReturn) return resultInBytes

	var blob = new Blob([resultInBytes], { type: "application/pdf" })// change resultByte to bytes

	var link = document.createElement('a')
	link.href = window.URL.createObjectURL(blob)
	link.download = name
	link.click()
}


export function jsonToFormData(data) {
	const form = new FormData()
	Object.keys(data).forEach(key => {
		if (data[key] !== null) { form.append(key, data[key]) }
	})
	return form
}

export const saveUserTypeFilter = (filter) => {
	localStorage.setItem("user_filter", filter)
}

export const getUserTypeFilter = () => {
	const filter = localStorage.getItem("user_filter") || "Todos"
	return filter
}

export function fetchFunction(endpoint, setter, body) {
	return async () => {
		const response = await endpoint(body)
		setter(response.data.info)
	}
}

export function completePath(file, url) {
	if (!file) return "/noimage.png"
	if (file.includes("amazonaws.com")) return file
	if (file.includes("data:image")) return file
	let url_final = url
	if (url.charAt(url.length - 1) === '/') {
		url_final = url.slice(0, -1)
	}
	let encoded_file = encodeURIComponent(file)
	return `${url_final}/${encoded_file}`
}

export function createDataFromJson(json) {
	const body = new FormData()
	Object.keys(json).forEach(key => {
		if (Array.isArray(json[key])) {
			json[key].forEach(value => {
				body.append(`${key}[]`, value)
			})
		} else {
			body.append(key, json[key])
		}
	})
	return body
}

export async function getBase64Image(file) {
	const resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 600, 600, 'PNG', 100, 0,
			uri => {
				resolve(uri)
			},
			'base64'
		)
	})

	const image = await resizeFile(file)
	return image
}

export async function fromBase64ToFile(base64) {
	// Check if its only 1 file
	if (typeof base64 === "string") {
		const files = base64.split("&#&")
		if (files.length > 1) return null
	}

	if (Array.isArray(base64)) {
		const files = []
		await Promise.all(base64.map(async url => {
			if (url.includes("amazonaws")) return files.push(url)
			const response = await fetch(url)
			const contentType = url.substring(5, url.indexOf(';'))
			const extension = contentType.split("/")[1]
			const blob = await response.blob()
			const newFile = new File([blob], extension, { type: contentType })
			files.push(newFile)
		}))
		return files
	}

	const response = await fetch(base64)
	const contentType = base64.substring(5, base64.indexOf(';'))
	const extension = contentType.split("/")[1]
	const blob = await response.blob()
	const newFile = new File([blob], extension, { type: contentType })
	return newFile
}

export async function transformOfflineRequestToOnline(body) {
	const new_body = { ...body }
	const values = Object.values(new_body)
	console.log(values)
	const hasBase64Strings = values.some(v => String(v).includes("data:"))
	if (!hasBase64Strings) return new_body
	for (const key in new_body) {
		const isBase64String = String(new_body[key]).includes("data:")
		if (isBase64String) {
			new_body[key] = await fromBase64ToFile(new_body[key])
		}
	}
	return createDataFromJson(new_body)
}