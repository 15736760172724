import React, { Component } from "react"
import {
    Paper,
    withStyles,
    Typography,
    ListItem,
    ListItemText,
    Divider,
    Button,
    ListItemIcon,
} from "@material-ui/core"
import { connect } from "react-redux"
import { logoutAction } from "../Actions/LoginActions"
import { Face } from "@material-ui/icons"
import autobind from "../Utils/autobind"
import { withRouter } from "react-router-dom"

const style = (theme) => ({
    container: {
        width: 400,
        maxWidth: "100%",
    },
    name: {
        padding: 12,
        background: theme.palette.primary.main,
        "&>h6": {
            color: "white"
        },
    },
    buttons: {
        padding: 12,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    button: {
        width: 200,
        margin: "0 3px",
    },
})

class ProfileCard extends Component {
    constructor(props) {
        super(props)
        autobind(ProfileCard, this)
    }

    handleGotoProfile() {
        const { history, user } = this.props
        history.push(`/workers/${user.account.user.id}`)
        window.location.reload(false)
    }

    render() {
        const { classes, logout, user } = this.props
        return (
            <Paper className={`${classes.container}`} square>
                <div className={classes.name}>
                    <Typography variant="subtitle1">
                        {user.account.user.name}
                    </Typography>
                </div>
                <ListItem button onClick={this.handleGotoProfile}>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle2">
                                Visitar mi perfil
                            </Typography>
                        }
                        secondary={
                            <Typography variant="body2">
                                {user.account.user.email}
                            </Typography>
                        }
                    />
                    <ListItemIcon>
                        <Face />
                    </ListItemIcon>
                </ListItem>
                <Divider style={{ margin: 0 }} />
                <div className={classes.buttons}>
                    <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        className={classes.button}
                        onClick={logout}
                    >
                        Cerrar Sesion
                    </Button>
                </div>
            </Paper>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutAction()),
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ProfileCard))
)
