import React, { Component } from 'react'
import { Button, Collapse, IconButton, Typography, withStyles } from '@material-ui/core'
import Stage from './Stage'
import { createSettingsWorkflowAction, createWorkflowStageAction, editSettingsWorkflowAction, editWorkflowStageAction, getSettingsWorkflowsAction } from '../../../Actions/WorkFlows'
import { connect } from 'react-redux'
import { Edit } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import TextInput from '../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../Shared/SubmitButton'
import { getWorkersAction } from '../../../Actions/EnterpriseAction'
import { createWorkflowPhase, createWorkflowStep, deleteWorkflowPhase, editSettingsWorkflow, editWorkflowPhase, editWorkflowStep, getSettingsWorkflows } from '../../../API/workflows'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import WorkflowContext from './WorkflowProvider'

const style = () => ({
  container: {
    margin: 12,
  },
  card: {
    padding: 12,
    borderRadius: 15,
    background: 'white',
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addCard: {
    background: '#ffffffaa',
    height: 300,
    borderRadius: 15,
    position: 'relative',
    cursor: 'pointer',
    '& > *': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 64,
      width: 64,
      color: 'lightgrey'
    }
  },
  create: {
    marginTop: 12
  }
})

class Process extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      edit: false,
      workflow: null
    }
    autobind(Process, this)
  }


  async componentDidMount() {
    const response = await getSettingsWorkflows()
    if (response?.data?.status !== "success") return false
    const { match } = this.props
    const workflows = response.data.info
    const workflow = workflows.find(wf => wf.id.toString() === match.params.id)
    this.setState({ workflow })
  }

  async handleCreateStep() {
    const { workflow } = this.state
    const body = {
      workflow_id: workflow.id,
      step_data: { name: "Nueva etapa" }
    }
    const response = await createWorkflowStep(body)
    if (response?.data?.status !== "success") return false
    const newWorkflow = response.data.info
    return this.setState({ workflow: newWorkflow })
  }

  handleEdit() {
    const { settings, match } = this.props
    const allWorkflows = settings.workflows.all || []
    const selectedWF = allWorkflows.find(w => w.id === match.params.id) || {}
    this.setState({ edit: !this.state.edit, name: selectedWF.name })
  }

  handleChange(event) {
    const { target } = event
    this.setState({ name: target.value })
  }

  async handleSubmit() {
    const { name, workflow } = this.state
    const body = {
      id: workflow.id,
      workflow_type_id: workflow.workflow_type_id,
      name
    }
    const response = await editSettingsWorkflow(body)
    if (response?.data?.status !== "success") return false
    const editedWorkflow = response.data.info
    this.setState({ name: "", edit: false, workflow: editedWorkflow })
  }

  async handleEditStep(body) {
    const response = await editWorkflowStep(body)
    if (response?.data?.status !== "success") return false
    const editedWorkflow = response.data.info
    this.setState({ workflow: editedWorkflow })
  }

  async handleEditPhase(body) {
    const response = await editWorkflowPhase(body)
    if (response?.data?.status !== "success") return false
    const editedWorkflow = response.data.info
    this.setState({ workflow: editedWorkflow })
  }

  async handleCreatePhase(body) {
    const response = await createWorkflowPhase(body)
    if (response?.data?.status !== "success") return false
    const editedWorkflow = response.data.info
    this.setState({ workflow: editedWorkflow })
  }

  async handleDeletePhase(body) {
    const response = await deleteWorkflowPhase(body)
    if (response?.data?.status !== "success") return false
    const editedWorkflow = response.data.info
    this.setState({ workflow: editedWorkflow })
  }

  renderStages() {
    const { classes, workers, branch } = this.props
    const { workflow } = this.state
    const steps = workflow?.workflow_steps || []
    return steps.map(step => (
      <div className={classes.stage}>
        <Stage branch={branch} workers={workers} stage={step} editStage={this.handleEditStep} />
      </div>
    ))
  }

  render() {
    const { classes } = this.props
    const { name, edit, workflow } = this.state
    const loading = workflow === null
    return (
      <div className={classes.container}>
        <div className={classes.card}>
          <div className={classes.inline}>
            <Typography variant="h1">{workflow?.name || "Cargando..."}</Typography>
            <IconButton onClick={this.handleEdit} disabled={!workflow}>
              <Edit />
            </IconButton>
          </div>
          <Collapse in={edit}>
            <TextInput label="Nombre de proceso" value={name} onChange={this.handleChange} />
            <SubmitButton onClick={this.handleSubmit}>
              Guardar
            </SubmitButton>
          </Collapse>
        </div>
        {loading ?
          <LoaderAnimator />
          :
          <>
            <Button color="primary" variant="contained" onClick={this.handleCreateStep} size="large" className={classes.create}>
              Agregar etapa
            </Button>
            <WorkflowContext.Provider value={{
              onEditStep: this.handleEditStep,
              onEditPhase: this.handleEditPhase,
              onCreatePhase: this.handleCreatePhase,
              onDeletePhase: this.handleDeletePhase
            }}>
              <div className={classes.stages}>
                {this.renderStages()}
              </div>
            </WorkflowContext.Provider>
          </>
        }
      </div >
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
  workers: state.workers,
  branch: state.branch
})

const mapDispatchToProps = dispatch => ({
  getSettingsWorkflows: () => dispatch(getSettingsWorkflowsAction()),
  createSettingsWorkflow: body => dispatch(createSettingsWorkflowAction(body)),
  editSettingsWorkflow: body => dispatch(editSettingsWorkflowAction(body)),
  createWorkflowStage: body => dispatch(createWorkflowStageAction(body)),
  editWorkflowStage: body => dispatch(editWorkflowStageAction(body)),
  getWorkers: () => dispatch(getWorkersAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Process))