import React, { Component } from 'react'
import { Collapse, Dialog, Typography, withStyles } from '@material-ui/core'
import FileInput from '../../../Shared/Inputs/FileInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import DateInput from '../../../Shared/Inputs/DateInput'
import SubmitButton from '../../../Shared/SubmitButton'
import autobind from '../../../Utils/autobind'
import { transformToOptions } from '../../../Utils/functions'
import DocumentContext from './DocumentContext'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import moment from 'moment'

const style = () => ({
  container: {
    padding: 24
  }
})

class DocumentDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(DocumentDialog, this)
  }

  componentDidMount() {
    const { document, type } = this.props
    const { selectedSubcategory, selectedCategory, categories, selectedSubSubcategory } = this.context
    const params = { ...document }

    if (type === "create") {
      if (!!selectedCategory) {
        params.category_id = selectedCategory
      }
      if (!!selectedSubcategory) {
        params.subcategory_id = selectedSubcategory
        const category = categories.find(category => category.subcategories.filter(sub => sub.id === selectedSubcategory).length > 0)
        params.category_id = category.id
      }
      if (!!selectedSubSubcategory) {
        params.sub_subcategory_id = selectedSubSubcategory
        const category = categories.find(category => category.subcategories.filter(sub => sub.sub_sub_categories.filter(subsub => subsub.id === selectedSubSubcategory).length > 0).length > 0)
        const subcategory = category.subcategories.find(sub => sub.sub_sub_categories.filter(subsub => subsub.id === selectedSubSubcategory).length > 0)
        params.category_id = category.id
        params.subcategory_id = subcategory.id
      }
    }

    if (!params.date) { params.date = moment().format("YYYY-MM-DD") }
    this.setState({ params })
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props
    const { open: prevOpen } = prevProps
    if (open !== prevOpen) {
      this.componentDidMount()
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    const { documents } = this.context
    params[target.name] = target.value
    let existsName = null
    if (target.name === "file") {
      params["name"] = target.value.name
      existsName = documents.find(doc => doc.name === target.value.name)
    }
    if (target.name === "name" && target.value !== "") {
      existsName = documents.find(doc => doc.name === target.value)
    }
    if (existsName) {
      this.setState({ params, openNameWarning: existsName })
    } else {
      this.setState({ params, openNameWarning: null })
    }
  }

  handleSend() {
    const { params } = this.state
    const { onClose, type } = this.props
    const { onEdit, onCreate } = this.context
    if (type === "create") {
      onCreate(params)
    } else {
      onEdit(params)
    }
    this.componentDidMount()
    onClose()
  }

  render() {
    const { classes, open, onClose, type } = this.props
    const { categories = [] } = this.context
    const { params, openNameWarning } = this.state
    const selectedSubcategories = categories.find(cat => cat.id.toString() === params?.category_id?.toString())?.subcategories.concat([{ name: "Sin Sub-Carpeta", id: 0 }]) || []
    const selectedSubSubcategories = selectedSubcategories.find(cat => cat.id.toString() === params?.subcategory_id?.toString())?.sub_sub_categories?.concat([{ name: "Sin Sub-Carpeta", id: 0 }]) || []
    return (
      <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
        <div className={classes.container}>
          <Typography variant="h2" className={`${classes.title} ${classes.openTitle}`}>{type === "create" ? "Crear" : "Editar"} Documento</Typography>
          <div className={classes.inputs}>
            <FileInput onChange={this.handleChange} label="Documento" name="file" value={params.file ? params.file.name : "Seleccionar Documento"} />
            <TextInput onChange={this.handleChange} label="Nombre (opcional)" name="name" value={params.name} />
            <Collapse in={openNameWarning}>
              <div className={classes.warningContainer}>
                <Typography variant="body1" className={classes.warning}>¡Ya existe un archivo con este nombre!</Typography>
                <Typography variant="caption" className={classes.warningLocation}>{`${openNameWarning?.category_name}  > ${openNameWarning?.name}`}</Typography>
              </div>
            </Collapse>
            <DateInput onChange={this.handleChange} label="Fecha de creación" name="date" value={params.date} />
            <SelectInput onChange={this.handleChange} label="Carpeta" options={transformToOptions(categories)} name="category_id" value={params.category_id} />
            <Collapse in={selectedSubcategories.length > 0}>
              <SelectInput onChange={this.handleChange} label="Sub-Carpeta" options={transformToOptions(selectedSubcategories)} name="subcategory_id" value={params.subcategory_id} />
              {!!selectedSubSubcategories.length && <SelectInput onChange={this.handleChange} label="Sub-Sub-Carpeta" options={transformToOptions(selectedSubSubcategories)} name="sub_subcategory_id" value={params.sub_subcategory_id} />}
            </Collapse>
            <SubmitButton onClick={this.handleSend}>Guardar</SubmitButton>
          </div>
        </div>
      </Dialog>
    )
  }
}

DocumentDialog.contextType = DocumentContext

export default withStyles(style)(DocumentDialog)