import { Box } from '@mui/material'
import { useState } from 'react'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'

function SupplyType({ supply_type, onEdit }) {

	const [name, setName] = useState(supply_type.name)

	function onSave() {
		const body = { ...supply_type, name }
		onEdit(body)
	}

	return (
		<Box>
			<SeamlessInput color="transparent" value={name} onChange={e => setName(e.target.value)} onBlur={onSave} />
		</Box>
	)
}

export default SupplyType