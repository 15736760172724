import React, { Component } from 'react'
import { Button, Dialog, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import { CategoryOutlined, Delete, DragIndicatorOutlined, Edit, KeyboardArrowDown, ReportOutlined, Settings } from '@material-ui/icons'
import autobind, { addToggle } from '../../../../Utils/autobind'
import FieldOption from './FieldOption'
import ChangeField from './ChangeField'
import CreateOption from './CreateOption'
import OptionSettings from './OptionSettings'
import QuestionCategoryDialog from './QuestionCategoryDialog'
import { Collapse } from '@mui/material'

const style = () => ({
  container: {
    paddingLeft: 12
  },
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.2s linear'
  },
  buttons: {
    display: 'flex'
  },
  dialog: {
    padding: 24
  },
  dialogButtons: {
    marginTop: 24,
    textAlign: 'right',
    '& > *': {
      marginLeft: 12
    }
  },
  drag: {
    marginRight: 12,
    cursor: 'pointer'
  }
})

const fields = [
  { label: "Texto", value: "text" },
  { label: "RUT", value: "rut" },
  { label: "Texto enriquecido", value: "rte" },
  { label: "Lista con opciones", value: "select" },
  { label: "Selección Múltiple", value: "multiselect" },
  { label: "Imagen", value: "img" },
  { label: "Cumplimiento", value: "compliance" },
  { label: "IDS (Experimental)", value: "ids" }
]

class Question extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      openEdit: false,
      openDelete: false
    }
    addToggle(Question, this, "category")
    addToggle(Question, this, "settings")
    autobind(Question, this)
  }

  componentDidMount() {
    const { question, index, onChangeOrder } = this.props
    const element = document.getElementById(`question-${question.label}-${index}`)
    element.addEventListener("dragstart", (e) => {

      e.dataTransfer.effectAllowed = "copy"
      e.dataTransfer.setData('text/plain', JSON.stringify({ actual: index }))
    })

    element.addEventListener("dragend", () => {

    })

    element.addEventListener('dragenter', (e) => {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      element.style.transform = "scale(1.02)"
    })
    element.addEventListener("dragover", (e) => { e.preventDefault() })
    element.addEventListener('dragleave', () => {
      element.style.transform = "scale(1)"
    })
    element.addEventListener("drop", (event) => {
      event.preventDefault()
      console.log(event.dataTransfer.getData("text/plain"))
      const eventData = JSON.parse(event.dataTransfer.getData("text/plain"))
      console.log(`pass ${eventData.actual} to ${index}`)
      onChangeOrder && onChangeOrder(eventData.actual, index)
    })
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleOpenEdit() {
    this.setState({ openEdit: !this.state.openEdit })
  }

  handleOpenDelete() {
    this.setState({ openDelete: !this.state.openDelete })
  }

  handleEdit(body) {
    const { onEdit, index } = this.props
    onEdit(body, index)
    this.setState({ openEdit: false })
  }

  handleSetAsCut(body) {
    const { onEdit, index } = this.props
    onEdit(body, index)
  }

  handleDelete() {
    const { onDelete, index } = this.props
    onDelete(index)
    this.setState({ openDelete: false })
  }

  handleSelectCategory(category) {
    const { onEdit, index, question } = this.props
    const body = { ...question }
    body.category = category
    console.log(body)
    onEdit(body, index)
  }

  handleDeleteOption(optionIndex) {
    const { question, index, onEdit } = this.props
    const options = [...question.options] || []
    options.splice(optionIndex, 1)
    question.options = [...options]
    onEdit(question, index)
  }

  handleEditOption(newOptions, optionIndex) {
    const { question, index, onEdit } = this.props
    const options = [...question.options] || []
    options[optionIndex] = newOptions
    question.options = [...options]
    onEdit(question, index)
  }

  handleAddOption(newOption) {
    const { question, index, onEdit } = this.props
    const options = [...question.options] || []
    options.push(newOption)
    question.options = [...options]
    onEdit(question, index)
  }

  renderOptions() {
    const { question } = this.props
    const options = question.options || []
    return options.map((option, index) => {
      return (
        <div style={{ padding: '6px 12px', margin: 12, background: '#f2f3f8', borderRadius: 8 }}>
          <FieldOption
            key={index}
            option={option}
            readOnly
            field={question}
            onDelete={this.handleDeleteOption}
            onEdit={this.handleEditOption}
            index={index}
          />
        </div>
      )
    })
  }

  render() {
    const { classes, question, editable = true, categories, index } = this.props
    const { open, openEdit, openDelete, openSettings, openCategory } = this.state
    const typeIndex = fields.findIndex(element => element.value === question.type)
    const typeName = fields[typeIndex]?.label
    const hasCategory = !!question.category
    const category = hasCategory && categories.find(cat => cat.value === question.category)
    const isSelect = ["select", "multiselect"].includes(question.type)
    const isCompliance = question?.type === "compliance"
    return (
      <div className={classes.container} style={{ background: hasCategory ? category?.color : "white" }}>
        <div className={`${classes.element} "ch-element"`} style={{ "&::before": { background: 'red' } }} draggable id={`question-${question?.label}-${index}`}>
          <div className={classes.buttons}>
            <DragIndicatorOutlined className={classes.drag} />
            <Typography variant="subtitle1">{`${question?.label} [ ${typeName} ${!!question?.required ? "- Obligatorio" : ""} ] ${hasCategory ? category?.label : ""}`}</Typography>
            {question.isCut &&
              <Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>{"Pregunta de corte"}</Typography>}>
                <ReportOutlined color="secondary" style={{ marginLeft: 6 }} />
              </Tooltip>
            }
          </div>
          <div className={classes.buttons}>
            {question?.type === "select" &&
              <IconButton onClick={this.handleOpenSettings}>
                <Settings />
              </IconButton>
            }
            {["select", "multiselect", "compliance"].includes(question?.type) &&
              <IconButton onClick={this.handleOpen}>
                <KeyboardArrowDown />
              </IconButton>
            }
            <IconButton onClick={this.handleOpenCategory}>
              <CategoryOutlined />
            </IconButton>
            {editable &&
              <IconButton onClick={this.handleOpenEdit}>
                <Edit />
              </IconButton>
            }
            <IconButton onClick={this.handleOpenDelete}>
              <Delete />
            </IconButton>
          </div>
        </div>
        <QuestionCategoryDialog
          value={question?.category}
          open={openCategory}
          onClose={this.handleOpenCategory}
          onSubmit={this.handleSelectCategory}
          categories={categories}
        />
        <OptionSettings onEdit={this.handleSetAsCut} field={question} open={openSettings} onClose={this.handleOpenSettings} />
        <Dialog open={openEdit} onClose={this.handleOpenEdit} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <ChangeField field={question} onEdit={this.handleEdit} />
          </div>
        </Dialog>
        <Dialog open={openDelete} onClose={this.handleOpenDelete} fullWidth maxWidth="sm">
          <div className={classes.dialog}>
            <Typography variant="h4">Eliminar pregunta?</Typography>
            <Typography variant="subtitle1">{`${question?.label} [ ${typeName} ${!!question?.required ? "- Obligatorio" : ""} ]`}</Typography>
            <div className={classes.dialogButtons}>
              <Button color="primary" variant="contained" onClick={this.handleOpenDelete}>Cancelar</Button>
              <Button color="primary" variant="outlined" onClick={this.handleDelete}>Eliminar</Button>
            </div>
          </div>
        </Dialog>
        <Collapse unmountOnExit in={open}>
          {isSelect &&
            <div>
              <Typography variant="h4" style={{ marginBottom: 12 }}>Opciones:</Typography>
              <CreateOption onSubmit={this.handleAddOption} />
              {this.renderOptions()}
            </div>
          }
          {isCompliance &&
            <div>
              <Typography variant='h4'>Más Información:</Typography>
              <Typography style={{ margin: '6px 0' }} variant='subtitle1'>- Titulo desfavorable: {question?.compliance_case}</Typography>
              <Typography style={{ margin: '6px 0' }} variant='subtitle1'>- Titulo de comentario: {question?.compliance_observation}</Typography>
            </div>
          }
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(Question)