import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getAllWorkers = () => {
  return instance2({
    method: 'get',
    url: 'users/get.php'
  })
}

export const createWorker = body => {
  return instance2({
    method: "post",
    url: "/users/create.php",
    data: body,
  })
}