import { Button, Typography } from '@material-ui/core'
import { ArrowRightRounded } from '@material-ui/icons'
import { Box, Dialog } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import { StructureContext } from '../InternalStructure'

const css = {
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	dialog: {
		padding: 3
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function NewSubdivision({ parent, onClose, parent_element }) {

	const [name, setName] = useState("")
	const [warning, toggleWarning] = useToggle(false)
	const { childCreate } = useContext(StructureContext)

	useEffect(() => {
		document.getElementById("defaultButton")?.focus()
	}, [warning])

	function checkEnter(e) {
		if (e.key === "Escape") {
			e.preventDefault()
			onClose()
		}
		if (e.key === "Enter") {
			e.preventDefault()
			if (!name.length) return null
			toggleWarning()
		}
	}

	function checkClose(e) {
		if (e.key === "Enter") {
			e.preventDefault()
			onCreate(false)()
		}
	}

	function onCreate(propagate) {
		return () => {
			const body = { name, parent_id: parent, propagate }
			childCreate(body)
			setName("")
			toggleWarning(false)
		}
	}

	return (
		<Box sx={css.container}>
			<Dialog open={warning} fullWidth maxWidth="sm" onClose={toggleWarning} onKeyPress={checkClose}>
				<Box sx={css.dialog}>
					<Typography style={{ marginBottom: 12 }} variant='h4'>¿Desea propagar el cambio?</Typography>
					<Typography style={{ marginBottom: 12 }} variant='subtitle1'>Desea que está subdivisión también sea creada en las otras {parent_element?.name} relacionadas?</Typography>
					<Box sx={css.buttons}>
						<Button color="primary" variant="outlined" onClick={onCreate(true)}>
							Si, propagar
						</Button>
						<Button color="primary" variant="contained" onClick={onCreate(false)}>
							No, solo crear para esta división
						</Button>
					</Box>
				</Box>
			</Dialog>
			<ArrowRightRounded style={{ height: 28, width: 28, color: 'lightgrey' }} />
			<SeamlessInput
				placeholder="Ingresar nombre..."
				color="transparent"
				variant="h4"
				value={name}
				onChange={e => setName(e.target.value)}
				padding={0}
				onKeyPress={checkEnter}
			/>
		</Box >
	)
}

export default NewSubdivision