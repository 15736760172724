import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import { ArrowDropDownRounded, Error, ErrorOutline, Folder, FolderOutlined } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import DocumentContext from './DocumentContext'
import moment from 'moment'

const style = () => ({
  sub: {
    paddingLeft: 12
  },
  folder: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0',
    cursor: 'pointer',
    '& > h4': {
      maxWidth: "240px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    color: '#6c6c6c', height: 20, width: 20, marginRight: 6,
    transition: 'all 0.2s linear'
  },
  selected: {
    '& *': {
      color: "#7373e8"
    }
  }
})
class Category extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
    autobind(Category, this)
  }

  handleOpen() {
    const { category, isSub, isSubsub } = this.props
    const { onSelect, selectedCategory, selectedSubcategory } = this.context
    const isSelected = isSub ? selectedSubcategory === category.id : selectedCategory === category.id
    onSelect(category, isSub, isSubsub)
    this.setState({ open: !isSelected })
  }

  renderCategories(categories) {
    const { classes, isSub } = this.props
    return categories.map(category => {
      return (
        <div>
          <Category category={category} classes={classes} isSub isSubsub={isSub} />
        </div>
      )
    })
  }

  render() {
    const { classes, category, isSub, isSubsub } = this.props
    const { selectedCategory, selectedSubcategory, selectedSubSubcategory, documents } = this.context
    const { open } = this.state
    const subcategories = category.subcategories || category.sub_sub_categories || []
    const isSelected = (isSubsub && selectedSubSubcategory === category.id) ||
      (!isSubsub && isSub && selectedSubcategory === category.id) ||
      (!isSub && !isSubsub && selectedCategory === category.id)

    // Up to date?

    let isUpToDate = true
    const categoryDocuments = documents.filter(document => document.category_id === category.id) || []

    if (category.frequency === 30) {
      const cutDate = moment(new Date()).startOf("month").add(category.cut_day, 'days')
      if (moment(new Date()) >= cutDate) {
        const validDocs = categoryDocuments.filter(doc => moment(doc.date).isSame(new Date(), "month"))
        if (validDocs.length === 0) {
          isUpToDate = false
        }
      }
    }

    if (category.frequency === 365) {
      const cutDate = moment(new Date()).startOf("year").add(category.cut_day, 'months')
      if (moment(new Date()) >= cutDate) {
        const validDocs = categoryDocuments.filter(doc => moment(doc.date).isSame(new Date(), "year"))
        if (validDocs.length === 0) {
          isUpToDate = false
        }
      }
    }

    if (category.frequency === 1 && categoryDocuments.length === 0) isUpToDate = false

    return (
      <div className={classes.container}>
        <div className={`${classes.folder} ${isSelected ? classes.selected : ""}`} onClick={this.handleOpen}>
          {isUpToDate ?
            (isSelected ? <Folder className={classes.icon} /> : <FolderOutlined className={classes.icon} />)
            :
            (isSelected ? <Error className={classes.icon} /> : <ErrorOutline className={classes.icon} style={{ color: "#ff6c87" }} />)
          }
          <Typography variant="h4">{category.name}</Typography>
          {subcategories?.length > 0 && <ArrowDropDownRounded style={{ transform: open ? "rotate(0deg)" : "rotate(-90deg)" }} className={classes.icon} />}
        </div>
        <Collapse in={open || isSelected}>
          <div className={classes.sub}>
            {subcategories?.length > 0 && this.renderCategories(subcategories)}
          </div>
        </Collapse>
      </div>
    )
  }
}

Category.contextType = DocumentContext

export default withStyles(style)(Category)