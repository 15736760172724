import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import DateInput from '../../../Shared/Inputs/DateInput'

const style = () => ({
  container: {
    padding: 12,
    maxWidth: 450
  }
})

class DateIntervalPicker extends Component {
  render() {
    const { classes, dates, onChange } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h4">Fechas</Typography>
        <Divider style={{ margin: '12px 0' }} />
        <DateInput label="Desde" name="date_start" value={dates.date_start} onChange={onChange} />
        <DateInput label="Hasta" name="date_end" value={dates.date_end} onChange={onChange} />
      </div>
    )
  }
}

export default withStyles(style)(DateIntervalPicker)