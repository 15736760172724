import React, { Component } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router'
import './animations.css'
import { FiAlertCircle, FiCheckCircle, FiSearch, FiUser, FiUsers } from 'react-icons/fi'
import { Home, HomeOutlined } from '@material-ui/icons'
import { Fab } from '@mui/material'


const style = theme => ({
  container: {
    background: "#ADF5FF",
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    height: 64,
    zIndex: 100
  },
  footerButton: {
    flexGrow: 1,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: 24,
    width: 24,
    color: theme.palette.primary.main,
    '&.active': {
      color: theme.palette.purple.light
    }
  }
})

class Footer extends Component {
  constructor() {
    super()
    this.state = {
      selected: -1,
      url: ""
    }
  }

  componentDidMount() {
    const actualUrl = window.location.href
    if (actualUrl.includes("checklists")) {
      this.setState({ selected: 1, url: actualUrl })
    } else if (actualUrl.includes("findings")) {
      this.setState({ selected: 2, url: actualUrl })
    } else if (actualUrl.includes("panel")) {
      this.setState({ selected: 3, url: actualUrl })
    } else if (actualUrl.includes("talks")) {
      this.setState({ selected: 4, url: actualUrl })
    } else if (actualUrl.includes("trainings")) {
      this.setState({ selected: 5, url: actualUrl })
    } else {
      this.setState({ selected: 3, url: actualUrl })
    }
  }

  componentDidUpdate() {
    const { url } = this.state
    const actualUrl = window.location.href
    if (url !== actualUrl) {
      if (actualUrl.includes("checklists")) {
        this.setState({ selected: 1, url: actualUrl })
      } else if (actualUrl.includes("findings")) {
        this.setState({ selected: 2, url: actualUrl })
      } else if (actualUrl.includes("panel")) {
        this.setState({ selected: 3, url: actualUrl })
      } else if (actualUrl.includes("talks")) {
        this.setState({ selected: 4, url: actualUrl })
      } else if (actualUrl.includes("trainings")) {
        this.setState({ selected: 5, url: actualUrl })
      } else {
        this.setState({ selected: 3, url: actualUrl })
      }
    }
  }

  handleGoToLink(option) {
    return () => {
      const { history } = this.props
      switch (option) {
        case 1: {
          history.push('/checklists')
          this.setState({ selected: 1 })
          break
        }
        case 2: {
          history.push('/findings')
          this.setState({ selected: 2 })
          break
        }
        case 3: {
          history.push('/panel')
          this.setState({ selected: 3 })
          break
        }
        case 4: {
          history.push('/talks')
          this.setState({ selected: 4 })
          break
        }
        case 5: {
          history.push('/trainings')
          this.setState({ selected: 5 })
          break
        }
        default: {
          history.push('/')
          break
        }
      }
    }
  }

  render() {
    const { classes } = this.props
    const { selected } = this.state
    return (
      <div className={classes.container}>
        <div className={`${classes.footerButton} ${selected === 1 ? "active" : ""}`}>
          <IconButton onClick={this.handleGoToLink(1)}>
            <FiCheckCircle className={`${classes.icon} ${selected === 1 ? "active" : ""}`} />
          </IconButton>
        </div>
        <div className={`${classes.footerButton} ${selected === 2 ? "active" : ""}`}>
          <IconButton onClick={this.handleGoToLink(2)}>
            <FiSearch className={`${classes.icon} ${selected === 2 ? "active" : ""}`} />
          </IconButton>
        </div>
        <div style={{ transform: 'translate(0, -20px)' }} className={`${classes.footerButton}`}>
          <Fab onClick={this.handleGoToLink(3)} color="default" >
            {selected === 3 ?
              <Home className={`${classes.icon} ${selected === 3 ? "active" : ""}`} />
              :
              <HomeOutlined className={`${classes.icon} ${selected === 3 ? "active" : ""}`} />
            }
          </Fab>
        </div>
        <div className={`${classes.footerButton} ${selected === 4 ? "active" : ""}`}>
          <IconButton onClick={this.handleGoToLink(4)}>
            <FiUser className={`${classes.icon} ${selected === 4 ? "active" : ""}`} />
          </IconButton>
        </div>
        <div className={`${classes.footerButton} ${selected === 5 ? "active" : ""}`}>
          <IconButton onClick={this.handleGoToLink(5)}>
            <FiUsers className={`${classes.icon} ${selected === 5 ? "active" : ""}`} />
          </IconButton>
        </div>
      </div>
    )
  }
}

export default withRouter(withStyles(style)(Footer))