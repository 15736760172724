import { Box, Button, Dialog, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { createTalkCategory, getTalksCategories } from '../../../API/talks'
import FileInput from '../../../Shared/Inputs/FileInput'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import SelectCreateInput from '../../../Shared/Inputs/SelectCreateInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import RTE from '../../../Shared/RTE'
import { baseTalksUrl } from '../../../Utils/baseUrls'
import { dialog_style, end_buttons, link_style, text_space } from '../../../Utils/defaultStyles'
import { filterActiveWorkers } from '../../../Utils/filters'
import { completePath, transformToOptions } from '../../../Utils/functions'

function EditTalkDialog({ open, onClose, talk, onEdit }) {

	const [params, setParams] = useState({})
	const workers = filterActiveWorkers(false)

	useEffect(() => {
		setParams({ ...talk })
	}, [talk])

	function onChange(event) {
		const { target } = event
		setParams(p => ({ ...p, [target.name]: target.value }))
		if (target.name === "new_file") {
			setParams(p => ({ ...p, fileName: target.value?.name }))
		}
	}

	function seeDocument() {
		if (!params.file) return null
		const url = completePath(params.file, baseTalksUrl)
		window.open(url)
	}

	function onSubmit() {
		const body = { ...params }
		onEdit(body)
		onClose()
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={dialog_style}>
				<Typography variant='h1'>Editar {talk?.name}</Typography>
				<TextInput name="name" value={params.name} onChange={onChange} label="Nombre" />
				<SelectCreateInput onCreate={createTalkCategory} name="category_id" value={params.category_id} onChange={onChange} onFetch={getTalksCategories} label="Categoría" />
				<FileInput name="new_file" value={params.fileName} onChange={onChange} label="Documento" />
				<Typography variant='subtitle1' onClick={seeDocument} sx={link_style}>{!!params.file ? "Ver Documento Actual" : ""}</Typography>
				<Box sx={text_space}>
					<Typography variant='subtitle2'>Contenido</Typography>
					<RTE onChange={onChange} name="content" value={params.content} />
				</Box>
				<NewMultiSelectInput value={params.users} name="users" label="Responsables" options={transformToOptions(workers)} onChange={onChange} />
				<TextInput label="Video (Enlace de Youtube)" name="video" value={params.video} onChange={onChange} />
				<Box sx={end_buttons}>
					<Button color="primary" variant="contained" onClick={onSubmit}>
						Editar Charla
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default EditTalkDialog