import React, { Component, useContext, useEffect, useState } from 'react'
import { CloudDownloadOutlined } from '@material-ui/icons'
import { getSubBranches } from '../../../API/branches'
import { Box, Dialog, Divider, IconButton, Typography } from '@mui/material'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import AppContext from '../../../AppContext'

const css = {
	container: {
		padding: 3,
	},
	user: {
		padding: 2
	},
	text: {
		'& > *': {
			margin: '6px 0'
		},
		marginBottom: 2
	},
	users: {
		maxHeight: 350,
		overflow: 'auto'
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}

function NotDoneUsers({ users = [], open, onClose }) {
	const { enterprise } = useContext(AppContext)
	const [filter, setFilter] = useState("Todos")
	const [branches, setBranches] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getSubBranches()
			console.log(response)
			setBranches(response.data.info)
		}
		fetchData()
	}, [])

	function handleDownload(users) {
		return () => {
			const headers = "Nombre,Rut,Email,Cargo"
			const body = users.map(user => `${user.name.split("-")[0]},${user.name.split("-")[1]},${user.email},${user.position}\n`).join("")
			const file = `${headers}\n${body}`
			var element = document.createElement('a')
			element.setAttribute('href', 'data:text/plain;charset=iso-88591,' + file)
			element.setAttribute('download', `UsuariosSinRegistro.csv`)

			element.style.display = 'none'
			document.body.appendChild(element)

			element.click()

			document.body.removeChild(element)
		}
	}

	const options = branches.map(branch => ({
		label: branch.name, value: branch.name
	}))
	const filteredUsers = users.filter(user => {
		if (filter === "Todos") return true
		return filter === branches.find(sb => sb.id === user.sub_branch_id.toString())?.name
	})

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<Box sx={css.container}>
				<Box sx={css.text}>
					<Typography variant="h1">Usuarios que no han participado</Typography>
					<Typography variant="caption" color="secondary">
						Los siguientes usuarios no han realizado ninguno de los registros y formularios
						presentes en la tabla, correspondientes a la actividad y registro o formulario seleccionada
					</Typography>
				</Box>
				<Box sx={css.inline}>
					<SelectInput value={filter} options={options} label="Filtro" onChange={e => setFilter(e.target.value)} />
					<IconButton onClick={handleDownload(filteredUsers)}>
						<CloudDownloadOutlined />
					</IconButton>
				</Box>
				<Divider />
				<Box sx={css.users}>
					{filteredUsers.map(user => {
						const hasSubBranch = !!user?.sub_branch_id
						const subBranch = hasSubBranch ?
							branches.find(sb => sb.id === user.sub_branch_id.toString())?.name :
							enterprise?.selected?.name
						return (
							<Box sx={css.user}>
								<Typography variant="subtitle1">{user.name} - {subBranch}</Typography>
							</Box>
						)
					})}
				</Box>
			</Box>
		</Dialog>
	)

}

export default NotDoneUsers