import { Collapse, Divider } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { Box, Dialog, IconButton, Switch, Typography, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { createBaseSignGroup, editBaseConfig, getBaseConfig, setQuickAccessUsers } from '../../../../API/base_checklists'
import useToggle from '../../../../Hooks/ToogleHook'
import NewMultiSelectInput from '../../../../Shared/Inputs/NewMultiSelectInput'
import TextInput from '../../../../Shared/Inputs/TextInput'
import { filterActiveWorkers } from '../../../../Utils/filters'
import { transformToOptions } from '../../../../Utils/functions'
import BaseConfigGroup from './BaseConfigGroup'

const css = {
	container: {
		padding: 3
	},
	section: {
		margin: '24px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function BaseConfig({ checklist }) {

	const [openDialog, toggleOpen] = useToggle(false)
	const [group, setGroup] = useState({})
	const [base_checklist, setChecklist] = useState({})
	const [users, setUsers] = useState([])
	const workers = filterActiveWorkers()

	useEffect(() => {
		async function fetchData() {
			const body = { id: checklist.id }
			const response = await getBaseConfig(body)
			const config = response.data.info
			setChecklist(config)
			setUsers(config.quick_access_users)
		}
		if (!!checklist) { fetchData() }

	}, [checklist])

	function toggleOption(option) {
		return async () => {
			const value = base_checklist[option] === 0 ? 1 : 0
			const body = { ...base_checklist, [option]: value }
			const response = await editBaseConfig(body)
			setChecklist(response.data.info)
		}
	}

	function onChange(event) {
		const { target } = event
		const newGroup = { ...group }
		newGroup[target.name] = target.value
		setGroup(newGroup)
	}

	async function addUsers() {
		const body = { users, id: base_checklist.id }
		const response = await setQuickAccessUsers(body)
		setChecklist(response.data.info)
	}

	async function createGroup() {
		const group_body = { ...group, base_checklist_id: checklist.id }
		const response = await createBaseSignGroup(group_body)
		setChecklist(response.data.info)
		setGroup({})
	}

	const groups = base_checklist?.base_checklist_sign_groups || []

	return (
		<Box>
			<IconButton onClick={toggleOpen} size="large">
				<Settings />
			</IconButton>
			<Dialog open={openDialog} onClose={toggleOpen} fullWidth maxWidth="sm">
				<Box sx={css.container}>
					<Typography variant='h1'>Configuración {base_checklist?.name}</Typography>
					<Box sx={css.section}>
						<Box sx={css.header}>
							<Typography variant='h4' style={{ marginBottom: 8 }} >Calendario</Typography>
							<Switch onClick={toggleOption("calendar")} checked={base_checklist.calendar === 0} />
						</Box>
						<Typography variant='subtitle1'>
							Desea que la programación de esta registro o formulario se vea reflejada en el calendario
						</Typography>
					</Box>
					<Divider />
					<Box sx={css.section}>
						<Box sx={css.header}>
							<Typography variant='h4' style={{ marginBottom: 8 }} >Acceso rápido</Typography>
							<Switch onClick={toggleOption("quick_access")} checked={base_checklist.quick_access === 1} />
						</Box>
						<Typography variant='subtitle1'>
							Si activa esta opción, el registro o formulario aparecerá en
							la pagina de inicio para que se pueda realizar de manera más directa y accesible
						</Typography>
						<Collapse in={base_checklist.quick_access === 1}>
							<Typography variant='subtitle1' style={{ marginTop: 8 }}><strong>Quiénes tiene acceso rápido en inicio</strong></Typography>
							<Typography variant='caption'>(No seleccionar ninguno para que todos lo tengan)</Typography>
							<NewMultiSelectInput
								onBlur={addUsers}
								value={users}
								onChange={e => setUsers(e.target.value)}
								options={transformToOptions(workers)}
								label=""
							/>
						</Collapse>
					</Box>
					<Divider />
					<Box sx={css.section}>
						<Box sx={css.header}>
							<Typography variant='h4' style={{ marginBottom: 8 }} >Firmas</Typography>
							<Switch onClick={toggleOption("require_signs")} checked={base_checklist.require_signs === 1} />
						</Box>
						<Typography variant='subtitle1'>Solicitar firmas para los registros de este tipo</Typography>
						<Collapse in={base_checklist.require_signs === 1}>
							<Typography variant='subtitle1' style={{ marginTop: 8 }}><strong>Grupos de firmas</strong></Typography>
							{!groups.length && <Typography variant='caption'>No haz creado grupos de firmas...</Typography>}
							{groups.map(group => <BaseConfigGroup group={group} />)}
							<TextInput onChange={onChange} name="name" value={group.name} label="Nombre del grupo" />
							<TextInput onChange={onChange} name="description" value={group.description} label="Descripción" />
							<Box style={{ textAlign: 'end' }} onClick={createGroup}>
								<Button color="secondary" variant="outlined" size="small" disabled={!group.name}>Crear Grupo</Button>
							</Box>
						</Collapse>
					</Box>
				</Box>
			</Dialog>
		</Box>
	)
}

export default BaseConfig