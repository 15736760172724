import React, { Component } from 'react'
import { Button, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import ImageContainer from '../../Shared/ImageContainer'
import { Edit } from '@material-ui/icons'
import autobind, { addToggle } from '../../Utils/autobind'
import TextInput from '../../Shared/Inputs/TextInput'

const style = () => ({
	sub: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: 6,
		margin: '12px 0',
		background: 'whitesmoke',
		borderRadius: 4
	},
	dialog: {
		padding: 24
	},
	imageContainer: {
		padding: 12,
		borderRadius: '50%',
		position: 'relative',
		height: 140,
		width: 140,
		margin: 'auto'
	},
	editImage: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 2,
		'& > *': {
			color: 'white',
			background: '#00000066'
		}
	},
	hidden: {
		position: 'absolute',
		visibility: 'hidden'
	}
})

function simulateClick(id) {
	return () => {
		document.getElementById(id).click()
	}
}
class SubEnterprise extends Component {
	constructor() {
		super()
		this.state = {
			params: {}
		}
		addToggle(SubEnterprise, this, "dialog")
		autobind(SubEnterprise, this)
	}

	componentDidMount() {
		const { sub } = this.props
		const params = { ...sub }
		this.setState({ params })
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	handleSubmit() {
		const { params } = this.state
		const body = { ...params }
		const { onEdit } = this.props
		console.log(body)
		onEdit(body)
		this.handleOpenDialog()
	}

	handleSelectImage(event) {
		const { sub, onEditLogo } = this.props
		const { target } = event
		const file = target.files[0]
		const body = new FormData()
		body.append("file", file)
		body.append("id", sub.id)
		onEditLogo(body)
	}

	render() {
		const { classes, sub } = this.props
		const { openDialog, params } = this.state
		const logoUrl = `${process.env.REACT_APP_IMG_URL}/${process.env.REACT_APP_ENTERPRISE_LOGO}`
		const inputId = "img-input" + sub.id
		return (
			<div className={classes.sub}>
				<div>
					<div style={{ marginBottom: 6 }}>
						<ImageContainer src={sub.logo ? logoUrl + sub.logo : "/noimage.png"} height={64} border={'50%'} />
					</div>
					<div>
						<div style={{ display: 'flex' }}>
							<Typography variant='subtitle1' style={{ width: 100 }} >Nombre:</Typography>
							<Typography variant='subtitle1'> <strong>{sub.name}</strong></Typography>
						</div>
						<div style={{ display: 'flex' }}>
							<Typography variant='subtitle1' style={{ width: 100 }} >Dirección:</Typography>
							<Typography variant='subtitle1'> <strong>{sub.address}</strong></Typography>
						</div>
						<div style={{ display: 'flex' }}>
							<Typography variant='subtitle1' style={{ width: 100 }} >Razón Social: </Typography>
							<Typography variant='subtitle1'> <strong>{sub.legalName}</strong></Typography>
						</div>
						<div style={{ display: 'flex' }}>
							<Typography variant='subtitle1' style={{ width: 100 }} >Rut:</Typography>
							<Typography variant='subtitle1'> <strong>{sub.rut}</strong></Typography>
						</div>
					</div>
				</div>
				<IconButton onClick={this.handleOpenDialog}>
					<Edit />
				</IconButton>
				<input type="file" className={classes.hidden} id={inputId} onChange={this.handleSelectImage} />
				<Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
					<div className={classes.dialog}>
						<Typography variant='h4'>Editar SubEmpresa</Typography>
						<div className={classes.imageContainer}>
							<img src={sub.logo ? logoUrl + sub.logo : "/noimage.png"} alt="logo" width="100%" style={{ borderRadius: '50%' }} />
							<IconButton className={classes.editImage} onClick={simulateClick(inputId)}>
								<Edit />
							</IconButton>
						</div>
						<TextInput name="name" value={params.name} onChange={this.handleChange} label="Nombre" />
						<TextInput name="address" value={params.address} onChange={this.handleChange} label="Dirección" />
						<TextInput name="rut" value={params.rut} onChange={this.handleChange} label="Rut" />
						<TextInput name="legalName" value={params.legalName} onChange={this.handleChange} label="Razón Social" />
						<Button color="primary" onClick={this.handleSubmit} variant="outlined" style={{ marginTop: 6 }}>
							Guardar
						</Button>
					</div>
				</Dialog>
			</div>
		)
	}
}

export default withStyles(style)(SubEnterprise)