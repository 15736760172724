import React, { Component } from "react"
import { loginAction } from "../../Actions/LoginActions"
import sha512 from "js-sha512"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import autobind from "../../Utils/autobind"
import { TextField, Button, withStyles, Typography } from "@material-ui/core"
import {
	getBranches,
	getEnterpriseAction,
	getWorkersAction,
} from "../../Actions/EnterpriseAction"
import { setBranchAction } from "../../Actions/BranchActions"
import { registerLogin } from '../../API/users'
import moment from "moment"

const style = () => ({
	container: {
		padding: 24,
		textAlign: "left",
		maxWidth: 600,
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		"@media (max-width:500px)": {
			width: "80%",
		},
	},
	input: {
		margin: 'auto',
		maxWidth: 370,
		marginTop: 12
	},
	button: {
		display: "flex",
		justifyContent: "center",
		marginTop: 36,
	},
	welcome: {
		margin: "24px 0",
		fontSize: 30,
		textAlign: 'center',
		color: 'white'
	},
	background: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: -1,
		height: '100vh',
		width: '100vw'
	},
	logoContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 12
	},
	logo: {
		width: '80%'
	},
	loginButton: {
		background: '#ADF4FF',
		width: 370,
		borderRadius: 12,
		'& > span': {
			fontWeight: 600,
			color: "#212D4C",
			fontSize: '1.5vh'
		},
		padding: 12
	},
	inputWithUnderline: {
		position: 'relative',
		'&::after': {
			content: '""',
			position: 'absolute',
			height: 2,
			width: '100%',
			bottom: 0,
			left: 0,
			background: '#212D4C'
		}
	},
	inputWithOutline: {
		position: 'relative',
		'&::before': {
			content: '""',
			position: 'absolute',
			height: '70%',
			width: '100%',
			bottom: 0,
			left: 0,
			borderRadius: '12px',
			border: '2px solid white',
		}
	},
	recovery: {
		textAlign: 'center',
		marginTop: 6,
		'& > *': {
			color: '#212D4C',
			fontSize: '0.8rem'
		}
	},
	mobileRecovery: {
		textAlign: 'center',
		marginTop: 6,
		'& > *': {
			color: 'white',
			fontSize: '1.2rem'
		}
	},
	newAccount: {
		textAlign: 'center',
		marginTop: 48,
		'& > *': {
			margin: '6px 0'
		},
		position: 'relative',
		zIndex: 100
	},
	backgroundImage: {
		zIndex: 0,
		position: 'absolute',
		width: '100%',
		height: '100%'
	},
	name: {
		position: 'absolute',
		bottom: 64,
		left: '75%',
		transform: 'translate(-50%, 0)'
	},
	sideLogin: {
		background: 'white',
		position: 'absolute',
		width: '45%',
		zIndex: 2,
		height: '100%',
		borderRadius: '0 100px 100px 0',
		textAlign: 'center',
	},
	title: {
		fontSize: '4vh',
		textAlign: 'start',
		lineHeight: 1,
		color: '#212D4C',
		marginBottom: 12
	},
	mobileTitle: {
		fontSize: '3vh',
		textAlign: 'start',
		lineHeight: 1,
		color: 'white',
		marginBottom: 12,
	},
	center: {
		display: 'flex',
		justifyContent: 'center'
	},
	link: {
		color: '#212D4C',
		fontWeight: 600,
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	mobileLink: {
		color: 'white',
		fontWeight: 600,
		cursor: 'pointer',
		textDecoration: 'underline',
		fontSize: 16
	},
	mobileContainer: {
		background: 'white',
		textAlign: 'center',
		width: '100%',
		height: '100vh'
	},
	mobileBackground: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: '100%',
		height: '75%',

		background: 'linear-gradient(0deg, rgba(33,51,103,1) 0%, rgba(33,45,76,1) 100%)', // to #213367
		borderRadius: '0 126px 0 0'
	},
	mobileLoginContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%'
	}
})

const start_url = window.location.origin

let enterprise = "tazki"
if (start_url.includes("essal")) { enterprise = "essal" }
if (start_url.includes("bricsa")) { enterprise = "bricsa" }
if (start_url.includes("befco")) { enterprise = "befco" }
if (start_url.includes("saesa")) { enterprise = "saesa" }

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			params: {},
		}

		autobind(Login, this)
	}

	handleRecover() {
		const { history } = this.props
		history.push("/recoverpswd")
	}

	handleSend() {
		const {
			login,
			enqueueSnackbar,
			getEnterprise,
			getBranches,
			setBranch,
			getWorkers
		} = this.props
		const { params } = this.state
		const password = sha512(params.password)
		const body = { username: params.username, password }
		login(body, enqueueSnackbar).then((response) => {
			const registerBody = { id: response.payload.data.info.user.id, timestamp: moment().format("YYYY-MM-DD HH:mm:ss") }
			registerLogin(registerBody)
			if (response.payload.data.status === "success") {
				const companyId = response.payload.data.info.user.idCompany
				const body = { companyId }
				getWorkers()
				getEnterprise(body)
				getBranches(body).then((response2) => {
					if (response2.payload.data.status === "success") {
						const allBranches = response2.payload.data.info || []
						const actualBranchId = response?.payload?.data?.info?.user?.branch_id?.toString()
						const branch = actualBranchId === null ? allBranches[0] : allBranches.find(b => b.id === actualBranchId)
						setBranch(branch ? branch : allBranches[0])
						const newEvent = new CustomEvent("branch_change", { detail: { branch: branch ? branch : allBranches[0] } })
						window.dispatchEvent(newEvent)
					}
				})
			}
		})
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	renderDesktop() {
		const { classes } = this.props
		const { params } = this.state
		return (
			<>
				<div className={classes.backgroundImage}>
					<div style={{ position: 'absolute', zIndex: 1, height: '100%', width: '100%', opacity: 0.9, overflow: 'hidden' }}>
						<img style={{ position: 'absolute' }} alt="fondo" src="/login/desktop/layer.jpg" width={'100%'} height={'100%'} />
						<img style={{ position: 'absolute', transform: 'translate(20%, 0)', top: 0, left: 0 }} alt="lineas" src="/login/desktop/lineas.svg" height={'100%'} />
					</div>
					<div style={{ position: 'absolute', overflow: 'hidden', height: '100%', width: '100%' }}>
						<img style={{ position: 'absolute' }} alt="fondo" src="/login/desktop/fondo.jpg" width={'100%'} height={'100%'} />
					</div>
				</div>
				<img className={classes.name} alt="name" src="/login/desktop/name.png" width={260} />
				<div className={classes.sideLogin}>
					<img alt="logo" src={`/enterprises/${enterprise}/192.png`} width={90} style={{ padding: '48px 0 24px 0' }} />
					<div className={classes.center}>
						<Typography className={classes.title}>Accede a tu cuenta</Typography>
					</div>
					<div className={classes.input}>
						<TextField
							fullWidth
							value={params.username || ""}
							type="text"
							name="username"
							label="Usuario"
							className={classes.inputWithUnderline}
							onChange={this.handleChange}
							inputProps={{
								style: {
									padding: "12px 18px",
									color: '#212D4C',
									fontSize: 15
								},
							}}
							InputProps={{
								style: { borderRadius: 12 },
								disableUnderline: true
							}}
							InputLabelProps={{
								style: { color: '#212D4C', paddingLeft: 12 },
							}}
						/>
					</div>
					<div className={classes.input}>
						<TextField
							fullWidth
							value={params.password || ""}
							type="password"
							name="password"
							label="Contraseña"
							onChange={this.handleChange}
							className={classes.inputWithUnderline}
							inputProps={{
								style: {
									padding: "12px 18px",
									color: '#212D4C',
									fontSize: 15
								},
							}}
							InputProps={{
								style: { borderRadius: 12 },
								disableUnderline: true
							}}
							InputLabelProps={{
								style: { color: '#212D4C', paddingLeft: 12 },
							}}
						/>
					</div>
					<div className={classes.button}>
						<Button
							variant="contained"
							className={classes.loginButton}
							onClick={this.handleSend}
						>
							Iniciar Sesión
						</Button>
					</div>
					<div className={classes.newAccount}>
						<div className={classes.recovery}>
							<Typography variant="body1">¿Olvidaste tu contraseña?</Typography>
						</div>
						<Typography
							className={classes.link}
							onClick={this.handleRecover}
						>
							RECUPÉRALA AQUÍ
						</Typography>
					</div>
				</div>
			</>
		)
	}

	renderMobile() {
		const { params } = this.state
		const { classes } = this.props
		return (
			<div className={classes.mobileContainer}>
				<img alt="logo" src={`/enterprises/${enterprise}/192.png`} width={'80vw'} style={{ padding: '48px 0 24px 0' }} />

				<div className={classes.mobileBackground}>
					<img style={{
						position: 'absolute',
						top: 0, left: 0,
						transform: "translate(-24%, -32%)",
						opacity: 0.5
					}} alt="lineas" src="/login/mobile/lineas.svg" height={'120%'} />
					<img style={{ position: 'absolute', bottom: 24, left: '50%', transform: 'translate(-50%, 0)' }} alt="name" src="/login/mobile/name.png" width='45%' />
					<div className={classes.mobileLoginContent}>
						<div style={{ maxWidth: '75%' }}>
							<div className={classes.center}>
								<Typography className={classes.mobileTitle}>Accede a tu cuenta</Typography>
							</div>
							<div className={classes.input}>
								<TextField
									fullWidth
									value={params.username || ""}
									type="text"
									name="username"
									label="Usuario"
									className={classes.inputWithOutline}
									onChange={this.handleChange}
									inputProps={{
										style: {
											padding: "16px 18px",
											color: 'white',
											fontSize: 16
										},
									}}
									InputProps={{
										style: { borderRadius: 12 },
										disableUnderline: true
									}}
									InputLabelProps={{
										style: { color: 'white', paddingLeft: 12 },
									}}
								/>
							</div>
							<div className={classes.input}>
								<TextField
									fullWidth
									value={params.password || ""}
									type="password"
									name="password"
									label="Contraseña"
									onChange={this.handleChange}
									className={classes.inputWithOutline}
									inputProps={{
										style: {
											padding: "16px 18px",
											color: 'white',
											fontSize: 16
										},
									}}
									InputProps={{
										style: { borderRadius: 12 },
										disableUnderline: true
									}}
									InputLabelProps={{
										style: { color: 'white', paddingLeft: 12 },
									}}
								/>
							</div>
							<div className={classes.button} style={{ margin: '24px 0' }}>
								<Button
									variant="contained"
									className={classes.loginButton}
									onClick={this.handleSend}
								>
									Iniciar Sesión
								</Button>
							</div>
							<div className={classes.newAccount}>
								<div className={classes.mobileRecovery}>
									<Typography variant="body1">¿Olvidaste tu contraseña?</Typography>
								</div>
								<Typography
									className={classes.mobileLink}
									onClick={this.handleRecover}
								>
									RECUPÉRALA AQUÍ
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const isMobile = window.innerWidth <= 1000
		if (!isMobile) return this.renderDesktop()
		return this.renderMobile()
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
	login: (body, snackbar) => dispatch(loginAction(body, snackbar)),
	getEnterprise: (body) => dispatch(getEnterpriseAction(body)),
	getBranches: (body) => dispatch(getBranches(body)),
	setBranch: (branch) => dispatch(setBranchAction(branch)),
	getWorkers: () => dispatch(getWorkersAction())
})

export default withStyles(style)(
	withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Login))
)
