import React, { Component } from 'react'
import { Button, Checkbox, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import MySignaturePad from '../../Talks/MySignaturePad'
import autobind from '../../../Utils/autobind'
import { transformToOptions } from '../../../Utils/functions'
import TNTContext from '../TNTContext'
import moment from 'moment'
import { callSnackbar } from '../../../Utils/snackbar'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import PSign from './PSign'
import LoadingDialog from '../../../Shared/LoadingDialog'
import TextInput from '../../../Shared/Inputs/TextInput'
import { formatRut } from '../../../Utils/formats'
import { notifySign } from '../../../API/notifications'

const style = theme => ({
  title: {
    margin: '24px 0 12px'
  },
  signChip: {
    background: theme.palette.blue.main,
    '& > *': {
      color: 'white'
    },
    padding: '6px 18px',
    borderRadius: '24px',
    position: 'absolute',
    top: 8,
    left: 6
  },
  singForm: {
    padding: 12,
    position: 'relative'
  },
  input: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      maxWidth: 350
    }
  },
  signatureContainer: {
    padding: 12,
    display: "flex",
    justifyContent: "center",
  },
  signsOpener: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "0 12px",
    margin: '12px 0',
    borderRadius: 12
  },
  participantSigns: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

class ParticipantSigns extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      openSigns: false,
      loading: false,
      isVisit: false
    }
    autobind(ParticipantSigns, this)
  }

  componentDidMount() {
    const { workers } = this.props
    if (workers.length === 1) {
      const params = { participant_id: workers[0].id }
      this.setState({ params })
    }
  }

  handleToggleVisit() {
    this.setState({ isVisit: !this.state.isVisit })
  }

  handleSignRegistered(worker) {
    return () => {
      const { onSign, element } = this.props
      const { elementType } = this.context
      // const { urls } = this.context
      const selected = element
      const signatures = selected.participants_signs
      const { params } = this.state
      const sign = worker?.signs[0]
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${sign.sign}`
      if (!signatures.map(sign => sign.user_id?.toString()).includes(params.participant_id.toString())) {
        this.setState({ loading: true })
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], "File name", { type: "image/png" })
            const data = new FormData()
            data.append("user_id", params.participant_id)
            data.append("file", file)
            data.append("name", worker?.name?.split("-")[0].trim())
            data.append("id", element.id)
            data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
            onSign(data)
            notifySign(worker, elementType === "talk" ? "Charla" : "Capacitación", window.location.href, element.name)
            this.setState({ params: { ...this.state.params, participant_id: null }, loading: false })
          })
      } else {
        callSnackbar("Solo se puede firmar una vez", "warning")
      }
    }
  }

  handleSave(value) {
    const { params, isVisit } = this.state
    const { workers, onSign, element } = this.props
    const { elementType } = this.context

    const selectedWorker = workers.find(worker => worker.id === params.participant_id)
    let email_worker = { ...selectedWorker }
    let user_name = selectedWorker ? selectedWorker.name.split("-")[0] : ""
    let rut = ""
    if (isVisit) {
      user_name = params.user_name
      rut = params.rut
      email_worker = { email: params.visit_email, name: user_name }
    }

    const signatures = element.participants_signs
    if (!signatures.map(sign => sign.user_id?.toString()).includes(params?.participant_id?.toString())) {
      this.setState({ loading: true })
      fetch(value)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], `${user_name}.png`, { type: "image/png" })
          const data = new FormData()
          data.append("user_id", isVisit ? 0 : params.participant_id)
          data.append("file", file)
          data.append("name", user_name.trim())
          data.append("id", element.id)
          if (!!rut) { data.append("rut", rut) }
          data.append("date", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"))
          onSign(data)
          notifySign(email_worker, elementType === "talk" ? "Charla" : "Capacitación", window.location.href, element.name)
          this.setState({ params: { ...this.state.params, participant_id: null, user_name: null, rut: null, visit_email: "" }, loading: false })
        })
    } else {
      callSnackbar("Solo se puede firmar una vez", "warning")
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state

    // Format rut
    if (target.name === "rut") {
      const newValue = formatRut(target.value)
      params[target.name] = newValue
      return this.setState({ params })
    }
    params[target.name] = target.value
    return this.setState({ params })
  }

  handleOpenSigns() {
    this.setState({ openSigns: !this.state.openSigns })
  }

  renderSigns() {
    const { urls } = this.context
    const { element, workers } = this.props
    const { openSigns } = this.state
    const signatures = element.participants_signs || []
    const isMobile = window.innerWidth <= 500
    const singatureElement = signatures.map((sign) => {
      const imgUrl = process.env.REACT_APP_IMG_URL + urls.signs + '/' + sign.sign
      const selectedWorker = workers.find(worker => worker.id?.toString() === sign.user_id?.toString())
      const disabled = element.user_sign.length === 0
      return (
        <PSign imgUrl={imgUrl} worker={selectedWorker} disabled={disabled} sign={sign} element={element} />
      )
    })
    return (
      <>
        {isMobile ? openSigns ? singatureElement : null : singatureElement}
      </>
    )
  }

  renderVisit() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <div className={classes.visitInputs}>
        <div className={classes.input}>
          <TextInput value={params.user_name} onChange={this.handleChange} label="Nombre" name="user_name" />
        </div>
        <div className={classes.input}>
          <TextInput value={params.rut} onChange={this.handleChange} label="Rut (sin puntos y con guión)" name="rut" />
        </div>
        <div className={classes.input}>
          <TextInput value={params.visit_email} onChange={this.handleChange} label="Correo (opcional)" name="visit_email" />
        </div>
      </div>
    )
  }

  render() {
    const { classes, workers = [], element } = this.props
    const { isFinished, level, hasSigned, isCurrentOwner, isMobile, elementType } = this.context
    const { params, openSigns, loading, isVisit } = this.state
    const isAbleToSign = (!isVisit && params.participant_id) || (isVisit && params.user_name && params.rut)
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <Typography variant="h1" className={classes.title}>Firmas de participantes</Typography>
        {!isFinished &&
          <Paper className={classes.singForm}>
            {(level === 1 || isCurrentOwner) &&
              <div className={classes.checkbox}>
                <Checkbox checked={isVisit} onClick={this.handleToggleVisit} />
                <Typography variant="subtitle1">Firmar como visita</Typography>
              </div>
            }
            {isVisit ?
              this.renderVisit()
              :
              <div className={classes.input}>
                <SelectInput
                  name="participant_id"
                  value={params.participant_id}
                  onChange={this.handleChange}
                  label="Asistente"
                  groupBy={(option) => option.isSupervised ? "Supervisado" : "Otros"}
                  options={transformToOptions(workers)}
                />
              </div>
            }
            <div className={classes.signatureContainer}>
              <Typography variant="h4">Firma asistente</Typography>
            </div>
            <div className={classes.signatureContainer}>
              <MySignaturePad
                clearButton="true"
                save={this.handleSave}
                disabled={!isAbleToSign}
              />
            </div>
            {level > 1 && !isCurrentOwner && workers[0]?.signs?.length > 0 &&
              <div style={{ textAlign: 'center' }}>
                <Button disabled={!isAbleToSign} variant="contained" color="primary" onClick={this.handleSignRegistered(workers[0])}>
                  Usar firma registrada
                </Button>
              </div>
            }
            {!isFinished && hasSigned &&
              <div className={classes.signChip}>
                <Typography variang="subtitle1">Ya firmaste esta {elementType === "talk" ? "charla" : "capacitación"}</Typography>
              </div>
            }
          </Paper>
        }
        {isMobile &&
          <Paper className={classes.signsOpener}>
            <Typography variant="body1">Mostrar firmas ({element?.participants_signs?.length || 0} elementos)</Typography>
            <IconButton onClick={this.handleOpenSigns}>
              {openSigns ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Paper>
        }
        <div className={classes.participantSigns}>
          {this.renderSigns()}
        </div>
      </div>
    )
  }
}

ParticipantSigns.contextType = TNTContext

export default withStyles(style)(ParticipantSigns)