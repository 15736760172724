import { Box, Button, Paper, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getBranches, getBranchTags } from '../../API/branches'
import { checklistReportAmount, checklistReportPercent, fullChecklistReportPercent, getSettingsChecklist, groupReportAmount, groupReportPercent } from '../../API/checklists'
import { usersReportAmount, usersReportPercent } from '../../API/users'
import useToggle from '../../Hooks/ToogleHook'
import NewMultiSelectInput from '../../Shared/Inputs/NewMultiSelectInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import PeriodicalReportTable from '../../Shared/Reports/PeriodicalReportTable'
import { filterActiveWorkers, filterOwnBranches } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'

const css = {
	input: {
		maxWidth: 500,
		padding: 2
	},
	inline: {
		display: 'flex',
		alignItems: 'center',
	}
}

function PeriodicalChecklists() {

	const [branches, setBranches] = useState([])
	const [branch_tags, setTags] = useState([])
	const [filtered_branches, setFiltered] = useState([])
	const [base_checklists, setChecklists] = useState([])
	const [elementId, setElement] = useState(null)
	const [switched, toggleSwitch] = useToggle(true)
	const [tab, setTab] = useState(0)
	const [subtitle, setSubtitle] = useState("Centro de trabajo")
	const [filtered_workers, setWorkers] = useState([])
	const [filtered_tag, setFilteredTag] = useState(null)
	const workers = filterActiveWorkers(false)

	const filtersData = localStorage.getItem("filters")
	const filters = filtersData ? JSON.parse(filtersData) : []

	useEffect(() => {
		async function fetchData() {
			const response = await Promise.all([getBranches(), getSettingsChecklist(), getBranchTags()])
			setBranches(response[0].data.info)
			setChecklists(response[1].data.info)
			setTags(response[2].data.info)
		}

		fetchData()
	}, [])

	useEffect(() => {
	}, [tab])

	useEffect(() => {
		if (tab === 0) { setFiltered(filterOwnBranches(branches)); setSubtitle("Centro de trabajo") }
		if (tab === 1) { setFiltered(branch_tags); setSubtitle("Agrupación") }
		if (tab === 2) { setFiltered(workers); setSubtitle("Colaborador"); setWorkers(workers.map(w => w.id)) }
	}, [branches, tab, branch_tags])

	function mainEndpoint() {
		if (tab === 0) {
			if (switched) return checklistReportAmount
			if (!switched) return checklistReportPercent
		}
		if (tab === 1) {
			if (switched) return groupReportAmount
			if (!switched) return groupReportPercent
		}
		if (tab === 2) {
			if (switched) return usersReportAmount
			if (!switched) return usersReportPercent
		}
	}

	function onEndFilter() {
		const new_filtered_workers = workers.filter(w => filtered_workers.includes(w.id))
		setFiltered(new_filtered_workers)
	}

	function selectFilterTag(e) {
		const selected_tag = e.target.value
		setFilteredTag(selected_tag)
		const filtered_branches_by_tag = filterOwnBranches(branches).filter(branch => !selected_tag || branch.tags.map(tag => String(tag.id)).includes(selected_tag))
		setFiltered(filtered_branches_by_tag)
	}

	function sumColumn(col) {
		return col.reduce((x, y) => Number.isFinite(y) ? x + y : 0, 0)
	}

	function averageColumn(col) {
		let suma = 0
		let amount = 0.0
		col.forEach(x => {
			if (isNaN(parseFloat(x, 10))) return false
			suma += parseFloat(x, 10)
			amount += 1.0
		})
		const avg = amount > 0 ? suma / amount : 0
		return `${avg}%`
	}

	const checklist = base_checklists.find(bc => String(bc.id) === elementId)
	const filtered_checklists = base_checklists.filter(bc => !filters.length || bc.tags.map(tag => filters.includes(tag)).some(Boolean))
	return (
		<Box>
			<Paper>
				<Typography variant='h1' style={{ padding: 12 }} >Reporte registros por periodicidad</Typography>
				<Tabs value={tab} onChange={(e, v) => setTab(v)}>
					<Tab label="Centro de trabajo" />
					<Tab label="Agrupación de centros" />
					<Tab label="Colaboradores" />
				</Tabs>
				<Box sx={css.input}>
					<SelectInput
						label="Seleccionar registro"
						value={elementId}
						options={transformToOptions(filtered_checklists)}
						onChange={e => setElement(e.target.value)}
					/>
					{tab === 0 &&
						<SelectInput
							label="Filtrar por agrupación de centros"
							options={transformToOptions(branch_tags).concat([{ label: "Todos", value: null }])}
							value={filtered_tag}
							onChange={selectFilterTag}
						/>
					}
					{tab === 2 &&
						<NewMultiSelectInput
							onChange={e => setWorkers(e.target.value)}
							value={filtered_workers}
							label="Filtrar colaboradores"
							options={transformToOptions(workers)}
							onBlur={onEndFilter}
						/>
					}
					<Box sx={css.inline}>
						<Button onClick={toggleSwitch} color="primary" variant="outlined" size="small">
							{`Mostrar ${switched ? "Cumplimiento" : "Cantidades"}`}
						</Button>
					</Box>
				</Box>
				<PeriodicalReportTable
					lastRowCallback={switched ? sumColumn : averageColumn}
					subtitle={subtitle}
					fetchEndpoint={mainEndpoint()}
					branches={filtered_branches}
					element={checklist}
					exportCallback={!switched ? fullChecklistReportPercent : null}
					title={`Resultado ${switched ? "Cantidades" : "Cumplimientos"}`}
				/>
			</Paper>
		</Box>
	)
}

export default PeriodicalChecklists