// import { KeyboardArrowRight, MoreVert } from "@material-ui/icons"
// import autobind from "../../Utils/autobind"
// import {
// 	getWorkersAction,
// 	createWorkerAction,
// 	getBranches,
// } from "../../Actions/EnterpriseAction"
// import { connect } from "react-redux"
// import GeneralForm from "../../Shared/GeneralForm"
// import { userInfo, userTableInfo } from "./Info"
// import { transformToOptions, validateForm } from "../../Utils/functions"
// import FormContext from "../../Shared/Cards/Provider"
// import AddElementButton from "../../Shared/AddElementButton"
// import moment from 'moment'
// import PermissionChecker from "../../Shared/Permissions/PermissionChecker"
// import { getSubEnterprises, getWelcome } from "../../API/enterprise"
// import { getPositions, getTableUsers } from "../../API/users"
// import { Box, IconButton, Menu, Paper, Switch, Tab, Tabs } from "@mui/material"
// import { sendMail } from "../../API/notifications"
// import MandatoryDocumentationReport from "./WorkerReports/MandatoryDocumentation/MandatoryDocumentationReport"
// import { filterActiveWorkers, filterOwnBranches } from "../../Utils/filters"
// import LazyTable from "../../Shared/LazyTable"
// import WorkersLazyTable from "./WorkersLazyTable"

// const style = () => ({
// 	container: {
// 		margin: 12,
// 	},
// 	title: {
// 		margin: "6px 0",
// 	},
// 	inline: {
// 		display: "flex",
// 		justifyContent: "space-between",
// 		alignItems: "center",
// 		flexWrap: "wrap",
// 	},
// 	filter: {
// 		maxWidth: 300,
// 		"@media (max-width:500px)": {
// 			width: "100%",
// 			maxWidth: 'unset'
// 		}
// 	},
// 	switch: {
// 		display: 'flex',
// 		alignItems: 'center'
// 	},
// 	paper: {
// 		padding: 8,
// 		margin: '0 0 12px 0'
// 	}
// })

// function formatBaseText(data = {}) {
// 	const name = data.name || "Mati"
// 	const user = data.email || "mati.eynaudi@gmail.com"
// 	const baseText = `<p>${name},</p><p><br>Espero que estés muy bien. A continuación detallo los datos de&nbsp;acceso&nbsp;para&nbsp;<strong>Tazki</strong>:</p><p>Acceso:&nbsp;<a href="https://app1.safeasy.cl">Click Aquí</a></p><p>Usuario: ${user}</p><p>Clave:&nbsp;<span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">es tu </span><strong>Rut sin puntos, sin guión y sin dígito verificador</strong><span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">.</span></p><p>Ejemplo: Si tu rut es 20.059.250-9 tu contraseña será:&nbsp;20059250</p><p>Al ingresar por primera vez, <strong>por favor realizar cambio de clave</strong>. Esto se realiza ingresando en la esquina superior derecha en el ícono de "usuario" y luego donde dice: "Visitar mi perfil". Una vez dentro, está el botón para hacer el <strong>cambio de clave</strong>.</p><p>Por otro lado, para instalar la aplicación en el teléfono hay que seguir los&nbsp;siguientes&nbsp;pasos:</p><p>Android: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-android.pdf">Descarga el instructivo aquí</a>.</p><p>Iphone IOs: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-iphone.pdf">Descarga el instructivo aquí</a>.</p><p>De todas formas, siempre se va a poder&nbsp;acceder&nbsp;desde el navegador utilizando el link de&nbsp;acceso.</p>`
// 	return baseText
// }



// class Workers extends Component {
// 	constructor(props) {
// 		super(props)
// 		this.state = {
// 			params: {
// 				dateBirth: moment(new Date()).format("YYYY-MM-DD"),
// 				date_entry: moment(new Date()).format("YYYY-MM-DD")
// 			},
// 			open: false,
// 			branch: "-1",
// 			sub_enterprises: [],
// 			positions: [],
// 			mustSend: false,
// 			tab: 0
// 		}
// 		autobind(Workers, this)
// 	}

// 	async componentDidMount() {
// 		const { getWorkers, getBranches, user } = this.props
// 		const { params } = this.state
// 		const { account: { user: { branch_id } } } = user
// 		getWorkers()
// 		getBranches()
// 		const responses = await Promise.all([getSubEnterprises(), getPositions()])
// 		const datas = responses.map(r => r.data.info)
// 		const [sub_enterprises, positions] = datas
// 		this.setState({ sub_enterprises, positions, params: { ...params, branch_ids: [branch_id] } })
// 	}

// 	handleOpenForm() {
// 		this.setState({ open: !this.state.open })
// 	}

// 	handleGoto(row) {
// 		return () => {
// 			const { history } = this.props
// 			history.push(`/workers/${row.id}`)
// 		}
// 	}

// 	handleChange(event) {
// 		const { target } = event
// 		const { params } = this.state
// 		params[target.name] = target.value
// 		this.setState({ params })
// 	}

// 	async handleCreate() {
// 		const { params, mustSend } = this.state
// 		const body = { ...params }
// 		const { createWorker } = this.props
// 		body.name = body.firstname
// 		body.dateBirth = moment(new Date()).format("YYYY-MM-DD")
// 		if (!validateForm(userInfo, params)) return false
// 		const response = await createWorker(body)
// 		if (response.payload.data.status !== "success") return false
// 		// Send email to new user
// 		if (mustSend) {
// 			const welcome_body = { branch_id: params?.branch_ids?.[0], sub_enterprise_id: params?.sub_enterprise_id }
// 			const welcomes = await getWelcome(welcome_body)
// 			const welcome_item = welcomes.data.info
// 			const welcome = welcome_item.content
// 			const email_body = formatBaseText(body) + welcome + `<p>Bienvenido!</p><p>Equipo Tazki</p>`
// 			sendMail({
// 				subject: `Bienvenido a Tazki`,
// 				content: email_body,
// 				emails: [body.email],
// 				extra_mails: []
// 			})
// 		}

// 		this.setState({
// 			open: false, params: { dateBirth: moment(new Date()).format("YYYY-MM-DD"), date_entry: moment(new Date()).format("YYYY-MM-DD") }
// 		})



// 	}

// 	handleChangeBranch(event) {
// 		const { target } = event
// 		this.setState({ branch: target.value })
// 	}

// 	setFormInfo(info) {
// 		const { enterprise, user } = this.props
// 		const { params, sub_enterprises, positions } = this.state
// 		const selectedBranches = params.branch_ids || []
// 		const filteredWorkers = filterActiveWorkers(false)
// 			.filter(worker => worker.branch_id === null || worker.branch_ids.filter(x => selectedBranches.includes(x.toString())).length > 0)
// 			.map(worker => ({ ...worker, name: `${worker.name} - ${worker.rut}`, isSupervised: String(worker?.user?.id) === String(user?.account?.user?.id) }))
// 			.sort((wa, wb) => {
// 				if (wa.isSupervised > wb.isSupervised) return -1
// 				if (wa.isSupervised < wb.isSupervised) return 1
// 				return 0
// 			})
// 		info.map((input) => {
// 			switch (input.name) {
// 				case "branch_ids": {
// 					input.options = transformToOptions(filterOwnBranches(enterprise.branches))
// 					return input
// 				}
// 				case "user_id": {
// 					input.options = transformToOptions(filteredWorkers)
// 					return input
// 				}
// 				case "sub_enterprise_id": {
// 					input.options = transformToOptions(sub_enterprises)
// 					return input
// 				}
// 				case "position_id": {
// 					input.options = transformToOptions(positions)
// 					return input
// 				}
// 				default:
// 					return input
// 			}
// 		})

// 		return info
// 	}

// 	render() {
// 		const { classes, user } = this.props
// 		const { params, open, branch, mustSend, tab } = this.state
// 		const level = user.account.user.userType
// 		const actions = level === 1 ? [{ name: "Ver Colaborador", icon: KeyboardArrowRight, action: this.handleGoto }] : []
// 		const filters = [
// 			{ name: "Empresa", label: "sub_branch_id", entity: "sub_branches" },
// 			{ name: "Sucursal", label: "branch_id", entity: "branches" }
// 		]
// 		const formattedInfo = this.setFormInfo(userInfo)
// const allBranches = enterprise.branches ? [{ id: "-1", name: "Todas" }, ...enterprise.branches, { id: "not", name: "No asignado" }] : [{ id: "-1", name: "Todas" }, { id: "not", name: "No asignado" }]

// 		return (
// 			<div className={classes.container}>
// 				<div className={classes.inline}>
// 					<Typography variant="h1" className={classes.title}>
// 						Colaboradores
// 					</Typography>
// 					<PermissionChecker expectedPermissions={["cw"]} >
// 						{level === 1 && <AddElementButton name="Crear Colaborador" open={open} onClick={this.handleOpenForm} />}
// 					</PermissionChecker>
// 				</div>
// 				<Paper style={{ margin: '12px 0', padding: '0 12px' }}>
// 					<Tabs value={tab} onChange={(e, v) => this.setState({ tab: v })}>
// 						<Tab label="Tabla" />
// 						<Tab label="Reporte" />
// 					</Tabs>
// 				</Paper>
// 				<FormContext.Provider value={{ handleChange: this.handleChange, params }}>
// 					<GeneralForm
// 						info={formattedInfo}
// 						onSubmit={this.handleCreate}
// 						open={open}
// 					/>
// 					{open &&
// 						<Paper className={classes.paper}>
// 							<Box className={classes.switch}>
// 								<Switch checked={mustSend} onClick={() => this.setState({ mustSend: !mustSend })} />
// 								<Typography variant='subtitle1'>Enviar correo de bienvenida al usuario creado</Typography>
// 							</Box>
// 						</Paper>
// 					}
// 				</FormContext.Provider>
// 				{tab === 0 && <WorkersLazyTable filters={filters} actions={actions} />}
// 				{tab === 1 && <MandatoryDocumentationReport branch={branch} />}
// 			</div>
// 		)
// 	}
// }

// Workers.propTypes = {}

// Workers.defaultProps = {
// 	workers: { all: [] },
// }

// const mapStateToProps = (state) => ({
// 	workers: state.workers,
// 	enterprise: state.enterprise,
// 	user: state.user,
// 	branch: state.branch

// })

// const mapDispatchToProps = (dispatch) => ({
// 	getWorkers: (body) => dispatch(getWorkersAction(body)),
// 	createWorker: (body) => dispatch(createWorkerAction(body)),
// 	getBranches: (body) => dispatch(getBranches(body)),
// })

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(withStyles(style)(Workers))


import React, { useContext, useEffect, useRef, useState } from "react"
import { Box, Button, IconButton, Menu, MenuItem, Paper, Switch, Tab, Tabs } from "@mui/material"
import useToggle from "../../Hooks/ToogleHook"
import { AddCircleOutline, ImportExport, KeyboardArrowRight, MoreVert } from "@material-ui/icons"
import autobind from "../../Utils/autobind"
import {
	getWorkersAction,
	createWorkerAction,
} from "../../Actions/EnterpriseAction"
import { connect } from "react-redux"
import GeneralForm from "../../Shared/GeneralForm"
import { userInfo, userTableInfo } from "./Info"
import { transformToOptions, validateForm } from "../../Utils/functions"
import FormContext from "../../Shared/Cards/Provider"
import AddElementButton from "../../Shared/AddElementButton"
import moment from 'moment'
import PermissionChecker from "../../Shared/Permissions/PermissionChecker"
import { getSubEnterprises, getWelcome } from "../../API/enterprise"
import { getPositions } from "../../API/users"
import { sendMail } from "../../API/notifications"
import MandatoryDocumentationReport from "./WorkerReports/MandatoryDocumentation/MandatoryDocumentationReport"
import { filterActiveWorkers, filterOwnBranches } from "../../Utils/filters"
import WorkersLazyTable from "./WorkersLazyTable"
import { Typography } from "@material-ui/core"
import AppContext from "../../AppContext"
import { getBranches } from "../../API/branches"
import ImportExcelDialog from "./WokerImport/ImportExcelDialog"
import { createWorker } from "../../API/workers"

const css = {
	container: {
		margin: 2,
	},
	title: {
		margin: "6px 0",
	},
	inline: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
	},
	filter: {
		maxWidth: 300,
		"@media (max-width:500px)": {
			width: "100%",
			maxWidth: 'unset'
		}
	},
	switch: {
		display: 'flex',
		alignItems: 'center'
	},
	paper: {
		padding: 1,
		margin: '0 0 12px 0'
	}
}

function formatBaseText(data = {}) {
	const name = data.name || "Mati"
	const user = data.email || "mati.eynaudi@gmail.com"
	const baseText = `<p>${name},</p><p><br>Espero que estés muy bien. A continuación detallo los datos de&nbsp;acceso&nbsp;para&nbsp;<strong>Tazki</strong>:</p><p>Acceso:&nbsp;<a href="https://app1.safeasy.cl">Click Aquí</a></p><p>Usuario: ${user}</p><p>Clave:&nbsp;<span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">es tu </span><strong>Rut sin puntos, sin guión y sin dígito verificador</strong><span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">.</span></p><p>Ejemplo: Si tu rut es 20.059.250-9 tu contraseña será:&nbsp;20059250</p><p>Al ingresar por primera vez, <strong>por favor realizar cambio de clave</strong>. Esto se realiza ingresando en la esquina superior derecha en el ícono de "usuario" y luego donde dice: "Visitar mi perfil". Una vez dentro, está el botón para hacer el <strong>cambio de clave</strong>.</p><p>Por otro lado, para instalar la aplicación en el teléfono hay que seguir los&nbsp;siguientes&nbsp;pasos:</p><p>Android: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-android.pdf">Descarga el instructivo aquí</a>.</p><p>Iphone IOs: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-iphone.pdf">Descarga el instructivo aquí</a>.</p><p>De todas formas, siempre se va a poder&nbsp;acceder&nbsp;desde el navegador utilizando el link de&nbsp;acceso.</p>`
	return baseText
}

function Workers() {
	const menu = useRef(null)
	const [openMenu, toggleMenu] = useToggle(false)
	const [openCreate, toggleCreate] = useToggle(false)
	const [openDialog, toggleDialog] = useToggle(false)
	const [tab, setTab] = useState(0)
	const [params, setParams] = useState({ dateBirth: moment().format("YYYY-MM-DD"), date_entry: moment(new Date()).format("YYYY-MM-DD") })
	const [mustSend, setSend] = useState(false)
	const [branch, setBranch] = useState(-1)
	const [positions, setPositions] = useState([])
	const [branches, setBranches] = useState([])

	const { account, sub_enterprises, history } = useContext(AppContext)

	useEffect(() => {
		async function fetchData() {
			const responses = await Promise.all([getPositions(), getBranches()])
			const datas = responses.map(r => r.data.info)
			setPositions(datas[0])
			setBranches(datas[1])
		}

		fetchData()

	}, [])

	function onChange(event) {
		const { target } = event
		setParams(p => ({ ...p, [target.name]: target.value }))
	}

	async function onCreate() {
		const body = { ...params }
		body.name = body.firstname
		body.dateBirth = moment(new Date()).format("YYYY-MM-DD")
		if (!validateForm(userInfo, params)) return false
		const response = await createWorker(body)
		if (response.data.status !== "success") return false
		// Send email to new user
		if (mustSend) {
			const welcome_body = { branch_id: params?.branch_ids?.[0], sub_enterprise_id: params?.sub_enterprise_id }
			const welcomes = await getWelcome(welcome_body)
			const welcome_item = welcomes.data.info
			const welcome = welcome_item.content
			const email_body = formatBaseText(body) + welcome + `<p>Bienvenido!</p><p>Equipo Tazki</p>`
			sendMail({
				subject: `Bienvenido a Tazki`,
				content: email_body,
				emails: [body.email],
				extra_mails: []
			})
		}

		setParams({ dateBirth: moment(new Date()).format("YYYY-MM-DD"), date_entry: moment(new Date()).format("YYYY-MM-DD") })
		toggleCreate()
	}

	function gotoWorker(row) {
		return () => {
			history.push(`workers/${row.id}`)
		}
	}

	function setFormInfo(info) {
		const selectedBranches = params.branch_ids || []
		const filteredWorkers = filterActiveWorkers(false)
			.filter(worker => worker.branch_id === null || worker.branch_ids.filter(x => selectedBranches.includes(x.toString())).length > 0)
			.map(worker => ({ ...worker, name: `${worker.name} - ${worker.rut}`, isSupervised: String(worker?.user?.id) === String(account?.user?.id) }))
			.sort((wa, wb) => {
				if (wa.isSupervised > wb.isSupervised) return -1
				if (wa.isSupervised < wb.isSupervised) return 1
				return 0
			})
		info.map((input) => {
			switch (input.name) {
				case "branch_ids": {
					input.options = transformToOptions(filterOwnBranches(branches))
					return input
				}
				case "user_id": {
					input.options = transformToOptions(filteredWorkers)
					return input
				}
				case "sub_enterprise_id": {
					input.options = transformToOptions(sub_enterprises)
					return input
				}
				case "position_id": {
					input.options = transformToOptions(positions)
					return input
				}
				default:
					return input
			}
		})

		return info
	}

	const formattedInfo = setFormInfo(userInfo)
	const level = account.user.userType
	const actions = level === 1 ? [{ name: "Ver Colaborador", icon: KeyboardArrowRight, action: gotoWorker }] : []
	const filters = [
		{ name: "Empresa", label: "sub_branch_id", entity: "sub_branches" },
		{ name: "Sucursal", label: "branch_id", entity: "branches" }
	]

	return (
		<Box sx={css.container}>
			<Box sx={css.inline}>
				<Typography variant="h1" sx={css.title}>
					Colaboradores
				</Typography>
				<IconButton ref={menu} onClick={toggleMenu}>
					<MoreVert />
				</IconButton>
				<Menu open={openMenu} anchorEl={menu.current} onClose={toggleMenu}>
					<MenuItem>
						<Button color="info" startIcon={<AddCircleOutline />} onClick={toggleCreate}>
							Crear Colaborador
						</Button>
					</MenuItem>
					<MenuItem>
						<Button color="info" startIcon={<ImportExport />} onClick={toggleDialog}>
							Importar desde Excel
						</Button>
					</MenuItem>
				</Menu>
			</Box>
			<ImportExcelDialog open={openDialog} onClose={toggleDialog} />
			<Paper style={{ margin: '12px 0', padding: '0 12px' }}>
				<Tabs value={tab} onChange={(e, v) => setTab(v)}>
					<Tab label="Tabla" />
					<Tab label="Reporte" />
				</Tabs>
			</Paper>
			<FormContext.Provider value={{ handleChange: onChange, params }}>
				<GeneralForm
					info={formattedInfo}
					onSubmit={onCreate}
					open={openCreate}
				/>
				{openCreate &&
					<Paper sx={css.paper}>
						<Box sx={css.switch}>
							<Switch checked={mustSend} onClick={() => setSend(ms => !ms)} />
							<Typography variant='subtitle1'>Enviar correo de bienvenida al usuario creado</Typography>
						</Box>
					</Paper>
				}
			</FormContext.Provider>
			{tab === 0 && <WorkersLazyTable filters={filters} actions={actions} />}
			{tab === 1 && <MandatoryDocumentationReport branch={branch} />}
		</Box>
	)
}

export default Workers
