import React, { Component } from 'react'
import { Paper, withStyles } from '@material-ui/core'
import BarChart from '../../Shared/Charts/BarChart'
import LineChart from '../../Shared/Charts/LineChart'

const style = () => ({
    container: {
        padding: 24,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        '&>*': {
            flexBasis: 500
        }
    }
})

const positionOptions = {
    height: "250px",
    width: "100%",
    barThickness: 16,
    enableHover: false,
    detachedLabels: false,
    minValue: 0,
    maxValue: 60,
    stepSize: 10,
    type: "bar",
    yEnableGrid: true,
    xHasTitle: true,
    xTitle: "N° Trabajadores",
}

const options = {}
options.width = '100%'

class Panel extends Component {

    render() {
        const { classes } = this.props
        return (
            <Paper square className={classes.container}>
                <LineChart data={[]} options={options} name="lineas" />
                <BarChart data={[]} options={positionOptions} name="barras" />
            </Paper>
        )
    }
}

export default withStyles(style)(Panel)