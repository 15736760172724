import React, { Component } from 'react'
import { Button, Divider, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../../Utils/autobind'
import ChecklistsElements from './SingleChecklist/ChecklistsElements'
import AddExtraField from './SingleChecklist/AddExtraField'
import Question from './SingleChecklist/Question'
import { Fragment } from 'react'
import { createSubtitle, deleteSubtitle, editSubtitle, getSettingsChecklist } from '../../../API/checklists'
import { createChecklistItem, deleteChecklistItem, editChecklistItem, setChecklistSupplyTypes, switchOrder, updateExtraField } from '../../../API/settings_checklist'
import ChecklistSettingsDialog from '../../Activities/ChecklistSettingsDialog'
import { CategoryOutlined, Settings } from '@material-ui/icons'
import ChangeSections from './SingleChecklist/ChangeSections'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import { connect } from 'react-redux'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import { getSettingsSupplies } from '../../../API/supplies'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import ChecklistSupplyTypes from './SingleChecklist/ChecklistSupplyTypes'
import BaseChecklistAuthorizations from './SingleChecklist/BaseChecklistAuthorizations'
import ChecklistNotifications from './SingleChecklist/ChecklistNotifications'
import BaseConfig from './SingleChecklist/BaseConfig'
import { Collapse } from '@mui/material'

const style = theme => ({
  container: {
    padding: 12
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  input: {
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: "0 6px"
    }
  },
  cardContent: {
    padding: 24,
    '& > h2': {
      margin: "12px 0"
    }
  },
  section: {
    margin: '24px 0',
    padding: 12,
    position: 'relative'
  },
  divider: {
    transition: 'all 0.2s linear'
  },
  categories: {
    maxWidth: 300,
  },
  category: {
    margin: 12,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    '& > div > div > div': {
      background: 'inherit !important'
    },
    '& > *:first-child': {
      margin: '0 6px'
    }
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12
  }
})
class SingleChecklist extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      checklist: {},
      openEditField: false,
      openExtraWarning: false,
      fieldIndex: -1,
      openSettings: false,
      categories: [],
      families: []
    }
    addToggle(SingleChecklist, this, "categories")
    autobind(SingleChecklist, this)
  }

  async componentDidMount() {
    const { match: { params: { id } } } = this.props
    const body = { id }
    const response = await getSettingsChecklist(body)
    const response2 = await getSettingsSupplies()
    if (response.status !== 200) return false
    const checklist = response.data.info
    const supplies = response2.data.info
    const subtitles = checklist.subtitles || []
    this.setCategoriesOrder(subtitles)
    const extraFields = (checklist && checklist.extra_fields) || []
    const firstExtraField = extraFields[0] || {}
    const isCategoriesCreated = Array.isArray(firstExtraField?.categories)


    let extraCategories = checklist.field_categories || []

    if (isCategoriesCreated) { extraCategories = firstExtraField.categories }
    this.setState({ checklist, categories: extraCategories, supplies }, this.setupParams)
  }

  setCategoriesOrder(subtitles) {
    const orderedSubtitles = subtitles.sort((a, b) => {
      if (a.subtitle_order > b.subtitle_order) return 1
      if (a.subtitle_order < b.subtitle_order) return -1
      return 0
    })
    orderedSubtitles.forEach((subtitle, index) => {
      console.log(subtitle)
      if (index + 1 !== subtitle.subtitle_order) {
        const body = { ...subtitle, subtitle_order: index + 1, users: Array.isArray(subtitle.users) ? subtitle.users : JSON.parse(subtitle.users) }
        this.handleEditCategoryName(body)
      }
    })
  }

  setupParams() {
    const { checklist } = this.state
    const params = { name: checklist.name }
    const extraFields = (checklist && checklist.extra_fields) || []

    const firstExtraField = extraFields[0] || {}
    const isCategoriesCreated = Array.isArray(firstExtraField?.categories)

    if (!isCategoriesCreated) {
      extraFields.forEach((ef, index) => {
        ef.categories = checklist.field_categories || []
        this.handleEditExtraNew(ef, index)
      })
    }

    this.setState({ params })
  }

  handleOpenSettings() {
    this.setState({ openSettings: !this.state.openSettings })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    if (target.name.includes("counter")) {
      params[target.name] = params[target.name] === 1 ? 0 : 1
      return this.setState({ params })
    }
    params[target.name] = target.value
    this.setState({ params })
  }

  async handleCreateCategory(body) {
    const response = await createSubtitle(body)
    if (response.status !== 200) return false
    const checklist = response.data.info
    this.setState({ checklist })
  }

  async handleEditCategoryName(body) {
    const { match: { params: { id } } } = this.props
    body.checklist_id = id
    const response = await editSubtitle(body)
    if (response.status !== 200) return false
    const checklist = response.data.info
    this.setState({ checklist })
  }

  async handleDeleteCategory(body) {
    const { match: { params: { id } } } = this.props
    body.checklist_id = id
    const response = await deleteSubtitle(body)
    if (response.status !== 200) return false
    const checklist = response.data.info
    this.setState({ checklist })
  }

  async handleCreate() {
    const { params } = this.state
    const { match } = this.props
    const body = {
      name: params.element_name,
      default_criticality: params.default_criticality,
      counter: params.counter,
      checklist_id: match.params.id
    }
    const response = await createChecklistItem(body)
    const checklist = response.data.info
    this.setState({ params: {}, checklist })
  }

  async handleCreateEmpty(category) {
    const { match } = this.props
    const body = {
      name: "Nuevo elemento",
      default_criticality: 0,
      counter: 0,
      subtitle_id: category,
      checklist_id: match.params.id
    }
    const response = await createChecklistItem(body)
    const checklist = response.data.info
    this.setState({ params: {}, checklist })
  }

  async handleCreateExtra(newField) {
    const { checklist, categories } = this.state
    const oldExtraFields = (checklist && checklist.extra_fields) || []
    newField.categories = categories
    oldExtraFields.push(newField)
    const body = {
      id: checklist.id,
      extra_fields: oldExtraFields
    }
    const response = await updateExtraField(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  async handleEditExtraNew(newField, index) {
    const { checklist } = this.state
    const oldExtraFields = (checklist && checklist.extra_fields) || []
    oldExtraFields[index] = newField
    const body = {
      id: checklist.id,
      extra_fields: oldExtraFields
    }
    const response = await updateExtraField(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  async handleDeleteExtraNew(index) {
    const { checklist } = this.state
    const oldExtraFields = (checklist && checklist.extra_fields) || []
    oldExtraFields.splice(index, 1)
    const body = {
      id: checklist.id,
      extra_fields: oldExtraFields
    }
    const response = await updateExtraField(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  async handleChangeOrderExtra(oldIndex, newIndex) {
    const { checklist } = this.state
    const oldExtraFields = (checklist && checklist.extra_fields) || []
    const oldElement = oldExtraFields.splice(oldIndex, 1)[0]
    oldExtraFields.splice(newIndex, 0, oldElement)
    const body = {
      id: checklist.id,
      extra_fields: oldExtraFields
    }
    const response = await updateExtraField(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  async handleEditItem(body) {
    const response = await editChecklistItem(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  async handleDeleteItem(body) {
    const response = await deleteChecklistItem(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  async handleSwitchOrder(body) {
    const { checklist } = this.state
    body.id = checklist.id
    const response = await switchOrder(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  handleEditCategory(body) {
    const { match: { params: { id } } } = this.props
    body.checklist_id = id
    this.handleEditItem(body)
  }

  async handleChangeSupplyTypes(values) {
    const { match: { params: { id } } } = this.props
    const body = { types: values, id }
    const response = await setChecklistSupplyTypes(body)
    const newChecklist = response.data.info
    this.setState({ params: {}, checklist: newChecklist })
  }

  async handleChangeConfig(body) {

  }

  handleChangeCategoryName(event) {
    const { target } = event
    const { categories } = this.state
    categories[target.name] = { ...categories[target.name], label: target.value }
    this.setState({ categories })
  }

  handleSendCategoryNameEdit() {
    const { checklist, categories } = this.state
    const extraFields = (checklist && checklist.extra_fields) || []
    if (extraFields.length === 0) return null

    extraFields.forEach((ef, index) => {
      ef.categories = categories
      this.handleEditExtraNew(ef, index)
    })
  }

  renderExtraFields() {
    const { classes } = this.props
    const { checklist, categories } = this.state
    const extraFields = (checklist && checklist.extra_fields) || []
    if (extraFields.length === 0) return <Typography variant="subtitle1" style={{ padding: 12 }}>Aún no hay campos en la lista</Typography>

    return extraFields.map((question, index) => {
      return (
        <Fragment key={index}>
          {index > 0 && <Divider className={`${classes.divider}`} />}
          <Question
            categories={categories}
            question={question}
            onEdit={this.handleEditExtraNew}
            index={index}
            onDelete={this.handleDeleteExtraNew}
            onChangeOrder={this.handleChangeOrderExtra}
          />
        </Fragment>
      )
    })
  }

  renderCategories() {
    const { classes } = this.props
    const { categories } = this.state
    return categories.map(category => {
      if (!category.value) return null
      return (
        <div key={category.value} className={classes.category} style={{ background: category.color }}>
          <CategoryOutlined style={{ color: category.color }} />
          <SeamlessInput
            onBlur={this.handleSendCategoryNameEdit}
            variant='subtitle1'
            value={category.label}
            onChange={this.handleChangeCategoryName}
            name={category.value}
          />
        </div>
      )
    })
  }


  render() {
    const { classes, workers } = this.props
    const { checklist, openSettings, openCategories, supplies } = this.state
    const allWorkers = workers.all || []
    return (
      <div className={classes.container}>
        <div className={classes.card}>
          <Paper className={classes.section}>
            <div className={classes.title}>
              <Typography variant="h1">Configuración: {checklist?.name}</Typography>
              <BaseConfig checklist={checklist} onEdit={this.handleChangeConfig} />
            </div>
          </Paper>
          <Paper className={classes.section}>
            <ChecklistSupplyTypes supplies={supplies} onSelectTypes={this.handleChangeSupplyTypes} selectedValues={checklist?.supply_types || []} />
          </Paper>
          <Paper className={classes.section}>
            <ChecklistsElements
              onCreateCategory={this.handleCreateCategory}
              checklist={checklist}
              workers={allWorkers}
              onDelete={this.handleDeleteItem}
              onEdit={this.handleEditItem}
              onChangeCategory={this.handleEditCategory}
              onEditCategory={this.handleEditCategoryName}
              onDeleteCategory={this.handleDeleteCategory}
              onCreateEmpty={this.handleCreateEmpty}
            />
          </Paper>
          <Paper className={classes.section}>
            <div style={{ padding: 12 }}>
              <AddExtraField onCreate={this.handleCreateExtra} />
            </div>
            <Divider />
            <div>
              <div className={classes.inline}>
                <Typography variant="h4">Campos en la lista</Typography>
                <Button size="small" onClick={this.handleOpenCategories} startIcon={<CategoryOutlined />}>
                  Configurar grupos
                </Button>
              </div>
              <Collapse unmountOnExit in={openCategories}>
                <div className={classes.categories}>
                  {this.renderCategories()}
                </div>
              </Collapse>
              {this.renderExtraFields()}
            </div>
          </Paper>
          <Paper className={classes.section}>
            <BaseChecklistAuthorizations checklist={checklist} />
          </Paper>
          <Paper className={classes.section}>
            <ChecklistNotifications checklist={checklist} />
          </Paper>
          <div className={classes.section}>
            <ChangeSections onSwitch={this.handleSwitchOrder} order={checklist?.invert_order} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers
})

export default connect(mapStateToProps)(withStyles(style)(SingleChecklist))