import { Box, IconButton } from "@material-ui/core"
import { Settings } from "@material-ui/icons"
import { Button, Collapse, Dialog, Divider, Switch, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { createActivitySignGroup, setAuthorizedUsers, toggleActivityCalendar } from "../../../API/activities"
import useToggle from "../../../Hooks/ToogleHook"
import NewMultiSelectInput from "../../../Shared/Inputs/NewMultiSelectInput"
import TextInput from "../../../Shared/Inputs/TextInput"
import UsersInput from "../../../Shared/Inputs/UsersInput"
import { filterActiveWorkers } from "../../../Utils/filters"
import BaseConfigGroup from "../../Settings/Checklists/SingleChecklist/BaseConfigGroup"
import { ActivityContext } from "./ActivityNew"
import ActivitySignGroup from "./ActivitySignGroup"

const css = {
	container: {
		padding: 3
	},
	section: {
		margin: '24px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function ManageActivityChecklist({ checklist, onClose }) {

	const { activity, setActivity } = useContext(ActivityContext)

	const [group, setGroup] = useState({})
	const [users, setUsers] = useState([])

	function toggleOption(option) {
		return async () => {
			const body = { id: activity.id, preventive_measure_id: checklist.id, option }
			const response = await toggleActivityCalendar(body)
			setActivity(response.data.info)
		}
	}

	function onChange(event) {
		const { target } = event
		setGroup(g => ({ ...g, [target.name]: target.value }))
	}

	async function addUsers() {
		const body = { id: activity.id, authorized_users: users, preventive_measure_id: checklist.id }
		const response = await setAuthorizedUsers(body)
		setActivity(response.data.info)
	}

	async function createGroup() {
		const body = { id: activity.id, preventive_measure_id: checklist.id, ...group }
		const response = await createActivitySignGroup(body)
		setActivity(response.data.info)
	}

	useEffect(() => {
		if (!!checklist?.authorized_users) {
			console.log(checklist.authorized_users)
			setUsers(checklist?.authorized_users.map(String))
		}
	}, [checklist])

	const groups = checklist?.group_signs || []
	console.log(users)

	return (
		<Dialog open={!!checklist} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={css.container}>
				<Typography variant='h1'>Configuración {checklist?.name}</Typography>
				<Box sx={css.section}>
					<Box sx={css.header}>
						<Typography variant='h4' style={{ marginBottom: 8 }} >Calendario</Typography>
						<Switch onClick={toggleOption("calendar")} checked={checklist?.calendar === 0} />
					</Box>
					<Typography variant='subtitle1'>
						Desea que la programación de esta registro o formulario se vea reflejada en el calendario
					</Typography>
				</Box>
				<Divider />
				<Box sx={css.section}>
					<Box sx={css.header}>
						<Typography variant='h4' style={{ marginBottom: 8 }} >Acceso rápido</Typography>
						<Switch onClick={toggleOption("quick_access")} checked={checklist?.quick_access === 1} />
					</Box>
					<Typography variant='subtitle1'>
						Si activa esta opción, el registro o formulario aparecerá en
						la pagina de inicio para que se pueda realizar de manera más directa y accesible
					</Typography>
					<Collapse in={checklist?.quick_access === 1}>
						<Typography variant='subtitle1' style={{ marginTop: 8 }}><strong>Quiénes tiene acceso rápido en inicio</strong></Typography>
						<Typography variant='caption'>(No seleccionar ninguno para que todos lo tengan)</Typography>
						<UsersInput onBlur={addUsers} options="multiple" value={users} onChange={(e) => setUsers(e.target.value)} label="" />
					</Collapse>
				</Box>
				<Divider />
				<Box sx={css.section}>
					<Box sx={css.header}>
						<Typography variant='h4' style={{ marginBottom: 8 }} >Firmas</Typography>
						<Switch onClick={toggleOption("require_signs")} checked={checklist?.require_signs === 1} />
					</Box>
					<Typography variant='subtitle1'>Solicitar firmas para los registros de este tipo</Typography>
					<Collapse in={checklist?.require_signs === 1}>
						<Typography variant='subtitle1' style={{ marginTop: 8 }}><strong>Grupos de firmas</strong></Typography>
						{!groups.length && <Typography variant='caption'>No haz creado grupos de firmas...</Typography>}
						{groups.map(group => <ActivitySignGroup group={group} />)}
						<TextInput onChange={onChange} name="name" value={group.name} label="Nombre del grupo" />
						<TextInput onChange={onChange} name="description" value={group.description} label="Descripción" />
						<Box style={{ textAlign: 'end' }} onClick={createGroup}>
							<Button color="secondary" variant="outlined" size="small" disabled={!group.name}>Crear Grupo</Button>
						</Box>
					</Collapse>
				</Box>
			</Box>
		</Dialog>
	)
}

export default ManageActivityChecklist