import React, { Component } from 'react'
import { Button, Dialog, IconButton, Menu, MenuItem, Typography, withStyles } from '@material-ui/core'
import { AccessTimeOutlined, CheckCircleOutline, InsertDriveFileOutlined, MoreVert } from '@material-ui/icons'
import autobind, { addToggle } from '../../../Utils/autobind'
import DocumentDialog from './DocumentDialog'
import { addQRToDocument } from '../../../Utils/functions'
import DocumentSigns from './DocumentSigns'
import DocumentContext from './DocumentContext'
import { withRouter } from 'react-router-dom'
import SignDocument from '../../Workers/SignDocument'
import Participants from '../../Talks&Trainings/Participants'
import { connect } from 'react-redux'

const style = () => ({
  container: {
    background: "#f2f3f8",
    padding: '6px 12px',
    borderRadius: 8,
    margin: '3px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: "#7373e8",
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& *': {
      color: "#7373e8",
    },
  },
  name: {
    flexShrink: "1",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialog: {
    padding: "12px 24px",
    '& > *': {
      margin: '12px 0'
    },
    '& > button': {
      marginRight: 12
    }
  }
})

class Document extends Component {
  constructor() {
    super()
    this.state = {
      anchorEl: null,
      openSign: false
    }
    addToggle(Document, this, "edit")
    addToggle(Document, this, "delete")
    addToggle(Document, this, "signs")
    addToggle(Document, this, "participants")
    autobind(Document, this)
  }

  handleOpenMenu() {
    const { document: doc } = this.props
    const { anchorEl } = this.state
    const element = document.getElementById(`menu-${doc.id}`)
    if (!!anchorEl) return this.setState({ anchorEl: null })
    return this.setState({ anchorEl: element })
  }

  handleOpenSign() {
    const { base_url, type = "branch" } = this.context
    const { document } = this.props
    const { openSign } = this.state
    if (openSign) return this.setState({ openSign: false })
    if (document?.file?.includes(".pdf")) {
      document.base_url = base_url
      document.type = type
      const { history } = this.props
      const documentCode = btoa(JSON.stringify(document))
      history.push(`/signpdf/${documentCode}`)
    }
    this.setState({ openSign: true })
  }

  handleDownload(element) {
    return () => {
      const { base_url } = this.context
      const url = `${base_url}${element.file}`
      const code = `${element.id}--b`
      const validateUrl = `${window.location.origin}/validate/${code}`
      addQRToDocument(url, validateUrl, element.file)
    }
  }

  handleSeeDocument(element) {
    return () => {
      const { base_url } = this.context
      window.open(`${base_url}${element.file}`)
    }
  }

  handleDelete() {
    const { document } = this.props
    const { onDelete } = this.context
    onDelete(document)
    this.handleOpenDelete()
  }

  handleSign(body) {
    const { onSign } = this.context
    onSign(body)
  }

  async handleEditParticipants(body) {
    const { onAskSign } = this.context
    onAskSign(body)
    this.handleOpenParticipants()
  }

  render() {
    const { classes, document, disableEdit, user: { account: { user: { id: userId } } }, disableAskSigns } = this.props
    const { anchorEl, openEdit, openSigns, openDelete, openSign, openParticipants } = this.state
    const containSigns = document?.signs?.length > 0 && !disableEdit
    const signs = document?.signs?.map(sign => String(sign.user_id))
    const isSigned = disableEdit
    return (
      <div className={classes.container}>
        <div className={classes.section}>
          {isSigned && (
            signs.includes(String(userId))
              ?
              < CheckCircleOutline style={{ color: 'green', marginRight: 8, height: 26, width: 26 }} />
              :
              <AccessTimeOutlined style={{ color: '#6a6a6a', marginRight: 8, height: 26, width: 26 }} />
          )}
          <div className={classes.name}>
            <div className={`${classes.link}`} onClick={this.handleSeeDocument(document)}>
              <InsertDriveFileOutlined style={{ marginRight: 6 }} />
              <Typography variant='subtitle1' >{document.name}</Typography>
            </div>
            <Typography variant='caption'>{document.date} <strong>{containSigns && "[Firmado]"}</strong></Typography>
          </div>
        </div>
        <div className={classes.section}>
          <Button onClick={this.handleOpenSign}>
            Firmar
          </Button>
          {!disableEdit &&
            <IconButton onClick={this.handleOpenMenu} id={`menu-${document.id}`}>
              <MoreVert />
            </IconButton>
          }
          <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={this.handleOpenMenu}>
            <div onClick={this.handleOpenMenu}>
              <MenuItem onClick={this.handleDownload(document)}>Descargar documento</MenuItem>
              <MenuItem onClick={this.handleOpenSigns}>Ver firmas</MenuItem>
              <MenuItem onClick={this.handleOpenEdit}>Editar</MenuItem>
              <MenuItem onClick={this.handleOpenDelete}>Eliminar</MenuItem>
              {!disableAskSigns &&
                <MenuItem onClick={this.handleOpenParticipants}>Solicitar firmas</MenuItem>
              }
            </div>
          </Menu>
          <DocumentDialog open={openEdit} document={document} onClose={this.handleOpenEdit} />
          <Dialog open={openDelete} onClose={this.handleOpenDelete} fullWidth maxWidth="sm">
            <div className={classes.dialog}>
              <Typography variant="h4">Seguro que deseas eliminar {document?.name}?</Typography>
              <Button color="primary" variant="contained" onClick={this.handleDelete}>
                Eliminar
              </Button>
              <Button color="primary" variant="outlined" onClick={this.handleOpenDelete}>
                Cancelar
              </Button>
            </div>
          </Dialog>
          <Dialog open={openParticipants} onClose={this.handleOpenParticipants} fullWidth maxWidth="sm">
            <Participants
              id={document.id}
              onSubmit={this.handleEditParticipants}
              participants={document?.requested_participants || []}
              element={document}
            />
          </Dialog>
          <DocumentSigns signs={document?.signs} open={openSigns} onClose={this.handleOpenSigns} />
          <SignDocument document={document} open={openSign} onClose={this.handleOpenSign} onSubmit={this.handleSign} />
        </div>
      </div>
    )
  }
}

Document.contextType = DocumentContext

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(withRouter(withStyles(style)(Document)))