import React, { Component } from "react"
import { withStyles, Paper, Tab, Typography, Divider, Switch, Box, Tooltip } from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
import { approveFindingAction, assignFindingAction, dismissFindingAction, getFindingsAction, solveFindingAction } from "../../Actions/FindingActions"
import { connect } from "react-redux"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import GroupDialog from "./GroupDialog"
import OptimalCard from "../Finding&Conformities/OptimalCard"
import { KeyboardArrowRight, Mood } from "@material-ui/icons"
import TagCell from "../../Shared/TableRenders/TagCell"
import moment from "moment"
import { csv_to_excel } from "../../API/enterprise"
import DateIntervalSelector from "../../Shared/Inputs/DateIntervalSelector"

const style = theme => ({
	container: {
		margin: 12,
	},
	title: {
		margin: "36px 0 12px 0 ",
	},
	divider: {
		marginBottom: 24,
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 24,
	},
	cardContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		gap: '12px',
		'&>*': {
			maxWidth: 350,
			"@media (max-width:500px)": {
				width: '100%',
				maxWidth: 'unset'
			}
		}
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: "36px 0 12px 0 "
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px'
	},
	filterContainer: {
		display: 'flex',
		padding: 12,
		'&>*': {
			maxWidth: 350,
			flexBasis: 350
		}
	},
	titleText: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	mainText: {
		height: 50
	},
	tooltip: {
		color: "white"
	},
	badge: {
		background: theme.palette.blue.main,
		textAlign: 'center',
		width: 24,
		height: 24,
		borderRadius: '50%',
		position: 'absolute',
		top: -6,
		right: -6,
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > *': {
			color: 'white',
			fontWeight: 600
		},
		cursor: 'pointer'
	}
})

export const criticities = {
	"Altamente Critico": "#ff6c87",
	"Medianamente Critico": "#ffab6e",
	"Poco Critico": "darkgoldenrod",
	"No Critico": "#f2f5ff",
	"No Asignado": "black"
}

export const criticities_index = {
	0: "No Asignado",
	1: 'No Critico',
	2: "Poco Critico",
	3: "Medianamente Critico",
	4: "Altamente Critico"
}

const criticalityFormat = (level) => {
	switch (level) {
		case 0: { return "No Asignado" }
		case 1: { return 'No Critico' }
		case 2: { return "Poco Critico" }
		case 3: { return "Medianamente Critico" }
		case 4: { return "Altamente Critico" }
		default: { return "No Asignado" }
	}
}

const criticalityDeFormat = (level) => {
	switch (level) {
		case "No Asignado": { return 0 }
		case 'No Critico': { return 1 }
		case "Poco Critico": { return 2 }
		case "Medianamente Critico": { return 3 }
		case "Altamente Critico": { return 4 }
		default: { return 0 }
	}
}

function calculate_late_days(value, element) {
	if (value === "0000-00-00") return "-"
	if (["Terminado", "Aprobado"].includes(element.status)) {
		const total = moment(element.date_solved).diff(moment(element.date_limit), "days")
		return total > 0 ? `${total} días` : "0 días"
	}
	const total = moment().diff(moment(element.date_limit), "days")
	return total > 0 ? `${total} días` : "0 días"
}

function calculate_speed(value, element) {
	if (element.date_solved === "0000-00-00") return "-"
	return `${moment(element.date_solved).diff(moment(element.date_assigned), "days")} días`
}

function LimitedCell({ value, as_string }) {
	if (as_string) return value
	return (
		<Box style={{ width: 200, overflow: 'hidden' }}>
			<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} >{value}</Typography>}>
				<Typography variant='subtitle1'>{value}</Typography>
			</Tooltip>
		</Box>
	)
}

const tableInfo = [
	{ name: "Descripción", label: "checklist_item_name", render: LimitedCell },
	{ name: "Centro de trabajo", label: "branch" },
	{ name: "Grupos", label: "tags", render: TagCell },
	{ name: "Registro o Formulario", label: "preventive_measure" },
	{ name: "Estado", label: "status" },
	{ name: "Detectado por", label: "user_creator" },
	{ name: "Responsable", label: "user" },
	{ name: "Fecha Detectado", label: "date_detected" },
	{ name: "Fecha Asignado", label: "date_assigned" },
	{ name: "Fecha Aprobado", label: "date_approved" },
	{ name: "Fecha Resuelto", label: "date_solved" },
	{ name: "Días de demora", label: "late_days", format: calculate_late_days },
	{ name: "Velocidad", label: "speed", format: calculate_speed },
	{ name: "Fecha Límite", label: "date_limit" },
	{ name: "Acción inmediata", label: "inmediate_action" },
	{ name: "Criticidad", label: "criticality", format: criticalityFormat, unformat: criticalityDeFormat },
]

const filters = [
	{ name: "Nombre", label: "user" },
	{ name: "Estado", label: "status" },
	{ name: "Registro", label: "preventive_measure" },
	{ name: "Criticidad", label: "criticality", format: criticalityFormat, unformat: criticalityDeFormat },
]



const sortables = [
	{ name: "Fecha Limite", label: "date_limit" },
	{ name: "Fecha Detectado", label: "date_detected" },
	{ name: "Nombre", label: "checklist_item_name" },
	{ name: "Usuario", label: "user" }
]

class Findings extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tab: 0,
			loading: false,
			selectedGroup: null,
			pendingGroups: [],
			approvedGroups: [],
			viewCards: true,
			dates: {
				date_start: moment().startOf("week").format("YYYY-MM-DD"),
				date_end: moment().endOf("week").format("YYYY-MM-DD")
			}
		}
		autobind(Findings, this)
	}

	componentDidMount() {
		window.addEventListener("branch_change", this.handleReload)
		this.handleReload()
	}

	componentWillUnmount() {
		window.removeEventListener("branch_change", this.handleReload)
	}

	async handleReload() {
		const { getFindings, getWorkers, online } = this.props
		const { dates } = this.state
		if (!online.status) return null
		this.setState({ loading: true })
		await getFindings(dates)
		this.setState({ loading: false })
		getWorkers()
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value })
	}

	handleSwitchView() {
		const { viewCards } = this.state
		this.setState({ viewCards: !viewCards })
	}

	handleGotoFinding(row) {
		return () => {
			const { history } = this.props
			history.push(`/findings/${row.id}`)
		}
	}

	renderFinishedSubquestions(field, values, columns, object) {
		if (!field.options || field.options.length === 0) return null
		const selectedOption = field.options.find(option => option.label === field.value)
		if (!selectedOption) return null
		const { subquestions = [] } = selectedOption
		if (subquestions.length === 0) return null
		return subquestions.forEach(question => {
			values.push(question.value)
			columns.push(question.label)
			object[question.label] = question.value
			this.renderFinishedSubquestions(question, values, columns, object)
		})
	}

	async handleDownload(file_structure, data, info) {
		const values = []
		const columns = []
		let globalColumns = []
		let realColumns = []
		const objects = []

		data.forEach(finding => {
			const f_columns = Object.keys(finding)
			const r_columns = info.map(i => `${i.name}#&H#${i.label}`).concat(["Comentarios#&H#comments"])
			const f_object = {}
			info.forEach(i => {
				const label = i.label
				const hasFormat = !!i.format
				const hasRender = !!i.render
				let value = finding[i.label]
				if (hasFormat) { value = i.format(value, finding) }
				if (hasRender) { value = i.render({ value: value, as_string: true }) }
				f_object[label] = value
			})
			const f_values = Object.values(finding)
			const fields = finding.checklist_extra_fields || []
			fields.forEach(field => {
				f_values.push(field.value)
				f_columns.push(field.label)
				r_columns.push(field.label)
				f_object[field.label] = field.value
				this.renderFinishedSubquestions(field, f_values, r_columns, f_object)
			})
			values.push(f_values)
			columns.push(f_columns)
			objects.push(f_object)
			if (r_columns.length > globalColumns.length) {
				globalColumns = [...f_columns]
				realColumns = [...r_columns]
			}
		})

		const headers = realColumns.map(col => col.split("#&H#")[0])
		const rows = objects.map(finding => {
			let row = ""
			realColumns.forEach(header => {
				const head = header.split("#&H#").length > 1 ? header.split("#&H#")[1] : header
				row += `${!!finding[head] ? String(finding[head]).replaceAll(";", "-").replaceAll("\n", "") : "-"};`
			})
			row += "\n"
			return row
		})

		const file = headers.join(";") + "\n" + rows.join("")
		const response = await csv_to_excel({ csv: file, name: "Hallazgos" })
		const url = window.URL.createObjectURL(new Blob([response.data]))
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', `Hallazgos.xlsx`)
		document.body.appendChild(link)
		link.click()
	}

	handleChangeDates(dates) {
		this.setState({ dates }, this.handleReload)
	}

	renderCallbackFinding(element, index) {
		const { assignFinding, solveFinding, approveFinding, dismissFinding, classes } = this.props
		const finding = element.finding
		const title = {
			main: [finding.checklist_item_name],
			text: [finding.preventive_measure, finding.activity]
		}
		const secondImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
		const firstImgURL = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_FINDING_IMG_FOLDER}`
		return (
			<>{element.length > 1 ?
				<div style={{ position: 'relative' }} key={finding.id}>
					{/* <Badge style={{ cursor: 'pointer' }} badgeContent={element.length} color="primary" onClick={() => this.setState({ selectedGroup: element.group })}>
					</Badge> */}
					<div className={classes.badge} onClick={() => this.setState({ selectedGroup: element.group })}>
						<Typography variant="subtitle1">{element.length}</Typography>
					</div>
					<OptimalCard
						group={element.group}
						urls={[firstImgURL, secondImgURL]}
						goto="findings"
						title={title}
						key={finding.id}
						finding={finding}
						approve={approveFinding}
						dismiss={dismissFinding}
						assign={assignFinding}
						solve={solveFinding}
					/>
				</div>
				:
				<OptimalCard
					group={element.group}
					urls={[firstImgURL, secondImgURL]}
					goto="findings"
					title={title}
					key={finding.id}
					finding={finding}
					approve={approveFinding}
					dismiss={dismissFinding}
					assign={assignFinding}
					solve={solveFinding}
				/>
			}
			</>
		)
	}

	groupFindings() {
		const { findings } = this.props
		const allFindings = findings.all || []
		const groups = {}
		allFindings
			.filter(finding => finding.status !== "Desestimado")
			.filter(finding => finding.status !== "Aprobado")
			.forEach(finding => {
				const key = {
					activity_id: finding.activity_id,
					preventive_measure_id: finding.preventive_measure_id,
					checklist_item_name: finding.checklist_item_name,
					date: "",
					status: finding.status === 'Terminado' ? "Ready" : "NotReady",
					id: finding.id
				}
				const stringKey = JSON.stringify(key)
				const group = groups[stringKey]
				if (group !== undefined) {
					if (group[0].date_detected === finding.date_detected) {
						key.date = finding.id
						const newStringKey = JSON.stringify(key)
						groups[newStringKey] = [finding]
					} else {
						group.push(finding)
						groups[stringKey] = group
					}
				} else {
					groups[stringKey] = [finding]
				}
			})
		const reducedGroups = Object.values(groups).map(group => {
			const reducedGroup = { ...group[0] }
			reducedGroup.finding = group[0]
			reducedGroup.length = group.length
			reducedGroup.group = group
			return reducedGroup
		})
		return reducedGroups
	}

	groupApprovedFindings() {
		const { findings } = this.props
		const allFindings = findings.all || []
		const groups = {}
		allFindings
			.filter(finding => finding.status !== "Desestimado")
			.filter(finding => finding.status === "Aprobado")
			.forEach(finding => {
				const key = {
					activity_id: finding.activity_id,
					preventive_measure_id: finding.preventive_measure_id,
					checklist_item_name: finding.checklist_item_name,
					date_approved: finding.date_approved,
					id: finding.id
				}
				const stringKey = JSON.stringify(key)
				const group = groups[stringKey]
				if (group !== undefined) {
					group.push(finding)
					groups[stringKey] = group
				} else {
					groups[stringKey] = [finding]
				}
			})
		const reducedGroups = Object.values(groups).map(group => {
			const reducedGroup = { ...group[0] }
			reducedGroup.finding = group[0]
			reducedGroup.length = group.length
			reducedGroup.group = group
			return reducedGroup
		})
		return reducedGroups
	}

	render() {
		const { classes } = this.props
		const { tab, loading, selectedGroup, viewCards } = this.state
		const actions = [
			{ name: "Ver Hallazgo", icon: KeyboardArrowRight, action: this.handleGotoFinding }
		]
		const pendingGroups = this.groupFindings()
		const approvedGroups = this.groupApprovedFindings()
		return (
			<div className={classes.container}>
				<div className={classes.header}>
					<Typography variant="h1">Hallazgos</Typography>
				</div>
				<Divider className={classes.divider} />
				<Paper square>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h4">
									Hallazgos en proceso
								</Typography>
							}
							value={0}
						/>
						<Tab
							label={
								<Typography variant="h4">
									Hallazgos aprobados
								</Typography>
							}
							value={1}
						/>
					</MyTabs>
				</Paper>
				<Box style={{ margin: '24px 0' }}>
					<Typography variant='h4'>Seleccione Intervalo de Fechas</Typography>
					<DateIntervalSelector onDateChange={this.handleChangeDates} />
				</Box>
				<TabPanel value={tab} index={0}>
					<div className={classes.inline}>
						<Typography variant="h1">
							Hallazgos en proceso
						</Typography>
					</div>
					<Divider className={classes.divider} />
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant='subtitle1'>Formato de información: {viewCards ? "Tarjetas" : "Tabla"}</Typography>
						<Switch checked={viewCards} onClick={this.handleSwitchView} />
					</div>
					{loading ?
						<LoaderAnimator loading={loading} />
						:
						<GeneralTable
							name="Hallazgos"
							data={pendingGroups}
							onlyCards={viewCards}
							callbackForRendering={this.renderCallbackFinding.bind(this)}
							info={tableInfo}
							sortings={sortables}
							filters={filters}
							actions={actions}
							exportCallback={this.handleDownload}
						/>
					}
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Typography variant="h1" className={classes.title}>
						Hallazgos aprobados
					</Typography>
					<Divider className={classes.divider} />
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant='subtitle1'>Formato de información: {viewCards ? "Tarjetas" : "Tabla"}</Typography>
						<Switch checked={viewCards} onClick={this.handleSwitchView} />
					</div>
					{loading ?
						<LoaderAnimator loading={loading} />
						:
						<GeneralTable
							name="Hallazgos_Aprobados"
							data={approvedGroups}
							callbackForRendering={this.renderCallbackFinding.bind(this)}
							info={tableInfo}
							onlyCards={viewCards}
							sortings={sortables}
							filters={filters}
							actions={actions}
							exportCallback={this.handleDownload}
						/>
					}
				</TabPanel>
				<GroupDialog open={selectedGroup !== null} group={selectedGroup} onClose={() => this.setState({ selectedGroup: null })} />
			</div>
		)
	}
}

const mapStateToProps = state => ({
	findings: state.findings,
	online: state.online
})

const mapDispatchToProps = dispatch => ({
	getFindings: (params) => dispatch(getFindingsAction(params)),
	getWorkers: () => dispatch(getWorkersAction()),
	assignFinding: body => dispatch(assignFindingAction(body)),
	dismissFinding: body => dispatch(dismissFindingAction(body)),
	approveFinding: (body) => dispatch(approveFindingAction(body)),
	solveFinding: (body, formData) => dispatch(solveFindingAction(body, formData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Findings))
