import { Button } from '@material-ui/core'
import { Box, Checkbox, Dialog, Typography } from '@mui/material'
import { useState } from 'react'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import { filterActiveWorkers } from '../../../Utils/filters'
import { transformToOptions } from '../../../Utils/functions'

const css = {
	container: {
		padding: 3
	},
	check: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	}
}

function WorkflowUsers({ open, onClose, step, onEdit }) {

	const { data: initialData } = step
	const [data, setData] = useState(initialData)

	const workers = filterActiveWorkers(false)

	function onChange(event) {
		const { target } = event
		const newData = { ...data }
		newData[target.name] = target.value
		setData(newData)
	}

	function finish() {
		const body = { ...step, data }
		onEdit(body)
		onClose()
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<Box sx={css.container}>
				<Typography variant='h4'>Responsables:</Typography>
				<MultiSelectInput onChange={onChange} name="responsible_ids" value={data?.responsible_ids} options={transformToOptions(workers)} label="responsables" />
				<Box sx={css.check}>
					<Typography variant='subtitle1'>Asignar creador como responsable de esta etapa</Typography>
					<Checkbox checked={data?.owner_as_responsible} onClick={() => setData({ ...data, owner_as_responsible: !data.owner_as_responsible })} />
				</Box>
				<Typography variant='h4'>Notificados</Typography>
				<MultiSelectInput onChange={onChange} name="auth_ids" value={data?.auth_ids} options={transformToOptions(workers)} label="responsables" />
				<Box sx={css.check}>
					<Typography variant='subtitle1'>Asignar creador como notificado para esta etapa</Typography>
					<Checkbox checked={data?.owner_as_auth} onClick={() => setData({ ...data, owner_as_auth: !data.owner_as_auth })} />
				</Box>
				<Button color="primary" variant="contained" onClick={finish}>
					Guardar
				</Button>
			</Box>
		</Dialog>
	)
}

export default WorkflowUsers