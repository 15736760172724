import React, { Component } from 'react'
import { Button, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../Utils/autobind'
import { changeSubEnterpriseLogo, createSubEnterprise, editSubEnterprise, getSubEnterprises } from '../../API/enterprise'
import { AddCircleOutline, Edit } from '@material-ui/icons'
import ImageContainer from '../../Shared/ImageContainer'
import SubEnterprise from './SubEnterprise'
import TextInput from '../../Shared/Inputs/TextInput'

const style = () => ({
	container: {
		margin: '12px 0',
		padding: 12,
		borderRadius: 8,
		background: 'white'
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	dialog: {
		padding: 24
	},
})

class SubEnterprises extends Component {
	constructor() {
		super()
		this.state = {
			sub_enterprises: [],
			params: {}
		}
		addToggle(SubEnterprises, this, "dialog")
		autobind(SubEnterprises, this)
	}

	async componentDidMount() {
		const response = await getSubEnterprises()
		const { data: { info: sub_enterprises } } = response
		this.setState({ sub_enterprises })
	}

	async handleEdit(body) {
		const response = await editSubEnterprise(body)
		const { data: { info: updated } } = response
		const { sub_enterprises } = this.state
		const new_index = sub_enterprises.findIndex(se => se.id === updated.id)
		sub_enterprises[new_index] = updated
		this.setState({ sub_enterprises })
	}

	async handleCreate() {
		const { params } = this.state
		const body = { ...params }
		const response = await createSubEnterprise(body)
		const { data: { info: new_sub_enterprise } } = response
		const { sub_enterprises } = this.state
		sub_enterprises.push(new_sub_enterprise)
		this.setState({ sub_enterprises, params: {} })
		this.handleOpenDialog()
	}

	async handleChangeLogo(body) {
		const response = await changeSubEnterpriseLogo(body)
		const { data: { info: updated } } = response
		const { sub_enterprises } = this.state
		const new_index = sub_enterprises.findIndex(se => String(se.id) === String(updated.id))
		sub_enterprises[new_index].logo = updated.logo
		this.setState({ sub_enterprises })
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	render() {
		const { classes } = this.props
		const { sub_enterprises, openDialog, params } = this.state
		console.log(sub_enterprises)
		return (
			<div className={classes.container}>
				<div className={classes.header}>
					<Typography variant='h4'>Empresas asociadas</Typography>
					<IconButton size="small" onClick={this.handleOpenDialog}>
						<AddCircleOutline />
					</IconButton>
				</div>
				{sub_enterprises.map(sub => <SubEnterprise onEditLogo={this.handleChangeLogo} sub={sub} onEdit={this.handleEdit} />)}
				<Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
					<div className={classes.dialog}>
						<Typography variant='h4'>Crear SubEmpresa</Typography>
						<TextInput name="name" value={params.name} onChange={this.handleChange} label="Nombre" />
						<TextInput name="address" value={params.address} onChange={this.handleChange} label="Dirección" />
						<TextInput name="rut" value={params.rut} onChange={this.handleChange} label="Rut" />
						<TextInput name="legalName" value={params.legalName} onChange={this.handleChange} label="Razón Social" />
						<Button color="primary" onClick={this.handleCreate} variant="outlined" style={{ marginTop: 6 }}>
							Guardar
						</Button>
					</div>
				</Dialog>
			</div>
		)
	}
}

export default withStyles(style)(SubEnterprises)