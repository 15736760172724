import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { Check, Close, ErrorOutline } from '@material-ui/icons'

const style = (theme) => ({
  container: {
    margin: 24,
    '& > h4': {
      marginBottom: 6
    },
  },
  inline: {
    display: "flex",
    gap: "12px",
    alignItems: "flex-end",
    margin: "3px 0",
  },
  button: {
    marginBottom: 6,
    marginLeft: 6,
    padding: '8px 16px'
  },
  buttons: {
    textAlign: 'end',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
  },
  red: {
    color: theme.palette.red.main,
  },
  green: {
    color: theme.palette.green.main,
  },
  topline: {
    display: 'flex',
    alignItems: 'flex-start'
  }
})
class CritIndex extends Component {

  render() {
    const { classes, show } = this.props
    if (!show) return null
    return (
      <div className={classes.container}>
        <Typography variant='h4'>Simbología</Typography>
        <div className={classes.topline}>
          <div className={classes.inlineContainer}>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#ff6c87" }} />
              <Typography variant="caption">
                Altamente Crítico
              </Typography>
            </div>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#ffab6e" }} />
              <Typography variant="caption">
                Medianamente Crítico
              </Typography>
            </div>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#f6ec79" }} />
              <Typography variant="caption">Poco Crítico</Typography>
            </div>
            <div className={classes.inline}>
              <ErrorOutline style={{ color: "#a3b8ff" }} />
              <Typography variant="caption">No Crítico</Typography>
            </div>
          </div>
          <div >
            <div className={classes.buttons}>
              <Check className={classes.green} />
              <Typography variant='h4'>= SI</Typography>
            </div>
            <div className={classes.buttons}>
              <Close className={classes.red} />
              <Typography variant='h4'>= NO</Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(CritIndex)