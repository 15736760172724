import instance from "../Utils/instance"
import createSnackbar from "../Utils/snackbar"

export const getSettingsListsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: 'settings/lists/get.php'
  })
  return {
    type: 'GET_SETTINGS_LISTS',
    payload: REQUEST
  }
}

export const createSettingsListAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/lists/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_LIST',
    payload: REQUEST
  }
}

export const editSettingsListAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/lists/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_LIST",
    payload: REQUEST
  }
}

export const getListsAction = body => {
  const REQUEST = instance({
    method: 'get',
    url: 'lists/get.php',
    params: body
  })
  return {
    type: "GET_LISTS",
    payload: REQUEST
  }
}

export const editListAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'lists/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_LIST",
    payload: REQUEST
  }
}

export const createListAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'lists/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_LIST",
    payload: REQUEST
  }
}

