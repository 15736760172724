import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import ChecklistContext from '../ChecklistContext'
import SingleElement from './SingleElement'
// import { checkIfCategoryisFull } from '../utils'
import autobind from '../../../Utils/autobind'
import ElementsCategory from './ElementsCategory'

const style = (theme) => ({
  categoryTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 0',
    '& > svg': {
      height: 24,
      width: 24,
      marginRight: 6
    }
  },
  innerTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > svg': {
      height: 24,
      width: 24,
      marginRight: 6
    }
  },
  category: {
    margin: '24px 0'
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  inlineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    background: 'white',
    borderRadius: 8,
    padding: 12,
  },
  defaultMarker: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  blue: {
    color: theme.palette.blue.main,
  },
  endOfCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  }
})

class ChecklistElements extends Component {
  constructor() {
    super()
    autobind(ChecklistElements, this)
  }

  handleAnswer(elements) {
    return async () => {
      const { onAnswer } = this.context
      elements.forEach(async element => {
        const body = {
          checklist_item_id: element.id,
          answer: 3
        }
        await onAnswer(body)
      })
    }
  }

  renderElements() {
    const { classes } = this.props
    const { checklist, current, workers } = this.context
    const { metadata } = checklist
    const items = checklist?.checklist_items || []
    const categories = checklist?.subtitles || []
    console.log(categories)
    const allCategories = categories.sort((a, b) => {
      if (a.subtitle_order > b.subtitle_order) return 1
      if (a.subtitle_order < b.subtitle_order) return -1
      return 0
    }).concat([{ name: "Sin categoría", id: 0, users: [], subtitle_order: -1 }])
    const allCategoryIds = allCategories.map(cat => cat.id)
    const responsable = String(checklist?.user_id)
    const currentUser = current?.id
    const notActualResponsable = responsable !== currentUser

    return allCategories.map((category, catIndex) => {
      const categoryElements = items.filter(element => {
        const isRealCategory = category.id !== 0
        if (isRealCategory) {
          const categoryIncludesElement = element?.subtitle_id === category.id
          return categoryIncludesElement
        }

        const categoryIncludesElement = element?.subtitle_id === category.id
        const elementIsFromNoExistingCategory = !allCategoryIds.includes(element.subtitle_id)
        return categoryIncludesElement || elementIsFromNoExistingCategory
      })
      if (categoryElements.length === 0) return null
      const users = Array.isArray(category?.users) ? category.users : JSON.parse(category.users) || []
      const disabledUsers = metadata?.disabled?.[category.id] || []
      const disabledCategories = metadata?.disable_categories || []
      const responsibleForCategory = !notActualResponsable || (users.includes(current?.id) && !disabledUsers.includes(current.id)) || current.userType === 1
      const responsibleUsers = !users.length ? [] : users?.map(user => workers.find(worker => worker.id === user)).filter(Boolean)
      const disabledCategory = (!responsibleForCategory && notActualResponsable) || disabledCategories.includes(category?.id)
      return (
        <>
          <ElementsCategory
            category={category}
            itsOnly={categories.length === 0}
            disabledCategory={disabledCategory}
            disabledCategories={disabledCategories}
            disabledUsers={disabledUsers}
            categoryElements={categoryElements}
            responsibleUsers={responsibleUsers}
            users={users}
            collapsed={disabledCategory}
            onAnswer={this.handleAnswer}
          />
        </>
      )
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderElements()}
      </div>
    )
  }
}

ChecklistElements.contextType = ChecklistContext

export default withStyles(style)(ChecklistElements)