import React, { Component } from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import { AddCircle, Check } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'

const style = () => ({
	tag: {
		padding: '6px 12px',
		borderRadius: 5,
		margin: '6px 0',
		maxWidth: 450,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	colors: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: 12,
	},
	color: {
		height: 20,
		width: 20,
		margin: 12,
		borderRadius: 4,
		border: '1px solid',
		cursor: 'pointer'
	},
})

const colors = [
	"#202d4c",
	"#8f4799",
	"#ff6c87",
	"#91deb1",
	"#7373e8",
]

class NewTag extends Component {
	constructor() {
		super()
		this.state = {
			name: "",
			color: ""
		}
		autobind(NewTag, this)
	}

	handleChange(event) {
		const { target } = event
		this.setState({ [target.name]: target.value })
	}

	handleSave() {
		const { onCreate } = this.props
		const body = { ...this.state }
		onCreate(body)
		this.setState({ name: "", color: "#FFFFFF" })
	}

	render() {
		const { name, color: tagColor } = this.state
		const { classes } = this.props
		return (
			<div className={classes.container}>
				<div className={classes.tag} style={{ background: tagColor + "70" }}>
					<SeamlessInput placeholder="Nueva Etiqueta..." color={"transparent"} value={name} name="name" onChange={this.handleChange} />
					<div>
						<IconButton size="small" onClick={this.handleSave}
							disabled={!name || !tagColor}
						>
							<AddCircle />
						</IconButton>
					</div>
				</div>
				<div className={classes.colors}>
					{colors.map(color => {
						const isSelected = color === tagColor
						return (
							<div className={classes.color} style={{ background: `${color}70`, borderColor: color }} onClick={() => {
								const target = { name: "color", value: color }
								const event = { target }
								this.handleChange(event)
							}}>
								{isSelected && <Check className={classes.checkIcon} />}
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

export default withStyles(style)(NewTag)