import { Box, Button, Table, TableBody, TableHead } from '@mui/material'
import { useEffect, useState } from 'react'
import { csv_to_excel } from '../API/enterprise'
import Pager from './LazyTable/Pager'
import TableBodyCells from './LazyTable/TableBodyCells'
import TableFilters from './LazyTable/TableFilters'
import TableHeadCells from './LazyTable/TableHeadCells'
import TableSearchBar from './LazyTable/TableSearchBar'
import LoaderAnimator from './LoaderAnimator'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,

	},
	table: {
		maxHeight: 500,
		overflow: 'auto',
		borderRadius: 1
	},
	loader: {
		height: 500,
		position: 'relative'
	},
	searchbar: {
		padding: 2,
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: 2
	},
	barSection: {
		textAlign: 'end',
		flexGrow: 1
	}
}

const controller = new AbortController()
let timeout = null

function LazyTable({
	fetchEndpoint,
	filters,
	info,
	actions,
	exportCallback,
	name = "Tabla",
	extra,
	selectable,
	selected,
	onSelect,
	reload,
	disableSearch,
	disableFilters,
	disableExport
}) {
	const [page, setPage] = useState(0)
	const [limit, setLimit] = useState(25)
	const [total, setTotal] = useState(0)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [temp_search, setSearch] = useState("")
	const [search, setFinalSearch] = useState("")
	const [filter_data, setFilterData] = useState({})
	const [selectedFilters, setFilters] = useState({})

	useEffect(() => {
		async function fetchData() {
			const body = { limit, offset: page * limit, search, filters: selectedFilters, ...extra }
			setLoading(true)
			const response = await fetchEndpoint(body, controller.signal)
			setLoading(false)
			setData(response.data.info)
			setTotal(response.data.total)
			setFilterData(response.data.filter)
		}

		if (!!fetchEndpoint) { fetchData() }
	}, [page, limit, search, fetchEndpoint, selectedFilters, extra, reload])

	useEffect(() => {
		if (!!timeout) clearTimeout(timeout)
		const timer = setTimeout(() => setFinalSearch(temp_search), 600)
		timeout = timer
	}, [temp_search])

	async function onDownload() {
		const filteredInfo = info
		const headers = info.map(element => element.name).map(header => `"${header}"`).join(";")
		const valueHeaders = info.map(element => element.label)
		const body = data.map(entry => valueHeaders
			.map(key => [entry, entry[key]])
			.map((values, index) => {
				if (!values[1]) return "-"
				if (!!filteredInfo[index]?.format) return filteredInfo[index].format(values[1], values[0])
				if (!!filteredInfo[index]?.render) {
					const ToRender = filteredInfo[index]?.render
					if (typeof ToRender === "function") {
						return ToRender({ value: values[1], as_string: true })
					}
				}
				return values[1]
			})
			.join(";"))
			.join('\n')
		const file = `${headers}\n${body}`

		if (!!exportCallback) return exportCallback(file, data, info, name)

		const response = await csv_to_excel({ csv: file, name })
		const url = window.URL.createObjectURL(new Blob([response.data]))
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', `${name}.xlsx`)
		document.body.appendChild(link)
		link.click()
	}

	return (
		<Box>
			<Box sx={css.searchbar}>
				{!disableFilters && <TableFilters filters={filters} data={filter_data} selected={selectedFilters} setFilters={setFilters} setPage={setPage} />}
				<Box sx={css.barSection}>
					{!disableExport && <Button onClick={onDownload} variant="outlined" size="small">Exportar a Excel</Button>}
					{!disableSearch && <TableSearchBar setSearch={setSearch} search={temp_search} />}
				</Box>
			</Box>
			<Box sx={css.container}>
				<Box sx={css.table}>
					<Table stickyHeader>
						<TableHead >
							<TableHeadCells selected={selected} selectable={selectable} info={info || []} actions={actions || []} />
						</TableHead>
						<TableBody>
							{!loading && <TableBodyCells onSelect={onSelect} selected={selected} selectable={selectable} data={data || []} info={info || []} actions={actions || []} />}
						</TableBody>
					</Table>
					{loading && <Box sx={css.loader}><LoaderAnimator /></Box>}
				</Box>
			</Box>
			<Pager limit={limit} page={page} setLimit={setLimit} setPage={setPage} total={total} />
		</Box >
	)
}

export default LazyTable