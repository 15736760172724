import { Button, Divider, Paper, Typography, withStyles } from "@material-ui/core"
import React, { Component } from "react"
import { connect } from "react-redux"
import DateInput from "../../Shared/Inputs/DateInput"
import SelectInput from "../../Shared/Inputs/SelectInput"
import TextInput from "../../Shared/Inputs/TextInput"
import autobind from "../../Utils/autobind"
import { transformToOptions } from "../../Utils/functions"
import moment from 'moment'
import { filterActiveWorkers } from "../../Utils/filters"

const style = () => ({
    container: {
        padding: 12,
        marginTop: 24,
    },
    divider: {
        margin: "6px 0",
    },
    form: {
        display: "flex",
        flexWrap: "wrap",
        gap: "12px",
        "& > *": {
            flexBasis: 250,
            display: "block",
            "@media (max-width:500px)": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                maxWidth: "unset",
                flexBasis: "100%",
            },
        },
    },
    button: {
        textAlign: 'end'
    }
})

class NoCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {
                date_limit: moment(new Date()).format("YYYY-MM-DD")
            },
        }
        autobind(NoCheck, this)
    }

    componentDidMount() {
        const { finding } = this.props
        const newParams = {
            user: finding.user,
            user_id: finding.user_id,
            inmediate_action: finding.inmediate_action,
            date_limit: finding.date_limit !== "0000-00-00" ? finding.date_limit : moment(new Date()).format("YYYY-MM-DD"),
        }
        this.setState({ params: newParams })
    }

    componentDidUpdate(prevProps) {
        const { finding } = this.props
        if (finding.id !== prevProps.finding.id) {
            const newParams = {
                user: finding.user,
                user_id: finding.user_id,
                inmediate_action: finding.inmediate_action,
                date_limit: finding.date_limit !== "0000-00-00" ? finding.date_limit : moment(new Date()).format("YYYY-MM-DD"),
            }
            this.setState({ params: newParams })
        }
    }

    handleChange(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    handleSave() {
        const { params } = this.state
        const { online, finding, workers, assignFinding } = this.props
        const allWorker = workers.all || []
        const user = allWorker.find(worker => worker.id.toString() === params.user_id.toString())
        const body = {
            ...params,
            online: online.status,
            id: finding.id,
            user: user.name
        }
        assignFinding(body)
    }

    render() {
        const { classes, finding, user } = this.props
        const { params } = this.state
        const filteredWorkers = filterActiveWorkers()

        const disabled = user.account.user.userType > 1 && finding.user_id
        return (
            <Paper square className={classes.container}>
                <div className={classes.title}>
                    <Typography variant="subtitle1">
                        Accion Inmediata: {finding.checklist_item_name}
                    </Typography>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.form}>
                    <SelectInput
                        value={params.user_id}
                        name="user_id"
                        onChange={this.handleChange}
                        label="Responsable"
                        options={transformToOptions(filteredWorkers)}
                        disabled={disabled}
                    />
                    <TextInput
                        value={params.inmediate_action}
                        name="inmediate_action"
                        onChange={this.handleChange}
                        label="Acción inmediata"
                        disabled={disabled}
                    />
                    <DateInput
                        value={params.date_limit}
                        name="date_limit"
                        onChange={this.handleChange}
                        label="Fecha límite de resolución"
                        disabled={disabled}
                    />
                </div>
                <div className={classes.button} onClick={this.handleSave}>
                    <Button color="primary" variant="outlined" disabled={disabled}>Asignar</Button>
                </div>
            </Paper>
        )
    }
}

const mapStateToProps = state => ({
    workers: state.workers,
    online: state.online,
    user: state.user,
    branch: state.branch
})

export default connect(mapStateToProps)(withStyles(style)(NoCheck))
