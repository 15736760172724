import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import React from 'react'
import { getFindings } from '../API/findings&nc'

const style = () => ({

})

class FindingsTable extends React.Component {
  constructor() {
    super()
    this.state = {
      findings: []
    }
  }

  async componentDidMount() {
    const response = await getFindings()
    const { data: { info: findings } } = response
    this.setState({ findings })
  }

  renderFinishedSubquestions(field, values, columns, object) {
    if (!field.options || field.options.length === 0) return null
    const selectedOption = field.options.find(option => option.label === field.value)
    if (!selectedOption) return null
    const { subquestions = [] } = selectedOption
    if (subquestions.length === 0) return null
    return subquestions.forEach(question => {
      values.push(question.value)
      columns.push(question.label)
      object[question.label] = question.value

      this.renderFinishedSubquestions(question, values, columns, object)
    })
  }

  render() {
    const { classes } = this.props
    const { findings = [] } = this.state
    console.log(findings)
    const values = []
    const columns = []
    let globalColumns = []
    const objects = []

    findings.forEach(finding => {
      const f_columns = Object.keys(finding)
      const f_object = { ...finding }
      const f_values = Object.values(finding).map(val => typeof val === "object" ? "Objeto" : val)
      console.log(finding)
      const fields = finding.checklist_extra_fields || []
      fields.forEach(field => {
        f_values.push(field.value)
        f_columns.push(field.label)
        f_object[field.label] = field.value
        this.renderFinishedSubquestions(field, f_values, f_columns, f_object)
      })
      values.push(f_values)
      columns.push(f_columns)
      objects.push(f_object)
      if (f_columns.length > globalColumns.length) {
        globalColumns = [...f_columns]
      }
    })

    console.log(values)
    console.log(columns)
    console.log(objects)
    const fixedObjects = objects.map(object => {
      Object.keys(object).forEach(key => {
        if (typeof object[key] === "object") {
          object[key] = "Objeto"
        }
      })
      return object
    })

    return (
      <div className={classes.container}>
        <Table>
          <TableHead>
            <TableRow>
              {globalColumns.map(header => {
                return (<TableCell>
                  {header}
                </TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {fixedObjects.map(finding => {
              const row = globalColumns.map(header => {
                return (
                  <TableCell>
                    {finding[header]}
                  </TableCell>
                )
              })
              return (
                <TableRow>
                  {row}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(style)(FindingsTable)