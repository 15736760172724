import { Edit } from '@material-ui/icons'
import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../AppContext'
import useToggle from '../../Hooks/ToogleHook'
import DateInput from '../../Shared/Inputs/DateInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { filterActiveWorkers } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		padding: 3,
		position: 'relative',
		marginBottom: 1
	},
	button: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	form: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2
	},
	input: {
		width: 200,
		'@media (max-width:500px)': {
			width: '100%'
		}
	}
}

function MainInfoCard() {

	const { checklist } = useContext(ChecklistContext)
	const [edit, toggleEdit] = useToggle(false)
	const [params, setParams] = useState({ ...checklist })
	const workers = filterActiveWorkers(false)

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	useEffect(() => {
		setParams({ ...checklist })
	}, [checklist])

	return (
		<Paper sx={css.container}>
			<Typography variant='h1' style={{ marginBottom: 24 }} >Información del registro</Typography>
			<IconButton sx={css.button} onClick={toggleEdit}>
				<Edit />
			</IconButton>
			<Box sx={css.form}>
				<Box sx={css.input}>
					{edit ?
						<SelectInput onChange={onChange} options={transformToOptions(workers)} label="Responsable" name="user_id" value={params.user_id} />
						:
						<Box>
							<Typography variant='subtitle1'><strong>Responsable</strong></Typography>
							<Typography variant='subtitle1'>{params.user_id}</Typography>
						</Box>
					}
				</Box>
				<Box sx={css.input}>
					{edit ?
						<DateInput onChange={onChange} label="Fecha de programación" name="date_scheduled" value={params.date_scheduled} />
						:
						<Box>
							<Typography variant='subtitle1'><strong>Fecha de programación</strong></Typography>
							<Typography variant='subtitle1'>{params.date_scheduled}</Typography>
						</Box>
					}
				</Box>
			</Box>
			{edit &&
				<Box style={{ textAlign: 'end' }}>
					<Button color="primary" variant="outlined" size="small">
						Guardar
					</Button>
				</Box>
			}
		</Paper>
	)
}

export default MainInfoCard