import React, { Component } from 'react'
import { Checkbox, Collapse, IconButton, Typography, withStyles } from '@material-ui/core'
import { permissionList } from './Info'
import { KeyboardArrowDown } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import LoadingDialog from '../../Shared/LoadingDialog'

const style = () => ({
  section: {
    margin: '24px 0'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 12
    }
  },
  container: {
    padding: 12,
    background: 'white',
    borderRadius: 15,
    marginBottom: 24
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  open: {
    transition: 'all 0.2s ease-in-out',
    transform: 'rotate(180deg)'
  },
  closed: {
    transition: 'all 0.2s ease-in-out',
    transform: 'rotate(0deg)'
  }
})

class Permissions extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      loading: false,
      openSection: ""
    }
    autobind(Permissions, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleOpenSection(label) {
    return () => {
      const { openSection } = this.state
      if (openSection === label) return this.setState({ openSection: "" })
      this.setState({ openSection: label })
    }
  }

  handleTogglePermission(label) {
    return () => {
      const { user, changePermissions } = this.props
      const { permissions = "" } = user
      const restrictions = permissions?.split(",") || []
      this.setState({ loading: true })
      if (!restrictions.includes(label)) {
        restrictions.push(label)
        const newPermissions = restrictions.join(",")
        const body = {
          id: user.id,
          permissions: newPermissions
        }
        return changePermissions(body).then(() => this.setState({ loading: false }))
      }
      const index = restrictions.findIndex(r => r === label)
      restrictions.splice(index, 1)
      const newPermissions = restrictions.join(",")
      const body = {
        id: user.id,
        permissions: newPermissions
      }
      return changePermissions(body).then(() => this.setState({ loading: false }))
    }
  }

  renderList() {
    const { openSection } = this.state
    const { classes, user } = this.props
    const { permissions } = user
    const userPermissions = permissions || ""
    const restrictions = userPermissions.split(",")
    return permissionList.map(sectionObject => {
      return (
        <div className={classes.section}>
          <Typography variant="h4">{sectionObject?.section}</Typography>
          {sectionObject?.actions?.map(action => {
            return (
              <div className={classes.action}>
                <Checkbox checked={!restrictions.includes(action.label)} onClick={this.handleTogglePermission(action.label)} />
                <Typography variant="subtitle1">{action.name}</Typography>
              </div>
            )
          })}
          {sectionObject?.subsections?.map(subsection => {
            return (
              <>
                <div className={classes.action}>
                  <Checkbox checked={!restrictions.includes(subsection.label)} onClick={this.handleTogglePermission(subsection.label)} />
                  <Typography variant="h4">{subsection?.section}</Typography>
                  {subsection?.actions?.length > 0 &&
                    <IconButton size="small" onClick={this.handleOpenSection(subsection.label)}>
                      <KeyboardArrowDown />
                    </IconButton>
                  }
                </div>
                <Collapse in={openSection === subsection.label}>
                  <div>
                    {
                      subsection?.actions?.map(action => {
                        return (
                          <div className={classes.action} style={{ marginLeft: 24 }}>
                            <Checkbox checked={!restrictions.includes(action.label)} onClick={this.handleTogglePermission(action.label)} />
                            <Typography variant="subtitle1">{action.name}</Typography>
                          </div>
                        )
                      })
                    }
                  </div>
                </Collapse>
              </>
            )
          })}
        </div>
      )
    })
  }

  render() {
    const { open, loading } = this.state
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <div className={classes.title} onClick={this.handleOpen}>
          <Typography variant="h4">Permisos del usuario</Typography>
          <KeyboardArrowDown className={open ? classes.open : classes.closed} />
        </div>
        <Collapse in={open}>
          {this.renderList()}
        </Collapse>
      </div>
    )
  }
}

Permissions.defaultProps = {
  user: {}
}

export default withStyles(style)(Permissions)