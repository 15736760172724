import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"
import createSnackbar from "../Utils/snackbar"

export const getSettingsWorkflowsAction = () => {
  const REQUEST = instance2({
    method: 'get',
    url: 'settings/workflows/get.php'
  })
  return {
    type: "GET_SETTINGS_WORKFLOWS",
    payload: REQUEST
  }
}

export const createSettingsWorkflowAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/workflows/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'CREATE_SETTINGS_WORKFLOW',
    payload: REQUEST
  }
}

export const editSettingsWorkflowAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/workflows/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_SETTINGS_WORKFLOW",
    payload: REQUEST
  }
}

export const createWorkflowStageAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/workflows/create_step.php',
    data: body,
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_WORKFLOW_STATE",
    payload: REQUEST
  }
}

export const editWorkflowStageAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'settings/workflows/edit_step.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_WORKFLOW_STATE',
    payload: REQUEST
  }
}

export const getWorkflowsAction = body => {
  const REQUEST = instance2({
    method: 'get',
    url: 'workflows/get.php',
  })
  return {
    type: "GET_WORKFLOWS",
    payload: REQUEST
  }
}

export const createWorkflowAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'workflows/create.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "CREATE_WORKFLOW",
    payload: REQUEST
  }
}

export const editWorkflowAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'workflows/edit.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: 'EDIT_WORKFLOW',
    payload: REQUEST
  }
}

export const editStepAction = body => {
  const REQUEST = instance({
    method: 'post',
    url: 'workflows/edit_step.php',
    data: body
  })
  createSnackbar(REQUEST)
  return {
    type: "EDIT_STEP",
    payload: REQUEST
  }
}