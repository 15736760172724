import { Button } from '@material-ui/core'
import { Box, Collapse } from '@mui/material'
import { useState } from 'react'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { filterActiveWorkers } from '../../../Utils/filters'
import { transformToOptions } from '../../../Utils/functions'

const css = {
	button: {
		textAlign: 'end'
	},
	container: {
		margin: '12px 0'
	}
}

const recordTypes = [
	"Registro o Formulario",
	"Charla",
	"Capacitación",
	"Evaluación",
]

const frequencies = [
	{ value: "0", label: "Solo una vez" },
	{ value: "1", label: "Diario" },
	{ value: "7", label: "Semanal" },
	{ value: "15", label: "Quincenal" },
	{ value: "30", label: "Mensual" },
	{ value: "60", label: "Bimensual" },
	{ value: "90", label: "Trimestral" },
	{ value: "180", label: "Semestral" },
	{ value: "365", label: "Anual" },
]

function CreateConstrain({ value, open, data, onCreate, options: filterOptions = [[], [], []], disableUsers }) {

	const [params, setParams] = useState({})

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	function onSubmit() {
		const body = { ...params }
		onCreate(body)
		setParams({})
	}

	const options = recordTypes.map((rt, index) => ({ value: String(index), label: rt }))
	const filteredData = params.record_type !== null ? data[params.record_type] : []
	const workers = filterActiveWorkers()
	const [sub_enterprises, branches, positions] = filterOptions

	return (
		<Collapse in={open}>
			<Box sx={css.container}>
				<SelectInput value={params.record_type} name="record_type" options={options} label="Seleccionar tipo de actividad" onChange={onChange} />
				<SelectInput value={params.record_id} name="record_id" onChange={onChange} options={transformToOptions(filteredData)} label="Seleccionar registro" />
				<SelectInput value={params.frequency} name="frequency" onChange={onChange} options={frequencies} label="Seleccionar frecuencía de cumplimiento" />
				{!disableUsers && <SelectInput value={params.position_id} name="position_id" onChange={onChange} options={transformToOptions(positions)} label="Seleccionar cargo asociado" />}
				{!disableUsers && <SelectInput value={params.branch_id} name="branch_id" onChange={onChange} options={transformToOptions(branches)} label="Seleccionar centro de trabajo" />}
				{!disableUsers && <SelectInput value={params.sub_enterprise_id} name="sub_enterprise_id" onChange={onChange} options={transformToOptions(sub_enterprises)} label="Seleccionar empresa asociada" />}
				{!disableUsers && <MultiSelectInput value={params.users_id} name="users_id" onChange={onChange} options={transformToOptions(workers)} label="Seleccionar usuarios directos" />}
				<Box sx={css.button}>
					<Button color="primary" variant="contained" size="small" onClick={onSubmit} disabled={
						!params.record_id || !params.record_id || !params.frequency
					}>
						Agregar definición
					</Button>
				</Box>
			</Box>
		</Collapse>
	)
}

export default CreateConstrain