import React, { Component } from 'react'
import { Button, Dialog, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../Shared/Inputs/SelectInput'
import autobind, { addChange } from '../../Utils/autobind'
import { getSettingsChecklist } from '../../API/checklists'
import { getSettingsTalks } from '../../API/talks'
import { getSettingsTrainings } from '../../API/trainings'
import { getSettingsWorkflows } from '../../API/workflows'
import { getSettingsActivities } from '../../API/activities'
import { transformToOptions } from '../../Utils/functions'
import { recordTypes } from './info'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'
import { getSettingsBranchDocuments } from '../../API/branches'

const style = () => ({
  container: {
    padding: 24
  }
})

const initialValues = {
  data: "",
  compliance: 0,
  frequency: 0
}

const frequencies = [
  { label: "Sin frecuencia", value: 0 },
  { label: "Semanal", value: 7 },
  { label: "Mensual", value: 30 },
  { label: "Trimestral", value: 90 },
  { label: "Semestral", value: 180 },
  { label: "Anual", value: 365 }
]

class NewRecordItem extends Component {
  constructor() {
    super()
    this.state = {
      params: { ...initialValues },
      checklists: [],
      activities: [],
      talks: [],
      trainings: [],
      workflows: [],
      documents: [],
      loading: true
    }
    addChange(NewRecordItem, this)
    autobind(NewRecordItem, this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const responses = await Promise.all([
      getSettingsChecklist(),
      getSettingsTalks(),
      getSettingsTrainings(),
      getSettingsWorkflows(),
      getSettingsActivities(),
      getSettingsBranchDocuments()
    ])
    this.setState({ loading: false })
    const [checklists, talks, trainings, workflows, activities, documents] = responses.map(response => response.data.info)
    this.setState({ checklists, talks, trainings, workflows, activities, documents })
  }

  componentDidUpdate(prevProps) {
    const { edit } = this.props
    const { params } = this.state
    const { edit: prevEdit } = prevProps
    if (!!edit && (JSON.stringify(edit) !== JSON.stringify(prevEdit))) {
      const newparams = { ...params, ...edit }
      this.setState({ params: newparams })
    }
  }

  handleSend() {
    const { onSubmit, onClose } = this.props
    const { params } = this.state
    const body = { ...params }
    this.setState({ params: initialValues })
    onSubmit(body)
    onClose()
  }

  render() {
    const { classes, open, onClose, edit } = this.props
    const { params, checklists, talks, trainings, workflows, activities, loading, documents } = this.state
    const menuOptions = [activities, checklists, trainings, talks, workflows, documents.map(d => d.subcategories).flat()]
    console.log(menuOptions)
    const selectedOptions = params.record_type_id ? menuOptions[params.record_type_id - 1] : []
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h4">Nuevo registro o formulario</Typography>
          <SelectInput
            value={params.record_type_id}
            onChange={this.handleChange}
            options={recordTypes}
            name="record_type_id"
            label="Tipo de registro o formulario"
          />
          {loading ?
            <MiniLoaderAnimator />
            :
            <SelectInput
              value={params.record_id}
              onChange={this.handleChange}
              options={transformToOptions(selectedOptions)}
              name="record_id"
              label="Seleccionar registro o formulario"
            />
          }
          <SelectInput
            value={params.frequency}
            onChange={this.handleChange}
            options={frequencies}
            name="frequency"
            label="Seleccionar frecuencia"
          />
          <Button color="primary" variant="contained" onClick={this.handleSend}>
            {edit ? "Editar registro o formulario" : "Agregar registro o formulario"}
          </Button>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(NewRecordItem)