import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import { getSettingsNonConformities } from '../../../API/settings'
import CreateConfiguration from '../Findings/CreateConfiguration'
import FindingInfo from '../Findings/FindingInfo'
import LoaderAnimator from '../../../Shared/LoaderAnimator'

const style = () => ({
  container: {
    margin: 24
  },
})

class NonConformitiesSettings extends Component {
  constructor() {
    super()
    this.state = {
      finding: null,
    }
    autobind(NonConformitiesSettings, this)
  }

  async componentDidMount() {
    const response = await getSettingsNonConformities()
    this.setState({ finding: response?.data?.info })
  }

  handleReload() {
    this.componentDidMount()
  }


  render() {
    const { classes } = this.props
    const { finding } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h1">Configuración No Conformidades</Typography>
        {!finding ?
          < LoaderAnimator loading />
          :
          <>
            {finding?.length === 0 ?
              <CreateConfiguration nonconf reload={this.handleReload} />
              :
              <FindingInfo nonconf finding={finding?.[0]} reload={this.handleReload} />
            }
          </>
        }
      </div>
    )
  }
}

export default withStyles(style)(NonConformitiesSettings)