import React, { Component } from 'react'
import { IconButton, Typography, withStyles, Paper } from '@material-ui/core'
import { Edit, Save } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import MyUploadAdapter from '../Talks/MyUploader'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

const style = () => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  htmlContent: {
    padding: 12,
    margin: '12px 0'
  }
})

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

class ContentEditor extends Component {
  constructor() {
    super()
    this.state = {
      openEdit: false,
      content: ""
    }
    autobind(ContentEditor, this)
  }

  componentDidMount() {
    const { content } = this.props
    this.setState({ content })
  }

  handleChange(event, editor) {
    const data = editor.getData()
    this.setState({ content: data })
  }

  handleOpenEdit() {
    const { openEdit } = this.state
    if (!openEdit) {
      const element = document.getElementById('html-content')
      element.innerHTML = null
    }
    this.setState({ openEdit: !openEdit })
  }

  handleSave() {
    const { onSave } = this.props
    const { content } = this.state
    onSave(content)
    this.setState({ openEdit: false })
  }

  renderContent() {
    const element = document.getElementById('html-content')
    const { content } = this.props
    if (!element) return null
    if (!content) return null
    element.innerHTML = content
  }

  renderEditor() {
    const { content } = this.state
    return (
      <CKEditor
        editor={Editor}
        data={content}
        onChange={this.handleChange}
        config={{
          // fontSize: {
          //   supportAllValues: true
          // },
          toolbar: ['bold', 'italic', '|',
            "Undo",
            "Redo",
            "imageUpload",
            "insertTable",
            "link",
            '|',
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
            '|',
            'fontColor',
            'fontBackgroundColor',
            'fontSize'
          ],
          language: 'es',
          extraPlugins: [MyCustomUploadAdapterPlugin]
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log("Editor is ready to use!", editor);
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "min-height",
              "200px",
              editor.editing.view.document.getRoot()
            )
          })
        }}
      />
    )
  }

  render() {
    const { classes, editable } = this.props
    const { openEdit } = this.state
    return (
      <div className={classes.container}>
        <Paper className={classes.htmlContent}>
          <div className={classes.title}>
            <Typography variant="h4">Contenido</Typography>
            {editable &&
              <>
                {openEdit ?
                  <IconButton onClick={this.handleSave}>
                    <Save />
                  </IconButton>
                  :
                  <IconButton onClick={this.handleOpenEdit}>
                    <Edit />
                  </IconButton>
                }
              </>
            }
          </div>
          {openEdit ?
            <div className={classes.editor}>
              {this.renderEditor()}
            </div>
            :
            <div id="html-content" className="ck-content">
              {this.renderContent()}
            </div>
          }
        </Paper>
      </div>
    )
  }
}

export default withStyles(style)(ContentEditor)