import React, { Component } from 'react'
import { Button, Collapse, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../../Utils/functions'
import autobind, { addToggle } from '../../../Utils/autobind'
import { AddCircleOutline, Check, HighlightOffOutlined } from '@material-ui/icons'
import TextInput from '../../../Shared/Inputs/TextInput'

const style = (theme) => ({
  container: {
    padding: 24
  },
  tag: {
    padding: 12,
    borderRadius: 8,
    margin: '8px 0',
    '& > *': {
      fontWeight: 600
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  colors: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -11
  },
  color: {
    height: 20,
    width: 20,
    margin: 12,
    borderRadius: 4,
    border: '1px solid',
    cursor: 'pointer'
  },
})

const colors = [
  "#202d4c",
  "#8f4799",
  "#ff6c87",
  "#91deb1",
  "#7373e8",
]

class TagsDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    addToggle(TagsDialog, this, "create")
    autobind(TagsDialog, this)
  }

  handleChange(event) {
    const { onSelect, onClose } = this.props
    const { target } = event
    console.log(target.value)
    onSelect(target.value)
    onClose()
  }

  handleChangeCreate(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { params } = this.state
    const { onCreate, onClose } = this.props
    const body = { ...params }
    onCreate(body)
    this.setState({ params: {}, openCreate: false })
    onClose()
  }

  handleRemove(id) {
    return () => {
      const { onRemove, onClose } = this.props
      onRemove(id)()
      onClose()
    }
  }

  render() {
    const { classes, open, onClose, element, tags } = this.props
    const { openCreate, params } = this.state
    const ownTagsIds = element?.tags || []
    const ownTags = ownTagsIds.map(id => tags.find(t => t.id === id))

    return (
      <Dialog open={open} onClose={onClose} element={element} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant='h2'>Etiquetas de registro</Typography>
          <div className={classes.inline}>
            <SelectInput
              options={transformToOptions(tags)}
              label="Seleccionar etiqueta"
              onChange={this.handleChange}
            />
            <IconButton onClick={this.handleOpenCreate}>
              <AddCircleOutline />
            </IconButton>
          </div>
          <Collapse in={openCreate}>
            <TextInput label="Nombre nueva etiqueta" name="name" value={params.name} onChange={this.handleChangeCreate} />
            <div className={classes.inline}>
              <div className={classes.colors}>
                {colors.map(color => {
                  const isSelected = color === params.color
                  return (
                    <div className={classes.color} style={{ background: `${color}70`, borderColor: color }} onClick={() => {
                      const target = { name: "color", value: color }
                      const event = { target }
                      this.handleChangeCreate(event)
                    }}>
                      {isSelected && <Check className={classes.checkIcon} />}
                    </div>
                  )
                })}
              </div>
              <Button color="secondary" variant="contained" onClick={this.handleCreate}>
                Crear etiqueta
              </Button>
            </div>
          </Collapse>
          {ownTags.map(tag => {
            if (!tag) return null
            return (
              <div className={classes.tag} style={{ background: tag.color + "60" }}>
                <Typography variant='subtitle1'>{tag.name}</Typography>
                <IconButton onClick={this.handleRemove(tag.id)} size="small">
                  <HighlightOffOutlined />
                </IconButton>
              </div>
            )
          })}
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(TagsDialog)