import React, { Component } from 'react'
import { Checkbox, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import SubmitButton from '../../../../Shared/SubmitButton'
import autobind from '../../../../Utils/autobind'
import FieldOption from './FieldOption'

const style = (theme) => ({
  container: {
    '& > h2': {
      marginBottom: 12
    }
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center'
  },
  optionsCreated: {
    background: theme.palette.purple.light,
    borderRadius: 7,
    display: 'grid'
  }
})

const fields = [
  { label: "Texto", value: "text" },
  { label: "RUT", value: "rut" },
  { label: "Texto enriquecido (Word)", value: "rte" },
  { label: "Lista con opciones", value: "select" },
  { label: "Selección Múltiple", value: "multiselect" },
  { label: "Imagen", value: "img" },
  { label: "Cumplimiento", value: "compliance" },
  { label: "IDS (Experimental)", value: "ids" }
]

class AddExtraField extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(AddExtraField, this)
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeRequired() {
    const { params } = this.state
    params.required = !params.required
    this.setState({ params })
  }

  handleDeleteOption(index) {
    return () => {
      const { params } = this.state
      const newOptions = [...params.options]
      newOptions.splice(index, 1)
      params.options = newOptions
      this.setState({ params })
    }
  }

  renderCreatedOptions() {
    const { params } = this.state
    const options = params.options || []
    return options.map((option, index) => {
      return <FieldOption option={option} key={`${option.option}${index}`} />
    })
  }

  handleAddOption() {
    const { params } = this.state
    const options = params.options ? [...params.options] : []
    const newOption = {
      label: params.option,
      message: params.option_message,
      color: params.option_color,
      subquestions: []
    }
    options.push(newOption)
    const newExtra = { ...params }
    newExtra.options = options
    newExtra.option = ""
    this.setState({ params: newExtra })
  }

  handleCreate() {
    const { onCreate } = this.props
    const { params } = this.state
    const body = { ...params }
    this.setState({ params: {} })
    onCreate(body)
  }

  render() {
    const { classes, final = true } = this.props
    const { params } = this.state
    const isSelectType = (params.type === "select" || params.type === "multiselect")
    const isCompliance = params.type === "compliance"
    return (
      <div className={classes.container}>
        <Typography variant="h4" style={{ margin: '0 0 12px' }}>{final ? "Agregar campo extra registro o formulario" : "Aregar pregunta"}</Typography>
        <div className={classes.addExtra}>
          <div className={classes.extraField}>
            <TextInput label="Nombre Campo" onChange={this.handleChange} name="label" value={params.label} />
          </div>
          <div className={classes.extraField}>
            <SelectInput label="Tipo" options={fields} onChange={this.handleChange} name="type" value={params.type} />
          </div>
        </div>
        <div className={classes.checkbox}>
          <Checkbox checked={!!params.required} onChange={this.handleChangeRequired} name="required" />
          <Typography variant="subtitle1"> Campo Obligatorio</Typography>
        </div>
        {isCompliance &&
          <div className={classes.extraField}>
            <TextInput label="Titulo caso desfavorable" onChange={this.handleChange} name="compliance_case" value={params.compliance_case} />
            <TextInput label="Titulo comentario incumplimiento" onChange={this.handleChange} name="compliance_observation" value={params.compliance_observation} />
          </div>
        }
        {isSelectType &&
          <>
            <div className={classes.optionsCreator}>
              <div className={classes.extraField}>
                <TextInput label="Agregar Opción" onChange={this.handleChange} name="option" value={params.option} />
              </div>
              {params.type === "select" && <div className={classes.extraField}>
                <TextInput label="Mensaje (Opcional)" onChange={this.handleChange} name="option_message" value={params.option_message} />
              </div>}
              {params.type === "select" && <div className={classes.extraField}>
                <SelectInput options={[
                  { label: "Rojo", value: "red" },
                  { label: "Verde", value: "green" },
                  { label: "Amarillo", value: "gold" },
                  { label: "Sin Color", value: "unset" },
                ]}
                  label="Color" onChange={this.handleChange} name="option_color" value={params.option_color} />
              </div>}
              <SubmitButton color="primary" onClick={this.handleAddOption} disabled={!params.option}>
                Agregar Opción
              </SubmitButton>
            </div>

            <Typography variant="subtitle1" style={{ marginBottom: 12 }}>Opciones del campo:</Typography>
            <div className={classes.optionsCreated}>
              {this.renderCreatedOptions()}
            </div>
          </>
        }
        <div style={{ marginTop: 12 }}>
          {final ?
            <SubmitButton color="primary" onClick={this.handleCreate} disabled={!params.label || !params.type}>
              Crear Campo Extra
            </SubmitButton>
            :
            <SubmitButton color="primary" onClick={this.handleCreate} disabled={!params.label || !params.type}>
              Agregar Pregunta
            </SubmitButton>
          }
        </div>
      </div>
    )
  }
}

export default withStyles(style)(AddExtraField)