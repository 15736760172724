import { Block, RestoreFromTrashOutlined } from '@material-ui/icons'
import { Badge, Box, Button, IconButton, Paper, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { getTableUsers, toggleActiveUser } from '../../API/users'
import useToggle from '../../Hooks/ToogleHook'
import LazyTable from '../../Shared/LazyTable'
import { userTableInfo } from './Info'

const css = {
	switch: {
		display: 'flex',
		alignItems: 'center'
	},
	toggleButton: {
		margin: '12px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

function WorkersLazyTable({ filters, actions }) {

	const [active, toggleActive] = useToggle(true)
	const [reload, activateReload] = useToggle(false)
	const [selected, setSelected] = useState([])
	const extra = useMemo(() => ({ active }), [active])
	const new_actions = [...actions]
	new_actions.push({ name: `${active ? "Deshabilitar" : "Habilitar"} Colaborador`, icon: active ? Block : RestoreFromTrashOutlined, action: onClickBlock })

	useEffect(() => {
		setSelected([])
	}, [active])

	function onSelect(value) {
		const new_selected = [...selected]
		if (new_selected.includes(value)) {
			new_selected.splice(new_selected.findIndex(v => v === value), 1)
		} else {
			new_selected.push(value)
		}
		setSelected(new_selected)
	}

	function onClickBlock(element) {
		return async () => {
			await toggleActiveUser({ ids: [element.id] })
			activateReload()
		}
	}

	async function toggleActiveMultiple() {
		await toggleActiveUser({ ids: selected })
		setSelected([])
		activateReload()
	}

	return (
		<Box>
			<Box sx={css.header}>
				<Box sx={css.switch}>
					<Switch checked={active} onClick={toggleActive} style={{ marginRight: 6 }} />
					<Typography variant='subtitle1'>Colaboradores {active ? "Activos" : "Inactivos"}</Typography>
				</Box>
				<Box sx={css.toggleButton}>
					<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }} ><strong>Deshabilitar colaboradores seleccionados</strong></Typography>}>
						<Badge color={active ? "warning" : "success"} badgeContent={selected.length}>
							<Button
								onClick={toggleActiveMultiple}
								disabled={!selected.length}
								variant="outlined"
								color={active ? "warning" : "success"}
								endIcon={active ? <Block /> : <RestoreFromTrashOutlined />}
							>
								{`${active ? "Deshabilitar" : "Habilitar"} usuarios seleccionados`}
							</Button>
						</Badge>
					</Tooltip>
				</Box>
			</Box>
			<LazyTable
				onSelect={onSelect}
				selected={selected}
				selectable
				reload={reload}
				name="Colaboradores"
				extra={extra}
				fetchEndpoint={getTableUsers}
				info={userTableInfo}
				filters={filters}
				actions={new_actions}
			/>
		</Box>
	)
}

export default WorkersLazyTable