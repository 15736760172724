import React, { Component } from "react"
import {
    Paper,
    withStyles,
    Typography,
    Button,
    Collapse,
} from "@material-ui/core"
import FieldRenrerer from "./Inputs/FieldRenrerer"
import FormContext from "./Cards/Provider"

const style = (theme) => ({
    inputContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: 12,
    },
    button: {
        textAlign: "end",
        margin: "6px 0",
    },
    input: {
        margin: "12px 0",
    },
    info: {
        width: "100%",
        maxWidth: 200,
        display: "block",
        marginRight: 24,
        "@media (max-width:500px)": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "unset",
            marginRight: 0,
        },
    },
    title: {
        padding: 24,
        color: theme.palette.primary.main,
    },
    formContainer: {
        transition: "all 0.2s linear",
    },
    bottom: {
        padding: 24
    },
    container: {
        marginBottom: 24
    }
})

class GeneralForm extends Component {
    renderInputs() {
        const { handleChange, params } = this.context
        const { info, classes } = this.props
        return info.map((input, index) => {
            return (
                <div key={index} className={classes.info}>
                    <FieldRenrerer
                        input={input}
                        onChange={handleChange}
                        value={params[input.name]}
                    />
                </div>
            )
        })
    }
    render() {
        const { open, onSubmit, submit, title, classes } = this.props
        return (
            <div className={classes.formContainer}>
                <Collapse in={open}>
                    <Paper className={classes.container}>
                        <div className={classes.title}>
                            <Typography variant="h1">
                                {title}
                            </Typography>
                            <Typography variant='caption'>Los campos con * son obligatorios, el resto son opcionales</Typography>
                        </div>
                        <div className={classes.bottom}>
                            <div className={classes.inputContainer}>
                                {this.renderInputs()}
                            </div>
                            <div className={classes.button}>
                                <Button onClick={onSubmit} variant="outlined" color="primary" >{submit}</Button>
                            </div>
                        </div>
                    </Paper>
                </Collapse>
            </div>
        )
    }
}

GeneralForm.contextType = FormContext

GeneralForm.propTypes = {}

GeneralForm.defaultProps = {
    submit: "Guardar",
    info: [],
    title: "Formulario",
    open: false,
}

export default withStyles(style)(GeneralForm)
