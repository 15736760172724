import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import SubmitButton from './SubmitButton'
import { withRouter } from 'react-router'
import autobind from '../Utils/autobind'
import store from "../store"
import { sendMail } from '../API/workflows'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
)

const style = () => ({
  container: {
    background: 'white',
    padding: 12,
    margin: 12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& > *': {
      margin: '6px 0'
    }
  }
})

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    autobind(ErrorBoundary, this)
  }

  async componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
    const userName = store.getState().user?.account.user?.name
    const userId = store.getState().user?.account.user?.id
    const body = {
      subject: `Error encontrado por: ${userName} (${userId}) - ${window.location.pathname}`,
      content: `${error?.stack} ${info.componentStack}`,
      emails: ['mati.eynaudi@gmail.com'],
    }
    if (isLocalhost) return console.log(body)
    sendMail(body)
  }

  handleBack() {
    localStorage.clear()
    const { history } = this.props
    history.push('/')
  }

  render() {
    const { classes } = this.props
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={classes.container}>
          <Typography variant="h1">¡Lo sentimos!</Typography>
          <Typography variant="subtitle1">Algo salió mal... enviamos un reporte de este problema a administración para que sea revisado</Typography>
          <Typography variant="subtitle2">Pulsa "volver" para continuar</Typography>
          <SubmitButton onClick={this.handleBack}>Volver</SubmitButton>
        </div>
      )
    }
    return this.props.children
  }
}

export default withRouter(withStyles(style)(ErrorBoundary))