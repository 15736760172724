import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import ProgramContext from '../ProgramContext'
import { AssignmentTurnedInOutlined, KeyboardArrowRight } from '@material-ui/icons'
import SignedCell from '../../../Shared/TableRenders/SignedCell'
import moment from 'moment'
import GeneralTable from '../../../Shared/GeneralTable'
import { pendingTableInfo } from '../../Checklists/Info'
import { activityTableInfo } from '../../Activities/Info'
import autobind from '../../../Utils/autobind'
import { withRouter } from 'react-router-dom'

const style = () => ({
  container: {
    background: '#f2f3f8',
    padding: '12px 12px 24px',
  }
})

function formatWorkflowDate(date) {
  if (!date) return "No registrado"
  return moment(date).format("DD-MM-YYYY HH:mm")
}

const tableInfo = [
  { name: "Fecha Programación", label: "date_scheduled", format: (value) => moment(value).format("YYYY-MM-DD") },
  { name: "Tipo", label: "category_name" },
  { name: "Tema", label: "name" },
  { name: "Responsable", label: "user_name" },
  { name: AssignmentTurnedInOutlined, label: "all", render: SignedCell },
]

const checklistInfo = pendingTableInfo
const talksInfo = tableInfo
const trainingsInfo = tableInfo
const workflowInfo = [
  { name: "Nombre", label: "name" },
  { name: "Creado por", label: "user" },
  { name: "Fecha de creación", label: "date_created", format: formatWorkflowDate },
  { name: "Fecha de término", label: "date_finished", format: formatWorkflowDate },
]
const activityInfo = activityTableInfo
const documentInfo = workflowInfo

const infos = [
  activityInfo,
  checklistInfo,
  trainingsInfo,
  talksInfo,
  workflowInfo,
  documentInfo,
]



class InnerRecordCard extends Component {
  constructor() {
    super()
    autobind(InnerRecordCard, this)
  }

  handleGoto(row) {
    return () => {
      const { element } = this.props
      const actionsArray = [
        (row) => {
          const { history } = this.props
          history.push(`/activities/${row.id}`)
        },
        (row) => {
          const { history } = this.props
          history.push(`/checklists/${row.id}`)
        },
        (row) => {
          const { history } = this.props
          history.push(`/trainings/${row.id}`)
        },
        (row) => {
          const { history } = this.props
          history.push(`/talks/${row.id}`)
        },
        (row) => {
          const { history } = this.props
          history.push(`/workflows/${row.id}`)
        },
        (row) => {
          // const { history } = this.props
          // history.push(`/workflows/${row.id}`)
        }
      ]
      actionsArray[element.record_type_id - 1](row)
    }


  }

  render() {
    const { getRecordName } = this.context
    const { classes, element } = this.props
    if (!element) return null
    const { records = [] } = element
    if (!records?.length) return null
    const generalTableInfo = infos[element.record_type_id - 1]
    const title = getRecordName(element.record_type_id, element.record_id)
    const actions = [
      { name: "Ver", icon: KeyboardArrowRight, action: this.handleGoto }
    ]
    return (
      <div className={classes.container}>
        <Typography variant="h4" style={{ margin: '12px 0' }}>{title}</Typography>
        <GeneralTable info={generalTableInfo} data={records} scrollable disableSearch actions={actions} />
      </div>
    )
  }
}

InnerRecordCard.contextType = ProgramContext

export default withRouter(withStyles(style)(InnerRecordCard))