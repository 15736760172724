import stateMaker from "../Utils/stateMaker"

const ListReducer = (
  state = { all: [] },
  action
) => {
  switch (action.type) {
    case "GET_LISTS":
      return stateMaker(state, "all", "fetch", action)
    case "CREATE_LIST":
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const newList = action.payload.data.info[0]
        newState.all.push(newList)
      }
      return newState
    case "EDIT_LIST": {
      const newState = { ...state }
      if (action.payload.data.status === "success") {
        const editedList = action.payload.data.info[0]
        const oldListIndex = newState.all.findIndex(list => list.id === editedList.id)
        newState.all[oldListIndex] = editedList
      }
      return newState
    }
    default:
      return state
  }
}

export default ListReducer
