import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import { SwitchCameraOutlined } from '@material-ui/icons'
import autobind from '../../../../Utils/autobind'
import { Paper } from '@mui/material'

const style = () => ({
  container: {
    // position: 'absolute',
    // right: 0,
    // top: 72,
    maxWidth: 500
  },
  section: {
    padding: 12,
    margin: '0 0 12px 0',
    transition: 'all 0.3s linear'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  exampleLine: {
    height: 6,
    width: '80%',
    background: '#6a6a6a66',
    borderRadius: 8,
    margin: '6px 0'
  }
})

class ChangeSections extends Component {
  constructor() {
    super()
    this.state = {
      order: 0,
      disabled: false
    }
    autobind(ChangeSections, this)
  }

  handleSwitchSections() {
    const { order, onSwitch } = this.props

    // Start animation
    this.setState({ disabled: true })
    document.getElementById("up").style.transform = "translateY(114%)"
    document.getElementById("down").style.transform = "translateY(-114%)"

    const callback = async () => {
      // document.getElementById("up").style.transform = "translateY(0)"
      // document.getElementById("down").style.transform = "translateY(0)"
      await onSwitch({ invert_order: order === 0 ? 1 : 0 })
      this.setState({ disabled: false })
    }

    setTimeout(callback, 310)
    // On end animation change state
  }

  render() {
    const { disabled } = this.state
    const { classes, order } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant='h4' style={{ paddingLeft: 12 }} >Cambiar orden de secciones</Typography>
          <IconButton onClick={this.handleSwitchSections} disabled={disabled}>
            <SwitchCameraOutlined style={{ transform: 'rotate(90deg)' }} />
          </IconButton>
        </div>
        {order === 0 ?
          <div key={"first"}>
            <Paper className={classes.section} id="up">
              <Typography variant='subtitle1'>Elementos de registro o formulario</Typography>
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
            </Paper>
            <Paper className={classes.section} id="down">
              <Typography variant='subtitle1'>Campos extra de registro o formulario</Typography>
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
            </Paper>
          </div>
          :
          <div key={"second"}>
            <Paper className={classes.section} id="up">
              <Typography variant='subtitle1'>Campos extra de registro o formulario</Typography>
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
            </Paper>
            <Paper className={classes.section} id="down">
              <Typography variant='subtitle1'>Elementos de registro o formulario</Typography>
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
              <div className={classes.exampleLine} />
            </Paper>
          </div>
        }
      </div>
    )
  }
}

export default withStyles(style)(ChangeSections)