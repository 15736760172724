import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { getEnterpriseActivitiesAction } from '../../Actions/ActivityActions'
import { connect } from 'react-redux'
import GeneralTable from '../../Shared/GeneralTable'
import { activityIPERInfo } from '../Activities/Info'

const style = () => ({
	container: {
		padding: 12
	}
})

class IPER extends Component {

	componentDidMount() {
		const { getActivities } = this.props
		getActivities()
	}

	render() {
		const { classes, activities } = this.props
		const allActivities = activities.all || []
		return (
			<div className={classes.container}>
				<Typography variant="h1">IPER</Typography>
				<GeneralTable
					info={activityIPERInfo}
					data={allActivities}
				/>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	activities: state.activities
})

const mapDispatchToProps = dispatch => ({
	getActivities: () => dispatch(getEnterpriseActivitiesAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(IPER))