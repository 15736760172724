import store from "../store"

const createSnackbar = (payload) => {
    payload.then((response) => {
        const snackbar = store.getState().controller.snackbar
        const { data } = response
        if (data.status === "error") {
            snackbar(data.message, { variant: "error" })
        } else {
            snackbar(data.message, { variant: "success" })
        }
    })
}

export const callSnackbar = (message, variant = "success") => {
    const snackbar = store.getState().controller.snackbar
    snackbar(message, { variant })
}

export default createSnackbar
