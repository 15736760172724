import React, { Component } from "react"
import {
    Paper,
    withStyles,
    Typography,
    IconButton,
    withWidth,
    Popover,
} from "@material-ui/core"
import ProfileCard from "../../Shared/ProfileCard"
import autobind, { addToggle } from "../../Utils/autobind"
import NotificationCard from "../../Shared/NotificationCard"
import EnterpriseCard from "../../Shared/EnterpriseCard"
import { connect } from "react-redux"
import { setBranchAction } from "../../Actions/BranchActions"
import { withRouter } from "react-router-dom"
import WifiCard from "./WifiCard"
import { removeDoneRequests } from "../../Actions/RequestAction"
import { FiBell, FiBriefcase, FiChevronLeft, FiChevronRight, FiHome, FiMenu, FiUser, FiWifi, FiWifiOff } from "react-icons/fi"
import SelectInput from "../../Shared/Inputs/SelectInput"
import { transformToOptions } from "../../Utils/functions"
import { LocalOffer } from "@material-ui/icons"
import TagsCard from "./TagsCard"

const style = (theme) => ({
    mainContainer: {
        "@media (max-width:500px)": {
            background: theme.palette.blue.dark,
            height: 150,
            position: 'relative',
            marginBottom: 24,
            '&::before': {
                content: "''",
                position: 'absolute',
                bottom: 0,
                right: 0,
                height: 24,
                width: '100%',
                background: theme.palette.blue.dark,
                transform: 'translate(0, 100%)',
            },
            '&::after': {
                content: "''",
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: 24,
                width: '100%',
                background: '#f2f3f8',
                transform: 'translate(0, 100%)',
                borderRadius: '30px 30px 0 0',
            }
        },

    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        padding: 12,
        position: "sticky",
        top: 0,
        zIndex: 100,
        background: '#f2f3f8',
        "@media (max-width:500px)": {
            background: theme.palette.blue.dark,
            position: 'relative',
            alignItems: 'center'
        },
    },
    hidden: {
        display: "none",
        "@media (max-width:500px)": {
            display: "block",
        },
    },
    unhide: {
        display: "block",
        "@media (max-width:500px)": {
            display: "none",
        },
    },
    inline: {
        display: "flex",
        alignItems: "center",
    },
    inlineStretch: {
        display: "flex",
        alignItems: "stretch",
        flexGrow: 1,
        "@media (max-width:500px)": {
            position: 'absolute',
            top: 72,
            right: 0
        },
    },
    button: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: 12,
        minHeight: 21
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 12,
    },
    backbutton: {
        background: theme.palette.primary.main,
        borderRadius: 15,
        padding: 4,
        display: 'flex',
        alignItems: 'center',
        marginRight: 12,
        cursor: 'pointer',
        '& > div > button:hover': {
            color: 'white'
        },
        "@media (max-width:500px)": {
            padding: '10px 0',
            background: theme.palette.primary.main,
            '& > div > button': {
                background: theme.palette.purple.light,
                color: 'white'
            },
        },
    },
    topElement: {
        background: theme.palette.blue.dark,
        borderRadius: 15,
        padding: '10px 35px',
        flexGrow: 1,
        marginRight: 12,
        maxWidth: 500
    },
    topButton: {
        background: theme.palette.primary.main,
        color: theme.palette.blue.light,
        margin: '0 12px',
        '&:hover': {
            color: theme.palette.blue.light
        },
        "@media (max-width:500px)": {
            background: theme.palette.blue.dark,
            color: theme.palette.blue.light,
        },
    },
    topText: {
        color: 'white'
    },
    input: {
        background: theme.palette.blue.light,
        borderRadius: 15,
        margin: 0
    },
    topElementButtons: {
        background: theme.palette.blue.dark,
        borderRadius: 15,
        padding: '10px 35px',
        display: 'flex',
        "@media (max-width:500px)": {
            display: 'block',
            padding: '10px 0'
        },
    },
    img: {
        padding: '0 28px',
        width: 140,
        height: 60
    },
    floatinBranch: {
        background: theme.palette.blue.light,
        '& > *': {
            color: "#B865C1",
            fontWeight: 600
        },
        padding: '6px 36px 6px 12px',
        borderRadius: '0 12px 12px 0',
        position: 'absolute',
        zIndex: 5,
        transition: 'all 0.2s ease-in-out'
    },
    branch: {
        marginLeft: 27,
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            color: theme.palette.blue.light,
            marginRight: 12
        },
        '& > svg': {
            height: 24,
            width: 24
        }
    }
})

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showProfile: false,
            showNotifications: false,
            showEnterprise: false,
            showWifi: false,
            changedOnline: false,
            anchorEl: "",
            selectedBranch: ""
        }

        this.oldValue = 0
        addToggle(Header, this, "tags")
        autobind(Header, this)
    }

    componentDidUpdate(prevProps) {
        const { online, branch } = this.props
        if (prevProps.online.status !== online.status && online.status) {
            this.setState({ changedOnline: true })
        }
        if (prevProps.branch?.global?.id !== branch?.global?.id) {
            this.setState({ selectedBranch: branch?.global?.id })
        }

    }

    componentDidMount() {
        this.setState({ anchorEl: document.getElementById("headerAnchor") })
        const { branch } = this.props
        const self = this
        window.addEventListener("scroll", () => {
            const element = document.getElementById("branch-tag")
            if (!element) return null
            const newValue = window.pageYOffset
            if (self.oldValue - newValue < 0) {
                element.style.transform = "translate(-90%, 0)"
            } else if (self.oldValue - newValue > 0) {
                element.style.transform = "translate(0, 0)"
            }

            // Update the old value
            self.oldValue = newValue
        })
        if (!!branch?.global?.id) return this.setState({ selectedBranch: branch?.global?.id || '' })
    }

    handleOpenProfile(e) {
        this.setState({ showProfile: !this.state.showProfile })
    }

    handleOpenNotifications(e) {
        this.setState({ showNotifications: !this.state.showNotifications })
    }

    handleOpenEnterprise(e) {
        this.setState({ showEnterprise: !this.state.showEnterprise })
    }

    handleOpenWifiPanel(e) {
        const { removeDoneRequests } = this.props
        if (navigator.onLine && (this.state.showWifi || this.state.changedOnline)) {
            removeDoneRequests()
        }
        this.setState({ showWifi: this.state.changedOnline ? false : !this.state.showWifi, changedOnline: false })
    }

    handleSelectBranch(e) {
        const { enterprise, setBranch } = this.props
        const { target } = e
        const selected = enterprise.branches.find((branch) => {
            return branch.id === target.value.toString()
        })
        let newSelected
        if (!selected) {
            newSelected = {
                name: "Todos",
                id: target.value.toString()
            }
        }
        selected ? setBranch(selected) : setBranch(newSelected)
        this.setState({ selectedBranch: selected ? selected.id : target.value })
        this.setState({ showEnterprise: false })
        // setTimeout(() => {
        //     window.location.reload()
        // }, 1000)
        const event = new CustomEvent("branch_change", { detail: { branch: selected || newSelected } })
        window.dispatchEvent(event)
    }

    handleBack() {
        this.props.history.goBack()
    }

    renderStations() {
        const { enterprise, user, workers } = this.props
        const level = user.account.user.userType
        const allWorkers = workers.all || []
        const branches = enterprise.branches.filter(branch => {
            if (level > 1) {
                const mySelf = user.account.user
                return mySelf?.branch_ids?.map(b => b.toString()).includes(branch.id.toString())
            } else if (level === 1) {
                const mySelf = allWorkers.find(worker => String(worker.id) === user.account.user.id)
                if (!!mySelf?.branch_id) return mySelf?.branch_ids?.map(b => b.toString()).includes(branch.id.toString())
                return true
            }
            return true
        })
        const finalOptions = transformToOptions(branches)
        const allOptionValues = []
        finalOptions.forEach(option => allOptionValues.push(option.value))
        const allOption = [{ label: "Todos", value: allOptionValues.reverse().join(",") }]
        return allOption.concat(finalOptions)
    }

    render() {
        const {
            showProfile,
            showNotifications,
            showEnterprise,
            anchorEl,
            showWifi,
            changedOnline,
            selectedBranch,
            openTags
        } = this.state
        const { classes, handleOpen, enterprise, branch, online, requests, user, workers } = this.props
        const eName = enterprise.selected.name || "Nombre de Empresa"
        const sBranch = branch.global || "Centro de trabajo"
        const branches = enterprise.branches
        return (
            <>
                <div className={classes.mainContainer}>
                    <div className={classes.container} id="header">
                        <div className={classes.hidden}>
                            <IconButton
                                size="small"
                                name="sidebar"
                                className={classes.topButton}
                                onClick={handleOpen()}
                            >
                                <FiMenu />
                            </IconButton>
                        </div>
                        <div className={classes.inlineStretch}>
                            <Paper className={classes.backbutton} onClick={this.handleBack}>
                                <div>
                                    <IconButton color="secondary" className={classes.topButton} size="small">
                                        <FiChevronLeft />
                                    </IconButton>
                                </div>
                            </Paper>
                            <Paper className={`${classes.topElement} ${classes.unhide} `}>
                                <div className={`${classes.inline} `}>
                                    {/* TODO: Get this from response on redux */}
                                    <Typography
                                        variant="h1"
                                        style={{ marginRight: 18 }}
                                        className={classes.topText}
                                    >
                                        {eName}
                                    </Typography>
                                    <FiChevronRight style={{ marginRight: 18, height: 24, width: 24 }} className={classes.topText} />
                                    <SelectInput
                                        value={selectedBranch}
                                        onChange={this.handleSelectBranch}
                                        variant="outlined"
                                        margin="dense"
                                        label=""
                                        inputStyle={classes.input}
                                        options={this.renderStations()}
                                    >
                                        { }
                                    </SelectInput>
                                </div>
                            </Paper>
                        </div>
                        <Paper className={classes.topElementButtons}>
                            <div className={classes.inline}>
                                <div className={classes.hidden}>
                                    <IconButton size="small" onClick={this.handleOpenEnterprise} className={classes.topButton}>
                                        <FiBriefcase />
                                    </IconButton>
                                </div>
                                <IconButton size="small" onClick={this.handleOpenTags} className={classes.topButton}>
                                    <LocalOffer />
                                </IconButton>
                                <IconButton size="small" onClick={this.handleOpenWifiPanel} className={classes.topButton}>
                                    {online.status ? <FiWifi /> : <FiWifiOff />}
                                </IconButton>
                                <IconButton size="small" onClick={this.handleOpenNotifications} className={classes.topButton}>
                                    <FiBell />
                                </IconButton>
                                <IconButton size="small" onClick={this.handleOpenProfile} className={classes.topButton}>
                                    <FiUser />
                                </IconButton>
                                <Popover
                                    onClose={this.handleOpenEnterprise}
                                    open={showEnterprise}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <EnterpriseCard
                                        onChange={this.handleSelectBranch}
                                        name={eName}
                                        branch={sBranch}
                                        branches={branches}
                                        user={user}
                                        workers={workers}
                                    />
                                </Popover>
                                <Popover
                                    onClose={this.handleOpenNotifications}
                                    open={showNotifications}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <NotificationCard />
                                </Popover>
                                <Popover
                                    onClose={this.handleOpenProfile}
                                    open={showProfile}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <ProfileCard />
                                </Popover>
                                <Popover
                                    onClose={this.handleOpenTags}
                                    open={openTags}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <TagsCard />
                                </Popover>
                                <Popover
                                    onClose={this.handleOpenWifiPanel}
                                    open={showWifi || changedOnline}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <WifiCard open={showWifi || changedOnline} />
                                </Popover>
                            </div>
                        </Paper>
                    </div>
                    <div className={classes.hidden}>
                        <div className={classes.branch}>
                            <FiHome />
                            <Typography variant="subtitle1">{sBranch?.name}</Typography>
                        </div>
                    </div>
                    {/* <div className={classes.hidden}>
                        <div className={classes.floatinBranch} id="branch-tag">
                            <Typography variant="subtitle1">{sBranch?.name}</Typography>
                        </div>
                    </div> */}
                    {/* <div className={classes.button} id="headerAnchor">
                    <div>
                        <div className={classes.hidden}>
                            <div className={classes.inline}>
                                <KeyboardArrowRight />
                                <Typography variant="subtitle1">
                                    {sBranch.name}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    enterprise: state.enterprise,
    branch: state.branch,
    online: state.online,
    requests: state.requests,
    user: state.user,
    workers: state.workers
})

const mapDispatchToProps = (dispatch) => ({
    setBranch: (branch) => dispatch(setBranchAction(branch)),
    removeDoneRequests: () => dispatch(removeDoneRequests())
})

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withWidth()(withStyles(style)(Header)))
)
