import React, { Component } from 'react'
import { Tooltip, Typography, withStyles } from '@material-ui/core'

const style = () => ({
  answer: {
    background: 'lightgrey',
    padding: '6px 12px',
    borderRadius: 6,
    maxWidth: 150,
    minWidth: 150,
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      maxWidth: 400
    },
    margin: 6,
    '& > *': {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

class TextFields extends Component {
  renderAnswers(answers) {
    const { classes } = this.props
    return answers.map(answer => {
      return (
        <div className={classes.answer}>
          <Tooltip title={answer}>
            <Typography variant="subtitle1">{answer}</Typography>
          </Tooltip>
        </div>
      )
    })
  }

  render() {
    const { classes, field, data = [] } = this.props
    const allAnswers = data
      .map(checklist => {
        const answer = checklist?.informacion_extra?.find(extra => extra?.label === field?.label)
        return answer?.value ? `${checklist?.fecha_realizado} - ${answer?.value}` : false
      }).filter(Boolean)
    return (
      <div className={classes.container}>
        {this.renderAnswers(allAnswers)}
      </div>
    )
  }
}

export default withStyles(style)(TextFields)