import { HighlightOff } from '@material-ui/icons'
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext } from 'react'
import { createMultipleActivitiesFromBranches, createMultipleFromPosition } from '../../API/activities'
import useChangeParams from '../../Hooks/UseStateChangeParams'
import DateInput from '../../Shared/Inputs/DateInput'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import UsersInput from '../../Shared/Inputs/UsersInput'
import { inline_space, paper_style } from '../../Utils/defaultStyles'
import { filterActiveWorkers, findWorker } from '../../Utils/filters'
import { transformToOptions } from '../../Utils/functions'
import { callSnackbar } from '../../Utils/snackbar'
import { ActivitiesContext } from './ActivitiesNew'
import CreateMultipleByUsers from './CreateMultipleByUsers'

const css = {
	container: {
		padding: 2,
		background: 'whtie',
		borderRadius: 2,
		position: 'relative'
	},
	form: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		'& > *': {
			flexBasis: 200,
			flexGrow: 1,
		}
	}
}

const initialParams = { start_date: moment().format("YYYY-MM-DD"), end_date: moment().format("YYYY-MM-DD") }

function CreateMultipleByBranch({ open, onClose }) {

	const [params, setParams, updateParams] = useChangeParams(initialParams)
	const { formData } = useContext(ActivitiesContext)

	async function onCreate() {
		const body = { ...params }
		const branches = body.branch_ids
		const newActivities = branches.map(branch_id => ({ ...params, branch_id }))
		await createMultipleActivitiesFromBranches(newActivities)
		callSnackbar("Actividades programas", "success")
		updateParams(initialParams)
		onClose()
	}

	function validateForm() {
		return (!!params.activity_id &&
			!!params.branch_ids &&
			!!params.start_date &&
			!!params.end_date &&
			!!params.frequency &&
			!!params.user_id)
	}

	const selectedWorker = findWorker(params.user_id)
	const posible_branches = selectedWorker ? formData.branches.filter(branch => selectedWorker.branch_ids.map(String).includes(String(branch.id))) : []

	return (
		<Collapse in={open}>
			<Box sx={paper_style}>
				<Box sx={inline_space}>
					<Typography variant='h4'>Planificar actividades para múltiples centros de trabajo</Typography>
					<IconButton onClick={onClose}>
						<HighlightOff />
					</IconButton>
				</Box>
				<Box sx={css.form}>
					<SelectInput
						onChange={setParams}
						value={params.activity_id}
						name="activity_id"
						label="Actividad*" options={transformToOptions(formData.activities)} />
					<UsersInput label="Responsable" name="user_id" onChange={setParams} value={params.user_id} />
					<DateInput
						onChange={setParams}
						value={params.start_date}
						name="start_date"
						label="Fecha de inicio*" />
					<DateInput
						onChange={setParams}
						value={params.end_date}
						name="end_date"
						label="Fecha de término*" />
					<SelectInput
						onChange={setParams}
						value={params.frequency}
						name="frequency"
						label="Frecuencia" options={formData.frequencies} />
					<MultiSelectInput
						onChange={setParams}
						value={params.branch_ids}
						name="branch_ids"
						label="Centros de trabajo*" options={transformToOptions(posible_branches)} />
				</Box>
				<Button color="primary" variant="contained" onClick={onCreate} disabled={!validateForm()}>
					Planificar Actividades
				</Button>
			</Box>
		</Collapse>
	)
}

export default CreateMultipleByBranch