import React, { Component } from 'react'
import { Dialog, Divider, Switch, Typography, withStyles } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import autobind from '../../../../Utils/autobind'

const style = () => ({
  container: {
    padding: 24,
    '& > *': {
      margin: '3px 0'
    }
  },
  switch: {
    display: 'flex',
    alignItems: 'center'
  }
})
class OptionSettings extends Component {
  constructor() {
    super()
    autobind(OptionSettings, this)
  }

  handleSetCut() {
    const { field, onEdit } = this.props
    const body = { ...field }
    const isCut = !body.isCut
    body.isCut = isCut
    onEdit(body)
  }

  render() {
    const { classes, open, onClose, field } = this.props
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant='h1'>Configuración de pregunta: {field?.label}</Typography>
          <Divider style={{ margin: '6px 0' }} />
          <div className={classes.switch}>
            <Typography variant='h4'>Activar pregunta de corte</Typography>
            <Switch checked={!!field.isCut} onClick={this.handleSetCut} />
          </div>
          <HelpOutline />
          <Typography variant='subtitle1'>
            Una pregunta de corte corresponde a aquellas
            que, basado en al respuesta, pueden
            levantar una alerta al finalizar un registro
            {' '}
            <span style={{ fontWeight: 600 }}>público</span>
          </Typography>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(style)(OptionSettings)