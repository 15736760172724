import React, { Component } from 'react'
import { Button, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import MyUploadAdapter from '../Talks/MyUploader'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import { transformToOptions } from '../../Utils/functions'
import { filterActiveWorkers } from '../../Utils/filters'
import TextInput from '../../Shared/Inputs/TextInput'
import { AddCircleOutline, HighlightOffOutlined, MailOutline, Send } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { connect } from 'react-redux'
import { sendMail } from '../../API/workflows'
import SendWarning from './SendWarning'

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader)
  }
}

const style = theme => ({
  container: {
    margin: 24,
    padding: 12,
    borderRadius: 12,
    background: 'white'
  },
  title: {
    margin: '12px 0'
  },
  form: {
    maxWidth: 500
  },
  inline: {
    display: 'flex',
    alignItems: 'center'
  },
  submit: {
    textAlign: 'end',
    marginTop: 12
  },
  chip: {
    padding: '4px 9px 4px 18px',
    borderRadius: 24,
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      color: 'white'
    },
    margin: 6
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

class MailStep extends Component {
  constructor() {
    super()
    this.state = {
      content: "",
      participants: [],
      aditional: [],
      email: "",
      openWarning: false
    }
    autobind(MailStep, this)
  }

  handleChangeParticipants(event) {
    const { target } = event
    this.setState({ participants: target.value })
  }

  getUsersNames(ids) {
    if (ids.length === 0) return (
      <div>
        <Typography variant="subtitle1">Sin asignar</Typography>
      </div>
    )
    const { workers } = this.props
    const allWorkers = workers.all || []
    return ids.map(id => allWorkers.find(w => w.id === id)?.name || null).join(", ")
  }

  componentDidUpdate(prevProps) {
    const { selectedItems } = this.props
    if (selectedItems.length !== prevProps.selectedItems.length) {
      console.log("CAMBIO")
      // Render contents on RTE

      const details = selectedItems.reduce((x, item) => {
        console.log(item)
        const data = item.data
        const type = data.type
        let value = data.value

        if (type === "file") {
          const files = data.value.split("#")
          value = files.map((file, index) => {
            const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}${file}`
            return `<a href="${url}">Documento ${index + 1}</a>`
          }).join(", ")
        }

        if (type === "check") {
          value = data.value === true ? "Si" : "No"
        }

        if (type === "select") {
          if (!!item?.data?.options) {
            value = item?.data?.options[value - 1]
          }
          if (item?.data?.entity === "workers") {
            value = this.getUsersNames([value])
          }
        }

        if (type === "multiselect") {
          if (!!item?.data?.options) {
            value = value.map(v => item?.data?.options[v])
          }
          if (item?.data?.entity === "workers") {
            value = this.getUsersNames(value)
          }
        }

        const itemText = `<p>Campo: ${data.label}</p>
        <p>Respuesta: ${value || "Sin respuesta..."}</p>
        <p></p>`
        return x + itemText
      }, "")

      const sep = `
      <p>#------------------------------------------------------------------#</p>
      <p>Items: </p>
      <p></p>
      `

      const { content } = this.state

      const startIndex = content.indexOf("<p>#---")
      const newContent = content.slice(0, startIndex)
      if (selectedItems.length === 0) return this.setState({ content: newContent })
      const finalContent = `${newContent}${sep}${details}`
      console.log(finalContent)
      this.setState({ content: finalContent })
    }
  }

  handleChange(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handleChangeContent(event, editor) {
    const data = editor.getData()
    this.setState({ content: data })
  }

  handleAddAditional() {
    const { email, aditional } = this.state
    aditional.push(email)
    this.setState({ aditional, email: "" })
  }

  handleOpenWarning() {
    const { openWarning } = this.state
    this.setState({ openWarning: !openWarning })
  }

  handleRemoveParticipant(participant) {
    return () => {
      const { participants } = this.state
      const newParticipants = [...participants]
      const index = newParticipants.findIndex(p => p === participant)
      newParticipants.splice(index, 1)
      this.setState({ participants: newParticipants })
    }
  }

  handleRemoveAditional(email) {
    return () => {
      const { aditional } = this.state
      const newAditional = [...aditional]
      const index = newAditional.findIndex(e => e === email)
      newAditional.splice(index, 1)
      this.setState({ aditional: newAditional })
    }
  }

  handleSend() {
    const { content, subject, participants, aditional } = this.state
    const { workers } = this.props
    const allWorkers = workers.all || []
    const body = {
      subject,
      content,
      emails: participants.map(participant => allWorkers
        .find(worker => worker.id.toString() === participant).email),
      extra_mails: aditional
    }
    sendMail(body)
    this.handleOpenWarning()
  }

  handleClean() {
    this.setState({
      content: "",
      participants: [],
      aditional: [],
      email: "",
      subject: "",
    })
    this.handleOpenWarning()
  }

  renderParticipants() {
    const { workers, classes } = this.props
    const allWorkers = workers.all || []
    const { participants } = this.state
    const mails = participants.map(participant => {
      const worker = allWorkers.find(worker => worker.id.toString() === participant.toString())
      return (
        <div className={classes.chip}>
          <Typography variant="subtitle1">{worker.email} - {worker.name}</Typography>
          <IconButton size="small" style={{ marginLeft: 6 }} onClick={this.handleRemoveParticipant(participant)}>
            <HighlightOffOutlined />
          </IconButton>
        </div>
      )
    })
    return (
      <>
        <Typography variant="h4" className={classes.title}>Destinatarios:</Typography>
        <div className={classes.chips}>
          {mails}
        </div>
      </>
    )
  }

  renderAditionals() {
    const { aditional } = this.state
    const { classes } = this.props
    const mails = aditional.map(email => {
      return (
        <div className={classes.chip}>
          <Typography variant="subtitle1">{email}</Typography>
          <IconButton size="small" style={{ marginLeft: 6 }} onClick={this.handleRemoveAditional(email)}>
            <HighlightOffOutlined />
          </IconButton>
        </div>
      )
    })
    return (
      <>
        <Typography variant="h4" className={classes.title}>Adicionales:</Typography>
        <div className={classes.chips}>
          {mails}
        </div>
      </>
    )
  }

  render() {
    const { classes } = this.props
    const { content, email, participants, aditional, subject, openWarning } = this.state
    return (
      <div className={classes.container}>
        <SendWarning open={openWarning} onClose={this.handleOpenWarning} onSubmit={this.handleClean} />
        <Typography variant="h4" className={classes.title}>Configuración envío por correo</Typography>
        <div className={classes.form}>
          <MultiSelectInput onChange={this.handleChangeParticipants} value={participants} label="Seleccionar colaboradores" options={transformToOptions(filterActiveWorkers())} />
          <div className={classes.inline}>
            <TextInput label="Agregar correo" value={email} onChange={this.handleChange} name="email" />
            <IconButton onClick={this.handleAddAditional} disabled={!email}>
              <AddCircleOutline />
            </IconButton>
          </div>
        </div>
        <Divider style={{ margin: '24px 0' }} />
        <div className={classes.inline}>
          <MailOutline style={{ marginRight: 6 }} />
          <Typography variant="h4" className={classes.title}>Correo</Typography>
        </div>
        {participants.length > 0 && this.renderParticipants()}
        {aditional.length > 0 && this.renderAditionals()}
        <TextInput label="Asunto" onChange={this.handleChange} value={subject} name="subject" />
        <div className={classes.rte}>
          <CKEditor
            editor={Editor}
            data={content}
            onChange={this.handleChangeContent}
            config={{
              // fontSize: {
              //   supportAllValues: true
              // },
              toolbar: ['bold', 'italic', '|',
                "Undo",
                "Redo",
                "imageUpload",
                "insertTable",
                "link",
                '|',
                "alignment:left",
                "alignment:right",
                "alignment:center",
                "alignment:justify",
                '|',
                'fontColor',
                'fontBackgroundColor',
                'fontSize'
              ],
              language: 'es',
              extraPlugins: [MyCustomUploadAdapterPlugin]
            }}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "min-height",
                  "200px",
                  editor.editing.view.document.getRoot()
                )
              })
            }}
          />
        </div>
        <div className={classes.submit}>
          <Button color="primary" variant="contained" onClick={this.handleSend} disabled={(participants.length === 0 && aditional.length === 0) || !subject || !content}>
            <Send style={{ marginRight: 6 }} />
            Enviar correos
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers
})

export default connect(mapStateToProps)(withStyles(style)(MailStep))