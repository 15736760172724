import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import autobind from '../../../../Utils/autobind'
import moment from 'moment'

const style = () => ({
  answer: {
    padding: '6px 12px',
    borderRadius: 8,
    margin: 6,
    background: 'white',
    maxWidth: 150,
    minWidth: 150,
    transition: 'all 0.5s ease-in-out',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  open: {
    maxWidth: '100%',

  }
})

class TextPhaseReport extends Component {
  constructor() {
    super()
    this.state = {
      open: -1
    }
    autobind(TextPhaseReport, this)
  }

  handleOpen(index) {
    return () => {
      this.setState({ open: index })
    }
  }

  render() {
    const { classes, answers } = this.props
    const { open } = this.state
    return (
      <div className={classes.container}>
        {answers.map((answer, index) => {
          if (!answer?.data?.value) return null
          return (
            <div className={`${classes.answer} ${open === index ? classes.open : ""}`} onClick={this.handleOpen(index)}>
              <Typography variant="subtitle1">{moment(answer?.data?.date).format("YYYY-MM-DD")} - {answer?.data?.value}</Typography>
            </div>
          )
        })}
      </div>
    )
  }
}

export default withStyles(style)(TextPhaseReport)