import React, { Component } from 'react'
import { Button, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import TextInput from '../../Shared/Inputs/TextInput'
import DateInput from '../../Shared/Inputs/DateInput'
import moment from 'moment'
import { connect } from 'react-redux'
import { openGlobalDialog } from '../../Actions/GlobalActions'

const style = () => ({
  container: {
    padding: 12,
    background: 'white',
    borderRadius: 10,
    margin: '12px 0',
    maxWidth: 500,
    position: 'relative',
    '& > *': {
      margin: '3px 0'
    }
  },
  buttons: {
    textAlign: 'end'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

class Injured extends Component {
  constructor() {
    super()
    this.state = {
      openEdit: false,
      params: {}
    }
    autobind(Injured, this)
  }

  componentDidMount() {
    const { injuredUser } = this.props
    const startParams = {
      lost_days: injuredUser.lost_days,
      date_start: moment(injuredUser.date_start).isValid() ? injuredUser.date_start : moment(new Date()).format("YYYY-MM-DD"),
      date_end: moment(injuredUser.date_end).isValid() ? injuredUser.date_end : moment(new Date()).format("YYYY-MM-DD")
    }
    this.setState({ params: startParams })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleOpenEdit() {
    const { openEdit } = this.state
    this.setState({ openEdit: !openEdit })
  }

  handleEdit() {
    const { onEdit, injuredUser } = this.props
    const { params } = this.state
    const body = { ...params, id: injuredUser.id, injured_user: injuredUser.user_id }
    onEdit(body)
    this.setState({ params: {}, openEdit: false })
  }

  handleDelete() {
    const { onDelete, injuredUser, openGlobalDialog } = this.props
    const body = { id: injuredUser.id, injured_user: injuredUser.user_id }
    openGlobalDialog(onDelete, body, {
      message: "Seguro que deseas eliminar este afectado",
      title: `Eliminar a ${injuredUser?.name} como afectado`,
      submit: "Si, eliminar"
    })
  }

  render() {
    const { classes, injuredUser } = this.props
    const { params, openEdit } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h4">{injuredUser.name}</Typography>
          <div className={classes.buttons}>
            <IconButton size="small" onClick={this.handleOpenEdit}>
              <Edit />
            </IconButton>
            <IconButton size="small" onClick={this.handleDelete}>
              <Delete />
            </IconButton>
          </div>
        </div>
        {openEdit ?
          <>
            <TextInput label="Días perdidos" onChange={this.handleChange} name="lost_days" value={params.lost_days} type="number" />
            <DateInput label="Desde" onChange={this.handleChange} name="date_start" value={params.date_start} />
            <DateInput label="Hasta" onChange={this.handleChange} name="date_end" value={params.date_end} />
            <div className={classes.buttons}>
              <Button onClick={this.handleOpenEdit}>
                Cancelar
              </Button>
              <Button onClick={this.handleEdit} color="primary" variant="contained">
                Guardar
              </Button>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </>
          :
          <>
            <Typography variant="subtitle1">Días perdidos: {injuredUser?.lost_days}</Typography>
            <Typography variant="subtitle1">{`Desde ${injuredUser.date_start} - Hasta ${injuredUser.date_end}`}</Typography>
          </>
        }

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  openGlobalDialog: (action, body, options) => dispatch(openGlobalDialog(action, body, options)),
})

export default connect(null, mapDispatchToProps)(withStyles(style)(Injured))