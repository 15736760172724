import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { getAllWorkers } from '../../../../API/workers'
import DonutChart from '../../../../Shared/Charts/DonutChart'

const style = () => ({
  chart: {
    width: 300,
    marginTop: 24
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  dot: {
    height: 18,
    width: 18,
    borderRadius: '50%',
    marginRight: 6
  }
})

const colors = [
  "#00876c",
  "#8cbcac",
  "#f1f1f1",
  "#ec9c9d",
  "#d43d51",
]

function stripOption(option) {
  return option.split("&")[0]
}

class MultipleField extends Component {
  constructor() {
    super()
    this.state = {
      workers: []
    }
  }
  async componentDidMount() {
    const response = await getAllWorkers()
    const workers = response.data.info
    this.setState({ workers })
  }

  calculateValues() {
    const { data, field } = this.props
    const options = field.options.map(option => typeof option === "string" ? stripOption(option) : stripOption(option?.label)).filter(Boolean)
    const values = {}
    options.forEach(option => {
      values[option] = 0
    })
    data.forEach(checklist => {
      const answer = checklist?.informacion_extra?.find(extra => extra?.label === field?.label)
      console.log(answer)
      if (!answer || !answer.value || !Array.isArray(answer.value)) return null
      answer.value.forEach(value => {
        const firstAnswer = value.split(":")[0]
        values[firstAnswer] += 1
      })
    })
    const realValues = {}
    Object.keys(values).forEach(key => {
      if (values[key] !== 0) {
        realValues[key] = values[key]
      }
    })
    return realValues
  }

  renderLabels(values) {
    const { classes } = this.props
    return Object.keys(values).map((option, index) => {
      return (
        <div className={classes.label}>
          <div className={classes.dot} style={{ background: colors[index] }} />
          <Typography variant="subtitle1">{option}: {values[option]}</Typography>
        </div>
      )
    })

  }

  render() {
    const { classes, field, index } = this.props
    const values = this.calculateValues()
    const totalOptions = values.length
    const colorsTuUse = colors.slice(0, totalOptions)
    return (
      <div>
        <div className={classes.container}>
          <div className={classes.chart}>
            <DonutChart data={Object.values(values)} color={colorsTuUse} name={`${field.label}-${index}`} />
          </div>
        </div>
        {this.renderLabels(values)}
      </div>
    )
  }
}

export default withStyles(style)(MultipleField)