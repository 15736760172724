import instance2 from "../Utils/instance2"

export const getTags = body => {
	return instance2({
		method: 'get',
		url: 'settings/checklists/get_tags.php',
		params: body
	})
}

export const createTag = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/create_tag.php',
		data: body
	})
}

export const editTag = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/edit_tag.php',
		data: body
	})
}

export const addTag = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/add_tag.php',
		data: body
	})
}

export const removeTag = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/remove_tag.php',
		data: body
	})
}

export const deleteTag = body => {
	return instance2({
		method: 'post',
		url: 'settings/checklists/delete_tag.php',
		data: body
	})
}