import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = () => ({
  options: {
    margin: '6px 0'
  },
  option: {
    position: 'relative',
    paddingLeft: 24,
    '&:before': {
      content: '""',
      position: 'absolute',
      background: 'lightgrey',
      height: 8,
      width: 8,
      borderRadius: '50%',
      left: 8,
      top: '50%',
      transform: 'translate(0, -50%)'
    }
  }
})

class SelectPhase extends Component {
  renderOptions() {
    const { phase, classes } = this.props
    if (phase.entity) return <Typography variant="subtitle1">Entidad: {phase.entity}</Typography>
    const options = phase.options || []
    return options.map(option => {
      return (
        <div className={classes.option}>
          <Typography variant="subtitle1">{option}</Typography>
        </div>
      )
    })
  }

  render() {
    const { classes, phase } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">Tipo de fase: {phase.type === "select" ? "Seleccionar una opción" : "Selección Múltiple"} </Typography>
        <Typography variant="caption">Titulo: {phase.label}</Typography>
        <div className={classes.options}>
          <Typography variant="body1">Opciones {`${phase.entity ? "(Entidad)" : "(Creadas)"}`}:</Typography>

          {this.renderOptions()}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(SelectPhase)