import React, { Component } from 'react'
import { Tooltip, IconButton, Typography, withStyles } from '@material-ui/core'
import Category from './Category'
import { CloudDownloadOutlined } from '@material-ui/icons'
import DocumentContext from './DocumentContext'

const style = () => ({
  container: {
    maxWidth: 350,
    minWidth: 250,
    position: 'relative'
  },
  sub: {
    paddingLeft: 12
  },
  folder: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    color: '#6c6c6c', height: 18, width: 18, marginRight: 6
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0
  }
})

class Categories extends Component {

  renderCategories(categories) {
    return categories.map(category => {
      return (
        <div>
          <Category category={category} />
        </div>
      )
    })
  }

  render() {
    const { classes, categories } = this.props
    const { downloadAll } = this.context
    return (
      <div className={classes.container}>
        <Tooltip title="Descargar Todo">
          <IconButton size="small" color="primary" className={classes.button} onClick={downloadAll}>
            <CloudDownloadOutlined />
          </IconButton>
        </Tooltip>
        <Typography variant='h1' style={{ marginBottom: 24 }}>Carpetas</Typography>
        {this.renderCategories(categories)}
      </div>
    )
  }
}

Categories.contextType = DocumentContext

export default withStyles(style)(Categories)