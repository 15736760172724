import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Chip, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { getSupervisedDetails } from '../../../API/users'
import AppContext from '../../../AppContext'
import newTheme from '../../../newTheme'
import BigTooltip from '../../../Shared/BigTooltip'
import { inline, inline_space, text_space } from '../../../Utils/defaultStyles'
import { SupervisionContext } from './SupervisionHome'

const css = {
	checklist: {
		background: newTheme.palette.background.main,
		padding: 2,
		margin: '6px 0',
		borderRadius: 2
	},
	tag: {
		background: 'white',
		borderRadius: '24px',
		padding: '6px 12px'
	},
	container: {
		maxHeight: 350,
		overflow: 'auto'
	},
	title: {
		padding: '12px 6px'
	},
	message: {
		padding: '0 6px'
	}
}


function SupervisedEvaluation({ evaluation = {} }) {

	const { colors } = useContext(SupervisionContext)
	const { history } = useContext(AppContext)

	const finished = evaluation.finished
	const status = evaluation.approved ? "Aprobada" : "Reprobada"

	return (
		<Box sx={{ ...css.checklist, ...inline_space }}>
			<Box>
				<Typography variant='subtitle2'>{evaluation.name}</Typography>
				<Typography variant='caption'>{moment(evaluation.created_at).format("YYYY-MM-DD HH:mm")}</Typography>
			</Box>
			<Box sx={inline}>
				{finished ?
					<Chip label={`${status}: ${evaluation.percentage}%`} color={colors[status]} /> :
					<Chip label={"En proceso"} color="info" />
				}
				<BigTooltip title="Ver Registro">
					<IconButton onClick={() => history.push(`/evaluations/${evaluation?.id}`)}>
						<KeyboardArrowRight />
					</IconButton>
				</BigTooltip>
			</Box>
		</Box>
	)
}

function SupervisedEvaluations({ id, dates, supervisedUser }) {

	const [evaluations, setEvaluations] = useState([])

	useEffect(() => {
		async function fetchData() {
			const body = { id, type: "evaluations", ...dates }
			const response = await getSupervisedDetails(body)
			setEvaluations(response.data.info)
		}

		if (!!id) { fetchData() }

	}, [id, dates])

	console.log(evaluations)

	if (!evaluations.length) return (
		<Box>
			<Typography variant='h4' sx={css.title} >Evaluaciones de {supervisedUser?.name}</Typography>
			<Typography variant='subtitle1' sx={css.message} >No hay registros de este usuario entre las fechas seleccionadas...</Typography>
		</Box>
	)

	return (
		<Box sx={css.main}>
			<Typography variant='h4' sx={css.title} >Evaluaciones de {supervisedUser?.name}</Typography>
			<Box sx={css.container}>
				{evaluations.map(evaluation => <SupervisedEvaluation key={evaluation.id} evaluation={evaluation} />)}
			</Box>
		</Box>
	)
}

export default SupervisedEvaluations