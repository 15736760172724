import React, { Component } from "react"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import {
	withStyles,
	Paper,
	Tab,
	Typography,
	Divider,
	Button,
	IconButton,
	Dialog,
	Checkbox,
	Badge,
} from "@material-ui/core"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import MyTabs from "../../Shared/MyTabs"
import {
	getWorkerAction,
	editWorkerAction,
	getBranches,
	getWorkersAction,
} from "../../Actions/EnterpriseAction"
import { connect } from "react-redux"
import { userEditInfo, pwdForm } from "./Info"
import { addQrToFile, mergeMultiplePdfBytesFiles, mergeMultiplePdfFilesAndGet, signDocument, transformToOptions } from "../../Utils/functions"
import GeneralForm from "../../Shared/GeneralForm"
import { sha512 } from "js-sha512"
import { changePasswordAction } from "../../Actions/LoginActions"
import { getTrainingsAction } from "../../Actions/TrainingActions"
import { getTalksAction } from "../../Actions/TalkActions"
import ImageContainer from "../../Shared/ImageContainer"
import { Brightness1Outlined, CheckCircle, GetApp, KeyboardArrowRight, Send } from "@material-ui/icons"
import FormContext from "../../Shared/Cards/Provider"
import { changePermissionsAction, getUserDocumentsAction, toggleActiveAction } from "../../Actions/WorkerActions"
import { getSettingsUserCategoriesAction } from "../../Actions/SettingsActions"
import WorkerSigns from "./WorkerSigns"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import Permissions from "./Permissions"
import PermissionChecker from "../../Shared/Permissions/PermissionChecker"
import { filterActiveWorkers } from "../../Utils/filters"
import PermissionBoolean from "../../Shared/Permissions/PermissionBoolean"
import ReportMain from "./WorkerReports/ReportMain"
import MiniLoaderAnimator from "../../Shared/MiniLoaderAnimator"
import NewWorkerDocuments from "./NewWorkerDocuments"
import { getSubEnterprises, getWelcome } from "../../API/enterprise"
import WorkerRegisters from "./WorkerRegisters"
import WorkerBaseActivities from "./WorkerBaseActivities"
import { getPositions } from "../../API/users"
import { Box } from "@mui/material"
import { sendMail } from "../../API/notifications"
import { callSnackbar } from "../../Utils/snackbar"

const style = theme => ({
	container: {
		margin: 12,
	},
	title: {
		margin: "36px 0 12px 0",
	},
	button: {
		textAlign: "end",
		margin: "12px 0",
	},
	titleText: {
		padding: 12,
		background: theme.palette.primary.main,
		color: "white",
		marginTop: 12
	},
	sign: {
		padding: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		position: 'relative',
		width: 350,
		margin: 12,
		"@media (max-width:500px)": {
			width: '100%',
			margin: '12px 0',
		},
	},
	signs: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center'
	},
	greentag: {
		color: "white",
		background: theme.palette.green.main,
		borderRadius: 24,
		padding: "0px 6px",
		width: "50%",
		textAlign: "center",
		marginBottom: 6,
	},
	bluetag: {
		color: "white",
		background: theme.palette.blue.main,
		borderRadius: 24,
		padding: "0px 6px",
		width: "50%",
		textAlign: "center",
		marginBottom: 6,
	},
	actionButton: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	dialog: {
		padding: 24,
		'& > *': {
			margin: '12px 0'
		}
	},
	removeButton: {
		textAlign: 'end',
		marginTop: 24,
		'& > *': {
			color: theme.palette.red.main,
			borderColor: theme.palette.red.main
		}
	},
	ttResume: {
		background: 'white',
		padding: 12,
		borderRadius: 10,
		maxWidth: 250,
		marginBottom: 12,
		'& > *': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			margin: '6px 0'
		}
	},
	selectBox: {
		position: 'absolute',
		top: -18,
		left: -18
	},
	floatingButton: {
		background: `${theme.palette.purple.light}`,
		color: 'white',
		'&:hover': {
			background: `${theme.palette.purple.light}42`
		},
		transition: 'all 0.2s ease-in-out'
	},
	floatingButtonContainer: {
		transition: 'all 0.2s ease-in-out',
		position: 'fixed',
		top: '50%',
		right: 24,
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
})

function formatBaseText(data = {}) {
	const name = data.name || "Mati"
	const user = data.email || "mati.eynaudi@gmail.com"
	const baseText = `<p>${name},</p><p><br>Espero que estés muy bien. A continuación detallo los datos de&nbsp;acceso&nbsp;para&nbsp;<strong>Tazki</strong>:</p><p>Acceso:&nbsp;<a href="https://app1.safeasy.cl">Click Aquí</a></p><p>Usuario: ${user}</p><p>Clave:&nbsp;<span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">es tu </span><strong>Rut sin puntos, sin guión y sin dígito verificador</strong><span style="background-color:rgb(255,255,255);color:rgb(34,34,34);font-family:Arial, Helvetica, sans-serif;">.</span></p><p>Ejemplo: Si tu rut es 20.059.250-9 tu contraseña será:&nbsp;20059250</p><p>Al ingresar por primera vez, <strong>por favor realizar cambio de clave</strong>. Esto se realiza ingresando en la esquina superior derecha en el ícono de "usuario" y luego donde dice: "Visitar mi perfil". Una vez dentro, está el botón para hacer el <strong>cambio de clave</strong>.</p><p>Por otro lado, para instalar la aplicación en el teléfono hay que seguir los&nbsp;siguientes&nbsp;pasos:</p><p>Android: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-android.pdf">Descarga el instructivo aquí</a>.</p><p>Iphone IOs: <a href="https://tazki.s3.us-east-2.amazonaws.com/instalar-app-iphone.pdf">Descarga el instructivo aquí</a>.</p><p>De todas formas, siempre se va a poder&nbsp;acceder&nbsp;desde el navegador utilizando el link de&nbsp;acceso.</p>`
	return baseText
}

class Worker extends Component {
	constructor() {
		super()
		this.state = {
			tab: 5,
			params: {},
			pwdparams: {},
			openpwd: false,
			loading: true,
			loadingDocument: true,
			openWarning: false,
			selectedSigns: [],
			selectedElements: [],
			loadingDownload: false,
			sub_enterprises: [],
			positions: []
		}
		autobind(Worker, this)
	}

	async componentDidMount() {
		this.setState({ loading: true })
		const {
			match,
			getWorker,
			enterprise,
			getBranches,
			getWorkers,
			getTalks, getTrainings,
			getUserDocuments,
			getSettingsUserCategories
		} = this.props
		const body = {}
		body.companyId = enterprise.selected.id
		getBranches(body)
		getWorkers(body)
		const fullBody = { ...body }
		fullBody.id = match.params.id
		const documentBody = {
			user_id: match.params.id
		}
		getWorker(fullBody).then(() => { this.setState({ loading: false }) })
		getTalks()
		getTrainings()
		getUserDocuments(documentBody).then(() => this.setState({ loadingDocument: false }))
		getSettingsUserCategories()
		const response = await getSubEnterprises()
		const { data: { info: sub_enterprises } } = response
		const pos_response = await getPositions()
		const { data: { info: positions } } = pos_response
		this.setState({ sub_enterprises, positions })

	}

	handleReload() {
		this.componentDidMount()
	}

	componentDidUpdate(prevProps) {
		const { match, user } = this.props
		const level = user.account.user.userType
		const user_id = user.account.user.id
		if (level > 1 && match.params.id !== user_id) {
			window.location.href = "/"
		}
	}

	handleSend(values) {
		const { editWorker, user, match, workers } = this.props
		const worker = workers ? workers.selected : null
		const body = Object.assign({}, worker, values)
		body.companyId = user.account.user.idCompany
		body.id = match.params.id
		delete body.branch_id
		editWorker(body)
	}

	handleOpenWarning() {
		this.setState({ openWarning: !this.state.openWarning })
	}

	handleSelectSign(sign, element) {
		return () => {
			const { selectedSigns, selectedElements } = this.state
			if (selectedSigns.includes(sign)) {
				const index = selectedSigns.findIndex(s => s === sign)
				selectedSigns.splice(index, 1)
				selectedElements.splice(index, 1)
			} else {
				selectedSigns.push(sign)
				selectedElements.push(element)
			}
			this.setState({ selectedSigns })
		}
	}

	handleChange(event) {
		const { target } = event
		const { params } = this.state
		params[target.name] = target.value
		this.setState({ params })
	}

	handleChangeTab(event, value) {
		this.setState({ tab: value, selectedElements: [], selectedSigns: [] })
	}

	async handleWelcome() {
		const { workers } = this.props
		const worker = workers ? workers.selected : null
		if (!worker) return null
		const welcome_body = { branch_id: worker.branch_id, sub_enterprise_id: worker.sub_enterprise_id }
		const welcomes = await getWelcome(welcome_body)
		const welcome_item = welcomes.data.info
		const welcome = welcome_item.content
		const email_body = formatBaseText(worker) + welcome + `<p>Bienvenido!</p><p>Equipo Tazki</p>`
		sendMail({
			subject: `Bienvenido a Tazki`,
			content: email_body,
			emails: [worker.email],
			extra_mails: []
		})
		callSnackbar("Correo enviado correctamente", "success")
	}

	handleOpenPwd() {
		this.setState({ openpwd: !this.state.openpwd })
	}

	handleToggleActive() {
		const { toggleActive, workers } = this.props
		const worker = workers ? workers.selected : null
		const body = {
			active: worker.active === 1 ? 0 : 1,
			id: worker.id
		}
		toggleActive(body)
		this.setState({ openWarning: false })
	}

	handleChangePwd(event) {
		const { target } = event
		const { pwdparams } = this.state
		pwdparams[target.name] = target.value
		this.setState({ pwdparams })
	}

	async handleDownloadMultipleSigns() {
		const { selectedSigns: selectedSignsId, selectedElements, tab } = this.state
		const { user, workers } = this.props
		const currentWorker = workers ? workers.selected : null

		console.log(selectedElements)

		const promises = selectedElements.map(async (selectedEvent, index) => {
			const signId = selectedSignsId[index]

			const sign = selectedEvent.participants_signs.find(s => s.id.toString() === signId.toString())

			const file = selectedEvent.file

			let url1, url2, imgUrl, relatorSignUrl
			const relatorSign = selectedEvent?.user_sign[0]
			const selectedWorker = workers.all.find(worker => worker.id?.toString() === sign.user_id?.toString())
			const worker = workers ? workers.selected : null

			const relator = workers.all.find(worker => worker.id?.toString() === selectedEvent.user_id?.toString())


			if (tab === 2) {
				url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_PDF}?id=${selectedEvent.id}&u=${user.account.user.id}`
				url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file
				imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + sign.sign
				relatorSignUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + relatorSign?.sign
			} else {
				url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TRAININGS_PDF}?id=${selectedEvent.id}&u=${user.account.user.id}`
				url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER + "/" + file
				imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_SIGNS_FOLDER + '/' + sign.sign
				relatorSignUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_SIGNS_FOLDER + '/' + relatorSign?.sign
			}

			let signedDocument
			if (file.includes(".pdf")) {
				signedDocument = await mergeMultiplePdfFilesAndGet([url1, url2], "Reporte Charla")
			} else {
				signedDocument = await mergeMultiplePdfFilesAndGet([url1], "Reporte Charla")
			}

			const officialSign = { ...sign, name: worker?.name }

			const finalDoc = await signDocument(signedDocument, officialSign, imgUrl, relatorSign, relatorSignUrl, `Charla ${sign.name}`, selectedWorker, relator, true)
			const code = `${selectedEvent.id}--${tab === 2 ? "k" : "t"}`
			const validateUrl = `${window.location.origin}/validate/${code}`
			return addQrToFile(finalDoc, `${tab === 2 ? "Charla" : "Capacitación"} ${selectedEvent.name} - ${sign.name}`, validateUrl, true)
		})

		this.setState({ loadingDownload: true })
		const pdfs = await Promise.all(promises)
		mergeMultiplePdfBytesFiles(pdfs, currentWorker?.name)
		this.setState({ loadingDownload: false, selectedElements: [], selectedSigns: [] })
	}

	handleSendPwd() {
		const { pwdparams } = this.state
		const { workers, changePassword } = this.props
		const newpassword = sha512(pwdparams.newpassword)
		const newpasswordconfirm = sha512(pwdparams.newpasswordconfirm)
		const worker = workers.selected
		const body = {
			newpassword,
			newpasswordconfirm,
			pwt: worker.pwt,
			email: worker.email,
			companyId: worker.companyId,
			id: worker.id,
		}
		changePassword(body)
		this.setState({ pwdparams: {}, openpwd: false })
	}

	setFormInfo(info) {
		const { enterprise } = this.props
		const { sub_enterprises, positions } = this.state
		const filteredWorkers = filterActiveWorkers()
		const final_info = [...info]
		final_info.map((input) => {
			switch (input.name) {
				case "branch_ids": {
					input.options = transformToOptions(enterprise.branches)
					return input
				}
				case "user_id": {
					input.options = transformToOptions(filteredWorkers)
					return input
				}
				case "sub_enterprise_id": {
					input.options = transformToOptions(sub_enterprises)
					return input
				}
				case "position_id": {
					input.options = transformToOptions(positions)
					return input
				}
				default:
					return input
			}
		})
		return final_info
	}

	renderSings(signs, type) {
		const { classes, talks, trainings, user, workers } = this.props
		const { selectedSigns } = this.state

		const worker = workers ? workers.selected : null

		let imgUrl = ""
		if (type === "talks") {
			imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER
		} else {
			imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_SIGNS_FOLDER
		}

		function goto(id, own) {
			return () => {
				const { history } = own.props
				history.push(`/${type}/${id}`)
			}
		}

		const allTalks = talks.all || []
		const allTrainings = trainings.all || []

		return signs.map(sign => {

			let selectedEvent
			if (type === "talks") {
				selectedEvent = allTalks.find(talk => talk.id === sign.el_id)
			} else {
				selectedEvent = allTrainings.find(talk => talk.id === sign.el_id)
			}

			async function startSignDocument() {
				const file = selectedEvent.file

				let url1, url2, imgUrl, relatorSignUrl
				const relatorSign = selectedEvent?.user_sign[0]
				const selectedWorker = workers.all.find(worker => worker.id?.toString() === sign.user_id?.toString())

				const relator = workers.all.find(worker => worker.id?.toString() === selectedEvent.user_id?.toString())


				if (type === "talks") {
					url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TALKS_PDF}?id=${selectedEvent.id}&u=${user.account.user.id}`
					url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_DOCUMENT_FOLDER + "/" + file
					imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + sign.sign
					relatorSignUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TALKS_SIGNS_FOLDER + '/' + relatorSign?.sign
				} else {
					url1 = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_TRAININGS_PDF}?id=${selectedEvent.id}&u=${user.account.user.id}`
					url2 = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER + "/" + file
					imgUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_SIGNS_FOLDER + '/' + sign.sign
					relatorSignUrl = process.env.REACT_APP_IMG_URL + process.env.REACT_APP_TRAININGS_SIGNS_FOLDER + '/' + relatorSign?.sign
				}

				let signedDocument
				if (file.includes(".pdf")) {
					signedDocument = await mergeMultiplePdfFilesAndGet([url1, url2], "Reporte Charla")
				} else {
					signedDocument = await mergeMultiplePdfFilesAndGet([url1], "Reporte Charla")
				}

				const officialSign = { ...sign, name: worker?.name }

				const finalDoc = await signDocument(signedDocument, officialSign, imgUrl, relatorSign, relatorSignUrl, `Charla ${sign.name}`, selectedWorker, relator, true)
				const code = `${selectedEvent.id}--${type === "talks" ? "k" : "t"}`
				const validateUrl = `${window.location.origin}/validate/${code}`
				return addQrToFile(finalDoc, `${type === "talks" ? "Charla" : "Capacitación"} ${selectedEvent.name} - ${sign.name}`, validateUrl)
			}

			return (
				<Paper className={classes.sign} key={sign.id}>
					{!sign.isOwner &&
						<Checkbox
							className={classes.selectBox}
							icon={<Brightness1Outlined />}
							checkedIcon={<CheckCircle />}
							onClick={this.handleSelectSign(sign.id, selectedEvent)}
							checked={selectedSigns.includes(sign.id)}
						/>
					}
					<div>
						<Typography className={sign.isOwner ? classes.greentag : classes.bluetag} variant="subtitle2">{sign.isOwner ? "Supervisor" : "Asistente"}</Typography>
						<ImageContainer src={`${imgUrl}/${sign.sign}`} width="150px" height="75px" />
					</div>
					<div className={classes.infoContainer}>
						<Typography variant="body1">Fecha: {sign.date}</Typography>
						<Typography variant="body1">Tipo: {sign.name}</Typography>
						<Typography variant="body1">Tema: {sign.category_name}</Typography>
					</div>
					<div className={classes.actionButton}>
						<IconButton color="primary" onClick={goto(sign.el_id, this)}>
							<KeyboardArrowRight />
						</IconButton>
						{!sign.isOwner &&
							<IconButton color="primary" onClick={startSignDocument}>
								<GetApp />
							</IconButton>}
					</div>
				</Paper>
			)
		})

	}

	render() {
		const { classes, workers, talks, trainings, user, match, changePermissions } = this.props
		const { openpwd, pwdparams, tab, loading, loadingDocument, openWarning, selectedElements, loadingDownload, sub_enterprises } = this.state
		const worker = workers ? workers.selected : null
		worker.sub_enterprise_id = String(worker.sub_enterprise_id)
		const level = user.account.user.userType
		const isSameUser = user.account.user.id === match.params.id
		const allTalks = talks.all || []
		const allTrainings = trainings.all || []
		const allTalksSigns = allTalks
			.reduce(
				(x, y) => x
					.concat(y.participants_signs.map(s => ({ ...s, isOwner: false, name: y.name, category_name: y.category_name, el_id: y.id })))
					.concat(y.user_sign.map(s => ({ ...s, isOwner: true, name: y.name, category_name: y.category_name, el_id: y.id }))),
				[]
			)
		const allTrainingsSigns = allTrainings
			.reduce(
				(x, y) => x
					.concat(y.participants_signs.map(s => ({ ...s, isOwner: false, name: y.name, category_name: y.category_name, el_id: y.id })))
					.concat(y.user_sign.map(s => ({ ...s, isOwner: true, name: y.name, category_name: y.category_name, el_id: y.id }))),
				[]
			)


		let myTalksSigns = []
		let myTrainingsSigns = []

		if (worker) {
			myTalksSigns = allTalksSigns.filter(sign => sign?.user_id?.toString() === worker?.id?.toString())
			myTrainingsSigns = allTrainingsSigns.filter(sign => sign?.user_id?.toString() === worker?.id?.toString())
		}


		return (
			<div className={classes.container}>
				<MutableInfoCard
					title="Datos Colaborador"
					submit="Guardar Cambios"
					subtitle="Editar Colaborador"
					submitAction={this.handleSend}
					onChange={this.handleChange}
					formInfo={this.setFormInfo(userEditInfo)}
					showInfo={this.setFormInfo(userEditInfo)}
					response={worker}
					loading={loading}
					disableEdit={level > 1 || !PermissionBoolean(["ew"])}

				/>
				<Box className={classes.buttons}>
					<Button color="secondary" variant="outlined" endIcon={<Send />} onClick={this.handleWelcome}>
						Enviar instructivo de ingreso al correo
					</Button>
					{(level === 1 || isSameUser) &&
						<div className={classes.button}>
							<Button
								color="primary"
								variant="outlined"
								size="small"
								onClick={this.handleOpenPwd}
							>
								Cambiar Contraseña
							</Button>
						</div>
					}
				</Box>
				<FormContext.Provider value={{ handleChange: this.handleChangePwd, params: pwdparams }}>
					<GeneralForm
						open={openpwd}
						info={pwdForm}
						title="Cambiar Contraseña"
						submit="Cambiar"
						onSubmit={this.handleSendPwd}
					/>
				</FormContext.Provider>
				<PermissionChecker expectedPermissions={["sa"]}>
					{level === 1 &&
						<Permissions user={worker} changePermissions={changePermissions} />
					}
				</PermissionChecker>
				<WorkerSigns reload={this.handleReload} />
				<Paper square className={classes.title}>
					<MyTabs value={tab} onChange={this.handleChangeTab}>
						<Tab
							label={
								<Typography variant="h1">
									Charlas
								</Typography>
							}
							value={0}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Capacitaciones
								</Typography>
							}
							value={1}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Documentos
								</Typography>
							}
							value={2}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Resumen
								</Typography>
							}
							value={3}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Registros
								</Typography>
							}
							value={4}
						/>
						<Tab
							label={
								<Typography variant="h1">
									Documentación Obligatoria
								</Typography>
							}
							value={5}
						/>
						{/* <Tab
							label={
								<Typography variant="h1">
									Firmas
								</Typography>
							}
							value={6}
						/> */}
					</MyTabs>
				</Paper>
				<TabPanel value={tab} index={0}>
					<Typography variant="h1" className={classes.title}>
						Participación en Charlas
					</Typography>
					<div style={{ right: selectedElements.length === 0 ? -50 : 24 }} className={classes.floatingButtonContainer}>
						<Badge badgeContent={selectedElements.length} color="primary">
							<IconButton disabled={loadingDownload} color="primary" onClick={this.handleDownloadMultipleSigns} className={classes.floatingButton}>
								{!loadingDownload ? <GetApp /> : <MiniLoaderAnimator />}
							</IconButton>
						</Badge>
					</div>
					<div className={classes.ttResume}>
						<div>
							<Typography variant="subtitle1">Participación total charlas: </Typography>
							<Typography variant="subtitle1" style={{ fontWeight: 600 }}>{myTalksSigns.length}</Typography>
						</div>
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ height: 18, marginRight: 6, width: 18, borderRadius: '50%', background: "#7373e8" }} />
								<Typography variant="subtitle1">Como asistente: </Typography>
							</div>
							<Typography variant="subtitle1" style={{ color: "#7373e8", fontWeight: 600 }}>
								{myTalksSigns.filter(sign => !sign.isOwner).length}
							</Typography>
						</div>
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ height: 18, marginRight: 6, width: 18, borderRadius: '50%', background: "#91deb1" }} />
								<Typography variant="subtitle1">Como supervisor: </Typography>
							</div>
							<Typography variant="subtitle1" style={{ color: "#91deb1", fontWeight: 600 }}>
								{myTalksSigns.filter(sign => sign.isOwner).length}
							</Typography>
						</div>
					</div>
					<Divider style={{ marginBottom: 24 }} />
					<div className={classes.signs}>
						{this.renderSings(myTalksSigns, 'talks')}
					</div>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Typography variant="h1" className={classes.title}>
						Participación en Capacitaciones
					</Typography>
					<div style={{ right: selectedElements.length === 0 ? -50 : 24 }} className={classes.floatingButtonContainer}>
						<Badge badgeContent={selectedElements.length} color="primary">
							<IconButton disabled={loadingDownload} color="primary" onClick={this.handleDownloadMultipleSigns} className={classes.floatingButton}>
								{!loadingDownload ? <GetApp /> : <MiniLoaderAnimator />}
							</IconButton>
						</Badge>
					</div>
					<div className={classes.ttResume}>
						<div>
							<Typography variant="subtitle1">Participación total capacitaciones: </Typography>
							<Typography variant="subtitle1" style={{ fontWeight: 600 }}>{myTrainingsSigns.length}</Typography>
						</div>
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ height: 18, marginRight: 6, width: 18, borderRadius: '50%', background: "#7373e8" }} />
								<Typography variant="subtitle1">Como asistente: </Typography>
							</div>
							<Typography variant="subtitle1" style={{ color: "#7373e8", fontWeight: 600 }}>
								{myTrainingsSigns.filter(sign => !sign.isOwner).length}
							</Typography>
						</div>
						<div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ height: 18, marginRight: 6, width: 18, borderRadius: '50%', background: "#91deb1" }} />
								<Typography variant="subtitle1">Como supervisor: </Typography>
							</div>
							<Typography variant="subtitle1" style={{ color: "#91deb1", fontWeight: 600 }}>
								{myTrainingsSigns.filter(sign => sign.isOwner).length}
							</Typography>
						</div>
					</div>
					<Divider style={{ marginBottom: 24 }} />
					<div className={classes.signs}>
						{this.renderSings(myTrainingsSigns, "trainings")}
					</div>
				</TabPanel>
				<TabPanel value={tab} index={2}>
					<Typography variant="h1" className={classes.title}>
						Documentos
					</Typography>
					<Divider style={{ marginBottom: 24 }} />
					{loadingDocument ?
						<div style={{ position: 'relative', height: 200 }}>
							<LoaderAnimator />
						</div>
						:
						// <WorkerDocuments categories={allCategories} documents={[]} />
						<NewWorkerDocuments />
					}
				</TabPanel>
				<TabPanel value={tab} index={3}>
					<ReportMain />
				</TabPanel>
				<TabPanel value={tab} index={4}>
					<WorkerRegisters id={match.params.id} />
				</TabPanel>
				<TabPanel value={tab} index={5}>
					<WorkerBaseActivities user_id={match.params.id} />
				</TabPanel>
				<TabPanel value={tab} index={6}>
					<Typography variant='h1'>Firmas</Typography>
				</TabPanel>
				<div className={classes.removeButton}>
					<Button className={classes.warning} variant="outlined" onClick={this.handleOpenWarning}>
						{worker.active === 0 ? "Desactivar Colaborador" : "Reactivar Colaborador"}
					</Button>
				</div>
				<Dialog open={openWarning} onClose={this.handleOpenWarning}>
					<div className={classes.dialog}>
						<Typography variant="h1">{worker.active === 0 ? "Desactivar" : "Reactivar"} a {worker.name}</Typography>
						<Typography variant="subtitle1">
							¿Seguro que deseas {worker.active === 0 ? "desactivar" : "reactivar"} este colaborador?
						</Typography>
						<div className={classes.removeButton}>
							<Button style={{ color: "grey", marginRight: 12 }} onClick={this.handleOpenWarning}>
								Cancelar
							</Button>
							<Button className={classes.warning} variant="outlined" onClick={this.handleToggleActive}>
								{worker.active === 0 ? "Desactivar" : "Reactivar"}
							</Button>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}

Worker.propTypes = {}

Worker.defaultProps = {
	workers: { all: [] },
}

const mapStateToProps = (state) => ({
	workers: state.workers,
	enterprise: state.enterprise,
	user: state.user,
	talks: state.talks,
	trainings: state.trainings,
	settings: state.settings,
	branch: state.branch,
})

const mapDispatchToProps = (dispatch) => ({
	getWorker: (body) => dispatch(getWorkerAction(body)),
	editWorker: (body) => dispatch(editWorkerAction(body)),
	getBranches: (body) => dispatch(getBranches(body)),
	getWorkers: (body) => dispatch(getWorkersAction(body)),
	changePassword: (body) => dispatch(changePasswordAction(body)),
	getTalks: () => dispatch(getTalksAction()),
	getTrainings: () => dispatch(getTrainingsAction()),
	getUserDocuments: (body) => dispatch(getUserDocumentsAction(body)),
	getSettingsUserCategories: () => dispatch(getSettingsUserCategoriesAction()),
	changePermissions: body => dispatch(changePermissionsAction(body)),
	toggleActive: body => dispatch(toggleActiveAction(body))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Worker))
