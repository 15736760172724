import { Button, Collapse, Divider, IconButton, Paper, Typography, withStyles, withTheme } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import React, { Component } from "react"
import { connect } from "react-redux"
import { approveNonConformityAction, assignNonConformityAction, dismissNonConformityAction, selectNonConformityAction, solveNonConformityAction } from "../../Actions/NoConfActions"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import ImageContainer from "../../Shared/ImageContainer"
import autobind from "../../Utils/autobind"
import ExtraFieldContainer from "../Finding&Conformities/ExtraFieldContainer"
import NonConformityAction from "../Findings/FindingAction"
import NoCheck from "../Findings/NoCheck"

const style = theme => ({
    container: {
        margin: 12,
    },
    statusTag: {
        padding: "6px 12px",
        borderRadius: 24,
        width: 120,
        textAlign: 'center',
        color: 'white'
    },
    statusTagContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 24
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            marginLeft: 12
        },
        marginTop: 24
    },
    evidence: {
        marginTop: 24,
        maxWidth: 400,
        width: 400,
        padding: 12,
        position: 'relative',
        height: 114
    },
    evidenceTitle: {
        textAlign: 'center'
    },
    evidenceImage: {
        position: 'absolute',
        top: "60%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    evidences: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            marginRight: 24,
            "@media (max-width:500px)": {
                marginRight: 0
            },
        }
    },
    titleContainer: {
        padding: 12,
        background: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 24
    },
    title: {
        color: "white"
    },
    titleButton: {
        color: "white"
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 12,
        flexWrap: 'wrap'
    },
    info: {
        width: 300,
        margin: 12,
        textAlign: 'center',
        "@media (max-width:500px)": {
            width: "100%",
            maxWidth: 'unset'
        },
    }
})

function getColor(status, theme) {
    switch (status) {
        case "Pendiente": return theme.palette.red.main
        case "Procesando": return theme.palette.blue.main
        case "Vencido": return theme.palette.red.main
        case "Terminado": return theme.palette.green.main
        case "Aprobado": return theme.palette.green.main
        case "Desestimado": return theme.palette.red.main
        default: return theme.palette.red.main
    }
}

const criticalityFormat = (level) => {
    switch (level) {
        case 0: { return "No Asignado" }
        case 1: { return 'No Critico' }
        case 2: { return "Poco Critico" }
        case 3: { return "Medianamente Critico" }
        case 4: { return "Altamente Critico" }
        default: { return "No Asignado" }
    }
}

function getImage(path) {
    if (path.includes("data:image")) {
        return path
    } else {
        return `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_NON_CONFORMITIES}${path}`
    }
}

const showInfo = [
    { name: "user_creator", label: "Usuario quien detectó" },
    { name: "name", label: "Asunto" },
    { name: "description", label: "Descripción" },
    { name: "criticality", label: "Criticidad", format: criticalityFormat },
    { name: "date_detected", label: "Fecha de detección" }
]

class NonConformity extends Component {
    constructor() {
        super()
        this.state = {
            openCollapse: false
        }
        autobind(NonConformity, this)
    }

    componentDidMount() {
        const { selectNonConformity, match } = this.props
        const id = match.params.id
        selectNonConformity(id)
    }

    handleApprove() {
        const { approveNonConformity, unconformities, online } = this.props
        const selected = unconformities.selected
        const body = {
            id: selected.id,
            status: 1,
            online: online.status
        }
        approveNonConformity(body)
    }

    handleDismiss() {
        const { dismissNonConformity, unconformities, online } = this.props
        const selected = unconformities.selected
        const body = {
            id: selected.id,
            status: 4,
            online: online.status
        }
        dismissNonConformity(body)
    }

    handleOpenCollapse() {
        this.setState({ openCollapse: !this.state.openCollapse })
    }

    render() {
        const { classes, unconformities, assignNonConformity, solveNonConformity, theme, enterprise, user } = this.props
        const selected = unconformities.selected

        const selectedId = enterprise?.selected?.id

        const { openCollapse } = this.state
        return (
            <div className={classes.container}>
                <div className={classes.statusTagContainer}>
                    <Typography
                        className={classes.statusTag}
                        style={{ background: getColor(selected.status, theme) }}
                        variant="body1"
                    >
                        {selected.status}
                    </Typography>
                </div>
                <MutableInfoCard
                    title={`Información ${selectedId === 14 ? "IPA" : "No Conformidad"}`}
                    disableEdit
                    showInfo={showInfo}
                    formInfo={showInfo}
                    response={selected}

                />
                <div className={classes.evidences}>
                    <Paper className={classes.evidence}>
                        <Typography className={classes.evidenceTitle} variant="subtitle1">Evidencia</Typography>
                        <div className={classes.evidenceImage}>
                            <ImageContainer src={selected.evidence ? `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_NON_CONFORMITIES}${selected.evidence}` : "/noimage.png"} alt="foto" height="64px" width="64px" />
                        </div>
                    </Paper>
                    <Paper className={classes.evidence}>
                        <Typography className={classes.evidenceTitle} variant="subtitle1">Evidencia de solución</Typography>
                        <div className={classes.evidenceImage}>
                            <ImageContainer src={selected.solution_evidence ? getImage(selected.solution_evidence) : "/noimage.png"} alt="foto" height="64px" width="64px" />
                        </div>
                    </Paper>
                </div>
                <ExtraFieldContainer element={selected} isConformity />
                <Paper >
                    <div className={classes.titleContainer}>
                        <Typography variant="h2" className={classes.title}>
                            Información Avanzada
                        </Typography>
                        <IconButton onClick={this.handleOpenCollapse} className={classes.titleButton} size="small">
                            <Edit />
                        </IconButton>
                    </div>
                    <div className={classes.infoContainer}>
                        <div className={classes.info}>
                            <Typography variant="body2">Responsable</Typography>
                            <Typography variant="subtitle1">{selected.user}</Typography>
                        </div>
                        <div className={classes.info}>
                            <Typography variant="body2">Acción Inmediata</Typography>
                            <Typography variant="subtitle1">{selected.inmediate_action}</Typography>
                        </div>
                        <div className={classes.info}>
                            <Typography variant="body2">Fecha Límite</Typography>
                            <Typography variant="subtitle1">{selected.date_limit}</Typography>
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.infoContainer}>
                        <div className={classes.info}>
                            <Typography variant="body2">Comentario de Ŕesolución</Typography>
                            <Typography variant="subtitle1">{selected.solution_comment || "No hay comentario..."}</Typography>
                        </div>
                    </div>
                </Paper>
                <Collapse in={openCollapse} >
                    <NoCheck assignFinding={assignNonConformity} finding={selected} />
                    <NonConformityAction solveFinding={solveNonConformity} finding={selected} />
                </Collapse>
                <div className={classes.buttons}>
                    {selected.status !== "Desestimado" && user.account.user.userType === 1 &&
                        <Button color="primary" variant="outlined" onClick={this.handleDismiss}>
                            Desestimar
                        </Button>
                    }
                    {selected.status !== "Aprobado" && user.account.user.userType === 1 &&
                        <Button color="primary" variant="contained" onClick={this.handleApprove} disabled={
                            selected.status === "Procesando" ||
                            selected.status === "Pendiente" ||
                            selected.status === "Vencido"
                        }>
                            Aprobar
                        </Button>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    unconformities: state.unconformities,
    online: state.online,
    enterprise: state.enterprise,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    selectNonConformity: (id) => dispatch(selectNonConformityAction(id)),
    assignNonConformity: (body) => dispatch(assignNonConformityAction(body)),
    solveNonConformity: (body, data) => dispatch(solveNonConformityAction(body, data)),
    dismissNonConformity: (body) => dispatch(dismissNonConformityAction(body)),
    approveNonConformity: (body) => dispatch(approveNonConformityAction(body))
})

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(NonConformity)))