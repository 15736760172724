import { Typography } from '@material-ui/core'
import { Box, Tabs, Tab, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { createWorkflow, getSettingsWorkflows, getWorkflows } from '../../API/workflows'
import AddElementButton from '../../Shared/AddElementButton'
import AddWorkflow from './AddWorkflow'
import WorkflowCard from './WorkflowCard'

const css = {
	container: {
		margin: 2,
	},
	tabs: {
		borderRadius: 1,
		margin: '24px 0'
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}

const tabs = [
	{ name: "Accidentes", value: 1 },
	{ name: "Incidentes", value: 2 },
	{ name: "Reclamos", value: 3 },
	{ name: "Generales", value: 5 },
]

function renderCard(workflow) { return <WorkflowCard workflow={workflow} /> }

function WorkflowsContainer() {
	const [workflows, setWorkflows] = useState([])
	const [base_workflows, setBases] = useState([])
	const [selectedTab, selectTab] = useState(1)
	const [openCreate, setOpenCreate] = useState(false)

	function onSelectTab(e, value) {
		selectTab(value)
	}

	async function onCreate(body) {
		body.workflow_type_id = selectedTab
		const response = await createWorkflow(body)
		const { data: { info } } = response
		setWorkflows(info)
	}

	useEffect(() => {
		async function fetchData() {
			const responses = await Promise.all([getWorkflows(), getSettingsWorkflows()])
			const [workflowsInfo, workflowBases] = responses.map(response => response.data.info)
			setWorkflows(workflowsInfo)
			setBases(workflowBases)
		}
		fetchData()
		window.addEventListener("branch_change", fetchData)
	}, [])

	const tabName = tabs.find(t => t.value === selectedTab).name
	const filtered = workflows.filter(workflow => workflow.workflow_type_id === selectedTab)
	const filteredBases = base_workflows.filter(base => base.workflow_type_id === selectedTab)

	return (
		<Box sx={css.container}>
			<Paper sx={css.tabs}>
				<Tabs onChange={onSelectTab} value={selectedTab}>
					{tabs.map(tab => <Tab value={tab.value} label={<Typography variant="h2">{tab.name}</Typography>} />)}
				</Tabs>
			</Paper>
			<Box sx={css.title}>
				<Typography variant='h1'>{tabName}</Typography>
				<AddElementButton name="Agregar proceso" open={openCreate} onClick={() => setOpenCreate(!openCreate)} />
			</Box>
			<AddWorkflow
				open={openCreate}
				type={selectedTab}
				bases={filteredBases}
				onCreate={onCreate}
			/>
			{filtered.map(renderCard)}
		</Box>
	)
}

export default WorkflowsContainer