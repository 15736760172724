import { Box } from '@mui/material'
import { useContext } from 'react'
import AppContext from './AppContext'

function OfflineRequestManager({ value }) {
	const { online: { status: online } } = useContext(AppContext)

	return (
		<Box>
		</Box>
	)
}

export default OfflineRequestManager