import React, { Component } from 'react'
import { Checkbox, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import SubmitButton from '../../../../Shared/SubmitButton'
import autobind from '../../../../Utils/autobind'

const style = (theme) => ({
  container: {
    '& > h2': {
      marginBottom: 12
    }
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center'
  },
  optionsCreated: {
    background: theme.palette.purple.light,
    borderRadius: 7,
    display: 'grid'
  }
})

const fields = [
  { label: "Texto", value: "text" },
  { label: "RUT", value: "rut" },
  { label: "Texto enriquecido", value: "rte" },
  { label: "Lista con opciones", value: "select" },
  { label: "Selección Múltiple", value: "multiselect" },
  { label: "Imagen", value: "img" },
  { label: "Cumplimiento", value: "compliance" },
  { label: "IDS (Experimental)", value: "ids" }
]

class AddExtraField extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(AddExtraField, this)
  }

  componentDidMount() {
    const { field } = this.props
    console.log(field)
    const baseParams = { ...field }
    this.setState({ params: baseParams })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangeRequired() {
    const { params } = this.state
    params.required = !params.required
    this.setState({ params })
  }

  handleEdit() {
    const { onEdit } = this.props
    const { params } = this.state
    const body = { ...params }
    onEdit(body)
  }

  render() {
    const { classes } = this.props
    const { params } = this.state
    const isCompliance = params.type === "compliance"
    return (
      <div className={classes.container}>
        <Typography variant="h4">Editar Pregunta</Typography>
        <div className={classes.addExtra}>
          <div className={classes.extraField}>
            <TextInput label="Nombre Campo" onChange={this.handleChange} name="label" value={params.label} />
          </div>
          <div className={classes.extraField}>
            <SelectInput label="Tipo" options={fields} onChange={this.handleChange} name="type" value={params.type} />
          </div>
        </div>
        <div className={classes.checkbox}>
          <Checkbox checked={!!params.required} onChange={this.handleChangeRequired} name="required" />
          <Typography variant="subtitle1"> Campo Obligatorio</Typography>
        </div>
        {isCompliance &&
          <div className={classes.extraField}>
            <TextInput label="Titulo caso desfavorable" onChange={this.handleChange} name="compliance_case" value={params.compliance_case} />
            <TextInput label="Titulo comentario incumplimiento" onChange={this.handleChange} name="compliance_observation" value={params.compliance_observation} />
          </div>
        }
        <div style={{ marginTop: 12 }}>
          <SubmitButton color="primary" onClick={this.handleEdit} disabled={!params.label || !params.type}>
            Guardar cambios
          </SubmitButton>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(AddExtraField)