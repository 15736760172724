import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getAllTalks = (body) => {
  return instance2({
    method: 'get',
    url: 'talks/get.php',
    params: body
  })
}

export const getSettingsTalks = () => {
  return instance2({
    method: 'get',
    url: 'settings/talks/get.php',
  })
}

export const createSettingTalk = body => {
  return instance2({
    method: 'post',
    url: 'settings/talks',
    data: body
  })
}

export const editSettingTalk = body => {
  return instance2({
    method: 'put',
    url: 'settings/talks',
    data: body
  })
}

export const getTalksCategories = () => {
  return instance2({
    method: 'get',
    url: 'settings/talks/get_category.php'
  })
}

export const createTalkCategory = body => {
  return instance2({
    method: 'post',
    url: 'settings/talks/category',
    data: body
  })
}

export const editTalk = body => {
  return instance({
    method: 'post',
    url: 'talks/edit.php',
    data: body
  })
}

export const editTalkContent = body => {
  return instance({
    method: 'post',
    url: 'talks/content.php',
    data: body
  })
}

export const editTalkParticipants = body => {
  return instance({
    method: 'post',
    url: 'talks/add_participants.php',
    data: body
  })
}

export const signTalkParticipant = body => {
  return instance({
    method: 'post',
    url: 'talks/sign.php',
    data: body
  })
}

export const signResponsibleTalk = body => {
  return instance({
    method: 'post',
    url: 'talks/user_sign.php',
    data: body
  })
}

export const endTalk = body => {
  return instance({
    method: 'post',
    url: 'talks/new_end.php',
    data: body
  })
}

export const uploadTalkFile = body => {
  return instance2({
    method: 'post',
    url: 'talks/upload_file',
    data: body
  })
}

export const deleteTalk = body => {
  return instance2({
    method: 'post',
    url: 'talks/delete.php',
    data: body
  })
}

export const createTalk = body => {
  return instance2({
    method: 'post',
    url: 'talks/create.php',
    data: body
  })
}

export const createMultipleTalk = body => {
  return instance({
    method: 'post',
    url: 'talks/create_multiple.php',
    data: body
  })
}