import { Box, Paper } from '@mui/material'
import { useContext, useState } from 'react'
import { ChecklistContext } from '../ChecklistView'
import SignGroup from './SignGroup'

const css = {
	paper: {
		padding: 2
	}
}

function SignsGroups({ value }) {

	const [selectedGroup, setGroup] = useState(null)

	const { checklist } = useContext(ChecklistContext)
	const groups = checklist?.group_signs || []

	function onSelect(group) {
		if (selectedGroup === group) return setGroup(null)
		setGroup(group)
	}

	return (
		<Box>
			<Paper sx={css.paper}>
				{groups.map(group => <SignGroup onSelect={onSelect} selected={selectedGroup} group={group} />)}
			</Paper>
		</Box>
	)
}

export default SignsGroups