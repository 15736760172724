import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import DonutChart from '../../../Shared/Charts/DonutChart'

const style = () => ({
  dot: {
    paddingLeft: 16,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 35,
    maxWidth: 50,
    '&.green ::before ': {
      content: '""',
      background: '#91deb1',
      borderRadius: '50%',
      height: 12,
      width: 12,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate(0, -50%)'
    },
    '&.red ::before': {
      content: '""',
      background: '#ff6c87',
      borderRadius: '50%',
      height: 12,
      width: 12,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate(0, -50%)'
    }
  }

})

class ItemCard extends Component {

  render() {
    const { classes, item, data = {} } = this.props
    return (
      <>
        <Typography variant="subtitle1" style={{ maxHeight: 100, overflow: 'auto' }}>{item.name}</Typography>
        <div>
          <div style={{ width: 200, margin: 'auto' }}>
            <DonutChart data={[data.yes, data.no]} name={item.id} />
          </div>
          <div>
            <div className={`${classes.dot} green`}>
              <Typography variant="subtitle1">
                Si:
              </Typography>
              <Typography variant="subtitle1">
                {data.yes}
              </Typography>
            </div>
            <div className={`${classes.dot} red`}>
              <Typography variant="subtitle1">
                No:
              </Typography>
              <Typography variant="subtitle1">
                {data.no}
              </Typography>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withStyles(style)(ItemCard)