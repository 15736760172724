import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

const style = () => ({

})
class PermissionChecker extends Component {

  render() {
    const { classes, expectedPermissions, user, workers, element } = this.props
    const actualUser = user?.account?.user
    const allWorkers = workers.all || []
    const actualWorker = allWorkers.find(w => w.id === actualUser.id)


    // Check if responsible for element
    if (element) {
      if (element?.user_id?.toString() === actualUser.id.toString()) return (
        <div className={classes.container}>
          {this.props.children}
        </div>
      )
    }

    const passPermissions = expectedPermissions
      .map(expectedType => !actualWorker?.permissions?.includes(expectedType))
      .filter(Boolean)
      .length > 0
    if (!passPermissions) return null
    return (
      <div className={classes.container}>
        {this.props.children}
      </div>
    )
  }
}

PermissionChecker.propTypes = {

}


PermissionChecker.defaultProps = {
  expectedPermissions: [],
  element: { user_id: "" }
}

const mapStateToProps = state => ({
  workers: state.workers,
  user: state.user
})

export default connect(mapStateToProps)(withStyles(style)(PermissionChecker))