import React, { Component } from 'react'
import { Checkbox, Collapse, Dialog, Typography, withStyles } from '@material-ui/core'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import autobind from '../../../../Utils/autobind'
import TextStepCreate from './NewSteps/TextStepCreate'
import SubmitButton from '../../../../Shared/SubmitButton'
import { withRouter } from 'react-router'
import SelectStepCreate from './NewSteps/SelectStepCreate'
import CheckStepCreate from './NewSteps/CheckStepCreate'

const style = () => ({
  container: {
    padding: 12
  },
  sameLine: {
    display: 'flex',
    alignItems: 'center'
  }
})
class EditPhaseDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(EditPhaseDialog, this)
  }

  componentDidMount() {
    const { phase } = this.props
    if (phase === null) return true
    const params = { ...phase.data }
    this.setState({ params })
  }

  componentDidUpdate(prevProps) {
    const { phase } = this.props
    if (phase !== prevProps.phase) {
      if (phase === null) return true
      const params = { ...phase.data }
      this.setState({ params })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  renderContinuation() {
    const { params } = this.state
    switch (params.type) {
      case "text": {
        return <TextStepCreate params={params} onChange={this.handleChange} />
      }
      case "select": {
        return <SelectStepCreate params={params} onChange={this.handleChange} />
      }
      case "multiselect": {
        return <SelectStepCreate params={params} onChange={this.handleChange} />
      }
      case "number": {
        return <TextStepCreate params={params} onChange={this.handleChange} noImage />
      }
      case "check": {
        return <CheckStepCreate params={params} onChange={this.handleChange} />
      }
      case "file": {
        return <TextStepCreate params={params} onChange={this.handleChange} noImage />
      }
      default: {
        return <TextStepCreate params={params} onChange={this.handleChange} noImage />
      }
    }
  }

  handleCreatePhase() {
    const { onSubmit, stage, match, phase } = this.props
    const { params } = this.state

    const body = {
      id: phase.id,
      step_id: stage.id,
      workflow_id: match.params.id,
      phase_data: { ...params }
    }
    onSubmit(body)
    this.handleClose()

  }

  handleClose() {
    const { onClose } = this.props
    this.setState({ params: {} })
    onClose()
  }

  render() {
    const { classes, open } = this.props
    const { params } = this.state
    return (
      <Dialog open={open} onClose={this.handleClose} fullWidth maxWidth="sm">
        <div className={classes.container}>
          <Typography variant="h3">Nueva fase</Typography>
          <SelectInput onChange={this.handleChange} value={params.type} name="type" label="Tipo de fase" options={[
            { label: "Texto", value: 'text' },
            { label: "Texto Enriquecido (word)", value: 'rte' },
            { label: "Seleccionar una opción", value: "select" },
            { label: "Selección Múltiple", value: "multiselect" },
            { label: "Campo numérico", value: 'number' },
            { label: "Subir un documento", value: "file" },
            { label: "Confirmar acción", value: 'check' },
            { label: "Ingreso de fecha", value: 'date' },
          ]} />
          <Collapse in={params.type}>
            {this.renderContinuation()}
          </Collapse>
          <div className={classes.sameLine}>
            <Typography variant="subtitle1">Obligatorio</Typography>
            <Checkbox checked={params.required} onClick={() => this.handleChange({ target: { value: !params.required, name: "required" } })} />
          </div>
          <SubmitButton onClick={this.handleCreatePhase}>
            Guardar
          </SubmitButton>
        </div>
      </Dialog>
    )
  }
}

EditPhaseDialog.propTypes = {

}


EditPhaseDialog.defaultProps = {
  phase: null
}

export default withRouter(withStyles(style)(EditPhaseDialog))