import React, { Component } from 'react'
import { Collapse, Dialog, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind, { addToggle } from '../../Utils/autobind'
import { getUserDocuments, getUserDocumentCategories, editUserDocument, deleteUserDocument, signUserDocument, createUserDocument } from '../../API/users'
import { withRouter } from 'react-router-dom'
import DocumentContext from '../Branch/Documents/DocumentContext'
import Documents from '../Branch/Documents/Documents'
import { List } from '@material-ui/icons'
import LoadingDialog from '../../Shared/LoadingDialog'
import Categories from '../Branch/Documents/Categories'
import JSZip from 'jszip'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import { addQRToDocument } from '../../Utils/functions'

const style = () => ({
  container: {
    display: 'flex',
    background: 'white',
    borderRadius: 8,
    padding: 12,
    margin: '12px 0',
    position: 'relative'
  },
  verticalDivider: {
    width: 1,
    background: 'lightgrey',
    margin: '0 12px'
  },
  mobileCategories: {
    position: 'absolute',
    zIndex: 2,
    background: 'white',
    borderRadius: 8,
    transition: 'all 0.3s linear',
    top: 0,
    left: 0,
    padding: 12
  },
  listButton: {
    top: -12,
    left: -12,
  }
})

class NewWorkerDocuments extends Component {
  constructor() {
    super()
    this.state = {
      documents: [],
      categories: [],
      selectedCategory: null,
      selectedSubcategory: null,
      selectedSubSubcategory: null,
      title: "",
      openList: true,
      loading: false
    }
    addToggle(NewWorkerDocuments, this, "list")
    autobind(NewWorkerDocuments, this)
  }

  async componentDidMount() {
    const { match: { params: { id: user_id } } } = this.props
    const body = { user_id }
    this.setState({ loading: true })
    const [response, settings] = await Promise.all([getUserDocuments(body), getUserDocumentCategories()])
    const { data: { info: documents } } = response
    const { data: { info: categories } } = settings
    this.setState({ documents, categories, openList: true, loading: false })
  }

  handleSelectCategory(cat, isSub, isSubSub) {
    const { selectedCategory, selectedSubcategory, selectedSubSubcategory } = this.state
    if (isSubSub) {
      if (cat.id === selectedSubSubcategory) return this.setState({ title: "", selectedSubSubcategory: null })
      return this.setState({ title: cat.name, selectedCategory: null, selectedSubcategory: null, selectedSubSubcategory: cat.id })
    }
    if (isSub) {
      if (cat.id === selectedSubcategory) return this.setState({ title: "", selectedSubcategory: null })
      return this.setState({ title: cat.name, selectedSubcategory: cat.id, selectedCategory: null, selectedSubSubcategory: null })
    }
    if (cat.id === selectedCategory) return this.setState({ title: "", selectedCategory: null })
    return this.setState({ title: cat.name, selectedCategory: cat.id, selectedSubcategory: null, selectedSubSubcategory: null })
  }

  async handleSendEdit(params) {
    const body = new FormData()
    body.append("name", params.name)
    if (typeof params.file !== "string") { body.append("file", params.file) }
    body.append("category_id", params.category_id)
    body.append("subcategory_id", params.subcategory_id)
    body.append("sub_subcategory_id", params.sub_subcategory_id)
    body.append("id", params.id)
    body.append("user_id", params.user_id)
    body.append("date", params.date)
    await editUserDocument(body)
    this.componentDidMount()
  }

  async handleDeleteDocument(document) {
    const body = { user_id: document.user_id, id: document.id }
    await deleteUserDocument(body)
    this.componentDidMount()
  }

  async handleSignDocument(body) {
    await signUserDocument(body)
    this.componentDidMount()
  }

  async handleCreateDocument(params) {
    const { match } = this.props
    const body = new FormData()
    body.append("name", params.name || "")
    body.append("file", params.file)
    body.append("date", params.date)
    body.append("user_id", match.params.id)
    body.append("category_id", params.category_id)
    body.append("subcategory_id", params.subcategory_id)
    body.append("sub_subcategory_id", params.sub_subcategory_id)
    await createUserDocument(body)
    this.componentDidMount()
  }

  async handleAskSign(body) {
    // this.setState({ loading: true })
    // await editDocumentParticipants(body)
    // this.setState({ loading: false })
    // callSnackbar("Solicitud enviada", "success")
    // this.componentDidMount()
  }

  async handleDownloadAll() {
    const { workers } = this.props
    const { documents, categories } = this.state
    const allDocuments = [...documents] || []
    const documentAmount = allDocuments.length
    this.setState({ loadingDownload: true, actual: 0 })
    let actual = 0
    const self = this
    const zip = new JSZip()

    const noCatDocuments = allDocuments.filter(doc => doc.category_id === null && doc.subcategory_id === 0 && doc.sub_subcategory_id === 0)
    const nocat = zip.folder("Sin Categoría")
    await Promise.all(noCatDocuments.map(async function (doc) {
      actual += 1
      self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_DOCUMENTS}${doc.file}`
      const code = `${doc.id}--b`
      const validateUrl = `${window.location.origin}/validate/${code}`
      const blob = await addQRToDocument(url, validateUrl, doc.file, true)
      if (!!blob) {
        nocat.file(doc.file, blob)
      }
    }))

    await Promise.all(categories.map(async category => {

      const catDocuments = allDocuments.filter(doc => doc.category_id === category.id && doc.subcategory_id === 0 && doc.sub_subcategory_id === 0)
      const cat = zip.folder(category.name)
      await Promise.all(catDocuments.map(async function (doc) {
        actual += 1
        self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
        const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_DOCUMENTS}${doc.file}`
        const code = `${doc.id}--b`
        const validateUrl = `${window.location.origin}/validate/${code}`
        const blob = await addQRToDocument(url, validateUrl, doc.file, true)
        if (!!blob) {
          cat.file(doc.file, blob)
        }
      }))

      const { subcategories } = category
      await Promise.all(subcategories.map(async subcategory => {
        const subcatDocuments = allDocuments.filter(doc => doc.category_id === category.id && doc.subcategory_id === subcategory.id && doc.sub_subcategory_id === 0)
        const subcat = cat.folder(subcategory.name)
        await Promise.all(subcatDocuments.map(async function (doc) {
          actual += 1
          self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
          const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_DOCUMENTS}${doc.file}`
          const code = `${doc.id}--b`
          const validateUrl = `${window.location.origin}/validate/${code}`
          const blob = await addQRToDocument(url, validateUrl, doc.file, true)
          if (!!blob) {
            subcat.file(doc.file, blob)
          }
        }))

        const { sub_sub_categories = [] } = subcategory
        await Promise.all(sub_sub_categories.map(async subsubcategory => {
          const subsubcatDocuments = allDocuments.filter(doc => doc.category_id === category.id && doc.subcategory_id === subcategory.id && doc.sub_subcategory_id === subsubcategory.id)
          const subsubcat = subcat.folder(subsubcategory.name)
          console.log(subsubcatDocuments.length)
          await Promise.all(subsubcatDocuments.map(async function (doc) {
            actual += 1
            self.setState({ actual: documentAmount > 0 ? Math.round(actual * 100 / documentAmount) : 0 })
            const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_DOCUMENTS}${doc.file}`
            const code = `${doc.id}--b`
            const validateUrl = `${window.location.origin}/validate/${code}`
            const blob = await addQRToDocument(url, validateUrl, doc.file, true)
            if (!!blob) {
              subsubcat.file(doc.file, blob)
            }
          }))
        }))
      }))
    }))

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        // see FileSaver.js
        saveAs(content, `Documentos${workers?.selected?.name}.zip`)
        self.setState({ loadingDownload: false })
      })
  }

  render() {
    const { classes } = this.props
    const {
      categories,
      selectedCategory,
      selectedSubcategory,
      documents,
      title,
      openList,
      loading,
      loadingDownload,
      actual,
      selectedSubSubcategory
    } = this.state
    const isMobile = window.innerWidth <= 900
    return (
      <DocumentContext.Provider value={{
        categories,
        documents,
        title,
        selectedCategory,
        selectedSubcategory,
        selectedSubSubcategory,
        onSelect: this.handleSelectCategory,
        onEdit: this.handleSendEdit,
        onDelete: this.handleDeleteDocument,
        onSign: this.handleSignDocument,
        onCreate: this.handleCreateDocument,
        onAskSign: this.handleAskSign,
        base_url: `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_DOCUMENTS}`,
        type: "worker",
        downloadAll: this.handleDownloadAll
      }}>
        <div className={classes.container}>
          <Dialog open={loadingDownload} fullWidth maxWidth="sm">
            <div style={{ padding: 24 }}>
              <Typography variant='h4'>Descargando documentos:</Typography>
              <Typography variant='h1' style={{ textAlign: 'center', margin: 24 }}>{`${actual}%`}</Typography>
            </div>
          </Dialog>
          <LoadingDialog open={loading} />
          {!isMobile ?
            <>
              <Categories categories={categories} />
              <div className={classes.verticalDivider} />
            </>
            :
            <div className={classes.mobileCategories} style={{ background: openList ? "white" : "transparent", boxShadow: openList ? "0 0 20px rgb(8 21 66 / 5%)" : "none" }}>
              <IconButton className={classes.listButton} onClick={this.handleOpenList}>
                <List />
              </IconButton>
              <Collapse in={openList}>
                <div style={{ padding: "0 6px" }}>
                  <Categories categories={categories} />
                </div>
              </Collapse>
            </div>
          }
          <div style={{ marginTop: isMobile ? 24 : 0, width: '100%' }}>
            <Documents documents={documents} disableAskSigns />
          </div>
        </div>
      </DocumentContext.Provider>
    )
  }
}

const mapStateToProps = state => ({
  workers: state.workers
})

export default connect(mapStateToProps)(withRouter(withStyles(style)(NewWorkerDocuments)))