import { Box } from '@mui/material'

const css = {
	grid: {
		display: 'grid',
		gridTemplateColumns: 'auto 1fr',
		gap: 2
	}
}

function InfoGrid({ children }) {
	return (
		<Box sx={css.grid}>
			{children}
		</Box>
	)
}

export default InfoGrid