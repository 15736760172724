import { Divider, Box, Paper, Typography, Switch, Tabs, Tab } from '@mui/material'
import { useEffect, useState } from 'react'
import { getBranches } from '../../../API/branches'
import { getSettingsChecklist } from '../../../API/checklists'
import { changeOrderRule, getOrderRule, getSubEnterprises } from '../../../API/enterprise'
import { getBaseEvaluations } from '../../../API/evaluations'
import { getSettingsTalks } from '../../../API/talks'
import { getSettingsTrainings } from '../../../API/trainings'
import { createBaseActivity, deleteBaseActivity, editBaseActivity, getBaseActivities, getPositions } from '../../../API/users'
import useToggle from '../../../Hooks/ToogleHook'
import AddElementButton from '../../../Shared/AddElementButton'
import CreateConstrain from './CreateConstrain'
import UserActivity from './UserActivity'
import WelcomeMessage from './WelcomeMessage'

const css = {
	container: {
		margin: 2,
		maxWidth: 850
	},
	title: {
		marginBottom: 3
	},
	paper: {
		padding: 2,
		margin: '12px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	divider: {
		margin: '12px 0'
	},
	activity: {
		background: 'whitesmoke',
		padding: 2,
		borderRadius: 1,
		margin: '12px 0',
		position: 'relative',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between'
	},
	icons: {
		position: 'absolute',
		top: 6,
		right: 6
	}
}

const recordTypes = [
	"Registro o Formulario",
	"Charla",
	"Capacitación",
	"Evaluación"
]

const frequencies = [
	{ value: "0", label: "Solo una vez" },
	{ value: "1", label: "Diario" },
	{ value: "7", label: "Semanal" },
	{ value: "15", label: "Quincenal" },
	{ value: "30", label: "Mensual" },
	{ value: "60", label: "Bimensual" },
	{ value: "90", label: "Trimestral" },
	{ value: "180", label: "Semestral" },
	{ value: "365", label: "Anual" },
]

function UserSettings({ value }) {

	const [openCreate, toggleCreate] = useToggle(false)
	const [baseActivities, setBaseActivities] = useState([])
	const [data, setData] = useState({})
	const [options, setOptions] = useState([])
	const [order, setOrder] = useState(false)
	const [tab, setTab] = useState(0)


	function getRecordName(id, type_id) {
		const records = data[type_id]
		const record = records?.find(r => String(r.id) === id)
		return record?.name || "Sin nombre"
	}

	function getRecordTypeName(id) {
		const name = recordTypes[id]
		return name || "Sin nombre"
	}

	function getFrequencyName(value) {
		const freq = frequencies.find(f => f.value === value)
		return freq.label
	}

	async function onCreateActivity(body) {
		const response = await createBaseActivity(body)
		const { data: { info } } = response
		setBaseActivities(info)
	}

	async function onEditActivity(body) {
		const response = await editBaseActivity(body)
		const { data: { info } } = response
		setBaseActivities(info)
	}

	async function onDeleteActivity(body) {
		const response = await deleteBaseActivity(body)
		const { data: { info } } = response
		setBaseActivities(info)
	}

	useEffect(() => {
		async function fetchData() {
			const response = await Promise.all([getSettingsChecklist(), getSettingsTalks(), getSettingsTrainings(), getBaseEvaluations()])
			const activities_response = await getBaseActivities()
			const options_response = await Promise.all([getSubEnterprises(), getBranches(), getPositions()])
			const order_response = await getOrderRule()
			const newData = response.map(r => r.data.info)
			const optionsData = options_response.map(r => r.data.info)
			const { data: { info } } = activities_response
			setData(newData)
			setOptions(optionsData)
			setBaseActivities(info)
			setOrder(order_response.data.info)
		}
		fetchData()
	}, [])

	function renderActivity(activity, index) {
		return <UserActivity
			order={order}
			options={options}
			key={activity.id}
			index={index}
			activity={activity}
			functions={{ getRecordName, getRecordTypeName, getFrequencyName }}
			onEdit={onEditActivity}
			onDelete={onDeleteActivity}
			data={data}
		/>
	}

	async function changeOrder() {
		await changeOrderRule()
		setOrder(!order)
	}

	return (
		<Box sx={css.container}>
			<Typography sx={css.title} variant='h1'>Configuración de colaboradores</Typography>
			<Tabs value={tab} onChange={(e, v) => setTab(v)}>
				<Tab label="Correo de bienvenida" />
				<Tab label="Documentación obligatoria" />
			</Tabs>
			{tab === 0 &&
				<Paper sx={css.paper}>
					<WelcomeMessage />
				</Paper>
			}
			{tab === 1 &&
				<Paper sx={css.paper}>
					<Box sx={css.header}>
						<Typography variant='h4'>Definición de actividades mínimas para todos usuarios:</Typography>
						<AddElementButton name="Definir actividad" open={openCreate} onClick={toggleCreate} />
					</Box>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Switch checked={order} onClick={changeOrder} />
						<Typography variant='subtitle1'>{`El orden de la documentación es ${!order ? "opcional (recomendado)" : "obligatorio"}`}</Typography>
					</Box>
					<CreateConstrain options={options} open={openCreate} data={data} onCreate={onCreateActivity} />
					<Divider sx={css.divider} />
					<Typography variant='h4'>Actividades definidas:</Typography>
					{baseActivities.map(renderActivity)}
				</Paper >
			}
		</Box >
	)
}

export default UserSettings