import { Box, Typography } from '@mui/material'
import { useContext, useMemo } from 'react'
import { getPoints } from './checklistUtils'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		margin: '12px 0'
	}
}

function ChecklistPoints({ value }) {

	const { checklist } = useContext(ChecklistContext)
	const { total, added } = useMemo(() => getPoints(checklist), [checklist])

	return (
		<Box sx={css.container}>
			<Typography variant="h4">Puntaje Obtenido: {added}/{total}</Typography>
		</Box>
	)
}

export default ChecklistPoints