import { Typography } from '@material-ui/core'
import { AddCircleOutline, Edit, KeyboardArrowDown, Save } from '@material-ui/icons'
import { Box, IconButton, Paper, Collapse, Divider } from '@mui/material'
import { useEffect, useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import SeamlessInput from '../../../Shared/Inputs/SeamlessInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import SupplyType from './SupplyType'

const css = {
	paper: {
		padding: 2,
		margin: '12px 0'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer'
	},
	types: {
		padding: 2,
		background: 'whitesmoke',
		borderRadius: 2,
	},
	create_type: {
		padding: 2,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	divider: {
		margin: '6px 0'
	}
}

function Family({ family, onEdit, onCreateType, onEditType }) {

	const [open, toggleOpen] = useToggle(false)
	const [name, setName] = useState(family.name)
	const [newName, setNewName] = useState("")

	function onSave() {
		const body = { id: family.id, name }
		onEdit(body)
	}

	function onCreate() {
		const body = { supply_family_id: family.id, name: newName }
		onCreateType(body)
		setNewName("")
	}

	const types = family.supply_types || []

	return (
		<Box>
			<Paper sx={css.paper}>
				<Box sx={css.header} onClick={toggleOpen}>
					<SeamlessInput value={name} onChange={e => setName(e.target.value)} onBlur={onSave} color="transparent" />
					<IconButton >
						<KeyboardArrowDown />
					</IconButton>
				</Box>
				<Collapse in={open}>
					<Box sx={css.create_type}>
						<TextInput label="Nombre nuevo tipo de insumo" value={newName} onChange={e => setNewName(e.target.value)} />
						<IconButton style={{ marginLeft: 6 }} onClick={onCreate}>
							<AddCircleOutline />
						</IconButton>
					</Box>
					<Typography variant='h4' style={{ marginBottom: 12 }}>Insumos de esta familia:</Typography>
					<Box sx={css.types}>
						{!types.length && <Typography variant='subtitle1'>No hay tipos de insumos o maquinarias creadas para esta familia...</Typography>}
						{types.map((supply_type, index) => (
							<>
								{index > 0 && <Divider sx={css.divider} />}
								<SupplyType key={supply_type.id} supply_type={supply_type} onEdit={onEditType} />
							</>
						))}
					</Box>
				</Collapse>
			</Paper>
		</Box>
	)
}

export default Family