import React, { Component, Fragment } from "react"
import propTypes from "prop-types"
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    withStyles,
    IconButton,
    Tooltip,
    TableSortLabel,
    TextField,
    Menu,
    MenuItem,
    Button,
    Checkbox,
    Collapse
} from "@material-ui/core"
import {
    Search,
    KeyboardArrowDown,
    CancelRounded,
    KeyboardArrowRight,
    KeyboardArrowLeft,
    ArrowDownward,
    MoreVert,
} from "@material-ui/icons"
import autobind from "../Utils/autobind"
import { compareValuesByKey, getTableCellValue } from "../Utils/functions"
import RowCard from "./RowCard"
import { csv_to_excel } from "../API/enterprise"
import { Box } from "@mui/material"

const style = (theme) => ({
    row: {
        transition: "all 0.2s, ease-in-out",
        transform: "scale(1)",
        "&:hover": {
            background: theme.palette.grey.main,
        },
    },
    actionMenu: {
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        background: `linear-gradient(90deg, transparent 0%, ${theme.palette.indigo.lighter} 30%)`,
        transition: "all 0.2s, ease-in-out",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0 12px",
        paddingLeft: 32,
    },
    hiddenMenu: {
        position: "absolute",
        right: 0,
        top: 0,
        background: "#fff",
        opacity: 0,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0 12px",
        paddingLeft: 32,
    },
    topBar: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "3px 0 6px 0",
        flexWrap: 'wrap-reverse',
        "&>*": {
            margin: '0 12px 12px 12px',
            minWidth: 150
        },
    },
    subTopBar: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "3px 0 6px 0",
        flexWrap: "wrap",
        "&>*": {
            margin: '0 12px 12px 12px',
            "@media (max-width:500px)": {
                minWidth: 130,
                flexBasis: 150,
                flexGrow: 1
            }
        },
    },
    dropdown: {
        borderRadius: 5,
        padding: "5px 8px",
        fontSize: 13,
        color: 'whitesmoke',
        "& p": {
            marginRight: 6, fontSize: 13, color: "whitesmoke"
        }
    },
    sortable: {
        borderRadius: 5,
        padding: "5px 8px",
        fontSize: 13,
        color: theme.palette.primary,
        "& p": {
            marginRight: 6, fontSize: 13
        }
    },
    tag: {
        background: "#22607b",
        padding: "3px 9px",
        borderRadius: 24,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: 12,
    },
    filterResult: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        marginBottom: 12,
    },
    cardsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        gap: '12px',
        '&>*': {
            maxWidth: 350,
            "@media (max-width:500px)": {
                width: '100%',
                maxWidth: 'unset'
            }
        }
    },
    link: {
        color: theme.palette.blue.main,
        textDecoration: 'underline',
        cursor: 'pointer'
    }
})

class GeneralTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderBy: "",
            orderOn: "desc",
            word: "",
            activeDropdown: "",
            filterTags: [],
            options: {},
            left: 0,
            right: 25,
            step: 25,
            openMenu: null,
            bottomLimit: 20,
            openRow: null
        }
        autobind(GeneralTable, this)
    }

    componentDidMount() {
        const { data, filters } = this.props
        const options = {}
        filters.forEach((filter) => {
            const result = []
            data.forEach((element) => {
                if (result.indexOf(filter.format ? filter.format(element[filter.label], element) : element[filter.label]) === -1) {
                    result.push(filter.format ? filter.format(element[filter.label], element) : element[filter.label])
                }
            })
            options[filter.label] = result
        })
        this.setState({ options })
    }

    componentDidUpdate(prevProps) {
        const { data, filters } = this.props
        if (prevProps.data !== data) {
            const options = {}
            filters.forEach((filter) => {
                const result = []
                data.forEach((element) => {
                    if (result.indexOf(filter.format ? filter.format(element[filter.label], element) : element[filter.label]) === -1) {
                        result.push(filter.format ? filter.format(element[filter.label], element) : element[filter.label])
                    }
                })
                options[filter.label] = result
            })
            this.setState({ options })
        }
    }

    handleSort(label) {
        return () => {
            const { orderBy, orderOn } = this.state
            if (label === orderBy) {
                this.setState({
                    orderBy: label,
                    orderOn: orderOn === "desc" ? "asc" : "desc",
                })
            } else {
                this.setState({ orderBy: label, orderOn: "desc" })
            }
        }
    }

    handleSearch(event) {
        const { target } = event
        const { selectAll } = this.props
        this.setState({ word: target.value })
        selectAll && selectAll([])
    }

    handleAddMore() {
        const { bottomLimit } = this.state
        this.setState({ bottomLimit: bottomLimit + 20 })
    }

    handleOpenRow(element) {
        return () => {
            const { openRow } = this.state
            if (element.id === openRow?.id) return this.setState({ openRow: null })
            return this.setState({ openRow: element })
        }
    }

    search(data) {
        const { info, name } = this.props
        const target = document.getElementById(`searchBar-${name}`)
        if (target && target.value !== "") {
            const result = data.filter((cell) => {
                let bool = false
                info.forEach((element) => {
                    const labels = element.label.split("&")
                    if (labels.length > 1) {
                        if (
                            String(cell[labels[0]][labels[1]])
                                .toLocaleLowerCase()
                                .includes(target.value.toLocaleLowerCase())
                        ) {
                            bool = true
                        }
                    } else if (
                        String(cell[labels[0]])
                            .toLocaleLowerCase()
                            .includes(target.value.toLocaleLowerCase())
                    ) {
                        bool = true
                    }
                })
                return bool
            })
            return result
        }
        return data
    }

    filter(data) {
        const { filterTags } = this.state
        let temp = data
        filterTags.forEach((tag) => {
            const name = tag.unformat ? tag.unformat(tag.name) : tag.name
            temp = temp.filter((element) => element[tag.label] === name)
        })
        return temp
    }

    sortedElements() {
        const { data } = this.props
        const { orderBy, orderOn } = this.state
        const sortedData = data.sort(compareValuesByKey(orderBy, orderOn))
        return sortedData
    }

    handleClose() {
        this.setState({ anchorEl: null, activeDropdown: "" })
    }

    handleAddFilter(name, newFilter) {
        return () => {
            const { filterTags, step } = this.state
            const { disablePagination, data, selectAll } = this.props
            const newFilters = [...filterTags]
            const isFound = newFilters.find(
                (filter) => filter.name === name && filter.label === newFilter.label
            )
            if (!isFound) {
                newFilters.push({ name, label: newFilter.label, format: newFilter.format, unformat: newFilter.unformat })
            }
            this.setState({
                filterTags: newFilters,
                left: 0,
                right: disablePagination ? data.length : step,
            })
            this.handleClose()
            selectAll && selectAll([])
        }
    }

    handleRemoveFilter(name, label) {
        return () => {
            const { filterTags, step } = this.state
            const { disablePagination, data, selectAll } = this.props
            const newFilters = [...filterTags]
            const resultFilters = newFilters.filter(
                (filter) => !(filter.name === name && filter.label === label)
            )
            this.setState({
                filterTags: resultFilters,
                left: 0,
                right: disablePagination ? data.length : step,
            })
            this.handleClose()
            selectAll && selectAll([])
        }
    }

    handleOpenDropdown(event, label) {
        this.setState({ anchorEl: event.target, activeDropdown: label })
    }

    handleNextPage(option) {
        return () => {
            const { onChangePage } = this.props
            const { left, right, step } = this.state
            const processedData = this.filter(
                this.search(this.sortedElements())
            )
            let rightLimit = processedData.length < right ? processedData.length : right
            if (!!onChangePage) {
                rightLimit = processedData.length < step ? left + processedData.length : left + step
            }
            if (!!onChangePage) {
                if (option > 0) {
                    if (rightLimit === left + step) {
                        this.setState({ left: left + step, right: right + step })
                        return onChangePage((left + step) / step, step)
                    }
                } else {
                    if (left > 0) {
                        this.setState({ left: left - step, right: right - step })
                        return onChangePage((left - step) / step, step)
                    }
                }
            }

            if (option > 0) {
                if (right < processedData.length) {
                    this.setState({ left: left + step, right: right + step })
                }
            } else if (left > 0) {
                this.setState({ left: left - step, right: right - step })
            }
        }
    }

    handleSelectAll() {
        const { selected, selectAll } = this.props
        const processedData = this.filter(
            this.search(this.sortedElements())
        )
        if (selected.length > 0) return selectAll([])
        return selectAll(processedData.map(d => d.id))
    }

    handleOpenMenu(event) {
        const { target } = event
        const { openMenu } = this.state
        if (openMenu !== null) return this.setState({ openMenu: null })
        return this.setState({ openMenu: target })
    }

    handleChangeStepSize(event) {
        const { target } = event
        const { onChangePage } = this.props
        this.setState({ step: target.value, right: target.value, left: 0, openMenu: null })
        onChangePage(0, target.value)
    }

    renderFilters() {
        const { filters, classes } = this.props
        const { activeDropdown, anchorEl, options } = this.state
        return filters.map((filter, index) => {
            const dropdownOptions = options[filter.label] || []
            return (
                <Fragment key={index}>
                    <Button
                        className={classes.dropdown}
                        color="primary"
                        variant="contained"
                        onClick={(e) =>
                            this.handleOpenDropdown(e, filter.label)
                        }
                    >
                        <Typography variant="body2">
                            {filter.name}
                        </Typography>
                        <KeyboardArrowDown style={{ paddingLeft: 6 }} />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={activeDropdown === filter.label}
                        onClose={this.handleClose}
                    >
                        {dropdownOptions.map((option) => (
                            <MenuItem
                                key={option}
                                onClick={this.handleAddFilter(
                                    option,
                                    filter
                                )}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Fragment>
            )
        })
    }

    renderSortings() {
        const { sortings, classes } = this.props
        const { orderBy, orderOn } = this.state
        return sortings.map((sortableHeader, index) => (
            <Fragment key={index}>
                <Button
                    className={classes.sortable}
                    color="primary"
                    variant="outlined"
                    onClick={this.handleSort(sortableHeader.label)}
                >
                    <Typography variant="body2">
                        {sortableHeader.name}
                    </Typography>
                    {orderBy === sortableHeader.label && (
                        <ArrowDownward
                            color="primary"
                            style={{
                                height: 18,
                                width: 18,
                                transform:
                                    orderOn === "desc" ? "" : "rotate(180deg)",
                                transition: "all 0.1s linear",
                            }}
                        />
                    )}
                </Button>
            </Fragment>
        ))
    }

    renderFilterTags() {
        const { filterTags } = this.state
        const { classes } = this.props
        return filterTags.map((tag, index) => (
            <div className={classes.tag} key={index}>
                <Typography
                    variant="body2"
                    style={{ color: "#fff", fontWeight: 600 }}
                >
                    {tag.name}
                </Typography>
                <IconButton
                    size="small"
                    style={{ marginLeft: 6 }}
                    onClick={this.handleRemoveFilter(tag.name, tag.label)}
                >
                    <CancelRounded
                        style={{ color: "#fff", height: 16, width: 16 }}
                    />
                </IconButton>
            </div>
        ))
    }

    renderActions(row) {
        const { actions = [] } = this.props
        if (actions.length === 0) return null
        const actionCells = actions.map((action, index) => {
            function clickWrapper(row) {
                return (event) => {
                    if (action.link) {
                        if (event.ctrlKey) {
                            return window.open(action.link(row))
                        }
                        if (event.button === 1) {
                            return window.open(action.link(row))
                        }
                    }
                    action.action(row)()
                }
            }
            if (!!action.component) {
                return (
                    <Tooltip title={action.name} key={index.toString()} >
                        <action.component onClick={action.action} params={row} disabled={action.disabled ? action.disabled(row) : false} />
                    </Tooltip>
                )
            }
            return (
                <Tooltip title={action.name} key={index.toString()}>
                    <IconButton onMouseDown={clickWrapper(row)} color={action.color} disabled={action.disabled ? action.disabled(row) : false}>
                        <action.icon />
                    </IconButton>
                </Tooltip>
            )
        })
        return (
            <TableCell style={{ textAlign: 'end' }}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {actionCells}
                </Box>
            </TableCell>
        )

    }

    renderTableHead() {
        const { info, actions, selectable, selected } = this.props
        const { orderBy, orderOn } = this.state
        const processedData = this.filter(
            this.search(this.sortedElements())
        )
        const row = info.map((header, index) => {
            if (typeof header.name !== "string") return (
                <TableCell key={index.toString()}>
                    <header.name />
                </TableCell>
            )
            return (
                <TableCell key={index.toString()}>
                    <TableSortLabel
                        direction={orderOn}
                        active={header.label === orderBy}
                        onClick={this.handleSort(header.label)}
                    >
                        <Typography variant="subtitle1">{header.name}</Typography>
                    </TableSortLabel>
                </TableCell>
            )
        })
        return (
            <TableRow>
                {selectable &&
                    <TableCell>
                        <Checkbox
                            indeterminate={selected.length > 0 && selected.length < processedData.length}
                            color="secondary"
                            checked={selected.length === processedData.length && processedData.length > 0}
                            onClick={this.handleSelectAll}
                        />
                    </TableCell>
                }
                {row}
                {actions.length > 0 && (
                    <TableCell style={{ textAlign: 'end' }}>
                        <Typography variant="subtitle1">Acciones</Typography>
                    </TableCell>
                )}
            </TableRow>
        )
    }

    renderTableBody(option = "first") {
        const { info, classes, selectable, handleSelect, selected, innerCard: InnerCard, scrollable, onChangePage } = this.props
        const { left, right, openRow } = this.state

        const filteredData = this.filter(this.search(this.sortedElements()))
        let processedData = filteredData
        if (!scrollable && !onChangePage) {
            processedData = filteredData.slice(left, right)
        }

        const sepIndex = processedData.findIndex(element => element.id === openRow?.id)

        const rows = processedData.map((rowElement, index) => {
            const row = info.map((header, index2) => {
                let value = getTableCellValue(rowElement, header)
                if (header.format) { value = header.format(value, rowElement) }
                const rendered = header.render ? (
                    <header.render
                        element={rowElement}
                        header={header}
                        value={value}
                    />
                ) : (
                    false
                )
                return (
                    <TableCell
                        key={index2.toString()}
                        style={{
                            maxWidth: header.max_width
                                ? header.max_width
                                : "unset",
                        }}
                    >
                        {rendered !== false ? (
                            <>{rendered}</>
                        ) : (
                            <Tooltip title={value}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                    className={header.clickable ? classes.link : ""}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        header.action(rowElement)
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Tooltip>
                        )}
                    </TableCell>
                )
            })
            const toReturn = (
                <>
                    <TableRow className={classes.row} key={(index * 10).toString()} onClick={InnerCard ? this.handleOpenRow(rowElement) : null}>
                        {selectable &&
                            <TableCell>
                                <Checkbox checked={selected.includes(rowElement.id)} onClick={handleSelect(rowElement)} />
                            </TableCell>
                        }
                        {row}
                        {this.renderActions(rowElement)}
                    </TableRow>
                </>
            )
            return toReturn
        })

        if (option === "first") return rows.slice(0, sepIndex + 1)
        if (option === "second") return rows.slice(sepIndex + 1)
        return rows
    }

    renderCards() {
        const { info, actions, callbackForRendering } = this.props
        const { bottomLimit } = this.state
        const totalData = this.filter(this.search(this.sortedElements()))
        const processedData = totalData.slice(0, bottomLimit)
        const dataToRender = processedData.map((rowElement, index) => {
            if (callbackForRendering) {
                return callbackForRendering(rowElement, index)
            }
            return (
                <RowCard
                    row={rowElement}
                    info={info}
                    key={index}
                    actions={actions}
                />
            )
        })
        return (
            <>
                {dataToRender}
                {totalData.length > processedData.length && <Button onClick={this.handleAddMore} color="primary" variant="outlined">
                    Ver más
                </Button>}
            </>
        )
    }

    async handleGenerateFile() {
        const { data, name, info, exportCallback = null } = this.props

        const filteredData = this.filter(this.search(this.sortedElements()))

        const filteredInfo = info
        const headers = info.map(element => element.name).map(header => `"${header}"`).join(";")
        const valueHeaders = info.map(element => element.label)
        const body = filteredData.map(entry => valueHeaders
            .map(key => [entry, entry[key]])
            .map((values, index) => {
                if (!values[1]) return "-"
                if (!!filteredInfo[index]?.format) return filteredInfo[index].format(values[1], values[0])
                if (!!filteredInfo[index]?.render) {
                    const ToRender = filteredInfo[index]?.render
                    if (typeof ToRender === "function") {
                        return ToRender({ value: values[1], as_string: true })
                    }
                }
                return values[1]
            })
            .join(";"))
            .join('\n')
        const file = `${headers}\n${body}`

        if (!!exportCallback) return exportCallback(file, filteredData, info, name)

        const response = await csv_to_excel({ csv: file, name })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}.xlsx`)
        document.body.appendChild(link)
        link.click()
    }

    render() {
        const {
            name,
            classes,
            disableFilters,
            onlyCards,
            disableExport,
            selected,
            data,
            scrollable,
            maxHeight,
            disableSearch,
            innerCard: InnerCard,
            onChangePage
        } = this.props
        const processedData = this.filter(this.search(this.sortedElements()))
        const { filterTags, openMenu } = this.state
        const { word, left, right, step, openRow } = this.state
        const mobile = window.innerWidth < 500
        let rightLimit = processedData.length < right ? processedData.length : right
        if (!!onChangePage) {
            rightLimit = processedData.length < step ? left + processedData.length : left + step
        }
        return (
            <div>
                {!disableExport && <Button onClick={this.handleGenerateFile} variant="outlined" size="small">Exportar a Excel</Button>}
                {!disableFilters && (
                    <div id={`top-${name}`}>
                        {!disableSearch &&
                            <div className={classes.topBar}>
                                <div className={classes.subTopBar}>
                                    {this.renderFilters()}
                                    {this.renderSortings()}
                                </div>
                                <TextField
                                    id={`searchBar-${name}`}
                                    placeholder="Buscar... "
                                    onChange={this.handleSearch}
                                    InputProps={{
                                        endAdornment: <Search />,
                                    }}
                                    value={word}
                                />
                            </div>
                        }
                        {filterTags.length > 0 && (
                            <div style={{ height: 28, marginBottom: 12 }}>
                                <div className={classes.filterResult}>
                                    <Typography
                                        variant="subtitle2"
                                        color="primary"
                                        style={{ marginRight: 12 }}
                                    >
                                        {"Resultado: "}
                                    </Typography>
                                    {this.renderFilterTags()}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {mobile || onlyCards ? (
                    <div className={classes.cardsContainer}>
                        {this.renderCards()}
                    </div>
                ) : (
                    <>
                        {selected?.length > 0 && <Typography variant="caption">{`Seleccionadas ${selected.length} de ${data.length}`}</Typography>}
                        <Paper
                            style={{
                                maxHeight: scrollable ? maxHeight : "unset",
                                overflow: "auto",
                                padding: scrollable ? 0 : 24,
                                borderRadius: 6,
                                boxShadow: scrollable ? "none" : ""
                            }}
                            onScroll={this.handleScrollBottom}
                            id={`table-${name}`}
                            square
                        >
                            <Table stickyHeader={scrollable} style={{ tableLayout: InnerCard ? "fixed" : "unset" }}>
                                <TableHead id={`head-${name}`}>
                                    {this.renderTableHead()}
                                </TableHead>
                                <TableBody id={`body-${name}`}>
                                    {this.renderTableBody(InnerCard ? "first" : null)}
                                </TableBody>
                            </Table>
                            <>
                                <Collapse in={!!InnerCard && !!openRow}>
                                    <div>
                                        {!!InnerCard && <InnerCard element={openRow} />}
                                    </div>
                                </Collapse>
                                {InnerCard &&
                                    <Table style={{ tableLayout: InnerCard ? "fixed" : "unset" }}>
                                        <TableBody>
                                            {this.renderTableBody("second")}
                                        </TableBody>
                                    </Table>
                                }
                            </>
                        </Paper>
                        {!scrollable &&
                            <>
                                <Paper
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: 12,
                                        padding: "0px 12px",
                                    }}
                                    id={`bottom-${name}`}
                                    square
                                >
                                    <Typography variant="body1">{!onChangePage ? `Página ${left / step + 1
                                        } de ${Math.ceil(
                                            processedData.length / step
                                        )}` : `Página ${left / step + 1}`}</Typography>
                                    <Typography variant="body1">{`${left + 1} - ${rightLimit}`}</Typography>
                                    <div style={{ display: "flex" }}>
                                        <IconButton onClick={this.handleNextPage(-1)}>
                                            <KeyboardArrowLeft />
                                        </IconButton>
                                        <IconButton onClick={this.handleNextPage(1)}>
                                            <KeyboardArrowRight />
                                        </IconButton>
                                        <IconButton onClick={this.handleOpenMenu}>
                                            <MoreVert />
                                        </IconButton>
                                    </div>
                                </Paper>
                                <Menu anchorEl={openMenu} open={openMenu !== null} onClose={this.handleOpenMenu}>
                                    <MenuItem onClick={this.handleChangeStepSize} value={25}>25 Elementos por página</MenuItem>
                                    <MenuItem onClick={this.handleChangeStepSize} value={50}>50 Elementos por página</MenuItem >
                                    <MenuItem onClick={this.handleChangeStepSize} value={100}>100 Elementos por página</MenuItem>
                                </Menu>
                            </>
                        }
                    </>
                )}
            </div>
        )
    }
}

GeneralTable.propTypes = {
    classes: propTypes.object.isRequired,
    info: propTypes.arrayOf(propTypes.object),
    data: propTypes.arrayOf(propTypes.object),
    actions: propTypes.arrayOf(propTypes.object),
    filters: propTypes.arrayOf(propTypes.object),
    sortings: propTypes.arrayOf(propTypes.object),
    name: propTypes.string,
    disableFilters: propTypes.bool,
    disablePagination: propTypes.bool,
    onlyCards: propTypes.bool,
    callbackForRendering: propTypes.func
}

GeneralTable.defaultProps = {
    info: [],
    data: [],
    actions: [],
    filters: [],
    sortings: [],
    name: "table",
    disableFilters: false,
    disablePagination: false,
    onlyCards: false,
    callbackForRendering: undefined,
    exportable: false,
    selectable: false
}

export default withStyles(style)(GeneralTable)
