import React, { Component } from 'react'
import { Dialog, Typography, withStyles } from '@material-ui/core'
import TextInput from '../../../Shared/Inputs/TextInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import DateTimeInput from '../../../Shared/Inputs/DateTimeInput'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import SubmitButton from '../../../Shared/SubmitButton'
import { connect } from 'react-redux'
import { transformToOptions } from '../../../Utils/functions'
import autobind from '../../../Utils/autobind'
import moment from 'moment'

const style = theme => ({
  container: {
    padding: 12
  },
  title: {
    background: theme.palette.primary.main,
    padding: 24,
    color: 'white',
    fontWeigth: 600
  },
  button: {
    padding: 12
  }
})

class RegisterDialog extends Component {
  constructor() {
    super()
    this.state = {
      params: {
        date_start: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        date_end: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
      }
    }
    autobind(RegisterDialog, this)
  }

  componentDidUpdate(prevProps) {
    const { baseParams } = this.props
    if (baseParams && baseParams !== prevProps.baseParams) {
      this.setState({
        params: {
          date_start: baseParams.date_start,
          date_end: baseParams.date_end,
          work: baseParams.work,
          tools: baseParams.tools,
          supervisor: baseParams.supervisor,
          list_type_id: baseParams.list_type_id,
          users_id: baseParams.users.map(u => u.id.toString()),
          place: baseParams.place
        }
      })
    }
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { onCreate, onClose } = this.props
    const { params } = this.state
    const body = { ...params }
    onCreate(body)
    this.setState({
      params: {
        date_start: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        date_end: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
      }
    })
    onClose()
  }

  render() {
    const { classes, open, onClose, settings, branch, edit = false } = this.props
    const { params } = this.state
    const subBranchUsers = branch?.global_sub_branch?.users || []
    const allLists = settings?.lists?.all || []
    return (
      <Dialog open={open} onClose={onClose}>
        <Typography variant="h1" className={classes.title}>{edit ? "Editar" : "Nuevo"} Lista</Typography>
        <div className={classes.container}>
          <SelectInput onChange={this.handleChange} value={params.list_type_id} label="Tipo de lista" name="list_type_id" options={transformToOptions(allLists)} />
          <DateTimeInput onChange={this.handleChange} value={params.date_start} label="Fecha inicio" name="date_start" />
          <DateTimeInput onChange={this.handleChange} value={params.date_end} label="Fecha término" name="date_end" />
          <TextInput onChange={this.handleChange} value={params.supervisor} label="Encargado" name="supervisor" />
          <TextInput onChange={this.handleChange} value={params.place} label="Lugar" name="place" />
          <TextInput onChange={this.handleChange} value={params.work} options={{ multiline: true, rows: 3 }} label="Trabajo" name="work" />
          <TextInput onChange={this.handleChange} value={params.tools} options={{ multiline: true, rows: 3 }} label="Herramientas" name="tools" />
          <MultiSelectInput onChange={this.handleChange} value={params.users_id} label="Trabajadores" name="users_id" options={transformToOptions(subBranchUsers)} />
        </div>
        <div className={classes.button}>
          <SubmitButton onClick={this.handleCreate}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings,
  branch: state.branch
})

export default connect(mapStateToProps)(withStyles(style)(RegisterDialog))