import { Button } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { Box, Dialog, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import DateInput from '../../../Shared/Inputs/DateInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import { filterActiveWorkers } from '../../../Utils/filters'
import { transformToOptions } from '../../../Utils/functions'

const css = {
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 6px'
	},
	user: {
		padding: 3,
		background: 'white',
		maxWidth: 500,
		margin: '12px 0 24px',
		borderRadius: 2,
		position: 'relative'
	},
	edit: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	dialog: {
		padding: 3
	}
}

function InjuredUser({ user, onEdit, onDelete, workflow }) {

	const [editing, toggleEdit] = useToggle(false)
	const [params, setParams] = useState({ ...user })

	function destroy() {
		const body = { workflow_id: workflow.id, id: user.id }
		onDelete(body)
	}

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	function edit() {
		const body = { ...params, workflow_id: workflow.id, id: user.id }
		onEdit(body)
		toggleEdit()
	}

	return (
		<Box sx={css.user}>
			<Typography variant='h4'>{user.name}</Typography>
			{editing ?
				<Box>
					<TextInput value={params.lost_days} type="number" label="Días perdidos" name="lost_days" onChange={onChange} />
					<DateInput value={params.date_start} label="Desde" name="date_start" onChange={onChange} />
					<DateInput value={params.date_end} label="Hasta" name="date_end" onChange={onChange} />
					<Button color="primary" variant="contained" size="small" onClick={edit}>Guardar</Button>
				</Box>
				:
				<Box>
					<Typography variant='subtitle1'>{`Días perdidos: ${user.lost_days}`}</Typography>
					<Typography variant='subtitle1'>{`Desde ${user.date_start} - Hasta ${user.date_end}`}</Typography>
				</Box>
			}
			<Box sx={css.edit}>
				<IconButton onClick={toggleEdit}>
					<Edit />
				</IconButton>
				<IconButton onClick={destroy}>
					<Delete />
				</IconButton>
			</Box>
		</Box>
	)
}

function WorkflowInjured({ workflow, onCreate, onDelete, onEdit }) {

	const users = workflow.injured_users || []
	const workers = filterActiveWorkers()
	const [openCreate, toggleCreate] = useToggle(false)

	function onChange(event) {
		const { target } = event
		const body = { workflow_id: workflow.id, injured_users: [target.value] }
		onCreate(body)
	}

	function renderUsers(user) {
		return <InjuredUser user={user} onDelete={onDelete} onEdit={onEdit} workflow={workflow} />
	}

	return (
		<Box>
			<Box sx={css.header}>
				<Typography variant='h4'>Afectados por el accidente/incidente</Typography>
				<Button color="primary" variant="outlined" size="small" onClick={toggleCreate}>
					Agregar Afectados
				</Button>
			</Box>
			<Box>
				{users.map(renderUsers)}
			</Box>
			<Dialog open={openCreate} onClose={toggleCreate} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<SelectInput options={transformToOptions(workers)} label="selecciona al afectado" onChange={onChange} />
				</Box>
			</Dialog>
		</Box>
	)
}

export default WorkflowInjured