import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import { getSettingsChecklist } from '../../../API/checklists'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../../Utils/functions'
import moment from 'moment'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import ResultsReport from './ResultsReport'
import DateIntervalSelector from '../../../Shared/Inputs/DateIntervalSelector'
import { getReport } from '../../../API/reports'
import ItemsReport from './ItemsReport'
import './animations.css'
import ExtrafieldReport from './ExtrafieldReport'
import { FilterList, HighlightOffOutlined } from '@material-ui/icons'
import FiltersDialog from './FiltersDialog'

const style = () => ({
  section: {
    background: 'white',
    padding: 12,
    borderRadius: 12,
    margin: 12,
    position: 'relative'
  },
  title: {
    margin: '24px 12px'
  },
  filters: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  filterContainer: {
    minHeight: 100,
    flexGrow: 1,
    marginLeft: 12,
    marginTop: 12,
    background: 'whitesmoke',
    borderRadius: 12,
    padding: 12
  },
  filterButton: {
    position: 'absolute',
    bottom: 6,
    right: 6,
    background: 'white'
  },
  filterChip: {
    display: 'flex',
    alignItems: 'center',
    padding: 6,
    '& > *': {
      margin: '0 6px'
    },
    background: "lightgrey",
    width: 'fit-content',
    borderRadius: 8,
    margin: '6px 0'
  }
})

class ChecklistsReport extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      options: [],
      selected: "",
      selectedItem: null,
      loading: true,
      user: null,
      activity: null,
      branch: null,
      openFilters: false,
      date_start: moment().startOf("week").format("YYYY-MM-DD"),
      date_end: moment().endOf("week").format("YYYY-MM-DD")
    }
    autobind(ChecklistsReport, this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const response = await getSettingsChecklist()
    if (response.data.status !== "success") return this.setState({ loading: false })
    const options = response.data.info
    this.setState({ options, loading: false })
  }

  async handleSelectChecklist(event) {
    const { target } = event
    const { date_start, date_end } = this.state
    const body = {
      id: target.value,
      date_start,
      date_end,
    }
    this.setState({ loading: true, selected: target.value, selectedItem: null })
    const response = await getReport(body)
    this.setState({ loading: false })
    if (response.status !== 200) return null
    const { data } = response
    console.log(data)
    this.setState({ data })
  }

  async handleChangeDates(dates) {
    const { date_start, date_end } = dates
    const { selected } = this.state
    const body = {
      id: selected,
      date_start,
      date_end,
    }
    if (!selected) return null
    this.setState({ loading: true, date_start, date_end })
    const response = await getReport(body)
    this.setState({ loading: false })
    if (response.status !== 200) return null
    const { data } = response
    this.setState({ data })

  }

  handleSelectItem(item) {
    return () => {
      const { selectedItem } = this.state
      if (selectedItem?.id === item.id) return this.setState({ selectedItem: null })
      return this.setState({ selectedItem: item })
    }
  }

  handleOpenFilters() {
    this.setState({ openFilters: !this.state.openFilters })
  }

  handleFilter(event) {
    const { target } = event
    this.setState({ [target.name]: target.value })
  }

  handlerRemoveFilter(name) {
    return () => {
      this.setState({ [name]: null })
    }
  }

  render() {
    const { classes } = this.props
    const { options, loading, data, selected, selectedItem, openFilters, user, activity, branch } = this.state
    const selectedChecklist = options.find(checklist => checklist.id === selected)
    const filteredData = data.filter(checklist => {
      const isSameActivity = checklist.actividad === activity
      const isSameResponsible = checklist.responsable === user
      const isSameBranch = checklist.centro_de_trabajo === branch

      // Check for activity
      const activityValue = (activity && isSameActivity) || !activity
      const userValue = (user && isSameResponsible) || !user
      const branchValue = (branch && isSameBranch) || !branch

      return [activityValue, userValue, branchValue].every(Boolean)
    })
    return (
      <div className={classes.container}>
        {loading && <LoaderAnimator />}
        <Typography variant="h1" className={classes.title}>Reporte de actividades</Typography>
        <div className={classes.section}>
          <SelectInput
            value={selected}
            big
            options={transformToOptions(options)}
            label="Seleccionar registro o formulario"
            onChange={this.handleSelectChecklist}
          />
          <div className={classes.filters}>
            <DateIntervalSelector disabled={!selected} onDateChange={this.handleChangeDates} />
            <div className={classes.filterContainer}>
              <Typography variant="h4">Filtros</Typography>
              {activity && <div className={classes.filterChip}>
                <Typography variant="subtitle1">Actividad: {activity}</Typography>
                <IconButton size="small" onClick={this.handlerRemoveFilter("activity")}><HighlightOffOutlined /></IconButton>
              </div>}
              {user && <div className={classes.filterChip}>
                <Typography variant="subtitle1">Responsable: {user}</Typography>
                <IconButton size="small" onClick={this.handlerRemoveFilter("user")}><HighlightOffOutlined /></IconButton>
              </div>}
              {branch && <div className={classes.filterChip}>
                <Typography variant="subtitle1">Centro de trabajo: {branch}</Typography>
                <IconButton size="small" onClick={this.handlerRemoveFilter("branch")}><HighlightOffOutlined /></IconButton>
              </div>}
              <IconButton className={classes.filterButton} color="primary" onClick={this.handleOpenFilters}>
                <FilterList />
              </IconButton>
            </div>
          </div>
          <FiltersDialog onChange={this.handleFilter} branch={branch} user={user} activity={activity} open={openFilters} onClose={this.handleOpenFilters} data={data} />
        </div>
        {!loading && filteredData.length > 0 &&
          <>
            <ResultsReport data={filteredData} />
            <ItemsReport data={filteredData} onSelect={this.handleSelectItem} selected={selectedItem} />
            <ExtrafieldReport data={filteredData} fields={selectedChecklist?.extra_fields || []} />
          </>
        }
      </div>
    )
  }
}

export default withStyles(style)(ChecklistsReport)