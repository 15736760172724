import { Divider } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Button, Dialog, Switch, Typography } from '@mui/material'
import { useState } from 'react'
import useToggle from '../../../Hooks/ToogleHook'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../../Utils/functions'

const css = {
	dialog: {
		padding: 3
	},
	crums: {
		display: 'flex',
		alignItems: 'center'
	}
}

function CloneDialog({ open, onClose, selected, crums, subdivisions, branch, onClone }) {

	const [cloned, setCloned] = useState(null)
	const [selectedCrums, setCrums] = useState([branch.name])
	const [propagate, togglePropagate] = useToggle(true)

	const options = cloned ? cloned.children || [] : subdivisions

	function selectStructure(e) {
		const { target } = e
		const clone = options.find(o => String(o.id) === target.value)
		selectedCrums.push(clone.name)
		setCrums(selectedCrums)
		setCloned(clone)
	}

	function close() {
		setCloned(null)
		setCrums([branch.name])
		onClose()
	}

	function clone() {
		const body = { cloned_id: cloned.id, selected_id: selected?.id, branch_id: branch.id, propagate }
		onClone(body)
		close()
	}

	return (
		<Dialog open={open} onClose={close} fullWidth maxWidth="sm">
			<Box sx={css.dialog}>
				<Typography variant='h1' style={{ marginBottom: 24 }} >Clonar estructura</Typography>
				<Typography variant='h4'>Clonar desde:</Typography>
				<Box sx={css.crums}>
					{selectedCrums.map((crum, index) => {
						return (
							<>
								{index > 0 && <KeyboardArrowRight />}
								<Typography variant='subtitle1'>{crum}</Typography>
							</>
						)
					})}
				</Box>
				<SelectInput options={transformToOptions(options)} label="Seleccione subdivisión" onChange={selectStructure} />
				<Divider style={{ margin: '12px 0' }} />
				<Typography variant='h4'>Clonar hacia:</Typography>
				<Box sx={css.crums}>
					{crums.map((crum, index) => {
						return (
							<>
								{index > 0 && <KeyboardArrowRight />}
								<Typography variant='subtitle1'>{crum?.name}</Typography>
							</>
						)
					})}
				</Box>
				<Box style={{ display: 'flex', alignItems: 'center' }}>
					<Switch checked={!propagate} onClick={togglePropagate} />
					<Typography variant='subtitle1'>Clonar solo el primer nivel de la estructura</Typography>
				</Box>
				{cloned &&
					<Box style={{ margin: "12px 0" }}>
						<Typography variant='caption'>{
							propagate ?
								`Se clonará la estructura de ${cloned?.name} y sus subdivisiones, como la estructura de ${selected?.name || branch.name}` :
								`Se clonará solo el primer nivel de la estructura de ${cloned?.name}, como la estructura de ${selected?.name || branch.name}`
						}</Typography>
					</Box>
				}
				<Box style={{ textAlign: 'end' }}>
					<Button color="secondary" variant="contained" onClick={clone} disabled={!cloned}>
						{cloned ? `Clonar ${cloned?.name} hacia ${selected?.name || branch.name}` : "Seleccione subdivisión para comenzar"}
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default CloneDialog
