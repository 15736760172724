import { Box, Checkbox, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { answerChecklistField } from '../../../API/new_checklists'
import { uploadFile } from '../../../API/users'
import AppContext from '../../../AppContext'
import { RequestManagerContext } from '../../../DatabaseManagers/RequestsManager'
import ImageContainer from '../../../Shared/ImageContainer'
import ImgInput from '../../../Shared/Inputs/ImgInput'
import MultiSelectInput from '../../../Shared/Inputs/MultiSelectInput'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import RutInput from '../../../Shared/Inputs/RutInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import RTE from '../../../Shared/RTE'
import { completePath } from '../../../Utils/functions'
import { getTotalCounters } from '../checklistUtils'
import { ChecklistContext } from '../ChecklistView'

const css = {
	input: {
		'& > *': {
			margin: "0 !important"
		}
	},
	field: {
		marginBottom: 2
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	},
	options: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2
	},
	option: {
		background: 'whitesmoke',
		padding: '6px 12px',
		borderRadius: 1
	}
}

function getArray(value) {
	if (value === "") return []
	return JSON.parse(value)
}

function transofrmToExtraOptions(options) {
	if (!options) return []
	return options.map(option => {
		if (typeof option === "string") {
			return {
				label: option.split('&')[0],
				value: option.split('&')[0]
			}
		}
		return { label: option?.label || "", value: option?.label || "" }
	})
}

function TextField({ field, onChange, value, onBlur }) {
	const { finished } = useContext(ChecklistContext)
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<TextInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function RutField({ field, onChange, value, onBlur }) {
	const { finished } = useContext(ChecklistContext)
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input}>
			<RutInput onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function SelectField({ field, onChange, value, onChangeAndSave }) {
	const options = field?.options || []
	const selectedOption = options.find(option => option.label === value)
	const { finished } = useContext(ChecklistContext)
	const subquestions = selectedOption?.subquestions || []

	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{selectedOption ? selectedOption?.label : "Sin respuesta"}</Typography>
		</Box>
	)

	return (
		<Box sx={css.input}>
			<SelectInput value={value} onChange={onChangeAndSave} label="" options={transofrmToExtraOptions(options)} />
			<Box style={{ padding: '8px 0 0 8px' }}>
				{subquestions.map(subquestion => <Field field={subquestion} />)}
			</Box>
		</Box>
	)
}

function MultiField({ field, onChange, value = [], onBlur }) {
	const options = field?.options || []
	const real_value = Array.isArray(value) ? value : getArray(value)

	const { finished } = useContext(ChecklistContext)

	if (finished) return (
		<Box sx={css.input}>
			<Box sx={css.options}>
				{!!real_value?.length ?
					real_value.map(val => <Typography sx={css.option} variant='subtitle1'>{val}</Typography>) :
					<Typography variant='subtitle1'>Sin respuesta</Typography>
				}
			</Box>
		</Box>
	)

	return (
		<Box sx={css.input}>
			<NewMultiSelectInput onBlur={onBlur} value={real_value} onChange={onChange} label="" options={transofrmToExtraOptions(options)} />
		</Box>
	)
}

function RTEField({ field, onChange, value, onBlur }) {

	const { finished } = useContext(ChecklistContext)

	return (
		<Box sx={css.input}>
			<RTE disabled={finished} onBlur={onBlur} value={value} onChange={onChange} label="" />
		</Box>
	)
}

function ComplianceField({ field, onChange, value, onBlur, onChangeAndSave }) {

	const [params, setParams] = useState(value ? { ...value } : {})
	const { finished } = useContext(ChecklistContext)

	useEffect(() => {
		const newValue = value ? JSON.parse(value) : {}
		setParams({ ...newValue })
	}, [value])

	function onChangeParams(event) {
		const { target } = event

		const new_value = value ? { ...params } : {}
		if (["total", "desfav"].includes(target.name)) {
			const value = target.value > 0 ? target.value : 0
			new_value[target.name] = value
		} else {
			new_value[target.name] = target.value
		}

		const new_event = {
			target: { value: JSON.stringify(new_value) }
		}
		setParams(new_value)
		onChange(new_event)
	}

	function toggleSolved() {
		const new_value = value ? { ...params } : {}
		new_value.solved = !new_value.solved
		const new_event = {
			target: { value: JSON.stringify(new_value) }
		}
		setParams(new_value)
		onChangeAndSave(new_event)
	}

	const ratio = params.total > 0 ? params.desfav / params.total : 0

	const calculated_compliance = (100 - (ratio) * 100)
	const partial_compliance = params.solved ? calculated_compliance : 0
	const final_compliance = params.solved ? 100 : calculated_compliance

	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{field.label}: <strong>{params.total}</strong></Typography>
			<Typography variant='subtitle1'>{field.compliance_case}: <strong>{params.desfav}</strong></Typography>
			{calculated_compliance < 100 && <Typography variant='subtitle1'>{field.compliance_observation}: <strong>{params.comment}</strong></Typography>}
			{calculated_compliance < 100 && <Typography variant='subtitle1'>Resuelto en el lugar: <strong>{params.solved ? "SI" : "NO"}</strong></Typography>}
			{calculated_compliance < 100 && <Typography variant='subtitle1'>Cumplimiento parcial: <strong>{partial_compliance.toFixed(1)}%</strong></Typography>}
			<Typography variant='subtitle1'>Cumplimiento final: <strong>{final_compliance.toFixed(1)}%</strong></Typography>
		</Box>
	)

	return (
		<>
			<Box sx={css.input}>
				<TextInput type="number" onBlur={onBlur} value={params.total} onChange={onChangeParams} label="" name="total" />
				<Typography variant='subtitle1'>{field.compliance_case}</Typography>
				<TextInput type="number" onBlur={onBlur} name="desfav" value={params.desfav} onChange={onChangeParams} label="" />
				{(final_compliance < 100 || (params.solved && partial_compliance < 100)) &&
					<Box sx={css.inline}>
						<Checkbox checked={params.solved || false} onClick={toggleSolved} />
						<Typography variant='subtitle1'>Se solucionó el incumplimiento en el lugar?</Typography>
					</Box>
				}
				{partial_compliance !== 0 && <Typography variant='subtitle1'><strong>Cumplimiento Parcial: {partial_compliance.toFixed(1)}%</strong></Typography>}
				<Typography variant='subtitle1'><strong>Cumplimiento Final: {final_compliance.toFixed(1)}%</strong></Typography>
				{(final_compliance < 100 || (params.solved && partial_compliance < 100)) &&
					<Box sx={css.input}>
						<Typography variant='subtitle1'>{field.compliance_observation}</Typography>
						<TextInput onBlur={onBlur} name="comment" value={params.comment} onChange={onChangeParams} label="" />
					</Box>
				}
			</Box>
		</>
	)
}

function ImageField({ field, onChange, value, onChangeAndSave }) {

	async function onImageSelect(event) {
		const { target } = event
		const file = target.value
		const body = new FormData()
		body.append("file", file)
		body.append("name", file.name)
		const response = await uploadFile(body)
		const new_image = response.data.info
		let actual_images = value.split("&#&")
		if (!!actual_images.length) {
			actual_images.push(new_image)
			const new_value = actual_images.join("&#&")
			onChangeAndSave({ target: { name: "", value: new_value } })
		} else {
			actual_images = [new_image]
			const new_value = actual_images.join("&#&")
			onChangeAndSave({ target: { name: "", value: new_value } })
		}

	}

	function onDelete(name, index) {
		const actual_images = value.split("&#&")
		actual_images.splice(index, 1)
		const new_value = actual_images.join("&#&")
		onChangeAndSave({ target: { name: "", value: new_value } })
	}

	const { finished } = useContext(ChecklistContext)
	const images = typeof value === "string" ? value.split("&#&") : ""
	if (finished) return (
		<Box sx={css.input}>
			{!!images.length ?
				images.map(image => <ImageContainer border="6px" src={completePath(image, `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`)} width={64} />) :
				<Typography variant='subtitle1'>Sin respuesta</Typography>
			}
		</Box>
	)

	return (
		<Box sx={css.input}>
			<ImgInput
				value={value}
				name={field.label}
				onChange={onImageSelect}
				onDelete={onDelete}
				label=""
				url={"/"}
			/>
		</Box>
	)
}

function IDSField({ field, onChange, value, onBlur }) {
	const { finished, checklist } = useContext(ChecklistContext)
	if (finished) return (
		<Box sx={css.input}>
			<Typography variant='subtitle1'>{value}</Typography>
		</Box>
	)
	return (
		<Box sx={css.input} style={{ paddingTop: 8 }}>
			<TextInput onBlur={onBlur} value={value} onChange={onChange} label="Cantidad de trabajadores" />
			<Typography variant="subtitle1">Eventos Ponderados: {getTotalCounters(checklist)}</Typography>
			<Typography variant="subtitle1" style={{ marginBottom: 12 }}>Resultado: {field?.value ? (getTotalCounters(checklist) * 100) / field?.value : 0}</Typography>
		</Box>
	)
}


function Field({ field }) {

	const { setChecklist, checklist } = useContext(ChecklistContext)

	let ToRender = TextField
	if (field.type === "text") { ToRender = TextField }
	if (field.type === "rut") { ToRender = RutField }
	if (field.type === "select") { ToRender = SelectField }
	if (field.type === "multiselect") { ToRender = MultiField }
	if (field.type === "rte") { ToRender = RTEField }
	if (field.type === "img") { ToRender = ImageField }
	if (field.type === "ids") { ToRender = IDSField }
	if (field.type === "compliance") { ToRender = ComplianceField }

	const [value, setValue] = useState(field.value)
	const { online: { status: online } } = useContext(AppContext)
	const { addRequest } = useContext(RequestManagerContext)

	useEffect(() => {
		setValue(field.value)
	}, [field])

	function onChange(event) {
		const { target } = event
		setValue(target.value)
	}

	function answerChecklistFieldOffline(body) {
		const new_checklist = { ...checklist }
		const new_fields = [...checklist.checklist_fields]
		const new_field_index = new_fields.findIndex(f => f.id === field.id)
		const new_field = { ...body }
		new_fields[new_field_index] = new_field
		new_checklist.checklist_fields = new_fields
		setChecklist(new_checklist)
		addRequest({ name: "Respuesta Formulario", method: answerChecklistField(body, true) })
	}

	async function updateChecklistField(body) {
		if (!online) return answerChecklistFieldOffline(body)
		const response = await answerChecklistField(body)
		setChecklist(response.data.info)
	}

	async function onBlur() {
		const body = { ...field, value }
		updateChecklistField(body)
	}

	async function onChangeAndSave(event) {
		const { target } = event
		setValue(target.value)
		const body = { ...field, value: target.value }
		updateChecklistField(body)
	}

	return (
		<Box sx={css.field}>
			<Typography variant='subtitle1'><strong>{`${field.label}${field?.required ? " *" : ""}`}</strong></Typography>
			<ToRender onBlur={onBlur} field={field} value={value} onChange={onChange} onChangeAndSave={onChangeAndSave} />
		</Box>
	)
}

export default Field