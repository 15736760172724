import { Box, Button, Paper, Slide, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { editActivity } from '../../../API/activities'
import { getSettingsChecklist } from '../../../API/checklists'
import useChangeParams from '../../../Hooks/UseStateChangeParams'
import DateInput from '../../../Shared/Inputs/DateInput'
import NewMultiSelectInput from '../../../Shared/Inputs/NewMultiSelectInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import UsersInput from '../../../Shared/Inputs/UsersInput'
import LoadingDialog from '../../../Shared/LoadingDialog'
import { end_buttons, paper_style } from '../../../Utils/defaultStyles'
import { transformToOptions } from '../../../Utils/functions'
import { ActivityContext } from './ActivityNew'

const css = {
	paper: {
		padding: 2,
		flexBasis: 250,
		flexGrow: 1
	}
}

function EditActivityForm({ open, onClose }) {

	const { activity, frequencies, setActivity } = useContext(ActivityContext)
	const [checklists, setChecklists] = useState([])
	const [params, setParams, updateParams] = useChangeParams({ ...activity })
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		async function fetchData() {
			const response = await getSettingsChecklist()
			setChecklists(response.data.info)
		}
		fetchData()
	}, [])

	useEffect(() => {
		const base_checklist_ids = activity?.preventive_measures?.map(pm => String(pm.id)) || []
		updateParams({ ...activity, base_checklist_ids })
	}, [activity])

	async function onEditActivity() {
		const body = { ...params }
		console.log(body)
		setLoading(true)
		const response = await editActivity(body)
		setActivity(response.data.info)
		setLoading(false)
	}


	return (
		<Slide in={open} direction="left" unmountOnExit>
			<Paper sx={css.paper}>
				<LoadingDialog loadingMessage='Reprogramando...' open={loading} />
				<Typography variant='h4'>Editar Actividad</Typography>
				<TextInput onChange={setParams} name="name" value={params.name} label="Nombre" />
				<UsersInput onChange={setParams} name="user_id" value={params.user_id} label="Responsable" />
				<NewMultiSelectInput
					name="base_checklist_ids"
					onChange={setParams}
					options={transformToOptions(checklists)}
					label="Registros o Formularios"
					value={params.base_checklist_ids}
				/>
				<DateInput onChange={setParams} name="date_start" value={params.date_start} label="Fecha de Inicio" />
				<DateInput onChange={setParams} name="date_end" value={params.date_end} label="Fecha de Término" />
				<SelectInput onChange={setParams} name="frequency" value={params.frequency} label="Frecuencia" options={frequencies} />
				<Box sx={end_buttons}>
					<Button color="info" variant="contained" onClick={onEditActivity}>
						Terminar y Guardar
					</Button>
				</Box>
			</Paper>
		</Slide>
	)
}

export default EditActivityForm