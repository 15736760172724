import { Box, Menu, Typography } from '@mui/material'
import TextInput from '../../../../Shared/Inputs/TextInput'

const css = {
	container: {
		padding: '6px 12px 0',
		'& > div': {
			margin: '6px 0 !important'
		}
	}
}

function PointsMenu({ open, anchorEl, onClose, points, value, onChange, onBlur }) {

	function onKeyPress(e) {
		if (e.key === "Enter") {
			e.preventDefault()
			onClose()
			// onBlur()
		}
	}

	return (
		<Menu open={open} anchorEl={anchorEl} onClose={onClose}>
			<Box sx={css.container} onKeyDown={onKeyPress} >
				<Typography variant='h4'>Establecer puntaje:</Typography>
				<TextInput autoFocus type="number" label="" value={value} onChange={onChange} />
			</Box>
		</Menu>
	)
}

export default PointsMenu