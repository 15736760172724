import { AddCircleOutline } from '@material-ui/icons'
import { Box, Divider, IconButton, Paper, Typography } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import { createBaseFindingAction, createBaseFindingMeasures, createBaseFindingTimings, createFindingType, deleteBaseFindingAction, deleteBaseFindingMeasures, deleteBaseFindingTimings, editBaseFindingAction, editBaseFindingMeasures, editBaseFindingTimings, getBaseFindingActions, getBaseFindingMeasures, getBaseFindingTimings, getFindingType } from '../../../../API/findings&nc'
import newTheme from '../../../../newTheme'
import BigTooltip from '../../../../Shared/BigTooltip'
import SelectCreateInput from '../../../../Shared/Inputs/SelectCreateInput'
import DefaultAction from './DefaultAction'
import DefaultMeasures from './DefaultMeasures'
import DefaultTimings from './DefaultTimings'

const css = {
	container: {
		maxWidth: 950
	},
	selector: {
		background: 'white',
		padding: '3px 12px',
		maxWidth: 300,
		borderRadius: 2,
		marginBottom: 3
	},
	title: {
		margin: '12px 0'
	},
	section: {
		margin: '24px 0'
	},
	paper: {
		padding: 2,
		borderRadius: 2,
		marginTop: 1
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	addButton: {
		textAlign: 'end'
	},
	actions: {
		maxHeight: 400,
		overflow: 'auto'
	}
}

export const FindingStructureContext = createContext({})

function EmptyContainer({ onCreate }) {
	return (
		<Box sx={css.header}>
			<Typography variant='subtitle1' style={{ paddingLeft: 6 }} >No has creado descripciones subestandar...</Typography>
			<BigTooltip title="Crear nueva acción">
				<IconButton color="info" onClick={onCreate}>
					<AddCircleOutline />
				</IconButton>
			</BigTooltip>
		</Box>
	)
}

const crits = [
	{ name: "No Crítico", value: 1, color: newTheme.palette.blue.main },
	{ name: "Poco Crítico", value: 2, color: newTheme.palette.middle.main },
	{ name: "Medianamente Crítico", value: 3, color: newTheme.palette.warning.main },
	{ name: "Altamente Crítico", value: 4, color: newTheme.palette.error.main },
]

function DefaultFindingStructure({ value }) {

	const [types, setTypes] = useState([])
	const [actions, setActions] = useState([])
	const [measures, setMeasures] = useState([])
	const [timings, setTimings] = useState([])
	const [findingType, selectType] = useState(0)

	function getTypeName(type) {
		return types.find(t => String(t.id) === findingType)?.name
	}

	useEffect(() => {
		async function fetchData() {
			const response = await getFindingType()
			setTypes(response.data.info)

		}
		fetchData()
	}, [])

	useEffect(() => {
		async function fetchData() {
			const body = { type: findingType }
			const responses = await Promise.all([
				getBaseFindingActions(body),
				getBaseFindingMeasures(body),
				getBaseFindingTimings(body)
			])
			const datas = responses.map(r => r.data.info)
			setActions(datas[0])
			setMeasures(datas[1])
			setTimings(datas[2])
		}

		if (findingType !== 0) {
			fetchData()
		}

	}, [findingType])

	async function updateElement(endpoint, params, setter) {
		params.type = findingType
		const response = await endpoint(params)
		setter(response.data.info)
	}

	function createAction() { updateElement(createBaseFindingAction, {}, setActions) }
	function editAction(body = {}) { updateElement(editBaseFindingAction, body, setActions) }
	function deleteAction(body = {}) { updateElement(deleteBaseFindingAction, body, setActions) }

	function createMeasure(body = {}) { updateElement(createBaseFindingMeasures, body, setMeasures) }
	function editMeasure(body = {}) { updateElement(editBaseFindingMeasures, body, setMeasures) }
	function deleteMeasure(body = {}) { updateElement(deleteBaseFindingMeasures, body, setMeasures) }

	function createTiming(body = {}) { updateElement(createBaseFindingTimings, body, setTimings) }
	function editTiming(body = {}) { updateElement(editBaseFindingTimings, body, setTimings) }
	function deleteTiming(body = {}) { updateElement(deleteBaseFindingTimings, body, setTimings) }

	return (
		<FindingStructureContext.Provider value={{
			createAction,
			editAction,
			deleteAction,
			createMeasure,
			editMeasure,
			deleteMeasure,
			createTiming,
			editTiming,
			deleteTiming,
			crits
		}} >
			<Box sx={css.container}>
				<Typography sx={css.title} variant='h1'>Configuración estructura interna de los hallazgos</Typography>
				<Typography sx={css.title} variant='subtitle2'>Seleccione tipo de hallazgo</Typography>
				<Box sx={css.selector}>
					<SelectCreateInput onFetch={getFindingType} label="Tipo de Hallazgo" value={findingType} onChange={e => selectType(e.target.value)} onCreate={createFindingType} />
				</Box>
				{!!findingType &&
					<Box>
						<Divider />
						<Typography sx={css.title} variant='h1'>Configuración de Hallazgos del tipo "{getTypeName(findingType)}"</Typography>
						<Box sx={css.section}>
							<Typography variant='h4'>Descripciones </Typography>
							<Paper sx={css.paper}>
								{!actions.length && <EmptyContainer onCreate={createAction} />}
								{!!actions.length &&
									<Box>
										<Box sx={css.actions}>
											{actions.map(action => <DefaultAction action={action} key={action.id} />)}
										</Box>
										<Box sx={css.addButton}>
											<BigTooltip title="Agregar acción subestandar">
												<IconButton color="info" onClick={createAction}>
													<AddCircleOutline />
												</IconButton>
											</BigTooltip>
										</Box>
									</Box>
								}
							</Paper>
						</Box>
						<Box sx={css.section}>
							<Typography variant='h4'>Medidas de control / Acciones Inmediatas</Typography>
							<DefaultMeasures measures={measures} />
						</Box>
						<Box sx={css.section}>
							<Typography variant='h4'>Tiempos límites por criticidad</Typography>
							<DefaultTimings timings={timings} />
						</Box>
					</Box>
				}
			</Box>
		</FindingStructureContext.Provider>
	)
}

export default DefaultFindingStructure