import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getSettingsActivities = () => {
  return instance2({
    method: 'get',
    url: "settings/activities/get.php"
  })
}

export const getActivities = body => {
  return instance2({
    method: 'get',
    url: 'activities/get.php',
    params: body
  })
}

export const getActivity = body => {
  return instance2({
    method: 'get',
    url: 'activities/show',
    params: body
  })
}

export const getQuickAccess = body => {
  return instance2({
    method: 'get',
    url: 'activities/quick_access'
  })
}

export const createMultipleFromPosition = body => {
  return instance2({
    method: 'post',
    url: 'activities/create_multiple_by_position.php',
    data: body
  })
}

export const createMultipleActivitiesFromBranches = body => {
  return instance2({
    method: "post",
    url: "activities/create_multiple.php",
    data: body,
  })
}

export const createActivity = (body) => {
  return instance2({
    method: "post",
    url: "activities/create.php",
    data: body,
  })
}

export const editActivity = body => {
  return instance2({
    method: 'put',
    url: 'activities',
    data: body
  })
}

export const setAuthorizedUsers = body => {
  return instance2({
    method: 'put',
    url: 'activities/authorized_users',
    data: body
  })
}

export const toggleActivityCalendar = body => {
  return instance2({
    method: 'put',
    url: 'activities/calendar',
    data: body
  })
}

export const createActivitySignGroup = body => {
  return instance2({
    method: 'post',
    url: 'activities/sign_group',
    data: body
  })
}

export const editActivitySignGroup = body => {
  return instance2({
    method: 'put',
    url: 'activities/sign_group',
    data: body
  })
}

export const deleteActivitySignGroup = body => {
  return instance2({
    method: 'delete',
    url: 'activities/sign_group',
    data: body
  })
}

export const getActivityChecklists = body => {
  return instance2({
    method: 'get',
    url: 'activities/checklists',
    params: body
  })
}

export const signActivityChecklist = body => {
  return instance({
    method: 'post',
    url: 'checklists/sign.php',
    data: body
  })
}