import { Add, DragIndicator, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Box, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { createAuthorization, editAuthorization, getBaseAuthorizations, moveAuthorization, setBaseAuthorizationsUsers } from '../../../../API/authorizations'
import AppContext from '../../../../AppContext'
import NewMultiSelectInput from '../../../../Shared/Inputs/NewMultiSelectInput'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import TextInput from '../../../../Shared/Inputs/TextInput'
import { transformToOptions } from '../../../../Utils/functions'
import { callSnackbar } from '../../../../Utils/snackbar'


const css = {
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	authorization: {
		padding: '6px 12px',
		background: 'whitesmoke',
		borderRadius: 2,
		margin: '12px 0'
	},
	users: {
		display: 'flex'
	},
	buttons: {
		textAlign: 'end'
	}
}

function BaseAuthorization({ authorization, index, onEdit, total, onMove }) {

	const [selected, setSelected] = useState(authorization.users)
	const { workers } = useContext(AppContext)
	const [name, setName] = useState(authorization.name)
	const [amount, setAmount] = useState(authorization.amount)

	useEffect(() => {
		setSelected(authorization.users)
	}, [authorization])

	function onBlur(value) {
		const selectedUsers = value
		const body = { id: authorization.id, users: selectedUsers }
		setBaseAuthorizationsUsers(body)
	}

	function handleEdit() {
		const body = { ...authorization, name, amount }
		onEdit(body)
	}

	function checkIfValidAmount(new_amount) {
		if (new_amount <= selected.length) return true
		callSnackbar("No se pueden solicitar más firmas que usuarios asignados", "warning")
		return false
	}

	function handleMove(option) {
		const body = { ...authorization, option }
		onMove(body)
	}

	useEffect(() => {
		const body = { ...authorization, amount }
		onEdit(body)
	}, [amount])

	return (
		<Box sx={css.authorization} >
			<SeamlessInput onBlur={handleEdit} value={name} onChange={e => setName(e.target.value)} color="transparent" />
			<NewMultiSelectInput
				label="Seleccione usuarios autorizadores"
				onBlur={onBlur}
				value={selected}
				options={transformToOptions(workers)}
				onChange={e => setSelected(e.target.value)}
				grouped={(worker) => worker.branch?.name || "Sin asignar"}
			/>
			<TextInput label="Mínimo de firmas" type="number" value={amount} onChange={e => {
				if (checkIfValidAmount(e.target.value)) {
					setAmount(e.target.value)
				}
			}} />
			<Box sx={css.buttons}>
				<IconButton size="large" disabled={authorization.order_index === total - 1} onClick={() => handleMove("down")}>
					<KeyboardArrowDown />
				</IconButton>
				<IconButton size="large" disabled={authorization.order_index === 0} onClick={() => handleMove("up")}>
					<KeyboardArrowUp />
				</IconButton>
			</Box>
		</Box>
	)
}

function BaseChecklistAuthorizations({ checklist }) {

	const [authorizations, setAuthorizations] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getBaseAuthorizations({ base_checklist_id: checklist.id })
			setAuthorizations(response.data.info)
		}

		if (!!checklist.id) { fetchData() }

	}, [checklist])

	async function onCreate() {
		const body = { id: checklist.id }
		const response = await createAuthorization(body)
		setAuthorizations(response.data.info)
	}

	async function onEdit(body) {
		const response = await editAuthorization(body)
		setAuthorizations(response.data.info)
	}

	async function onMove(body) {
		const response = await moveAuthorization(body)
		setAuthorizations(response.data.info)
	}

	return (
		<Box>
			<Box sx={css.header}>
				<Typography variant='h4'>Autorizaciones del registro</Typography>
				<IconButton size="small" onClick={onCreate}>
					<Add />
				</IconButton>
			</Box>
			<Box>
				{authorizations.map((authorization, index) => <BaseAuthorization
					key={authorization.id}
					onMove={onMove}
					onEdit={onEdit}
					authorization={authorization}
					index={index}
					total={authorizations.length}
				/>)}
			</Box>
		</Box>
	)
}

export default BaseChecklistAuthorizations