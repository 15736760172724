import { HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import useToggle from '../../Hooks/ToogleHook'
import { getCompliance, getPoints, getProgress } from './checklistUtils'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		flexGrow: 1,
		position: 'sticky',
		top: '24px',
		zIndex: 100
	},
	card: {
		padding: 2,
		background: 'white',
		borderRadius: 2,
		position: 'sticky',
		top: '24px',
		transition: 'all 0.2s linear'
	},
	info: {
		marginBottom: 2
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}



function FlexCard() {

	const { checklist } = useContext(ChecklistContext)
	const [isOverChecklist, setOver] = useState(false)

	const [openInfo, toggleInfo] = useToggle(false)
	const progress = useMemo(() => getProgress(checklist), [checklist])
	const compliance = useMemo(() => getCompliance(checklist), [checklist])
	const partial_compliance = useMemo(() => getCompliance(checklist, true), [checklist])
	const { total, added } = useMemo(() => getPoints(checklist), [checklist])

	useEffect(() => {
		window.addEventListener("scroll", isOver)
		// return window.removeEventListener("scroll", isOver)
	}, [])

	function isOver() {
		if (window.scrollY > 150 && window.innerWidth < 1300) {
			setOver(true)
		} else {
			setOver(false)
		}
	}

	const textColor = false && isOverChecklist ? 'whitesmoke' : '#6a6a6a'
	const cardBackground = false && isOverChecklist ? '#00000042' : 'white'

	return (
		<Box sx={css.card} style={{ background: cardBackground }}>
			<Box sx={css.header}>
				<Box>
					{!isOverChecklist && <Typography style={{ color: textColor }} variant='h1'>{`Estadísticas`}</Typography>}
					{!openInfo && <Typography style={{ color: textColor }} variant='subtitle1'>{`Cumplimiento: ${compliance}% | Avance: ${progress}%`}</Typography>}
				</Box>
				<IconButton onClick={toggleInfo}>
					{openInfo ? <HighlightOffOutlined style={{ color: textColor }} /> : <KeyboardArrowDown style={{ color: textColor }} />}
				</IconButton>
			</Box>
			{openInfo &&
				<Box style={{ marginTop: 12 }}>
					<Typography sx={css.info} style={{ color: textColor }} variant='h4'>Avance: {progress}%</Typography>
					<Typography sx={css.info} style={{ color: textColor }} variant='h4'>Cumplimiento Parcial: {partial_compliance}%</Typography>
					<Typography sx={css.info} style={{ color: textColor }} variant='h4'>Cumplimiento Final: {compliance}%</Typography>
					<Typography sx={css.info} style={{ color: textColor }} variant='h4'>Puntaje: {added}/{total}</Typography>
				</Box>
			}
		</Box>
	)
}

function ComplianceAndProgress({ value }) {

	return (
		<Box sx={css.container}>
			<FlexCard />
		</Box>
	)
}

export default ComplianceAndProgress