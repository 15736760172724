import instance2 from "../Utils/instance2"

export const getBaseAuthorizations = body => {
	return instance2({
		method: 'get',
		url: 'authorizations/base',
		params: body
	})
}

export const createAuthorization = body => {
	return instance2({
		method: 'post',
		url: 'authorizations/base',
		data: body
	})
}

export const setBaseAuthorizationsUsers = body => {
	return instance2({
		method: 'put',
		url: 'authorizations/base/users',
		data: body
	})
}

export const signAuthorization = body => {
	return instance2({
		method: 'post',
		url: 'authorizations/sign',
		data: body
	})
}

export const editAuthorization = body => {
	return instance2({
		method: 'put',
		url: 'authorizations/base',
		data: body
	})
}

export const moveAuthorization = body => {
	return instance2({
		method: 'post',
		url: 'authorizations/base/move',
		data: body
	})
}