import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SelectInput from '../../../../Shared/Inputs/SelectInput'
import SubmitButton from '../../../../Shared/SubmitButton'
import autobind from '../../../../Utils/autobind'

const style = (theme) => ({
  container: {
    '& > h2': {
      marginBottom: 12
    }
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center'
  },
  optionsCreated: {
    background: theme.palette.purple.light,
    borderRadius: 7,
    display: 'grid'
  }
})

class ChangeOption extends Component {
  constructor() {
    super()
    this.state = {
      params: {}
    }
    autobind(ChangeOption, this)
  }

  componentDidMount() {
    const { option } = this.props
    console.log(option)
    let baseParams = { ...option }
    if (typeof option === "string") {
      const [value, message, color] = option.split('&')
      baseParams.label = value
      baseParams.message = message
      baseParams.color = color
    }
    this.setState({ params: baseParams })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleEdit() {
    const { onEdit } = this.props
    const { params } = this.state
    const body = { ...params }
    onEdit(body)
  }

  handleCreate() {
    const { onCreate } = this.props
    const { params } = this.state
    const body = { ...params }
    this.setState({ params: {} })
    onCreate(body)
  }

  render() {
    const { classes } = this.props
    const { params } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.optionsCreator}>
          <div className={classes.extraField}>
            <TextInput label="Agregar Opción" onChange={this.handleChange} name="label" value={params.label} />
          </div>
          {<div className={classes.extraField}>
            <TextInput label="Mensaje (Opcional)" onChange={this.handleChange} name="message" value={params.message} />
          </div>}
          {<div className={classes.extraField}>
            <SelectInput options={[
              { label: "Rojo", value: "red" },
              { label: "Verde", value: "green" },
              { label: "Amarillo", value: "gold" },
              { label: "Sin Color", value: "unset" },
            ]}
              label="Color" onChange={this.handleChange} name="color" value={params.color} />
          </div>}
          <SubmitButton color="primary" onClick={this.handleEdit} disabled={!params.label}>
            Guardar Cambios
          </SubmitButton>
        </div>
      </div>

    )
  }
}

export default withStyles(style)(ChangeOption)