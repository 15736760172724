import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getSettingPrograms = body => {
  return instance2({
    method: 'get',
    url: 'settings/programs/get.php',
    params: body
  })
}

export const createSettingsProgram = body => {
  return instance({
    method: 'post',
    url: 'settings/programs/create.php',
    data: body
  })
}

export const createProgramRecord = body => {
  return instance({
    method: 'post',
    url: 'settings/programs/create_record_item.php',
    data: body
  })
}

export const editProgramRecord = body => {
  return instance({
    method: 'post',
    url: 'settings/programs/edit_record_item.php',
    data: body
  })
}

export const deleteProgramRecord = body => {
  return instance({
    method: 'post',
    url: 'settings/programs/delete_record_item.php',
    data: body
  })
}

export const editSettingsProgram = body => {
  return instance({
    method: 'post',
    url: 'settings/programs/edit.php',
    data: body
  })
}

export const getPrograms = (body) => {
  return instance2({
    method: 'get',
    url: 'programs/get.php',
    params: body
  })
}