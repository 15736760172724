import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"

export const getSettingsFindings = () => {
  return instance2({
    method: 'get',
    url: 'settings/findings/get.php'
  })
}

export const editSettingsFinding = body => {
  return instance({
    method: 'post',
    url: 'settings/findings/edit.php',
    data: body
  })
}

export const createSettingsFinding = body => {
  return instance({
    method: 'post',
    url: 'settings/findings/create.php',
    data: body
  })
}

export const getSettingsNonConformities = () => {
  return instance2({
    method: 'get',
    url: 'settings/nonconformities/get.php'
  })
}

export const editSettingsNonConformity = body => {
  return instance({
    method: 'post',
    url: 'settings/nonconformities/edit.php',
    data: body
  })
}

export const createSettingsNonConformity = body => {
  return instance({
    method: 'post',
    url: 'settings/nonconformities/create.php',
    data: body
  })
}