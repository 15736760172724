import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'

const style = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 24
  },
  card: {
    background: 'white',
    borderRadius: 8,
    padding: 24,
    margin: 12,
    width: 120,
    textAlign: 'center',
    marginLeft: 0,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  },
  selected: {
    background: theme.palette.primary.main,
    '& > *': {
      color: 'white'
    }
  }
})

class WorkflowTypeSelector extends Component {


  render() {
    const { classes, onSelect, selected } = this.props
    return (
      <div className={classes.container}>
        <div className={`${classes.card} ${selected === 1 ? classes.selected : ""}`} onClick={onSelect(1)} >
          <Typography variant="h4">Accidentes</Typography>
        </div>
        <div className={`${classes.card} ${selected === 2 ? classes.selected : ""}`} onClick={onSelect(2)} >
          <Typography variant="h4">Incidentes</Typography>
        </div>
        <div className={`${classes.card} ${selected === 3 ? classes.selected : ""}`} onClick={onSelect(3)} >
          <Typography variant="h4">Reclamos</Typography>
        </div>
        <div className={`${classes.card} ${selected === 5 ? classes.selected : ""}`} onClick={onSelect(5)} >
          <Typography variant="h4">Generales</Typography>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(WorkflowTypeSelector)