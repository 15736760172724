import React, { Component } from 'react'
import { TextField, Typography, withStyles } from '@material-ui/core'
import newTheme from '../../newTheme'

const style = () => ({
  container: {
    maxWidth: 500
  },
  input: {
    position: 'relative',
  }
})
class SeamlessInput extends Component {

  render() {
    const {
      classes,
      label,
      onChange,
      onBlur,
      value,
      disabled,
      type = "text",
      name,
      placeholder,
      width = 500,
      color = '#6a6a6a42',
      variant = "subtitle1",
      fontColor = "#6A6A6A",
      padding = 6,
      onKeyPress,
      focus = false
    } = this.props
    const useless = disabled && !value
    if (useless) return null
    return (
      <div className={classes.container} style={{ maxWidth: width }}>
        <Typography variant={variant}>{label}</Typography>
        {disabled ?
          <Typography variant={variant} style={{
            borderRadius: 5,
            background: color,
            padding: 6,
            color: '#1a1a1a'
          }}>
            {value || placeholder}
          </Typography>
          :
          <TextField
            onChange={onChange}
            onBlur={onBlur}
            value={value || ""}
            name={name}
            type={type}
            multiline
            onKeyDown={onKeyPress}
            placeholder={placeholder}
            className={classes.input}
            autoFocus={focus}
            InputProps={{
              style: {
                borderRadius: 5,
                background: color,
                padding,
                fontSize: "0.95rem",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "400",
                lineHeight: "1.75",
                ...newTheme.typography[variant],
                color: fontColor,
              },
              classes: { input: classes.input },
              disableUnderline: true
            }}
            fullWidth
          />
        }
      </div>
    )
  }
}

export default withStyles(style)(SeamlessInput)