export const checkIfWorkflowIsReadyToEnd = (workflow) => {
  if (!workflow) return false
  const steps = workflow.workflow_steps || []
  const completed = steps.map(checkIfFinished).every(Boolean)
  return completed
}

export const checkIfStepIsCompleted = (step) => {
  const { phases = [] } = step
  let ready = true
  phases.forEach(phase => {
    if (phase?.data?.required && !phase?.data?.value) { ready = false }
    if (phase?.data?.value && !phase?.data?.checked) {
      console.log(phase)
      ready = false
    }
  })
  return ready
}

export const checkIfFinished = step => {
  if (!step) return false
  return !!step?.data?.finished
}