import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import TextPhaseReport from './PhasesReport/TextPhaseReport'
import FilePhaseReport from './PhasesReport/FilePhaseReport'
import CheckPhaseReport from './PhasesReport/CheckPhaseReport'
import SelectPhaseReport from './PhasesReport/SelectPhaseReport'

const style = () => ({
  container: {
    background: '#f2f3f8',
    padding: 12,
    margin: '12px 0',
    borderRadius: 12
  }
})

class StepsReport extends Component {

  getAllWorkflowsPhases() {
    const { workflows } = this.props
    const allPhases = []
    workflows.forEach(workflow => {
      workflow.workflow_steps.forEach(step => {
        allPhases.push(step.phases)
      })
    })
    return allPhases.flat()
  }

  getComponentBasedOnType(data) {
    if (data === "text") return TextPhaseReport
    if (data === "file") return FilePhaseReport
    if (data === "check") return CheckPhaseReport
    if (data === "select") return SelectPhaseReport
    if (data === "multiselect") return SelectPhaseReport
    return TextPhaseReport
  }

  renderPhases(phases) {
    if (phases.length === 0) return null
    const allPhases = this.getAllWorkflowsPhases()
    return phases.map(item => {
      const { data = {} } = item
      const filteredPhases = allPhases.filter(answer => answer.data.label === data.label)
      const PhaseComponent = this.getComponentBasedOnType(data.type)
      return (
        <>
          <Typography variant="subtitle1" style={{ marginLeft: 6, marginTop: 12, fontWeight: 600 }}>{data.label}</Typography>
          <PhaseComponent answers={filteredPhases} name={data.label} />
        </>

      )
    })
  }

  render() {
    const { classes, step: { data = {}, phases = [] } } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1" style={{ marginLeft: 6, fontWeight: 600, marginBottom: 6 }}>{data.name}</Typography>
        {this.renderPhases(phases)}
      </div>
    )
  }
}

export default withStyles(style)(StepsReport)