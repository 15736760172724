import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Button, Slide, Typography } from '@mui/material'
import { useContext } from 'react'
import AppContext from '../../../AppContext'
import useToggle from '../../../Hooks/ToogleHook'
import useChange from '../../../Hooks/UseStateChange'
import AddElementButton from '../../../Shared/AddElementButton'
import GeneralTable from '../../../Shared/GeneralTable'
import TextInput from '../../../Shared/Inputs/TextInput'
import { end_buttons, inline_space, text_space } from '../../../Utils/defaultStyles'
import WorkflowSetup from './WorkflowSetup'
import { WorkflowsSetupContext } from './WorkflowsSetup'

const css = {
	input: {
		maxWidth: 400,
		background: 'white',
		padding: 2,
		borderRadius: 2,
		margin: '12px 0',
		flexGrow: 1
	}
}

function WorkflowList({ workflows, type }) {

	const { onCreate } = useContext(WorkflowsSetupContext)
	const { history } = useContext(AppContext)
	const [openCreate, toggleCreate] = useToggle(false)
	const [name, setName] = useChange("")

	const myWorkflows = workflows.filter(wf => wf.workflow_type_id === type)

	const tableInfo = [
		{ label: "name", name: "Nombre" },
		{ label: "workflow_steps", name: "Etapas", format: v => v.length || 0 }
	]

	const actions = [
		{ name: "Ver Proceso", icon: KeyboardArrowRight, action: openWorkflow }
	]

	function openWorkflow(row) {
		return () => {
			history.push(`/settings/processes/${row.id}`)
		}
	}

	function create() {
		const body = { name, workflow_type_id: type }
		onCreate(body)
	}

	return (
		<Box>
			<Box sx={{ ...inline_space, ...text_space }}>
				<Typography variant='h1'>Procesos de Accidentes</Typography>
				<AddElementButton name='Crear proceso' open={openCreate} onClick={toggleCreate} />
			</Box>
			<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Slide in={openCreate} direction="left" unmountOnExit>
					<Box sx={css.input}>
						<Typography variant='h4' style={{ marginBottom: 12 }}>Nuevo proceso</Typography>
						<TextInput onChange={setName} value={name} label="Nombre nuevo proceso" />
						<Box sx={end_buttons}>
							<Button color="primary" variant="contained" onClick={create}>
								Crear proceso
							</Button>
						</Box>
					</Box>
				</Slide>
			</Box>
			<GeneralTable data={myWorkflows} info={tableInfo} disableExport disableSearch actions={actions} />
		</Box>
	)
}

export default WorkflowList