import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import ResponisveImageContainer from '../../../../../Shared/ResponisveImageContainer'

const style = () => ({

})
class CheckPhase extends Component {

  render() {
    const { classes, phase } = this.props
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}`
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">Tipo: Confirmar una acción</Typography>
        <Typography variant="caption">Acción: {phase.label}</Typography>
        {phase.image && <ResponisveImageContainer height={64} border={0} width={'100%'} src={`${url}/${phase.image}`} />}
      </div>
    )
  }
}

export default withStyles(style)(CheckPhase)