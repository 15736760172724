import React, { Component, Fragment } from 'react'
import { Collapse, Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import {
  createPermissionsRequirementAction,
  createPermissionsRequirementCategoryAction,
  deletePermissionsRequirementCategoryAction,
  editPermissionsRequirementAction,
  editPermissionsRequirementCategoryAction,
  getPermissionsRequirementCategoriesAction,
  getPermissionsRequirementsAction
} from '../../../../Actions/RequirementsPermissionsActions'
import autobind from '../../../../Utils/autobind'
import { connect } from 'react-redux'
import TextInput from '../../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../../Shared/SubmitButton'
import LoaderAnimator from '../../../../Shared/LoaderAnimator'
import { Delete, Edit, KeyboardArrowDown } from '@material-ui/icons'

const style = theme => ({
  createForm: {
    background: 'white',
    maxWidth: 700,
    margin: 'auto',
    marginTop: 24,
    marginBottom: 24
  },
  title: {
    background: theme.palette.primary.main,
    padding: 12,
    color: 'white'
  },
  form: {
    padding: 12
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    '&:hover': {
      background: 'whitesmoke'
    },
    height: 48,
    padding: '0px 12px'
  },
  requirementsContainer: {
    background: 'white',
    marginBottom: 12
  },
  requirements: {
    padding: 12
  }
})

class Requirements extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      params: {},
      selectedEdit: null,
      selectedDelete: null,
      openBar: -1,
      loadingRequirements: false,
      requirements: [],
      selectedRequirement: null
    }
    autobind(Requirements, this)
  }

  componentDidMount() {
    const { getPermissionsRequirementCategories } = this.props
    getPermissionsRequirementCategories().then(() => this.setState({ loading: false }))
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreateCategory() {
    const { createPermissionsRequirementCategory } = this.props
    const { params } = this.state
    const body = { ...params }
    createPermissionsRequirementCategory(body)
    this.setState({ params: {} })
  }

  handleOpenBar(value) {
    return () => {
      const { getPermissionsRequirements } = this.props
      const { openBar } = this.state
      if (openBar === value) return this.setState({ openBar: -1 })
      this.setState({ openBar: value, loadingRequirements: true })
      const body = { category_id: value }
      getPermissionsRequirements(body).then(response => {
        this.setState({ requirements: response?.payload?.data?.info?.requirements || [], loadingRequirements: false })
      })
    }
  }

  handleOpenEdit(selectedEdit) {
    return () => {
      const { params } = this.state
      params.editName = selectedEdit.name
      this.setState({ selectedEdit })
    }
  }

  handleOpenDelete(selectedDelete) {
    return () => {
      this.setState({ selectedDelete })
    }
  }

  handleOpenEditRequirement(selectedRequirement) {
    return () => {
      const { params } = this.state
      params.editRequirementName = selectedRequirement.name
      this.setState({ selectedRequirement })
    }
  }

  handleCloseModals() {
    this.setState({ selectedDelete: null, selectedEdit: null, selectedRequirement: null, params: {} })
  }

  handleEditCategory() {
    const { selectedEdit, params } = this.state
    const { editPermissionsRequirementCategory } = this.props
    const body = {
      name: params.editName,
      id: selectedEdit.id
    }
    editPermissionsRequirementCategory(body)
    this.handleCloseModals()
    this.setState({ params: {} })
  }

  handleEditRequirement() {
    const { selectedRequirement, params, openBar } = this.state
    const { editPermissionsRequirement } = this.props
    const body = {
      name: params.editRequirementName,
      id: selectedRequirement.id,
      category_id: openBar
    }
    this.handleCloseModals()
    this.setState({ params: {} })
    editPermissionsRequirement(body).then(response => {
      this.setState({ requirements: response?.payload?.data?.info?.requirements })
    })

  }

  handleDeleteCategory() {
    const { selectedDelete } = this.state
    const { deletePermissionsRequirementCategory } = this.props
    const body = {
      id: selectedDelete.id
    }
    deletePermissionsRequirementCategory(body)
    this.handleCloseModals()
  }

  renderRequirements() {
    const { requirements } = this.state
    const { classes } = this.props
    return requirements.map(epp =>
    (
      <div className={classes.category}>
        <Typography variant="subtitle2">{epp.name}</Typography>
        <IconButton onClick={this.handleOpenEditRequirement(epp)}>
          <Edit />
        </IconButton>
      </div>
    ))
  }

  handleCreateRequirement() {
    const { openBar, params } = this.state
    const { createPermissionsRequirement } = this.props
    const body = {
      name: params.eppName,
      category_id: openBar,
    }
    createPermissionsRequirement(body).then(response => {
      this.setState({ requirements: response?.payload?.data?.info?.requirements || [] })
    })
  }

  renderCategories() {
    const { openBar, loadingRequirements, params } = this.state
    const { classes, settings } = this.props
    const allCategories = settings?.requirements_categories?.all || []
    const list = allCategories.map(category => {
      const isOpen = category.id === openBar
      return (
        <Fragment key={category.id}>
          <Divider />
          <div className={classes.category}>
            <Typography variant="subtitle1">{category.name}</Typography>
            <div className={classes.buttons}>
              <IconButton onClick={this.handleOpenBar(category.id)}>
                <KeyboardArrowDown />
              </IconButton>
              <IconButton onClick={this.handleOpenEdit(category)}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.handleOpenDelete(category)}>
                <Delete />
              </IconButton>
            </div>
          </div>
          <Collapse in={isOpen}>
            {loadingRequirements ?
              <div style={{ height: 120, position: 'relative', background: 'white' }}>
                <LoaderAnimator loading />
              </div>
              :
              <div className={classes.requirementsContainer}>
                <Typography variant="h2" className={classes.title}>Crear Exigencia</Typography>
                <div className={classes.form}>
                  <TextInput label="Nombre Exigencia" name="eppName" value={params.eppName} onChange={this.handleChange} />
                  <SubmitButton onClick={this.handleCreateRequirement}>Crear</SubmitButton>
                </div>
                <Divider />
                <div className={classes.requirements}>
                  {this.renderRequirements()}
                </div>
              </div>
            }
          </Collapse>
        </Fragment>
      )
    })
    return (
      <>
        <div className={classes.category}>
          <div>
            <Typography variant="subtitle1">Nombre</Typography>
          </div>
          <div>
            <Typography variant="subtitle1">Acciones</Typography>
          </div>
        </div>
        {list}
      </>
    )
  }

  EditDialog() {
    const { classes } = this.props
    const { selectedEdit, params } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedEdit !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Editar Categoría</Typography>
        <div className={classes.form}>
          <TextInput label="Nombre" name="editName" value={params.editName} onChange={this.handleChange} />
          <SubmitButton onClick={this.handleEditCategory}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  EditRequirementDialog() {
    const { classes } = this.props
    const { selectedRequirement, params } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedRequirement !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Editar Exigencia</Typography>
        <div className={classes.form}>
          <TextInput label="Nombre" name="editRequirementName" value={params.editRequirementName} onChange={this.handleChange} />
          <SubmitButton onClick={this.handleEditRequirement}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  DeleteDialog() {
    const { classes } = this.props
    const { selectedDelete } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedDelete !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Eliminar Categoría: {selectedDelete?.name}</Typography>
        <div className={classes.form}>
          <Typography variant="subtitle1">¿Seguro que deseas eliminar esta categoría?</Typography>
          <SubmitButton onClick={this.handleDeleteCategory}>Eliminar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  render() {
    const { classes } = this.props
    const { params, loading } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.createForm}>
          <Typography variant="h2" className={classes.title}>Crear categoría</Typography>
          <div className={classes.form}>
            <TextInput label="Nombre" name="name" value={params.name} onChange={this.handleChange} />
            <SubmitButton onClick={this.handleCreateCategory}>Crear</SubmitButton>
          </div>
        </div>
        {loading ?
          <div style={{ position: 'relative', height: 300 }}>
            <LoaderAnimator loading />
          </div>
          :
          this.renderCategories()
        }
        {this.EditDialog()}
        {this.DeleteDialog()}
        {this.EditRequirementDialog()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  createPermissionsRequirementCategory: body => dispatch(createPermissionsRequirementCategoryAction(body)),
  getPermissionsRequirementCategories: body => dispatch(getPermissionsRequirementCategoriesAction(body)),
  editPermissionsRequirementCategory: body => dispatch(editPermissionsRequirementCategoryAction(body)),
  deletePermissionsRequirementCategory: body => dispatch(deletePermissionsRequirementCategoryAction(body)),
  getPermissionsRequirements: body => dispatch(getPermissionsRequirementsAction(body)),
  createPermissionsRequirement: body => dispatch(createPermissionsRequirementAction(body)),
  editPermissionsRequirement: body => dispatch(editPermissionsRequirementAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Requirements))