import React, { Component } from "react"
import { Button, Collapse, Divider, Typography, withStyles } from "@material-ui/core"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import { activityEditInfo } from "./Info"
import autobind from "../../Utils/autobind"
import { activityDispatcher } from "../../Actions/ActivityActions"
import { connect } from "react-redux"
import { addQrToFile, mergeMultiplePdfFilesAndGet, transformToOptions } from "../../Utils/functions"
import { getWorkersAction } from "../../Actions/EnterpriseAction"
import GeneralTable from "../../Shared/GeneralTable"
import SelectInput from "../../Shared/Inputs/SelectInput"
import DateInput from "../../Shared/Inputs/DateInput"
import moment from "moment"
import SignRegisters from "./SignRegisters"
import { getDoneChecklistsAction, signChecklistAction } from "../../Actions/CheckListActions"
import { getSettingChecklistsAction } from "../../Actions/SettingsActions"
import SignCell from "./SignCell"
import ImageCell from "../Reports/Activities/ImageCell"
import ChecklistAccessSetting from "./ChecklistAccessSetting"
import IrresponsableUsers from "./IrresponsableUsers"
import { callSnackbar } from "../../Utils/snackbar"
import PermissionBoolean from "../../Shared/Permissions/PermissionBoolean"
import { filterActiveBranches, filterActiveWorkers } from "../../Utils/filters"
import { generateRegistersDocument } from "./Utils/SettingsUtils"
import { PDFDocument } from 'pdf-lib'
import MiniLoaderAnimator from "../../Shared/MiniLoaderAnimator"
import { GetApp } from "@material-ui/icons"
import LoaderAnimator from "../../Shared/LoaderAnimator"
import { getActivities } from "../../API/activities"

const style = (theme) => ({
    container: {
        margin: 12,
    },
    input: {
        maxWidth: 500,
        width: '100%',
        padding: '12px 18px',
        background: 'white',
        borderRadius: 15
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginRight: 12
        }
    },
    signButton: {
        position: 'sticky',
        top: 24,
        right: 24,
        textAlign: 'end',
        marginBottom: 24,
        zIndex: 5,
        '& > *': {
            marginBottom: 12
        }

    },
    middleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        margin: '24px 0',
        flexWrap: 'wrap',
        position: 'relative'
    },
    link: {
        textAlign: 'end',
        padding: 12,
        color: theme.palette.blue.main,
        cursor: 'pointer'
    }
})

class Activity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            params: {
                date_start: moment(new Date()).format("YYYY-MM-DD"),
                date_end: moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
                sub_branch_id: 'all'
            },
            selectedRows: [],
            openSigns: false,
            reason: "",
            openIrresponsable: false,
            waitingDownload: false,
            activities: []
        }
        autobind(Activity, this)
    }

    async componentDidMount() {
        const { getActivity, getWorkers, getActivityParams, getDoneChecklists, getSettingsChecklists, match } = this.props
        const { params } = this.state
        const { date_start, date_end } = params
        const body = {
            companyId: 4,
            id: this.props.match.params.id,
        }
        this.setState({ loading: true })
        const response = await getActivities()
        const allActivities = response.data.info || []
        const allMeasures = allActivities.find(activity => activity.id === match.params.id)?.preventive_measures || []
        const preselectedMeasure = allMeasures[0]
        params.preventive_measure = String(preselectedMeasure.id)
        this.setState({ params, activities: allActivities })
        await getWorkers()
        await getActivityParams()
        await getActivity(body)
        await getSettingsChecklists(body)
        await getDoneChecklists({ date_start, date_end, activity_id: this.props.match.params.id, preventive_measure_id: String(preselectedMeasure.id) })
        this.setState({ loading: false })
    }

    handleOpenSigns() {
        this.setState({ openSigns: !this.state.openSigns })
    }

    handleEndableDates() {
        this.setState({ enabledDates: !this.state.enabledDates })
    }

    handleOpenUsers() {
        this.setState({ openIrresponsable: !this.state.openIrresponsable })
    }

    handleSelectPM(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params, selectedRows: [] }, this.componentDidMount)
    }

    handleSelectSubBranch(event) {
        const { target } = event
        const { params } = this.state
        params.sub_branch_id = target.value
        this.setState({ params })
    }

    handleEdit(params) {
        const { user } = this.props
        const body = { ...params }
        body.id = this.props.match.params.id
        body.companyId = user.account.user.idCompany
        const { editActivity } = this.props
        editActivity(body)
    }

    handleSign() {
        const { selectedRows, reason } = this.state
        const { signChecklists } = this.props
        const body = {
            checklist_ids: selectedRows,
            reason,
            date_signed: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        }
        signChecklists(body)
    }

    handleDownloadRegisters() {
        const { selectedRows } = this.state
        const body = {
            checklist_ids: selectedRows
        }
        fetch('https://app.safeasy.cl/api/model/pdf/multiple-checklists.php', {
            method: 'post',
            body: JSON.stringify(body),
        }).then(response => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement("a")
                a.href = url
                a.download = `Reporte`
                a.click()
            })
        }).catch(() => callSnackbar("Error al descargar documento", "error"))
    }

    async handleCreateRegisterDocument() {
        const { match } = this.props
        const { selectedRows, params } = this.state
        const body = {
            checklist_ids: selectedRows,
            preventive_measure_id: params.preventive_measure,
            activity_id: match.params.id
        }
        // Generate document and fetch id
        this.setState({ waitingDownload: true })
        const documentResponse = await generateRegistersDocument(body)
        if (documentResponse.data.status !== "success") {
            this.setState({ waitingDownload: false })
            return callSnackbar("No se pudo generar el documento", "error")
        }
        const documentInfo = documentResponse.data.info
        const documentId = documentInfo.id

        // Download document and add QR with ID
        const documentPDF = await fetch('https://app.safeasy.cl/api/model/pdf/multiple-checklists.php', {
            method: 'post',
            body: JSON.stringify(body),
        })

        const pdfBytes = await documentPDF.arrayBuffer()
        const bytes = new Uint8Array(pdfBytes)
        let reportPDF = await PDFDocument.load(bytes)

        const code = `${documentId}--c`
        const validateUrl = `${window.location.origin}/validate/${code}`
        await addQrToFile(reportPDF, `Reporte consolidado`, validateUrl)
        this.setState({ waitingDownload: false })
    }

    handleSelectRow(row) {
        return () => {
            const { selectedRows } = this.state
            if (selectedRows.includes(row.id)) {
                selectedRows.splice(selectedRows.indexOf(row.id), 1)
            } else {
                selectedRows.push(row.id)
            }
            this.setState({ selectedRows })
        }
    }

    handleDownload(row) {
        const { user } = this.props
        return async () => {
            const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_CHECKLIST_PDF}?id=${row.id}&u=${user.account.user.id}`
            const signedDocument = await mergeMultiplePdfFilesAndGet([url], "Reporte de registro o formulario")
            const code = `${row.id}--l`
            const validateUrl = `${window.location.origin}/validate/${code}`
            addQrToFile(signedDocument, `${moment().format("DD-MM-YYYY")}-${row.preventive_measure}-${row.branch_name}`, validateUrl)
        }
    }

    handleChangeReason(event) {
        const { target } = event
        this.setState({ reason: target.value })
    }

    handleSelectAll(ids) {
        this.setState({ selectedRows: ids })
    }

    handleSingRegisters() {
        this.setState({ openSigns: true })
    }

    setFormInfo(info) {
        const { enterprise, activities } = this.props
        const params = activities.params
        const filteredWorkers = filterActiveWorkers()
        info.map((input) => {
            switch (input.name) {
                case "user_id": {
                    input.options = transformToOptions(filteredWorkers)
                    return input
                }
                case "probability_id": {
                    input.options = transformToOptions(params?.probabilities || [])
                    return input
                }
                case "consequence_id": {
                    input.options = transformToOptions(params?.consequences || [])
                    return input
                }
                case "residual_probability_id": {
                    input.options = transformToOptions(params?.probabilities || [])
                    return input
                }
                case "residual_consequence_id": {
                    input.options = transformToOptions(params?.consequences || [])
                    return input
                }
                case "risks_id": {
                    input.options = transformToOptions(params?.risks || [])
                    return input
                }
                case "epps_id": {
                    input.options = transformToOptions(params?.epps || [])
                    return input
                }
                case "measures_id": {
                    input.options = transformToOptions(params?.preventive_measures || [])
                    return input
                }
                case "branch_id": {
                    input.options = transformToOptions(filterActiveBranches(enterprise.branches))
                    return input
                }
                default:
                    return input
            }
        })

        return info
    }

    render() {
        const { activities: activities_array } = this.state
        const { activities, classes, settings, checklists, match, branch } = this.props
        const {
            loading,
            params,
            enabledDates,
            selectedRows,
            openSigns,
            reason,
            openIrresponsable,
            waitingDownload
        } = this.state

        const filteredWorkers = filterActiveWorkers()
        const selectedWorkers = filteredWorkers.filter(worker => params.sub_branch_id === "all" || worker.sub_branch_id.toString() === params.sub_branch_id).map(u => u.id)

        const allActivities = activities_array || []
        const allMeasuresValues = allActivities.find(activity => activity.id === match.params.id)?.preventive_measures || []
        const allMeasures = Array.isArray(allMeasuresValues[0]) ? allMeasuresValues[0] : allMeasuresValues


        const allChecklists = checklists.done
            .filter(
                checklist => checklist.activity_id?.toString() === match.params.id
                    && checklist.preventive_measure_id === params.preventive_measure
                    && (selectedWorkers.includes(checklist.user_id) || checklist.user_id === "0")
            ).filter(ch => enabledDates ? moment(ch.date_done).isBetween(moment(params.date_start), moment(params.date_end), null, "[]") : true)



        const allAnswers = allChecklists.map(checklist => {
            const baseExtra = checklist.extra_fields || []
            return checklist.checklist_items
                .concat(baseExtra.concat([{ label: "observation", value: checklist.observations }])
                    .concat([{ label: "user", value: checklist.user }])
                    .concat([{ label: "date_done", value: checklist.date_done }])
                    .concat([{ label: "time_done", value: checklist.hour_done }])
                    .concat([{ label: "id", value: checklist.id }])
                    .concat([{ label: "signs", value: checklist.signs }])
                )
        }
        )

        const settingChecklists = settings.checklists.all || []
        const selectedMeasure = settingChecklists.find(measure => measure.id === params.preventive_measure)
        const selectedExtraFields = selectedMeasure?.extra_fields || []
        const selectedChecklistElements = selectedMeasure?.checklist_items || []


        const responsibleUsers = allChecklists.map(ch => ch.user)

        const tableInfo = selectedChecklistElements.concat(selectedExtraFields).map(element => ({
            name: element.name || element.label, label: element.name || element.label, type: element?.type || "item"
        }))


        tableInfo.push({ name: "Observaciones", label: "observation" })
        tableInfo.push({ name: "Responsable", label: "user" })
        tableInfo.push({ name: "Fecha", label: "date_done" })
        tableInfo.push({ name: "Hora", label: "time_done" })
        tableInfo.push({ name: "Firmas", label: "signs", render: SignCell })

        const finalInfo = tableInfo.map(el => {
            if (el?.type === "img") return { ...el, render: ImageCell }
            if (el?.type === "item") return {
                ...el, format: (value, element) => {
                    console.log(element)
                    return value
                }
            }
            return el
        })

        const processedData = allAnswers.map(answer => {

            // Factor for IDS
            function getFactor(item) {
                const crit = item?.answer?.criticality
                if (crit === 4) return 2
                if (crit === 3) return 1.5
                return 1
            }

            const filtered = answer
                .filter(item => item?.counter === 1 && item?.answer?.counter !== null)
            let total = { answer: { counter: 0 } }
            if (filtered.length > 0) {
                total = filtered
                    .reduce((x, y) => ({ answer: { counter: (getFactor(x) * parseInt(x?.answer?.counter, 10) + getFactor(y) * parseInt(y?.answer?.counter, 10)) } }))
            }
            const totalCounters = total?.answer?.counter
            const keys = answer.map(element => element.name || element.label)
            const values = answer.map(element => {
                if (element.name) {
                    return `${element.answer.value}${element.answer?.counter > 0 ? ` / ${element.answer.counter}` : ""}`
                } else if (element.label) {
                    if (element.label === "IDS") {
                        return element?.value ? ((totalCounters * 100) / element.value).toFixed(2) : 0
                    }
                    return element.value
                }
                return null
            })
            const result = {}
            keys.forEach((key, index) => { result[key] = values[index] })
            return result
        })

        const tableData = processedData.sort((a, b) => {
            if (moment(a.date_done) > moment(b.date_done)) return -1
            if (moment(a.date_done) < moment(b.date_done)) return 1
            return 0
        })

        const irresponsibleUsers = filteredWorkers.filter(worker => !responsibleUsers.includes(worker.name.split(" - ")[0]))
        const permissions = !PermissionBoolean(["eact"])

        const posibleBranches = [{ value: "all", label: "Todos" }]
        const posibleIds = []

        const subBranches = branch.sub_branches || []
        filteredWorkers.forEach(user => {
            const hasSubBranch = !!user?.sub_branch_id
            const subBranch = hasSubBranch ?
                subBranches.find(sb => sb.id === user.sub_branch_id.toString()) :
                "No Contratista"
            if (hasSubBranch && subBranch) {
                if (!posibleIds.includes(subBranch?.id)) {
                    posibleIds.push(subBranch?.id)
                    posibleBranches.push({ value: user.sub_branch_id.toString(), label: subBranch?.name })
                }
            }
        })

        return (
            <div className={classes.container}>
                <MutableInfoCard
                    title="Datos de la Actividad"
                    submit="Guardar"
                    subtitle="Editar actividad"
                    submitAction={this.handleEdit}
                    showInfo={this.setFormInfo(activityEditInfo)}
                    formInfo={this.setFormInfo(activityEditInfo)}
                    response={activities.selected}
                    loading={loading}
                    disableEdit={permissions}
                />
                <ChecklistAccessSetting checklists={allMeasures.map(ch => ({ ...ch, activity_id: activities.selected.id }))} />
                <div className={classes.middleContainer}>
                    <div className={classes.input}>
                        <SelectInput
                            label="Registro o formulario"
                            value={params.preventive_measure}
                            onChange={this.handleSelectPM}
                            options={transformToOptions(allMeasures)}
                            name="preventive_measure"
                        />
                        <Divider style={{ margin: 12 }} />
                        <DateInput
                            label="Fecha inicio"
                            value={params.date_start}
                            name="date_start"
                            onChange={this.handleSelectPM}
                            disabled={loading}
                        />
                        <DateInput
                            label="Fecha de término"
                            value={params.date_end}
                            name="date_end"
                            onChange={this.handleSelectPM}
                            disabled={loading}
                        />
                        <Collapse in={!!params.preventive_measure}>
                            <Divider style={{ margin: 12 }} />
                            <Typography className={classes.link} onClick={this.handleOpenUsers}>
                                Mostrar colaboradores que no han participado
                            </Typography>
                        </Collapse>
                    </div>
                    {loading && <LoaderAnimator />}
                </div>
                <Collapse in={!!params.preventive_measure}>
                    <div className={classes.middleContainer}>
                        <div className={classes.input}>
                            <SelectInput
                                label="Contratista"
                                value={params.sub_branch_id}
                                onChange={this.handleSelectSubBranch}
                                options={posibleBranches}
                                name="preventive_measure"
                            />
                        </div>
                    </div>
                </Collapse>
                {selectedRows.length > 0 &&
                    <div className={classes.signButton}>
                        <div>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="large"
                                onClick={this.handleSingRegisters}
                                disabled={waitingDownload}
                            >
                                Firmar registro o formulario(s)
                            </Button>
                        </div>
                        <div>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="large"
                                onClick={this.handleCreateRegisterDocument}
                                disabled={waitingDownload}
                            >
                                Descargar informe registro o formulario(s)
                            </Button>
                            {waitingDownload && <MiniLoaderAnimator loading />}
                        </div>
                    </div>
                }
                <GeneralTable
                    info={finalInfo}
                    data={tableData}
                    selectable
                    exportable
                    handleSelect={this.handleSelectRow}
                    selected={selectedRows}
                    selectAll={this.handleSelectAll}
                    name="tabla-registros y formularios"
                    actions={[{ name: "Descargar Resumen", icon: GetApp, action: this.handleDownload, color: "primary" }]}
                />
                <SignRegisters reason={reason} changeReason={this.handleChangeReason} signRegisters={this.handleSign} open={openSigns} onClose={this.handleOpenSigns} />
                <IrresponsableUsers id={activities.selected.branch_id} users={irresponsibleUsers} open={openIrresponsable} onClose={this.handleOpenUsers} />
            </div>
        )
    }
}

Activity.propTypes = {}

Activity.defaultProps = {
    workers: { all: [] },
    activities: { selected: [{}], params: [{}] },
}

const mapStateToProps = (state) => ({
    user: state.user,
    workers: state.workers,
    activities: state.activities,
    enterprise: state.enterprise,
    branch: state.branch,
    settings: state.settings,
    checklists: state.checklists
})

const mapDispatchToProps = (dispatch) => ({
    getWorkers: (body) => dispatch(getWorkersAction(body)),
    getDoneChecklists: (body) => dispatch(getDoneChecklistsAction(body)),
    getSettingsChecklists: () => dispatch(getSettingChecklistsAction()),
    signChecklists: body => dispatch(signChecklistAction(body)),
    ...activityDispatcher(dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(Activity))
