import React, { Component } from 'react'
import { Tab, Typography, withStyles } from '@material-ui/core'
import MyTabs from '../../../Shared/MyTabs'
import autobind from '../../../Utils/autobind'
import TabPanel from '../../../Shared/TabPanel'
import Workflows from './Workflows'
import { createSettingsWorkflow, editSettingsWorkflow, getSettingsWorkflows } from '../../../API/workflows'

const style = () => ({
  container: {
    marginTop: 36,
    margin: 12
  },
  tabs: {
    background: 'white',
    marginTop: 12,
  }
})

// const tipos = {
//   1: "Accidentes",
//   2: "Incidentes",
//   3: "Procedimientos",
//   4: "Protocolos",
//   5: "Generales"
// }

class Processes extends Component {
  constructor() {
    super()
    this.state = {
      tab: 0,
      workflows: []
    }
    autobind(Processes, this)
  }

  async componentDidMount() {
    const response = await getSettingsWorkflows()
    if (response?.data?.status !== "success") return false
    const workflows = response.data.info
    this.setState({ workflows })
  }

  handleChangeTab(event, value) {
    this.setState({ tab: value })
  }

  async handleCreate(body) {
    const response = await createSettingsWorkflow(body)
    if (response?.data?.status !== "success") return false
    const { workflows } = this.state
    workflows.push(response.data.info)
    this.setState({ workflows })

  }

  async handleEdit(body) {
    const response = await editSettingsWorkflow(body)
    console.log(response)
  }

  render() {
    const { workflows } = this.state
    const { classes } = this.props
    const { tab } = this.state
    return (
      <div className={classes.container}>
        <Typography variant="h1">Configuración de procesos</Typography>
        <div className={classes.tabs}>
          <MyTabs value={tab} onChange={this.handleChangeTab}>
            <Tab value={0} label={<Typography variant="h2">Accidentes</Typography>} />
            <Tab value={1} label={<Typography variant="h2">Incidentes</Typography>} />
            <Tab value={2} label={<Typography variant="h2">Reclamos</Typography>} />
            <Tab value={3} label={<Typography variant="h2">Generales</Typography>} />
          </MyTabs>
        </div>
        <TabPanel index={0} value={tab}>
          <Workflows type={1} workflows={workflows} onCreate={this.handleCreate} onEdit={this.handleEdit} />
        </TabPanel>
        <TabPanel index={1} value={tab}>
          <Workflows type={2} workflows={workflows} onCreate={this.handleCreate} onEdit={this.handleEdit} />
        </TabPanel>
        <TabPanel index={2} value={tab}>
          <Workflows type={3} workflows={workflows} onCreate={this.handleCreate} onEdit={this.handleEdit} />
        </TabPanel>
        <TabPanel index={3} value={tab}>
          <Workflows type={5} workflows={workflows} onCreate={this.handleCreate} onEdit={this.handleEdit} />
        </TabPanel>
      </div>
    )
  }
}


export default withStyles(style)(Processes)