import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import CalendarContext from './CalendarContext'
import moment from 'moment'
import MobileDay from './MobileDay'

const style = () => ({

})

class MobileDays extends Component {
  renderDays(days) {
    return days.map(day => <MobileDay day={day} />)
  }

  render() {
    const { classes } = this.props
    const { events, date } = this.context
    console.log(date)
    const amountOfDays = moment(date).daysInMonth()
    const startOfMonth = moment(date).startOf("month")
    const daysWithEvents = []
    const allDays = Array.from(new Array(amountOfDays)).map((_, index) => startOfMonth.clone().add(index, 'days'))
    allDays.forEach(day => {
      const dayEvents = events.filter(event => moment(event.date_scheduled).isSame(day, 'day'))
      if (!!dayEvents.length) {
        daysWithEvents.push({ date: day, events: dayEvents })
      }
    })
    console.log(daysWithEvents)

    return (
      <div className={classes.container}>
        {this.renderDays(daysWithEvents)}
      </div>
    )
  }
}

MobileDays.contextType = CalendarContext

export default withStyles(style)(MobileDays)