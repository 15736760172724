import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import GeneralTable from '../../../Shared/GeneralTable'
import moment from 'moment'
import { percentageCell } from '../../../Utils/formats'

const style = theme => ({
  container: {
    padding: 12
  },
  titleContainer: {
    background: theme.palette.primary.main,
    padding: 12,
    "& > *": {
      color: "white"
    },
    maxWidth: 500,
    marginBottom: 12
  }
})

class ChecklistsResume extends Component {

  render() {
    const { classes, match, checklists, workers } = this.props
    const option = match.params.option
    const pendings = checklists.pending || []
    const done = checklists.done || []
    const allWorkers = workers.all || []

    // week
    const periodPending = pendings.filter(checklist => moment(checklist.date_scheduled).isSame(new Date(), option))
    const periodDone = done.filter(checklist => moment(checklist.date_scheduled).isSame(new Date(), option))
    const periodOverdue = periodPending.filter(checklist => checklist.status === "Vencido")
    // const allPerdiod = periodPending.concat(periodDone)

    const periodTableData = allWorkers.map(worker => {
      const workerDone = periodDone.filter(cl => cl.user_id === worker.id).length
      const workerOverdue = periodOverdue.filter(cl => cl.user_id === worker.id).length
      return {
        name: worker.name,
        overdued: periodOverdue.filter(cl => cl.user_id === worker.id).length,
        scheduled: periodPending.filter(cl => cl.user_id === worker.id).length,
        done: workerDone,
        compliance: workerDone + workerOverdue > 0 ? workerDone / (workerDone + workerOverdue) * 100 : 100
      }
    }).filter(data => {
      return (data.overdued + data.scheduled + data.done > 0)
    })

    const periodTableInfo = [
      { label: "name", name: "Nombre" },
      { label: "overdued", name: `Total Vencido ${option === "week" ? "Semana" : "Mes"} actual` },
      { label: "scheduled", name: `Total Programado ${option === "week" ? "Semana" : "Mes"} actual` },
      { label: "done", name: `Total Realizado ${option === "week" ? "Semana" : "Mes"} actual` },
      { label: "compliance", name: "Cumplimiento", format: percentageCell },
    ]
    return (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h1">{`Registros y formularios por supervisor - ${option === "week" ? "Semana" : "Mes"} Actual`}</Typography>
        </div>
        <GeneralTable
          data={periodTableData}
          info={periodTableInfo}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checklists: state.checklists,
  workers: state.workers
})

export default connect(mapStateToProps)(withStyles(style)(ChecklistsResume))