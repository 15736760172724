import { Bookmark, BookmarkBorder, ErrorOutline, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import { IconButton, Tooltip, Typography, Box } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { deleteBaseItem } from "../../../../API/base_checklists"
import { editBaseChecklistItem, editItemCategory } from "../../../../API/settings_checklist"
import useToggle from "../../../../Hooks/ToogleHook"
import newTheme from "../../../../newTheme"
import DeleteButton from "../../../../Shared/Buttons/DeleteButton"
import SeamlessInput from "../../../../Shared/Inputs/SeamlessInput"
import { SetupChecklistContext } from "./ChecklistSetup"
import CritsMenu from "./CritsMenu"
import PointsMenu from "./PointsMenu"

const css = {
	item: {
		background: newTheme.palette.blue.transparent,
		margin: '6px 0',
		borderRadius: 1,
		padding: 0.5,
		display: 'flex',
		alignItems: 'center',
		gap: 3,
		flexWrap: 'wrap'
	},
	item_name: {
		flexBasis: '60%',
		flexGrow: 1
	},
	item_actions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flexGrow: 1
	},
}

const colors = [
	newTheme.palette.grey.dark,
	newTheme.palette.blue.main,
	newTheme.palette.gold.main,
	newTheme.palette.warning.main,
	newTheme.palette.error.main
]

function Item({ item }) {
	const [mounted, setMounted] = useState(false)
	const [name, setName] = useState(item.name)
	const [points, setPointsValue] = useState(item.points)
	const [crit, setCritValue] = useState(item.default_criticality)
	const [openCrits, setCrits] = useToggle(false)
	const [openPoints, setPoints] = useToggle(false)
	const [counter, setCounter] = useState(item.counter)
	const critAnchor = useRef(null)
	const pointsAnchor = useRef(null)
	const { setChecklist } = useContext(SetupChecklistContext)

	async function onEdit(info) {
		const body = { ...item, name, ...info }
		const response = await editBaseChecklistItem(body)
		setChecklist(response.data.info)
	}

	async function changeCategory(direction) {
		const response = await editItemCategory({ ...item, direction })
		setChecklist(response.data.info)
	}

	useEffect(() => {
		if (!openCrits && !openPoints && mounted) {
			const body = { points, default_criticality: crit, counter }
			onEdit(body)
		}
	}, [openCrits, openPoints, points, crit, counter, mounted])

	function onClose(callback) { return () => { callback(); setMounted(true) } }

	async function onBlur() {
		const body = { ...item, name }
		const response = await editBaseChecklistItem(body)
		setChecklist(response.data.info)
	}

	async function onDelete() {
		const body = { ...item }
		const response = await deleteBaseItem(body)
		setChecklist(response.data.info)
	}


	return (
		<Box sx={css.item}>
			<Box sx={css.item_name}>
				<SeamlessInput
					onBlur={onBlur}
					name="name"
					width='100%'
					color="transparent"
					value={name}
					placeholder="Ingrese nombre del elemento..."
					onChange={e => setName(e.target.value)}
				/>
			</Box>
			<Box sx={css.item_actions}>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Puntaje asociado</Typography>}>
					<IconButton ref={pointsAnchor} onClick={setPoints}>
						<Typography variant='subtitle1'>{points}pts</Typography>
					</IconButton>
				</Tooltip>
				<Typography variant='subtitle1'>|</Typography>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Mover hacia abajo</Typography>}>
					<IconButton onClick={() => changeCategory("down")}>
						<KeyboardArrowDown />
					</IconButton>
				</Tooltip>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Mover hacia arriba</Typography>}>
					<IconButton onClick={() => changeCategory("up")}>
						<KeyboardArrowUp />
					</IconButton>
				</Tooltip>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Criticidad por defecto del item</Typography>}>
					<IconButton ref={critAnchor} onClick={setCrits}>
						<ErrorOutline style={{ color: colors[crit], height: 24, width: 24 }} />
					</IconButton>
				</Tooltip>
				<Tooltip title={<Typography variant='subtitle1' style={{ color: 'white' }}>Contador del unidades</Typography>}>
					<IconButton onClick={() => { setCounter(c => c === 1 ? 0 : 1); setMounted(true) }}>
						{counter === 1 ? <Bookmark style={{ color: newTheme.palette.secondary.main }} /> : <BookmarkBorder />}
					</IconButton>
				</Tooltip>
				<DeleteButton onClick={onDelete} />
			</Box>
			<CritsMenu value={crit} onSelect={setCritValue} open={openCrits} anchorEl={critAnchor.current} onClose={onClose(setCrits)} />
			<PointsMenu value={points} onChange={e => setPointsValue(e.target.value)} open={openPoints} anchorEl={pointsAnchor.current} onClose={onClose(setPoints)} />
		</Box>
	)
}

export default Item