import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Chip, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { getSupervisedDetails } from '../../../API/users'
import newTheme from '../../../newTheme'
import BigTooltip from '../../../Shared/BigTooltip'
import ImageContainer from '../../../Shared/ImageContainer'
import { checklistsUrl, findingsUrl } from '../../../Utils/baseUrls'
import { inline, inline_space, text_space } from '../../../Utils/defaultStyles'
import { completePath } from '../../../Utils/functions'
import CardTags from '../../Finding&Conformities/Card/CardTags'
import { SupervisionContext } from './SupervisionHome'

const css = {
	checklist: {
		background: newTheme.palette.background.main,
		padding: 2,
		margin: '6px 0',
		borderRadius: 2,
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between'
	},
	tag: {
		background: 'white',
		borderRadius: '24px',
		padding: '6px 12px'
	},
	container: {
		maxHeight: 350,
		overflow: 'auto'
	},
	title: {
		padding: '12px 6px'
	},
	message: {
		padding: '0 6px'
	}
}

function isDate(date) { return date !== "0000-00-00" }

function SupervisedFinding({ finding = {} }) {

	const { colors } = useContext(SupervisionContext)
	console.log(finding)
	return (
		<Box sx={css.checklist}>
			<Box>
				<Typography variant='subtitle2'>{finding.checklist_item_name}</Typography>
				<Box>
					<Typography variant='caption'>Detectado el: {finding.date_detected}</Typography>
				</Box>
				<ImageContainer src={completePath(finding.evidence, checklistsUrl)} border={12} width={64} height={64} />
				<Typography variant='subtitle2'>Responsable: {finding.user || "No asignado..."}</Typography>
				<Typography variant='subtitle1'>Acción inmediata: {finding.inmediate_action || "No especifica"}</Typography>
				{["Terminado", "Aprobado"].includes(finding.status) &&
					<Box >
						<Typography variant='subtitle1'>Comentarios: {finding.solution_comment}</Typography>
						<ImageContainer src={completePath(finding.file, findingsUrl)} border={12} width={64} height={64} />
						<Box>
							{isDate(finding.date_solved) && <Typography variant='caption'>Resuelto el: {finding.date_solved}</Typography>}
						</Box>
						<Box>
							{isDate(finding.date_approved) && <Typography variant='caption'>Aprobado el: {finding.date_approved}</Typography>}
						</Box>
					</Box>
				}
			</Box>
			<Box sx={inline}>
				<Chip label={finding.status} color={colors[finding.status]} />
				<CardTags tags={finding?.tags || []} />
			</Box>
		</Box>
	)
}

function SupervisedFindings({ id, dates, supervisedUser }) {

	const [findings, setFindings] = useState([])

	useEffect(() => {
		async function fetchData() {
			const body = { id, type: "findings", ...dates }
			const response = await getSupervisedDetails(body)
			setFindings(response.data.info)
		}

		if (!!id) { fetchData() }

	}, [id, dates])

	console.log(findings)

	if (!findings.length) return (
		<Box>
			<Typography variant='h4' sx={css.title} >Hallazgos de {supervisedUser?.name}</Typography>
			<Typography variant='subtitle1' sx={css.message} >No hay registros de este usuario entre las fechas seleccionadas...</Typography>
		</Box>
	)

	return (
		<Box sx={css.main}>
			<Typography variant='h4' sx={css.title} >Hallazgos de {supervisedUser?.name}</Typography>
			<Box sx={css.container}>
				{findings.map(finding => <SupervisedFinding key={finding.id} finding={finding} />)}
			</Box>
		</Box>
	)
}

export default SupervisedFindings