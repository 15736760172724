import { Divider, Paper, Typography, withStyles } from "@material-ui/core"
import React, { Component } from "react"
import AddElementButton from "../../Shared/AddElementButton"
import GeneralForm from "../../Shared/GeneralForm"
import GeneralTable from "../../Shared/GeneralTable"
import MutableInfoCard from "../../Shared/Cards/MutableInfoCard"
import autobind from "../../Utils/autobind"
import { newItemForm } from "./info"
import FormContext from "../../Shared/Cards/Provider"

const style = () => ({
    container: {
        margin: 12,
    },
    paper: {
        marginTop: 24,
        padding: 12,
    },
    title: {
        margin: "24px 0 0 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    divider: {
        marginBottom: 12,
    },
    rotated: {
        transition: "all 0.3s linear",
        transform: "rotate(45deg)",
    },
    normal: {
        transition: "all 0.3s linear",
        transform: "rotate(0deg)",
    },
})

class Revision extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {},
            open: false,
        }
        autobind(Revision, this)
    }

    handleOpenForm() {
        this.setState({ open: !this.state.open })
    }

    handleChange(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    render() {
        const { classes } = this.props
        const { params, open } = this.state
        return (
            <div className={classes.container}>
                <MutableInfoCard title="Revision de calidad" />
                <div className={classes.title}>
                    <Typography variant="h1">Items</Typography>
                    <div>
                        <AddElementButton
                            onClick={this.handleOpenForm}
                            name="Agregar Item"
                        />
                    </div>
                </div>
                <Divider className={classes.divider} />
                <Paper square>
                    <FormContext.Provider value={{ handleChange: this.handleChange, params }}>
                        <GeneralForm
                            info={newItemForm}
                            open={open}
                            title="Agregar Item"
                        />
                    </FormContext.Provider>
                </Paper>
                <GeneralTable />
            </div>
        )
    }
}

export default withStyles(style)(Revision)
