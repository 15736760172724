import React, { Component } from 'react'
import { Button, Paper, Typography, withStyles } from '@material-ui/core'
import MutableInfoCard from '../../Shared/Cards/MutableInfoCard'
import { filterActiveWorkers } from '../../Utils/filters'
import { addQrToFile, mergeMultiplePdfFilesAndGet, transformToOptions } from '../../Utils/functions'
import { addFileToElementChecklist, answerChecklist, commentElement, counterChecklist, createChecklist, createFindingFromChecklist, critChecklist, editChecklist, finishChecklist, getChecklist, saveChecklist, setChecklistMetadata, setChecklistParticipants, setChecklistSupply, setSubdivision } from '../../API/checklists'
import autobind from '../../Utils/autobind'
import ChecklistContext from './ChecklistContext'
import ChecklistTitle from './Components/ChecklistTitle'
import ChDescription from '../Checklists/ChecklistComponents/ChDescription'
import CritIndex from '../Checklists/ChecklistComponents/CritIndex'
import ChecklistElements from './Components/ChecklistElements'
import Participants from '../Checklists/ChecklistComponents/Participants'
import ChSigns from '../Checklists/ChecklistComponents/ChSigns'
import ExtraFields from './Components/ExtraFields'
import TextInput from '../../Shared/Inputs/TextInput'
import { connect } from 'react-redux'
import { getCompliance, getPoints, getProgress, newCheckForExtraFinished } from '../Checklists/ChecklistComponents/utils'
import moment from 'moment'
import { callSnackbar } from '../../Utils/snackbar'
import { CheckCircleOutline, Save } from '@material-ui/icons'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'
import { getWorkersAction } from '../../Actions/EnterpriseAction'
// import { checkIfCategoryisFull } from './utils'
import { sendMail } from '../../API/workflows'
import Comments from './Components/Comments'
import NewFindingFromChecklist from './Components/NewFindingFromChecklist'
import ReactGA from 'react-ga'
import { notifyAuthorization, notifyEndChecklist } from '../../API/notifications'
import { getSettingsSupplies, getSupplies } from '../../API/supplies'
import ChecklistSupplies from './Components/ChecklistSupplies'
import Authorizations from './Components/Authorizations'
import { signAuthorization } from '../../API/authorizations'
import ChecklistStructure from './Components/ChecklistStructure'

const style = theme => ({
  container: {
    margin: 12
  },
  section: {
    background: 'white',
    borderRadius: 10,
    margin: '12px 0',
    padding: 12
  },
  endButton: {
    textAlign: 'end',
    marginTop: 24
  },
  progressLabel: {
    position: "sticky",
    top: "78px",
    zIndex: "5",
    width: 250,
  },
  label: {
    background: theme.palette.primary.main,
    maxWidth: "210px",
    borderTopRightRadius: "24px",
    borderBottomRightRadius: "24px",
    opacity: 0.7,
    margin: '3px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 12,
    marginLeft: 0,
    '&>h6': {
      color: 'white',
      padding: 6
    }
  },
  responsible: {
    background: theme.palette.green.main,
    padding: '6px 12px',
    borderRadius: 5,
    marginBottom: 24,
    transition: 'all 0.2s linear',
    float: 'right',
    '& > *': {
      color: 'white'
    }
  },
  responsibleBig: {
    background: theme.palette.green.main,
    padding: '24px 12px',
    borderRadius: 5,
    textAlign: 'center',
    marginBottom: 12,
    transition: 'all 0.2s linear',
    '& > *': {
      color: '#1a1a1a'
    }
  },
  responsibleOffline: {
    background: theme.palette.grey.main,
    padding: '24px 12px',
    borderRadius: 5,
    textAlign: 'center',
    marginBottom: 12,
    transition: 'all 0.2s linear',
    '& > *': {
      color: '#1a1a1a'
    }
  },
  button: {
    textAlign: 'right',
    marginBottom: 24
  },
  loader: {
    display: 'flex',
    background: theme.palette.purple.light,
    position: 'fixed',
    alignItems: 'center',
    padding: 6,
    borderRadius: 8,
    '& > *': {
      color: 'white',
      margin: '0 6px'
    },
    top: '50%',
    right: -150,
    zIndex: 3,
    transition: 'all 0.2s ease-in-out'
  },
  show: {
    right: 12
  },
  warning: {
    textAlign: 'center',
    marginTop: 24,
    '& > *': {
      color: theme.palette.red.main
    }
  },
  signs: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
    margin: '12px 0',
    borderRadius: 8,
    "@media (max-width:500px)": {
      width: '100%',
      margin: '12px 0',
    },
  },
})

class ChecklistContainer extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      globalLoading: false,
      checklist: {},
      extrafields: [],
      observations: "",
      hasJustFinished: false,
      isAdminEditing: false,
      families: [],
      supplies: []
    }
    this.timer = null
    autobind(ChecklistContainer, this)
  }

  async componentDidMount() {
    const { match: { params: { id } }, online, checklist: { pending = [] }, getWorkers } = this.props
    ReactGA.pageview("checklist")
    getWorkers()
    if (online.status) {
      const body = { id }
      this.setState({ loading: true })
      const checklistResponse = await getChecklist(body)
      const families_response = await getSettingsSupplies()
      const supplies_response = await getSupplies()
      const { data: { info: families } } = families_response
      const { data: { info: supplies } } = supplies_response
      if (checklistResponse.data.status !== "success") return this.setState({ loading: false, globalLoading: false })
      const checklist = checklistResponse.data.info
      const { extra_fields: extrafields, observations } = checklist
      const indexedExtrafields = Array.isArray(extrafields) ? extrafields.map((e, index) => ({ ...e, index })) : []

      this.setState({ checklist, loading: false, extrafields: indexedExtrafields, observations, globalLoading: false, families, supplies })
    } else {
      const offlineChecklist = pending.find(ch => ch.id === id)
      const { extra_fields: extrafields, observations } = offlineChecklist
      const indexedExtrafields = Array.isArray(extrafields) ? extrafields.map((e, index) => ({ ...e, index })) : []
      this.setState({
        checklist: offlineChecklist, loading: false, extrafields: indexedExtrafields, observations, globalLoading: false
      })
    }

  }

  simulateAnswer(body, replace = "") {
    const { online: { status }, updateChecklist } = this.props
    let finalBody = { ...body }
    if (replace !== "") {
      const [original, newkey] = replace.split("=>")
      const tempValue = finalBody[original]
      delete finalBody[original]
      finalBody[newkey] = tempValue
    }
    const { checklist: { checklist_items: items }, checklist } = this.state
    const item = items.find(item => item.id === body.checklist_item_id)
    const { answer } = item

    const keysToReplace = Object.keys(finalBody).filter(key => Object.keys(answer).includes(key))
    keysToReplace.forEach(key => answer[key] = finalBody[key])
    !status && updateChecklist(checklist)
  }

  simulateEnd(body) {
    const { checklist } = this.state
    const { updateChecklist } = this.props
    const newChecklist = { ...checklist, ...body }
    updateChecklist(newChecklist)
    this.setState({ checklist: newChecklist })
  }

  simulateFinding(body) {
    const { checklist } = this.state
    const { updateChecklist } = this.props
    const newChecklist = { ...checklist }
    newChecklist.findings.push(body)
    updateChecklist(newChecklist)
    this.setState({ checklist: newChecklist })
  }

  async handleEdit(body) {
    const { match: { params: { id } }, updateChecklist } = this.props
    body.id = id
    this.setState({ loading: true })
    const response = await editChecklist(body)
    if (response.data.status !== "success") return this.setState({ loading: false })
    const checklist = response.data.info
    updateChecklist(checklist)
    this.setState({ checklist, loading: false })
  }

  async handleCreateFinding(body) {
    const { match: { params: { id } }, updateChecklist, online: { status } } = this.props
    const online = status
    body.id = id
    if (online) { body.file = null }
    this.simulateFinding(body)
    this.setState({ loading: true && online })
    const response = await createFindingFromChecklist(body)
    if (response.data.status !== "success") return this.setState({ loading: false })
    const checklist = response.data.info
    online && updateChecklist(checklist)
    online && this.setState({ checklist, loading: false })
  }

  async handleAnswerElement(body) {
    const { match: { params: { id } }, online: { status } } = this.props
    const online = status
    body.checklist_id = id
    this.simulateAnswer(body, "answer=>value_id")
    this.setState({ globalLoading: true && online })
    const response = await answerChecklist(body)
    online && this.processResponse(response)
  }

  async handleCritElement(body) {
    const { match: { params: { id } }, online: { status } } = this.props
    const online = status
    body.checklist_id = id
    this.simulateAnswer(body)
    this.setState({ globalLoading: true && online })
    const response = await critChecklist(body)
    online && this.processResponse(response)
  }

  async handleSetSupply(body) {
    const response = await setChecklistSupply(body)
    const { data: { info: checklist } } = response
    this.setState({ checklist })
  }

  async handleSetSubdivision(body) {
    const response = await setSubdivision(body)
    const { data: { info: checklist } } = response
    this.setState({ checklist })
  }

  async handleSignAuthorization(body) {
    const { checklist: og_checklist } = this.state
    const { data, notify, index, last } = body
    this.setState({ globalLoading: true })
    const response = await signAuthorization(data)
    if (notify && !last) {
      const next_authorization = og_checklist.authorizations[index + 1]
      next_authorization.users.forEach(user => {
        notifyAuthorization(user, window.location.href, og_checklist.preventive_measure, og_checklist)
      })
      callSnackbar("Notificación de avance enviada", "info")
    }
    if (notify && last) {
      const users_to_notify_end = og_checklist.notified_users
      console.log(users_to_notify_end)
      users_to_notify_end.forEach(user => {
        notifyEndChecklist(user, window.location.href, og_checklist.preventive_measure, og_checklist)
      })
      callSnackbar("Notificación de términio enviada", "info")
    }
    const { data: { info: checklist } } = response
    this.setState({ checklist, globalLoading: false })
  }

  async handleFileElement(body, rawBody, index) {
    const { match: { params: { id } }, online: { status } } = this.props
    console.log(body)
    const online = status
    body.append("checklist_id", id)
    rawBody.checklist_id = id
    this.simulateAnswer(rawBody, `file=>file${index}`)
    this.setState({ globalLoading: true && online })
    const response = await addFileToElementChecklist(body, rawBody, index)
    online && this.processResponse(response)
  }

  async handleCommentElement(body) {
    const { match: { params: { id } }, online: { status } } = this.props
    const online = status
    body.checklist_id = id
    this.simulateAnswer(body)
    this.setState({ globalLoading: true && online })
    const response = await commentElement(body)
    online && this.processResponse(response)
  }

  async handleCountElement(body) {
    const { match: { params: { id } }, online: { status } } = this.props
    const online = status
    body.checklist_id = id
    this.simulateAnswer(body)
    this.setState({ globalLoading: true && online })
    const response = await counterChecklist(body)
    online && this.processResponse(response)
  }


  async handleSave() {
    const { match: { params: { id: checklist_id } }, online: { status }, user } = this.props
    ReactGA.event({
      category: "event",
      action: "finish_checklist",
      label: "checklist",
    })
    const online = status
    const { extrafields: extra_fields, observations, checklist } = this.state
    const body = { checklist_id, extra_fields, observations, status: "Terminado", date_done: moment().format("YYYY-MM-DD"), time_done: moment().format("HH:mm:ss") }
    this.setState({ loading: true && online })
    this.simulateEnd(body)
    const response = await finishChecklist(body)
    // Notification for checklist owner
    notifyEndChecklist(user.account.user, window.location.href, checklist.preventive_measure, checklist)

    // Notification for notified users (settings) only if there are no authorizations
    const authorizations = checklist.authorizations || []
    const users_to_notify_end = checklist.notified_users
    if (!authorizations.length) {
      users_to_notify_end.forEach(user => {
        notifyEndChecklist(user, window.location.href, checklist.preventive_measure, checklist)
      })
      callSnackbar("Notificación de términio enviada", "info")
    }

    // Notification for first level authorizations (settings) only if there are >=1 authorizations
    if (!!authorizations.length) {
      const first_authorization = authorizations[0]
      first_authorization.users.forEach(user => {
        notifyAuthorization(user, window.location.href, checklist.preventive_measure, checklist)
      })
      callSnackbar("Notificación de avance enviada", "info")
    }

    if (online && response.data.status !== "success") return this.setState({ loading: false })
    this.setState({ hasJustFinished: true })
    online && this.componentDidMount()
  }

  async handleSaveProgress() {
    const { match: { params: { id: checklist_id } }, online: { status } } = this.props
    const online = status
    const { extrafields: extra_fields, observations, checklist } = this.state
    const body = { checklist_id, extra_fields, observations }
    this.setState({ globalLoading: true && online })
    this.simulateEnd(body)
    const response = await saveChecklist(body)
    if (online && response.data.status !== "success") return this.setState({ loading: false, globalLoading: false })
    online && this.setState({ checklist: { ...checklist, ...response.data.info }, globalLoading: false })
  }

  async handleSubmitParticipants(body) {
    this.setState({ loading: true })
    const { updateChecklist } = this.props
    const response = await setChecklistParticipants(body)
    if (response.data.status !== "success") return this.setState({ loading: false })
    const checklist = response.data.info
    updateChecklist(checklist)
    this.setState({ checklist, loading: false })
  }

  async handleCreateAnother() {
    const { history } = this.props
    const { checklist } = this.state
    const body = {
      user_id: checklist.user_id,
      date_scheduled: moment(new Date()).format("YYYY-MM-DD"),
      preventive_measure_id: checklist.preventive_measure_id,
      activity_id: checklist.activity_id,
    }
    this.setState({ loading: true })
    const response = await createChecklist(body)
    if (response.data.status !== "success") return this.setState({ loading: false })
    const newChecklist = response.data.info
    history.push(`/checklists/${newChecklist.id}`)
    this.setState({ checklist: newChecklist, loading: false }, this.componentDidMount)
  }

  processResponse(response) {
    if (response?.data?.status !== "success") {
      callSnackbar("Error de conexión", "error")
      return this.setState({ globalLoading: false })
    }

    const newAnswer = response.data.info
    const { checklist } = this.state
    const { updateChecklist } = this.props
    // const workers = filterActiveWorkers(false)
    const answerIndex = checklist.checklist_items.findIndex(item => String(item.id) === newAnswer.checklist_item_id)

    if (answerIndex === -1) {
      callSnackbar("Error de conexión", "error")
      return this.setState({ globalLoading: false })
    }

    checklist.checklist_items[answerIndex].answer = newAnswer.checklist_answer
    updateChecklist(checklist)
    this.setState({ checklist, globalLoading: false })
  }

  handleSendNotification(category) {
    const { checklist } = this.state
    const metadata = checklist?.metadata || {}
    const workers = filterActiveWorkers(false)
    const usersToAlert = !!category.users ? (Array.isArray(category.users) ? category.users : JSON.parse(category.users)) : []
    const disabledUsers = metadata?.disabled?.[category.id] || []
    const activeUsersToAlert = usersToAlert.filter(uta => !disabledUsers.includes(uta))
    activeUsersToAlert.push(checklist.user_id)
    const workersToAlert = [...new Set(activeUsersToAlert)].map(uta => workers.find(worker => worker.id === uta)).filter(Boolean)
    workersToAlert.forEach(worker => {
      const body = {
        subject: `Avance en ${checklist?.activity} - ${checklist?.preventive_measure}`,
        content: `
          <strong>${worker.name}</strong>
          <p>Se ha terminado la etapa ${category?.name} del registro ${checklist?.preventive_measure} y es necesario que continues con la siguiente</p>
          <p>En el enlace a continuación podrás revisar el registro: ${window.location.href}</p>
          `,
        emails: [worker.email],
        // emails: ["mati.eynaudi@gmail.com"],
        extra_mails: []
      }
      sendMail(body)
    })
    callSnackbar("Correo enviado a los responsables", "success")
  }

  handleSendGlobalNotification() {
    const { checklist } = this.state
    const metadata = checklist?.metadata || {}
    const workers = filterActiveWorkers(false)
    const categories = checklist.subtitles || []
    const allUsersToAlert = []
    categories.forEach(category => {
      const usersToAlert = !!category.users ? (Array.isArray(category.users) ? category.users : JSON.parse(category.users)) : []
      const disabledUsers = metadata?.disabled?.[category.id] || []
      const activeUsersToAlert = usersToAlert.filter(uta => !disabledUsers.includes(uta))
      activeUsersToAlert.push(checklist.user_id)
      const workersToAlert = [...new Set(activeUsersToAlert)].map(uta => workers.find(worker => worker.id === uta)).filter(Boolean)
      allUsersToAlert.push(workersToAlert)
    })
    const allWorkersToAlert = [...new Set(allUsersToAlert.flat())]
    allWorkersToAlert.forEach(worker => {
      const body = {
        subject: `Notificación registro: ${checklist?.activity} - ${checklist?.preventive_measure}`,
        content: `
          <strong>${worker.name}</strong>
          <p>Esta disponible el registro ${checklist?.preventive_measure} para ser completado</p>
          <p>En el enlace a continuación podrás revisar el registro: ${window.location.href}</p>
          `,
        emails: [worker.email],
        // emails: ["mati.eynaudi@gmail.com"],
        extra_mails: []
      }
      sendMail(body)
    })
    callSnackbar("Correo enviado a los responsables", "success")
  }

  handleBack() {
    const { history } = this.props
    history.push("/checklists")
  }

  handleChangeObservation(event) {
    const { target: { value: observations } } = event
    this.setState({ observations })
    if (!!this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(this.handleSaveProgress, 2000)
  }

  handleAnswerExtra(event) {
    const { extrafields } = this.state
    const { target } = event
    console.log(extrafields)
    console.log(target)
    extrafields[target.name].value = target.value
    this.setState({ extrafields })
    if (!!this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(this.handleSaveProgress, 2000)
  }

  handleDisableUser(user, category) {
    return async () => {
      const { checklist } = this.state
      const { online: { status } } = this.props
      const online = status
      const metadata = checklist.metadata || {}
      if (Object.keys(metadata).includes("disabled")) {
        const disabled = metadata.disabled
        if (Array.isArray(metadata.disabled[category])) {
          if (disabled[category].includes(user)) {
            disabled[category].splice(disabled[category].indexOf(user), 1)
          } else {
            disabled[category].push(user)
          }
        } else {
          disabled[category] = [user]
        }
      } else {
        metadata.disabled = { [category]: [user] }
      }
      const body = { metadata, checklist_id: checklist.id }
      const response = await setChecklistMetadata(body)
      if (online && response.data.status !== "success") return this.setState({ loading: false, globalLoading: false })
      online && this.setState({ checklist: { ...checklist, ...response.data.info }, globalLoading: false })
    }
  }

  handleDisableCategory(category) {
    return async () => {
      const { checklist } = this.state
      const { online: { status } } = this.props
      const online = status
      const metadata = checklist.metadata || {}
      if (Object.keys(metadata).includes("disable_categories")) {
        let disable_categories = metadata.disable_categories
        if (Array.isArray(metadata.disable_categories)) {
          if (disable_categories.includes(category)) {
            disable_categories.splice(disable_categories.indexOf(category), 1)
          } else {
            disable_categories.push(category)
          }
        } else {
          metadata.disable_categories = [category]
        }
      } else {
        metadata.disable_categories = [category]
      }
      console.log(metadata.disable_categories)
      const body = { metadata, checklist_id: checklist.id }
      const response = await setChecklistMetadata(body)
      if (online && response.data.status !== "success") return this.setState({ loading: false, globalLoading: false })
      online && this.setState({ checklist: { ...checklist, ...response.data.info }, globalLoading: false })
    }
  }

  async handleChangeMetadata(metadata) {
    const { checklist } = this.state
    const { online: { status } } = this.props
    const online = status
    const body = { metadata, checklist_id: checklist.id }
    const response = await setChecklistMetadata(body)
    if (online && response.data.status !== "success") return this.setState({ loading: false, globalLoading: false })
    online && this.setState({ checklist: { ...checklist, ...response.data.info }, globalLoading: false })
  }

  handleAddField(body) {
    const { extrafields } = this.state
    const lastIndex = extrafields.length - 1
    body.index = lastIndex + 1
    extrafields.push(body)
    this.setState({ extrafields })
  }

  handleUpdateFields(newFields) {
    const indexedExtrafields = Array.isArray(newFields) ? newFields.map((e, index) => ({ ...e, index })) : []
    this.setState({ extrafields: indexedExtrafields }, this.handleSaveProgress)
  }

  handleDownload(row, params) {
    const { user } = this.props
    const { checklist } = this.state
    return async () => {
      const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_CHECKLIST_PDF}?id=${row.id}&u=${user.account.user.id}`
      const parsed_url = new URL(url)
      if (params) {
        Object.keys(params).forEach(key => {
          parsed_url.searchParams.append(key, params[key])
        })
      }
      console.log(parsed_url.toString())
      const final_url = parsed_url.toString()
      const signedDocument = await mergeMultiplePdfFilesAndGet([final_url], "Reporte de registro o formulario")
      const code = `${row.id}--l`
      const validateUrl = `${window.location.origin}/validate/${code}`
      addQrToFile(signedDocument, `${moment(checklist.date_done).format("DD-MM-YYYY")
        } -${checklist.preventive_measure.replaceAll(".", "-")} -${checklist.branch_name} `, validateUrl)
    }
  }

  render() {
    const { classes, user, online } = this.props
    const { checklist, loading, observations, extrafields, hasJustFinished, globalLoading, isAdminEditing, supplies, families } = this.state
    const requireSigns = checklist?.require_signs === 1
    const progress = getProgress(checklist)
    const compliance = getCompliance(checklist)
    const partial_compliance = getCompliance(checklist, true)
    const points = getPoints(checklist)
    const answers = checklist?.checklist_items || []
    const responsable = checklist?.user_id?.toString()
    const currentUser = user?.account?.user?.id
    const level = user?.account?.user?.userType
    const notActualResponsable = responsable !== currentUser
    const actualResponsible = responsable === currentUser
    const isAdmin = level === 1
    const isAdminTazki = user?.account?.user?.name === "Admin Tazki"
    const isDone = checklist?.status === "Terminado"
    const isResponsibleForCategory = checklist?.subtitles?.map(s => Array.isArray(s.users) ? s.users : JSON.parse(s.users))?.flat()?.includes(currentUser)
    const hasResponsibleForCategories = checklist?.subtitles?.map(s => Array.isArray(s.users) ? s.users : JSON.parse(s.users))?.flat().filter(Boolean).length > 0
    const disabled = (isDone && !isAdminEditing) || (notActualResponsable && !isAdmin && !isAdminEditing && !isResponsibleForCategory)
    const disableExtraField = (isDone && !isAdminEditing) || (notActualResponsable && !isAdmin && !isAdminEditing)
    const notAResponsable = responsable === "0"
    const isInvited = notActualResponsable && checklist?.requested_participants?.map(String)?.includes(currentUser) && !isResponsibleForCategory
    const isExtraFinished = newCheckForExtraFinished(extrafields)
    const workers = filterActiveWorkers(false)
    const responsableWorker = workers.find(wk => wk.id === checklist?.user_id)
    const responsableSing = responsableWorker?.signs?.[0]
    const signUrl = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_USER_SIGNS}${responsableSing?.sign}`

    return (
      <div className={classes.container}>
        {checklist?.checklist_items?.length > 0 &&
          <div className={classes.progressLabel}>
            <div className={classes.label}>
              <Typography variant="subtitle1">Cumplimiento Parcial:</Typography>
              <Typography variant="subtitle1">{partial_compliance} %</Typography>
            </div>
            <div className={classes.label}>
              <Typography variant="subtitle1">Cumplimiento:</Typography>
              <Typography variant="subtitle1">{compliance} %</Typography>
            </div>
            <div className={classes.label}>
              <Typography variant="subtitle1">Avance:</Typography>
              <Typography variant="subtitle1">{progress} %</Typography>
            </div>
          </div>
        }
        <div className={`${classes.loader} ${globalLoading ? classes.show : classes.hide} `}>
          <Save />
          {globalLoading ? <MiniLoaderAnimator /> : <CheckCircleOutline style={{ height: 32, width: 32 }} />}
        </div>
        <ChecklistContext.Provider value={{
          loading,
          current: user?.account?.user,
          checklist,
          finished: disabled || isInvited,
          fields: extrafields,
          workers,
          notify: this.handleSendNotification,
          onAnswer: this.handleAnswerElement,
          onCrit: this.handleCritElement,
          onFile: this.handleFileElement,
          onCount: this.handleCountElement,
          onComment: this.handleCommentElement,
          onAnswerExtra: this.handleAnswerExtra,
          onAddExtraField: this.handleAddField,
          onUpdateExtraFields: this.handleUpdateFields,
          onGlobalNotify: this.handleSendGlobalNotification,
          onCreateFinding: this.handleCreateFinding,
          disableUser: this.handleDisableUser,
          disableCategory: this.handleDisableCategory,
        }}>
          {/* Editable information */}
          <MutableInfoCard
            title="Información registro o formulario"
            subtitle="Editar registro o formulario"
            formInfo={[{ name: "user_id", label: "Responsable", type: "select", options: transformToOptions(filterActiveWorkers(false)) }, { name: "date_scheduled", label: "Fecha programación", type: "date" }]}
            showInfo={[{ name: "user_id", label: "Responsable", type: "select", options: transformToOptions(filterActiveWorkers(false)) }, { name: "date_scheduled", label: "Fecha programación", type: "date" }]}
            response={checklist}
            loading={loading}
            submitAction={this.handleEdit}
            disableEdit={disabled || notActualResponsable}
          />

          {/* Structure */}
          <ChecklistStructure checklist={checklist} onSave={this.handleSetSubdivision} />

          {/* Content and data */}
          <ChecklistTitle download={this.handleDownload} />

          {/* Description */}
          <ChDescription checklist={checklist} />

          {/* Supplies */}
          <ChecklistSupplies supplies={supplies} checklist={checklist} families={families} onEdit={this.handleSetSupply} />

          {(!isAdminTazki && !isAdmin && notActualResponsable && !isInvited && !disabled && !isResponsibleForCategory) ?
            <>
              {notAResponsable ?
                <div className={classes.warning}>
                  <Typography variant="h5">Se debe asignar responsable para continuar</Typography>
                </div>
                :
                <div className={classes.warning}>
                  <Typography variant="h5">No eres el responsable de este registro o formulario, la información será visible una vez esté terminado</Typography>
                </div>
              }
            </>
            :

            <>

              {isInvited && requireSigns &&
                <>
                  {(isAdmin || actualResponsible) &&
                    <Participants
                      id={checklist?.id}
                      participants={checklist?.requested_participants || []}
                      onSubmit={this.handleSubmitParticipants}
                    />
                  }
                  <div className={classes.section}>
                    <ChSigns isInvited={isInvited} checklist={checklist} />
                  </div>
                </>
              }
              {isAdmin && (notActualResponsable || isDone) &&
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '24px 0' }}
                  onClick={() => this.setState({ isAdminEditing: !this.state.isAdminEditing })}
                >
                  {isAdminEditing ? "Guardar Registro" : "Editar Registro"}
                </Button>
              }
              {/* Elements */}
              {checklist?.invert_order === 0 ?
                <>
                  <CritIndex show={answers.length > 0 && !isInvited} />
                  <ChecklistElements />
                  {(isAdmin || actualResponsible || isResponsibleForCategory) && <NewFindingFromChecklist checklist={checklist} />}

                  {/* Extra fields */}
                  <ExtraFields disabled={disableExtraField} disableNew={level > 1} disableAlert={!hasResponsibleForCategories} />
                </>
                :
                <>
                  {/* Extra fields */}
                  <ExtraFields disabled={disableExtraField} disableNew={level > 1} disableAlert={!hasResponsibleForCategories} />
                  <CritIndex show={answers.length > 0 && !isInvited} />
                  <ChecklistElements />
                  {(isAdmin || actualResponsible || isResponsibleForCategory) && <NewFindingFromChecklist checklist={checklist} />}
                </>
              }

              {/* Signs */}
              {!isInvited && requireSigns &&
                <>
                  {(isAdmin || actualResponsible) &&
                    <Participants
                      element={checklist}
                      id={checklist?.id}
                      participants={checklist?.requested_participants || []}
                      onSubmit={this.handleSubmitParticipants}
                    />
                  }
                  <div className={classes.section}>
                    <ChSigns isInvited={isInvited} checklist={checklist} />
                  </div>
                </>
              }

              {/* Observations */}
              <div className={classes.section}>
                <Typography variant="h4">Observaciones</Typography>
                {(disabled || isInvited) ?
                  <Typography variant="subtitle1">{observations || "Sin observaciones..."}</Typography> :
                  <TextInput
                    name="observations"
                    label=""
                    onChange={this.handleChangeObservation}
                    value={observations}
                    disabled={disabled || isInvited}
                  />
                }
              </div>
              {points?.total > 0 &&
                <Paper style={{ padding: 12, maxWidth: 200 }}>
                  <Typography variant="h4">Puntaje Obtenido: {points.added}/{points.total}</Typography>
                </Paper>
              }

              {/* Authorizations */}
              {isDone && <Authorizations checklist={checklist} onSign={this.handleSignAuthorization} />}

              {/* Ending info and buttons */}
              {disabled && isDone ?
                <>
                  <Comments
                    checklist={checklist}
                    responsible={responsableWorker}
                    metadata={checklist?.metadata}
                    onSubmit={this.handleChangeMetadata}
                  />
                  {hasJustFinished &&
                    <>
                      {online.status ?
                        <div className={`${classes.responsibleBig} show`}>
                          <Typography variant="subtitle1">Completada por {checklist?.user} el {checklist?.date_done}</Typography>
                        </div>
                        :
                        <div className={`${classes.responsibleOffline} show`}>
                          <Typography variant="subtitle1">Completada por {checklist?.user} el {checklist?.date_done}</Typography>
                          <Typography variant="body1">Esta lista ha sido completada sin conexión, favor volver a conectar para confirmar realización</Typography>
                        </div>
                      }
                    </>
                  }
                  <div>
                    <div className={classes.button}>
                      <Button color="primary" variant="contained" onClick={this.handleBack}>Volver</Button>
                    </div>
                    {hasJustFinished && checklist?.quick_access === 1 && <div className={classes.button} onClick={this.handleCreateAnother}>
                      <Button variant="outlined" disabled={!online.status}>Realizar otro registro o formulario</Button>
                    </div>}
                  </div>
                  {!hasJustFinished &&
                    <div className={`${classes.responsible} show`}>
                      <Typography variant="subtitle1">Completada por {checklist?.user} el {checklist?.date_done}</Typography>
                      {!!responsableSing &&
                        <div>
                          <Paper className={classes.signs} square>
                            <img src={signUrl} height="75px" width="150px" alt="firma" />
                            <div className={classes.signInfo}>
                              <Typography variant="body2">{responsableSing?.name}</Typography>
                              <Typography variant="body1">{responsableWorker?.position}</Typography>
                              <Typography variant="body1">{responsableWorker?.rut || responsableSing?.rut}</Typography>
                            </div>
                          </Paper>
                        </div>
                      }
                    </div>
                  }
                </>
                :
                <div className={classes.endButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.handleSave}
                    disabled={progress < 100 || !isExtraFinished || (isInvited && !isAdmin) || (notActualResponsable && !isAdmin)}
                  >
                    Guardar y Terminar
                  </Button>
                </div>
              }
            </>
          }
        </ChecklistContext.Provider>
      </div>
    )
  }
}

function updateChecklistAction(checklist) {
  return {
    type: "UPDATE_CHECKLIST",
    payload: checklist
  }
}

const mapStateToProps = state => ({
  user: state.user,
  online: state.online,
  checklist: state.checklists,
  workers: state.workers,
})

const mapDispatchToProps = dispatch => ({
  updateChecklist: newChecklist => dispatch(updateChecklistAction(newChecklist)),
  getWorkers: () => dispatch(getWorkersAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ChecklistContainer))