import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import autobind from '../../Utils/autobind'
import { editTalk, editTalkContent, editTalkParticipants, endTalk, getAllTalks, getSettingsTalks, signResponsibleTalk, signTalkParticipant, uploadTalkFile } from '../../API/talks'
import MainInformationCard from './Components/MainInformationCard'
import LoaderAnimator from '../../Shared/LoaderAnimator'
import { callSnackbar } from '../../Utils/snackbar'
import ContentEditor from './ContentEditor'
import Participants from './Participants'
import ParticipantSigns from './Components/ParticipantSigns'
import { getAllWorkers } from '../../API/workers'
import { connect } from 'react-redux'
import moment from 'moment'
import TNTContext from './TNTContext'
import ResponsibleResume from './Components/ResponsibleResume'
import ResumeFinished from './Components/ResumeFinished'
import { editTraining, editTrainingContent, editTrainingParticipants, endTraining, getAllTrainings, getSettingsTrainings, signResponsibleTraining, signTrainingParticipant, uploadTrainingFile } from '../../API/trainings'
import { filterActiveWorkers } from '../../Utils/filters'
import VideoWatcher from './VideoWatcher'

const style = () => ({
  container: {
    margin: 24
  }
})

const functions = {
  edit: { talk: editTalk, train: editTraining },
  content: { talk: editTalkContent, train: editTrainingContent },
  participants: { talk: editTalkParticipants, train: editTrainingParticipants },
  end: { talk: endTalk, train: endTraining },
  all: { talk: getAllTalks, train: getAllTrainings },
  settings: { talk: getSettingsTalks, train: getSettingsTrainings },
  signOwner: { talk: signResponsibleTalk, train: signResponsibleTraining },
  signUser: { talk: signTalkParticipant, train: signTrainingParticipant },
  upload: { talk: uploadTalkFile, train: uploadTrainingFile }
}

const urls = {
  document: { talk: process.env.REACT_APP_TALKS_DOCUMENT_FOLDER, train: process.env.REACT_APP_TRAININGS_DOCUMENT_FOLDER },
  signs: { talk: process.env.REACT_APP_TALKS_SIGNS_FOLDER, train: process.env.REACT_APP_TRAININGS_SIGNS_FOLDER },
  pdf: { talk: process.env.REACT_APP_TALKS_PDF, train: process.env.REACT_APP_TRAININGS_PDF },
}

class ElementContainer extends Component {
  constructor() {
    super()
    this.state = {
      element: null,
      loading: false,
      elementType: "",
      talks: [],
      workers: []
    }
    autobind(ElementContainer, this)
  }

  async componentDidMount() {
    const isTalk = window.location.href.includes("talks")
    const elementType = isTalk ? "talk" : "train"
    const { match: { params: { id } } } = this.props
    const body = { id }
    // If talk
    this.setState({ loading: true })

    const response = await functions.all[elementType](body)
    if (response.data.status !== "success") return this.setState({ loading: false })
    const element = response.data.info
    console.log(element)
    const settingsResponse = await functions.settings[elementType]()
    if (settingsResponse.data.status !== "success") return this.setState({ loading: false })
    const talks = settingsResponse.data.info
    const workers = filterActiveWorkers()

    return this.setState({ element, talks, workers, loading: false, elementType })

  }

  async handleEditMainInfo(body) {
    const { element, elementType } = this.state
    body.id = element.id
    const response = await functions.edit[elementType](body)
    if (response.data.status !== "success") return callSnackbar("Error al editar", "error")
    const newElement = response.data.info
    this.setState({ element: newElement })
    return callSnackbar("Editado correctamente", "success")
  }

  async handleEditContent(content) {
    const { elementType } = this.state
    const { match } = this.props
    const body = {
      id: match.params.id,
      content
    }
    const response = await functions.content[elementType](body)
    if (response.data.status !== "success") return callSnackbar("Error al editar", "error")
    const newElement = response.data.info
    this.setState({ element: newElement })
    return callSnackbar("Editado correctamente", "success")
  }

  async handleEditParticipants(body) {
    const { elementType } = this.state
    const response = await functions.participants[elementType](body)
    if (response.data.status !== "success") return callSnackbar("Error al editar", "error")
    const newElement = response.data.info
    this.setState({ element: newElement })
    return callSnackbar("Editado correctamente", "success")
  }

  async handleParticipantSign(body) {
    const { elementType } = this.state
    const response = await functions.signUser[elementType](body)
    if (response.data.status !== "success") return callSnackbar("Error al firmar", "error")
    const newElement = response.data.info
    this.setState({ element: newElement })
    return callSnackbar("Firmado correctamente", "success")
  }

  async handleResponsibleSign(body) {
    const { elementType } = this.state
    const response = await functions.signOwner[elementType](body)
    if (response.data.status !== "success") return callSnackbar("Error al firmar", "error")
    const newElement = response.data.info
    this.setState({ element: newElement })
    return callSnackbar("Firmado correctamente", "success")
  }

  async handleEnd(body) {
    const { elementType } = this.state
    const response = await functions.end[elementType](body)
    if (response.data.status !== "success") return callSnackbar("Error al terminar", "error")
    const newElement = response.data.info
    this.setState({ element: newElement })
    return callSnackbar("Terminado correctamente", "success")
  }

  async uploadSavedFile(body) {
    const { elementType } = this.state
    const response = await functions.upload[elementType](body)
    if (response.data.status !== "success") return callSnackbar("Error al subir foto", "error")
    const newElement = response.data.info
    this.setState({ element: newElement })
    return callSnackbar("Foto agregada correctamente", "success")
  }

  render() {
    const { classes, user, branch } = this.props
    const { element, talks, loading, workers, elementType } = this.state

    if (loading || !element) return (<LoaderAnimator />)

    const level = user.account.user.userType
    const isMobile = window.innerWidth <= 500
    const isFinished = !moment(element.date_saved).format("YYYY-MM-DD").includes("Fecha")


    const isCurrentOwner = element?.user_id?.toString() === user?.account?.user?.id
    const isAbleToEnd = isCurrentOwner || level === 1
    const hasSigned = !isCurrentOwner && element?.participants_signs?.filter(sign => sign?.user_id?.toString() === user?.account?.user?.id?.toString())?.length > 0

    let allWorkers = workers
    const filteredWorkers = allWorkers
      .filter(worker => worker.branch_id === null || worker.branch_ids.includes(parseInt(branch?.global?.id)))
      .map(worker => ({ ...worker, name: `${worker.name} - ${worker.rut}`, isSupervised: String(worker?.user?.id) === String(user?.account?.user?.id) }))
    allWorkers = filteredWorkers
    if (level > 1 && !isCurrentOwner) {
      allWorkers = allWorkers.filter(worker => worker.id === user?.account?.user?.id?.toString())
    }
    if (isCurrentOwner) {
      allWorkers = allWorkers.filter(worker => worker.id !== user?.account?.user?.id?.toString())
    }

    const finalUrls = {}
    Object.keys(urls).forEach(key => {
      finalUrls[key] = urls[key][elementType]
    })

    const isTooEarly = moment().isBefore(moment(element.date_scheduled))

    return (
      <div className={classes.container}>
        <Typography variant="h1">Información de {elementType === "talk" ? "Charla" : "Capacitación"}</Typography>
        <TNTContext.Provider value={{ level, isMobile, isFinished, isCurrentOwner, isAbleToEnd, hasSigned, urls: finalUrls, elementType }}>
          <MainInformationCard element={element} onEdit={this.handleEditMainInfo} talks={talks} />
          <ContentEditor content={element.content} editable={level <= 2 && !isFinished} onSave={this.handleEditContent} />
          <VideoWatcher video={element.video} />
          {(level === 1 || isCurrentOwner) &&
            <Participants
              id={element.id}
              onSubmit={this.handleEditParticipants}
              participants={element?.requested_participants || []}
              element={element}
              disabled={isFinished}
            />
          }
          {isTooEarly ?
            <Typography
              style={{ margin: '24px 0' }}
              variant="h4">
              {`Aún no se pueden agregar firmas, debes esperar 
                hasta las 
                ${moment(element.date_scheduled).format("HH:mm [del] dddd DD [de] MMM [del] YYYY")}`}
            </Typography> :
            <ParticipantSigns workers={allWorkers} element={element} onSign={this.handleParticipantSign} />
          }
          {isFinished ?
            <ResumeFinished element={element} workers={allWorkers} />
            :
            isAbleToEnd && <ResponsibleResume onUpload={functions.upload[elementType]} element={element} onSign={this.handleResponsibleSign} onEnd={this.handleEnd} />
          }
        </TNTContext.Provider>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  branch: state.branch
})

export default connect(mapStateToProps)(withStyles(style)(ElementContainer))