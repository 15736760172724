import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import ImageContainer from '../../../../Shared/ImageContainer'

const style = () => ({
  img: {
    margin: 12,
    textAlign: 'center'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

class ImgField extends Component {
  renderImages() {
    const { data, field, classes } = this.props
    const images = []
    data.forEach(checklist => {
      const answer = checklist?.informacion_extra?.find(extra => extra.label === field?.label)
      if (!answer || !answer.value) return null
      images.push([answer.value, checklist?.fecha_realizado])
    })
    return images.map(image => {
      return (
        <div className={classes.img}>
          <ImageContainer height={64} width={64} src={`${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}${image[0]}`} alt="imagen" />
          <Typography variant="subtitle1">{image[1]}</Typography>
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderImages()}
      </div>
    )
  }
}

export default withStyles(style)(ImgField)