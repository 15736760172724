import React, { Component, Fragment } from "react"
import { Box, Dialog, FormControl, IconButton, InputLabel, MenuItem, Radio, Select, Typography, withStyles } from "@material-ui/core"
import autobind, { addToggle } from "../../Utils/autobind"
import TextInput from "./TextInput"
import { AddCircle } from "@material-ui/icons"
import { transformToOptions } from "../../Utils/functions"

const style = ({
	dialog: {
		padding: 24,
		'& > *': {
			margin: '12px 0'
		}
	},
	option: {
		display: 'flex',
		alignItems: 'center'
	},
	options: {
		maxHeight: 300,
		overflowX: 'auto'
	}
})
class SelectCreateInput extends Component {
	constructor() {
		super()
		this.state = {
			open: false,
			search: "",
			name: "",
			options: []
		}
		addToggle(SelectCreateInput, this, "dialog")
		autobind(SelectCreateInput, this)
	}

	async componentDidMount() {
		const { onFetch } = this.props
		const response = await onFetch()
		this.setState({ options: response.data.info })
	}

	handleClick() {
		this.setState({ open: !this.state.open })
	}

	handleChange(event, value) {
		const { onChange, name } = this.props
		if (!value) return null
		const newEvent = {
			target: {
				name, value: value.value.toString()
			}
		}
		onChange(newEvent)
	}

	handleChangeLong(option) {
		return () => {
			const { name, onChange } = this.props
			const event = {
				target: {
					name,
					value: option.value
				}
			}
			onChange(event)
			this.handleOpenDialog()
		}
	}

	handleSearch(event) {
		const { target: { value } } = event
		this.setState({ search: value })
	}

	renderMenu() {
		const { big } = this.props
		const { options } = this.state
		const allOptions = transformToOptions(options) || []
		return allOptions.map((option) => {
			return (
				<MenuItem value={option.value} key={option.value} style={{ fontSize: big ? 15 : "unset" }}>
					{option.color &&
						<div style={{ height: 12, width: 12, background: option.color, borderRadius: '50%', marginRight: 12 }} />
					}
					{option.label}
				</MenuItem>
			)
		})
	}

	async handleCreate() {
		const { onCreate } = this.props
		const { name } = this.state
		if (!onCreate) return null
		const response = await onCreate({ name })
		this.setState({ options: response.data.info })
	}

	renderLongMenu() {
		const { openDialog, name, options } = this.state
		const { classes, label, value, groupBy } = this.props
		const { search } = this.state
		const sValue = value ? value + "" : ""
		const filteredOptions = transformToOptions(options).filter(option => option.label.toLowerCase().includes(search.toLocaleLowerCase()))
		const groups = Array.from(new Set(options.map(groupBy)))
		return (
			<Dialog open={openDialog} onClose={this.handleOpenDialog} fullWidth maxWidth="sm">
				<div className={classes.dialog}>
					<Typography variant='h1'>Seleccionar {label}</Typography>
					<TextInput
						label="Buscar"
						placeholder="Buscar..."
						value={search}
						onChange={this.handleSearch}
					/>
					<Box style={{ display: 'flex' }}>
						<TextInput
							label="Nuevo elemento"
							placeholder="Ingrese nombre"
							value={name}
							onChange={e => this.setState({ name: e.target.value })}
						/>
						<IconButton onClick={this.handleCreate}>
							<AddCircle />
						</IconButton>
					</Box>
					{groups.map((group, gindex) => {
						const groupOptions = filteredOptions.filter(option => groupBy(option) === group).sort((a, b) => {
							if (a.label > b.label) return 1
							if (a.label < b.label) return -1
							return 0
						})
						return (
							<Fragment key={gindex}>
								<Typography variant='h4'>{group}</Typography>
								<div className={classes.options}>
									{groupOptions.map((option, index) => {
										const isSelected = option.value === sValue
										return (
											<div key={option.label + index} className={classes.option} onClick={this.handleChangeLong(option)}>
												<Radio checked={isSelected} />
												<Typography variant='subtitle1'>{option.label}</Typography>
											</div>
										)
									})}
								</div>
							</Fragment>
						)
					})}
				</div>
			</Dialog>
		)
	}

	render() {
		const { value, label, name, disabled = false, required, inputStyle, onChange } = this.props
		const { options } = this.state
		const sValue = value ? value + "" : ""


		// if (options.length < 10) {
		// 	return (
		// 		<>
		// 			<FormControl fullWidth style={{ margin: "12px 0" }}>
		// 				<Select
		// 					value={sValue || ""}
		// 					fullWidth
		// 					name={String(name)}
		// 					onChange={onChange}
		// 					variant="outlined"
		// 					displayEmpty
		// 					disabled={disabled}
		// 					className={inputStyle}
		// 				>
		// 					{this.renderMenu()}
		// 				</Select>
		// 				<InputLabel shrink variant="outlined">
		// 					{`${label}${required ? "*" : ""}`}
		// 				</InputLabel>
		// 			</FormControl>
		// 		</>
		// 	)
		// }

		return (
			<div style={{ position: 'relative', width: '100%' }}>
				<FormControl fullWidth style={{ margin: "12px 0" }}>
					<Select
						value={sValue || ""}
						fullWidth
						name={name}
						onChange={onChange}
						variant="outlined"
						displayEmpty
						disabled
						className={inputStyle}
						onClick={this.handleOpenDialog}
					>
						{this.renderMenu()}
					</Select>
					<InputLabel shrink variant="outlined">
						{`${label}${required ? "*" : ""}`}
					</InputLabel>
				</FormControl>
				{this.renderLongMenu()}
			</div>
		)
	}
}

SelectCreateInput.propTypes = {}

SelectCreateInput.defaultProps = {
	options: [],
	placeholder: "",
	groupBy: (option) => option.isSupervised !== undefined ? option.isSupervised ? "Supervisado" : "Otro" : ""
}

export default withStyles(style)(SelectCreateInput)