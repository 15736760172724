import { Brightness1, Brightness1Outlined, CheckCircle, CheckCircleOutline, HighlightOffOutlined } from '@material-ui/icons'
import { Box, Checkbox, Paper, Typography } from '@mui/material'
import { useContext } from 'react'
import newTheme from '../../newTheme'
import ImageContainer from '../../Shared/ImageContainer'
import EvaluationContext from '../Settings/Evaluations/EvaluationContext'

const css = {
	container: {
		padding: 2,
		margin: '12px 0',
		background: 'white',
		borderRadius: 2,
		position: 'relative'
	},
	option: {
		display: 'flex',
		alignItems: 'center',
		minWidth: 150
	},
	options: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start'
	},
	correct: {
		color: newTheme.palette.green.dark
	},
	incorrect: {
		color: newTheme.palette.red.main
	},
	mainOption: {
		background: 'whitesmoke',
		margin: 2,
		borderRadius: 1,
		padding: 1
	}
}

function Option({ option }) {

	const { onAnswer, finished } = useContext(EvaluationContext)

	function handleAnswer() {
		const body = { ...option }
		onAnswer(body)
	}

	const isCorrectAnswer = option.is_correct

	return (
		<Box sx={css.mainOption}>
			<Box sx={css.option} >
				<Checkbox
					color="success"
					icon={<Brightness1Outlined />}
					checkedIcon={<Brightness1 />}
					size="small"
					checked={option.answer}
					onClick={handleAnswer}
					disabled={finished}
				/>
				<Typography variant='subtitle1' sx={finished ? isCorrectAnswer ? css.correct : css.incorrect : {}} >{option.name}</Typography>
			</Box>
			{option.photo &&
				<Box style={{ maxWidth: 150, margin: '6px 0' }}>
					<ImageContainer src={option.photo} alt={"apoyo-item"} width="100%" border={6} />
				</Box>
			}
		</Box>
	)
}

function Question({ question }) {

	const { finished } = useContext(EvaluationContext)

	const options = question.options || []
	const finished_options = options.filter(option => {
		if (option.answer) return true
		if (option.is_correct) return true
	})
	const render_options = finished ? finished_options : options
	const isCorrectAnswer = finished && render_options.some(option => option.answer && option.is_correct)
	const isIncorrectAnswer = finished && render_options.some(option => option.answer && !option.is_correct)
	return (
		<Box sx={css.container}>
			{isCorrectAnswer &&
				<CheckCircleOutline style={{ color: newTheme.palette.green.dark, height: 32, width: 32, position: 'absolute', top: 6, right: 6 }} />
			}
			{isIncorrectAnswer &&
				<HighlightOffOutlined style={{ color: newTheme.palette.red.main, height: 32, width: 32, position: 'absolute', top: 6, right: 6 }} />
			}
			<Typography variant='subtitle1'><strong>{question?.name}</strong></Typography>
			{question.photo &&
				<Box style={{ maxWidth: 350, margin: '6px 0' }}>
					<ImageContainer src={question.photo} alt={"apoyo-item"} width="100%" border={6} />
				</Box>
			}
			<Box sx={!finished ? css.options : {}}>
				{render_options.map(option => <Option option={option} />)}
			</Box>
		</Box>
	)
}

export default Question