import instance from "../Utils/instance"
import instance2 from "../Utils/instance2"
import createSnackbar from "../Utils/snackbar"

export const setBranchAction = (branch, snackbar) => {
    const payload = {
        data: {
            status: "success",
            info: branch,
        },
    }
    if (snackbar) {
        snackbar("Centro de trabajo seleccionado!", { variant: "success" })
    }
    return {
        type: "SET_BRANCH",
        payload,
    }
}

export const createBranchAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "/branches/create.php",
        data: body,
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_BRANCH",
        payload: REQUEST,
    }
}

export const getBranchAction = (body) => {
    const REQUEST = instance2({
        method: "get",
        url: "/branches/get.php",
        params: body,
    })
    return {
        type: "GET_BRANCH",
        payload: REQUEST,
    }
}

export const getSubBranchesAction = (body) => {
    const REQUEST = instance2({
        method: 'get',
        url: 'subbranches/get.php',
        params: body
    })
    return {
        type: "GET_SUB_BRANCHES",
        payload: REQUEST
    }
}

export const setSubBranch = body => {
    return {
        type: "SET_SUB_BRANCH",
        payload: body
    }
}

export const createSubBranchAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'subbranches/create.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: 'CREATE_SUB_BRANCH',
        payload: REQUEST
    }
}

export const editSubBranchAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'subbranches/edit.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "EDIT_SUB_BRANCH",
        payload: REQUEST
    }
}

export const getSubBranchAction = body => {
    const REQUEST = instance2({
        method: 'get',
        url: 'subbranches/get.php',
        params: body
    })
    return {
        type: 'GET_SUB_BRANCH',
        payload: REQUEST
    }
}

export const createSubBranchUser = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'subbranch/create_user.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_SUB_BRANCH_USER",
        payload: REQUEST
    }
}

export const getSubBranchDocumentsAction = body => {
    const REQUEST = instance2({
        method: 'get',
        url: 'subbranches/get_documents.php',
        params: body
    })
    return {
        type: "GET_SUB_BRANCH_DOCUMENTS",
        payload: REQUEST
    }
}

export const editSubBranchDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: "subbranches/edit_document.php",
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "EDIT_SUB_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}

export const createSubBranchDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'subbranches/create_document.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_SUB_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}

export const deleteSubBranchDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'subbranches/delete_document.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "DELETE_SUB_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}

export const createSubBranchUserAction = body => {
    const REQUEST = instance2({
        method: 'post',
        url: 'subbranches/create_user.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_SUB_BRANCH_USER",
        payload: REQUEST
    }
}

export const signDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'subbranches/user_document_sign.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "SIGN_SUB_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}

export const signBranchDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'branches/user_document_sign.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "SIGN_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}

export const getBranchDocumentsAction = body => {
    const REQUEST = instance2({
        method: 'get',
        url: 'branches/get_documents.php',
        params: body
    })
    return {
        type: "GET_BRANCH_DOCUMENTS",
        payload: REQUEST
    }
}

export const editBranchDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: "branches/edit_document.php",
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "EDIT_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}

export const createBranchDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'branches/create_document.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "CREATE_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}

export const deleteBranchDocumentAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'branches/delete_document.php',
        data: body
    })
    createSnackbar(REQUEST)
    return {
        type: "DELETE_BRANCH_DOCUMENT",
        payload: REQUEST
    }
}