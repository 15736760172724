import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import { KeyboardArrowDown, Settings } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import GeneralTable from '../../Shared/GeneralTable'
import { connect } from 'react-redux'
import { getEnterpriseActivitiesAction, setAuthorizedUsersAction } from '../../Actions/ActivityActions'
import LoadingDialog from '../../Shared/LoadingDialog'
import ChecklistSettingsDialog from './ChecklistSettingsDialog'

const style = (theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      color: 'white',
      fontWeight: 600
    }
  },
  container: {
    padding: 12,
    margin: '24px 0',
    background: theme.palette.blue.main,
    borderRadius: 15
  },
  icon: {
    transform: 'rotate(0deg)',
    transition: 'all 0.2s ease-in-out'
  },
  openIcon: {
    transform: 'rotate(180deg)'
  },
  table: {
    marginTop: 12
  },
  dialog: {
    padding: 24
  }
})

const tableInfo = [
  { name: "Nombre", label: "name" },
]



class ChecklistAccessSetting extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      selectedChecklist: null,
      selectedWorkers: [],
      loading: false,
      openDialog: false
    }

    autobind(ChecklistAccessSetting, this)
  }

  componentDidUpdate() {
    const { selectedChecklist } = this.state
    if (!selectedChecklist) return null
    const newChecklists = this.props.checklists
    const newSelected = newChecklists.find(ch => ch.id === selectedChecklist.id)
    if (JSON.stringify(newSelected) !== JSON.stringify(selectedChecklist)) return this.setState({ selectedChecklist: newSelected })
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleOpenDialog(row) {
    return () => {
      this.setState({ openDialog: !this.state.openDialog, selectedChecklist: row })
    }
  }

  render() {
    const { open, selectedChecklist, loading, openDialog } = this.state
    const { classes, checklists } = this.props

    const actions = [
      { name: "Configurar accesos", icon: Settings, action: this.handleOpenDialog, color: "primary" },
    ]

    return (
      <div className={classes.container}>
        <LoadingDialog open={loading} />
        <div className={classes.title} onClick={this.handleOpen}>
          <Typography variant="subtitle1">Configuración registros y formularios</Typography>
          <KeyboardArrowDown className={`${classes.icon} ${open ? classes.openIcon : ""}`} />
        </div>
        <Collapse in={open}>
          <div className={classes.table}>
            <GeneralTable info={tableInfo} data={checklists} actions={actions} />
          </div>
        </Collapse>
        <ChecklistSettingsDialog checklist={selectedChecklist} open={openDialog} onClose={this.handleOpenDialog(null)} />
      </div>
    )
  }
}

ChecklistAccessSetting.propTypes = {

}


ChecklistAccessSetting.defaultProps = {
  checklists: []
}

const mapDispatchToProps = dispatch => ({
  setUsers: body => dispatch(setAuthorizedUsersAction(body)),
  getActivity: () => dispatch(getEnterpriseActivitiesAction()),
})

export default connect(null, mapDispatchToProps)(withStyles(style)(ChecklistAccessSetting))