import React, { Component } from 'react'
import { IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { addQrToFile, completePath, mergeMultiplePdfFilesAndGet } from '../../../Utils/functions'
import TNTContext from '../TNTContext'
import MiniLoaderAnimator from '../../../Shared/MiniLoaderAnimator'
import moment from 'moment'
import DownloadButton from '../../../Shared/DownloadButton'
import autobind from '../../../Utils/autobind'

const style = theme => ({
  container: {
    margin: '24px 0'
  },
  paper: {
    padding: 12,
    marginTop: 12,
    position: 'relative'
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: 12,
    width: '100%',
    '& > *': {
      width: '100%',
      maxWidth: 400
    }
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: "12px 0",
    "@media (max-width:500px)": {
      width: '100%',
    },
    '& > *:nth-child(2)': {
      fontWeight: 600
    }
  },
  file: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  observationContent: {
    padding: 12,
    borderRadius: 5,
    border: 'solid 1px lightgrey',
  },
  observation: {
    padding: "12px 0",
    margin: "0 12px",
    width: 400,
    "@media (max-width:500px)": {
      width: '100%',
      margin: 0
    },
  },
  userSigns: {
    margin: '12px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    background: '#f2f3f8',
    borderRadius: 5
  },
  userSignTitle: {
    marginTop: 12
  },
  observations: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%'
  },
  downloadButton: {
    textAlign: 'end'
  },
  signs: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
    margin: 12,
    "@media (max-width:500px)": {
      width: '100%',
      margin: '12px 0',
    },
  },
  signInfo: {
    '& > *': {
      margin: '3px 0'
    }
  },
  downloadText: {
    color: theme.palette.blue.main,
    cursor: 'pointer'
  },
})

class ResumeFinished extends Component {
  constructor() {
    super()
    this.state = {
      loadingDownload: false
    }
    autobind(ResumeFinished, this)
  }

  handleGetTypeName(singular = true) {
    const { elementType } = this.context
    if (singular) return elementType === "talk" ? "Charla" : "Capacitación"
    return elementType === "talk" ? "Charlas" : "Capacitaciones"
  }

  handleSeeFile(file) {
    return () => {
      const { urls } = this.context
      const url = completePath(file, urls.document)
      window.open(url)
    }
  }

  handleDownload(row, params) {
    return async () => {
      const { user, element } = this.props
      const { urls, elementType } = this.context
      const selected = element
      const file = selected.file
      const url1 = `${process.env.REACT_APP_IMG_URL}${urls.pdf}?id=${row.id}&u=${user?.account?.user?.id}`
      const parsed_url = new URL(url1)
      if (params) {
        Object.keys(params).forEach(key => {
          parsed_url.searchParams.append(key, params[key])
        })
      }
      console.log(parsed_url.toString())
      const final_url = parsed_url.toString()
      const url2 = process.env.REACT_APP_IMG_URL + urls.document + "/" + file
      this.setState({ loadingDownload: true })
      if (file.includes(".pdf")) {
        const signedFile = await mergeMultiplePdfFilesAndGet([final_url, url2], `Reporte ${this.handleGetTypeName()}`)
        const code = `${selected.id}--${elementType === "talk" ? "k" : "t"}`
        const validateUrl = `${window.location.origin}/validate/${code}`
        await addQrToFile(signedFile, `${row.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}}`, validateUrl)
        return this.setState({ loadingDownload: false })

      }
      const signedFile = await mergeMultiplePdfFilesAndGet([final_url], `${row.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}}`)
      const code = `${selected.id}--${elementType === "talk" ? "k" : "t"}`
      const validateUrl = `${window.location.origin}/validate/${code}`
      await addQrToFile(signedFile, `${row.name.replaceAll(".", "-")}-${moment().format("YYYY-MM-DD")}`, validateUrl)
      return this.setState({ loadingDownload: false })
    }
  }

  renderUserSignatures() {
    const { classes, element, workers } = this.props
    const { urls } = this.context
    const signatures = element.user_sign || []
    return signatures.map((sign) => {
      const imgUrl = process.env.REACT_APP_IMG_URL + urls.signs + '/' + sign.sign
      const selectedWorker = workers.find(worker => worker.id?.toString() === sign.user_id?.toString())
      return (
        <Paper className={classes.signs}>
          <img src={imgUrl} height="75px" width="150px" alt="firma" />
          <div className={classes.signInfo}>
            <Typography variant="body2">{sign.name}</Typography>
            <Typography variant="body1">{selectedWorker?.position}</Typography>
            <Typography variant="body1">{selectedWorker?.rut}</Typography>
            <Typography variant="caption">{sign.date}</Typography>
          </div>
        </Paper>
      )
    })
  }

  render() {
    const { classes, element, workers } = this.props
    const { loadingDownload } = this.state
    console.log(element)
    const participants = element.requested_participants || []
    console.log(participants)
    const requested_sub_enterprises = workers.filter(worker => participants.map(String).includes(String(worker.id))).map(x => x.sub_enterprise_id)
    console.log(requested_sub_enterprises)
    return (
      <div className={classes.container}>
        <Typography variant="h1">Firma supervisor e información final</Typography>
        <Paper className={classes.paper}>
          <div className={classes.infoContainer}>
            <div>
              <div className={classes.info}>
                <Typography variant="body1">Hora de inicio:</Typography>
                <Typography variant="body1">{element.date_start}</Typography>
              </div>
              <div className={classes.info}>
                <Typography variant="body1">Hora de Término:</Typography>
                <Typography variant="body1">{element.date_end}</Typography>
              </div>
            </div>
            <div>
              <div className={classes.info}>
                <Typography variant="body1">Documento original:</Typography>
                <Typography variant="body1" className={element.file ? classes.downloadText : ""} onClick={this.handleSeeFile(element.file)}>{element.file ? "Ver Documento" : "No contiene documento"}</Typography>
              </div>
            </div>
          </div>

          <Typography variant="h4" style={{ marginBottom: 12 }}>Documentos de {this.handleGetTypeName()}:</Typography>
          <div>
            {element?.file_saved ?
              element?.file_saved?.split("#").map((file, index) => {
                return (
                  <Typography variant="body1" className={file ? classes.downloadText : ""} onClick={this.handleSeeFile(file)}>{file ? `${index + 1}. Ver Documento` : "No contiene documento"}</Typography>
                )
              })
              :
              <Typography variant="body1">{"No contiene documento"}</Typography>
            }
          </div>
          <div className={classes.observations}>
            <div className={classes.observation}>
              <Typography variant="subtitle2">Observación participantes</Typography>
              <Typography variant="body1" className={classes.observationContent}>{element.participants_observations ? element.participants_observations : "Sin observación..."}</Typography>
            </div>
            <div className={classes.observation}>
              <Typography variant="subtitle2">Observación supervisores</Typography>
              <Typography variant="body1" className={classes.observationContent}>{element.user_observations ? element.user_observations : "Sin observación..."}</Typography>
            </div>
          </div>
          <Typography variant="subtitle1" className={classes.userSignTitle}>Firmas supervisor/es</Typography>
          <div className={classes.userSigns}>
            {this.renderUserSignatures()}
          </div>
          <DownloadButton onClick={this.handleDownload} params={element} disabled={loadingDownload} enableOnly={requested_sub_enterprises} />
          {/* <IconButton className={classes.downloadButton} onClick={this.handleDownload(element)} disabled={loadingDownload}>
            {loadingDownload ? <MiniLoaderAnimator /> : <GetApp />}
          </IconButton> */}
        </Paper>
      </div>
    )
  }
}

ResumeFinished.contextType = TNTContext

export default withStyles(style)(ResumeFinished)