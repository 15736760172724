import { Divider } from '@material-ui/core'
import { Edit, KeyboardArrowRight, Save } from '@material-ui/icons'
import { Box, Collapse, IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getSubdivisions } from '../../../API/subdivisions'
import useToggle from '../../../Hooks/ToogleHook'
import newTheme from '../../../newTheme'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../../Utils/functions'

const css = {
	container: {
		padding: 3,
		background: 'white',
		borderRadius: 2,
		margin: '24px 0',
		position: 'relative'
	},
	breadcrums: {
		display: 'flex',
		alignItems: 'center'
	},
	link: {
		cursor: 'pointer'
	},
	button: {
		position: 'absolute',
		top: 6,
		right: 6
	}
}

function checkIfShouldBeOpen(subdivision, selected) {
	const imSelected = selected?.id === subdivision.id
	const children = subdivision.children || []
	const oneChildSelected = children.map(child => checkIfShouldBeOpen(child, selected)).some(Boolean)
	return imSelected || oneChildSelected
}

function ChecklistStructure({ checklist, onSave }) {

	const [structure, setStructure] = useState([])
	const [selected, setSelected] = useState(checklist?.subdivision)
	const [options, setOptions] = useState()
	const [breadcrums, setCrums] = useState(getCrums())
	const [edit, toggleEdit] = useToggle(false)

	useEffect(() => {
		async function fetchData() {
			if (checklist?.branch_id) {
				const response = await getSubdivisions({ branch_id: checklist?.branch_id })
				setStructure(response.data.info)
				setSelected(checklist?.subdivision)
				setCrums(getCrums())
			}
		}

		if (!!checklist.branch_id) { fetchData() }
	}, [checklist])

	function onSelect(event) {
		const { target } = event
		const newSelected = options.find(op => String(op.id) === String(target.value))
		setSelected(newSelected)
	}

	useEffect(() => {
		setCrums(getCrums())
		setOptions(selected?.children || structure)
	}, [selected])

	function getCrums() {
		const names = [{ name: checklist?.branch?.name, element: null }]
		function addToBread(subs) {
			subs.forEach(subdivision => {
				if (checkIfShouldBeOpen(subdivision, selected)) {
					names.push({ name: subdivision.name, element: subdivision })
					const children = subdivision.children || []
					addToBread(children)
				}
			})
		}

		addToBread(structure)
		return names
	}

	function onEdit() {
		if (edit) { onSave({ id: checklist.id, subdivision_id: selected?.id || null }) }
		toggleEdit()
	}

	return (
		<Box sx={css.container}>
			<Box sx={css.breadcrums}>
				{breadcrums.map((crum, index) => {
					const imLast = index === breadcrums?.length - 1
					return (
						<>
							{index > 0 && <KeyboardArrowRight style={{ height: 28, width: 28 }} />}
							<Typography
								onClick={() => {
									if (!edit) return null
									setSelected(crum.element)
									setOptions(crum?.element?.children || structure)
								}}
								sx={edit ? css.link : {}}
								variant='h4'
								style={{ color: imLast ? newTheme.palette.blue.main : "" }}>
								{crum.name}
							</Typography>
						</>
					)
				})
				}
			</Box>
			<Collapse in={edit}>
				<Box>
					{!!options?.length &&
						<Box>
							<Divider style={{ margin: '12px 0' }} />
							<SelectInput onChange={onSelect} label="Seleccionar subdivisión" options={transformToOptions(options)} />
						</Box>
					}
				</Box>
			</Collapse>
			<IconButton sx={css.button} onClick={onEdit}>
				{edit ? <Save /> : <Edit />}
			</IconButton>
		</Box>
	)
}

export default ChecklistStructure