import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import moment from 'moment'
import Day from './Day'
import CalendarContext from './CalendarContext'

const style = () => ({
  container: {
    display: 'grid',
    gridTemplateColumns: "repeat(7, 1fr)"
  },
  day: {
    height: 150,
    border: '1px solid #ececec'
  },
  dayName: {
    textAlign: 'center',
    padding: 6
  }
})

function getTotalAmountOfDays(indexStart, totalMonth) {
  let totalAmountInCalendar = indexStart + totalMonth
  while (totalAmountInCalendar % 7 !== 0) {
    totalAmountInCalendar += 1
  }
  return totalAmountInCalendar
}

class DaysContainer extends Component {
  constructor() {
    super()
    this.state = {
      days: []
    }
  }

  componentDidMount() {
    this.handleReloadDays()
  }

  handleReloadDays(date) {
    const firstDayIndex = (moment(date).startOf("month").day() + 7 - 1) % 7 // To avoid negatives normalize to 7
    const monthDaysAmount = moment(date).daysInMonth()

    const totalOfDays = getTotalAmountOfDays(firstDayIndex, monthDaysAmount)
    const firstDayOfCalendar = moment(date).startOf("month").subtract(firstDayIndex, 'days')
    const days = Array.from(new Array(totalOfDays)).map((_, index) => firstDayOfCalendar.clone().add(index, 'day'))
    return days
  }

  render() {
    const { date } = this.context
    const dayNames = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
    const days = this.handleReloadDays(date)
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {dayNames.map(day => (<div className={classes.dayName}><Typography variant='h4'>{day}</Typography></div>))}
        {days.map(day => <Day day={day} key={day.format("YYYY-MM-DD")} />)}
      </div>
    )
  }
}

DaysContainer.contextType = CalendarContext

export default withStyles(style)(DaysContainer)