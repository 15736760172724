import { Box, Typography } from '@mui/material'

function InfoLine({ primary, secondary }) {
	return (
		<>
			<Box>
				<Typography variant='subtitle2'>{primary}:</Typography>
			</Box>
			<Box>
				<Typography variant='subtitle1'>{secondary}</Typography>
			</Box>
		</>
	)
}

export default InfoLine