import React, { Component } from 'react'
import { Button, Collapse, Typography, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import autobind, { addToggle } from '../../../Utils/autobind'
import ProgramContext from '../ProgramContext'

const style = () => ({
  container: {
    background: 'white',
    borderRadius: 8,
    padding: 12,
    width: 350,
    margin: '0 12px 12px 0',
    '& > *': {
      margin: '3px 0'
    }
  },
  subcontainer: {
    transition: 'all 0.2s ease-in-out',
    borderRadius: 8
  },
  open: {
    background: '#f2f3f8',
    padding: 8
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 8
  }
})

class ProgramCard extends Component {
  constructor() {
    super()
    this.state = {
      openRecords: false,
      openChildren: false
    }
    addToggle(ProgramCard, this, "records")
    addToggle(ProgramCard, this, "children")
    autobind(ProgramCard, this)
  }

  handleOpenProgram() {
    const { history, program } = this.props
    history.push(`/programs/view/${program.id}`)
  }

  render() {
    const { classes, program } = this.props
    const { openRecords, openChildren } = this.state
    const { getRecordName, getRecordTypeName } = this.context
    const items = program?.program_items || []
    const subprograms = program?.child_programs || []
    return (
      <div className={classes.container}>
        <Typography variant="h4">{program?.name}</Typography>
        {/* <Typography variant="subtitle1">{program?.date_start} - {program?.date_end}</Typography> */}
        <Typography variant='subtitle1'>{`Puntaje: ${program?.compliance}`}</Typography>
        <div className={`${classes.subcontainer} ${openRecords ? classes.open : ""}`}>
          <Typography variant="subtitle1" onClick={this.handleOpenRecords} >Registros: {items?.length}</Typography>
          <Collapse in={openRecords}>
            {items.map(item => {
              return (
                <div key={item.id} className={classes.item}>
                  <Typography variant='subtitle1'>{getRecordTypeName(item.record_type_id)}:</Typography>
                  <Typography variant='subtitle1'>{getRecordName(item.record_type_id, item.record_id)}</Typography>
                </div>
              )
            })}
          </Collapse>
        </div>
        <div className={`${classes.subcontainer} ${openChildren ? classes.open : ""}`}>
          <Typography variant="subtitle1" onClick={this.handleOpenChildren} >Programas asociados: {subprograms?.length}</Typography>
          <Collapse in={openChildren}>
            {subprograms.map(subprogram => {
              return (
                <div key={subprogram.id} className={classes.item}>
                  <Typography variant='subtitle1'>{subprogram.name}</Typography>
                </div>
              )
            })}
          </Collapse>
        </div>
        <div style={{ textAlign: 'end' }}>
          <Button size="small" color="primary" variant="outlined" onClick={this.handleOpenProgram}>
            Ver
          </Button>
        </div>
      </div>
    )
  }
}

ProgramCard.contextType = ProgramContext

export default withRouter(withStyles(style)(ProgramCard))