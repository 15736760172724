import React, { Component } from "react"
import {
    withStyles,
    Paper,
    Typography,
    IconButton,
} from "@material-ui/core"
import autobind from "../../Utils/autobind"
import { Edit, Cancel } from "@material-ui/icons"
import GeneralForm from "../GeneralForm"
import { getCellValue } from "../../Utils/functions"
import FormContext from "./Provider"
import LoaderAnimator from "../LoaderAnimator"

const style = theme => ({
    container: {
        position: "relative",
    },
    flex: {
        display: "flex",
        flexWrap: "wrap",
        padding: 12,
        "@media (max-width:500px)": {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
        },
    },
    info: {
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "unset",
        flexBasis: "21%",
        minWidth: 200,
        padding: "0 2%",
        margin: '12px 0',
        "@media (max-width:500px)": {
            display: 'unset',
            flexBasis: '100%',
            minWidth: 'unset',
        },
        '& > *:first-child > *': {
            fontWeight: 600,
            color: theme.palette.primary.main
        }
    },
    text: {
        margin: "3px 0 ",
        '&>p': {
            fontSize: "1.25rem",
            "@media (max-width:500px)": {
                fontSize: "0.95rem"
            },
        }
    },
    title: {
        padding: 24,
        "&>h1": {
            color: theme.palette.primary.main,
        }
    },
    button: {
        display: "flex",
        justifyContent: "flex-end",
    },
    formContainer: {
        overflowY: "hidden",
        transition: "all 0.2s linear",
    },
    form: {
        padding: 12,
    },
    loaderContainer: {
        height: 80,
        position: "relative",
        width: "100%",
    }
})

class MutableInfoCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            params: {}
        }
        autobind(MutableInfoCard, this)
    }

    componentDidMount() {
        const { response, formInfo } = this.props
        let { params } = this.state
        formInfo.forEach(input => {

            const responseValue = input.realName ? response[input.realName] && response[input.realName].forEach(element => element.id) : response[input.name]
            params[input.name] = responseValue
        })
        this.setState({ params })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.response !== this.props.response) {
            const { response, formInfo } = this.props
            let { params } = this.state
            formInfo.forEach(input => {
                const responseValue = input.realName ? response[input.realName] && response[input.realName].map(element => element.id) : response[input.name]
                params[input.name] = responseValue
            })
            this.setState({ params })
        }
    }

    handleEdit() {
        this.setState({ edit: !this.state.edit })
    }

    handleChange(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    handleKeyPress(e) {
        if (e.key === "Enter") {
            const { submitAction } = this.props
            submitAction()
            this.setState({ edit: false })
        }
    }

    handleSubmit() {
        const { params } = this.state
        this.setState({ edit: false })
        const { submitAction } = this.props
        submitAction(params)
    }

    renderInfo() {
        const { classes, showInfo } = this.props
        const { params } = this.state
        return showInfo.map((input) => {
            return (
                <div key={input.name} className={classes.info}>
                    <div className={classes.text} >
                        <Typography variant="subtitle1" >
                            {input.label}
                        </Typography>
                    </div>
                    <div className={classes.text}>
                        <Typography variant="subtitle2" >
                            {input.format ? input.format(getCellValue(params, input)) : getCellValue(params, input)}
                        </Typography>
                    </div>
                </div>
            )
        })
    }

    render() {
        const { classes, title, submit, formInfo, subtitle, loading, disableEdit } = this.props
        const { edit, params } = this.state
        return (
            <div>
                {!disableEdit &&
                    <div>
                        <div className={classes.button}>
                            <IconButton onClick={this.handleEdit} disabled={loading}>
                                {edit ? <Cancel /> : <Edit />}
                            </IconButton>
                        </div>
                        <FormContext.Provider value={{ handleChange: this.handleChange, params }}>
                            <GeneralForm
                                open={edit}
                                info={formInfo}
                                onSubmit={this.handleSubmit}
                                submit={submit}
                                title={subtitle}
                            />
                        </FormContext.Provider>
                    </div>
                }
                <Paper className={classes.container}>
                    <div className={classes.title}>
                        <Typography variant="h1">{title}</Typography>
                    </div>
                    <div className={classes.flex}>
                        {loading ? (
                            <div className={classes.loaderContainer}>
                                <LoaderAnimator />
                            </div>
                        ) : (
                            this.renderInfo()
                        )}
                    </div>
                </Paper>
            </div>
        )
    }
}

MutableInfoCard.propTypes = {}

MutableInfoCard.defaultProps = {
    response: {},
    title: "",
    formInfo: [{ name: 'Test', label: 'Test', type: 'text' }],
    showInfo: [],
    disableEdit: false
}

export default withStyles(style)(MutableInfoCard)
