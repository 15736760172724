import React, { Component } from "react"
import {
    withStyles,
    Paper,
    Tab,
    Typography,
    Divider,
} from "@material-ui/core"
import MyTabs from "../../Shared/MyTabs"
import autobind from "../../Utils/autobind"
import TabPanel from "../../Shared/TabPanel"
import GeneralTable from "../../Shared/GeneralTable"
import AddElementButton from "../../Shared/AddElementButton"
import Panel from "../Activities/Panel"

const style = () => ({
    container: {
        margin: 12,
    },
    title: {
        margin: "36px 0 12px 0 ",
    },
    divider: {
        marginBottom: 24,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 24,
    },
    inline: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    },
})

class Revisions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
        }
        autobind(Revisions, this)
    }

    handleChangeTab(event, value) {
        this.setState({ tab: value })
    }

    render() {
        const { classes } = this.props
        const { tab } = this.state
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h1">Revisiones de Calidad</Typography>
                    <AddElementButton name="Agregar revisión de calidad" />
                </div>
                <Panel />
                <Divider className={classes.divider} />
                <Paper square>
                    <MyTabs value={tab} onChange={this.handleChangeTab}>
                        <Tab
                            label={
                                <Typography variant="h1">En proceso</Typography>
                            }
                            value={0}
                        />
                        <Tab
                            label={
                                <Typography variant="h1">Realizadas</Typography>
                            }
                            value={1}
                        />
                    </MyTabs>
                </Paper>
                <TabPanel value={tab} index={0}>
                    <Typography variant="h1" className={classes.title}>
                        Revisiones Solicitadas en Proceso
                    </Typography>
                    <Divider className={classes.divider} />
                    <GeneralTable />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Typography variant="h1" className={classes.title}>
                        Revisiones de Calidad Realizadas
                    </Typography>
                    <Divider className={classes.divider} />
                    <GeneralTable />
                </TabPanel>
            </div>
        )
    }
}

export default withStyles(style)(Revisions)