import stateMaker from "../Utils/stateMaker"
import moment from 'moment'

const ChecklistReducer = (state = { pending: [], done: [], selected: [], calendar: [] }, action) => {
    switch (action.type) {
        case "GET_PENDING_CHECKLISTS":
            return stateMaker(state, "pending", "fetch", action)
        case "CREATE_CHECKLIST":
            return stateMaker(state, "pending", "create", action)
        case "GET_DONE_CHECKLISTS":
            return stateMaker(state, "done", "fetch", action)
        case "GET_CALENDAR_CHECKLISTS":
            return stateMaker(state, "calendar", "fetch", action)
        case "GET_CHECKLIST": {
            const newState = { ...state }
            const allChecklists = state.pending.concat(state.done)
            const newSelected = allChecklists.find(cl => cl.id.toString() === action.payload.id)
            newState.selected = newSelected
            return newState
        }
        case "UPDATE_CHECKLIST": {
            const newState = { ...state }
            const indexOfChecklist = state.pending.findIndex(ch => ch.id === action.payload.id)
            newState.pending[indexOfChecklist] = action.payload
            return newState
        }
        case "EDIT_CHECKLIST": {
            const newState = stateMaker(state, "selected", 'fetch', action)
            if (action.payload.data.status !== "success") return newState
            const allChecklists = state.pending
            const changedChecklist = action.payload.data.info
            const changedIndex = allChecklists.findIndex(cl => cl.id.toString() === changedChecklist.id.toString())
            newState.pending[changedIndex] = changedChecklist
            return newState
        }
        case "ANSWER_CHECKLIST": {
            const options = ["Si", "No", "N/A"]
            const newState = { ...state }
            const selectedChecklistFromPending = state.pending.find(cl => cl?.id?.toString() === action.payload.checklist_id)
            const selectedChecklistFromDone = state.done.find(cl => cl?.id?.toString() === action.payload.checklist_id)
            let isFromPending = selectedChecklistFromPending && !selectedChecklistFromDone
            let selectedChecklistIndex = 0
            if (isFromPending) {
                selectedChecklistIndex = state.pending.indexOf(selectedChecklistFromPending)
            } else {
                selectedChecklistIndex = state.done.indexOf(selectedChecklistFromDone)
            }

            const selectedChecklist = selectedChecklistFromPending || selectedChecklistFromDone

            const newChecklist = { ...selectedChecklist }
            const items = newState.selected.checklist_items
            const newItemId = action.payload.checklist_item_id
            console.log(newItemId)
            console.log(items)
            const newItem = items.find(item => String(item.id) === String(newItemId))
            console.log(newItem)
            newItem.answer = { ...newItem.answer, value_id: action.payload.answer, value: options[action.payload.answer - 1] }
            const newItems = items.map(item => {
                if (item.id.toString() === newItemId.toString()) {
                    return newItem
                }
                return item
            })
            newChecklist.checklist_items = newItems
            newState.selected.checklist_items = newItems
            if (isFromPending) {
                const newPendings = [...state.pending]
                newPendings[selectedChecklistIndex] = newChecklist
                newState.pending = newPendings
            } else {
                const newDones = [...state.done]
                newDones[selectedChecklistIndex] = newChecklist
                newState.done = newDones
            }
            return newState
        }
        case "CRITICITY_CHECKLIST": {
            const newState = { ...state }
            const selectedChecklistFromPending = state.pending.find(cl => cl.id.toString() === action.payload.checklist_id)
            const selectedChecklistFromDone = state.done.find(cl => cl.id.toString() === action.payload.checklist_id)
            let isFromPending = selectedChecklistFromPending && !selectedChecklistFromDone
            let selectedChecklistIndex = 0
            if (isFromPending) {
                selectedChecklistIndex = state.pending.indexOf(selectedChecklistFromPending)
            } else {
                selectedChecklistIndex = state.done.indexOf(selectedChecklistFromDone)
            }

            const selectedChecklist = selectedChecklistFromPending || selectedChecklistFromDone

            const newChecklist = { ...selectedChecklist }
            const items = newState.selected.checklist_items
            const newItemId = action.payload.checklist_item_id
            const newItem = items.find(item => String(item.id) === String(newItemId))
            newItem.answer = { ...newItem.answer, criticality: action.payload.criticality }
            const newItems = items.map(item => {
                if (item.id.toString() === newItemId.toString()) {
                    return newItem
                }
                return item
            })
            newChecklist.checklist_items = newItems
            newState.selected.checklist_items = newItems

            if (isFromPending) {
                const newPendings = [...state.pending]
                newPendings[selectedChecklistIndex] = newChecklist
                newState.pending = newPendings
            } else {
                const newDones = [...state.done]
                newDones[selectedChecklistIndex] = newChecklist
                newState.done = newDones
            }

            return newState
        }
        case "IMAGE_CHECKLIST": {
            const newState = { ...state }
            const selectedChecklistFromPending = state.pending.find(cl => cl.id.toString() === action.payload.checklist_id)
            const selectedChecklistFromDone = state.done.find(cl => cl.id.toString() === action.payload.checklist_id)
            let isFromPending = selectedChecklistFromPending && !selectedChecklistFromDone
            let selectedChecklistIndex = 0
            if (isFromPending) {
                selectedChecklistIndex = state.pending.indexOf(selectedChecklistFromPending)
            } else {
                selectedChecklistIndex = state.done.indexOf(selectedChecklistFromDone)
            }

            const selectedChecklist = selectedChecklistFromPending || selectedChecklistFromDone

            const newChecklist = { ...selectedChecklist }
            const items = newState.selected.checklist_items
            const newItemId = action.payload.checklist_item_id
            const newItem = items.find(item => String(item.id) === String(newItemId))
            newItem.answer = { ...newItem.answer, file: action.payload.file }
            const newItems = items.map(item => {
                if (String(item.id) === newItemId) {
                    return newItem
                }
                return item
            })
            newChecklist.checklist_items = newItems
            newState.selected.checklist_items = newItems

            if (isFromPending) {
                const newPendings = [...state.pending]
                newPendings[selectedChecklistIndex] = newChecklist
                newState.pending = newPendings
            } else {
                const newDones = [...state.done]
                newDones[selectedChecklistIndex] = newChecklist
                newState.done = newDones
            }

            return newState
        }

        case "IMAGE_CHECKLIST_2": {
            const newState = { ...state }
            const selectedChecklistFromPending = state.pending.find(cl => cl.id.toString() === action.payload.checklist_id)
            const selectedChecklistFromDone = state.done.find(cl => cl.id.toString() === action.payload.checklist_id)
            let isFromPending = selectedChecklistFromPending && !selectedChecklistFromDone
            let selectedChecklistIndex = 0
            if (isFromPending) {
                selectedChecklistIndex = state.pending.indexOf(selectedChecklistFromPending)
            } else {
                selectedChecklistIndex = state.done.indexOf(selectedChecklistFromDone)
            }

            const selectedChecklist = selectedChecklistFromPending || selectedChecklistFromDone

            const newChecklist = { ...selectedChecklist }
            const items = newState.selected.checklist_items
            const newItemId = action.payload.checklist_item_id
            const newItem = items.find(item => String(item.id) === String(newItemId))
            newItem.answer = { ...newItem.answer, file2: action.payload.file }
            const newItems = items.map(item => {
                if (String(item.id) === newItemId) {
                    return newItem
                }
                return item
            })
            newChecklist.checklist_items = newItems
            newState.selected.checklist_items = newItems

            if (isFromPending) {
                const newPendings = [...state.pending]
                newPendings[selectedChecklistIndex] = newChecklist
                newState.pending = newPendings
            } else {
                const newDones = [...state.done]
                newDones[selectedChecklistIndex] = newChecklist
                newState.done = newDones
            }

            return newState
        }

        case "IMAGE_CHECKLIST_3": {
            const newState = { ...state }
            const selectedChecklistFromPending = state.pending.find(cl => cl.id.toString() === action.payload.checklist_id)
            const selectedChecklistFromDone = state.done.find(cl => cl.id.toString() === action.payload.checklist_id)
            let isFromPending = selectedChecklistFromPending && !selectedChecklistFromDone
            let selectedChecklistIndex = 0
            if (isFromPending) {
                selectedChecklistIndex = state.pending.indexOf(selectedChecklistFromPending)
            } else {
                selectedChecklistIndex = state.done.indexOf(selectedChecklistFromDone)
            }

            const selectedChecklist = selectedChecklistFromPending || selectedChecklistFromDone

            const newChecklist = { ...selectedChecklist }
            const items = newState.selected.checklist_items
            const newItemId = action.payload.checklist_item_id
            const newItem = items.find(item => String(item.id) === String(newItemId))
            newItem.answer = { ...newItem.answer, file3: action.payload.file }
            const newItems = items.map(item => {
                if (String(item.id) === newItemId) {
                    return newItem
                }
                return item
            })
            newChecklist.checklist_items = newItems
            newState.selected.checklist_items = newItems

            if (isFromPending) {
                const newPendings = [...state.pending]
                newPendings[selectedChecklistIndex] = newChecklist
                newState.pending = newPendings
            } else {
                const newDones = [...state.done]
                newDones[selectedChecklistIndex] = newChecklist
                newState.done = newDones
            }

            return newState
        }

        case "SAVE_CHECKLIST": {
            const newState = { ...state }
            const selectedChecklist = state.pending.find(cl => cl.id.toString() === action.payload.checklist_id)
            const selectedChecklistIndex = state.pending.findIndex(cl => cl.id.toString() === action.payload.checklist_id)
            const newChecklist = { ...selectedChecklist }
            newChecklist.observations = action.payload.observations
            newChecklist.status = "Terminado"
            newChecklist.date_done = moment(new Date()).format("YYYY-MM-DD")
            newState.done.push(newChecklist)
            newState.pending.splice(selectedChecklistIndex, 1)
            newState.selected = newChecklist
            return newState
        }
        case "COMMENT_CHECKLIST": {
            const newState = { ...state }
            const selectedChecklistFromPending = state.pending.find(cl => cl.id.toString() === action.payload.checklist_id)
            const selectedChecklistFromDone = state.done.find(cl => cl.id.toString() === action.payload.checklist_id)
            let isFromPending = selectedChecklistFromPending && !selectedChecklistFromDone
            let selectedChecklistIndex = 0
            if (isFromPending) {
                selectedChecklistIndex = state.pending.indexOf(selectedChecklistFromPending)
            } else {
                selectedChecklistIndex = state.done.indexOf(selectedChecklistFromDone)
            }

            const selectedChecklist = selectedChecklistFromPending || selectedChecklistFromDone

            const newChecklist = { ...selectedChecklist }
            const items = newState.selected.checklist_items
            const newItemId = action.payload.checklist_item_id
            const newItem = items.find(item => String(item.id) === String(newItemId))
            newItem.answer = { ...newItem.answer, comments: action.payload.comments }
            const newItems = items.map(item => {
                if (String(item.id) === newItemId) {
                    return newItem
                }
                return item
            })
            newChecklist.checklist_items = newItems
            newState.selected.checklist_items = newItems

            if (isFromPending) {
                const newPendings = [...state.pending]
                newPendings[selectedChecklistIndex] = newChecklist
                newState.pending = newPendings
            } else {
                const newDones = [...state.done]
                newDones[selectedChecklistIndex] = newChecklist
                newState.done = newDones
            }

            return newState
        }
        case "COUNTER_CHECKLIST": {
            const newState = { ...state }
            const selectedChecklistFromPending = state.pending.find(cl => cl.id.toString() === action.payload.checklist_id)
            const selectedChecklistFromDone = state.done.find(cl => cl.id.toString() === action.payload.checklist_id)
            let isFromPending = selectedChecklistFromPending && !selectedChecklistFromDone
            let selectedChecklistIndex = 0
            if (isFromPending) {
                selectedChecklistIndex = state.pending.indexOf(selectedChecklistFromPending)
            } else {
                selectedChecklistIndex = state.done.indexOf(selectedChecklistFromDone)
            }

            const selectedChecklist = selectedChecklistFromPending || selectedChecklistFromDone

            const newChecklist = { ...selectedChecklist }
            const items = newState.selected.checklist_items
            const newItemId = action.payload.checklist_item_id
            const newItem = items.find(item => String(item.id) === String(newItemId))
            newItem.answer = { ...newItem.answer, counter: action.payload.counter }
            const newItems = items.map(item => {
                if (String(item.id) === newItemId) {
                    return newItem
                }
                return item
            })
            newChecklist.checklist_items = newItems
            newState.selected.checklist_items = newItems

            if (isFromPending) {
                const newPendings = [...state.pending]
                newPendings[selectedChecklistIndex] = newChecklist
                newState.pending = newPendings
            } else {
                const newDones = [...state.done]
                newDones[selectedChecklistIndex] = newChecklist
                newState.done = newDones
            }

            return newState
        }
        case "DELETE_CHECKLIST": {
            const newState = { ...state }
            const selectedChecklistFromPending = newState.pending.findIndex(cl => cl.id.toString() === action.payload.toString())
            const newPendings = [...newState.pending]
            newPendings.splice(selectedChecklistFromPending, 1)
            newState.pending = newPendings
            return newState
        }

        case "SIGN_CHECKLISTS": {
            const newState = { ...state }
            if (action.payload.data.status !== "success") return newState
            const oldDone = newState.done
            const newDone = [...oldDone]
            const newChecklists = action.payload.data.info
            newChecklists.forEach(checklist => {
                const index = oldDone.findIndex(ch => {
                    return ch.id === checklist.id
                })
                newDone[index] = checklist
            })
            newState.done = newDone
            return newState
        }
        default:
            return state
    }
}

export default ChecklistReducer
