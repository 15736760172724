import { AddCircleOutline, ErrorOutline } from '@material-ui/icons'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import BigTooltip from '../../../../Shared/BigTooltip'
import { FindingStructureContext } from './DefaultFindingStructure'
import DefaultMeasure from './DefaultMeasure'

const css = {
	container: {
		padding: 2,
		background: 'white',
		borderRadius: 1,
		marginTop: 1
	},
	crits: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: 2,
		marginBottom: 2
	},
	crit: {
		flexGrow: 1,
		flexBasis: 190
	},
	title: {
		margin: '12px 0'
	},
	add_button: {
		textAlign: 'end'
	},
	measures: {
		maxHeight: 400,
		overflow: 'auto'
	}
}



const colors = ["info", "middle", "warning", "error"]

function DefaultMeasures({ measures }) {

	const [selectedCrit, setCrit] = useState(1)

	const { crits, createMeasure } = useContext(FindingStructureContext)
	const filteredMeasures = measures.filter(m => m.default_criticality === selectedCrit)

	function onCreate() {
		const body = { default_criticality: selectedCrit }
		createMeasure(body)
	}

	return (
		<Box sx={css.container}>
			<Box sx={css.crits}>
				{crits.map(crit => {
					const isSelected = crit.value === selectedCrit
					return (
						<Box sx={css.crit}>
							<Button
								onClick={() => setCrit(crit.value)}
								fullWidth color={colors[crit.value - 1]}
								variant={isSelected ? "contained" : "outlined"}
								startIcon={<ErrorOutline />}
							>
								Ver {crit.name}
							</Button>
						</Box>
					)
				})}
			</Box>
			<Box sx={css.measures}>
				{!filteredMeasures.length && <Typography sx={css.title} variant='subtitle1'>No se han agregado medidas de control para este nivel de criticidad...</Typography>}
				{filteredMeasures.map(measure => <DefaultMeasure key={measure.id} measure={measure} />)}
			</Box>
			<Box sx={css.add_button}>
				<BigTooltip title="Agregar Medida de Control">
					<IconButton color="info" onClick={onCreate}>
						<AddCircleOutline />
					</IconButton>
				</BigTooltip>
			</Box>
		</Box>
	)
}

export default DefaultMeasures