import React, { Component } from "react"
import {
    Paper,
    withStyles,
    Typography,
    Divider,
    Switch,
} from "@material-ui/core"

const style = () => ({
    switch: {
        padding: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    container: {
        width: 400,
        maxWidth: "100%",
    },
    title: {
        padding: 12,
    },
})

function arrayBufferToBase64(buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

function getNotificationsStatus() {
    if (Notification.permission === 'granted') return true
    return false
}

async function askForPermissions() {
    const permission = await window.Notification.requestPermission()

    if (permission !== "granted") return 0

    const registration = await navigator.serviceWorker.ready

    const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_KEY),
    })

    const object = {
        user_id: 38,
        endpoint: subscription.endpoint,
        publicKey: arrayBufferToBase64(subscription.getKey('p256dh')),
        authToken: arrayBufferToBase64(subscription.getKey('auth')),
    }

    fetch('https://app.safeasy.cl/push_subscription2.php', {
        method: 'post',
        body: JSON.stringify(object)
    })

    // .then(() => navigator.serviceWorker.ready)
    // .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.subscribe({
    //     userVisibleOnly: true,
    //     applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_KEY),
    // })
    // )
    // .then(subscription => {
    //     const object = {
    //         user_id: 38,
    //         endpoint: subscription.endpoint,
    //         publicKey: arrayBufferToBase64(subscription.getKey('p256dh')),
    //         authToken: arrayBufferToBase64(subscription.getKey('auth')),
    //     }
    //     console.log(object)
    //     fetch('https://app.safeasy.cl/push_subscription2.php', {
    //         method: 'post',
    //         body: JSON.stringify(object)
    //     })
    // })
}

class NotificationCard extends Component {
    render() {
        const { classes, notifications } = this.props

        return (
            <Paper className={classes.container} square>
                <div className={classes.title}>
                    <Typography variant="subtitle1">Notificaciones</Typography>
                </div>
                <Divider style={{ margin: 0 }} />
                {notifications.length === 0 ? (
                    <div className={classes.title}>
                        <Typography variant="caption">
                            No tienes notificationes nuevas...
                        </Typography>
                    </div>
                ) : (
                    <div>Aqui se rendearia la lista con notificationes</div>
                )}
                <Divider style={{ margin: 0 }} />
                <div className={classes.switch}>
                    <Typography variant="subtitle2">
                        Activar notificationes
                    </Typography>
                    <Switch color="primary" checked={getNotificationsStatus()} onClick={askForPermissions} />
                </div>
            </Paper>
        )
    }
}

NotificationCard.propTypes = {}

NotificationCard.defaultProps = {
    notifications: [],
}

export default withStyles(style)(NotificationCard)
