import React, { Component } from 'react'
import { Button, IconButton, withStyles } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

const style = () => ({
  selector: {
    background: 'whitesmoke',
    borderRadius: 8,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 400,
    margin: 'auto'
  },
})
class Selector extends Component {

  render() {
    const { classes, handleChange } = this.props
    return (
      <div className={classes.selector}>
        <IconButton onClick={handleChange("prev")}>
          <KeyboardArrowLeft />
        </IconButton>
        <Button onClick={handleChange("today")}>
          HOY
        </Button>
        <IconButton onClick={handleChange("next")}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
    )
  }
}

export default withStyles(style)(Selector)