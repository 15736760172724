import { IconButton } from '@material-ui/core'
import { ArrowForward, ArrowRight, ArrowRightOutlined, KeyboardArrowDown, KeyboardArrowRight, LocationOn, LocationOnOutlined } from '@material-ui/icons'
import { Box, Collapse, Paper, Typography } from '@mui/material'
import moment from 'moment'
import { moveSupply } from '../../API/supplies'
import useToggle from '../../Hooks/ToogleHook'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { transformToOptions } from '../../Utils/functions'
import Supply from './Supply'

const css = {
	paper: {
		padding: 1,
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	position: {
		flexBasis: '30%',
		minWidth: 240,
		margin: 2
	},
	history: {
		flexBasis: '60%',
		margin: 2,
		flexGrow: 1,
		maxWidth: 600
	},
	inline: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start'
	},
	movement: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 2,
		background: 'whitesmoke',
		borderRadius: 2,
		maxWidth: 500
	},
	location: {
		textAlign: 'start'
	},
	location2: {
		textAlign: 'end'
	},
	translation: {
		margin: '12px 0'
	}
}

function SupplyBranch({ value, branches, supply, onMove }) {

	const [openHistory, toggleHistory] = useToggle(false)

	async function onSelect(event) {
		const { target } = event
		const body = { ...supply, branch_id: target.value }
		onMove(body)
	}

	function Translation({ translation }) {
		return (
			<Box sx={css.translation}>
				<Box sx={css.movement}>
					<Box sx={css.location}>
						<LocationOnOutlined />
						<Typography variant='subtitle1'>{translation.from}</Typography>
					</Box>
					<ArrowForward />
					<Box sx={css.location2}>
						<LocationOn />
						<Typography variant='subtitle1'>{translation.to}</Typography>
					</Box>
				</Box>
				<Typography variant='caption'>{moment(translation.created_at).format("YYYY-MM-DD HH:mm")}</Typography>
			</Box>
		)
	}

	const history = supply.history || []

	return (
		<Box>
			<Paper sx={css.paper}>
				<Box sx={css.position}>
					<Typography style={{ marginBottom: 12 }} variant='h4'>Mover insumo de ubicación</Typography>
					<SelectInput onChange={onSelect} label="Centro de trabajo" options={transformToOptions(branches)} value={supply.branch_id} />
				</Box>
				<Box sx={css.history}>
					<Box sx={css.inline}>
						<Typography style={{ marginBottom: 12 }} variant='h4'>Historial de traslados</Typography>
						<IconButton onClick={toggleHistory}>
							<KeyboardArrowDown />
						</IconButton>
					</Box>
					<Collapse in={openHistory}>
						{history.map(translation => <Translation translation={translation} />)}
					</Collapse>
				</Box>
			</Paper>
		</Box>
	)
}

export default SupplyBranch