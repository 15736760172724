import React, { Component } from 'react'
import { Collapse, Typography, withStyles } from '@material-ui/core'
import { HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
// import StepsReport from './StepsReport'
import TextPhaseReport from './PhasesReport/TextPhaseReport'
import FilePhaseReport from './PhasesReport/FilePhaseReport'
import CheckPhaseReport from './PhasesReport/CheckPhaseReport'
import SelectPhaseReport from './PhasesReport/SelectPhaseReport'

const style = () => ({
  container: {
    background: 'white',
    borderRadius: 12,
    padding: 12,
    margin: '12px 0',
    transition: 'all 0.2s ease-in-out 0.2s',
    height: 16
  },
  selected: {
    minHeight: 600,
    height: 'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  phasesContainer: {
    background: '#f2f3f8',
    padding: 12,
    margin: '12px 0',
    borderRadius: 12
  }
})

class AllStepReport extends Component {

  getAllWorkflowsPhases() {
    const { workflows } = this.props
    const allPhases = []
    const uniquePhaseName = []
    workflows.forEach(workflow => {
      workflow.workflow_steps.forEach(step => {
        allPhases.push(step.phases)
      })
    })
    const flattened = allPhases.flat()
    const uniquePhases = []
    flattened.forEach(phase => {
      if (uniquePhaseName.includes(phase.data.label)) return null
      uniquePhaseName.push(phase.data.label)
      uniquePhases.push(phase)
      return true
    })
    return [flattened, uniquePhases]
  }

  getComponentBasedOnType(data) {
    if (data === "text") return TextPhaseReport
    if (data === "file") return FilePhaseReport
    if (data === "check") return CheckPhaseReport
    if (data === "select") return SelectPhaseReport
    if (data === "multiselect") return SelectPhaseReport
    return TextPhaseReport
  }

  renderPhases() {
    const [allPhases, phases] = this.getAllWorkflowsPhases()
    console.log(allPhases)
    if (phases.length === 0) return null
    return phases.map(item => {
      const { data = {} } = item
      const filteredPhases = allPhases.filter(answer => answer.data.label === data.label)
      const PhaseComponent = this.getComponentBasedOnType(data.type)
      return (
        <>
          <Typography variant="subtitle1" style={{ marginLeft: 6, marginTop: 12, fontWeight: 600 }}>{data.label}</Typography>
          <PhaseComponent answers={filteredPhases} name={data.label} />
        </>

      )
    })
  }

  render() {
    const { classes, onSelect, selected } = this.props
    const isSelected = selected === "all"
    return (
      <div className={`${classes.container} ${isSelected ? classes.selected : ""}`} >
        <div className={classes.header} onClick={onSelect("all")}>
          <Typography variant="h4">Todos</Typography>
          {isSelected ? <HighlightOffOutlined /> : <KeyboardArrowDown />}
        </div>
        <Collapse in={isSelected}>
          <div className={classes.phasesContainer}>
            {/* <Typography variant="subtitle1" style={{ marginLeft: 6, fontWeight: 600, marginBottom: 6 }}>{data.name}</Typography> */}
            {this.renderPhases()}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(AllStepReport)