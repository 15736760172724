import { Button } from '@material-ui/core'
import { FindInPageOutlined } from '@material-ui/icons'
import { Box, Paper, Typography } from '@mui/material'
import { useContext } from 'react'
import { createFindingFromChecklistNew } from '../../../API/new_checklists'
import AppContext from '../../../AppContext'
import { RequestManagerContext } from '../../../DatabaseManagers/RequestsManager'
import useToggle from '../../../Hooks/ToogleHook'
import { inline, inline_space } from '../../../Utils/defaultStyles'
import { transformOfflineRequestToOnline } from '../../../Utils/functions'
import CreatedFinding from '../../ChecklistNew/Components/CreatedFinding'
import { ChecklistContext } from '../ChecklistView'
import FindingForm from './FindingForm'

const css = {
	paper: {
		padding: 2,
		margin: '12px 0'
	},
	dialog: {
		padding: 2
	},
	inline: {
		display: 'flex',
		alignItems: 'center'
	}
}

function NewFinding({ value }) {
	const { checklist, setChecklist } = useContext(ChecklistContext)
	const { online: { status: online } } = useContext(AppContext)
	const [openDialog, toggleDialog] = useToggle(false)
	const { addRequest } = useContext(RequestManagerContext)

	function createFindingOffline(body) {
		const new_checklist = { ...checklist }
		const new_finding = { ...body }
		new_checklist.findings.push(new_finding)
		setChecklist(new_checklist)
		addRequest({ name: "Crear hallazgo del registro", method: createFindingFromChecklistNew(body, true) })
	}

	async function onCreate(body) {
		body.id = checklist.id
		if (!online) return createFindingOffline(body)
		const response = await createFindingFromChecklistNew(await transformOfflineRequestToOnline(body))
		toggleDialog()
		setChecklist(response.data.info)
	}

	const findings = checklist.findings || []

	return (
		<Paper sx={css.paper}>
			<Box sx={inline_space}>
				<Box sx={inline}>
					<FindInPageOutlined />
					<Typography variant='h4'>Hallazgos</Typography>
				</Box>
				<Button color="primary" variant="outlined" onClick={toggleDialog}>
					Crear Hallazgo
				</Button>
			</Box>
			<FindingForm open={openDialog} onClose={toggleDialog} onCreateFinding={onCreate} />
			{findings.map(finding => {
				return (
					<CreatedFinding finding={finding} />
				)
			})}
		</Paper>
	)
}

export default NewFinding