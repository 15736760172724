import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'

const style = () => ({

})

class SubmitButton extends Component {

  render() {
    const { classes, color, variant, size, align, children, onClick, disabled } = this.props
    return (
      <div className={classes.container} style={{ textAlign: align }}>
        <Button color={color} variant={variant} size={size} onClick={onClick} disabled={disabled}>
          {children}
        </Button>
      </div>
    )
  }
}

SubmitButton.propTypes = {

}


SubmitButton.defaultProps = {
  color: "primary",
  size: "small",
  align: 'end',
  variant: "outlined",
  disabled: false
}

export default withStyles(style)(SubmitButton)