import { KeyboardArrowRight } from '@material-ui/icons'
import { Box, Chip, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { getSupervisedDetails } from '../../../API/users'
import AppContext from '../../../AppContext'
import newTheme from '../../../newTheme'
import BigTooltip from '../../../Shared/BigTooltip'
import { inline, inline_space, text_space } from '../../../Utils/defaultStyles'
import { SupervisionContext } from './SupervisionHome'

const css = {
	tnt: {
		background: newTheme.palette.background.main,
		padding: 2,
		margin: '6px 0',
		borderRadius: 2
	},
	tag: {
		background: 'white',
		borderRadius: '24px',
		padding: '6px 12px'
	},
	container: {
		maxHeight: 350,
		overflow: 'auto'
	},
	title: {
		padding: '12px 6px'
	},
	message: {
		padding: '0 6px'
	}
}


function SupervisedTNT({ tnt, id, type }) {

	const { colors } = useContext(SupervisionContext)
	const { history } = useContext(AppContext)

	const isSupervisor = tnt.user_id === id
	const isInvited = tnt.requested_participants.includes(id)
	const hasSigned = !!tnt.participants_signs.find(sign => sign.user_id === id)

	return (
		<Box sx={{ ...css.tnt, ...inline_space }}>
			<Box>
				<Typography variant='subtitle2'>{tnt.name}</Typography>
				<Typography variant='caption'>{moment(tnt.date_scheduled).format("YYYY-MM-DD HH:mm")}</Typography>
			</Box>
			<Box sx={inline}>
				{/* {tnt.date_saved !== "0000-00-00" && <Chip label="Terminada" color={colors["Terminado"]} />} */}
				{isInvited && !hasSigned && <Chip label="No Firmado" color="warning" />}
				{hasSigned && <Chip label="Firmado" color="info" />}
				{isSupervisor && <Chip label="Supervisor" color="info" />}
				{isInvited && <Chip label="Invitado" color="success" />}

				<BigTooltip title="Ver Registro">
					<IconButton onClick={() => history.push(`/${type}/${tnt?.id}`)}>
						<KeyboardArrowRight />
					</IconButton>
				</BigTooltip>
			</Box>
		</Box>
	)
}

function SupervisedTNTs({ id, dates, type, supervisedUser }) {

	const [tnts, setChecklists] = useState([])

	useEffect(() => {
		async function fetchData() {
			const body = { id, type, ...dates }
			const response = await getSupervisedDetails(body)
			setChecklists(response.data.info)
		}

		if (!!id) { fetchData() }

	}, [id, dates, type])

	console.log(tnts)

	if (!tnts.length) return (
		<Box>
			<Typography variant='h4' sx={css.title} >{type === "talks" ? "Charlas" : "Capacitaciones"} de {supervisedUser?.name}</Typography>
			<Typography variant='subtitle1' sx={css.message} >No hay registros de este usuario entre las fechas seleccionadas...</Typography>
		</Box>
	)

	return (
		<Box sx={css.main}>
			<Typography variant='h4' sx={css.title} >{type === "talks" ? "Charlas" : "Capacitaciones"} de {supervisedUser?.name}</Typography>
			<Box sx={css.container}>
				{tnts.map(tnt => <SupervisedTNT key={tnt.id} type={type} tnt={tnt} id={id} />)}
			</Box>
		</Box>
	)
}

export default SupervisedTNTs