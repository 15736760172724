import React, { Component } from 'react'
import { Collapse, Divider, IconButton, Menu, MenuItem, Typography, withStyles } from '@material-ui/core'
import { AddCircleOutline, Edit } from '@material-ui/icons'
import autobind from '../../../Utils/autobind'
import CreateField from './CreateField'
import { editSettingsFinding, editSettingsNonConformity } from '../../../API/settings'
import { callSnackbar } from '../../../Utils/snackbar'
import FindingField from './FindingField'

const style = () => ({
  container: {
    margin: '24px 0',
    padding: 12,
    background: 'white',
    borderRadius: 12
  },
  message: {
    margin: '12px 0',
    display: 'flex'
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
    marginLeft: 6,
    cursor: 'pointer'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const crits = [
  "No Crítico",
  "Poco Crítico",
  "Medianamente Crítico",
  "Altamente Crítico"
]

class FindingInfo extends Component {
  constructor() {
    super()
    this.state = {
      openCreate: false,
      openEdit: false,
      anchorEl: null
    }
    autobind(FindingInfo, this)
  }
  componentDidMount() {
    const element = document.getElementById("edit-button")
    this.setState({ anchorEl: element })
  }

  handleOpenCreate() {
    const { openCreate } = this.state
    this.setState({ openCreate: !openCreate })
  }

  handleOpenEdit() {
    this.setState({ openEdit: !this.state.openEdit })
  }

  handleEditCrit(value) {
    return async () => {
      const { finding, reload, nonconf } = this.props
      const body = { ...finding, activation_criticality: value }
      this.setState({ openEdit: false })
      if (nonconf) {
        await editSettingsNonConformity(body)
      } else {
        await editSettingsFinding(body)
      }
      callSnackbar("Editado correctamente", "success")
      reload()
    }
  }

  renderFields() {
    const { finding, classes, reload, nonconf } = this.props
    const fields = finding?.extra_fields || []
    if (fields.length === 0) return (
      <div className={classes.message}>
        <Typography variant="subtitle1">Aún no tienes campos a responder.</Typography>
        <Typography variant="subtitle1" className={classes.link} onClick={this.handleOpenCreate}>{` Agrega uno`}</Typography>
      </div>
    )
    return fields.filter(field => field.text).map((field, index) => {
      return (
        <div className={classes.field} key={index}>
          {index > 0 && <Divider style={{ margin: '12px 0' }} />}
          <FindingField field={field} index={index} reload={reload} finding={finding} nonconf={nonconf} />
        </div>
      )
    })
  }
  render() {
    const { classes, finding, reload, nonconf } = this.props
    const { openCreate, openEdit, anchorEl } = this.state
    return (
      <>
        <div className={classes.container}>
          <div className={classes.inline}>
            <Typography variant="h4">Activado para {nonconf ? "no conformidades" : "hallazgos"} {crits[finding?.activation_criticality - 1]}s</Typography>
            <IconButton onClick={this.handleOpenEdit} id="edit-button">
              <Edit />
            </IconButton>
          </div>
          <Divider style={{ margin: '12px 0' }} />
          <div className={classes.inline}>
            <Typography variant="h4">Campos a responder</Typography>
            <IconButton onClick={this.handleOpenCreate}>
              <AddCircleOutline />
            </IconButton>
          </div>
          <Collapse in={openCreate}>
            <CreateField finding={finding} reload={reload} onClose={this.handleOpenCreate} nonconf={nonconf} />
          </Collapse>
          <Menu open={openEdit} onClose={this.handleOpenEdit} anchorEl={anchorEl}>
            <MenuItem onClick={this.handleEditCrit(1)}>
              No Crítico
            </MenuItem>
            <MenuItem onClick={this.handleEditCrit(2)}>
              Poco Crítico
            </MenuItem>
            <MenuItem onClick={this.handleEditCrit(3)}>
              Medianamente Crítico
            </MenuItem>
            <MenuItem onClick={this.handleEditCrit(4)}>
              Altamente Crítico
            </MenuItem>
          </Menu>
        </div>
        <div className={classes.container}>
          {this.renderFields()}
        </div>
      </>
    )
  }
}

export default withStyles(style)(FindingInfo)