import { Button } from '@material-ui/core'
import { Box, Divider, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { createInjured, deleteInjured, editInjured, editPhase, editStep, editWorkflow, getWorkflows } from '../../API/workflows'
import WorkflowInjured from './Steps/WorkflowInjured'
import WorkflowStep, { check_if_step_ready } from './Steps/WorkflowStep'
import WorkflowContext from './WorkflowContext'
import WorkflowMail from './WorkflowMail'

const css = {
	container: {
		margin: 2,
		maxWidth: 1000
	},
	info: {
		marginBottom: 2
	},
	button: {
		textAlign: 'end',
		margin: '12px 0'
	}
}

function checkIfWorkflowReady(workflow) {
	if (!workflow.workflow_steps) return false
	const steps = workflow.workflow_steps
	const step_bools = steps.map(step => {
		return check_if_step_ready(step)
	})
	const isReady = step_bools.every(Boolean)
	return isReady
}

function WorkflowView({ value, match }) {

	const [workflow, setWorkflow] = useState([])
	const [selectedItems, setItems] = useState([])
	const id = match.params.id

	useEffect(() => {
		async function fetchData() {
			const response = await getWorkflows({ id })
			const { data: { info } } = response
			setWorkflow(info)
		}
		fetchData()
	}, [])

	async function editItem(itemBody) {
		const body = { ...itemBody }
		body.workflow_id = workflow.id
		const response = await editPhase(body)
		const { data: { info } } = response
		setWorkflow(info)
	}

	async function editWFStep(stepBody) {
		const body = { ...stepBody }
		body.workflow_id = workflow.id
		const response = await editStep(body)
		const { data: { info } } = response
		setWorkflow(info)
	}

	async function finish() {
		const body = { ...workflow }
		body.date_finished = moment().format("YYYY-MM-DD HH:mm")
		const response = await editWorkflow(body)
		const { data: { info } } = response
		setWorkflow(info)
	}

	async function createInjuredUsers(body) {
		const response = await createInjured(body)
		const { data: { info } } = response
		setWorkflow(info)
	}

	async function editInjuredUsers(body) {
		const response = await editInjured(body)
		const { data: { info } } = response
		setWorkflow(info)
	}

	async function deleteInjuredUsers(body) {
		const response = await deleteInjured(body)
		const { data: { info } } = response
		setWorkflow(info)
	}

	function addItem(item) {
		const newItems = [...selectedItems]
		const index = newItems.findIndex(i => i.id === item.id)
		if (index !== -1) {
			newItems.splice(index, 1)
		} else {
			newItems.push(item)
		}
		setItems(newItems)
	}

	function renderSteps(step, index) {
		return <WorkflowStep
			onEdit={editWFStep}
			key={step.id}
			index={index}
			step={step}
			workflow={workflow}
			items={selectedItems}
		/>
	}
	const steps = workflow.workflow_steps || []

	return (
		<WorkflowContext.Provider value={{ editItem, addItem }}>
			<Box sx={css.container}>
				<Box sx={css.info}>
					<Typography sx={css.info} variant='h1'>{workflow.name}</Typography>
					<Typography variant='h4'>{workflow.workflow_type}</Typography>
					<Typography variant='subtitle1' color="primary">{workflow.user}</Typography>
					<Box>
						<Typography variant='caption'>Iniciado el {moment(workflow.date_created).format("YYYY-MM-DD [a las] HH:mm")}</Typography>
					</Box>
					<Box>
						{workflow.date_finished &&
							<Typography variant='caption'>Terminado el {moment(workflow.date_finished).format("YYYY-MM-DD [a las] HH:mm")}</Typography>
						}
					</Box>
					{[1, 2].includes(workflow.workflow_type_id) &&
						<Box>
							<Divider style={{ margin: '12px 0' }} />
							<WorkflowInjured
								workflow={workflow}
								onCreate={createInjuredUsers}
								onEdit={editInjuredUsers}
								onDelete={deleteInjuredUsers}
							/>
							<Divider style={{ margin: '12px 0 24px' }} />
						</Box>
					}
				</Box>
				<Box>
					{steps.map(renderSteps)}
				</Box>
				{!workflow.date_finished &&
					<Box sx={css.button}>
						<Button onClick={finish} color="primary" variant="contained" disabled={!checkIfWorkflowReady(workflow)}>
							Finalizar Proceso
						</Button>
					</Box>
				}
				<WorkflowMail items={selectedItems} />
			</Box>
		</WorkflowContext.Provider>
	)
}

export default withRouter(WorkflowView)