import React, { Component } from 'react'
import { Collapse, IconButton, Switch, Typography, withStyles } from '@material-ui/core'
import { Clear, DragIndicatorOutlined, Edit, Error } from '@material-ui/icons'
import autobind, { addToggle } from '../../../../Utils/autobind'
import SeamlessInput from '../../../../Shared/Inputs/SeamlessInput'
import MiniLoaderInline from '../../../../Shared/MiniLoaderInline'
import CritSelector from './CritSelector'

const style = () => ({
  container: {},
  element: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *:first-child': {
      flexGrow: 1
    }
  },
  option: {
    marginBottom: 12,
    paddingLeft: 24
  },
  bold: {
    fontWeight: 600
  },
  buttons: {
    display: 'flex',
    alignItems: 'center'
  },
  dialog: {
    padding: 24,
    '& > *': {
      margin: '12px 0'
    }
  },
  elementHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > *:nth-child(2)': {
      flexGrow: 1
    }
  },
  collapse: {
    margin: '12px 0',
    '& > *': {
      margin: '6px 0'
    }
  }
})

const crits = [
  // { label: "No asignar valor por defecto", value: 0 },
  { label: "No Crítico", value: 1, color: "#a6b9f9" },
  { label: "Poco Crítico", value: 2, color: "#f6ec79" },
  { label: "Medianamente Crítico", value: 3, color: "#ffab6e" },
  { label: "Altamente Crítico", value: 4, color: "#ff6c87" }
]

class ChecklistElement extends Component {
  constructor() {
    super()
    this.state = {
      openCategory: false,
      category: null,
      points: 1,
      name: "",
      loadingChange: null,
      counter: 0,
      changed: false
    }
    this.counter = 0
    addToggle(ChecklistElement, this, "edit")
    addToggle(ChecklistElement, this, "score")
    autobind(ChecklistElement, this)
  }

  componentDidMount() {
    const { element } = this.props
    this.setState({ category: String(element.subtitle_id), points: element?.points, name: element?.name, counter: element?.counter })
    const self = this
    const docElement = document.getElementById(`element-${element.id}`)

    docElement.addEventListener("dragstart", (e) => {
      e.dataTransfer.effectAllowed = "move"
      e.dataTransfer.setData('text/plain', JSON.stringify(self.props.element))
      e.dataTransfer.dropEffect = "none"
      docElement.style.opacity = "0.5"
    })

    docElement.addEventListener("dragend", () => {
      docElement.style.opacity = "1"
    })
  }

  handleChange(event, save = false) {
    const { target } = event
    let value = target.value
    if (target.name === "points") {
      console.log(parseInt(target.value, 10))
      if (parseInt(target.value, 10) < 1 || !target.value) {
        value = 1
      }
    }
    if (save) return this.setState({ [target.name]: value, changed: true }, this.handleEdit)
    return this.setState({ [target.name]: value, changed: true })
  }

  handleOpenCategory() {
    const { openCategory } = this.state
    this.setState({ openCategory: !openCategory })
  }

  handleEditCategory() {
    const { element, onChangeCategory } = this.props
    const { category } = this.state
    const body = { ...element, subtitle_id: category }
    onChangeCategory(body)
    this.setState({ openCategory: false })
  }

  handleEditScore() {
    const { element, onChangeCategory } = this.props
    const { score } = this.state
    const body = { ...element, points: score }
    onChangeCategory(body)
    this.setState({ openScore: false })
  }

  async handleEdit() {
    const { element, onEdit, checklist } = this.props
    const body = { ...element, ...this.state, checklist_id: checklist.id }
    if (!this.state.changed) return null
    this.setState({ loadingChange: true })
    await onEdit(body)
    this.setState({ loadingChange: false, changed: false })
  }

  render() {
    const { classes, element, onDelete } = this.props
    const { points, name, loadingChange, openEdit, counter } = this.state
    if (!element) return null
    const hasCounter = element.counter === 1
    const hasCrit = element.default_criticality !== 0
    const defCrit = hasCrit && element.default_criticality
    const selectedCrit = hasCrit && crits.find(crit => crit.value === defCrit)
    return (
      <div className={classes.container}>
        <div className={classes.element} style={{ "&::before": { background: 'red' } }} draggable={!openEdit} id={`element-${element.id}`}>
          <div className={classes.elementHeader}>
            <div style={{ display: 'flex' }}>
              <DragIndicatorOutlined style={{ color: openEdit ? "lightgray" : "#6a6a6a", cursor: "grab" }} />
              {hasCrit && <Error style={{ color: selectedCrit?.color }} />}
            </div>
            <SeamlessInput onBlur={this.handleEdit} onChange={this.handleChange} name="name" width='100%' color="transparent" variant="subtitle1" value={`${name}`} />
            <Typography variant='subtitle1'>{`${element?.points > 1 ? ` (${element?.points} Pts)` : ""}`}</Typography>
          </div>
          <div className={classes.buttons}>
            <IconButton onClick={this.handleOpenEdit}>
              <Edit />
            </IconButton>
            <IconButton onClick={onDelete}>
              <Clear />
            </IconButton>
            <MiniLoaderInline loading={loadingChange} />
          </div>
        </div>
        <Collapse in={openEdit}>
          <div className={classes.collapse}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch checked={hasCounter} onClick={() => {
                const event = {
                  target: {
                    name: "counter",
                    value: counter === 0 ? 1 : 0
                  }
                }
                this.handleChange(event, true)
              }} />
              <Typography variant='subtitle1'>Agregar contador al elemento</Typography>
            </div>
            {/* Crit Selector */}
            <CritSelector crits={crits} element={element} onEdit={this.handleChange} />
            {/* Score Selector */}
            <Typography variant='subtitle1'>Puntaje:</Typography>
            <SeamlessInput onBlur={this.handleEdit} onChange={this.handleChange} width="258px" name="points" color="white" variant="subtitle1" value={points} />
            {/* Counter Switch */}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(style)(ChecklistElement)