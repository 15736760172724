import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import DonutChart from '../../../../Shared/Charts/DonutChart'

const style = () => ({
  chart: {
    width: 300,
    marginTop: 24
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  dot: {
    height: 18,
    width: 18,
    borderRadius: '50%',
    marginRight: 6
  }
})

const colors = [
  "#00876c",
  "#8cbcac",
  "#f1f1f1",
  "#ec9c9d",
  "#d43d51",
]

function stripOption(option) {
  return option.split("&")[0]
}

class SelectField extends Component {
  calculateValues() {
    const { data, field } = this.props
    const options = field?.options
    const realOptions = options.map(option => typeof option === "string" ? stripOption(option) : stripOption(option?.label))
    const values = {}
    realOptions.forEach(option => { values[option] = 0 })
    data.forEach(checklist => {
      const answer = checklist?.informacion_extra?.find(extra => extra.label === field.label)
      if (!answer || !answer.value) return null
      values[answer.value] += 1
    })
    return values
  }

  renderLabels(values) {
    const { field, classes } = this.props
    const options = field?.options
    const realOptions = options.map(option => typeof option === "string" ? stripOption(option) : stripOption(option?.label))
    return realOptions.map((option, index) => {
      return (
        <div className={classes.label}>
          <div className={classes.dot} style={{ background: colors[index] }} />
          <Typography variant="subtitle1">{option}: {values[option]}</Typography>
        </div>
      )
    })

  }

  render() {
    const { classes, field, index } = this.props
    const values = this.calculateValues()
    const totalOptions = field.options.length
    const colorsTuUse = colors.slice(0, totalOptions)
    return (
      <div>
        <div className={classes.container}>
          <div className={classes.chart}>
            <DonutChart data={Object.values(values)} color={colorsTuUse} name={`${field.label}-${index}`} />
          </div>
        </div>
        {this.renderLabels(values)}
      </div>
    )
  }
}

export default withStyles(style)(SelectField)