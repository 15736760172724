import { Typography } from '@material-ui/core'
import { Box, Paper } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { getSupplies } from '../../API/supplies'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { ChecklistContext } from './ChecklistView'

const css = {
	container: {
		padding: 3,
		margin: '24px 0'
	}
}

function ChecklistSupplies({ families, onEdit }) {

	const { checklist } = useContext(ChecklistContext)
	const [supplies, setSupplies] = useState([])

	useEffect(() => {
		async function fetchData() {
			const response = await getSupplies()
			setSupplies(response.data.info)
		}

		fetchData()

	}, [])

	const selectedTypes = checklist.supply_types || []
	if (!selectedTypes.length) return null
	const selectedTypeIds = selectedTypes.map(st => st.id)
	const selectedSupplies = supplies.filter(supply => selectedTypeIds.includes(supply.supply_type_id))
	let suppliesOptions = selectedSupplies.map(sup => ({ label: `${sup.code} - ${sup.type}`, value: sup.id }))
	if (!selectedSupplies.length) return null
	if (!selectedTypes.length) {
		suppliesOptions = supplies.map(sup => ({ label: `${sup.code} - ${sup.type}`, value: sup.id }))
	}

	function handleChange(event) {
		const { target } = event
		const body = { id: checklist.id, supply_id: target.value }
		onEdit(body)
	}

	return (
		<Paper sx={css.container}>
			<Typography variant='h4'>Insumo o Maquinaria relacionada</Typography>
			<SelectInput value={checklist.supply_id} label="Seleccionar insumo" options={suppliesOptions} onChange={handleChange} />
		</Paper>
	)
}

export default ChecklistSupplies