import React, { Component } from 'react'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import { HighlightOffOutlined } from '@material-ui/icons';

const style = () => ({
	tag: {
		padding: '6px 6px 6px 18px',
		borderRadius: 24,
		width: 150,
		'& > *': {
			color: 'black'
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '12px'
	}
})

class SelectedFilters extends Component {

	render() {
		const { classes, filters = [], tags, removeFilter } = this.props;
		console.log(filters)
		console.log(tags)
		const selectedTags = tags.filter(tag => filters?.includes(tag.id))
		return (
			<div className={classes.container}>
				{selectedTags.map(tag => {
					return (
						<div className={classes.tag} style={{ background: tag.color + "60" }}>
							<Typography variant='subtitle1'>{tag.name}</Typography>
							<IconButton size="small" onClick={removeFilter(tag.id)}>
								<HighlightOffOutlined />
							</IconButton>
						</div>
					)
				})}
			</div>
		);
	}
}

export default withStyles(style)(SelectedFilters)