import { HighlightOff } from '@material-ui/icons'
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import { useContext, useMemo, useState } from 'react'
import { createMultipleFromPosition } from '../../API/activities'
import useToggle from '../../Hooks/ToogleHook'
import useChangeParams from '../../Hooks/UseStateChangeParams'
import DateInput from '../../Shared/Inputs/DateInput'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import SelectInput from '../../Shared/Inputs/SelectInput'
import { inline_space, paper_style } from '../../Utils/defaultStyles'
import { transformToOptions } from '../../Utils/functions'
import { ActivitiesContext } from './ActivitiesNew'
import SelectResponsiblesForActivities from './SelectResponsiblesForActivities'

const css = {
	container: {
		padding: 2,
		background: 'whtie',
		borderRadius: 2,
		position: 'relative'
	},
	form: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		'& > *': {
			flexBasis: 200,
			flexGrow: 1,
		}
	}
}

function validateForm(params) {
	return (!!params.activity_id &&
		!!params.branch_ids &&
		!!params.start_date &&
		!!params.end_date &&
		!!params.frequency)
}

function CreateMultipleByUsers({ open, onClose }) {

	const [params, setParams] = useState({ start_date: moment().format("YYYY-MM-DD"), end_date: moment().format("YYYY-MM-DD") })
	const [openResponsibles, toggleResponsibles] = useToggle(false)
	const { formData } = useContext(ActivitiesContext)

	function onChange(event) {
		const { target } = event
		setParams(p => ({ ...p, [target.name]: target.value }))
	}

	const ids = useMemo(() => params.branch_ids || [], [params])

	return (
		<Collapse in={open}>
			<Box sx={paper_style}>
				<Box sx={inline_space}>
					<Typography variant='h4'>Planificar actividades para múltiples usuarios</Typography>
					<IconButton onClick={onClose}>
						<HighlightOff />
					</IconButton>
				</Box>
				<Box sx={css.form}>
					<SelectInput
						onChange={onChange}
						value={params.activity_id}
						name="activity_id"
						label="Actividad*" options={transformToOptions(formData.activities)} />
					<MultiSelectInput
						onChange={onChange}
						value={params.branch_ids}
						name="branch_ids"
						label="Centros de trabajo*" options={transformToOptions(formData.branches)} />
					<DateInput
						onChange={onChange}
						value={params.start_date}
						name="start_date"
						label="Fecha de inicio*" />
					<DateInput
						onChange={onChange}
						value={params.end_date}
						name="end_date"
						label="Fecha de término*" />
					<SelectInput
						onChange={onChange}
						value={params.frequency}
						name="frequency"
						label="Frecuencia" options={formData.frequencies} />
				</Box>
				<Button color="primary" variant="contained" onClick={toggleResponsibles} disabled={!validateForm(params)}>
					Seleccionar Responsables
				</Button>
				<SelectResponsiblesForActivities
					base_activity={params}
					branch_ids={ids}
					open={openResponsibles}
					onClose={toggleResponsibles}
				/>
			</Box>

		</Collapse>
	)
}

export default CreateMultipleByUsers