import { Button, withStyles } from '@material-ui/core'
import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import MultiSelectInput from '../../Shared/Inputs/MultiSelectInput'
import { getWorkersAction } from '../../Actions/EnterpriseAction'
import { transformToOptions } from '../../Utils/functions'
import { filterActiveWorkers } from '../../Utils/filters'
import autobind from '../../Utils/autobind'
import { CheckCircleOutline, InfoOutlined } from '@material-ui/icons'
import MiniLoaderAnimator from '../../Shared/MiniLoaderAnimator'

const style = theme => ({
  section: {
    padding: 12
  },
  flexSection: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  subsection: {
    maxWidth: 400,
    flexBasis: '100%',
    margin: 12

  },
  participants: {
    background: theme.palette.purple.light,
    padding: 12,
    borderRadius: 12,
    '& > *': {
      color: 'white'
    },
    marginTop: 12
  },
  user: {
    '& > *': {
      color: 'white',
      marginRight: 6
    },
    display: 'flex',
    margin: '6px 0'
  }
})

class Participants extends React.Component {
  constructor() {
    super()
    this.state = {
      workers: [],
      selected: [],
      hasChanged: false,
      loading: false,
    }
    autobind(Participants, this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const response = await getWorkersAction().payload
    if (response.data.status !== "success") return this.setState({ loading: false })
    const allWorkers = response.data.info
    const filteredWorkers = filterActiveWorkers(allWorkers)
    const { participants = [] } = this.props
    const selected = [...participants]
    return this.setState({ workers: filteredWorkers, selected, loading: false })
  }

  componentDidUpdate(prevProps) {
    const { participants } = this.props
    if (participants.length !== prevProps.participants.length) {
      const newSelected = [...participants]
      this.setState({ selected: newSelected })
    }
  }


  handleChange(event) {
    const { target } = event
    this.setState({ selected: target.value, hasChanged: true })
  }

  handleSend() {
    const { selected } = this.state
    const { onSubmit, id } = this.props
    const body = {
      id,
      participants: selected
    }
    onSubmit(body)
    this.setState({ hasChanged: false })
  }

  renderSelected() {
    const { classes, element } = this.props
    const { selected, workers } = this.state
    const currentSigns = element?.participants_signs || element?.signs || []
    const currentSignsUserIds = currentSigns.map(sign => sign.user_id.toString())
    return workers
      .filter(worker => selected.map(x => x.toString()).includes(worker.id.toString()))
      .map(user => {
        const isSigned = currentSignsUserIds.includes(user.id.toString())
        return (
          <div className={classes.user} key={user.id}>
            {isSigned &&
              <CheckCircleOutline />
            }
            <Typography variant="subtitle1">{user.name}</Typography>
          </div>
        )
      })
  }

  render() {
    const { classes, disabled } = this.props
    const { workers, selected, hasChanged, loading } = this.state
    return (
      <div className={classes.container}>
        <Paper className={classes.section}>
          <Typography variant="h4">Solicitar firma a:</Typography>
          <div className={classes.flexSection}>
            {!disabled && <div className={classes.subsection}>
              <MultiSelectInput
                label="Participantes"
                options={transformToOptions(workers)}
                value={selected}
                onChange={this.handleChange}
                grouped
              />
              {hasChanged &&
                <Button onClick={this.handleSend} color="primary" variant="contained">
                  <InfoOutlined style={{ marginRight: 12 }} />
                  Guardar cambios
                </Button>}
            </div>}
            <div className={classes.subsection}>
              <div className={classes.participants}>
                {loading ? <MiniLoaderAnimator />
                  :
                  <>
                    {selected.length > 0 ?
                      this.renderSelected()
                      :
                      <Typography variant="subtitle1">No hay participantes invitados...</Typography>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

export default withStyles(style)(Participants)