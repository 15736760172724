import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import "./animation.css"
import { CheckCircleOutline, CheckOutlined } from '@material-ui/icons'

const style = () => ({

})
class MiniLoaderAnimator extends Component {
  render() {
    const { loading } = this.props
    if (loading === null) return null
    return (
      <div className={`mini-lds-container ${loading ? "showCheck" : "hideCheck"}`}>
        {loading ?
          <div className="mini-lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
          :
          <CheckCircleOutline style={{ color: 'green', width: 24, height: 24, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
        }
      </div>
    )
  }
}

export default withStyles(style)(MiniLoaderAnimator)