import { Box } from '@mui/material'
import moment from 'moment'
import DateInput from './DateInput'

const css = {
  container: {
    maxWidth: 500
  }
}

const initial_dates = {
  date_start: moment().startOf("week").format("YYYY-MM-DD"),
  date_end: moment().endOf("week").format("YYYY-MM-DD"),
}

function DateIntervalSelectorNew({ dates = initial_dates, disabled, onChange }) {

  const { date_end, date_start } = dates
  return (
    <Box sx={css.container}>
      <DateInput disabled={disabled} label="Desde" name="date_start" value={date_start} onChange={onChange} />
      <DateInput disabled={disabled} label="Hasta" name="date_end" value={date_end} onChange={onChange} />
    </Box>
  )
}

export default DateIntervalSelectorNew