import React, { Component, Fragment } from 'react'
import { Dialog, Divider, IconButton, Typography, withStyles } from '@material-ui/core'
import autobind from '../../../Utils/autobind'
import { connect } from 'react-redux'
import TextInput from '../../../Shared/Inputs/TextInput'
import SubmitButton from '../../../Shared/SubmitButton'
import LoaderAnimator from '../../../Shared/LoaderAnimator'
import { Edit } from '@material-ui/icons'
import { createSettingsListAction, getSettingsListsAction, editSettingsListAction } from '../../../Actions/ListsAction'

const style = theme => ({
  createForm: {
    background: 'white',
    maxWidth: 700,
    margin: 'auto',
    marginTop: 24,
    marginBottom: 24
  },
  title: {
    background: theme.palette.primary.main,
    padding: 12,
    color: 'white'
  },
  form: {
    padding: 12
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    '&:hover': {
      background: 'whitesmoke'
    },
    height: 48,
    padding: '0px 12px'
  },
  epps: {
    padding: 12,
    background: 'white'
  },
})

class Lists extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      params: {},
      selectedEdit: null,
      selectedDelete: null,
      openBar: -1
    }
    autobind(Lists, this)
  }

  componentDidMount() {
    const { getLists } = this.props
    getLists().then(() => this.setState({ loading: false }))
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { createList } = this.props
    const { params } = this.state
    const body = { ...params }
    createList(body)
    this.setState({ params: {} })
  }

  handleOpenBar(value) {
    return () => {
      const { openBar } = this.state
      if (openBar === value) return this.setState({ openBar: -1 })
      this.setState({ openBar: value })
    }
  }

  handleOpenEdit(selectedEdit) {
    return () => {
      const { params } = this.state
      params.editName = selectedEdit.name
      this.setState({ selectedEdit })
    }
  }

  handleOpenDelete(selectedDelete) {
    return () => {
      this.setState({ selectedDelete })
    }
  }

  handleCloseModals() {
    this.setState({ selectedDelete: null, selectedEdit: null })
  }

  handleEdit() {
    const { selectedEdit, params } = this.state
    const { editList } = this.props
    const body = {
      name: params.editName,
      id: selectedEdit.id
    }
    editList(body)
    this.handleCloseModals()
    this.setState({ params: {} })
  }

  handleDelete() {
    const { selectedDelete } = this.state
    const { deletePermissionsLists } = this.props
    const body = {
      id: selectedDelete.id
    }
    deletePermissionsLists(body)
    this.handleCloseModals()
  }

  renderCategories() {
    const { classes, settings } = this.props
    const allCategories = settings?.lists?.all || []
    const list = allCategories.map(category => {
      return (
        <Fragment key={category.id}>
          <Divider />
          <div className={classes.category}>
            <Typography variant="subtitle1">{category.name}</Typography>
            <div className={classes.buttons}>
              <IconButton onClick={this.handleOpenEdit(category)}>
                <Edit />
              </IconButton>
            </div>
          </div>
        </Fragment>
      )
    })
    return (
      <>
        <div className={classes.category}>
          <div>
            <Typography variant="subtitle1">Nombre</Typography>
          </div>
          <div>
            <Typography variant="subtitle1">Acciones</Typography>
          </div>
        </div>
        {list}
      </>
    )
  }

  EditDialog() {
    const { classes } = this.props
    const { selectedEdit, params } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedEdit !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Editar lista</Typography>
        <div className={classes.form}>
          <TextInput label="Nombre" name="editName" value={params.editName} onChange={this.handleChange} />
          <SubmitButton onClick={this.handleEdit}>Guardar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  DeleteDialog() {
    const { classes } = this.props
    const { selectedDelete } = this.state
    return (
      <Dialog fullWidth maxWidth="sm" open={selectedDelete !== null} onClose={this.handleCloseModals}>
        <Typography variant="h2" className={classes.title}>Eliminar lista: {selectedDelete?.name}</Typography>
        <div className={classes.form}>
          <Typography variant="subtitle1">¿Seguro que deseas eliminar esta lista?</Typography>
          <SubmitButton onClick={this.handleDelete}>Eliminar</SubmitButton>
        </div>
      </Dialog>
    )
  }

  render() {
    const { classes } = this.props
    const { params, loading } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.createForm}>
          <Typography variant="h2" className={classes.title}>Crear lista</Typography>
          <div className={classes.form}>
            <TextInput label="Nombre" name="name" value={params.name} onChange={this.handleChange} />
            <SubmitButton onClick={this.handleCreate}>Crear</SubmitButton>
          </div>
        </div>
        {loading ?
          <div style={{ position: 'relative', height: 300 }}>
            <LoaderAnimator loading />
          </div>
          :
          this.renderCategories()
        }
        {this.EditDialog()}
        {this.DeleteDialog()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  createList: body => dispatch(createSettingsListAction(body)),
  getLists: body => dispatch(getSettingsListsAction(body)),
  editList: body => dispatch(editSettingsListAction(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Lists))