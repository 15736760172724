import instance2 from "../Utils/instance2"

export const getBaseEvaluations = (body) => {
  return instance2({
    method: 'get',
    url: 'settings/evaluations',
    params: body
  })
}

export const createBaseEvaluation = (body) => {
  return instance2({
    method: 'post',
    url: 'settings/evaluations',
    data: body
  })
}

export const editBaseEvaluation = (body) => {
  return instance2({
    method: 'put',
    url: 'settings/evaluations',
    data: body
  })
}

export const createBaseGroup = body => {
  return instance2({
    method: 'post',
    url: 'settings/evaluations/group',
    data: body
  })
}

export const editBaseGroup = body => {
  return instance2({
    method: 'put',
    url: 'settings/evaluations/group',
    data: body
  })
}

export const editBaseGroupPhoto = body => {
  return instance2({
    method: 'put',
    url: 'settings/evaluations/group/photo',
    data: body
  })
}

export const createBaseQuestion = body => {
  return instance2({
    method: 'post',
    url: 'settings/evaluations/question',
    data: body
  })
}

export const editBaseQuestion = body => {
  return instance2({
    method: 'put',
    url: 'settings/evaluations/question',
    data: body
  })
}

export const editBaseQuestionPhoto = body => {
  return instance2({
    method: 'put',
    url: 'settings/evaluations/question/photo',
    data: body
  })
}

export const deleteBaseQuestion = body => {
  return instance2({
    method: 'delete',
    url: 'settings/evaluations/question',
    data: body
  })
}

export const createBaseOption = body => {
  return instance2({
    method: 'post',
    url: 'settings/evaluations/option',
    data: body
  })
}

export const editBaseOption = body => {
  return instance2({
    method: 'put',
    url: 'settings/evaluations/option',
    data: body
  })
}

export const editBaseOptionPhoto = body => {
  return instance2({
    method: 'put',
    url: 'settings/evaluations/option/photo',
    data: body
  })
}

export const deleteBaseOption = body => {
  return instance2({
    method: 'delete',
    url: 'settings/evaluations/option',
    data: body
  })
}

export const getEvaluations = body => {
  return instance2({
    method: 'get',
    url: 'evaluations',
    params: body
  })
}

export const createEvaluation = body => {
  return instance2({
    method: 'post',
    url: 'evaluations',
    data: body
  })
}

export const answerQuestion = body => {
  return instance2({
    method: 'post',
    url: 'evaluations/answer',
    data: body
  })
}

export const finishEvaluation = body => {
  return instance2({
    method: 'put',
    url: 'evaluations/finish',
    data: body
  })
}