import axios from "axios"
import store from "../store"


const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)
// const url = `https://app.safeasy.cl/apiv1`
const url = isLocalhost ? 'http://localhost:3000/apiv1' : `https://meynaudi.com/apiv1`

const instance2 = axios.create({
    baseURL: url,
    timeout: 120000,
})

instance2.interceptors.request.use((config) => {
    const filtersData = localStorage.getItem("filters")
    const filters = filtersData ? JSON.parse(filtersData) : []
    const newConfig = { ...config }
    const accessToken = store.getState().user?.account?.jwt
    const userId = store.getState().user?.account.user?.id
    const companyId = store.getState().user?.account?.user?.idCompany
    const branch_id = store.getState().branch?.global?.id
    newConfig.headers["Token"] = accessToken
    newConfig.headers["User-Id"] = userId
    newConfig.headers["Company-Id"] = companyId
    newConfig.headers["Branch-Id"] = branch_id
    newConfig.headers["Branch-Ids"] = branch_id
    newConfig.headers["filters"] = filters
    return config
})

// instance2.interceptors.response.use(response => response,
//   () => store.getState().controller.snackbar('Error de conexión', { variant: 'error' }))

export default instance2
