import { Button, Typography, withStyles } from '@material-ui/core'
import { AddAPhoto, KeyboardArrowDown } from '@material-ui/icons'
import React from 'react'
import ImageContainer from '../../../Shared/ImageContainer'
import DateInput from '../../../Shared/Inputs/DateInput'
import SelectInput from '../../../Shared/Inputs/SelectInput'
import TextInput from '../../../Shared/Inputs/TextInput'
import autobind from '../../../Utils/autobind'
import { filterActiveWorkers } from '../../../Utils/filters'
import { resizeImageAndGetFile, transformToOptions } from '../../../Utils/functions'
import { cardStyle } from '../../Findings/CardStyles'
import moment from 'moment'
import PermissionChecker from '../../../Shared/Permissions/PermissionChecker'
import { connect } from 'react-redux'

function getImage(path, url) {
  if (path.includes("data:image")) {
    return path
  } else {
    return `${url}${path}`
  }
}

function simulateClick(finding) {
  return () => {
    const element = document.getElementById(`fileInput-${finding.id}`)
    element.click()
  }
}

class CardActionSection extends React.Component {
  constructor() {
    super()
    this.state = {
      openActions: false,
      params: {},
      base64File: null
    }
    autobind(CardActionSection, this)
  }

  handleOpenActions() {
    this.setState({ openActions: !this.state.openActions })
  }

  handleClickFileSelector(event, finding) {
    const { target } = event
    const file = target.files[0]

    const callback = (image, file) => {
      const { params } = this.state
      params.solution_evidence = file
      this.setState({ base64File: image, params })
    }

    resizeImageAndGetFile(file, callback)

  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleAssing() {
    const { params } = this.state
    const { assign, element, online, workers } = this.props
    const allWorker = workers?.all || []
    const user = allWorker.find(worker => worker.id.toString() === params.user_id.toString())
    const body = {
      ...params,
      id: element.id,
      online: online.status,
      user: user.name,
      date_assigned: moment(new Date()).format("YYYY-MM-DD"),
      date_limit: params.date_limit || moment(new Date()).format("YYYY-MM-DD"),
    }
    assign(body)
    this.setState({ openActions: false })
  }

  handleSolve() {
    const { params, base64File } = this.state
    const { solve, online, group } = this.props
    group.forEach(finding => {
      const body = { ...params, id: finding.id, status: 2, online: online.status, base64: base64File, date_solved: moment(new Date()).format("YYYY-MM-DD") }
      const data = new FormData()
      data.append("solution_evidence", params.solution_evidence)
      data.append("id", finding.id)
      data.append("status", 2)
      data.append("solution_comment", params.solution_comment)
      data.append("date_solved", moment(new Date()).format("YYYY-MM-DD"))
      solve(body, data)
    })
    this.setState({ openActions: false })
  }

  renderPending() {
    const { classes, element } = this.props
    const { openActions, params } = this.state
    const filteredWorkers = filterActiveWorkers()
    if (element.status !== "Pendiente") return null
    return (
      <div className={`${classes.actionContainer} ${openActions ? "open" : ""}`}>
        <div className={`${classes.actionButton} ${openActions ? "open" : ""}`} onClick={this.handleOpenActions}>
          <Typography className={`${classes.buttonText} ${openActions ? "open" : ""}`} variant="subtitle1">Asignar Acción Inmediata</Typography>
          <Typography className={`${classes.buttonHiddenText} ${openActions ? "" : "open"}`} variant="subtitle1">Asignando Acción Inmediata</Typography>
          <KeyboardArrowDown className={`${classes.arrowIcon} ${openActions ? "open" : ""}`} />
        </div>
        <div className={classes.form}>
          <SelectInput
            value={params.user_id}
            options={transformToOptions(filteredWorkers)}
            label="Seleccionar responsable"
            placeholder="Responsables"
            onChange={this.handleChange}
            name="user_id"
          />
          <DateInput label="Fecha límite de resolución" onChange={this.handleChange} name="date_limit" value={params.date_limit} />
          <TextInput label="Acción inmediata" onChange={this.handleChange} value={params.inmediate_action} name="inmediate_action" />
          <div className={classes.finalButton} onClick={this.handleAssing}>
            <Button size="small" variant="outlined" color="primary" disabled={!params.user_id}>Asignar</Button>
          </div>
        </div>
      </div>
    )
  }

  renderFinished() {
    const { classes, element, urls } = this.props
    if (element.status === "Pendiente") return null
    return (
      <div>
        <div className={classes.action}>
          <Typography variant="subtitle1">Acción Inmediata:</Typography>
          <Typography variant="body2">{element.inmediate_action || "No especifica..."}</Typography>
        </div>
        <div className={classes.resolveContainer}>
          <div>
            <Typography variant="subtitle1">Comentarios:</Typography>
            <Typography variant="body2">{element.solution_comment}</Typography>
          </div>
          <ImageContainer src={element.solution_evidence ? getImage(element.solution_evidence, urls[0]) : "/noimage.png"} alt="foto" height="64px" width="64px" />
        </div>
      </div>
    )
  }

  renderAsigned() {
    const { classes, element } = this.props
    const { openActions, params, base64File } = this.state
    if (element.status !== "Procesando" && element.status !== "Vencido") return null
    return (
      <PermissionChecker expectedPermissions={["resf"]} element={element}>
        <>
          <div className={`${classes.actionContainer} ${openActions ? "open" : ""}`}>
            <div className={`${classes.actionButton} ${openActions ? "open" : ""}`} onClick={this.handleOpenActions}>
              <Typography className={`${classes.buttonText} ${openActions ? "open" : ""}`} variant="subtitle1">Resolver Acción Inmediata</Typography>
              <Typography className={`${classes.buttonHiddenText} ${openActions ? "" : "open"}`} variant="subtitle1">Resolviendo Acción Inmediata</Typography>
              <KeyboardArrowDown className={`${classes.arrowIcon} ${openActions ? "open" : ""}`} />
            </div>
            <div className={classes.form}>
              <Typography variant="caption">Evidencia de resolución</Typography>
              <div className={classes.evidence}>
                <Button
                  variant="outlined"
                  onClick={simulateClick(element)}
                  style={{ padding: "7px 12px" }}
                >
                  <AddAPhoto />
                </Button>
                <ImageContainer src={params.solution_evidence ? base64File : "/noimage.png"} alt="foto" height="32px" width="32px" />
              </div>
              <TextInput label="Comentarios" onChange={this.handleChange} name="solution_comment" value={params.solution_comment} />
              <div className={classes.finalButton}>
                <Button size="small" variant="outlined" color="primary" onClick={this.handleSolve}>Resolver</Button>
              </div>
            </div>
          </div>
          <input
            type="file"
            id={`fileInput-${element.id}`}
            style={{
              visibility: "hidden",
              display: "none",
            }}
            onChange={e => this.handleClickFileSelector(e, element)}
          />
        </>
      </PermissionChecker>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        {this.renderFinished()}
        {this.renderPending()}
        {this.renderAsigned()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  online: state.online,
  workers: state.workers
})

export default connect(mapStateToProps)(withStyles(cardStyle)(CardActionSection))