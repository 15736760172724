import { Divider } from '@material-ui/core'
import { Edit, LocationOnOutlined } from '@material-ui/icons'
import { Dialog, Box, IconButton, Typography, Button, Paper } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getBranches } from '../../API/branches'
import { editSupply, getSupplies, moveSupply, supplyReport } from '../../API/supplies'
import useToggle from '../../Hooks/ToogleHook'
import GeneralTable from '../../Shared/GeneralTable'
import TextInput from '../../Shared/Inputs/TextInput'
import PeriodicalReportTable from '../../Shared/Reports/PeriodicalReportTable'
import Chip from '../Checklists/Chip'
import SupplyBranch from './SupplyBranch'

const css = {
	container: {
		background: 'white',
		padding: 2,
		borderRadius: 2,
		margin: '12px 0',
		position: 'relative',
		cursor: 'pointer'
	},
	infoLine: {
		display: 'flex',
		marginBottom: 1
	},
	button: {
		position: 'absolute',
		top: 6,
		right: 6
	},
	dialog: {
		padding: 3
	},
	submit: {
		textAlign: 'end'
	},
	mainContainer: {
		margin: 2,
		maxWidth: 1200
	},
	inline: {
		display: 'flex',
		margin: '12px 0',
		'& > *': {
			marginRight: 1
		}
	},
	branch: {
		margin: '24px 0'
	},
	paper: {
		padding: 2,
		margin: '24px 0'
	}
}

function Supply({ onEdit, onSelect, match }) {

	const [supply, setSupply] = useState({})
	const [edit, toggleEdit] = useToggle(false)
	const [params, setParams] = useState({})
	const [branches, setBranches] = useState([])

	useEffect(() => {
		async function fetchData() {
			const { id } = match.params
			const response = await getSupplies({ id })
			const branches_response = await getBranches()
			const { data: { info: branches_info } } = branches_response
			const { data: { info } } = response
			setSupply(info)
			setParams(info)
			setBranches(branches_info)
		}

		fetchData()
	}, [])

	function onChange(event) {
		const { target } = event
		const newParams = { ...params }
		newParams[target.name] = target.value
		setParams(newParams)
	}

	async function handleEdit() {
		const body = { ...params }
		const response = await editSupply(body)
		const { data: { info } } = response
		toggleEdit()
		setSupply(info)
		setParams(info)
	}

	async function onMove(body) {
		const response = await moveSupply(body)
		const { data: { info } } = response
		setSupply(info)
		setParams(info)
	}

	const tableInfo = [
		{ label: 'date_scheduled', name: "Fecha de Programación" },
		{ label: "preventive_measure", name: "Registro o formulario" },
		{ label: "user", name: "Responsable" },
		{ label: "compliance", name: "Cumplimiento", format: (value) => `${value}%` },
		{ label: "progress", name: "Avance", format: (value) => `${value}%` },
		{ label: "status", name: "Estado", render: Chip },
	]

	return (
		<Box sx={css.mainContainer}>
			<Box sx={css.container}>
				<Box sx={css.infoLine}>
					<Typography variant='h4' style={{ minWidth: 70 }} >{`Código:`}</Typography>
					<Typography variant='h4' style={{ flexGrow: 1 }} color="secondary" >{supply.code}</Typography>
				</Box>
				<Box sx={css.infoLine}>
					<Typography variant='h4' style={{ minWidth: 70 }} >{`Marca:`}</Typography>
					<Typography variant='h4' style={{ flexGrow: 1 }} color="secondary" >{supply.brand || "No ingresado"}</Typography>
				</Box>
				<Box sx={css.infoLine} >
					<Typography variant='h4' style={{ minWidth: 70 }} >{`Modelo:`}</Typography>
					<Typography variant='h4' style={{ flexGrow: 1 }} color="secondary" >{supply.model || "No ingresado"}</Typography>
				</Box>
				<Divider style={{ margin: '12px 0' }} />
				<Typography variant='subtitle1'>{`Tipo: ${supply.type}`}</Typography>
				<Typography variant='subtitle1'>{`Familia: ${supply.family}`}</Typography>
				<Typography variant='caption'>{moment(supply.created_at).format("YYYY-MM-DD HH:mm")}</Typography>
				<IconButton sx={css.button} onClick={toggleEdit}>
					<Edit />
				</IconButton>
			</Box >
			<Dialog open={edit} onClose={toggleEdit} fullWidth maxWidth="sm">
				<Box sx={css.dialog}>
					<Typography variant='h4'>{`Editar ${supply.type} código: ${supply.code}`}</Typography>
					<TextInput name="code" value={params.code} onChange={onChange} label="Código" />
					<TextInput name="brand" value={params.brand} onChange={onChange} label="Marca" />
					<TextInput name="model" value={params.model} onChange={onChange} label="Modelo" />
					<Box sx={css.submit}>
						<Button color="primary" variant="contained" size="small" onClick={handleEdit}>
							Guardar cambios
						</Button>
					</Box>
				</Box>
			</Dialog>
			<Box sx={css.branch}>
				<Typography variant='h4'>Ubicación actual:</Typography>
				<Box sx={css.inline}>
					<LocationOnOutlined />
					<Typography variant='subtitle1' color="secondary"><strong>{supply.branch}</strong></Typography>
				</Box>
				<SupplyBranch onMove={onMove} branches={branches} supply={supply} />
			</Box>
			<Paper>
				<PeriodicalReportTable fetchEndpoint={supplyReport} branches={branches} element={supply} />
			</Paper>
			<Paper sx={css.paper}>
				<Typography style={{ marginBottom: 12 }} variant='h4'>Participación del insumo o maquinaria</Typography>
				<GeneralTable info={tableInfo} data={supply.checklists} scrollable disableSearch />
			</Paper>
		</Box>
	)
}

export default withRouter(Supply)