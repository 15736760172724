import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import DonutChart from '../../../../Shared/Charts/DonutChart'

const style = () => ({
  chart: {
    width: 300,
    marginTop: 24
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    background: 'white',
    borderRadius: 8,
    padding: 12,
    margin: 6,
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  dot: {
    height: 18,
    width: 18,
    borderRadius: '50%',
    marginRight: 6
  }
})

const colors = [
  "#00876c",
  "#8cbcac",
  "#f1f1f1",
  "#ec9c9d",
  "#d43d51",
]

function enumerateAnswers(answers) {
  const result = {}
  answers.forEach(answer => {
    const { value, options } = answer.data
    if (!Array.isArray(options)) return null
    if (!value) return null
    console.log(answer, value, options)
    if (value) {
      if (Array.isArray(value)) {
        value.forEach(subvalue => {
          const subvalueInt = parseInt(subvalue, 10)
          result[options[subvalueInt]] ? result[options[subvalueInt]] += 1 : result[options[subvalueInt]] = 1
        })
      } else {
        result[options[value - 1]] ? result[options[value - 1]] += 1 : result[options[value - 1]] = 1
      }
    }
  })
  return result
}

class SelectPhaseReport extends Component {
  renderLabels(values) {
    const { classes } = this.props
    return Object.keys(values).map((option, index) => {
      return (
        <div className={classes.label}>
          <div className={classes.dot} style={{ background: colors[index] }} />
          <Typography variant="subtitle1">{option}: {values[option]}</Typography>
        </div>
      )
    })

  }

  render() {
    const { classes, answers, name } = this.props
    const values = enumerateAnswers(answers)
    const colorsTuUse = colors
    if (!Object.keys(values).length) return null
    return (
      <div className={classes.main}>
        <div className={classes.container}>
          <div className={classes.chart}>
            <DonutChart data={Object.values(values)} color={colorsTuUse} name={`${name}-${Math.random() * 100}`} />
          </div>
        </div>
        {this.renderLabels(values)}
      </div>
    )
  }
}

export default withStyles(style)(SelectPhaseReport)