import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import ResponisveImageContainer from '../../../../../Shared/ResponisveImageContainer'

const style = () => ({

})

class TextPhase extends Component {

  render() {
    const types = {
      text: "Texto",
      date: "Fecha",
      number: "Número",
      file: "Subir Documento",
      rte: "Texto enriquecido (word)"
    }
    const { classes, phase } = this.props
    const url = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_WORKFLOW_DOCUMENTS}`
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">Tipo de fase: {types[phase.type]}</Typography>
        <Typography variant="caption">Titulo: {phase.label}</Typography>
        {phase.image && <ResponisveImageContainer height={64} border={0} width={'100%'} src={`${url}/${phase.image}`} />}
      </div>
    )
  }
}

export default withStyles(style)(TextPhase)