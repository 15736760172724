import { Box, Button } from '@mui/material'
import { createContext, useContext, useEffect, useState } from 'react'

export const ChecklistManagerContext = createContext({})

function ChecklistManager({ children, db }) {

	function addChecklist(checklist) {
		const request = db.transaction(["checklists"], "readwrite")
			.objectStore("checklists")
			.put(checklist)

		request.onsuccess = function (event) {
			console.log("Checklist has been added to the store")
		}

		request.onerror = function (event) {
			console.log("Error adding checklist to the store")
		}
	}

	function addChecklists(checklists) {
		const transaction = db.transaction(["checklists"], "readwrite")
		const store = transaction.objectStore("checklists")

		for (const checklist of checklists) {
			store.put(checklist)
		}

		transaction.oncomplete = function (event) {
			console.log("Checklists has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding checklists to the store")
		}
	}

	function getChecklist(id) {
		if (!db) return null
		const request = db.transaction(["checklists"]).objectStore("checklists").get(id)

		return new Promise((resolve, reject) => {
			request.onsuccess = function (event) {
				resolve(request.result)
			}

			request.onerror = function (event) {
				console.log("Error getting checklist from the store")
				reject(request.error)
			}
		})
	}

	function setChecklists(checklists) {
		const transaction = db.transaction(["checklists"], "readwrite")
		const store = transaction.objectStore("checklists")
		store.clear()

		for (const checklist of checklists) {
			store.put(checklist)
		}

		transaction.oncomplete = function (event) {
			console.log("Checklists has been added to the store")
		}

		transaction.onerror = function (event) {
			console.log("Error adding checklists to the store")
		}
	}

	function getChecklists() {
		if (!db) return null
		const objectStore = db.transaction("checklists").objectStore("checklists")
		const checklists = []

		return new Promise((resolve, reject) => {
			objectStore.openCursor().onsuccess = function (event) {
				const cursor = event.target.result
				if (cursor) {
					checklists.push(cursor.value)
					cursor.continue()
				} else {
					resolve(checklists)
				}
			}

			objectStore.openCursor().onerror = function (event) {
				console.log("Error getting checklists from the store")
				reject(event.target.error)
			}
		})
	}

	return (
		<Box>
			<ChecklistManagerContext.Provider value={{ addChecklist, addChecklists, getChecklist, getChecklists, setChecklists }}>
				{children}
			</ChecklistManagerContext.Provider>
		</Box>
	)
}

export default ChecklistManager