import React, { Component } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import Chart from "chart.js"

const style = () => ({
  container: {
    padding: 12,
    maxWidth: 700
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  labelCircl: {
    height: 12,
    width: 12,
    borderRadius: '50%',
    marginRight: 12
  },
  blue: { background: '#ff6c87' },
  green: { background: "#91deb1" }
})

class FindingsDonut extends Component {
  componentDidMount() {
    const chartElement = document.getElementById("finding-donut")
    const myChartRef = chartElement.getContext("2d")
    this.chart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: ["Generó hallazgos", "No generó hallazgos"],
        datasets: [{
          data: [0, 0],
          borderWidth: 1,
          backgroundColor: ['#ff6c87', "#91deb1"]
        }]
      },
      options: {
        tooltips: false,
      },
    })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
      const { totalWithFinding = 0, totalWithoutFinding = 0 } = data
      const withPercentage = (totalWithFinding + totalWithoutFinding) > 0 ? totalWithFinding / (totalWithFinding + totalWithoutFinding) * 100 : 0
      const withouPercentage = (totalWithFinding + totalWithoutFinding) > 0 ? totalWithoutFinding / (totalWithFinding + totalWithoutFinding) * 100 : 0
      const chartData = [withPercentage, withouPercentage]
      this.chart.data.datasets = [
        {
          data: chartData,
          borderWidth: 1,
          backgroundColor: ['#ff6c87', "#91deb1"]
        }
      ]
      this.chart.update()
    }
  }

  render() {
    const { classes, data } = this.props
    const { totalFindings = 0, totalWithFinding = 0, totalWithoutFinding = 0 } = data
    const withPercentage = (totalWithFinding + totalWithoutFinding) > 0 ? totalWithFinding / (totalWithFinding + totalWithoutFinding) * 100 : 0
    const withouPercentage = (totalWithFinding + totalWithoutFinding) > 0 ? totalWithoutFinding / (totalWithFinding + totalWithoutFinding) * 100 : 0
    return (
      <div className={classes.container}>
        <Typography variant="h4">Recuento de Hallazgos</Typography>
        <Divider style={{ margin: '12px 0' }} />
        <div style={{ margin: 24 }}>
          <canvas id="finding-donut" ref={this.chartRef} />
        </div>
        <div className={classes.label}>
          <div className={`${classes.labelCircl} ${classes.blue}`} />
          <Typography variant="subtitle1">{Math.round(withPercentage)}% Registros y formularios generaron hallazgos</Typography>
        </div>
        <div className={classes.label}>
          <div className={`${classes.labelCircl} ${classes.green}`} />
          <Typography variant="subtitle1">{Math.round(withouPercentage)}% Registros y formularios no generaron hallazgos</Typography>
        </div>
        <div style={{ marginTop: 12 }}>
          <Typography variant="h2">Total de hallazgos levantados: {totalFindings}</Typography>
        </div>
      </div>
    )
  }
}

export default withStyles(style)(FindingsDonut)